import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { GoodsTable } from "Common/GoodsTable/GoodsTable/GoodsTable";
import { AcceptedCodes, FailedCodes } from "Common/Stages/CodesStatuses/CodesStatuses";
import { DateAndUser } from "Common/Stages/DateAndUser";
import { GoodsTableWrap, RecalledWrap } from "Common/Stages/Stages.styles";
import { DeliveryStatusBlock, DeliveryStatusBlockProps } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { GoodsWrapperBlock } from "Common/Styles/DetailsPage.styles";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { DeliveryStatus } from "typings/server";
import { CirculationPageVM } from "../../CirculationPageVM";
import { StagesPartVM } from "./StagesPartVM";
import { ErrorText } from "Common/Status/DeliveryStatus/DeliveryStatus.styles";

interface IWithdrawalStagesPartProps {
  delivery: WithdrawalDeliveryModel;
  vm: CirculationPageVM;
  overrideFields?: Partial<DeliveryStatusBlockProps>;
}

export const DisposalWithRegistratorStagesPart = observer((props: IWithdrawalStagesPartProps) => {
  const { delivery, vm, overrideFields } = props;

  const stagesVM = useMemo(() => new StagesPartVM(delivery), [delivery]);

  const collapseId = `${delivery.id}${stagesVM.isPartialProcessed && "anotherStage"}`;

  const onCollapse = () => vm.onCollapseDelivery(collapseId);
  const errorInfo = delivery.stepsInfo[DeliveryStatus.MdlpProcessing]?.errorInfo?.errorDescription;
  return (
    <DeliveryStatusBlock
      size={delivery.isActive ? "large" : "medium"}
      name={stagesVM.title}
      status={delivery.stages[DeliveryStage.Sending].progress}
      collapsed={!vm.isDeliveryOpenedState.get(collapseId)}
      alwaysVisibleComp={
        <DateAndUser
          delivery={vm.delivery}
          getCompletionUser={vm.getCompletionUser}
          getCompletionDate={vm.getCompletionDate}
          step={DeliveryStatus.Sending}
        />
      }
      onCollapse={stagesVM.isCollapsible ? onCollapse : undefined}
      error={stagesVM.isErrorStatus}
      {...overrideFields}
    >
      <GoodsWrapperBlock>
        {errorInfo && <ErrorText>{errorInfo}</ErrorText>}
        <RecalledWrap>
          <AcceptedCodes delivery={delivery} acceptedCodesLen={delivery.acceptedCodesLen} />
          <FailedCodes failedCodesLen={delivery.failedCodesLen} />
        </RecalledWrap>
        <GoodsTableWrap>
          <GoodsTable goods={stagesVM.deliveryItems} additionalInfo={vm.additionalInfo} />
        </GoodsTableWrap>
      </GoodsWrapperBlock>
    </DeliveryStatusBlock>
  );
});
