import * as React from "react";
import { Logger } from "helpers/logger";
import { BILLING_STATUS } from "api/shared";
import { errorNotification } from "./shared";
import { store } from "react-notifications-component";
import { UserStore } from "stores/UserStore";
import { ComponentDidCatchError } from "src/errors/ComponentDidCatchError";

export class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Logger.error({ error: new ComponentDidCatchError(error, errorInfo) });
  }

  componentDidMount(): void {
    window.addEventListener("error", this.onError);
    window.addEventListener("unhandledrejection", this.onUnhandledRejection);

    const inn = UserStore.organization && UserStore.organization.inn;
    Logger.configure({ inn, username: UserStore.fullName, id: UserStore.portalUserId });
  }

  componentWillUnmount(): void {
    window.removeEventListener("error", this.onError);
    window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  render() {
    return this.props.children;
  }

  protected onError(error: ErrorEvent) {
    Logger.error({ error });
  }

  protected onUnhandledRejection = (e: PromiseRejectionEvent) => {
    Logger.error({ error: e.reason });
    
    if (!e.reason) return;    
    const error = e.reason;    
    
    const status = error.status;
    let msg = error.message || "Произошла ошибка, попробуйте еще раз или обратитесь в техподдержку";
    let title = "Ошибка";

    // todo: https://yt.skbkontur.ru/issue/MARKDRUGS-107
    if (status && status === BILLING_STATUS) {
      msg = "Нет действительной подписки на сервис, поэтому операции по маркировке товаров недоступны.";
      title = "Нет действительной подписки";
    }

    ErrorBoundary.showErrorNotification(msg, title);
  };

  static showErrorNotification(text: string, title?: string) {
    store.addNotification({
      ...errorNotification,
      title,
      message: text,
    });
  }
}
