import React from "react";
import { observer } from "mobx-react";
import { BalanceStore } from "stores/BalanceStore";
import { BalanceTextVM } from "./BalanceTextVM";
import { OrderMoreCodesUrl } from "StringResx";
import { Link } from "components";
import { WarningText } from "components/WarningText/WarningText";
import { getEnding } from "helpers/endings";

interface BalanceTextProps {
  top?: boolean;
}

interface BalanceTextInternalProps extends BalanceTextProps {
  vm: BalanceTextVM;
}

const BalanceTextInternal = observer(({ vm, top }: BalanceTextInternalProps) => {
  const getBalanceText = () => {
    if (!vm.balanceInfo.allRestCount && !vm.balanceInfo.formattedDate) {
      return top ? null : "Закончилась подписка на сервис.";
    }
    const remains = getEnding(vm.balanceInfo.allRestCount, "Остался", "Осталось", "Осталось");
    const codes = getEnding(vm.balanceInfo.allRestCount, "код", "кода", "кодов");

    const codesLeftText = vm.isUnlimit
      ? `Оплачено до ${vm.balanceInfo.formattedDate}`
      : `${remains} ${vm.balanceInfo.allRestCount} ${codes} маркировки до ${vm.balanceInfo.formattedDate}`;
    if (vm.isBalanceSevereWarning || vm.isBalanceWarning) {
      return (
        <div>
          {vm.isBalanceSevereWarning ? "Оплаченные коды маркировки закончились" : codesLeftText}. Для продления
          обслуживания{" "}
          <Link href={OrderMoreCodesUrl} target="_blank">
            оставьте заявку на оплату
          </Link>
          .
        </div>
      );
    }
    return top ? null : codesLeftText;
  };

  return (
    <WarningText text={getBalanceText()} level={vm.balanceWarningLevel} isIcon={false} maxWidth="initial" top={top} />
  );
});

// React.memo + () => true - не обновляет компонент
export const BalanceText = React.memo(
  ({ top }: BalanceTextProps) => {
    const vm = new BalanceTextVM(BalanceStore);

    return <BalanceTextInternal vm={vm} top={top} />;
  },
  () => true
);
