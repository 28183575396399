import styled from "styles/styled-components";

const radius = "18px";

export const GoToIcon = styled.div`
  display: inline-flex;
  width: ${radius};
  height: ${radius};
  border: 0.75px solid ${props => props.theme.brandColors.default};
  border-radius: ${radius};
  vertical-align: bottom;
  align-items: center;
  font-size: 11px;
  line-height: 13px;
  justify-content: center;
  margin-right: 7px;
`;

export const GoToBlockWrap = styled.div`
  display: inline-block;
  color: ${props => props.theme.colors.darkBlue};
  cursor: pointer;
`;

export const GoToName = styled.span`
  ${GoToBlockWrap}:hover & {
    text-decoration: underline;
  }
`;
