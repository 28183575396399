export interface IHttpClient {
  get<T>(requestUrl: string, params?: any, headers?: THeaders, signal?: AbortSignal): Promise<T>;
  delete<T>(requestUrl: string, params?: any, headers?: THeaders): Promise<T>;
  put<T>(requestUrl: string, data?: any, params?: any, headers?: THeaders): Promise<T>;
  patch<T>(requestUrl: string, data?: any, params?: any, headers?: THeaders): Promise<T>;
  post<T>(requestUrl: string, data?: any, params?: any, headers?: THeaders, signal?: AbortSignal): Promise<T>;
  download(requestUrl: string, params?: any, headers?: THeaders): Promise<DownloadResult>;
}

export interface DownloadResult {
  data: Blob;
  fileName: string;
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export type THeaders = Record<string, string>;

export const headersDefault: THeaders = {
  "Content-Type": "application/json; charset=utf-8",
  "Cache-Control": "must-revalidate, private",
  Pragma: "no-cache",
};

export function isResponse(response: any): response is Response {
  return response && typeof response.status === "number";
} 