import styled from "styles/styled-components";

export const UploadText = styled.span`
  margin-top: 15px;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
`;

export const ErrorText = styled.span`
  max-width: 100%;
  margin-top: 30px;
  font-size: 16px;
  line-height: 30px;
  white-space: pre-line;
`;

export const ModalWrapHeight = "420px";
export const LoaderMinHeight = "415px";

export const UtdModalWrap = styled.div`
  width: 655px;
  height: ${ModalWrapHeight};
  overflow-y: auto;
`;

export const UtdModalDeliveryWrap = styled.div`
  line-height: 20px;
`;

export const UtdModalDeliveryIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const TipMsg = styled.div`
  line-height: 20px;

  p:first-of-type {
    margin-top: 5px;
  }
  p:last-of-type {
    margin-bottom: 5px;
  }
`;

export const TipIconWrap = styled.span`
  cursor: pointer;
  color: ${props => props.theme.colors.darkBlue};
`;

export const RowWithOffset = styled.div`
  margin-bottom: 20px;
`;

export const DepartmentAddress = styled.div`
  margin: 22px 4px 8px;
`;

export const TemplateLinkWrap = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export const InvoiceList = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 10px;
  }

  li::before {
    content: "• ";
    position: relative;
    top: 2px;
    font-size: 25px;
    color: ${props => props.theme.colors.gray};
  }
`;

export const fileNameMaxW = 550;
