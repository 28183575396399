import React from "react";
import { BackgroundFar, BackgroundMed, BackgroundNear, Content, LogoLink, PageWrapper, Root } from "./ErrorPage.styles";
import { RootRoute } from "routes";

export function CloudsPage(props: React.PropsWithChildren<{}>) {
  return (
    <Root>
      <BackgroundFar>
        <BackgroundMed>
          <BackgroundNear>
            <PageWrapper>
              <LogoLink href={RootRoute} rel={"noopener"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="135" height="40" viewBox="0 0 135 40">
                  <path
                    fill="#333"
                    id="kontur-logo-text"
                    d="M14.783 11.332h-3.905l-7.823 8.525h-.07v-8.525h-2.985v18.617h2.984v-8.948h.07l8.219 8.948h4.187l-9.312-9.642 8.635-8.975zm58.883 7.627h-9.489v-7.627h-2.987v18.617h2.986v-8.458h9.489v8.458h2.986v-18.617h-2.986v7.627zm6.541-5.087h5.605v16.077h2.977v-16.077h5.588v-2.54h-14.17v2.54zm25.705 11.82c-.178.633-.302 1.091-.366 1.38h-.106c-.189-.762-.316-1.231-.385-1.418l-5.031-14.322h-3.308l7.271 18.578-1.488 3.529c-.756 1.796-1.879 2.688-3.385 2.688-.525 0-1.119.041-1.76-.178v2.525c.514.156 1.169.232 1.938.232 2.77 0 4.917-1.926 6.443-5.781l8.561-21.594h-3.091l-5.293 14.361zm26.702-12.298c-1.353-1.668-3.231-2.499-5.622-2.499-2.826 0-4.974 1.229-6.439 3.703h-.074v-3.266h-2.984v27.178h2.984v-11.254h.074c1.309 2.087 3.17 3.127 5.58 3.127 2.631 0 4.703-.937 6.216-2.808 1.522-1.876 2.273-4.365 2.273-7.49 0-2.793-.667-5.023-2.008-6.691zm-2.581 12.409c-1.019 1.377-2.437 2.076-4.24 2.076-1.545 0-2.812-.54-3.811-1.615-1.004-1.07-1.504-2.377-1.504-3.916v-2.603c0-1.819.529-3.331 1.58-4.533 1.059-1.208 2.452-1.812 4.183-1.812 1.636 0 2.936.585 3.892 1.763.957 1.176 1.441 2.8 1.441 4.869 0 2.463-.513 4.388-1.541 5.771z"
                  />
                  <path
                    fill="#D91F26"
                    id="kontur-logo-cloud"
                    d="M35.322 5.122c3.223 0 6.178 1.449 8.109 3.986l.826 1.078 1.352.082c4.922.322 8.779 4.433 8.779 9.371 0 5.174-4.21 9.385-9.389 9.385h-18.132c-4.444 0-8.057-3.612-8.057-8.062 0-3.326 2.101-6.35 5.222-7.535l1.249-.478.484-1.252c1.508-3.935 5.347-6.575 9.557-6.575m0-2.986c-5.627 0-10.44 3.534-12.343 8.505-4.175 1.571-7.153 5.611-7.153 10.322 0 6.089 4.953 11.037 11.041 11.037h18.133c6.815 0 12.366-5.539 12.366-12.36 0-6.548-5.119-11.922-11.565-12.342-2.434-3.2-6.256-5.162-10.479-5.162z"
                  />
                </svg>
              </LogoLink>
              <Content>{props.children}</Content>
            </PageWrapper>
          </BackgroundNear>
        </BackgroundMed>
      </BackgroundFar>
    </Root>
  );
}
