import React from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { Header, ProducerTextWrapper, TableWrap } from "../AdditionalInfo.styles";
import { AdditionalInfoSsccTableItem } from "./AdditionalInfoSsccTableItem";
import { TextGrey } from "styles/shared.styles";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { AdditionalInfoVM } from "../AdditionalInfoVM";

interface IAdditionalInfoTableProps {
  vm: AdditionalInfoVM;
  codes: string[];
  item?: DeliveryItemModelExtended;
  onDiscardCode?(code: string): void;
}

export const AdditionalInfoSsccTable = observer((props: IAdditionalInfoTableProps) => {
  const { vm, codes, onDiscardCode, item } = props;

  if (!vm.additionalInfo.checkCodesHaveAdditionalInfo(codes)) return null;

  const producer = vm.getProducer(codes[0]);

  const canBeDiscarded = (code: string) => {
    const codeModel = item?.getCode(code);
    if (codeModel) {
      return codeModel.scanned === true;
    }
    return true;
  };

  return (
    <TableWrap data-tid="AdditionalInfoSsccTable">
      {producer && (
        <ProducerTextWrapper>
          <TextGrey>Производитель: {producer}</TextGrey>
        </ProducerTextWrapper>
      )}
      <Header>
        <Grid cols={vm.ssccCols} paddingHeaderCells={"6px 4px 6px"}>
          <GridCellHeader col={1} row={1} padding={"6px"}>
            {!vm.isSidePage && "Код агрегата "}SSCC
          </GridCellHeader>
          <GridCellHeader col={2} row={1} alignSelf="start">
            GTIN
          </GridCellHeader>
          <GridCellHeader col={3} row={1}>
            Состав, агрегат
          </GridCellHeader>
          <GridCellHeader col={4} row={1} padding={"6px"}>
            Состав, штучный товар
          </GridCellHeader>
        </Grid>
      </Header>
      {codes.map(code => (
        <AdditionalInfoSsccTableItem
          key={code}
          codeInfo={vm.additionalInfo.codesInfo.get(code)}
          vm={vm}
          onDiscardCode={onDiscardCode}
          canBeDiscarded={canBeDiscarded(code)}
        />
      ))}
    </TableWrap>
  );
});
