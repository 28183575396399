import { DownloadResult, headersDefault, HttpMethod, IHttpClient, THeaders, isResponse } from "./shared";
import { getFileName } from "helpers/download";
import { AUTH_REQUIRED_STATUS } from "../shared";
import { isTestEnv } from "../../enviroment";
import { ServerNotAvailableError } from "src/errors/ServerNotAvailableError";
import { ServerError } from "src/errors/ServerError";

declare var process: any;
const { REACT_APP_API_URL } = process.env;

export class HttpClient implements IHttpClient {
  private readonly baseUrl: string;

  constructor(basePath: string, getHeadersFunc?: any) {
    const urlPrefix =
      isTestEnv() && !window.location.host.startsWith("localhost.testkontur.ru") ? "http://localhost:28307" : "";
    this.baseUrl = `${urlPrefix}${basePath || REACT_APP_API_URL || ""}`;
    if (getHeadersFunc) this.getHeaders = getHeadersFunc;
  }

  public get = <T>(requestUrl: string, params?: any, headers?: THeaders, signal?: AbortSignal): Promise<T> => {
    const url = this.buildUrl(requestUrl, params);
    const options = this.getRequestOptions("GET", undefined, headers, signal);
    return fetch(url, options).then(this.resolveJson).catch(this.reject);
  };

  public delete = <T>(requestUrl: string, params?: any, headers?: THeaders): Promise<T> => {
    const url = this.buildUrl(requestUrl, params);
    const options = this.getRequestOptions("DELETE", undefined, headers);
    return fetch(url, options).then(this.resolveJson).catch(this.reject);
  };

  public put = <T>(requestUrl: string, data?: any, params?: any, headers?: THeaders): Promise<T> => {
    const url = this.buildUrl(requestUrl, params);
    const options = this.getRequestOptions("PUT", data, headers);
    return fetch(url, options).then(this.resolveJson).catch(this.reject);
  };

  public patch = <T>(requestUrl: string, data?: any, params?: any, headers?: THeaders): Promise<T> => {
    const url = this.buildUrl(requestUrl, params);
    const options = this.getRequestOptions("PATCH", data, headers);
    return fetch(url, options).then(this.resolveJson).catch(this.reject);
  };

  public post = <T>(
    requestUrl: string,
    data?: any,
    params?: any,
    headers?: THeaders,
    signal?: AbortSignal
  ): Promise<T> => {
    const url = this.buildUrl(requestUrl, params);
    const options = this.getRequestOptions("POST", data, headers, signal);
    return fetch(url, options).then(this.resolveJson).catch(this.reject);
  };

  public download = (requestUrl: string, params?: any, headers?: THeaders): Promise<DownloadResult> => {
    const url = this.buildUrl(requestUrl, params);
    const options = this.getRequestOptions("GET", undefined, headers);
    return fetch(url, options).then(this.resolveBlob).catch(this.reject);
  };

  private resolveJson = async (response: Response): Promise<any> => {
    const error = this.resolveErrors(response);

    if (error) {
      return Promise.reject(error);
    }

    const text = await response.text();

    try {
      return JSON.parse(text);
    } catch (e) {
      return text;
    }
  };

  resolveErrors(response: Response): Response | undefined {
    const { status } = response;

    if (status === AUTH_REQUIRED_STATUS) {
      response.text().then(authUrl => {
        if (authUrl) {
          const portalAuthUrl = authUrl;
          const currentLocation = window.location.href;
          const url = new URL(portalAuthUrl);
          url.searchParams.append("back", currentLocation);
          window.location.href = url.href;
        }
      });
    }

    if (status >= 400) {
      return response;
    }
  }

  // might be override
  private getHeaders(): THeaders {
    return headersDefault;
  }

  private resolveBlob = async (response: Response): Promise<DownloadResult> => {
    const error = this.resolveErrors(response);

    if (error) {
      return Promise.reject(error);
    }

    const data = await response.blob();
    return {
      data,
      fileName: getFileName(response.headers),
    };
  };

  private async reject(error: any | Response) {
    if (isResponse(error)) {
      let text, jsonMsg;

      try {
        text = await error.text();
        jsonMsg = JSON.parse(text);
      } catch (e) {}  

      const serverError = new ServerError(
        error.statusText || text || "",
        error.status,
        error.headers,
        jsonMsg,
      );

      return Promise.reject(serverError);  
    }

    return Promise.reject(new ServerNotAvailableError(error));
  }

  private getRequestOptions = (
    method: HttpMethod,
    data?: object,
    headers?: THeaders,
    signal?: AbortSignal
  ): RequestInit => {
    const request: RequestInit = {
      method,
      mode: "cors",
      credentials: "include",
    };

    if (data instanceof FormData) {
      return { ...request, body: data };
    }

    return {
      ...request,
      headers: headers || this.getHeaders(),
      body: data ? JSON.stringify(data) : undefined,
      signal,
    };
  };

  private buildUrl = (requestUrl: string, params: any) => {
    const arrayParams: Array<any> = [];
    const strParams: Array<any> = [];
    const baseUrl = `${this.baseUrl}/${requestUrl}`;

    if (!params) return baseUrl;

    try {
      Object.entries(params).forEach(param => {
        const [, value] = param;
        if (Array.isArray(value)) {
          arrayParams.push(param);
        } else strParams.push(param);
      });

      // change array params from &param=val1,val2 (default URLSearchParams behaviour)
      // to &param=val1&param=val2
      const uriParams = new URLSearchParams(strParams);
      arrayParams.forEach(([key, values]) => {
        values.forEach((value: string) => {
          uriParams.append(key, value);
        });
      });

      return params ? `${this.baseUrl}/${requestUrl}?${uriParams}` : `${this.baseUrl}/${requestUrl}`;
    } catch (e) {
      return baseUrl;
    }
  };
}
