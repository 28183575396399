import React, { useEffect, useState, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { CirculationRoute } from "routes";
import { Loader } from "components/Loader";
import { SignatureStore } from "stores/SignatureStore";
import { WithdrawalPage } from "./WithdrawalPage";
import { UserStore } from "stores/UserStore";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { DeliveryModel } from "typings/server";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { CirculationSubRoute } from "routes/CirculationRoute";
import { CirculationPageVM } from "./CirculationPageVM";
import { ReentryPage } from "./ReentryPage";
import { DestructionPage } from "./DestructionPage";

export const DetailsPageWrapper = (props: RouteComponentProps<CirculationRoute>) => {
  const { match, history } = props;

  const isReentry = history.location.pathname.includes(CirculationSubRoute.reentry);
  const isDestruction = history.location.pathname.includes(CirculationSubRoute.destruction);

  const id = match.params.operationId;
  const [loading, setLoading] = useState<boolean>(true);
  const [delivery, setDelivery] = useState<DeliveryModel | null>();

  useEffect(() => {
    if (id) {
      WithdrawalStore.getItem(id, { force: true })
        .then(delivery => {
          WithdrawalStore.setSelected(delivery);
          setDelivery(delivery);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const details = useMemo(() => {
    if (!delivery) {
      return null;
    }
    if (isReentry) {
      return (
        <ReentryPage vm={new CirculationPageVM(WithdrawalStore, SignatureStore, UserStore, delivery.type)} {...props} />
      );
    }
    if (isDestruction) {
      return (
        <DestructionPage
          vm={new CirculationPageVM(WithdrawalStore, SignatureStore, UserStore, delivery.type)}
          {...props}
        />
      );
    }
    return (
      <WithdrawalPage
        vm={new CirculationPageVM(WithdrawalStore, SignatureStore, UserStore, delivery.type)}
        {...props}
      />
    );
  }, [isReentry, isDestruction, delivery]);

  if (loading) {
    return <Loader active />;
  }

  if (!delivery) {
    return <>Поставка не найдена</>;
  }

  return <DeliveryTypeContext.Provider value={delivery.type}>{details}</DeliveryTypeContext.Provider>;
};
