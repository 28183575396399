import styled from "styles/styled-components";

export const ModalBodyInner = styled.div`
  max-width: 600px;
  margin-top: 5px;
`;

interface IEditorLabelProps {
  required?: boolean;
  inside?: boolean;
  breakText?: boolean;
}

export const EditorLabel = styled.div<IEditorLabelProps>`
  display: ${props => (props.inside ? "inline-block" : "block")};
  position: relative;
  bottom: ${props => (props.breakText ? "10px" : "0")};
  &:after {
    content: "*";
    display: ${props => (props.required ? "inline-block" : "none")};
    color: ${props => props.theme.textColors.red};
    position: relative;
    top: -3px;
    right: -1px;
  }
`;

export const OKUDWrap = styled.div`
  display: inline-block;
  margin-left: 25px;
`;

export const OKUDInputWrap = styled.span`
  margin-left: 15px;
`;

export const DocumentHeader = styled.h4`
  margin-top: -10px;
`;

export const HelpDotIconWrap = styled.span`
  margin-left: 4px;
  font-size: 19px;
  color: ${props => props.theme.colors.darkBlue};
  cursor: pointer;
`;

export const HelpBookIconWrap = styled.span`
  margin-right: 4px;
  cursor: pointer;
`;

export const WarningIconWrap = styled.div`
  margin-right: 7px;
  color: ${props => props.theme.colors.orangeDark};
  display: inline-flex;
  float: left;
`;

export const TooltipRoot = styled.div`
  line-height: 20px;
  font-weight: 400;
`;

export const TooltipUp = styled.div`
  margin-bottom: 16px;
`;

export const TooltipBottom = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
`;

export const RadioRoot = styled.div`
  margin-right: 12px;
`;
