import React from "react";
import { observer } from "mobx-react";
import { Button } from "components";
import { UnpackingPageVM } from "../../UnpackingPageVM";
import { DateText, DateWrap, PageInfoBlockWrap, Title } from "./PageInfoBlock.styles";
import { UnpackingCodesTable } from "./UnpackingCodesTable/UnpackingCodesTable";
import {
  CodesBlock,
  ScanPanel,
} from "features/Packing/Extraction/DetailsPage/ExtractionPage/Stages/PageInfoBlock/PageInfoBlock.styles";
import { ScannedLargeText } from "features/Common/ScannedItemsCount/ScannedItemsCount.styles";
import BarcodeScannerIcon from "@skbkontur/react-icons/BarcodeScanner";
import { getEnding } from "helpers/endings";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

interface IPageInfoBlockProps {
  vm: UnpackingPageVM;
}

export const PageInfoBlock: React.FC<IPageInfoBlockProps> = observer((props: IPageInfoBlockProps) => {
  const { vm } = props;

  const onScan = () => {
    vm.toggleModal("scanPage");
    // blur(), because scanner triggers button click on "enter"
    if (window.document.activeElement && (window.document.activeElement as HTMLElement).blur) {
      (window.document.activeElement as HTMLElement).blur();
    }
  };

  const getReportDateError = () => vm.errors.get("invalidReportDate");

  return (
    <PageInfoBlockWrap>
      {vm.isEditable && (
        <DateWrap>
          <DateText>Дата расформирования</DateText>
          <DateTimePicker
            getReportDateError={getReportDateError}
            maxDate={vm.maxDate}
            value={vm.reportDate}
            onChange={vm.setReportDate}
          />
        </DateWrap>
      )}

      <Title>Агрегаты</Title>

      {vm.isEditable && (
        <ScanPanel>
          <CodesBlock>
            <ScannedLargeText>{vm.delivery.scannedCodes.length} </ScannedLargeText>
            &nbsp;&nbsp;{getEnding(vm.delivery.scannedCodes.length, "код", "кода", "кодов")} маркировки
          </CodesBlock>
          <Button onClick={onScan} size={"large"} icon={<BarcodeScannerIcon />}>
            Вернуться к сканированию
          </Button>
        </ScanPanel>
      )}

      <UnpackingCodesTable vm={vm} />
    </PageInfoBlockWrap>
  );
});
