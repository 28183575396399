import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { CodesByStatusVM, TCodeType } from "./CodesByStatusVM";
import { ScannedStatusText } from "../ScannedItemsCount/ScannedItemsCount.styles";
import { getEnding } from "helpers";
import { DeliveryItemStatus } from "typings/server";
import { RejectedStatusIcon } from "../GoodsTable/GoodsTable/GoodsTable.styles";
import { AcceptedStatusIcon, RecalledStatusIcon } from "icons";
import { Error } from "@skbkontur/react-icons";
import { StatusIcon, StatusWrap } from "./CodesByStatus.styles";

interface ICodesByStatusProps {
  good: UtdPositionModelExtended;
  type: TCodeType;
  allLen: number;
  dataTid?: string;
}

export const CodesByStatus = observer(({ good, type, allLen, dataTid }: ICodesByStatusProps) => {
  const vm = useMemo(() => new CodesByStatusVM(good, type), [good, type]);

  if (!allLen) return <>—</>;

  if (!vm.groupedCodes) return null;

  return (
    <div>
      {vm.groupedCodes.map((item, i) => (
        <StatusWrap key={i}>
          <ScannedStatusText status={item.status}>
            {(item.status || vm.moreThanOneStatus) && (
              <StatusIcon>
                {item.status === DeliveryItemStatus.Accepted && <AcceptedStatusIcon />}
                {item.status === DeliveryItemStatus.Error && <Error />}
                {item.status === DeliveryItemStatus.Recalled && <RecalledStatusIcon top={"1px"} />}
                {item.status === DeliveryItemStatus.Rejected && <RejectedStatusIcon />}
              </StatusIcon>
            )}
            <span data-tid={dataTid}>{item.count}</span> {getEnding(item.count, "код", "кода", "кодов")}
          </ScannedStatusText>
        </StatusWrap>
      ))}
    </div>
  );
});
