import { EmployeeRole } from "./server";

export interface DownloadResult {
  data: Blob;
  fileName: string;
}

export const EmployeeRoleBitwise: Record<string | EmployeeRole, number> = {
  [EmployeeRole.User]: 1,
  [EmployeeRole.AllDepartmentsUser]: 2,
  [EmployeeRole.Admin]: 4,
};
