import styled from "styles/styled-components";
import ClockIcon from "@skbkontur/react-icons/Clock";
import { theme } from "styles/theme";

export const ClockIconStyled = styled(ClockIcon)`
  font-size: 13px;
  color: ${props => props.theme.textColors.default};
  transform: rotate(32.74deg);
`;

// fix for mac os
export const TimeInputWrap = styled.span`
  & input {
    font-family: kontur-mask-char, Lab Grotesque, Helevetica Neue, sans-serif !important;
    color: ${theme.textColors.default} !important;
  }
`;
