import { CodesReportModel, DeliveryItemStatus } from "typings/server";
import { DeliveryItemModelExtended } from "../Delivery/ItemModel/DeliveryItemModelExtended";

export interface ICodesReportModelExtended extends CodesReportModel {}

export interface ICodesReportDeliveryModel {
  items: DeliveryItemModelExtended[];
  confirmPaused?: boolean;
  reportDate?: string;
  recallReason?: string;
  rejectUnscanned?: boolean;
}

export class CodesReportModelExtended implements ICodesReportModelExtended {
  public codes: string[];
  public allIncomingCodes: boolean;
  public deliveryId: string;
  public operationDate?: string;
  public rejectReason: string;
  rejectUnscanned?: boolean;
  confirmPaused: boolean;
  reportDate: string;

  constructor(model: ICodesReportDeliveryModel, deliveryId: string, allIncomingCodes?: boolean, operationDate?: Date) {
    this.deliveryId = deliveryId;
    this.setCodes(model.items);
    this.confirmPaused = model.confirmPaused || false;

    if (model.reportDate) {
      this.reportDate = model.reportDate;
    }
    this.operationDate = operationDate && operationDate.toISOString();

    this.allIncomingCodes = allIncomingCodes || false;

    // it is called recallReason in DeliveryModel, but the same field called rejectReason in CodesReportModel
    // this is not an error
    if (model.recallReason) {
      this.rejectReason = model.recallReason.trim();
    }

    this.rejectUnscanned = model.rejectUnscanned;
  }

  private setCodes(items: DeliveryItemModelExtended[]) {
    this.codes = items
      .filter(
        item =>
          item.status === DeliveryItemStatus.Unprocessed || !item.status || item.status === DeliveryItemStatus.Error
      )
      .map(item => item.allScannedCodes)
      .flat();
  }
}
