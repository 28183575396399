import React from "react";
import { observable } from "mobx";
import { DeliveriesStore } from "stores/DeliveriesStore";
import { UserStore } from "stores/UserStore";
import { AcceptanceRoute, PackingRoute, SettingsRoute, ShipmentRoute, TransferRoute, CirculationRoute } from "routes";
import { AcceptanceIcon, AggregationIcon, SettingsIcon, ShipmentIcon, TransferIcon, WithdrawalIcon } from "icons";

export interface IMenuItem {
  name: string;
  href: string;
  icon: JSX.Element;
  count?: number;
}

export class MenuVMInner {
  @observable readonly menuItems: IMenuItem[] = [
    {
      name: AcceptanceRoute.title,
      icon: <AcceptanceIcon />,
      href: AcceptanceRoute.baseRoute,
      get count() {
        const currentDepartmentId = UserStore.user ? UserStore.currentDepartment?.id : undefined;

        if (!currentDepartmentId) {
          return;
        }

        return DeliveriesStore.counters[currentDepartmentId]?.newAccepancesCount;
      },
    },
    {
      name: ShipmentRoute.title,
      icon: <ShipmentIcon />,
      href: ShipmentRoute.baseRoute,
      get count() {
        const currentDepartmentId = UserStore.user ? UserStore.currentDepartment?.id : undefined;

        if (!currentDepartmentId) {
          return;
        }

        return DeliveriesStore.counters[currentDepartmentId]?.newShipmentsCount;
      },
    },
    {
      name: PackingRoute.title,
      href: PackingRoute.baseRoute,
      icon: <AggregationIcon />,
    },
    { name: TransferRoute.title, icon: <TransferIcon />, href: TransferRoute.baseRoute },
    {
      name: CirculationRoute.title,
      icon: <WithdrawalIcon />,
      href: CirculationRoute.baseRoute,
      get count() {
        const currentDepartmentId = UserStore.user ? UserStore.currentDepartment?.id : undefined;

        if (!currentDepartmentId) {
          return;
        }

        return DeliveriesStore.counters[currentDepartmentId]?.newDestructionsCount;
      },
    },
    { name: SettingsRoute.title, icon: <SettingsIcon />, href: SettingsRoute.baseRoute },
  ];
}

export const MenuVM = new MenuVMInner();
