import React, { SyntheticEvent, FC, memo, useState } from "react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { TextGreen, TextGrey } from "styles/shared.styles";
import { CollapseIconWrap, DiscardBlockWrap, NameWrap, ScannerRow } from "../../ScannerSidePage.styles";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { isSgtin, isSscc } from "helpers/codes";
import { Button } from "components";
import UndoIcon from "@skbkontur/react-icons/Undo";
import { AdditionalInfoSgtinTable } from "Common/AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "Common/AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { ArrowChevronDown, ArrowChevronRight } from "@skbkontur/react-icons";
import { TableItemHr } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";

interface UtdScanItemRowProps {
  item: CodeModelExtended;
  index: number;
  additionalInfoVM: AdditionalInfoVM;
  isNew?: boolean;
  allCodesExpanded: boolean;
  onDiscardItem(item: CodeModelExtended): void;
  onDiscardCode(code: string): void;
}

export const UtdScanItemRow: FC<UtdScanItemRowProps> = memo(
  (props: UtdScanItemRowProps) => {
    const { item, index, isNew, additionalInfoVM, allCodesExpanded, onDiscardItem, onDiscardCode } = props;
    const [isExpanded, setExpanded] = useState(allCodesExpanded);
    const hasAdditionalInfo = additionalInfoVM.checkIfGoodHasAdditionalInfo(item);

    const onOpenInfo = () => {
      if (hasAdditionalInfo) {
        setExpanded(v => !v);
      }
    };

    const onDiscard = (e: SyntheticEvent<HTMLElement>) => {
      e.stopPropagation();
      onDiscardItem(item);
    };

    return (
      <>
        <ScannerRow data-tid="UtdScannerRow" isNew={!!isNew} isInfo={hasAdditionalInfo} onClick={onOpenInfo}>
          {hasAdditionalInfo && (
            <CollapseIconWrap>{isExpanded ? <ArrowChevronDown /> : <ArrowChevronRight />}</CollapseIconWrap>
          )}
          <Grid cols={"40px 120px 1fr 180px"} cellsBorderBottom={!isExpanded}>
            <GridCell col={1} row={1}>
              <TextGrey>{index + 1}</TextGrey>
            </GridCell>
            <GridCell data-tid="ScannedItemType" col={2} row={1}>
              <NameWrap bold={isExpanded}>
                <TextGreen>{isSscc(item.code) ? "Агрегат" : "Штучный товар"}</TextGreen>
              </NameWrap>
            </GridCell>
            <GridCell data-tid="ScannedCode" col={3} row={1}>
              <NameWrap bold={isExpanded}>
                <TextGreen>{item.code}</TextGreen>
              </NameWrap>
            </GridCell>
            <GridCell data-tid="DiscardButton" col={4} row={1}>
              <DiscardBlockWrap>
                <Button use={"link"} size={"small"} onClick={onDiscard}>
                  <UndoIcon /> Отменить сканирование
                </Button>
              </DiscardBlockWrap>
            </GridCell>
          </Grid>
        </ScannerRow>
        {isExpanded && hasAdditionalInfo && (
          <>
            <AdditionalInfoSgtinTable
              vm={additionalInfoVM}
              codes={isSgtin(item.code) ? [item] : []}
              onDiscardCode={onDiscardCode}
            />
            <AdditionalInfoSsccTable
              vm={additionalInfoVM}
              codes={isSscc(item.code) ? [item.code] : []}
              onDiscardCode={onDiscardCode}
            />
            <TableItemHr />
          </>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.index === nextProps.index && prevProps.item === nextProps.item && prevProps.isNew === nextProps.isNew
);
