import { DeliveryModel, WithdrawalReason } from "typings/server";
import { action, computed, observable } from "mobx";
import { DeliveriesApi } from "api/DeliveryApi";
import { WithdrawalReasonNames, WithdrawalReasonNamesArr } from "../../WithdrawalDictionary";
import { IType } from "typings/dictionaries";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { BaseEditorModalVM, TBaseErrors } from "../BaseEditorModalVM";

type TErrors = TBaseErrors | "invalidOperationDate" | "reasonRequired";

const WithdrawalNonRequiredReasons: WithdrawalReason | string[] = [
  WithdrawalReason.SelectiveAudit,
  WithdrawalReason.CustomAudit,
  WithdrawalReason.FederalSupervision,
  WithdrawalReason.ClinicalResearch,
  WithdrawalReason.PharmaceuticalExamination,
  WithdrawalReason.Archive,
  WithdrawalReason.QualityAudit,
];

export class WithdrawalEditorModalVM extends BaseEditorModalVM<TErrors> {
  @observable operationDate: string = new Date().toISOString();
  @observable reason: IType;
  private isOperationDateModified: boolean = false;

  @computed
  get reasons() {
    return WithdrawalReasonNamesArr;
  }

  @action.bound
  setOperationDate(date?: string) {
    this.operationDate = date || "";
    this.isOperationDateModified = true;
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @computed
  get isDocumentInfoRequired(): boolean {
    const reason = this.reason && this.reason.value;
    return !(reason && WithdrawalNonRequiredReasons.includes(reason));
  }

  @action.bound
  setReason(reason: IType) {
    this.reason = reason;
    if (this.isSubmitted) this.validate();
  }

  @action
  async save(): Promise<string | void> {
    this.isSubmitted = true;
    if (this.validate()) {
      const model = new DeliveryModel();
      const reportDate =
        this.isOperationDateModified || (this.delivery && this.delivery.reportDate) ? this.operationDate : undefined;

      const data = {
        reportDate, // for editing current delivery
        operationDate: this.isOperationDateModified ? this.operationDate : undefined, // for new withdrawal delivery
        documentDate: this.documentDate || "",
        documentNumber: this.documentNumber,
        withdrawalReason: this.reason.value, // for editing current delivery
        reason: this.reason.value, // for new withdrawal delivery
      };
      Object.assign(model, data);

      if (this.isNew) {
        return await this.createNewOperation(model);
      }

      return await this.patchOperation(model);
    } else {
      return Promise.reject();
    }
  }

  async createNewOperation(model: DeliveryModel): Promise<string> {
    return await DeliveriesApi.addNewWithdrawal(model);
  }

  @action
  async patchOperation(model: DeliveryModel): Promise<void> {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    await this.store.saveDelivery(model, this.delivery!.id);
    this.delivery!.updateDelivery(model);
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (!this.reason) {
      this.errors.set("reasonRequired", "Укажите причину вывода из оборота");
    }
    if (this.operationDate && new Date(this.operationDate) > new Date()) {
      this.errors.set("invalidOperationDate", "Дата не должна быть больше текущей даты и времени");
    }
    if (this.documentDate && new Date(this.documentDate) > new Date()) {
      this.errors.set("invalidDocDate", "Дата не должна быть больше текущей даты и времени");
    }
    if (this.isDocumentInfoRequired && !this.documentDate) {
      this.errors.set("docDateRequired", "Укажите дату документа-основания");
    }
    if (this.isDocumentInfoRequired && !this.documentNumber) {
      this.errors.set("docNumberRequired", "Укажите номер документа-основания");
    }
    return !this.errors.size;
  }

  @action
  setByDelivery(delivery: WithdrawalDeliveryModel) {
    this.operationDate = delivery.reportDate ? new Date(delivery.reportDate).toISOString() : new Date().toISOString();
    if (delivery.withdrawalReason) {
      this.setReason({
        value: delivery.withdrawalReason,
        label: WithdrawalReasonNames[delivery.withdrawalReason],
      });
    }
    if (delivery.documentDate) {
      this.documentDate = new Date(delivery.documentDate).toISOString();
    }
    this.documentNumber = delivery.documentNumber || "";
  }
}
