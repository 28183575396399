import React from "react";
import { observer } from "mobx-react";
import { Link, ScrollContainer, SidePage } from "components";
import { HelpList, HelpListImg, HelpListNum, WarningWrap, WarningIconWrap } from "./HelpSidePage.styles";
import { MDLPCabinetUrl } from "StringResx";
import { Warning } from "@skbkontur/react-icons";

interface IHelpSidePageProps {
  onClose(): void;
}

export const HelpSidePage: React.FC<IHelpSidePageProps> = observer(
  (props: React.PropsWithChildren<IHelpSidePageProps>) => {
    const onClose = () => props.onClose();

    return (
      <SidePage ignoreBackgroundClick width={"500px"} onClose={onClose}>
        <ScrollContainer maxHeight={"100vh"}>{props.children}</ScrollContainer>
      </SidePage>
    );
  }
);

export const RegNumberHelpSidePage = (
  <>
    <SidePage.Header>Как посмотреть регистрационный номер участника в ИС МДЛП</SidePage.Header>
    <SidePage.Body>
      <HelpList>
        <li>
          <HelpListNum>1.</HelpListNum>Войдите в профиль{" "}
          <Link href={MDLPCabinetUrl} target={"_blank"}>
            личного кабинета участника
          </Link>{" "}
          на вкладку
          <br />
          Данные организации
        </li>
        <li>
          <HelpListNum>2.</HelpListNum>Скопируйте регистрационный номер участника
          <br />
          <HelpListImg height={"447px"} imgSrc={"mdlp-reg-num-screen.png"} />
        </li>
      </HelpList>
    </SidePage.Body>
  </>
);

export const SystemHelpSidePage = (
  <>
    <SidePage.Header>Как добавить учетную систему</SidePage.Header>
    <SidePage.Body>
      <HelpList>
        <li>
          <HelpListNum>1.</HelpListNum>Войдите в администрирование&nbsp;
          <Link href={MDLPCabinetUrl} target={"_blank"}>
            личного кабинета участника
          </Link>
          <br />
          на вкладку Учетные системы
        </li>
        <li>
          <HelpListNum>2.</HelpListNum>Добавьте новую учетную систему
          <br />
          <HelpListImg height={"145px"} imgSrc={"mdlp-system-screen-1.png"} />
        </li>
        <li>
          <HelpListNum>3.</HelpListNum>Завершите регистрацию и скопируйте идентификатор клиента и<br /> секретный ключ
          <br />
          <HelpListImg height={"238px"} imgSrc={"mdlp-system-screen-2.png"} />
        </li>
      </HelpList>
    </SidePage.Body>
  </>
);

export const ClientIdHelpSidePage = (
  <>
    <SidePage.Header>Как посмотреть идентификатор клиента</SidePage.Header>
    <SidePage.Body>
      <HelpList>
        <li>
          <HelpListNum>1.</HelpListNum>Войдите в администрирование&nbsp;
          <Link href={MDLPCabinetUrl} target={"_blank"}>
            личного кабинета участника
          </Link>
          <br />
          на вкладку Учетные системы
        </li>
        <li>
          <HelpListNum>2.</HelpListNum>Скопируйте идентификатор клиента из добавленной ранее
          <br /> учетной системы
          <HelpListImg height={"213px"} imgSrc={"mdlp-system-client.png"} />
        </li>
      </HelpList>
    </SidePage.Body>
  </>
);

export const SystemClientSecretSidePage = (
  <>
    <SidePage.Header>Как посмотреть секретный ключ</SidePage.Header>
    <SidePage.Body>
      <HelpList>
        <li>
          <HelpListNum>1.</HelpListNum>Войдите в администрирование&nbsp;
          <Link href={MDLPCabinetUrl} target={"_blank"}>
            личного кабинета участника
          </Link>
          <br />
          на вкладку Учетные системы
        </li>
        <li>
          <HelpListNum>2.</HelpListNum>Нажмите на секретный ключ под звездочками из добавленной
          <br />
          ранее учетной системы
          <br />
          <HelpListImg height={"179px"} imgSrc={"mdlp-system-client-secret-1.png"} />
        </li>
        <li>
          <HelpListNum>3.</HelpListNum>Скопируйте отобразившийся секретный ключ при помощи
          <br />
          кнопки Скопировать
          <br />
          <HelpListImg height={"167px"} imgSrc={"mdlp-system-client-secret-2.png"} />
        </li>
      </HelpList>

      <WarningWrap>
        <WarningIconWrap>
          <Warning />
        </WarningIconWrap>
        Если скопировать без нажатия на звездочки, то
        <br />
        скопируется неверный секретный ключ в виде звездочек
      </WarningWrap>
    </SidePage.Body>
  </>
);
