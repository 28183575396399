import styled from "styles/styled-components";

export const EmptyDeliveries = styled.div`
  margin-top: 10px;
  margin-left: 9px;
`;

export const List = styled.div`
  max-width: 900px;
  margin: 14px 0 0 9px;
`;

interface HelpTextWrapProps {
  isAdmin: boolean;
}

export const HelpTextWrap = styled.div<HelpTextWrapProps>`
  max-width: ${props => (props.isAdmin ? "240px" : "210px")};
`;

export const TabsWrap = styled.div`
  margin-top: 12px;
  margin-left: 2px;
`;

export const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${props => props.theme.colors.orangeDark};
  position: absolute;
  top: 6px;
  right: -10px;
`;
