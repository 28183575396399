import styled from "styles/styled-components";

export const ClientTopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ClientTopBlockSupplier = styled.div`
  width: 50%;
`;

export const ClientInfoBlockLabel = styled.div`
  font-size: 14px;
  letter-spacing: 0.005em;
  color: ${props => props.theme.textColors.lightenGray};
`;

export const ClientInfoBlockTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
  color: ${props => props.theme.textColors.lightBlack};
`;

export const ClientInfoBlockInn = styled.div`
  margin-bottom: 5px;
`;

export const ClientInfoBlockAddress = styled.div`
  line-height: 20px;
`;

export const GoToDetailPageWrapper = styled.div`
  margin-bottom: 10px;
`;

export const TitlePageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitlePageEditWrap = styled.span`
  margin-left: 5px;
`;

export const Unregistered = styled.div`
  color: ${props => props.theme.colors.orangeDark};
`;

export const DownloadBtnWrap = styled.span`
  margin-right: 15px;
`;

export const RequisitesWrap = styled.div`
  margin-top: 5px;
`;
