export const getShipmentStageTitle = (params: {
  isFailedStatus: boolean;
  isPartial: boolean | undefined;
  isRejected: boolean;
  isRecalled: boolean;
  isPartialRejectRecall: boolean;
  isDeliveryAgreed: boolean;
  isSimplifiedAccept: boolean;
}): string => {
  const {
    isFailedStatus,
    isPartial,
    isRecalled,
    isRejected,
    isPartialRejectRecall,
    isDeliveryAgreed,
    isSimplifiedAccept,
  } = params;

  if (isFailedStatus) {
    if (isRecalled) {
      return isPartial ? "Частичная ошибка отзыва отгрузки" : "Ошибка отзыва отгрузки";
    } else {
      return isPartial ? "Частичная ошибка отгрузки товаров" : "Ошибка отгрузки товаров";
    }
  }

  if (isRejected) {
    return isPartialRejectRecall ? "Частичный отказ получателя" : "Отказ получателя";
  }

  if (isRecalled) {
    return isPartialRejectRecall ? "Частичный отзыв отгрузки" : "Отзыв отгрузки";
  }

  // Уведомление о приемке по упрощенной схеме внутри операции отгрузки
  if (isSimplifiedAccept) {
    return isPartial ? "Частичная приемка по упрощенной схеме получателем" : "Приемка по упрощенной схеме получателем";
  }

  if (isDeliveryAgreed) {
    return isPartial ? "Частичное согласование получателем" : "Согласование получателем";
  }

  return isPartial ? "Частичная отгрузка товаров" : "Отгрузка товаров";
};
