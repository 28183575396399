import * as Sentry from "@sentry/react";

export interface SentryExtras {
  [key: string]: any;
}

export type SentryTags = {
  [key: string]: string;
};

interface LogEvent {
  data?: any;
  tags?: SentryTags;
}

interface LogErrorEvent extends LogEvent {
  error: any;
}

interface LogMessageEvent extends LogEvent {
  message: string;
}

interface IUserData {
  id: string;
  username: string;
  email?: string;
  ip_address?: string;
  // additional
  inn: string;
}

export class Logger {
  public static configure(user: Partial<IUserData>) {
    Sentry.withScope(function (scope) {
      scope.setUser(user);
    });
  }

  public static error(event: LogErrorEvent) {
    Logger.reportToSentryWrap(event, scope => {
      const error = event.error;
      if (error?.sentryTags) scope.setTags(error.sentryTags);
      if (error?.sentryExtras) scope.setTags(error.sentryExtras);      
      Sentry.captureException(error);
    });
  }

  public static message(event: LogMessageEvent) {
    Logger.reportToSentryWrap(event, () => {
      Sentry.captureMessage(event.message);
    });
  }

  private static reportToSentryWrap(event: LogEvent, reportToSentry: (scope: Sentry.Scope) => void) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(event);
      return;
    }

    Sentry.withScope(scope => {
      if (event.tags) scope.setTags(event.tags);
      if (event.data) scope.setExtra("data", event.data);
      reportToSentry(scope);
    })
  }
}
