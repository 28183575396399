import React from "react";
import { NewDeliveryModel } from "typings/server";
import {
  DepartmentAddress,
  fileNameMaxW,
  RowWithOffset,
  UtdModalDeliveryIcon,
  UtdModalDeliveryWrap,
} from "Common/UtdModal/UtdModal.styles";
import { DocXmlIcon } from "icons";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { getFormattedDate } from "helpers/date";
import { EllipsisText } from "components/EllipsisText/EllipsisText";
import { TextSecondary } from "styles/shared.styles";

export const getNewShipmentDeliveryInfoBlock = (
  delivery: NewDeliveryModel,
  fileName: string,
  departmentAddress: string
) => {
  return (
    <UtdModalDeliveryWrap>
      <UtdModalDeliveryIcon>
        <DocXmlIcon /> <EllipsisText width={fileNameMaxW}>{fileName}</EllipsisText>
      </UtdModalDeliveryIcon>
      <Grid rows={"repeat(20px 5px)"} cols={"150px 1fr"} paddingCells={"6px 4px"}>
        <GridCell col={1} row={1}>
          <TextSecondary>Отправитель</TextSecondary>
        </GridCell>
        <GridCell col={2} row={1}>
          {delivery.supplier?.name}
        </GridCell>

        <GridCell col={1} row={2}>
          <TextSecondary>Адрес отправителя</TextSecondary>
        </GridCell>
        <GridCell col={2} row={2}>
          <RowWithOffset>{delivery.supplier?.address}</RowWithOffset>
        </GridCell>

        <GridCell col={1} row={3}>
          <TextSecondary>Получатель</TextSecondary>
        </GridCell>
        <GridCell col={2} row={3}>
          {delivery.recipient?.name}
        </GridCell>

        <GridCell col={1} row={4}>
          <TextSecondary>Адрес получателя</TextSecondary>
        </GridCell>
        <GridCell col={2} row={4}>
          <RowWithOffset>{delivery.recipient?.address}</RowWithOffset>
        </GridCell>

        <GridCell col={1} row={5}>
          <TextSecondary>№ документа</TextSecondary>
        </GridCell>
        <GridCell col={2} row={5}>
          {delivery.documentNumber || "—"}
        </GridCell>

        <GridCell col={1} row={6}>
          <TextSecondary>Дата документа</TextSecondary>
        </GridCell>
        <GridCell col={2} row={6}>
          {(delivery.documentDate && getFormattedDate(delivery.documentDate)) || "—"}
        </GridCell>
      </Grid>
      <DepartmentAddress>Отгрузка будет добавлена в подразделение: {departmentAddress}</DepartmentAddress>
    </UtdModalDeliveryWrap>
  );
};
