import React from "react";
import { observer } from "mobx-react";
import { Grid, GridCell, GridCellHeader, GridHeaderWrap } from "components/Grid/Grid.styles";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import { SsccCodeContentBlock } from "features/ScannerSidePage/ScannerSidePage.styles";
import { getFormattedDate } from "helpers";
import { SgtinInfoModel, SsccInfoModel } from "typings/server";
import { Code, CopyIconWrap, SgtinCodeText, SsccCodeText } from "Common/AdditionalInfo/AdditionalInfo.styles";
import { Hint, Toast } from "components";
import { Copy } from "@skbkontur/react-icons";
import { copyToBuffer } from "helpers/copy";

interface ISsccCodeContentProps {
  isOpened: boolean;
  ssccCodeContent?: CodeAdditionalInfoModel;
  sidePage?: boolean;
}

export const SsccCodeContent = observer((props: ISsccCodeContentProps) => {
  const { ssccCodeContent, isOpened, sidePage } = props;

  if (!ssccCodeContent || ssccCodeContent.isError || !isOpened) return null;

  const ssccCols = "2fr 1.7fr 3fr 1.7fr 1.6fr";
  const sgtinCols = `3fr 2fr 2fr ${sidePage ? "" : "1.5fr"} 1fr`;

  const copy = (code?: string) => {
    copyToBuffer(code as string);
    Toast.push("Код скопирован");
  };

  if (ssccCodeContent.containedSgtinsCount && !ssccCodeContent.containedSsccCount)
    return (
      <SsccCodeContentBlock>
        <GridHeaderWrap>
          <Grid cols={sgtinCols} paddingHeaderCells={"6px 4px 6px"}>
            <GridCellHeader col={1} row={1}>
              {sidePage ? "SGTIN" : "Код штучного товара SGTIN"}
            </GridCellHeader>
            <GridCellHeader col={2} row={1}>
              Производственная серия
            </GridCellHeader>
            <GridCellHeader col={3} row={1}>
              Производитель
            </GridCellHeader>
            {!sidePage && (
              <GridCellHeader col={4} row={1}>
                Дата производства
              </GridCellHeader>
            )}
            <GridCellHeader col={sidePage ? 4 : 5} row={1}>
              Cрок годности
            </GridCellHeader>
          </Grid>
        </GridHeaderWrap>

        <Grid cols={sgtinCols}>
          {ssccCodeContent.childCodesInfo &&
            ssccCodeContent.childCodesInfo.map((codeInfo: Partial<SgtinInfoModel>, index) => (
              <React.Fragment key={codeInfo.code}>
                <GridCell col={1} row={index + 2}>
                  <Code onClick={() => copy(codeInfo.code)}>
                    <SgtinCodeText>{codeInfo.code}</SgtinCodeText>
                    <Hint text={"Скопировать"}>
                      <CopyIconWrap>
                        <Copy />
                      </CopyIconWrap>
                    </Hint>
                  </Code>
                </GridCell>
                <GridCell col={2} row={index + 2}>
                  {codeInfo.batch || "—"}
                </GridCell>
                <GridCell col={3} row={index + 2}>
                  {codeInfo.producer || "—"}
                </GridCell>
                {!sidePage && (
                  <GridCell col={4} row={index + 2}>
                    {codeInfo.releaseDate ? getFormattedDate(codeInfo.releaseDate) : "—"}
                  </GridCell>
                )}
                <GridCell col={sidePage ? 4 : 5} row={index + 2}>
                  {codeInfo.expirationDate ? getFormattedDate(codeInfo.expirationDate) : "—"}
                </GridCell>
              </React.Fragment>
            ))}
        </Grid>
      </SsccCodeContentBlock>
    );

  return (
    <SsccCodeContentBlock>
      <GridHeaderWrap>
        <Grid cols={ssccCols} paddingHeaderCells={"6px 4px 6px"}>
          <GridCellHeader col={1} row={1}>
            {sidePage ? "SSCC" : "Код агрегата SSCC"}
          </GridCellHeader>
          <GridCellHeader col={2} row={1}>
            GTIN
          </GridCellHeader>
          <GridCellHeader col={3} row={1}>
            Производитель
          </GridCellHeader>
          {!sidePage && (
            <>
              <GridCellHeader col={4} row={1}>
                Состав, агрегат
              </GridCellHeader>
              <GridCellHeader col={5} row={1}>
                Состав, штучный товар
              </GridCellHeader>
            </>
          )}
        </Grid>
      </GridHeaderWrap>

      <Grid cols={ssccCols}>
        {ssccCodeContent.childCodesInfo &&
          ssccCodeContent.childCodesInfo.map((codeInfo: Partial<SsccInfoModel>, index) => (
            <React.Fragment key={codeInfo.code}>
              <GridCell col={1} row={index + 2}>
                <Code onClick={() => copy(codeInfo.code)}>
                  <SsccCodeText>{codeInfo.code}</SsccCodeText>
                  <Hint text={"Скопировать"}>
                    <CopyIconWrap>
                      <Copy />
                    </CopyIconWrap>
                  </Hint>
                </Code>
              </GridCell>
              <GridCell col={2} row={index + 2}>
                {codeInfo.gtin || "—"}
              </GridCell>
              <GridCell col={3} row={index + 2}>
                {codeInfo.producer || "—"}
              </GridCell>
              {!sidePage && (
                <>
                  <GridCell col={4} row={index + 2}>
                    {codeInfo.containedSsccCount || "—"}
                  </GridCell>
                  <GridCell col={5} row={index + 2}>
                    {codeInfo.containedSgtinsCount || "—"}
                  </GridCell>
                </>
              )}
            </React.Fragment>
          ))}
      </Grid>
    </SsccCodeContentBlock>
  );
});
