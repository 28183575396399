import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";

class DocumentsApiInternal {
  constructor(private httpClient: IHttpClient) {}

  prepareDocuments(deliveryId: string): Promise<string> {
    return this.httpClient.post("", null, { deliveryId });
  }
}

export const DocumentsApi = new DocumentsApiInternal(getHttpClient(drugsApi + "/documents"));
