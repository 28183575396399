import { action, computed, observable, toJS } from "mobx";
import { getFormattedDate, longDateTimeOptions } from "helpers/date";
import { ISettingsStore } from "stores/SettingsStore";
import { IUserStore } from "stores/UserStore";
import {
  DepartmentModel,
  OrganizationModel,
  RegistratorEditModel,
  RegistratorSettingsModel,
  RequisitesModel,
} from "typings/server";
import { MDLP_AUTH_REQUIRED_STATUS, MDLP_ERROR_STATUS, NOT_FOUND_STATUS } from "api/shared";
import { ICertificate } from "@skbkontur/plugin-js";
import { ISignatureStore } from "stores/SignatureStore";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";
import { checkSecretKey } from "helpers/requisites";
import { RegistratorsApi } from "api/RegistratorsApi";
import { Logger } from "helpers/logger";
import { store } from "react-notifications-component";
import { errorNotification } from "components/ErrorBoundary/shared";
import { cloneDeep } from "lodash-es";

type TSettingsPageErrors = "sync" | "auth" | "wrongSecretKey";
type TSettingsPageLoaders = "sync" | "page" | "auth";
type TSettingsPageModals =
  | "mdlpModal"
  | "deleteEmployee"
  | "employeeEditor"
  | "help"
  | "deleteRegistrator"
  | "registratorEditor";
type TSettingsHelp = "regNumber" | "system" | "client" | "clientSecret";

const CLIENT_SECRET_CONST = "<censored>";

export class SettingsPageVM {
  private static instance: SettingsPageVM;
  @observable errors: Map<TSettingsPageErrors, string> = new Map();
  @observable loaders: Map<TSettingsPageLoaders, boolean> = new Map();
  @observable modals: Map<TSettingsPageModals, boolean> = new Map();
  @observable selectedEmployee?: EmployeeModelExtended;
  @observable selectedRegistrator?: RegistratorSettingsModel;
  @observable activeHelpType: TSettingsHelp;
  @observable requisites: RequisitesModel = { memberId: "", clientId: "", secret: "" };
  @observable certificate?: ICertificate;
  @observable showSecret: boolean = false;
  @observable showRegistratorEmployeesMap: Map<string, boolean> = new Map();

  constructor(
    private readonly settingsStore: ISettingsStore,
    private readonly userStore: IUserStore,
    private readonly signatureStore: ISignatureStore
  ) {
    this.load();
  }

  // singleton, react-routing create new SettingsIndexPage every time when tab changes
  public static getInstance(
    settingsStore: ISettingsStore,
    userStore: IUserStore,
    signatureStore: ISignatureStore
  ): SettingsPageVM {
    if (!SettingsPageVM.instance) {
      SettingsPageVM.instance = new SettingsPageVM(settingsStore, userStore, signatureStore);
    }

    return SettingsPageVM.instance;
  }

  async load() {
    this.loaders.set("page", true);
    try {
      await this.getDepartments();
      await this.loadEmployees();
      await this.settingsStore.getRequisites();
      await this.getRegistrators();
      this.requisites = this.settingsStore.requisites;
    } finally {
      this.loaders.set("page", false);
    }
  }

  @action
  async getRegistrators(): Promise<void> {
    await this.settingsStore.getRegistrators();
  }

  @action
  async getDepartments(): Promise<void> {
    await this.settingsStore.getDepartments();
  }

  @action
  async loadEmployees(): Promise<void> {
    await this.settingsStore.getEmployees();
  }

  @action
  async synchronizeDepartments(): Promise<void> {
    this.loaders.set("sync", true);
    this.errors.delete("sync");
    try {
      await this.settingsStore.synchronizeDepartments();
    } catch (e: any) {
      if (e && e.status === MDLP_AUTH_REQUIRED_STATUS) {
        this.toggleModal("mdlpModal");
      } else if (e && e.status === MDLP_ERROR_STATUS) {
        const mdlpMsg = e && e.jsonMsg && e.jsonMsg.description;
        this.errors.set("sync", `Не удалось синхронизироваться с ИС МДЛП\n\r${mdlpMsg || ""}`);
      } else {
        throw e;
      }
    } finally {
      this.loaders.set("sync", false);
    }
  }

  @action
  toggleModal(name: TSettingsPageModals) {
    this.modals.set(name, !this.modals.get(name));
  }

  @action
  onChangeMemberId = (val: string) => {
    const trimmed = val.trim();
    this.requisites = { ...this.requisites, memberId: trimmed };
  };

  @action
  onChangeClientId = (val: string) => {
    const trimmed = val.trim();
    this.requisites = { ...this.requisites, clientId: trimmed };
  };

  @action
  onChangeSecretKey = (val: string) => {
    const trimmed = val.trim();
    this.requisites = { ...this.requisites, secret: trimmed };
    const getWrongSymbol = checkSecretKey(trimmed);
    if (getWrongSymbol) {
      this.errors.set("wrongSecretKey", `Секретный ключ не должен содержать символ ${getWrongSymbol}`);
    } else {
      this.errors.delete("wrongSecretKey");
    }
  };

  @action
  setHelpType(type: TSettingsHelp) {
    this.activeHelpType = type;
  }

  @action
  async onSaveRequisites() {
    const requisites = toJS({ ...this.requisites });
    await this.settingsStore.saveRequisites(requisites);
  }

  @action
  onSaveRegistratorEditor = async (editModel: RegistratorEditModel, isNew: boolean) => {
    if (isNew) {
      await this.createNewRegistrator(editModel);
    } else {
      await this.updateRegistrator(editModel);
    }
    await this.settingsStore.getRegistrators();
    this.toggleModal("registratorEditor");
  };

  @action
  createNewRegistrator = async (editModel: RegistratorEditModel) => {
    const deviceInfo = await RegistratorsApi.getDeviceInfo(editModel);
    const device = deviceInfo.devices[0];
    await RegistratorsApi.addNewRegistrator({
      ...editModel,
      model: device.modelInfo,
      serialNumber: device.deviceSerialNumber,
      name: editModel.name || device.modelInfo,
    });
  };

  @action
  updateRegistrator = async (editModel: RegistratorEditModel) => {
    const selectedRegistrator = this.selectedRegistrator as RegistratorSettingsModel;
    const { ipAddress, login, password, id } = selectedRegistrator;
    const isShouldGetDeviceInfo =
      ipAddress !== editModel.ipAddress || login !== editModel.login || password !== editModel.password;
    const deviceInfo = isShouldGetDeviceInfo ? await RegistratorsApi.getDeviceInfo(editModel) : null;
    const device = deviceInfo?.devices[0];
    await RegistratorsApi.editRegistrator(id, {
      ...editModel,
      name: (editModel.name || device?.modelInfo) ?? editModel.model,
      model: device?.modelInfo ?? editModel.model,
      serialNumber: device?.deviceSerialNumber ?? editModel.serialNumber,
    });
  };

  @action
  async onDiscard() {
    this.requisites = this.settingsStore.requisites;
  }

  @action
  setSelectedEmployee(employee?: EmployeeModelExtended) {
    this.selectedEmployee = employee;
  }

  @action
  setSelectedRegistrator(registrator?: RegistratorSettingsModel) {
    this.selectedRegistrator = registrator;
  }

  @action
  setCertificate = (cert?: ICertificate) => {
    this.certificate = cert;
  };

  @action
  toggleRegistratorEmployees = (registratorId: string) => {
    this.showRegistratorEmployeesMap.set(registratorId, !this.showRegistratorEmployeesMap.get(registratorId));
  };

  async onSing(cert: ICertificate) {
    await this.signatureStore.signCode(cert);
  }

  @action
  async auth(cert: ICertificate) {
    this.loaders.set("auth", true);
    this.errors.delete("auth");
    try {
      try {
        await this.onSing(cert);
      } catch (e: any) {
        const jsonMsg = e.jsonMsg || {};
        const msg = jsonMsg.description || "Не удалось авторизоваться в ИС МДЛП";
        this.errors.set("auth", msg);
        this.setCertificate(undefined);
        Logger.error({ error: e });
        return Promise.reject();
      }
      await this.synchronizeDepartments();
      await this.getDepartments();
    } finally {
      this.loaders.set("auth", false);
    }
  }

  @action
  toggleSecret = () => {
    this.showSecret = !this.showSecret;
  };

  @action
  async deleteEmployee() {
    if (this.selectedEmployee?.id) {
      await this.settingsStore.deleteEmployee(this.selectedEmployee?.id);
      this.setSelectedEmployee(undefined);
    }
  }

  @action
  async refreshEmployeeFio(portalId: string) {
    try {
      await this.settingsStore.refreshEmployeeFio(portalId);
    } catch (e: any) {
      if (e && e.status === NOT_FOUND_STATUS) {
        const errorNotificationToast = cloneDeep(errorNotification);
        errorNotificationToast.dismiss = { ...errorNotification.dismiss, duration: 5000 };

        store.addNotification({
          ...errorNotificationToast,
          message: "Пользователь не найден в портале",
          title: "Ошибка",
        });
      } else {
        throw e;
      }
    }
  }

  @action
  async deleteRegistrator() {
    if (this.selectedRegistrator?.id) {
      await this.settingsStore.deleteRegistrator(this.selectedRegistrator?.id);
      this.setSelectedEmployee(undefined);
      this.toggleModal("deleteRegistrator");
    }
  }

  @action
  async changeOrganization(organization: OrganizationModel) {
    await this.userStore.changeOrganizationById(organization.id);
  }

  @computed
  get departmentsSyncTime(): string {
    return this.settingsStore.departmentsSyncInfo
      ? getFormattedDate(this.settingsStore.departmentsSyncInfo, longDateTimeOptions)
      : "";
  }

  @computed
  get departments(): DepartmentModel[] {
    return this.settingsStore.allDepartments;
  }

  @computed
  get organization(): OrganizationModel | undefined {
    return this.userStore.organization;
  }

  @computed
  get availableOrganizations() {
    return this.userStore.user.availableOrganizations;
  }

  @computed
  get hasAccess(): boolean {
    return this.userStore.isAdmin;
  }

  @computed
  get employees(): EmployeeModelExtended[] {
    return this.settingsStore.employees;
  }

  @computed
  get registrators(): RegistratorSettingsModel[] {
    return this.settingsStore.registrators;
  }

  @computed
  get isDisabledBtn(): boolean {
    return (
      !(this.requisites.memberId && this.requisites.clientId && this.requisites.secret) ||
      this.errors.has("wrongSecretKey")
    );
  }

  @computed
  get showEyeIcon(): boolean {
    return this.requisites.secret !== CLIENT_SECRET_CONST;
  }

  getDepartmentByID(id: string) {
    const department = this.departments.find(x => x.id === id);
    return department ? department.address : undefined;
  }
}
