import React, { FC, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock, ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage, DestructionStageNames } from "Common/DeliveryDictionary";
import { CirculationPageVM } from "../CirculationPageVM";
import { PageInfoBlock } from "../Common/PageInfoBlock/PageInfoBlock";
import { OperationsStatusNames } from "Common/Status/StatusesName";
import { DateAndUser } from "Common/Stages/DateAndUser";
import { DestructionInfoBlock } from "./destructionInfoBlock";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";

interface IStagesProps {
  vm: CirculationPageVM;
}

export const DestructionStages: FC<IStagesProps> = observer((props: IStagesProps) => {
  const { vm } = props;
  const [isDestructionStageVisible, setIsDestructionStageVisible] = useState(false);

  const getDocumentSendingStatus = (delivery: WithdrawalDeliveryModel) => {
    const signingProgress = delivery.stages[DeliveryStage.Signing].progress;
    const sendingProgress = delivery.stages[DeliveryStage.Sending].progress;

    if (signingProgress === ProgressStage.InProgress || sendingProgress === ProgressStage.InProgress) {
      return ProgressStage.InProgress;
    }

    if (signingProgress === ProgressStage.Done && sendingProgress === ProgressStage.Done) {
      return ProgressStage.Done;
    }

    return ProgressStage.Planned;
  };

  const getDocumentSendingTitleSize = (delivery: WithdrawalDeliveryModel) => {
    let activeStage: DeliveryStage;

    const signingProgress = delivery.stages[DeliveryStage.Signing].progress;
    const sendingProgress = delivery.stages[DeliveryStage.Sending].progress;

    if (signingProgress === ProgressStage.InProgress) {
      activeStage = DeliveryStage.Signing;
    } else if (sendingProgress === ProgressStage.InProgress) {
      activeStage = DeliveryStage.Sending;
    } else {
      activeStage = DeliveryStage.Signing;
    }
    return vm.getTitleSize(delivery.stages[activeStage]);
  };

  const toggleDestructionStageVisible = () => {
    setIsDestructionStageVisible(v => !v);
  };

  const onCollapse = () => vm.onCollapseDelivery(vm.delivery.id);

  const childFaliedDelivery = vm.delivery?.childDeliveries.filter(
    delivery => delivery.status === DeliveryStatus.Failed
  )[0];
  const childNotFailedDelivery = vm.delivery?.childDeliveries.filter(
    delivery => delivery.status !== DeliveryStatus.Failed
  )[0];

  const firstDocumentSendingStatus = getDocumentSendingStatus(vm.delivery);
  const firstDocumentSendingTitleSize = getDocumentSendingTitleSize(vm.delivery);

  useEffect(() => {
    // Если delivery переходит в Processing, стадия должна быть развернута
    // Например, при возврате в состояние черновика.
    if (vm.delivery.status === DeliveryStatus.Processing) {
      vm.onCollapseDelivery(vm.delivery.id, true);
    }
  }, [vm.delivery.status]);

  return (
    <DeliveryStagesBlock>
      <DeliveryStatusBlock
        data-tid="ProcessingStage"
        size={vm.getTitleSize(vm.stages[DeliveryStage.Processing])}
        name={vm.stages[DeliveryStage.Processing].name}
        status={vm.stages[DeliveryStage.Processing].progress}
        collapsed={!vm.isDeliveryOpenedState.get(vm.delivery.id)}
        alwaysVisibleComp={
          <DateAndUser
            delivery={vm.delivery}
            getCompletionUser={vm.getCompletionUser}
            getCompletionDate={vm.getCompletionDate}
            step={DeliveryStatus.Processing}
          />
        }
        onCollapse={onCollapse}
      >
        <PageInfoBlock vm={vm} />
      </DeliveryStatusBlock>

      <DeliveryStatusBlock
        data-tid="SendingStage"
        status={firstDocumentSendingStatus}
        size={firstDocumentSendingTitleSize}
        name={vm.getSendingStepName(
          vm.stages[DeliveryStage.Sending],
          !childNotFailedDelivery && !childFaliedDelivery ? vm.delivery.status : undefined
        )}
        error={
          childFaliedDelivery
            ? childFaliedDelivery.stages[DeliveryStage.Sending].errorInfo
            : vm.stages[DeliveryStage.Sending].errorInfo
        }
        alwaysVisibleComp={
          <>
            {vm.delivery.status === DeliveryStatus.CreatingDocument && !vm.delivery.childDeliveries.length && (
              <Spinner type="mini" caption={OperationsStatusNames[DeliveryStatus.CreatingDocument]} />
            )}
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.Sending}
            />
          </>
        }
      />
      {!vm.showCancelDestructionStage && (
        <DeliveryStatusBlock
          data-tid="DestructionStage"
          // На бэке реалзовано через WaitingForCounterparty, так как идеологически - похожая история
          status={vm.stages[DeliveryStage.WaitingForCounterparty].progress}
          name="Уничтожение"
          collapsed={!isDestructionStageVisible}
          onCollapse={
            vm.stages[DeliveryStage.WaitingForCounterparty].progress !== ProgressStage.Done
              ? undefined
              : toggleDestructionStageVisible
          }
          size={vm.getTitleSize(vm.stages[DeliveryStage.WaitingForCounterparty])}
          error={vm.stages[DeliveryStage.WaitingForCounterparty].errorInfo}
          alwaysVisibleComp={
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.WaitingForCounterparty}
            />
          }
        >
          <DestructionInfoBlock vm={vm} />
        </DeliveryStatusBlock>
      )}
      {vm.showCancelDestructionStage && (
        <DeliveryStatusBlock
          data-tid="CancelDestructionStage"
          status={vm.stages[DeliveryStage.WaitingForCounterparty].progress}
          name="Отмена передачи на уничтожение"
          isRecalledByClient
          size={vm.getTitleSize(vm.stages[DeliveryStage.WaitingForCounterparty])}
          error={vm.stages[DeliveryStage.WaitingForCounterparty].errorInfo}
          alwaysVisibleComp={
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.WaitingForCounterparty}
            />
          }
        />
      )}
      <DeliveryStatusBlock
        data-tid="SendingStage"
        status={
          vm.isSecondDocumentSending
            ? getDocumentSendingStatus(vm.delivery)
            : childNotFailedDelivery
            ? getDocumentSendingStatus(childNotFailedDelivery)
            : ProgressStage.Planned
        }
        name={vm.getSendingStepName(
          childNotFailedDelivery ? childNotFailedDelivery.stages[DeliveryStage.Sending] : undefined,
          childNotFailedDelivery ? childNotFailedDelivery.status : undefined
        )}
        size={childNotFailedDelivery ? getDocumentSendingTitleSize(childNotFailedDelivery) : "medium"}
        alwaysVisibleComp={
          <>
            {vm.isSecondDocumentSending && (
              <Spinner type="mini" caption={OperationsStatusNames[DeliveryStatus.CreatingDocument]} />
            )}
            {childNotFailedDelivery && (
              <DateAndUser
                delivery={childNotFailedDelivery}
                getCompletionUser={vm.getCompletionUser}
                getCompletionDate={vm.getCompletionDate}
                step={DeliveryStatus.Sending}
              />
            )}
          </>
        }
      />

      <DeliveryStatusBlock
        data-tid="DoneStage"
        status={vm.stages[DeliveryStage.Done].progress}
        name={
          vm.delivery.status === DeliveryStatus.Recalled
            ? DestructionStageNames[DeliveryStatus.Recalled]
            : vm.stages[DeliveryStage.Done].name
        }
        error={vm.stages[DeliveryStage.Done].errorInfo}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
      />
    </DeliveryStagesBlock>
  );
});
