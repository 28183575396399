import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { CounteragentModel, DepartmentModel } from "typings/server";
import { getHttpClient } from "./shared";

interface ICounteragentApiInternal {
  findDepartments(inn: string): Promise<DepartmentModel[]>;
}

class CounteragentApiInternal implements ICounteragentApiInternal {
  constructor(private httpClient: IHttpClient) {}

  findDepartments(inn: string): Promise<DepartmentModel[]> {
    return this.httpClient.post("findDepartments", null, { inn });
  }

  findCounteragents(inn: string): Promise<CounteragentModel> {
    return this.httpClient.post("find", null, { inn });
  }
}

export const CounteragentApi = new CounteragentApiInternal(getHttpClient(drugsApi + "/counteragents"));
