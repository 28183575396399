import {
  loadSupportCenter,
  ISupportCenter,
  SupportCenterEnvironment,
  SupportCenterAdditionalParameters,
} from '@skbkontur/support-center';

type Environment = Extract<SupportCenterEnvironment, 'staging' | 'production'>;

const supportCenterSrc: Record<Environment, string> = {
  staging: 'https://tp.testkontur.ru/support/support_center.js',
  production: 'https://tp.kontur.ru/support/support_center.js',
}

function getSupportCenterEnvironment(hostName: string): Environment {
  return hostName.includes('testkontur') ? 'staging' : 'production';
}

class SupportCenterInner {
  private sc: ISupportCenter | undefined;
  private readonly initPromise: Promise<void>;

  constructor() {
    const environment = getSupportCenterEnvironment(window.location.hostname);
    const src = supportCenterSrc[environment];

    this.initPromise = loadSupportCenter(src).then(({ SupportCenterConstructor }) => {
      if (!SupportCenterConstructor) {
        return;
      }

      this.sc = new SupportCenterConstructor({
        environment,
        integrationId: 'farma',
      });
      this.sc.initialize();
    });
  }

  open = async (): Promise<void> => {
    await this.initPromise;
    if (!this.sc) return;

    await this.sc.open();
  };

  async close(): Promise<void> {
    await this.initPromise;
    if (!this.sc) return;

    await this.sc.close();
  }

  setSupportParams = async (inn: string, orgName: string) => {
    const params: SupportCenterAdditionalParameters = { "base/inn": inn, "base/orgname": orgName };
    await this.initPromise;
    if (!this.sc) return;

    await this.sc.setAdditionalParameters(params);
  };
}

export const SupportCenter = new SupportCenterInner();
