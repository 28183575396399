import styled from "styles/styled-components";
import { RecalledStatusIcon } from "icons";

interface IWrapProps {
  marginTop?: string;
}

export const Wrap = styled.div<IWrapProps>`
  min-width: 450px;
  display: flex;
  margin-top: ${props => props.marginTop};
`;

export const IconWrap = styled.div`
  position: relative;
  top: 3px;
  width: 20px;
`;

export const Unscanned = styled.div`
  color: ${props => props.theme.textColors.lightGray2};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 7px;
`;

export const TextWrap = styled.div`
  margin-left: 15px;
`;

export const ErrorModalText = styled.div`
  font-size: 16px;
  line-height: 25px;
  max-width: 650px;
`;

export const PartiallyRejectedStatusIcon = styled(RecalledStatusIcon)`
  path {
    fill: ${props => props.theme.textColors.warningText};
  }
`;
