import { action, computed, observable } from "mobx";
import { ISignatureStore } from "stores/SignatureStore";
import {
  DeliveryStatus,
  DeliveryType,
  DestructionModel,
  ErrorInfoModel,
  PutDestructionModel,
  StepInfoModel,
  DeliveryItemStatus,
} from "typings/server";
import { ICertificate } from "@skbkontur/plugin-js";
import { DeliveryActions, DetailPageLoadingName, DetailPageModalNames } from "Common/DeliveryActions";
import { IStage } from "models/Delivery/shared";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { IUserStore } from "stores/UserStore";
import { IWithdrawalStore } from "stores/WithdrawalStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { RegistratorsApi } from "api/RegistratorsApi";
import { Logger, Polling } from "helpers";
import { isUuidv4 } from "helpers/uuid";
import { DisposalApi } from "api/DisposalApi";
import { clone } from "lodash-es";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import {
  ReturnReasonNames,
  WithdrawalCauseInReentryNames,
  WithdrawalReasonNames,
} from "features/Circulation/WithdrawalDictionary";
import { DestructionApi } from "api/DestructionApi";
import { RegistratorDisposeTaskError } from "src/errors/RegistratorDisposeTaskError";

// TODO: создать подкласс DisposalWithRegistratorPageVM и DestructionPageVM
export class CirculationPageVM {
  @observable isDeliveryOpenedState: Map<string, boolean> = new Map();
  @observable additionalInfo: CodesAdditionalInfo;
  @observable registratorError: Error | null;
  @observable destruction: DestructionModel | undefined;
  @observable showErrors: boolean;

  // Не очень хороший костыль - этот флаг проставляется в true,
  // когда кидаем запрос на отправку второго документа.
  @observable isSecondDocumentSending: boolean = false;

  private pollStatus: Map<string, Polling> = new Map();
  private actions: DeliveryActions;

  constructor(
    private readonly withdrawalStore: IWithdrawalStore,
    documentStore: ISignatureStore,
    readonly userStore: IUserStore,
    readonly type: DeliveryType
  ) {
    this.actions = new DeliveryActions(withdrawalStore, documentStore, userStore, type);

    if (this.withdrawalStore.selectedDelivery.status === DeliveryStatus.CreatingDocument) {
      this.actions.startPolling(this.delivery.id);
    }

    if (this.activeDelivery && this.activeDelivery.isActive) {
      this.isDeliveryOpenedState.set(this.activeDelivery.id, true);
    }

    this.additionalInfo = new CodesAdditionalInfo(this.delivery.id, this.delivery.allItemsCodes);

    withdrawalStore.getAvailableRegistrators();

    if (this.delivery.registratorTaskId && this.pollingStatusAvailable) {
      this.startPolling(this.delivery.registratorTaskId);
    }
  }

  toggleModal(modalName: DetailPageModalNames) {
    return this.actions.toggleModal(modalName);
  }

  toggleLoading(loadingName: DetailPageLoadingName) {
    return this.actions.toggleLoading(loadingName);
  }

  @action
  onCollapseDelivery(id: string, val?: boolean) {
    const isOpened = val || !this.isDeliveryOpenedState.get(id);
    this.isDeliveryOpenedState.set(id, isOpened);
  }

  @action
  changeType(type: DeliveryType) {
    this.withdrawalStore.changeType(type);
  }

  @action
  save = async () => {
    if (this.isDisposalWithRegistrator) {
      this.toggleLoading("creatingDocument");
      await this.sendCodesToDisposal();
    } else if (this.delivery.type === DeliveryType.Destruction && this.destruction) {
      if (!this.destruction.destructionCause) {
        this.showErrors = true;
        return;
      }
      // Актуализируем destruction
      await DestructionApi.getSendingToDestruction(this.withdrawalStore.selectedDelivery.id).then(destruction => {
        this.setDestruction({
          ...destruction,
          rznDecision: this.destruction?.rznDecision,
          destructionCause: this.destruction?.destructionCause,
          destructionReason: this.destruction?.destructionReason,
        });
      });
      this.showErrors = false;
      this.toggleLoading("creatingDocument");
      const model = new PutDestructionModel();
      const data = {
        ...this.destruction.recipient,
        address: this.destruction.recipient?.address,
        addressFias: this.destruction.recipient?.addressFias,
        actDate: this.destruction.actDate,
        documentDate: this.destruction.documentDate,
        rznDecision: this.destruction.rznDecision,
        documentNumber: this.destruction.documentNumber,
        actNumber: this.destruction.actNumber,
        destructionCause: this.destruction.destructionCause,
        destructionReason: this.destruction.destructionReason,
      };
      Object.assign(model, data);
      await this.actions.save(this.delivery, model);
    } else {
      this.toggleLoading("creatingDocument");
      await this.actions.save(this.delivery);
    }
  };

  @action
  sendCodesToDisposal = async () => {
    const registrator = this.registrators.find(x => x.id === this.delivery.registratorId);
    if (registrator) {
      this.registratorError = null;
      try {
        const body = await DisposalApi.generateRequest(this.delivery.id);
        const rvRequestId = await RegistratorsApi.queueUpDisposal(registrator, body);
        if (isUuidv4(rvRequestId)) {
          await DisposalApi.updateStatus(this.delivery.id);
          this.delivery.setRegistratorTaskId(rvRequestId);
          this.delivery.setNextStage(
            DeliveryStatus.CreatingDocument,
            DeliveryStage.Processing,
            {
              completionDate: new Date().toISOString(),
            },
            this.userStore.fullName
          );
          this.toggleLoading("creatingDocument");
          this.startPolling(rvRequestId);
        }
      } catch (err: any) {
        this.registratorError = err;
      }
    } else {
      this.toggleLoading("creatingDocument");
      this.registratorError = new Error("Выбранный регистратор недоступен. Выберите один из доступных");
    }
  };

  @action
  saveItems = async () => {
    await this.withdrawalStore.saveItems(this.delivery.items, this.delivery.id, this.isDisposalWithRegistrator);
  };

  @action
  sign = async (cert: ICertificate) => {
    // Для уничтожения, когда есть чайлды - нужно подписывать именно их
    if (this.delivery.type === DeliveryType.Destruction && !!this.delivery.childDeliveries.length) {
      const deliveries = this.delivery.childDeliveries.filter(item => item.status === DeliveryStatus.Signing);
      await this.actions.sign(cert, deliveries);
      await DestructionApi.getSendingToDestruction(this.withdrawalStore.selectedDelivery.id).then(destruction => {
        this.setDestruction(destruction);
      });
    } else {
      await this.actions.sign(cert, [this.delivery]);
    }
  };

  @action
  setDestruction(destruction: DestructionModel | undefined) {
    if (destruction) {
      this.destruction = destruction;
    }
  }

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.withdrawalStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.withdrawalStore.getItem(this.delivery.id, { force: true });
    this.withdrawalStore.setSelected(item);
    this.toggleLoading("rollback");
  }

  @action
  async deleteDelivery() {
    this.toggleLoading("delete");
    await this.withdrawalStore.deleteDelivery(this.delivery.id);
    this.toggleLoading("delete");
  }

  @action
  prepareDocuments = async () => {
    this.toggleLoading("download");
    try {
      const taskId = await this.withdrawalStore.prepareDocuments(this.delivery.id);
      this.actions.startPolling(taskId, "download");
    } catch (e) {
      this.toggleLoading("download");
      throw e;
    }
  };

  @action
  updateWaitingForCounterpartyModel = async () => {
    // Обновляем модель
    this.actions.updateStatus(
      DeliveryStatus.WaitingForCounterparty,
      DeliveryStage.WaitingForCounterparty,
      this.delivery
    );
    this.actions.startPolling(this.delivery.id);
  };

  @action
  onSendingSecondDocument = (v: boolean) => {
    this.isSecondDocumentSending = v;
  };

  handleUnmount() {
    this.pollStatus.forEach(val => {
      val.stop();
    });
    this.pollStatus.clear();
    return this.actions.handleUnmount();
  }

  @computed
  get stages(): Record<DeliveryStage | string, IStage> {
    return this.delivery.stages;
  }

  @computed
  get hasDocuments() {
    return this.delivery.hasDocuments;
  }

  // поставка в которой надо совершить действие
  @computed
  get activeDelivery() {
    return this.delivery.childDeliveries.find(child => child.isActive) || this.delivery;
  }

  // группируем все успешные child в один, а ошибочные не трогаем
  @computed
  get groupedMdlpChildDeliveries() {
    const chieldsWithErrors = this.delivery.childDeliveries.filter(
      x =>
        (x.status === DeliveryStatus.Failed || x.status === DeliveryStatus.PartiallyFailed) &&
        this.isMdlpProcessingStep(x)
    );
    const chieldsWithSuccess = this.delivery.childDeliveries.filter(
      x => x.status === DeliveryStatus.Success && this.isMdlpProcessingStep(x)
    );

    let grouped: WithdrawalDeliveryModel[] = [];
    if (chieldsWithSuccess.length) {
      const childWithSuccessClone = clone(chieldsWithSuccess[0]);
      const aggregatedSuccessChild = chieldsWithSuccess.reduce((sum, curr) => {
        if (curr.id === childWithSuccessClone.id) {
          return sum;
        }
        const items = sum.items.concat(curr.items);
        sum.items = items;
        return sum;
      }, childWithSuccessClone);

      grouped = [...chieldsWithErrors, aggregatedSuccessChild];
    } else {
      grouped = chieldsWithErrors;
    }
    return grouped;
  }

  @computed
  get processingErrorChildDelivery() {
    return this.delivery.childDeliveries.find(
      x =>
        x.status === DeliveryStatus.Failed &&
        x.stepsInfo[DeliveryStatus.Processing] &&
        x.stepsInfo[DeliveryStatus.Processing].completionDate
    );
  }

  @computed
  get showCancelDestructionStage() {
    if (this.withdrawalStore.selectedDelivery.status === DeliveryStatus.Recalled) {
      return true;
    }
    // Лютый костыль, чтобы понять, что операция была передана на отмену, но еще не отменена.
    // Думали, костылять на бэке или на фронте - реультат ниже.
    const hasDeliveryBeenCanceled = !!this.withdrawalStore.selectedDelivery.items.filter(
      delivery => delivery.status === DeliveryItemStatus.Recalled
    ).length;
    return hasDeliveryBeenCanceled;
  }

  @computed
  get delivery() {
    return this.withdrawalStore.selectedDelivery;
  }

  @computed
  get modalMap() {
    return this.actions.modalMap;
  }

  @computed
  get loadingMap() {
    return this.actions.loadingMap;
  }

  @computed
  get showSignBtn(): boolean {
    const { status, type, childDeliveries } = this.withdrawalStore.selectedDelivery;
    if (type === DeliveryType.Destruction) {
      if (!childDeliveries.length) {
        return status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
      } else {
        return childDeliveries.some(
          delivery => delivery.status === DeliveryStatus.Signing || delivery.status === DeliveryStatus.Signed
        );
      }
    }
    return status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
  }

  @computed
  get showSaveBtn(): boolean {
    const { status } = this.withdrawalStore.selectedDelivery;
    return status === DeliveryStatus.Processing || status === DeliveryStatus.New;
  }

  @computed
  get showRollbackBtn(): boolean {
    const { status, type, childDeliveries } = this.withdrawalStore.selectedDelivery;
    if (type === DeliveryType.Destruction && !!childDeliveries.length) {
      return false;
    }
    if (type === DeliveryType.DisposalWithRegistrator) {
      return status === DeliveryStatus.Failed;
    }
    return status === DeliveryStatus.Failed || status === DeliveryStatus.Signing;
  }

  @computed
  get showDestructionButton(): boolean {
    // Для типа операций "Уничтожение", статус WaitingForCounterparty означает непосредственно "Уничтожение", так сделано на бэке
    return (
      this.withdrawalStore.selectedDelivery.status === DeliveryStatus.WaitingForCounterparty &&
      this.withdrawalStore.selectedDelivery.type === DeliveryType.Destruction
    );
  }

  @computed
  get rollbackHintText(): string {
    const { status } = this.withdrawalStore.selectedDelivery;
    return status === DeliveryStatus.Signing
      ? "Для редактирования вывода из оборота"
      : "Для редактирования и повторного вывода из оборота";
  }

  @computed
  get shouldOpenRollbackModal(): boolean {
    const { status } = this.withdrawalStore.selectedDelivery;
    return status === DeliveryStatus.Failed;
  }

  @computed
  get showDeleteBtn(): boolean {
    const {
      selectedDelivery: { status },
      isCanBeDeleted,
    } = this.withdrawalStore;
    return isCanBeDeleted(status) || status === DeliveryStatus.Failed;
  }

  @computed
  get showFooter(): boolean {
    return (
      this.showSignBtn || this.showSaveBtn || this.showRollbackBtn || this.showDeleteBtn || this.showDestructionButton
    );
  }

  @computed
  get showEditor(): boolean {
    const { status } = this.delivery;
    return status === DeliveryStatus.New || status === DeliveryStatus.Processing || status === DeliveryStatus.Draft;
  }

  @computed
  get isEditable(): boolean {
    return this.delivery.status === DeliveryStatus.New || this.delivery.status === DeliveryStatus.Processing;
  }

  @computed
  get isFailedStatus() {
    const { status } = this.withdrawalStore.selectedDelivery;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.RecallFailed;
  }

  @computed
  get isDisposal() {
    return this.type === DeliveryType.Disposal;
  }

  @computed
  get isDisposalWithRegistrator() {
    return this.delivery.type === DeliveryType.DisposalWithRegistrator;
  }

  @computed
  get returnName(): string {
    if (this.delivery.type !== DeliveryType.Reentry) {
      return "";
    }

    return this.delivery.returnReason ? ReturnReasonNames[this.delivery.returnReason] : "";
  }

  @computed
  get reasonName(): string {
    if (this.delivery.type === DeliveryType.Reentry) {
      return this.delivery.withdrawalCause ? WithdrawalCauseInReentryNames[this.delivery.withdrawalCause] : "";
    }

    if (this.isDisposal) {
      return "Для оказания медицинской помощи при неработоспособности регистратора выбытия";
    }

    if (this.isDisposalWithRegistrator) {
      return "Для оказания медицинской помощи через регистратор выбытия";
    }

    return (this.delivery.withdrawalReason && WithdrawalReasonNames[this.delivery.withdrawalReason]) || "";
  }

  @computed
  get registrators() {
    return this.withdrawalStore.registrators;
  }

  @computed
  get isChildsWithMdlpError() {
    return this.delivery.childDeliveries.some(
      x =>
        (x.status === DeliveryStatus.Failed || x.status === DeliveryStatus.PartiallyFailed) &&
        this.isMdlpProcessingStep(x)
    );
  }

  @computed
  get isPartiallyFailed() {
    const { status } = this.delivery;
    return status === DeliveryStatus.PartiallyFailed;
  }

  isMdlpProcessingStep = ({ stepsInfo }: WithdrawalDeliveryModel) => {
    return stepsInfo[DeliveryStatus.MdlpProcessing] && stepsInfo[DeliveryStatus.MdlpProcessing].completionDate;
  };

  @computed
  get supplier() {
    return this.delivery.supplier;
  }

  @computed
  get destructorAddress() {
    return this.destruction?.recipient?.address;
  }

  @computed
  get destructorName() {
    return this.destruction?.recipient?.name;
  }

  @computed
  get destructorInnKpp() {
    if (!!this.destruction?.recipient?.inn && this.destruction?.recipient?.kpp) {
      return `ИНН ${this.destruction.recipient?.inn}, КПП ${this.destruction.recipient?.kpp}`;
    }
    return;
  }

  @computed
  get pollingStatusAvailable() {
    const { status } = this.delivery;
    return status === DeliveryStatus.CreatingDocument;
  }

  @computed
  get registratorErrorInfo(): ErrorInfoModel | undefined {
    if (this.registratorError) {
      const message =
        this.registratorError.message === "Failed to fetch"
          ? "Нет соединения с регистратором выбытия"
          : this.registratorError.message;
      return { errorDescription: message };
    }
  }

  getCompletionUser(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionUser(stepInfo);
  }

  getCompletionDate(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionDate(stepInfo);
  }

  getTitleSize(stage: IStage) {
    return this.actions.getTitleSize(this.delivery.stages, stage);
  }

  getSendingStepName(sendingStage: IStage | undefined, status?: DeliveryStatus): string {
    if (!sendingStage) {
      return "Отправка в ИС МДЛП";
    }
    if (status === DeliveryStatus.Sending || status === DeliveryStatus.MdlpProcessing) {
      return "Ожидание ИС МДЛП";
    }
    return sendingStage?.progress === ProgressStage.Planned ? "Отправка в ИС МДЛП" : "Отправлено в ИС МДЛП";
  }

  @action
  startPolling(id: string) {
    this.pollStatus.set(id, new Polling(this.pollRegistratorFunc.bind(this, id)));
    this.pollStatus.get(id)!.start();
  }

  @action
  stopPolling(id: string) {
    const poll = this.pollStatus.get(id);
    if (poll) {
      poll.stop();
      this.pollStatus.delete(id);
    }
  }

  private async pollRegistratorFunc(taskId: string) {
    const tags = {
      isRegistratorDisposeTask: "true",
      registratorDisposeTaskId: taskId,
    };

    const registrator = this.registrators.find(x => x.id === this.delivery.registratorId);

    if (!registrator) {
      this.registratorError = new Error(
        "Выбранный регистратор выбытия недоступен. Создайте новую операцию с существующим регистратором."
      );
      this.stopPolling(taskId);
      return;
    }

    try {
      const result = await RegistratorsApi.getRequestStatus(registrator, taskId);
      Logger.message({
        message: `Получили ответ от РВ: ${result.results.status}`,
        tags: tags,
      });

      if (result.results.status === "ready" || result.results.status === "error") {
        this.stopPolling(taskId);
        await DisposalApi.saveTaskResult(result.results, this.delivery.id);
        this.delivery.setNextStage(
          DeliveryStatus.Sending,
          DeliveryStage.Signing,
          {
            completionDate: new Date().toISOString(),
          },
          this.userStore.fullName
        );
      }
    } catch (e: any) {
      const error = new RegistratorDisposeTaskError(e, tags);
      Logger.error({ error });
      
      this.registratorError = error;
      this.stopPolling(taskId);
    }
  }
}
