import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import {
  CreateDestructionModel,
  CreateRecallModel,
  DestructionModel,
  DestructModel,
  PutDestructionModel,
} from "typings/server";
import { getHttpClient } from "./shared";

class DestructionApiInternal {
  constructor(private httpClient: IHttpClient) {}

  addNewSendingToDestruction(model: CreateDestructionModel): Promise<void> {
    return this.httpClient.post("", model);
  }

  getSendingToDestruction(id: string): Promise<DestructionModel> {
    return this.httpClient.get(id);
  }

  updateSendingToDestruction(params: { id: string; model: PutDestructionModel }): Promise<void> {
    const { model, id } = params;
    return this.httpClient.put(id, model);
  }

  destruct(id: string, model: DestructModel): Promise<string> {
    return this.httpClient.post(`${id}/destruct`, model);
  }

  recall(model: CreateRecallModel): Promise<string> {
    return this.httpClient.post("recall", model);
  }
}

export const DestructionApi = new DestructionApiInternal(getHttpClient(drugsApi + "/destruction"));
