import { DepartmentModel, EmployeeModel, InviteModel } from "typings/server";
import { EmployeeModelExtended } from "./EmployeeModelExtended";

export class EmployeePatchModelExtended extends InviteModel {
  constructor(employee: EmployeeModelExtended | EmployeeModel, isAdmin: boolean, allDepartmentsChecked: boolean) {
    super();
    this.isAdmin = isAdmin;
    this.allDepartments = allDepartmentsChecked;
    this.email = employee.email ?? "";
    this.availableDepartments = this.getDepartments(employee.availableDepartments);
  }

  private getDepartments(departments?: DepartmentModel[]): string[] | undefined {
    return departments?.map(dep => dep.id);
  }
}
