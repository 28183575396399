import styled from "styles/styled-components";
import { colors } from "styles/colors";

export const Text = styled.p`
  line-height: 25px;
`;

export const ErrorText = styled.span`
  color: ${colors.darkRed};
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-left: 60px;
  padding-top: 30px;
  min-width: 400px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
