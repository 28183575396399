import styled from "styles/styled-components";

export const maxDropdownHeight = 350;

export const DropdownItemsWrap = styled.div`
  max-height: ${maxDropdownHeight - 20}px;
  padding: 6px 10px;
`;

export const DropdownMenuWrap = styled.div`
  line-height: normal;
`;

export const InputWrap = styled.div`
  padding-bottom: 10px;
`;

export const RegularHeader = styled.div`
  padding: 0 0 4px 10px;
`;
