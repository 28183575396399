import React from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock, ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { CreatingDocWrap, DateAndFIOWrap } from "Common/Stages/Stages.styles";
import { PageInfoBlock } from "../../Common/PageInfoBlock/PageInfoBlock";
import { DateAndUser } from "Common/Stages/DateAndUser";
import { DisposalWithRegistratorStagesPart } from "./DisposalWithRegistratorStagesPart";
import { CirculationPageVM } from "../../CirculationPageVM";

interface IStagesProps {
  vm: CirculationPageVM;
}

export const DisposalWithRegistratorStagesBlock: React.FC<IStagesProps> = observer((props: IStagesProps) => {
  const { vm } = props;

  const onCollapse = () => vm.onCollapseDelivery(vm.delivery.id);

  return (
    <DeliveryStagesBlock>
      <DeliveryStatusBlock
        data-tid="ProcessingStage"
        size={vm.getTitleSize(vm.stages[DeliveryStage.Processing])}
        name={vm.stages[DeliveryStage.Processing].name}
        status={vm.stages[DeliveryStage.Processing].progress}
        collapsed={!vm.isDeliveryOpenedState.get(vm.delivery.id)}
        alwaysVisibleComp={
          <DateAndUser
            delivery={vm.delivery}
            getCompletionUser={vm.getCompletionUser}
            getCompletionDate={vm.getCompletionDate}
            step={DeliveryStatus.Processing}
          />
        }
        onCollapse={onCollapse}
      >
        <PageInfoBlock vm={vm} />
      </DeliveryStatusBlock>

      <DisposalWithRegistratorStagesPart
        data-tid="SigningStage"
        delivery={vm.delivery}
        vm={vm}
        overrideFields={{
          status: vm.registratorErrorInfo ? ProgressStage.Done : vm.stages[DeliveryStage.Signing].progress,
          size: vm.getTitleSize(vm.stages[DeliveryStage.Signing]),
          error: vm.registratorErrorInfo || vm.stages[DeliveryStage.Signing].errorInfo,
          alwaysVisibleComp: (
            <>
              {vm.delivery.status === DeliveryStatus.CreatingDocument && (
                <CreatingDocWrap leftOffset={"395px"}>
                  <Spinner type="mini" caption={"Проверка"} />
                </CreatingDocWrap>
              )}
              <DateAndUser
                delivery={vm.delivery}
                getCompletionUser={vm.getCompletionUser}
                getCompletionDate={vm.getCompletionDate}
                step={DeliveryStatus.CreatingDocument}
              />
            </>
          ),
        }}
      />

      {vm.processingErrorChildDelivery && (
        <DisposalWithRegistratorStagesPart data-tid="ChildStage" delivery={vm.processingErrorChildDelivery} vm={vm} />
      )}

      {vm.isChildsWithMdlpError ? (
        vm.groupedMdlpChildDeliveries.map(child => (
          <DisposalWithRegistratorStagesPart data-tid="ChildStage" key={child.id} delivery={child} vm={vm} />
        ))
      ) : (
        <DeliveryStatusBlock
          data-tid="SendingStage"
          status={vm.isPartiallyFailed ? ProgressStage.Done : vm.stages[DeliveryStage.Sending].progress}
          name={vm.getSendingStepName(vm.stages[DeliveryStage.Sending])}
          size={vm.isPartiallyFailed ? "medium" : vm.getTitleSize(vm.stages[DeliveryStage.Sending])}
          error={vm.stages[DeliveryStage.Sending].errorInfo}
          alwaysVisibleComp={
            <DateAndFIOWrap data-tid="CompleteInfo">
              {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Sending])}
            </DateAndFIOWrap>
          }
        />
      )}

      {!vm.isFailedStatus && (
        <DeliveryStatusBlock
          data-tid="DoneStage"
          // TODO: Хак, разобраться
          status={vm.isPartiallyFailed ? ProgressStage.Done : vm.stages[DeliveryStage.Done].progress}
          name={vm.stages[DeliveryStage.Done].name}
          error={vm.stages[DeliveryStage.Done].errorInfo}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
        />
      )}
    </DeliveryStagesBlock>
  );
});
