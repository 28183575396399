import styled from "styles/styled-components";
import { theme } from "styles/theme";
import { DeliveryItemStatus } from "typings/server";

export const ScannedGreenText = styled.span`
  font-weight: 400;
  color: ${props => props.theme.textColors.green};
`;

interface IScannedStatusTextProps {
  status: DeliveryItemStatus;
}

export const ScannedStatusText = styled.span<IScannedStatusTextProps>`
  font-weight: 400;
  color: ${props => getColorByStatus(props.status)};
`;

function getColorByStatus(status: DeliveryItemStatus) {
  switch (status) {
    case DeliveryItemStatus.Accepted:
      return theme.textColors.green;
    case DeliveryItemStatus.Recalled:
      return theme.textColors.lightenGray;
    case DeliveryItemStatus.Error:
      return theme.textColors.red;
    case DeliveryItemStatus.Rejected:
      return theme.textColors.warningText;
    default:
      return "initial";
  }
}

type ScannerTextColor = "initial" | "green" | "grey" | "red" | "orange";

interface ScannedLargeTextProps {
  color?: ScannerTextColor;
}

export const ScannedLargeText = styled.span<ScannedLargeTextProps>`
  font-weight: 400;
  color: ${props => (props.color ? getColor(props.color) : "initial")};
  font-size: 22px;
  line-height: 30px;
`;

interface ITextWrapProps {
  color?: "initial" | "green" | "grey";
}

export const TextWrap = styled.span<ITextWrapProps>`
  color: ${props => (props.color ? getColor(props.color) : "initial")};
`;

function getColor(col: ScannerTextColor) {
  switch (col) {
    case "green":
      return theme.textColors.green;
    case "grey":
      return theme.textColors.lightenGray;
    case "red":
      return theme.textColors.red;
    case "orange":
      return theme.textColors.warningText;
    default:
      return "initial";
  }
}
