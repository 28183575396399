import { Link } from "react-router-dom";
import styled from "styles/styled-components";

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.darkBlue};
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:active {
    color: #044785;
  }
`;
