import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { UtdModalVM } from "./UtdModalVM";
import { Dropzone } from "components/Dropzone";
import { UploadErrorIcon, UploadIcon } from "icons";
import { ErrorText, InvoiceList, TemplateLinkWrap, TipIconWrap, TipMsg, UploadText } from "./UtdModal.styles";
import { Button, Link, Tooltip } from "components";
import { Download, HelpDot } from "@skbkontur/react-icons";
import { ExcelTemplateFile } from "StringResx";
import { FileRejection } from "react-dropzone";

interface IUtdDropZoneProps {
  vm: UtdModalVM;
  onDrop(acceptedFiles: File[], fileRejections: FileRejection[]): Promise<void>;
}

export const UtdDropZone = observer((props: IUtdDropZoneProps) => {
  const { vm } = props;
  const acceptFormats = [
    "application/xml",
    "text/xml",
    ".xml",
    ".xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const maxFileSize = 73400320; //70 Mb

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) =>
    props.onDrop(acceptedFiles, fileRejections);
  const error = vm.errors.get("wrongFormat") || vm.errors.get("serverError");

  const toggleUtdTooltipClick = (e?: SyntheticEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    vm.toggleTooltip("utd");
  };

  const toggleInvoiceTooltip = (e?: SyntheticEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    vm.toggleTooltip("invoice");
  };

  const onClickTooltipMsg = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

  const onDownload = (e: any) => {
    e.stopPropagation();
  };

  const renderUtdTooltip = () => (
    <TipMsg onClick={onClickTooltipMsg}>
      <p>
        УПД — это универсальный передаточный
        <br />
        документ, электронный файл с данными
        <br />
        о поставке лекарственных препаратов.
        <br />
        Скачайте его в сервисе электронного
        <br />
        документооборота или запросите
        <br />у поставщика.
      </p>
      <p>
        Необходимый формат УПД — XML-файл
        <br />
        по приказу №820
      </p>
    </TipMsg>
  );

  const renderInvoiceTooltip = () => (
    <TipMsg onClick={onClickTooltipMsg}>
      <p>
        Товарная накладная — это первичный
        <br />
        учетный документ, электронный файл
        <br />
        с данными о поставке лекарственных
        <br />
        препаратов. Скачайте его в сервисе
        <br />
        электронного документооборота или
        <br />
        запросите у поставщика.
      </p>
      <p>
        Необходимый формат товарной
        <br />
        накладной — XML-файл по приказу №551.
      </p>
    </TipMsg>
  );

  return (
    <Dropzone onDrop={onDrop} multiple={false} accept={acceptFormats} maxSize={maxFileSize}>
      {error ? (
        <>
          <UploadErrorIcon />
          <ErrorText>{error}</ErrorText>
        </>
      ) : (
        <>
          <UploadIcon />
          <UploadText>
            Перетащите или&nbsp;
            <Button use={"link"} size={"large"}>
              загрузите с компьютера
            </Button>
            &nbsp;любой из этих файлов:
            <InvoiceList>
              <li>
                УПД в формате XML&nbsp;
                <TipIconWrap onClick={toggleUtdTooltipClick}>
                  <Tooltip
                    render={renderUtdTooltip}
                    pos={"top center"}
                    trigger={vm.tooltips.get("utd")}
                    onCloseRequest={toggleUtdTooltipClick}
                  >
                    <HelpDot />
                  </Tooltip>
                </TipIconWrap>
              </li>
              <li>
                Товарную накладную в формате XML&nbsp;
                <TipIconWrap onClick={toggleInvoiceTooltip}>
                  <Tooltip
                    render={renderInvoiceTooltip}
                    pos={"top center"}
                    trigger={vm.tooltips.get("invoice")}
                    onCloseRequest={toggleInvoiceTooltip}
                  >
                    <HelpDot />
                  </Tooltip>
                </TipIconWrap>
              </li>
              <li>
                Заполненный шаблон в формате XLSX
                <TemplateLinkWrap>
                  <Link data-tid="DownloadLink" href={ExcelTemplateFile} download onClick={onDownload}>
                    <Download /> Скачать шаблон
                  </Link>
                </TemplateLinkWrap>
              </li>
            </InvoiceList>
          </UploadText>
        </>
      )}
    </Dropzone>
  );
});
