import { action, observable } from "mobx";
import { DepartmentModel, RegistratorSettingsModel, RequisitesModel } from "typings/server";
import { DepartmentsApi } from "api/DepartmentsApi";
import { EmployeesApi } from "api/EmployeesApi";
import { EmployeePatchModelExtended } from "models/Employee/EmployeePatchModelExtended";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";
import { RequisitesApi } from "api/RequisitesApi";
import { NOT_FOUND_STATUS } from "api/shared";
import { RegistratorsApi } from "api/RegistratorsApi";
import { AdminApi } from "api/AdminApi";

class SettingsStoreInternal {
  @observable departmentsSyncInfo?: string;
  @observable allDepartments: DepartmentModel[] = [];
  @observable employees: EmployeeModelExtended[] = [];
  @observable requisites: RequisitesModel;
  @observable registrators: RegistratorSettingsModel[] = [];

  @action
  async getRegistrators() {
    this.registrators = await RegistratorsApi.getAllRegistrators();
  }

  @action
  async getDepartments(): Promise<DepartmentModel[]> {
    const res = await DepartmentsApi.getDepartments();
    this.allDepartments = res.departments;
    this.departmentsSyncInfo = res.syncTime;
    return this.allDepartments;
  }

  @action
  async getRequisites(): Promise<RequisitesModel> {
    try {
      const requisites = await RequisitesApi.get();
      this.requisites = new RequisitesModel();
      this.requisites.secret = requisites.secret || "";
      this.requisites.memberId = requisites.memberId || "";
      this.requisites.clientId = requisites.clientId || "";
    } catch (e: any) {
      if (e && e.status === NOT_FOUND_STATUS) {
        this.requisites = new RequisitesModel();
        Object.assign(this.requisites, { memberId: "", clientId: "", secret: "" });
      } else throw e;
    }

    return this.requisites;
  }

  @action
  async getEmployees(): Promise<EmployeeModelExtended[]> {
    const employees = await EmployeesApi.getEmployees();
    this.employees = employees.map(e => new EmployeeModelExtended(e));
    return this.employees;
  }

  @action
  async deleteEmployee(id: string): Promise<void> {
    await EmployeesApi.delete(id);
    const index = this.employees.findIndex(employee => employee.id === id);
    if (index !== -1) {
      this.employees.splice(index, 1);
    }
  }

  @action
  async refreshEmployeeFio(portalId: string): Promise<void> {
    await AdminApi.refreshUserFio(portalId);
    await this.getEmployees();
  }

  @action
  async deleteRegistrator(id: string): Promise<void> {
    await RegistratorsApi.deleteRegistrator(id);
    const index = this.registrators.findIndex(reg => reg.id === id);
    if (index !== -1) {
      this.registrators.splice(index, 1);
    }
  }

  @action
  async changeEmployee(employee: EmployeeModelExtended, patchModel: EmployeePatchModelExtended): Promise<void> {
    const { id } = employee;
    await EmployeesApi.patch(id, patchModel);
    const ind = this.employees.findIndex(e => e.id === id);
    if (ind !== -1) {
      this.employees.splice(ind, 1, employee);
    }
  }

  @action
  async saveRequisites(newRequisites: RequisitesModel) {
    const isTheSameSecret = this.requisites && newRequisites.secret === this.requisites.secret;
    if (isTheSameSecret) {
      // @ts-ignore
      // не сохраняем secret если он не менялся, т.к. secret в открытом виде не приходит с бэка
      // если secret заполнен - с бэка придет строка <censored>
      delete newRequisites.secret;
    }
    await RequisitesApi.save(newRequisites);
    this.requisites = {
      clientId: newRequisites.clientId,
      memberId: newRequisites.memberId,
      secret: isTheSameSecret ? this.requisites.secret : newRequisites.secret,
    };
  }

  async invite(patchModel: EmployeePatchModelExtended): Promise<void> {
    await EmployeesApi.invite(patchModel);
  }

  async synchronizeDepartments(): Promise<DepartmentModel[]> {
    return await DepartmentsApi.synchronize();
  }
}

export interface ISettingsStore extends SettingsStoreInternal {}

export const SettingsStore = new SettingsStoreInternal();
