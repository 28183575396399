import styled from "styles/styled-components";

export const ModalWrap = styled.div`
  min-width: 655px;
`;

export const LabelRequired = styled.div`
  position: relative;

  &:after {
    content: "*";
    position: relative;
    color: ${props => props.theme.colors.red};
    top: -3px;
    right: -2px;
  }
`;

export const TextAreaWrap = styled.div`
  position: relative;
`;

export const ToggleWrap = styled.div`
  cursor: pointer;
`;
