import { CreateReentryModel, DeliveryModel, ReturnReason, WithdrawalCause, WithdrawalReason } from "typings/server";
import { action, computed, observable } from "mobx";
import { DeliveriesApi } from "api/DeliveryApi";
import {
  WithdrawalCauseInReentryNamesArr,
  ReturnReasonNamesArr,
  ReturnReasonNames,
  WithdrawalCauseInReentryNames,
} from "../../WithdrawalDictionary";
import { IType } from "typings/dictionaries";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { BaseEditorModalVM, TBaseErrors } from "../BaseEditorModalVM";

type TErrors = TBaseErrors | "invalidOperationDate" | "reasonRequired";

const WithdrawalNonRequiredReasons: WithdrawalReason | string[] = [
  WithdrawalReason.SelectiveAudit,
  WithdrawalReason.CustomAudit,
  WithdrawalReason.FederalSupervision,
  WithdrawalReason.ClinicalResearch,
  WithdrawalReason.PharmaceuticalExamination,
  WithdrawalReason.Archive,
  WithdrawalReason.QualityAudit,
];

export class ReentryEditorModalVM extends BaseEditorModalVM<TErrors> {
  @observable operationDate: string = new Date().toISOString();
  @observable withdrawalCause: IType;
  @observable returnReason: IType;
  private isOperationDateModified: boolean = false;

  @computed
  get withdrawalCauses() {
    return WithdrawalCauseInReentryNamesArr;
  }

  @computed
  get returnReasons() {
    return ReturnReasonNamesArr;
  }

  @action.bound
  setOperationDate(date?: string) {
    this.operationDate = date || "";
    this.isOperationDateModified = true;
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @computed
  get isDocumentInfoRequired(): boolean {
    const withdrawalCause = this.withdrawalCause && this.withdrawalCause.value;
    return !(withdrawalCause && WithdrawalNonRequiredReasons.includes(withdrawalCause));
  }

  @action.bound
  setWithdrawalCause(withdrawalCause: IType) {
    this.withdrawalCause = withdrawalCause;
    if (withdrawalCause.value === WithdrawalCause.HealthCare) {
      // При выводе из оборота по причине выдачи для оказания медицинской помощи возрат должен быть ненадлежащего качества
      this.returnReason = {
        label: ReturnReasonNames.PoorQuality,
        value: ReturnReason.PoorQuality,
      };
    }

    if (this.isSubmitted) {
      this.validate();
    }
  }

  @action.bound
  setReturnReasons(returnReason: IType) {
    this.returnReason = returnReason;
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @action
  async save(): Promise<string | void> {
    this.isSubmitted = true;
    if (this.validate()) {
      if (this.isNew) {
        const model = new CreateReentryModel();

        const data = {
          operationDate: this.isOperationDateModified ? this.operationDate : undefined, // for new withdrawal delivery
          documentDate: this.documentDate || "",
          documentNumber: this.documentNumber,
          withdrawalCause: this.withdrawalCause.value,
          returnReason: this.returnReason?.value || ReturnReason.GoodQuality,
        };
        Object.assign(model, data);

        return await this.createNewOperation(model);
      }

      const model = new DeliveryModel();
      const reportDate =
        this.isOperationDateModified || (this.delivery && this.delivery.reportDate) ? this.operationDate : undefined;

      const data = {
        reportDate,
        operationDate: this.isOperationDateModified ? this.operationDate : undefined,
        documentDate: this.documentDate || "",
        documentNumber: this.documentNumber,
        withdrawalCause: this.withdrawalCause.value,
        returnReason: this.returnReason?.value || ReturnReason.GoodQuality,
      };
      Object.assign(model, data);

      return await this.patchOperation(model);
    } else {
      return Promise.reject();
    }
  }

  async createNewOperation(model: CreateReentryModel): Promise<string> {
    return await DeliveriesApi.addNewReentry(model);
  }

  @action
  async patchOperation(model: DeliveryModel): Promise<void> {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    await this.store.saveDelivery(model, this.delivery!.id);
    this.delivery!.updateDelivery(model);
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (!this.withdrawalCause) {
      this.errors.set("reasonRequired", "Укажите причину вывода из оборота");
    }
    if (this.operationDate && new Date(this.operationDate) > new Date()) {
      this.errors.set("invalidOperationDate", "Дата не должна быть больше текущей даты и времени");
    }
    if (this.documentDate && new Date(this.documentDate) > new Date()) {
      this.errors.set("invalidDocDate", "Дата не должна быть больше текущей даты и времени");
    }
    if (this.isDocumentInfoRequired && !this.documentDate) {
      this.errors.set("docDateRequired", "Укажите дату документа-основания");
    }
    if (this.isDocumentInfoRequired && !this.documentNumber) {
      this.errors.set("docNumberRequired", "Укажите номер документа-основания");
    }
    return !this.errors.size;
  }

  @action
  setByDelivery(delivery: WithdrawalDeliveryModel) {
    this.operationDate = delivery.reportDate ? new Date(delivery.reportDate).toISOString() : new Date().toISOString();
    if (delivery.withdrawalCause) {
      this.setWithdrawalCause({
        value: delivery.withdrawalCause,
        label: WithdrawalCauseInReentryNames[delivery.withdrawalCause],
      });
    }
    if (delivery.returnReason) {
      this.setReturnReasons({
        value: delivery.returnReason,
        label: ReturnReasonNames[delivery.returnReason],
      });
    }
    if (delivery.documentDate) {
      this.documentDate = new Date(delivery.documentDate).toISOString();
    }
    this.documentNumber = delivery.documentNumber || "";
  }
}
