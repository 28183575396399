import React from "react";
import { observer } from "mobx-react";
import { CounterWrap, CodesOffset, RejectedWrap, RejectedCodesOffset } from "../Stages.styles";
import { ScannedItemsCountLarge } from "../../ScannedItemsCount/ScannedItemsCountLarge";
import { getEnding } from "helpers";
import { ScannedLargeText } from "../../ScannedItemsCount/ScannedItemsCount.styles";
import { Input, Tooltip } from "components";
import { TextSecondary } from "styles/shared.styles";
import { DeliveryStatus, DeliveryType } from "typings/server";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { ShipmentDeliveryModel } from "models/Delivery/ShipmentDeliveryModel";

interface IAcceptedCodesProps {
  delivery: {
    allCodesLen: number;
  };
  acceptedCodesLen: number;
  isAgreed?: boolean;
  isSimplified?: boolean;
}

export const AcceptedCodes = observer(({ delivery, acceptedCodesLen, isAgreed, isSimplified }: IAcceptedCodesProps) => {
  if (!acceptedCodesLen) return null;

  if (isSimplified) {
    return (
      <>
        <ScannedLargeText data-tid="AcceptedCodesCount" color={"green"}>
          {acceptedCodesLen}
        </ScannedLargeText>
        &nbsp;&nbsp;
        {getEnding(
          acceptedCodesLen,
          "код маркировки принят по упрощенной схеме",
          "кода маркировки принято по упрощенной схеме",
          "кодов маркировки принято по упрощенной схеме"
        )}
      </>
    );
  }
  if (isAgreed) {
    return (
      <>
        <ScannedLargeText data-tid="AcceptedCodesCount" color={"green"}>
          {acceptedCodesLen}
        </ScannedLargeText>
        &nbsp;&nbsp;
        {getEnding(
          acceptedCodesLen,
          "код маркировки согласован",
          "кода маркировки согласовано",
          "кодов маркировки согласовано"
        )}
      </>
    );
  }

  return (
    <CounterWrap>
      <ScannedItemsCountLarge allLen={delivery.allCodesLen} scannedLen={acceptedCodesLen} />
      &nbsp;&nbsp;кодов маркировки принято
    </CounterWrap>
  );
});

interface IFailedCodesProps {
  failedCodesLen: number;
}

export const FailedCodes = observer(({ failedCodesLen }: IFailedCodesProps) => {
  if (!failedCodesLen) return null;
  return (
    <CodesOffset>
      <ScannedLargeText data-tid="FailedCodesCount" color="red">
        {failedCodesLen}
      </ScannedLargeText>
      &nbsp;&nbsp;{getEnding(failedCodesLen, "код маркировки", "кода маркировки", "кодов маркировки")}&nbsp;с ошибкой
    </CodesOffset>
  );
});

interface IRecalledCodesProps {
  recalledCodesLen: number;
}

export const RecalledCodes = observer(({ recalledCodesLen }: IRecalledCodesProps) => {
  if (!recalledCodesLen) return null;
  return (
    <CodesOffset>
      <ScannedLargeText data-tid="RecalledCodesCount" color={"grey"}>
        {recalledCodesLen}
      </ScannedLargeText>
      &nbsp;&nbsp;
      {getEnding(recalledCodesLen, "код отозван ", "кода отозвано ", "кодов отозвано ")}поставщиком
    </CodesOffset>
  );
});

interface IRejectedCodesProps {
  vm: {
    errors: Map<string, string>;
    setRejectReason?: (val: string) => void;
  };
  rejectedCodesLen: number;
  delivery: AcceptanceDeliveryModel | ShipmentDeliveryModel;
}

export const RejectedCodes = observer(({ rejectedCodesLen, vm, delivery }: IRejectedCodesProps) => {
  if (!rejectedCodesLen) return null;

  const { recallReason, type, status } = delivery;

  const renderError = () => vm.errors.get("requiredRecallReason");

  const subString = type === DeliveryType.Acceptance ? "приемке" : "отгрузке";

  const possibleReasonEditStatuses =
    status === DeliveryStatus.New ||
    status === DeliveryStatus.Processing ||
    status === DeliveryStatus.WaitingForCounterparty ||
    status === DeliveryStatus.PartiallyFailedIncompleted;

  const possibleRejectStatuses =
    possibleReasonEditStatuses || status === DeliveryStatus.Rejected || status === DeliveryStatus.PartiallyRejected;

  return (
    <RejectedCodesOffset>
      <RejectedWrap>
        <ScannedLargeText data-tid="RejectedCodesCount" color={"orange"}>
          {rejectedCodesLen}
        </ScannedLargeText>
        &nbsp;&nbsp;
        {getEnding(rejectedCodesLen, "код", "кода ", "кодов")} маркировки для отказа в {subString}
      </RejectedWrap>

      {possibleRejectStatuses && (
        <>
          {possibleReasonEditStatuses ? (
            <Tooltip render={renderError}>
              <Input
                data-tid="RejectReason"
                value={recallReason}
                placeholder={"Причина отказа"}
                onValueChange={value => vm.setRejectReason?.(value)}
                width={"430px"}
                autoFocus
                error={!!renderError()}
              />
            </Tooltip>
          ) : (
            recallReason && (
              <div>
                <TextSecondary>
                  Причина отказа: <span data-tid="RejectReasonReadonly">{recallReason}</span>
                </TextSecondary>
              </div>
            )
          )}
        </>
      )}
    </RejectedCodesOffset>
  );
});
