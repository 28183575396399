import { action, observable } from "mobx";
import {
  CodeInfoResponseModel,
  DeliveryIndexModel,
  DeliveryItemModel,
  DeliveryModel,
  DeliveryStatus,
  DeliveryType,
  PatchDeliveryModel,
  RegistratorEmbeddedModel,
} from "typings/server";
import { ILoadOptions } from "./shared";
import { DeliveriesApi } from "api/DeliveryApi";
import { DeliveryStoreActions } from "./DeliveryStoreActions";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { ReportsApi } from "api/ReportsApi";
import { DeliveriesStore, IDeliveryStore, ISyncPollingOptions } from "./DeliveriesStore";
import { FilterModel } from "models/Filter/FilterModel";
import { RegistratorsApi } from "api/RegistratorsApi";
import { DisposalApi } from "api/DisposalApi";

class WithdrawalStoreInternal {
  @observable deliveries: DeliveryIndexModel[] = [];
  @observable public selectedDelivery: WithdrawalDeliveryModel;
  @observable totalSize: number;
  @observable registrators: RegistratorEmbeddedModel[] = [];
  @observable currentScanCodeStatus: CodeInfoResponseModel | undefined;

  firstLoad: boolean = false;
  readonly filters: FilterModel;
  private readonly actions: DeliveryStoreActions<WithdrawalDeliveryModel>;
  private readonly deliveriesStore: IDeliveryStore = DeliveriesStore;
  private types: DeliveryType[] = [
    DeliveryType.Withdrawal,
    DeliveryType.DisposalWithRegistrator,
    DeliveryType.Disposal,
  ];

  private getDefaultAllowedTypes(type: DeliveryType) {
    if (
      type === DeliveryType.Withdrawal ||
      type === DeliveryType.DisposalWithRegistrator ||
      type === DeliveryType.Disposal
    ) {
      return [DeliveryType.Withdrawal, DeliveryType.DisposalWithRegistrator, DeliveryType.Disposal];
    }

    return type ? [type] : this.types;
  }

  constructor() {
    this.actions = new DeliveryStoreActions();
    this.filters = new FilterModel(this.types);
  }

  @action
  updateDeliveries = async () => {
    const filters = this.filters.getFullFiltersInfo();
    const result = await DeliveriesApi.getAll(filters);
    if (result) {
      const types = result.presenceInfo.types.filter(x => this.types.includes(x));
      const defaultAllowedTypes = this.getDefaultAllowedTypes(types[0]);
      if (this.firstLoad) {
        this.filters.setDefaultAllowedTypes(defaultAllowedTypes);
      } else {
        this.filters.setDefaultAllowedTypes(types.length ? types : this.types);
        this.firstLoad = true;
      }
      this.totalSize = result.totalSize;
      this.setDeliveries(result.deliveries);
      this.filters.setPagesCount(result.totalSize);
      this.filters.setAllowedStatuses(result.presenceInfo.statuses, this.types[0]);
      this.filters.setAllowedTypes(types);
      this.filters.setAllowedWithdrawalCauses(result.presenceInfo.withdrawalCauses);
      this.filters.setAllowedReturnReasons(result.presenceInfo.returnReasons);
      this.filters.setAllowedWithdrawalReason(result.presenceInfo.withdrawalReasons, types);
    }
  };

  @action
  setActivePage = async (page: number) => {
    this.filters.setActivePage(page);
    await this.updateDeliveries();
  };

  @action
  setDeliveries(deliveries: DeliveryIndexModel[]) {
    this.deliveries = deliveries;
  }

  @action
  async getItem(id: string, options?: Partial<ILoadOptions>): Promise<DeliveryModel> {
    return this.actions.getItem(this.selectedDelivery, id, options);
  }

  @action
  setSelected(delivery: DeliveryModel) {
    this.selectedDelivery = new WithdrawalDeliveryModel(delivery);
  }

  @action
  async startPollingDeliveries(page: number) {
    await this.setActivePage(page);
    this.restartPollingDeliveries({ fireImmediately: true });
  }

  @action
  async restartPollingDeliveries(options?: ISyncPollingOptions) {
    await this.deliveriesStore.restartSyncInfoPolling(this.updateDeliveries, options);
  }

  @action
  stopPollingDeliveries() {
    this.deliveriesStore.stopSyncInfoPolling();
  }

  @action
  setCurrentScanCodeStatus(codeStatus: CodeInfoResponseModel) {
    this.currentScanCodeStatus = codeStatus;
  }

  async saveDelivery(delivery: Partial<PatchDeliveryModel>, id: string) {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    return this.actions.saveDelivery(delivery, id);
  }

  async saveItems(items: DeliveryItemModelExtended[], id: string, isDisposalWithRegistrator: boolean = false) {
    const reportItems: any[] = [];
    items.forEach(item =>
      item.allCodes.forEach(codeModel => {
        const { totalParts, part, dataMatrix, code } = codeModel;
        reportItems.push({
          name: item.realName,
          ...(totalParts && part ? { totalParts, part } : {}),
          ...(isDisposalWithRegistrator ? { dataMatrix: dataMatrix as string } : { code: code }),
        });
      })
    );
    if (isDisposalWithRegistrator) {
      await DisposalApi.saveDisposalCodes(reportItems, id);
    } else {
      await ReportsApi.saveItems(reportItems, id);
    }
  }

  @action
  async getAvailableRegistrators() {
    try {
      this.registrators = await RegistratorsApi.getAvailableRegistrators();
    } catch (e) {
      this.registrators = [];
    }
  }

  async rollbackDeliveryStatus(deliveryId: string) {
    return this.actions.rollbackDeliveryStatus(deliveryId);
  }

  async getScannedCodes(deliveryId: string) {
    return this.actions.getDeliveryProperty(deliveryId, "scannedCodes");
  }

  async getItems(deliveryId: string): Promise<DeliveryItemModel[]> {
    return this.actions.getDeliveryProperty(deliveryId, "items");
  }

  async prepareDocuments(deliveryId: string) {
    return this.actions.prepareDocuments(deliveryId);
  }

  async checkDocumentsStatus(taskId: string) {
    return this.actions.checkDocumentsStatus(taskId);
  }

  @action
  async deleteDelivery(deliveryId: string) {
    await this.actions.deleteDelivery(deliveryId);
    await this.updateDeliveries();
  }

  @action
  changeType(type: DeliveryType) {
    switch (type) {
      case DeliveryType.Reentry:
        this.types = [DeliveryType.Reentry];
        break;
      case DeliveryType.Destruction:
        this.types = [DeliveryType.Destruction];
        break;
      default:
        this.types = [DeliveryType.Withdrawal, DeliveryType.DisposalWithRegistrator, DeliveryType.Disposal];
        break;
    }
    this.filters.setDefaultAllowedTypes(this.types);
  }

  isCanBeDeleted(status?: DeliveryStatus) {
    return status === DeliveryStatus.Draft || status === DeliveryStatus.Processing;
  }
}

export interface IWithdrawalStore extends WithdrawalStoreInternal {}

export const WithdrawalStore = new WithdrawalStoreInternal();
