import React from "react";
import { IButtonProps } from "components/Button/Button";
import { Button, Hint } from "components";

interface RollbackButtonProps extends IButtonProps {
  hintText: string;
}

export function RollbackButton({
  children,
  hintText,
  disabled,
  ...props
}: React.PropsWithChildren<RollbackButtonProps>) {
  return (
    <Hint text={hintText}>
      <Button data-tid="OpenRollbackModal" disabled={disabled} {...props}>
        {children}
      </Button>
    </Hint>
  );
}
