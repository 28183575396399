import React from "react";
import { RouteComponentProps } from "react-router";
import { TransferRoute } from "routes";
import { Route, Switch } from "react-router-dom";
import { MainPage } from "./MainPage/MainPage";
import { DetailsPageWrapper } from "./DetailsPage/DetailsPageWrapper";

interface TransferPageProps extends RouteComponentProps<TransferRoute> {}

export function TransferIndexPage(_props: TransferPageProps) {
  return (
    <Switch>
      <Route path={TransferRoute.baseRoute} exact component={MainPage} />
      <Route path={TransferRoute.route} exact component={DetailsPageWrapper} />
    </Switch>
  );
}
