import { action, computed, observable, runInAction } from "mobx";
import { Logger, Polling } from "helpers";
import { currentCodeTimeout, IErrorObj, pollCodesTimeout, TLoadingState } from "../shared";
import { ScanHelper, ScanMode } from "../ScanHelper";
import { ICertificate } from "@skbkontur/plugin-js";
import { IStore } from "stores/shared";
import { MDLP_AUTH_REQUIRED_STATUS, MDLP_SERVER_ERROR } from "api/shared";
import { MdlpAuthApi } from "api/MdlpAuthApi";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { CodeAdditionalInfoModel, CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { ISignatureStore } from "stores/SignatureStore";
import { Advertisement, IAdvertisementStore } from "stores/AdvertisementStore";

export type ScanValidationErrors = "lang" | "wrongCode" | "notFound" | "register" | "notSsccAllowed";
export type ScanValidationWarns = "alreadyExist" | "caps";
type TMdlpErrors = "certNotFound" | "unavailable";
type TModalName = "mdlpModal";

export abstract class BaseScanPageVM<TStore extends IStore, TItem, TError extends string | IErrorObj> {
  @observable currentCode: string | undefined;
  @observable copiedData: TItem[] = [];
  @observable newCodeItem?: TItem;
  @observable newCode?: string;
  @observable mode: ScanMode = ScanMode.Scanner;
  @observable serverErrors: Map<Partial<TMdlpErrors>, string> = new Map();
  @observable errors: Map<Partial<ScanValidationErrors>, TError> = new Map();
  @observable warns: Map<Partial<ScanValidationWarns>, string> = new Map();
  @observable modalState: Map<TModalName, boolean> = new Map();
  @observable loadingState: Map<TLoadingState, boolean> = new Map();
  @observable inputStr: string = "";
  @observable showWarn: boolean = false;
  @observable showError: boolean = false;
  @observable showCurrentCode: boolean = false;
  @observable isInfoOpened: Map<string, boolean> = new Map();

  public pollCodes: Polling;
  protected tokenExpired: boolean;
  protected timeout: number;
  protected deletedCodes: string[] = [];

  constructor(
    protected readonly store: TStore,
    protected readonly signatureStore: ISignatureStore,
    readonly advertisementStore: IAdvertisementStore,
    public readonly additionalInfo: CodesAdditionalInfo,
    public readonly deliveryId: string,
    isScannerOnly?: boolean
  ) {
    if (isScannerOnly) {
      ScanHelper.setScanMode(ScanMode.Scanner);
    }
    this.mode = ScanHelper.getMode();
    this.pollCodes = new Polling(this.pollScannedCodesFunc.bind(this));
    if (this.isPhoneMode) {
      this.pollCodes.start(null, pollCodesTimeout);
      // проверяем авторизацию в мдлп, и если нужно авторизовываем
      MdlpAuthApi.check().catch(e => {
        if (e && e.status === MDLP_AUTH_REQUIRED_STATUS) {
          this.toggleModal("mdlpModal");
        }
      });
    }
    this.additionalInfo.codesInfo.forEach(info => this.isInfoOpened.set(info.code, false));
  }

  protected abstract pollScannedCodesFunc(): Promise<void>;

  abstract createQRCode(): void;

  @action
  changeMode = (mode: ScanMode) => {
    this.mode = mode;
    this.errors.clear();
    this.warns.clear();
    ScanHelper.setScanMode(mode);
    if (this.isPhoneMode) {
      this.createQRCode();
      this.pollCodes.start(null, pollCodesTimeout);
    } else this.pollCodes.stop();
  };

  handleUnmount(): void {
    this.pollCodes.stop();
  }

  @action
  onInput = (val: string) => {
    this.inputStr = val;
    this.errors.clear();
    if (!this.warns.get("caps")) {
      this.warns.clear();
    }
  };

  @action
  setCertNotFoundError() {
    this.serverErrors.set("certNotFound", "Не найден ключ доступа к МДЛП.\n\rНаименования товаров недоступны.");
    this.tokenExpired = true;
  }

  @action
  setCurrentCode(code: string | undefined) {
    this.currentCode = code;
    this.showCurrentCode = true;
    setTimeout(() => {
      runInAction(() => {
        this.showCurrentCode = false;
      });
    }, currentCodeTimeout);
  }

  @action
  toggleModal(name: TModalName) {
    this.modalState.set(name, !this.modalState.get(name));
  }

  @action
  protected setErrorWarningTimeout() {
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.showError = false;
      this.showWarn = false;
    }, currentCodeTimeout);
  }

  @action
  async onSing(cert: ICertificate) {
    this.serverErrors.delete("certNotFound");
    await this.signatureStore.signCode(cert);
    if (this.currentCode) {
      this.getCodeAdditionalInfo(this.currentCode);
    }
    this.tokenExpired = false;
  }

  @action
  handleErrors = (e: any, options?: { showModal?: boolean }) => {
    if (e?.status === MDLP_AUTH_REQUIRED_STATUS) {
      if (options?.showModal) this.toggleModal("mdlpModal");
      this.setCertNotFoundError();
    } else if (e?.status === MDLP_SERVER_ERROR) {
      this.serverErrors.set("unavailable", "Сервер МДЛП недоступен.\n\rНаименования товаров недоступны.");
    }
    Logger.error({ error: e });
  };

  @action
  getCodeAdditionalInfo(
    code: string,
    item?: TItem,
    options?: { showModal?: boolean }
  ): Promise<CodeAdditionalInfoModel | undefined | void> {
    this.loadingState.set("additionalInfo", true);
    return this.additionalInfo
      .getCodeInfo(code)
      .then(info => {
        if (info?.name && item instanceof DeliveryItemModelExtended) {
          item.updateName(info.name);
        }
        return info;
      })
      .catch(e => {
        this.handleErrors(e, options);
      })
      .finally(() => {
        this.loadingState.set("additionalInfo", false);
      });
  }

  @action
  protected resetFields(): void {
    this.errors.clear();
    this.warns.clear();
    this.showError = false;
    this.showCurrentCode = false;
    this.currentCode = undefined;
    this.onInput("");
    this.setNewCodeItem(undefined);
  }

  isOpened(code: string): boolean {
    return !!this.isInfoOpened.get(code);
  }

  @action
  toggleIsOpened(code: string): void {
    this.isInfoOpened.set(code, !this.isOpened(code));
  }

  @computed
  get isAnyCodeHasAddInfo(): boolean {
    let size = 0;
    this.additionalInfo.codesInfo.forEach(x => {
      if (!x.isError) {
        size++;
      }
    });
    return !!size;
  }

  // для мигания строчки с только что отсканированным кодом
  @action
  protected setNewCodeItem(item?: TItem) {
    this.newCodeItem = item;
  }

  // для мигания строчки в доп. инфе с только что отсканированным кодом
  @action
  protected setNewCode(code?: string) {
    this.newCode = code;
  }

  @computed
  get isPhoneMode(): boolean {
    return this.mode === ScanMode.Phone;
  }

  @computed
  get isInputMode() {
    return this.mode === ScanMode.Input;
  }

  @computed
  get codeAdditionalInfo() {
    return this.currentCode ? this.additionalInfo.codesInfo.get(this.currentCode) : undefined;
  }

  @computed
  get advertisementsMap() {
    return this.advertisementStore.isShowAdvertisementMap;
  }

  @action.bound
  setAdvertisementShown(key: Advertisement) {
    this.advertisementStore.setAdvertisementStatus(key, false);
  }
}
