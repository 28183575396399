import React from "react";
import { observer } from "mobx-react";
import { Button, Modal, Select, Tooltip } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { WithdrawalEditorModalVM } from "./WithdrawalEditorModalVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { DocumentHeader, EditorLabel, ModalBodyInner } from "../EditorModal.styles";
import { UserStore } from "stores/UserStore";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { EditorFields } from "../EditorFields";
import { IType } from "typings/dictionaries";
import { CirculationSubRoute } from "routes/CirculationRoute";

interface IOperationInternalModalProps extends IOperationModalProps, RouteComponentProps<CirculationRoute> {
  vm: WithdrawalEditorModalVM;
}

const WithdrawalModalInternal = withRouter(
  observer((props: IOperationInternalModalProps) => {
    const { vm, history } = props;

    const onClose = () => props.onClose();

    const getReasonError = () => vm.errors.get("reasonRequired");

    const renderReason = (item: IType) => item.label;

    const onSave = async () => {
      try {
        const id = await vm.save();
        if (id) {
          history.push(CirculationRoute.getHref(id, CirculationSubRoute.withdrawal));
        } else {
          onClose();
        }
      } catch (e) {
        // empty promise for validation fail
        if (e) {
          throw e;
        }
      }
    };

    return (
      <Modal data-tid="WithdrawalEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>Вывод из оборота по различным причинам</Modal.Header>
        <Modal.Body>
          <ModalBodyInner>
            <Grid cols={"130px 1fr"}>
              <EditorFields.Sender vm={vm} required row={1} />

              <GridCell col={1} row={2} padding={"15px 4px 8px"}>
                <EditorLabel required>Тип вывода из оборота</EditorLabel>
              </GridCell>

              <GridCell col={2} row={2}>
                <Tooltip trigger={"hover"} render={getReasonError}>
                  <Select<IType>
                    data-tid="Reason"
                    placeholder={"Выберите тип вывода из оборота"}
                    width={"100%"}
                    maxWidth={"480px"}
                    items={vm.reasons}
                    onValueChange={vm.setReason}
                    renderItem={renderReason}
                    renderValue={renderReason}
                    error={!!getReasonError()}
                    value={vm.reason}
                    maxMenuHeight={250}
                  />
                </Tooltip>
              </GridCell>

              <EditorFields.ReportDate vm={vm} required row={3} />

              <GridCell colStart={1} colEnd={3} row={4}>
                <DocumentHeader>Документ-основание</DocumentHeader>
              </GridCell>

              <EditorFields.DocumentNumber vm={vm} required={vm.isDocumentInfoRequired} row={5} />

              <EditorFields.DocumentDate vm={vm} required={vm.isDocumentInfoRequired} row={6} />
            </Grid>
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer panel>
          <FooterBtnWrapper>
            <Button data-tid="Submit" use="primary" size={"large"} onClick={onSave}>
              {vm.isNew ? "Добавить вывод из оборота" : "Сохранить"}
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  })
);

interface IOperationModalProps {
  onClose(): void;
  delivery?: WithdrawalDeliveryModel;
}

// React.memo + () => true - не обновляет компонент
export const WithdrawalEditorModal = React.memo(
  (props: IOperationModalProps) => {
    const { delivery } = props;
    const vm = new WithdrawalEditorModalVM(WithdrawalStore, UserStore, delivery);
    return <WithdrawalModalInternal {...props} vm={vm} />;
  },
  () => true
);
