import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { DeliveryItemStatus } from "typings/server";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { getUniqueBy } from "helpers";

export type TCodeType = "sscc" | "sgtin";

export class CodesByStatusVM {
  constructor(readonly good: UtdPositionModelExtended, readonly type: TCodeType) {}

  private getGroupedCodes(codes: CodeModelExtended[]): { count: number; status: DeliveryItemStatus }[] {
    const uniqCodesByStatus = getUniqueBy(codes, "status");
    // @ts-ignore
    return uniqCodesByStatus.map(codeItem => {
      const { status } = codeItem;
      const count = codes.reduce((sum, code) => {
        if (code.status === codeItem.status) sum++;
        return sum;
      }, 0);

      return { status, count };
    });
  }

  get groupedCodes() {
    if (this.type === "sscc") {
      return this.getGroupedCodes(this.good.ssccCodes);
    }
    if (this.type === "sgtin") {
      return this.getGroupedCodes(this.good.sgtinCodes);
    }
    return undefined;
  }

  get moreThanOneStatus(): boolean {
    return !!(this.groupedCodes && this.groupedCodes.length > 1);
  }
}
