import { action, computed, observable, toJS } from "mobx";
import { DeliveryActions, DetailPageLoadingName, DetailPageModalNames } from "Common/DeliveryActions";
import { ICertificate } from "@skbkontur/plugin-js";
import { IStage } from "models/Delivery/shared";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { AcceptusType, CounteragentInfoModel, DeliveryStatus, DeliveryType, StepInfoModel } from "typings/server";
import { ISignatureStore } from "stores/SignatureStore";
import { IAcceptanceStore } from "stores/AcceptanceStore";
import { IUserStore } from "stores/UserStore";
import { DeliveryStage } from "features/Common/DeliveryDictionary";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { getFormattedDate, longDateTimeOptions } from "helpers/date";

export type AcceptanceErrors = "requiredFields" | "requiredRecallReason" | "invalidReportDate";

export abstract class BaseAcceptancePageVM {
  @observable submitted: boolean = false;
  @observable isDeliveryOpenedState: Map<string, boolean> = new Map();
  @observable additionalInfo: CodesAdditionalInfo;
  @observable errors: Map<AcceptanceErrors, string> = new Map();
  @observable isRequisitesOpen: boolean = false;
  protected actions: DeliveryActions;
  protected readonly currentDate: string = new Date().toISOString();
  public readonly maxDate: string;
  public readonly minDate: string;

  constructor(
    readonly deliveryStore: IAcceptanceStore,
    readonly documentStore: ISignatureStore,
    readonly userStore: IUserStore,
    type: DeliveryType
  ) {
    this.actions = new DeliveryActions(deliveryStore, documentStore, userStore, type);

    if (this.deliveryStore.selectedDelivery.status === DeliveryStatus.CreatingDocument) {
      this.actions.startPolling(this.delivery.id);
    }

    if (this.activeDelivery && this.activeDelivery.isActive) {
      this.isDeliveryOpenedState.set(this.activeDelivery.id, true);
    }

    this.additionalInfo = new CodesAdditionalInfo(this.delivery.id, this.delivery.allItemsCodes);

    this.maxDate = this.currentDate;
    this.minDate = this.delivery.minReportDate;
  }
  //#region Computed
  // поставка в которой надо совершить действие
  @computed
  get activeDelivery() {
    return this.delivery.childDeliveries.find(child => child.isActive) || this.delivery;
  }

  @computed
  get delivery(): AcceptanceDeliveryModel {
    return this.deliveryStore.selectedDelivery;
  }

  @computed
  get hasDocuments() {
    return this.delivery.hasDocuments;
  }

  @computed
  get supplier(): CounteragentInfoModel {
    return this.delivery.supplier;
  }

  @computed
  get recipient() {
    return this.delivery.recipient;
  }

  @computed
  get consignorInn(): string {
    return this.delivery.supplier.inn;
  }

  @computed
  get stages(): Record<DeliveryStage | string, IStage> {
    return this.delivery.stages;
  }

  @computed
  get modalMap() {
    return this.actions.modalMap;
  }

  @computed
  get loadingMap() {
    return this.actions.loadingMap;
  }

  @computed
  get supplierAddress(): string {
    const { counteragentDepartment, supplier } = this.delivery;
    return counteragentDepartment?.address || supplier.address;
  }

  @computed
  get showCompleteStage(): boolean {
    const { status } = this.delivery;
    return (
      status === DeliveryStatus.Success ||
      status === DeliveryStatus.Recalled ||
      status === DeliveryStatus.Rejected ||
      status === DeliveryStatus.PartiallyRejected ||
      status === DeliveryStatus.PartiallyRecalled
    );
  }

  @computed
  get areGoodsEditable(): boolean {
    const { status } = this.activeDelivery;
    return status === DeliveryStatus.New || status === DeliveryStatus.Processing;
  }

  @computed
  get isAllScanned(): boolean {
    return this.activeDelivery.items.every(item => item.isAllScanned);
  }

  @computed
  get isAnyRequisites(): boolean {
    // без toJS, после создания документа, значение isAnyRequisites почему-то берется из кеша
    const { receiveType, contractNumber, contractType, financingType, turnoverType } = toJS(this.delivery);
    return Boolean(receiveType || contractNumber || contractType || financingType || turnoverType);
  }
  //#endregion

  //#region Buttons
  @computed
  get showSignBtn(): boolean {
    const status = this.activeDelivery.status;
    return status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
  }
  //#endregion

  //#region API helpers
  async sign(cert: ICertificate, shouldSignOnlyChild?: boolean) {
    const { childDeliveries } = this.delivery;
    const deliveriesToSign: AcceptanceDeliveryModel[] = [];
    if (this.isDeliveryAvailableToSign(this.delivery) && !shouldSignOnlyChild) {
      deliveriesToSign.push(this.delivery);
    }
    if (childDeliveries) {
      childDeliveries.forEach(d => {
        if (d.status === DeliveryStatus.Signing || d.status === DeliveryStatus.Signed) deliveriesToSign.push(d);
      });
    }
    await this.actions.sign(cert, deliveriesToSign);
  }

  isDeliveryAvailableToSign(delivery: AcceptanceDeliveryModel) {
    const { status, acceptusType, isRecalled, isRejected } = delivery;
    const signing = status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
    const recalledOrRejected = isRecalled || isRejected;
    return (
      signing &&
      (!recalledOrRejected ||
        ((acceptusType === AcceptusType.Direct || acceptusType === AcceptusType.StateDispatch) && recalledOrRejected))
    );
  }

  @action
  prepareDocuments = async () => {
    this.toggleLoading("download");
    try {
      const taskId = await this.deliveryStore.prepareDocuments(this.delivery.id);
      this.actions.startPolling(taskId, "download");
    } catch (e) {
      this.toggleLoading("download");
      throw e;
    }
  };
  //#endregion

  @action.bound
  toggleIsRequisitesOpen() {
    this.isRequisitesOpen = !this.isRequisitesOpen;
  }

  @action
  onCollapseDelivery(key: string, val?: boolean) {
    const isOpened = val !== undefined ? val : !this.isDeliveryOpenedState.get(key);
    this.isDeliveryOpenedState.set(key, isOpened);
  }

  @action
  setSubmitted(val: boolean) {
    this.submitted = val;
  }

  @action
  addChildDelivery() {
    const newDeliveryId = "new";
    this.delivery.addChildDelivery(newDeliveryId);
    this.isDeliveryOpenedState.set("new", true);
  }

  getCompletionUser(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionUser(stepInfo);
  }

  getCompletionDate(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionDate(stepInfo);
  }

  getTitleSize(stage: IStage) {
    return this.actions.getTitleSize(this.delivery.stages, stage);
  }

  handleUnmount() {
    return this.actions.handleUnmount();
  }

  toggleModal(modalName: DetailPageModalNames) {
    return this.actions.toggleModal(modalName);
  }

  toggleLoading(loadingName: DetailPageLoadingName) {
    return this.actions.toggleLoading(loadingName);
  }

  @action
  validateReason(isReject: boolean) {
    if (isReject && !this.activeDelivery.recallReason) {
      this.errors.set("requiredRecallReason", "Укажите причину отказа");
    }
  }

  @action
  validateDate(date?: string) {
    this.errors.delete("invalidReportDate");

    const isFutureDate = date && new Date(date) > new Date();
    if (isFutureDate) {
      this.errors.set(
        "invalidReportDate",
        "Невалидная дата. Дата приемки не должна быть больше текущей даты и времени"
      );
    }

    const isPastDate = date && new Date(date) < new Date(this.delivery.minReportDate);
    if (isPastDate) {
      const minFormattedDate = getFormattedDate(this.delivery.minReportDate, longDateTimeOptions);
      this.errors.set(
        "invalidReportDate",
        `Невалидная дата. Дата приемки не должна быть раньше последней операции по товару – ${minFormattedDate}`
      );
    }
  }
  //#endregion
}
