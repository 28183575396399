import React from "react";
import { observer } from "mobx-react";
import { Button, DateTimePicker } from "components";
import { ExtractionPageVM } from "../../ExtractionPageVM";
import { CodesBlock, DateText, DateWrap, PageInfoBlockWrap, ScanPanel } from "./PageInfoBlock.styles";
import { CodesTable } from "Common/GoodsTable/CodesTable/CodesTable";
import { ScannedLargeText } from "Common/ScannedItemsCount/ScannedItemsCount.styles";
import { getEnding } from "helpers/endings";
import BarcodeScannerIcon from "@skbkontur/react-icons/BarcodeScanner";

interface IPageInfoBlockProps {
  vm: ExtractionPageVM;
}

export const PageInfoBlock: React.FC<IPageInfoBlockProps> = observer((props: IPageInfoBlockProps) => {
  const { vm } = props;

  const getReportDateError = () => vm.errors.get("invalidReportDate");

  const onScan = () => {
    vm.toggleModal("scanPage");
    // blur(), because scanner triggers button click on "enter"
    if (window.document.activeElement && (window.document.activeElement as HTMLElement).blur) {
      (window.document.activeElement as HTMLElement).blur();
    }
  };

  return (
    <PageInfoBlockWrap>
      {vm.isEditable && (
        <DateWrap>
          <DateText>Дата изъятия</DateText>
          <DateTimePicker
            value={vm.reportDate}
            onChange={vm.setReportDate}
            getReportDateError={getReportDateError}
            maxDate={vm.maxDate}
          />
        </DateWrap>
      )}

      {vm.isEditable && (
        <ScanPanel>
          <CodesBlock>
            <ScannedLargeText>{vm.delivery.allCodesLen} </ScannedLargeText>
            &nbsp;&nbsp;{getEnding(vm.delivery.allCodesLen, "код", "кода", "кодов")} маркировки
          </CodesBlock>
          <Button onClick={onScan} size={"large"} icon={<BarcodeScannerIcon />}>
            Вернуться к сканированию
          </Button>
        </ScanPanel>
      )}
      <CodesTable items={vm.delivery.items} additionalInfo={vm.additionalInfo} />
    </PageInfoBlockWrap>
  );
});
