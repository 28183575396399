import FarImage from "./images/far.png";
import MedImage from "./images/med.png";
import NearImage from "./images/near.png";
import { keyframes } from "styles/styled-components";
import styled from "styled-components";
import { colors, textColors } from "styles/colors";

export const Slide = keyframes`
  from {
    background-position: 0 bottom;
  }
  to {
    background-position: -2770px bottom;
  }
`;

export const Root = styled.div`
  position: absolute;
  background: ${colors.white};
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  margin: 0;
  padding: 0;
  min-width: 590px;

  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p {
    margin: 0;
    padding: 0;
  }

  h1 > sup {
    color: ${textColors.gray};
    font-size: 24px;
    font-weight: 400;
  }

  li {
    list-style: none;
  }
  a {
    color: #2270b8;
    text-decoration: none;
  }
  a:visited {
    color: #446d99;
    text-decoration: none;
  }
  a:hover {
    color: #d15a57;
    text-decoration: underline;
  }
`;

export const BackgroundFar = styled.div`
  width: 100%;
  height: 100%;
  background: url(${FarImage}) 0 100% repeat-x;
  animation: ${Slide} 360s linear infinite;
`;

export const BackgroundMed = styled.div`
  width: 100%;
  height: 100%;
  background: url(${MedImage}) 0 100% repeat-x;
  animation: ${Slide} 220s linear infinite;
`;

export const BackgroundNear = styled.div`
  width: 100%;
  height: 100%;
  background: url(${NearImage}) 0 100% repeat-x;
  animation: ${Slide} 100s linear infinite;
`;

export const PageWrapper = styled.div`
  width: 830px;
  margin: 0 auto;
  padding: 40px 30px;
`;

export const LogoLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const Content = styled.div`
  margin: 80px 0 0;
  color: ${textColors.default};
`;

export const TextBlock = styled.div`
  margin: 50px 0 0;
  font-size: 18px;
  line-height: 28px;

  p {
    margin-bottom: 35px;
    max-width: 530px;
  }

  p b {
    font-weight: 600;
  }
`;

export const IconWrap = styled.span`
  position: relative;
  top: 2px;
`;
