import styled from "styles/styled-components";

export const HelpList = styled.ol`
  list-style-type: none;
  padding-inline-start: 30px;

  li {
    margin-bottom: 15px;
    line-height: 22px;
    padding-left: 17px;
    position: relative;
  }
`;

export const HelpListNum = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  margin-right: 5px;
`;

interface IHelpListImgProps {
  imgSrc: string;
  height: string;
}

export const HelpListImg = styled.div<IHelpListImgProps>`
  background-image: ${props => `url(${process.env.PUBLIC_URL}/img/requisites/${props.imgSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 436px;
  height: ${props => props.height};
  margin-left: -17px;
`;

export const WarningWrap = styled.div`
  background-color: ${props => props.theme.colors.yellowPale};
  padding: 12px 10px 15px;
  line-height: 22px;
  display: flex;
  margin-right: 32px;
  margin-left: 31px;
  border-radius: 3px;
`;

export const WarningIconWrap = styled.div`
  color: ${props => props.theme.colors.orangeDark};
  margin-right: 10px;
`;
