import { action, computed, observable, toJS } from "mobx";
import {
  AcceptusType,
  CounteragentDepartmentModel,
  CounteragentInfoModel,
  DeliveryItemModel,
  DeliveryItemStatus,
  DeliveryModel,
  DeliveryStatus,
  DepartmentModel,
  PatchDeliveryModel,
  StepInfoModel,
  UtdPositionModel,
} from "typings/server";
import { AcceptanceStageNames, DeliveryStage } from "Common/DeliveryDictionary";
import { UtdPositionModelExtended } from "./ItemModel/UtdPositionModelExtended";
import { DeliveryItemModelExtended } from "./ItemModel/DeliveryItemModelExtended";
import { copyOnlyExistingProperties } from "helpers/object";
import { DeliveryActions } from "./DeliveryModelActions";
import { IStage } from "./shared";

export class AcceptanceDeliveryModel extends DeliveryModel {
  // шаги приемки для отображения в интерфейсе
  @observable stages: Record<DeliveryStage | string, IStage> = Object.create({});
  @observable items: DeliveryItemModelExtended[];
  @observable utdPositions: UtdPositionModelExtended[];
  @observable status: DeliveryStatus;
  @observable supplier: CounteragentInfoModel;
  @observable confirmPaused: boolean;
  @observable reportDate: string | undefined;
  @observable stepsInfo: { [key in DeliveryStatus]: StepInfoModel };
  @observable childDeliveries: AcceptanceDeliveryModel[] = [];
  @observable counteragentDepartment: CounteragentDepartmentModel;
  @observable recallReason: string = "";
  @observable isRecalled: boolean = false;
  @observable isRejected: boolean = false;
  @observable rejectUnscanned: boolean = false;
  @observable isPartiallyFailed: boolean;
  @observable parentDelivery?: AcceptanceDeliveryModel;
  readonly actions: DeliveryActions;

  constructor(readonly deliveryModel: DeliveryModel) {
    super();
    Object.assign(this, deliveryModel);
    this.deliveryModel = deliveryModel;
    this.actions = new DeliveryActions(deliveryModel);
    this.confirmPaused = deliveryModel.confirmPaused || false;
    if (deliveryModel.items) {
      this.items = this.getExtendedItems(deliveryModel.items);
      if (deliveryModel.utdPositions) {
        this.utdPositions = this.getExtendedUtdPositions(deliveryModel.items, deliveryModel.utdPositions);
      }
    }
    if (deliveryModel.stepsInfo) {
      this.setStages(deliveryModel.stepsInfo);
    }

    if (this.childDeliveries?.length) {
      this.childDeliveries = this.childDeliveries.map(child => {
        const childModel = new AcceptanceDeliveryModel(child);
        childModel.parentDelivery = this;
        return childModel;
      });
    } else this.childDeliveries = [];

    this.isRecalled =
      this.items?.some(x => x.status === DeliveryItemStatus.Recalled) ||
      (this.status === DeliveryStatus.RecallFailed && this.allItemsCodes.length === this.failedCodesLen);

    this.isRejected =
      this.items?.some(x => x.status === DeliveryItemStatus.Rejected) ||
      (this.status === DeliveryStatus.RecallFailed && this.allItemsCodes.length === this.failedCodesLen);

    this.isPartiallyFailed = this.childDeliveries?.some(x => x.status === DeliveryStatus.Failed);
  }

  private getExtendedItems(items: DeliveryItemModel[]): DeliveryItemModelExtended[] {
    const scanned = this.acceptusType === AcceptusType.Reverse || this.acceptusType === AcceptusType.Simplified;
    const groupByName = this.actions.getGroupedCodes(items, { groupBy: "name", isAllScanned: scanned, showGtin: true });
    return this.actions.processItems(items, groupByName, { showGtin: true });
  }

  private getExtendedUtdPositions(
    items: DeliveryItemModel[],
    utdPositions: UtdPositionModel[]
  ): UtdPositionModelExtended[] {
    const scanned = this.acceptusType === AcceptusType.Reverse || this.acceptusType === AcceptusType.Simplified;
    const groupedCodes = this.actions.getGroupedCodes(items, { groupBy: "positionId", isAllScanned: scanned });

    return this.actions.processUtdPositions(utdPositions, groupedCodes);
  }

  /**
   * получаем из stepsInfo шаги приемки для отображения в интерфейсе
   * @param delivery
   */
  @action
  setStages(stepsInfo: { [key in DeliveryStatus]: StepInfoModel }) {
    const mappedSteps = this.actions.mapSteps(stepsInfo);
    this.stages = this.actions.getStages(mappedSteps, AcceptanceStageNames);
  }

  @action
  setAllIncomingCodes() {
    this.items.forEach(item => {
      if (item.isUnprocessedStatus) {
        item.sgtinCodes.forEach(code => code.setScanned(true));
        item.ssccCodes.forEach(code => code.setScanned(true));
      }
    });
  }

  @action
  setItems(items: DeliveryItemModelExtended[]) {
    items.forEach(item => {
      const foundItem = this.items.find(i => i.name === item.name && item.status === i.status);
      if (foundItem) foundItem.setCodes(item.allCodes);
    });
  }

  @action
  setNextStage(status: DeliveryStatus, stageName: DeliveryStage, stage: Partial<IStage>, userName: string) {
    const info = { status: this.status, completionDate: stage.completionDate, userName };
    // update status
    this.status = status;
    this.stages = this.actions.setNextStage(this.stages, stageName, stage);
    this.stepsInfo = this.actions.updateStepInfo(this.stepsInfo, info);
  }

  @action
  update(model: PatchDeliveryModel, acceptusType: AcceptusType, counteragentDepartment?: DepartmentModel) {
    copyOnlyExistingProperties(this, model);
    this.acceptusType = acceptusType;
    if (counteragentDepartment) {
      this.counteragentDepartment = counteragentDepartment;
    }
  }

  @action
  setConfirmPaused(val: boolean) {
    this.confirmPaused = val;
  }

  @action
  setReportDate(val: string | undefined) {
    this.reportDate = val;
  }

  @action
  addChildDelivery(deliveryId?: string) {
    const delivery = new AcceptanceDeliveryModel({
      ...toJS(this.deliveryModel),
      items: [],
      status: DeliveryStatus.Processing,
      childDeliveries: [],
      id: deliveryId || "",
      reportDate: undefined,
    });
    delivery.items = [...this.unprocessedItems];
    if (this.rejectUnscanned) delivery.setRejectUnscanned();
    this.childDeliveries.push(delivery);
  }

  @action
  replaceChildDelivery(delivery: DeliveryModel) {
    const ind = this.childDeliveries.findIndex(d => d.id === delivery.id);
    if (ind !== -1) {
      const acceptanceDelivery = new AcceptanceDeliveryModel(delivery);
      this.childDeliveries.splice(ind, 1, acceptanceDelivery);
    }
  }

  @action
  toggleRejectAllStatus() {
    this.isRejected = !this.isRejected;
    if (this.isRejected) {
      this.setRejectAll();
    } else {
      this.undoRejectAll();
    }
  }

  @action
  setRejectAll() {
    this.items.forEach(item => {
      if (item.isUnprocessedStatus || item.status === DeliveryItemStatus.WaitingForCounterparty) {
        item.setStatus(DeliveryItemStatus.Rejected);
      }
    });
  }

  @action
  undoRejectAll() {
    this.items.forEach(item => {
      if (item.status === DeliveryItemStatus.Rejected) {
        item.setStatus(DeliveryItemStatus.Unprocessed);
      }
    });
  }

  @action
  toggleRejectUnscannedStatus() {
    this.rejectUnscanned = !this.rejectUnscanned;
    if (this.rejectUnscanned) {
      this.setRejectUnscanned();
    } else {
      this.undoRejectUnscanned();
    }
  }

  @action
  private setRejectUnscanned() {
    this.items.forEach(item => {
      if (item.isUnprocessedStatus) {
        const hasItemScannedCodes = item.ssccScanLen || item.sgtinScanLen;
        if (!hasItemScannedCodes) item.setStatus(DeliveryItemStatus.Rejected);
        else if (!item.isAllScanned) {
          const newItem = item.moveScannedCodesToNewItem();
          if (newItem) {
            this.items.push(newItem!);
          }
          item.setStatus(DeliveryItemStatus.Rejected);
        }
      }
    });
  }

  @action
  private undoRejectUnscanned() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.status === DeliveryItemStatus.Rejected) {
        const duplicate = this.items.find(x => x.name === item.name && x.status === DeliveryItemStatus.Unprocessed);
        item.setStatus(DeliveryItemStatus.Unprocessed);
        if (duplicate) {
          duplicate.addCodes(this.items[i].allCodes);
          this.items.splice(i, 1);
        }
      }
    }
  }

  @computed
  get unprocessedItems(): DeliveryItemModelExtended[] {
    return this.items.filter(item => item && item.isUnprocessedStatus);
  }

  @computed
  get allScannedLen(): number {
    return this.items.reduce((count: number, item) => (count += item.ssccScanLen + item.sgtinScanLen), 0);
  }

  @computed
  get allCodesLen() {
    if (this.utdPositions && this.utdPositions.length) {
      return this.utdPositions.reduce(
        (sum: number, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length),
        0
      );
    }

    return this.items.reduce((sum: number, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length), 0);
  }

  @computed
  get allItemsCodes(): string[] {
    return this.items.map(item => item.allCodes.map(codeItem => codeItem.code)).flat();
  }

  // поставка активна, если с ней можно совершить действие и у нее нет подпоставок
  @computed
  get isActive(): boolean {
    const editable = [
      DeliveryStatus.Processing,
      DeliveryStatus.New,
      DeliveryStatus.Signing,
      DeliveryStatus.Signed,
      DeliveryStatus.CreatingDocument,
    ];
    return editable.includes(this.status) && this.childDeliveries.length === 0;
  }

  @computed
  get minReportDate(): string {
    return this.stepsInfo[DeliveryStatus.Draft]?.completionDate;
  }

  @computed
  get acceptedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Accepted);
  }

  @computed
  get failedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Error);
  }

  @computed
  get recalledCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Recalled);
  }

  @computed
  get rejectedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Rejected);
  }

  private getDeliveryCodesLenByStatus(status: DeliveryItemStatus): number {
    return this.items.reduce(
      (count: number, item: DeliveryItemModelExtended) =>
        (count += item.status === status && item.allCodes ? item.allCodes.length : 0),
      0
    );
  }
}
