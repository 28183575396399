import React from "react";
import { observer } from "mobx-react";
import { Warning } from "@skbkontur/react-icons";
import { IconWrap, WarningBlock } from "Common/Confirmation/DeliveryConfirmation.styles";

interface IConfirmationTextProps {
  unscannedLen: number;
}

export const SimplifiedWarning = observer((props: IConfirmationTextProps) => {
  const { unscannedLen } = props;
  return (
    <>
      {!unscannedLen && "В ИС МДЛП будут отправлены сведения о приемке по упрощенной 702 схеме."}
      <WarningBlock>
        <IconWrap>
          <Warning />
        </IconWrap>
        <span>
          Упрощенную схему рекомендуется использовать <br />
          только в случае возникновения нештатных ситуаций <br />
          при работе с ИС МДЛП
        </span>
      </WarningBlock>
    </>
  );
});
