import { action, computed, observable } from "mobx";
import { DeliveryType, DepartmentCountersModel, SyncInfoModel, SyncResult } from "typings/server";
import { DeliveriesApi } from "api/DeliveryApi";
import { Polling } from "helpers/polling";
import { POLL_INTERVAL, SYNC_POLL_INTERVAL } from "./shared";
import { UserStore } from "./UserStore";

const POLLING_DELAY = 10 * 60 * 1000;

export interface ISyncPollingOptions {
  fireImmediately?: boolean;
  interval?: number;
}

export type DeliveriesCountDictModel = Record<string, DepartmentCountersModel | undefined>;
export class DeliveryStoreInternal {
  @observable public counters: DeliveriesCountDictModel = {};

  @observable private syncInfo: SyncInfoModel;
  private readonly pollSyncInfo: Polling;

  constructor() {
    this.pollSyncInfo = new Polling(this.pollSyncInfoFunc.bind(this), { maxTryCount: 100 });
  }

  private async pollSyncInfoFunc(callback?: () => Promise<void>) {
    const syncInfo = await DeliveriesApi.getSyncInfo();
    if (syncInfo) {
      const syncStartTime = this.getSyncStartTime(syncInfo);
      // вызываем коллбэк когда обновилось время синхронизации, либо когда синхронизации еще не происходило, и ответ Success
      if (
        this.syncTime && syncStartTime
          ? syncStartTime > this.syncTime
          : syncInfo.deliveriesSyncStatusResult === SyncResult.Success
      ) {
        if (callback) await callback();
      }
      this.syncInfo = syncInfo;
      if (this.syncResult === SyncResult.InProcess) {
        this.pollSyncInfo.changeInterval(SYNC_POLL_INTERVAL);
      } else {
        this.pollSyncInfo.changeInterval(POLL_INTERVAL);
      }
    }
  }

  @action
  async restartSyncInfoPolling(callback: () => Promise<void>, options?: ISyncPollingOptions) {
    if (this.pollSyncInfo.inProcess) {
      this.pollSyncInfo.stop();
    }
    if (options && options.fireImmediately) {
      this.syncInfo = await DeliveriesApi.getSyncInfo();
    }
    const interval = (options && options.interval) || POLL_INTERVAL;
    this.pollSyncInfo.start(callback, interval || POLL_INTERVAL);
  }

  stopSyncInfoPolling() {
    if (this.pollSyncInfo.inProcess) this.pollSyncInfo.stop();
  }

  @action
  public async loadFreshDeliveriesCount(): Promise<DeliveriesCountDictModel> {
    const rawData = await DeliveriesApi.getCounters();
    // Положим значения каунтеров подразделений в мапу
    this.counters = rawData.departmentsCounters.reduce<DeliveriesCountDictModel>((acc, cur) => {
      acc[cur.departmentId] = cur;
      return acc;
    }, {});

    return this.counters;
  }

  @action
  public initPollingFreshDeliveriesCount() {
    this.loadFreshDeliveriesCount();
    new Polling(DeliveriesStore.loadFreshDeliveriesCount.bind(DeliveriesStore)).start(null, POLLING_DELAY);
  }

  @action
  decrementCounter(type: DeliveryType) {
    const currentDepartmentId = UserStore.user ? UserStore.currentDepartment?.id : undefined;

    if (!currentDepartmentId) {
      return;
    }

    const currentCounter = this.counters[currentDepartmentId];

    if (!currentCounter) {
      return;
    }

    switch (type) {
      case DeliveryType.Acceptance:
        if (!!currentCounter.newAccepancesCount) {
          currentCounter.newAccepancesCount--;
        }
        break;
      case DeliveryType.Shipment:
        if (!!currentCounter.newShipmentsCount) {
          currentCounter.newShipmentsCount--;
        }
        break;
      case DeliveryType.Destruction:
        if (!!currentCounter.newDestructionsCount) {
          currentCounter.newDestructionsCount--;
        }
        break;
    }
  }

  // get earlier sync date
  @computed
  get syncTime(): Date | null {
    if (this.syncInfo) {
      return this.getSyncStartTime(this.syncInfo);
    }
    return null;
  }

  // get min sync date
  private getSyncStartTime(syncInfo: SyncInfoModel): Date | null {
    if (!syncInfo || !syncInfo.deliveriesSyncStatusTime) return null;
    return new Date(syncInfo.deliveriesSyncStatusTime!);
  }

  @computed
  get syncResult(): SyncResult | undefined {
    if (!this.syncInfo) return undefined;
    return this.syncInfo.deliveriesSyncStatusResult;
  }

  @computed
  get syncErrorMessage(): string | undefined {
    if (!this.syncInfo) return undefined;
    return this.syncInfo.deliveriesSyncStatusError;
  }
}

export interface IDeliveryStore extends DeliveryStoreInternal {}

export const DeliveriesStore = new DeliveryStoreInternal();
