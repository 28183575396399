import React from "react";
import { LinkProps } from "react-router-dom";
import { StyledLink } from "./RouterLink.styles";

export function RouterLink(props: LinkProps) {
  return (
    // @ts-ignore
    <StyledLink {...props} />
  );
}
