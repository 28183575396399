import { brandColors, colors, textColors } from "./colors";

export const theme = {
  layout: {
    minWidth: "900px",
    maxWidth: "1340px",
  },
  contentWrap: {
    fixedWidth: "838px",
    minHeight: "calc(100vh - 90px)",
    shadow: "0 2px 5px 0 rgba(0, 0, 0, 0.25)",
    display: "flex",
    maxWidth: "1236px",
  },
  content: {
    padding: "24px",
  },
  font: {
    defaultSize: "14px",
    mediumSize: "16px",
  },
  btn: {
    paddingTopM: "9px",
    btnDisabledShadow: "0 0 0 1px rgba(0, 0, 0, 0.15)",
  },
  table: {
    thPadding: "8px 16px",
    tdPadding: "16px",
  },
  colors: colors,
  brandColors: brandColors,
  textColors: textColors,
};

export type IThemeInterface = typeof theme;
