const EMPTY_OBJ_IN_LS = "{}";

type LSParams<T> = {
  value: T | undefined;
  key: string;
  scope: string;
  organizationId: string | undefined;
  counteragentInn: string;
};

// хэлпер для сохранения значения в LS в рамках конкретной организации, ИННа и скоупа.
export const setLSValueInScope = <T>(params: LSParams<T>) => {
  //  organizationId является ключём в localStorage
  //  По ключу хранится stringify-объект (хэш-мапа) объектов вида { key: value },
  //  где key мы генерируем с помощью функции getFullKey
  if (!params.value || !params.organizationId) {
    return;
  }
  const valueInLS = JSON.parse(localStorage.getItem(params.organizationId) || EMPTY_OBJ_IN_LS);
  const key = getFullKey({ key: params.key, counteragentInn: params.counteragentInn, scope: params.scope });

  valueInLS[key] = params.value;

  localStorage.setItem(params.organizationId, JSON.stringify(valueInLS));
};

export const getLSValueOrSetIfNotExist = <T>(params: LSParams<T>): T | undefined => {
  if (!params.value || !params.organizationId) {
    return;
  }

  const valueInLS = JSON.parse(localStorage.getItem(params.organizationId) || EMPTY_OBJ_IN_LS);
  const key = getFullKey({ key: params.key, counteragentInn: params.counteragentInn, scope: params.scope });

  // хэлпер вернет значение из LS, если оно там есть
  if (valueInLS[key]) {
    return valueInLS[key];
  }
  // Иначе - сохранит переданное значение и вернет его же
  setLSValueInScope(params);
  return params.value;
};

const getFullKey = (params: { scope: string; counteragentInn: string; key: string }) => {
  const { scope, counteragentInn, key } = params;
  return `${scope}-${key}-${counteragentInn}`;
};
