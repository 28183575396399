import { getHttpClient } from "./shared";
import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import {
  CodesReportModel,
  CreateRecallModel,
  CreateRefusalModel,
  DeliveryItemReportModel,
  DestructionCause,
  PositionsReportModel,
} from "typings/server";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";

interface IReportApiInternal {
  saveCodes(model: CodesReportModel): Promise<void>;
  send(id: string): Promise<void>;
}

class ReportApiInternal implements IReportApiInternal {
  constructor(private httpClient: IHttpClient) {}

  saveCodes(model: Partial<CodesReportModelExtended>): Promise<void> {
    return this.httpClient.post("draft/codes", model);
  }

  savePositions(model: Partial<PositionsReportModel>): Promise<void> {
    return this.httpClient.post("draft/positions", model);
  }

  saveItems(items: DeliveryItemReportModel[], deliveryId: string): Promise<void> {
    return this.httpClient.post("draft/items", items, { deliveryId });
  }

  send(deliveryId: string): Promise<void> {
    return this.httpClient.post("send", null, { deliveryId });
  }

  create(deliveryId: string, destruction?: { destructionCause?: DestructionCause }): Promise<void> {
    return this.httpClient.post("create", { destructionCause: destruction?.destructionCause }, { deliveryId });
  }

  recall(model: CreateRecallModel): Promise<void> {
    return this.httpClient.post("recall", model);
  }

  refuse(model: CreateRefusalModel): Promise<void> {
    return this.httpClient.post("refusal", model);
  }

  reject(model: CreateRefusalModel): Promise<void> {
    return this.httpClient.post("reject", model);
  }

  resend(deliveryId: string): Promise<void> {
    return this.httpClient.post("resend", undefined, { deliveryId });
  }
}

export const ReportsApi = new ReportApiInternal(getHttpClient(drugsApi + "/reports"));
