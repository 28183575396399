import { getHttpClient } from "./shared";
import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { PostAuthCodeModel } from "typings/server";
import { DownloadResult } from "typings/client";

interface IMdlpAuthApiInternal {}

class MdlpAuthApiInternal implements IMdlpAuthApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getCode(thumbprint: string): Promise<DownloadResult> {
    return this.httpClient.download("code", { thumbprint });
  }

  signCode(data: PostAuthCodeModel): Promise<void> {
    return this.httpClient.post("code", data);
  }

  check(): Promise<any> {
    return this.httpClient.get("check");
  }
}

export const MdlpAuthApi = new MdlpAuthApiInternal(getHttpClient(drugsApi + "/mdlpAuth"));
