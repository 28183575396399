import { action, observable } from "mobx";
import { CryptoProviderType, ICertificate, StoreType } from "@skbkontur/plugin-js";
import { NOT_FOUND_STATUS } from "api/shared";

type TCertificateListError = "certNotFound" | "defaultSignError";

export class CertificateListVM {
  @observable errors: Map<TCertificateListError, boolean> = new Map();
  @observable certificate: ICertificate;
  @observable loading: boolean = false;
  @observable isSigned: boolean = false;
  @observable mdlpErrorDescription: string;
  static readonly storeTypes = [StoreType.CryptoApi, StoreType.Pkcs11];

  constructor(
    private readonly onSignCallback: (c: ICertificate) => Promise<any>,
    private readonly onCloseCallback: () => void,
    private readonly onDismissCallback?: () => void
  ) {}

  @action
  async onSign(): Promise<void> {
    if (this.certificate) {
      this.clearErrors();
      this.setLoading(true);
      try {
        await this.onSignCallback(this.certificate);
        this.isSigned = true;
      } catch (e: any) {
        if (e && e.jsonMsg && e.jsonMsg.statusCode === NOT_FOUND_STATUS) {
          this.setError("certNotFound", true);
        } else {
          this.setError("defaultSignError", true);

          // MDLP error description
          if (e && e.jsonMsg && e.jsonMsg.description) {
            this.mdlpErrorDescription = e.jsonMsg.description;
          }
        }
        return Promise.reject(e);
      } finally {
        this.setLoading(false);
      }
    }
  }

  // смотреть в поле printableCertificate.cryptoProvider серта
  static mdlpTestProviderId = 80;

  static filterCertificate = (certificate: ICertificate) => {
    const provider = certificate.cryptoProvider();
    const isTrustedProvider =
      provider &&
      provider!.type === CryptoProviderType.CryptoApi &&
      provider!.id === CertificateListVM.mdlpTestProviderId;
    return certificate.isQualified() || !!isTrustedProvider;
  };

  @action
  setCertificate = (certificate: ICertificate) => {
    this.certificate = certificate;
  };

  @action
  private setLoading(val: boolean) {
    this.loading = val;
  }

  @action
  setError(type: TCertificateListError, val: boolean) {
    this.errors.set(type, val);
  }

  @action
  clearErrors() {
    this.mdlpErrorDescription = "";
    this.errors.clear();
  }

  onDismiss = () => {
    if (this.onDismissCallback) {
      this.onDismissCallback();
    } else {
      this.onClose();
    }
  };

  onClose = () => this.onCloseCallback();
}
