// todo: enable autoprefixer

import styled from "styles/styled-components";

interface GridCellProps {
  col?: number | string;
  colStart?: number | string; // for IE support
  colEnd?: number | string; // for IE support
  row?: number | string;
  textAlign?: "left" | "right" | "center";
  breakWord?: "break-word" | "break-all" | "normal";
  whiteSpace?: "nowrap" | "pre" | "pre-line" | "pre-wrap" | "normal";
  minHeight?: string;
  colSp?: string; // for IE support. For example, colStart = "2", colEnd = "3" colSp="2" - cell starts from 2 column to 3 column.
  padding?: string;
  margin?: string;
  alignSelf?: "start" | "end" | "baseline" | "center";
  borderRight?: boolean;
  collapsible?: boolean;
  fullHeight?: boolean;
}

export const GridCell = styled.div<GridCellProps>`
  grid-column: ${props => props.col};
  grid-column-start: ${props => props.colStart};
  grid-column-end: ${props => props.colEnd};
  -ms-grid-column: ${props => props.colStart || props.col};
  -ms-grid-column-span: ${props => props.colSp};
  grid-row: ${props => props.row};
  -ms-grid-row: ${props => props.row};
  text-align: ${props => props.textAlign || "left"};
  word-break: ${props => props.breakWord || "break-word"};
  min-height: ${props => props.minHeight};
  padding: ${props => `${props.padding} !important`};
  margin: ${props => props.margin};
  align-self: ${props => props.alignSelf};
  border-right: ${props => props.borderRight && `1px solid ${props.theme.colors.darkBackground}`};
  cursor: ${props => (props.collapsible ? "pointer" : "inherit")};
  height: ${props => props.fullHeight && "100%"};

  &:hover {
    background-color: ${props => props.collapsible && props.theme.colors.lightBackground};
  }
`;

export const GridCellHeader = styled.div<GridCellProps>`
  grid-column: ${props => props.col};
  grid-column-start: ${props => props.colStart};
  grid-column-end: ${props => props.colEnd};
  -ms-grid-column: ${props => props.colStart || props.col};
  -ms-grid-column-span: ${props => props.colSp};
  grid-row: ${props => props.row};
  -ms-grid-row: ${props => props.row};
  text-align: ${props => props.textAlign || "left"};
  white-space: ${props => props.whiteSpace || "nowrap"};
  word-break: ${props => props.breakWord || "break-word"};
  color: ${props => props.theme.textColors.lightenGray};
  padding: ${props => `${props.padding} !important`};
  font-size: 12px;
  border-right: ${props => props.borderRight && `1px solid ${props.theme.colors.darkBackground}`};
`;

interface GridProps {
  cols: string; // 1f 10px 1fr
  rows?: string; // repeat(100px 10px 100px 10px 100px);
  paddingCells?: string;
  paddingHeaderCells?: string;
  headerBorderBottom?: boolean;
  cellsBorderBottom?: boolean;
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  lineHeight?: string;
  fullSize?: boolean;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  display: -ms-grid;
  grid-template-columns: ${props => props.cols};
  -ms-grid-columns: ${props => props.cols};
  grid-template-rows: ${props => props.rows};
  -ms-grid-rows: ${props => props.rows};
  align-items: ${props => props.alignItems};
  width: ${props => props.fullSize && "100%"};

  ${GridCellHeader} {
    border-bottom: ${props => props.headerBorderBottom && "1px solid"};
    border-bottom-color: ${props => props.headerBorderBottom && props.theme.colors.darkBackground};
    padding: ${props => props.paddingHeaderCells || "8px 4px 0px"};
  }

  ${GridCell} {
    border-bottom: ${props => props.cellsBorderBottom && "1px solid"};
    border-bottom-color: ${props => props.cellsBorderBottom && props.theme.colors.lightBackground};
    padding: ${props => props.paddingCells || "8px 4px"};
    line-height: ${props => props.lineHeight || "20px"};
  }
`;

export const GridHeaderWrap = styled.div`
  background-color: ${props => props.theme.colors.greyLight};
`;
