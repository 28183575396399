import React from "react";
import { observer } from "mobx-react";
import { Button, Modal } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { DisposalRegistratorEditorModalVM } from "./DisposalRegistratorEditorModalVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { UserStore } from "stores/UserStore";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { EditorFields } from "../EditorFields";
import { DocumentHeader, ModalBodyInner } from "../EditorModal.styles";
import { HeaderComment } from "../DisposalEditorModal/DisposalEditorModal.styles";
import { RegistratorEmbeddedModel } from "typings/server";
import { CirculationSubRoute } from "routes/CirculationRoute";

interface IOperationInternalModalProps extends IOperationModalProps, RouteComponentProps<CirculationRoute> {
  vm: DisposalRegistratorEditorModalVM;
}

const DisposalRegistratorModalInternal = withRouter(
  observer((props: IOperationInternalModalProps) => {
    const { vm, history } = props;

    const onClose = () => props.onClose();

    const onSave = async () => {
      try {
        const id = await vm.save();
        if (id) {
          history.push(CirculationRoute.getHref(id, CirculationSubRoute.withdrawal));
        } else {
          onClose();
        }
      } catch (e) {
        // empty promise for validation fail
        if (e) {
          throw e;
        }
      }
    };

    return (
      <Modal data-tid="DisposalRegistratorEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>
          Вывод из оборота через регистратор выбытия
          <HeaderComment>Для оказания медицинской помощи</HeaderComment>
        </Modal.Header>
        <Modal.Body>
          <ModalBodyInner>
            <Grid cols={"130px 1fr"}>
              <EditorFields.Sender vm={vm} required row={1} />

              <EditorFields.Registrator vm={vm} required row={2} />

              <GridCell colStart={1} colEnd={3} row={3}>
                <DocumentHeader>Документ-основание</DocumentHeader>
              </GridCell>

              <EditorFields.DocumentType vm={vm} required row={4} />

              <EditorFields.DocumentNumber vm={vm} required row={5} />

              <EditorFields.DocumentDate vm={vm} required row={6} />
            </Grid>
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer panel>
          <FooterBtnWrapper>
            <Button data-tid="Submit" use="primary" size={"large"} onClick={onSave}>
              {vm.isNew ? "Добавить вывод из оборота" : "Сохранить"}
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  })
);

interface IOperationModalProps {
  registrators: RegistratorEmbeddedModel[];
  delivery?: WithdrawalDeliveryModel;
  onClose(): void;
}

// React.memo + () => true - не обновляет компонент
export const DisposalRegistratorEditorModal = React.memo(
  (props: IOperationModalProps) => {
    const { delivery, registrators } = props;
    const vm = new DisposalRegistratorEditorModalVM(WithdrawalStore, UserStore, registrators, delivery);
    return <DisposalRegistratorModalInternal {...props} vm={vm} />;
  },
  () => true
);
