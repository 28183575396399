import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { GoodsWrapperBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatus } from "typings/server";
import { StagesPartVM } from "../../Common/Stages/StagesPartVM";
import { ReverseAcceptancePageVM } from "../ReverseAcceptancePageVM";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { AcceptedCodes, FailedCodes, RecalledCodes, RejectedCodes } from "Common/Stages/CodesStatuses/CodesStatuses";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { GoodsScannerTable } from "Common/GoodsTable/GoodsScannerTable/GoodsScannerTable";
import { GoodsTableWrap, RecalledWrap, ScanText } from "Common/Stages/Stages.styles";
import { DeliveryStatusBlock, DeliveryStatusBlockProps } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliverySendingSteps } from "Common/Stages/DeliverySendingSteps/DeliverySendingSteps";
import { AcceptanceDeliveryStatusNames } from "Common/Status/StatusesName";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { CirculationWarning } from "Common/CirculationWarning/CirculationWarning";
import { GoodsTable } from "Common/GoodsTable/GoodsTable/GoodsTable";

interface IAcceptanceStagesPartProps {
  delivery: AcceptanceDeliveryModel;
  vm: ReverseAcceptancePageVM;
  isPartial?: boolean;
  overrideFields?: Partial<DeliveryStatusBlockProps>;
  parentDelivery?: AcceptanceDeliveryModel;
  hideChildren?: boolean;
}

export const ReversedAcceptanceStagesPart = observer(
  ({ delivery, vm, isPartial, overrideFields, parentDelivery, hideChildren }: IAcceptanceStagesPartProps) => {
    const stagesVM = useMemo(() => new StagesPartVM(delivery, vm.additionalInfo, isPartial, parentDelivery), [
      delivery,
    ]);

    const onClick = (good: UtdPositionModelExtended) => {
      vm.setSelectedGood(good);
      vm.toggleModal("scanPage");
    };

    const onCollapse = () => vm.onCollapseDelivery(delivery.id);

    return (
      <DeliveryStatusBlock
        size={delivery.isActive ? "large" : "medium"}
        name={stagesVM.title}
        status={delivery.stages[DeliveryStage.Processing].progress}
        collapsed={!vm.isDeliveryOpenedState.get(delivery.id)}
        alwaysVisibleComp={
          <>
            <DeliverySendingSteps
              vm={stagesVM}
              delivery={delivery}
              stages={delivery.stages}
              statusNames={AcceptanceDeliveryStatusNames}
              parentDelivery={parentDelivery}
            />
            {stagesVM.isShowCirculationWarning && <CirculationWarning isError={stagesVM.isFailedStatus} />}
          </>
        }
        onCollapse={onCollapse}
        error={stagesVM.isFailedStatus}
        isRejected={stagesVM.isRejected}
        isRecalled={delivery.status === DeliveryStatus.Recalled}
        {...overrideFields}
      >
        {!hideChildren && (
          <GoodsWrapperBlock>
            {vm.areGoodsEditable ? (
              <>
                <ScanText>Выберите товар из списка и отсканируйте коды маркировки</ScanText>
                <GoodsScannerTable
                  goods={delivery.utdPositions}
                  onClick={onClick}
                  editable={vm.areGoodsEditable}
                  additionalInfo={vm.additionalInfo}
                />
              </>
            ) : (
              <GoodsTableWrap>
                <RecalledWrap>
                  <AcceptedCodes
                    delivery={delivery}
                    acceptedCodesLen={delivery.acceptedCodesLen}
                    isAgreed={stagesVM.isDeliveryAgreed}
                  />
                  <FailedCodes failedCodesLen={delivery.failedCodesLen} />
                  <RecalledCodes recalledCodesLen={delivery.recalledCodesLen} />
                  <RejectedCodes delivery={delivery} vm={vm} rejectedCodesLen={delivery.rejectedCodesLen} />
                </RecalledWrap>
                {!!delivery.parentDelivery ? (
                  <GoodsTable goods={delivery.items} additionalInfo={vm.additionalInfo} />
                ) : (
                  <GoodsScannerTable
                    goods={delivery.utdPositions}
                    onClick={onClick}
                    editable={vm.areGoodsEditable}
                    additionalInfo={vm.additionalInfo}
                  />
                )}
              </GoodsTableWrap>
            )}
          </GoodsWrapperBlock>
        )}
      </DeliveryStatusBlock>
    );
  }
);
