import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { MobileTokenModel, UserInfoModel } from "typings/server";
import { getHttpClient } from "./shared";

interface IUserApiInternal {
  getInfo(): Promise<UserInfoModel>;
}

class UserApiInternal implements IUserApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getInfo(): Promise<UserInfoModel> {
    return this.httpClient.get("");
  }

  chooseDepartment(departmentId: string): Promise<void> {
    return this.httpClient.post(`chooseDepartment/${departmentId}`);
  }

  chooseOrganizationById(organizationId: string): Promise<void> {
    return this.httpClient.post(`chooseOrganization/${organizationId}`);
  }

  chooseOrganizationByInn(inn: string): Promise<void> {
    return this.httpClient.post(`chooseOrganization`, null, { inn });
  }

  logout(): Promise<void> {
    return this.httpClient.post(`logout`);
  }

  getToken(): Promise<MobileTokenModel> {
    return this.httpClient.post("mobile");
  }
}

export const UserApi = new UserApiInternal(getHttpClient(drugsApi + "/user"));
