import { SentryTags } from "helpers/logger";

export class RegistratorDisposeTaskError extends Error {
  public sentryTags: SentryTags;

  constructor(cause: Error, sentryTags: SentryTags) {
    super("Произошла ошибка при отправке на регистратор выбытия.", { cause });
    Object.setPrototypeOf(this, RegistratorDisposeTaskError.prototype);
    this.sentryTags = sentryTags;
  }
}
