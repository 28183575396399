import React, { FC, memo } from "react";
import WarningIcon from "@skbkontur/react-icons/Warning";
import HelpBook from "@skbkontur/react-icons/HelpBook";
import { reentryInstruction } from "StringResx";
import { HelpBookIconWrap, WarningIconWrap, TooltipRoot, TooltipUp, TooltipBottom } from "../EditorModal.styles";
import { Link } from "components";

export const HelpTooltip: FC = memo(() => {
  return (
    <TooltipRoot>
      <TooltipUp>
        <TooltipUp>
          Повторный ввод в оборот возможен для товаров,
          <br />
          не требующих переупаковки или перемаркировки,
          <br />
          выведенных ранее из оборота по причинам:
          <br />
        </TooltipUp>
        – выборочный контроль
        <br />
        – отбор образцов
        <br />
        – отгрузка незарегистрированному участнику
        <br />
        – отгрузка в ЕАЭС
        <br />
        – продажа, отпуск по льготному рецепту
        <br />
        или выдача для оказания медицинской помощи
        <br />
        (только для товаров ненадлежащего качества)
        <br />
        – списание
        <br />– экспорт, реэкспорт
      </TooltipUp>
      <TooltipBottom>
        <WarningIconWrap>
          <WarningIcon />
        </WarningIconWrap>
        Повторный ввод в оборот может выполнить
        <br />
        только организация, которая ранее выводила
        <br />
        товары из оборота.
      </TooltipBottom>

      <Link
        data-tid="InfoLink"
        href={reentryInstruction}
        icon={
          <HelpBookIconWrap>
            <HelpBook />
          </HelpBookIconWrap>
        }
        target="_blank"
      >
        Инструкция по повторному вводу в оборот
      </Link>
    </TooltipRoot>
  );
});
