import styled from "styles/styled-components";
import { mediaWidth } from "styles/media";

export const HeaderMenuAdditionalItem = styled.div``;

export const HelpWrap = styled.div`
  margin-top: -1px;
`;

export const HelpWrapText = styled.span`
  margin-left: 5px;

  @media (max-width: ${mediaWidth.notebook}) {
    display: none;
  }
`;

export const DepartmentTextWrap = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;

  @media (max-width: ${mediaWidth.notebook}) {
    max-width: 300px;
  }
`;

export const IconWrap = styled.div`
  margin-left: 5px;
  top: 15px;
`;

export const DepWrap = styled.div`
  display: flex;
`;

export const UserWrap = styled.div`
  max-width: 280px;
  overflow: hidden;

  @media (max-width: ${mediaWidth.notebook}) {
    display: none;
  }
`;

export const SearchWrap = styled.div`
  padding: 2px 9px 7px;
`;

export const DepartmentsWrap = styled.div`
  max-height: 480px;
  // не убирать, фиксит баг, когда при max-height не показывается полоска футера DropdownMenu
  margin-bottom: 5px;
`;

export const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${props => props.theme.colors.orangeDark};
  position: absolute;
  top: 6px;
  right: 2px;
`;

export const EndChildren = styled.div`
  display: flex;
  margin-top: 2px;
`;
