import styled from "styles/styled-components";

export const Container = styled.div`
  margin-left: 30px;
  margin-top: 10px;
`;

export const ScanPanel = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
`;

export const ScanPanelText = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;
`;

export const CodesBlock = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 34px;
`;
