import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { Button, Loader, Modal } from "components";
import { Footer, FooterBtnWrapper } from "components/Layout/Footer.styles";
import { LoaderMinHeight, UtdModalWrap } from "./UtdModal.styles";
import { UtdModalVM } from "./UtdModalVM";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { UtdDropZone } from "./DropZone";
import { AcceptusType, DeliveryType, NewDeliveryModel } from "typings/server";
import { IAcceptanceStore } from "stores/AcceptanceStore";
import { IShipmentStore } from "stores/ShipmentStore";
import { UserStore } from "stores/UserStore";
import { FileRejection } from "react-dropzone";

interface IUpdModalProps {
  onClose(): void;
  type: DeliveryType;
  title: ReactNode;
  btnText: string;
  baseRoute: string;
  acceptusType?: AcceptusType;
  newDeliveryBlockFunc(delivery: NewDeliveryModel, fileName: string, dep: string): JSX.Element;
  store: IAcceptanceStore | IShipmentStore;
}

interface IUpdModalInternalProps extends IUpdModalProps, RouteComponentProps {
  vm: UtdModalVM;
}

const UtdModalInternal = withRouter(
  observer((props: IUpdModalInternalProps) => {
    const { vm, history, newDeliveryBlockFunc, title, baseRoute, btnText } = props;
    const onClose = () => props.onClose();

    const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const [file] = acceptedFiles;
      if (file) {
        await vm.uploadUtd(file);
      }
      if (fileRejections.length) {
        vm.setError(
          "wrongFormat",
          `Неверный формат файла.
           Загрузите УПД или товарную накладную в формате xml.`
        );
      }
    };

    const addDelivery = async () => {
      await vm.addDelivery();
      history.push(`${baseRoute}/${vm.newDelivery.id}?new`);
    };

    return (
      <Modal data-tid="UtdModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>{title || "Загрузка товарной накладной"}</Modal.Header>
        <Modal.Body>
          <UtdModalWrap>
            <Loader active={vm.loading} minHeight={LoaderMinHeight}>
              {vm.newDelivery ? (
                newDeliveryBlockFunc(vm.newDelivery, vm.updFileName, vm.currentDepartmentAddress)
              ) : (
                <UtdDropZone vm={vm} onDrop={onDrop} />
              )}
            </Loader>
          </UtdModalWrap>
        </Modal.Body>
        <Footer>
          <FooterBtnWrapper>
            {vm.newDelivery && (
              <Button data-tid="Add" use={"primary"} size={"large"} onClick={addDelivery}>
                {btnText}
              </Button>
            )}
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Закрыть
            </Button>
          </FooterBtnWrapper>
        </Footer>
      </Modal>
    );
  })
);

// React.memo + () => true - не обновляет компонент
// https://yt.skbkontur.ru/issue/MARKDRUGS-74
export const UtdModal = React.memo(
  (props: IUpdModalProps) => {
    const { type, acceptusType } = props;
    const vm = new UtdModalVM(props.store, UserStore, type, acceptusType);
    return <UtdModalInternal {...props} vm={vm} />;
  },
  () => true
);
