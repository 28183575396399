import React, { useMemo, useRef, KeyboardEvent } from "react";
import { observer } from "mobx-react";
import { theme } from "styles/theme";
import { UserStore } from "stores/UserStore";
import {
  DepartmentTextWrap,
  DepWrap,
  HeaderMenuAdditionalItem,
  HelpWrap,
  HelpWrapText,
  IconWrap,
  SearchWrap,
  UserWrap,
  EndChildren,
  DepartmentsWrap,
  Dot,
} from "./Header.styles";
import { DropdownMenu, Input, Link, Logotype, TopBar } from "../../";
import { SettingsRoute } from "routes";
import { isEnterEvent } from "helpers/input";
import SearchIcon from "@skbkontur/react-icons/Search";
import SettingsIcon from "@skbkontur/react-icons/Settings";
import ArrowChevronDownIcon from "@skbkontur/react-icons/ArrowChevronDown";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { ErrorBoundary } from "../../ErrorBoundary";
import { HeaderVM } from "./HeaderVM";
import { Kontur, MarkirovkaPharma } from "@skbkontur/logos";
import { MenuItem } from "./MenuItem";
import { SupportCenter } from "support";

const { Start, End, ItemStatic, User, Logout, Item } = TopBar;

export const Header = observer(() => {
  const vm = useMemo(() => new HeaderVM(UserStore), []);
  const departmentQueryInput = useRef<null | typeof Input>(null);

  // Точку-индиктор новых операций нужно будет скрывать при развернутом дропдауне
  const label = vm.currentDepartment ? (
    <Item use="default">
      <DepWrap>
        <DepartmentTextWrap>{vm.currentDepartment.name || vm.currentDepartment.address}</DepartmentTextWrap>
        <IconWrap>
          {vm.hasNewOperationsForAnyDepartment && !vm.isIndicatiorDotHidden && <Dot />}
          <ArrowChevronDownIcon />
        </IconWrap>
      </DepWrap>
    </Item>
  ) : (
    <Item use="default">
      Выберите подразделение <ArrowChevronDownIcon />
    </Item>
  );

  const onLogout = async () => {
    await vm.onLogout();
    window.location.reload();
  };

  const onKeyDown = async (e: KeyboardEvent) => {
    if (isEnterEvent(e) && vm.innQuery) {
      try {
        await vm.changeOrganization();
        window.location.reload();
      } catch (e) {
        ErrorBoundary.showErrorNotification(`Не нашли организацию с ИНН ${vm.innQuery}`, "Ошибка");
      }
    }
  };

  const onDropdownOpen = () => {
    // Скроем точку-индикатор новых операций
    vm.setIsIndicatiorDotHidden(true);
    vm.onChangeDepartment();
    // @TODO разобраться с типом
    const current = departmentQueryInput.current as any;
    setTimeout(() => current?.focus(), 0);
  };

  const onDropdownClose = () => vm.setIsIndicatiorDotHidden(false);

  const menuFooter = (
    <HeaderMenuAdditionalItem>
      <Link href={SettingsRoute.getHref()}>
        <SettingsIcon /> Управление организациями
      </Link>
    </HeaderMenuAdditionalItem>
  );

  return (
    <TopBar maxWidth={theme.layout.maxWidth} noShadow>
      <Start>
        <ItemStatic>
          <Logotype
            size={28}
            konturLogo={<Kontur />}
            productLogo={<MarkirovkaPharma color={theme.brandColors.default} />}
          />
        </ItemStatic>
        <DropdownMenu
          data-tid="DepartmentsDropdownMenu"
          onOpen={onDropdownOpen}
          onClose={onDropdownClose}
          caption={label}
          footer={vm.showLinkToSettings ? menuFooter : null}
          menuMaxHeight="550px"
          menuWidth="500px"
          style={{ marginTop: 2 }}
        >
          {vm.isShowSearchBar && (
            <SearchWrap>
              <Input
                data-tid="DepartmentsSearch"
                value={vm.departmentQuery}
                onValueChange={vm.onChangeDepartment}
                ref={departmentQueryInput}
                rightIcon={<SearchIcon />}
                placeholder="Поиск по подразделениям"
                width="100%"
              />
            </SearchWrap>
          )}
          <DepartmentsWrap data-tid="Departments">
            {vm.availableDepartments.map(dep => (
              <MenuItem
                data-tid="Department"
                chooseDepartment={vm.chooseDepartment}
                department={dep}
                key={dep.id}
                // Если у текущего подразделения есть новые операции
                // Будет показана точка-индикатор
                hasNew={vm.hasNewOperationsForCurrentDepartment(dep.id)}
                isSelected={dep === vm.currentDepartment}
              />
            ))}
          </DepartmentsWrap>
        </DropdownMenu>
      </Start>
      <End>
        <EndChildren>
          {vm.canChangeOrgByInn && (
            <TopBar.ItemStatic>
              <Input
                data-tid="InnSearch"
                rightIcon={<SearchIcon />}
                onValueChange={vm.onChangeInn}
                onKeyDown={onKeyDown}
                placeholder="Введите ИНН"
                width={160}
              />
            </TopBar.ItemStatic>
          )}
          <Item onClick={SupportCenter.open}>
            <HelpWrap>
              <HelpDotIcon size={19} color={theme.colors.dimgray} />
              <HelpWrapText>Помощь</HelpWrapText>
            </HelpWrap>
          </Item>
          <UserWrap>
            <User userName={vm.userNameOrEmail} />
          </UserWrap>
          <Logout use="default" onClick={onLogout} />
        </EndChildren>
      </End>
    </TopBar>
  );
});
