import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { TableWrap } from "./CodesTable.styles";
import { CodeItem } from "../CodeItem/CodeItem";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { AdditionalInfoVM } from "../../AdditionalInfo/AdditionalInfoVM";
import { ExpandAllIconWrap, TableHeadCellWrap } from "../GoodsTable/GoodsTable.styles";
import { CollapseAllIcon, ExpandAllIcon } from "icons";
import { DeliveryType } from "typings/server";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";

const cols = "2fr 7fr 1fr";

interface ICodesTableProps {
  items: DeliveryItemModelExtended[];
  additionalInfo: CodesAdditionalInfo;
}

export const CodesTable: React.FC<ICodesTableProps> = observer(({ items, additionalInfo }: ICodesTableProps) => {
  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;
  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const vm = React.useMemo(() => new AdditionalInfoVM(items, additionalInfo, false, deliveryType, extraFields), [
    items,
  ]);
  return (
    <TableWrap>
      <Grid cols={cols} headerBorderBottom paddingHeaderCells="8px 5px">
        <GridCellHeader row={1} colStart={"1"} colEnd={"3"} colSp={"2"}>
          <TableHeadCellWrap hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
            <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
            Коды маркировки
          </TableHeadCellWrap>
        </GridCellHeader>
        <GridCellHeader row={1} col={3} textAlign={"right"}>
          Кол-во кодов
        </GridCellHeader>
      </Grid>
      {items.map((item, i) => (
        <CodeItem key={item.name} item={item} index={i} cols={cols} vm={vm} allCodesExpanded={allCodesExpanded} />
      ))}
    </TableWrap>
  );
});
