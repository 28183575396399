import React, { FC, useState } from "react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { Capitalized, TextGreen, TextGrey } from "styles/shared.styles";
import { ScannedItemsCount } from "Common/ScannedItemsCount/ScannedItemsCount";
import { CollapseIconWrap, DiscardBlockWrap, NameWrap, ScannerRow } from "../../ScannerSidePage.styles";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { DeliveryItemStatus } from "typings/server";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { AdditionalInfoSgtinTable } from "Common/AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "Common/AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { ArrowChevronDown, ArrowChevronRight, Undo } from "@skbkontur/react-icons";
import { TableItemHr } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { Button } from "components";
import { observer } from "mobx-react";

interface IDirectAcceptanceScannerItemRow {
  item: DeliveryItemModelExtended;
  index: number;
  isNew?: boolean;
  additionalInfoVM: AdditionalInfoVM;
  cols: string;
  discardItem: (item: DeliveryItemModelExtended) => void;
  discardCode: (code: string) => void;
  showDelete: boolean;
  allCodesExpanded: boolean;
  toggleShowDelete(item: DeliveryItemModelExtended): void;
}

export const ScanAndCheckItemRow: FC<IDirectAcceptanceScannerItemRow> = observer(
  ({
    item,
    index,
    isNew,
    additionalInfoVM,
    cols,
    discardItem,
    discardCode,
    allCodesExpanded,
    toggleShowDelete,
    showDelete,
  }) => {
    const isRecalled = item.status === DeliveryItemStatus.Recalled;
    const [isExpanded, setExpanded] = useState(allCodesExpanded);

    const hasAdditionalInfo = additionalInfoVM.checkIfGoodHasAdditionalInfo(item);

    const onOpenInfo = () => {
      if (hasAdditionalInfo) {
        setExpanded(v => !v);
      }
    };

    const onDiscardItem = () => {
      toggleShowDelete(item);
      discardItem(item);
    };

    return (
      <>
        <ScannerRow
          isNew={!!isNew}
          isInfo={hasAdditionalInfo}
          onClick={onOpenInfo}
          isRecalled={isRecalled}
          onMouseEnter={() => toggleShowDelete(item)}
          onMouseLeave={() => toggleShowDelete(item)}
        >
          {hasAdditionalInfo && (
            <CollapseIconWrap>{isExpanded ? <ArrowChevronDown /> : <ArrowChevronRight />}</CollapseIconWrap>
          )}
          <Grid cols={cols} cellsBorderBottom={!isExpanded}>
            <GridCell col={1} row={1}>
              <TextGrey>{index + 1}</TextGrey>
            </GridCell>
            <GridCell col={2} row={1}>
              <NameWrap bold={isExpanded}>
                {item.isAllScanned ? (
                  <Capitalized>
                    <TextGreen>{item.name}</TextGreen>
                  </Capitalized>
                ) : (
                  <Capitalized title={(isRecalled && "Товар был отозван поставщиком") || ""}>{item.name}</Capitalized>
                )}
              </NameWrap>
            </GridCell>
            {showDelete ? (
              <GridCell colStart={3} colEnd={5} row={1} textAlign="right">
                <DiscardBlockWrap isInfo={hasAdditionalInfo}>
                  <Button use={"link"} size={"small"} onClick={onDiscardItem} icon={<Undo />}>
                    Отменить сканирование
                  </Button>
                </DiscardBlockWrap>
              </GridCell>
            ) : (
              <>
                <GridCell col={3} row={1} textAlign={"right"}>
                  <ScannedItemsCount allLen={item.ssccCodes.length} scannedLen={item.ssccScanLen} />
                </GridCell>
                <GridCell col={4} row={1} textAlign={"right"}>
                  <ScannedItemsCount allLen={item.sgtinCodes.length} scannedLen={item.sgtinScanLen} />
                </GridCell>
              </>
            )}
          </Grid>
        </ScannerRow>
        {isExpanded && hasAdditionalInfo && (
          <>
            <AdditionalInfoSgtinTable
              codes={item.sgtinCodes}
              vm={additionalInfoVM}
              onDiscardCode={discardCode}
              item={item}
            />
            <AdditionalInfoSsccTable
              vm={additionalInfoVM}
              codes={item.ssccCodesFlat}
              onDiscardCode={discardCode}
              item={item}
            />
            <TableItemHr />
          </>
        )}
      </>
    );
  }
);
