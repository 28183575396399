import { isSscc } from "helpers/codes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { IFreeScanError } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageVM";
import { DeliveryType } from "typings/server";

export function withdrawalValidations(delivery: WithdrawalDeliveryModel, code: string): undefined | IFreeScanError {
  if (delivery.type === DeliveryType.Reentry && isSscc(code)) {
    return {
      code: "notSsccAllowed",
      errorObj: {
        title: "Повторный ввод в оборот агрегатов не предусмотрен. Отсканируйте другой товар.",
        description: "Код агрегата. Повторный ввод в оборот агрегатов не предусмотрен.",
      },
    };
  }
  if (delivery.isDisposal && isSscc(code)) {
    return {
      code: "wrongCode",
      errorObj: {
        title: "Вывод агрегатов для оказания медицинской помощи не предусмотрен. Отсканируйте другой товар.",
        description: "Код агрегата. Вывод агрегатов для оказания медицинской помощи не предусмотрен.",
      },
    };
  }
}
