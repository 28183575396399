import React from "react";
import { observer } from "mobx-react";
import {
  AllScanned,
  CanvasWrap,
  LinkWrap,
  QRCodeTextWrap,
  ScannerQRCodeWrap,
  StyledCheckIcon,
} from "./QrCodeBlock.styles";
import { Link } from "components";
import { AndroidApp, IOSdApp, MobileInstruction } from "StringResx";
import { HelpDot } from "@skbkontur/react-icons";

interface IQrCodeBlockProps {
  allScanned?: boolean;
}

export const QRCodeId = "qrcode";

// todo: move qr-code rendering here
// useEffect(() => {
//   if (vm.isPhoneMode) ScanHelper.createQRCode(QRCodeId, vm.deliveryId, null, vm.positionId);
// }, [isPhoneMode]);

export const QrCodeBlock: React.FC<IQrCodeBlockProps> = observer((props: IQrCodeBlockProps) => {
  const { allScanned } = props;
  return (
    <>
      {allScanned && (
        <AllScanned>
          <StyledCheckIcon />
          <span>
            Все товары совпадают <br /> со сведениями поставщика
          </span>
        </AllScanned>
      )}
      <ScannerQRCodeWrap>
        <CanvasWrap>
          <canvas id={QRCodeId} />
        </CanvasWrap>
        <QRCodeTextWrap>
          Для начала работы отсканируйте QR&#8209;код в мобильном приложении
          <br />
          <Link target={"_blank"} href={AndroidApp}>
            Android
          </Link>
          &nbsp;или&nbsp;
          <Link target={"_blank"} href={IOSdApp}>
            iOS
          </Link>
          <LinkWrap>
            <Link href={MobileInstruction} target={"_blank"} icon={<HelpDot />}>
              Как сканировать приложением
            </Link>
          </LinkWrap>
        </QRCodeTextWrap>
      </ScannerQRCodeWrap>
    </>
  );
});
