import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock, ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { CreatingDocWrap, DateAndFIOWrap } from "Common/Stages/Stages.styles";
import { TransferPageVM } from "../../TransferPage/TransferPageVM";
import { PageInfoBlock } from "./PageInfoBlock/PageInfoBlock";
import { TransferRecallStagesBlock } from "./TransferRecallStagesBlock";
import { OperationsStatusNames } from "Common/Status/StatusesName";
import { DateAndUser } from "Common/Stages/DateAndUser";
import { CirculationWarning } from "Common/CirculationWarning/CirculationWarning";

interface IStagesProps {
  vm: TransferPageVM;
}

export const TransferStagesBlock: React.FC<IStagesProps> = observer((props: IStagesProps) => {
  const { vm } = props;
  const onCollapse = () => vm.onCollapseDelivery(vm.delivery.id);

  useEffect(() => {
    if (vm.delivery.status === DeliveryStatus.Processing) {
      // Если delivery переходит в Processing, стадия должна быть развернута
      // Например, при возврате в состояние черновика.
      vm.onCollapseDelivery(vm.delivery.id, true);
    }
  }, [vm.delivery.status]);

  return (
    <DeliveryStagesBlock>
      <DeliveryStatusBlock
        data-tid="ProcessingStage"
        size={vm.getTitleSize(vm.stages[DeliveryStage.Processing])}
        name={vm.stages[DeliveryStage.Processing].name}
        status={vm.stages[DeliveryStage.Processing].progress}
        collapsed={!vm.isDeliveryOpenedState.get(vm.delivery.id)}
        alwaysVisibleComp={
          <>
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.Processing}
            />
            {vm.isShowCirculationWarning && <CirculationWarning isError={vm.isFailedStatus} />}
          </>
        }
        onCollapse={onCollapse}
      >
        <PageInfoBlock vm={vm} />
      </DeliveryStatusBlock>
      {/* TODO: скорее всего, нужно порефакторить */}
      <DeliveryStatusBlock
        data-tid="SigningStage"
        status={vm.delivery.isRecalled ? ProgressStage.Done : vm.stages[DeliveryStage.Signing].progress}
        name={vm.stages[DeliveryStage.Signing].name}
        size={vm.delivery.isRecalled ? "medium" : vm.getTitleSize(vm.stages[DeliveryStage.Signing])}
        error={vm.stages[DeliveryStage.Signing].errorInfo}
        alwaysVisibleComp={
          <>
            {vm.delivery.status === DeliveryStatus.CreatingDocument && !vm.delivery.isRecalled && (
              <CreatingDocWrap>
                <Spinner type="mini" caption={OperationsStatusNames[DeliveryStatus.CreatingDocument]} />
              </CreatingDocWrap>
            )}
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.Signing}
            />
          </>
        }
      />

      <DeliveryStatusBlock
        data-tid="SendingStage"
        status={vm.delivery.isRecalled ? ProgressStage.Done : vm.stages[DeliveryStage.Sending].progress}
        name={vm.getSendingStepName(vm.stages[DeliveryStage.Sending])}
        size={vm.delivery.isRecalled ? "medium" : vm.getTitleSize(vm.stages[DeliveryStage.Sending])}
        error={vm.stages[DeliveryStage.Sending].errorInfo}
        alwaysVisibleComp={
          <DateAndFIOWrap data-tid="CompleteInfo">
            {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Sending])}
          </DateAndFIOWrap>
        }
      />

      {vm.showDoneStage && (
        <DeliveryStatusBlock
          data-tid="DoneStage"
          status={vm.stages[DeliveryStage.Done].progress}
          name={vm.stages[DeliveryStage.Done].name}
          error={vm.stages[DeliveryStage.Done].errorInfo}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
        />
      )}

      {vm.delivery.isRecalled && <TransferRecallStagesBlock vm={vm} />}
    </DeliveryStagesBlock>
  );
});
