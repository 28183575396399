import styled from "styles/styled-components";
import { TrashIconWrap } from "../EmployeesPage/Employees.styles";

export const PageWrap = styled.div`
  margin-top: 27px;
`;

export const GridWrap = styled.div`
  max-width: 870px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ItemWrap = styled.div`
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.lightBackground};

    ${TrashIconWrap} {
      display: block;
    }
  }
`;

export const MainName = styled.div`
  font-weight: 600;
`;

export const ArrowTextWrap = styled.div`
  position: relative;
  right: 4px;
  color: ${props => props.theme.textColors.secondaryText};
  margin-bottom: 2px;

  &:hover {
    color: inherit;
  }
`;

export const EmployeeName = styled.div`
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;
