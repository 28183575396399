import { action, computed, observable } from "mobx";
import { DepartmentModel, EmployeeRole, OrganizationModel, UserInfoModel } from "typings/server";
import { UserApi } from "api/UserApi";
import { EmployeeRoleBitwise } from "typings/client";
import { SupportCenter } from "support";
import { getCookie } from "helpers/cookie";

class UserStoreInternal {
  @observable user: UserInfoModel;
  organization?: OrganizationModel;
  roleBitwise: number;

  async tryToSetUserData() {
    // если не выбрано ни одно подразделение - устанавливаем первое и перезагружаем страницу
    if (this.hasAnyDepartment && !this.currentDepartment) {
      const firstDepartment = this.user?.availableDepartments && this.user?.availableDepartments[0];
      if (firstDepartment) {
        await this.setCurrentDep(firstDepartment);
      }
    }
    if (this.organization) {
      SupportCenter.setSupportParams(this.organization.inn, this.organization.name);
    }
  }

  @computed
  get firstAndLastName(): string {
    if (!this.user) return "";
    if (!this.user.lastName) return "";
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  @computed
  get fullName(): string {
    if (!this.user) return "";
    const firstName = this.user.firstName || "";
    const lastName = this.user.lastName || "";
    const middleName = this.user.middleName || "";
    if (firstName || lastName || middleName) {
      return `${lastName} ${firstName} ${middleName}`;
    } else return this.email;
  }

  @computed
  get email(): string {
    if (!this.user) return "";
    return this.user.email || "";
  }

  @computed
  get currentDepartment() {
    return this.user.availableDepartments?.find(item => item.id === this.user.chosenDepartmentId);
  }

  @computed
  get hasAnyDepartment(): boolean {
    return Boolean(this.user.availableDepartments?.length);
  }

  @computed
  get currentOrgId(): string {
    return this.user?.chosenOrganizationId || "";
  }

  @computed
  get isSuperAdmin(): boolean {
    return this.user?.role === EmployeeRole.Admin && this.user?.hasView;
  }

  @computed
  get isAdmin(): boolean {
    return (
      (this.roleBitwise & EmployeeRoleBitwise[EmployeeRole.Admin]) === EmployeeRoleBitwise[EmployeeRole.Admin] ||
      this.user?.role === EmployeeRole.Admin
    );
  }

  get portalUserId() {
    return getCookie("portaluserid");
  }

  @action
  async getUser(): Promise<void> {
    this.user = await UserApi.getInfo();
    this.user.firstName = this.user.firstName || "";
    this.user.lastName = this.user.lastName || "";
    this.organization = this.user.availableOrganizations?.find(org => this.user.chosenOrganizationId === org.id);
    this.setRoleBitwise(this.user.role);
  }

  @action
  setRoleBitwise(role: EmployeeRole[] | EmployeeRole = EmployeeRole.User) {
    const roleToArr = (role as string).split(", ");
    this.roleBitwise = roleToArr.reduce((result, item) => (result |= EmployeeRoleBitwise[item]), 1);
  }

  @action
  async setCurrentDep(dep: DepartmentModel) {
    if (dep.id === this.user.chosenDepartmentId) return;
    await UserApi.chooseDepartment(dep.id);
    this.user = { ...this.user, chosenDepartmentId: dep.id };
  }

  changeOrganizationById(id: string) {
    return UserApi.chooseOrganizationById(id);
  }
}

export interface IUserStore extends UserStoreInternal {}

export const UserStore = new UserStoreInternal();
