import { RouteName } from "./RouteNames";

class ShipmentRouteParams {
  deliveryId?: string;
}

export class ShipmentRoute extends ShipmentRouteParams {
  public static readonly title = "Отгрузка";
  public static readonly pageTitle = "Отгрузка товара";
  public static readonly baseRoute = `/${RouteName.Shipment}`;
  public static readonly route = `${ShipmentRoute.baseRoute}/:deliveryId?`;

  public static getHref(deliveryId?: string): string {
    return deliveryId ? `${this.baseRoute}/${deliveryId}` : this.baseRoute;
  }
}
