import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { AdditionalInfoSgtinTableItem } from "./AdditionalInfoSgtinTableItem";
import { Header, ProducerTextWrapper, TableWrap } from "../AdditionalInfo.styles";
import { TextGrey } from "styles/shared.styles";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { AdditionalInfoVM } from "../AdditionalInfoVM";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { ExtraField } from "../extraFields/extraFields";

interface IAdditionalInfoTableProps {
  vm: AdditionalInfoVM;
  codes: CodeModelExtended[];
  item?: DeliveryItemModelExtended;
  alwaysVisible?: boolean;
  onDiscardCode?(code: string): void;
  newCode?: string;
}

export const AdditionalInfoSgtinTable = observer((props: IAdditionalInfoTableProps) => {
  const { vm, codes, onDiscardCode, item, alwaysVisible, newCode } = props;

  let codesFlat = useMemo(() => codes.map(x => x.code), [codes]);

  if (!vm.additionalInfo.checkCodesHaveAdditionalInfo(codesFlat) && !alwaysVisible) return null;

  const producer = vm.getProducer(codes[0].code);

  const lastColNumber = vm.isSidePage ? 3 : 4;

  const canBeDiscarded = (code: string) => {
    const codeModel = item?.getCode(code);
    if (codeModel) {
      return codeModel.scanned === true;
    }
    return true;
  };

  return (
    <TableWrap data-tid="AdditionalInfoSgtinTable">
      <ProducerTextWrapper>
        <TextGrey>Производитель: {producer || "—"}</TextGrey>
      </ProducerTextWrapper>
      <Header>
        <Grid cols={vm.sgtinCols} paddingHeaderCells={"6px 4px 6px"}>
          <GridCellHeader col={1} row={1} padding={"6px"}>
            {!vm.isSidePage && "Код штучного товара "}SGTIN
          </GridCellHeader>
          <GridCellHeader col={2} row={1}>
            Производственная серия
          </GridCellHeader>
          {!vm.isSidePage && (
            <GridCellHeader col={3} row={1}>
              Дата производства
            </GridCellHeader>
          )}
          <GridCellHeader col={lastColNumber} row={1}>
            Срок годности
          </GridCellHeader>
          {vm.showExtraField && (
            <GridCellHeader
              col={lastColNumber + 1}
              row={1}
              padding={vm.extraField?.type === ExtraField.FractionDisposal ? "6px 0px" : "6px 4px"}
            >
              {vm.extraField?.header}
            </GridCellHeader>
          )}
        </Grid>
      </Header>
      {codes.map(codeModel => (
        <AdditionalInfoSgtinTableItem
          vm={vm}
          key={codeModel.code}
          codeModel={codeModel}
          alwaysVisible={alwaysVisible}
          onDiscardCode={onDiscardCode}
          canBeDiscarded={canBeDiscarded(codeModel.code)}
          isNewCode={codeModel.code === newCode}
        />
      ))}
    </TableWrap>
  );
});
