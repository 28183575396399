// todo: made progressive interval 2, 4, 6... 64 сек,
//  move setInterval,
//  show error after 5 times

export interface IPollingOptions {
  maxTryCount: number; // 0 - бесконечное количество попыток
  progressive: boolean;
}

export class Polling {
  private tryCount: number = 0;
  private readonly maxTryCount: number;
  private intervalId: number | undefined;
  private interval: number;
  // private progressive: boolean;
  private readonly callback: (params: any) => void;

  constructor(callback: () => void, options: Partial<IPollingOptions> = {}) {
    this.maxTryCount = typeof options.maxTryCount === "number" ? options.maxTryCount : 5;
    // this.progressive = options.progressive || false;
    this.callback = callback;
  }

  start(params?: any, interval?: number) {
    this.interval = interval || 5000;
    this.tryCount = 0;
    this.intervalId = window.setInterval(async () => {
      try {
        await this.callback(params);
      } catch (e) {
        this.tryCount += 1;
        if (this.tryCount >= this.maxTryCount && this.maxTryCount !== 0) {
          this.stop();
        }
      }
    }, this.interval);
  }

  changeInterval(interval: number, params?: any) {
    if (this.interval !== interval) {
      this.stop();
      this.start(params, interval);
    }
  }

  stop() {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  get inProcess(): boolean {
    return !!this.intervalId;
  }
}
