import styled from "styles/styled-components";

export const ScanTitle = styled.div`
  padding-left: 4px;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 3px;
`;

interface IScannerCounterCodesCount {
  color?: "green" | "default";
}

export const ScannerCounterCodesCount = styled.span<IScannerCounterCodesCount>`
  color: ${props => (props.color === "green" ? props.theme.textColors.green : "inherit")};
`;
