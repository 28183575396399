import React from "react";
import { ScannedLargeText, TextWrap } from "./ScannedItemsCount.styles";

export interface IScanItemsCount {
  allLen: number;
  scannedLen: number;
}

export const ScannedItemsCountLarge = (props: IScanItemsCount) => {
  const { allLen, scannedLen } = props;
  if (!allLen) return <>—</>;
  if (!scannedLen)
    return (
      <ScannedLargeText data-tid="AllCodesCount" color={"initial"}>
        {allLen}
      </ScannedLargeText>
    );

  return (
    <>
      <ScannedLargeText data-tid="AcceptedCodesCount" color={"green"}>
        {scannedLen}
      </ScannedLargeText>
      <TextWrap color={allLen === scannedLen ? "green" : "initial"}>&nbsp;из&nbsp;</TextWrap>
      <ScannedLargeText data-tid="AllCodesCount" color={allLen === scannedLen ? "green" : "initial"}>
        {allLen}
      </ScannedLargeText>
    </>
  );
};
