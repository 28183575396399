import { action, computed, observable, toJS } from "mobx";
import { hasRuChars } from "helpers/lang";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { IStore } from "stores/shared";
import { ScanHelper, ScanMode } from "../../ScanHelper";
import { DeliveryItemStatus } from "typings/server";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { BaseScanPageVM } from "../BaseScanPageVM";
import { ISignatureStore } from "stores/SignatureStore";
import { QRCodeId } from "../../QrCode/QrCodeBlock";
import { IScannerSidePageVM } from "../../shared";
import { isDataMatrix, isSgtin } from "helpers/codes";
import { IAdvertisementStore } from "stores/AdvertisementStore";

export class ScanAndCheckSidePageVM<T extends IStore>
  extends BaseScanPageVM<IStore, DeliveryItemModelExtended, string>
  implements IScannerSidePageVM {
  public allCodesLen: number;
  @observable showDelete: boolean = false;

  constructor(
    store: T,
    signatureStore: ISignatureStore,
    advertisementStore: IAdvertisementStore,
    additionalInfo: CodesAdditionalInfo,
    readonly onSaveCodes: () => Promise<void>,
    private readonly delivery: {
      allCodesLen: number;
      items: DeliveryItemModelExtended[];
      setItems(items: DeliveryItemModelExtended[]): void;
    },
    parentDeliveryId: string
  ) {
    super(store, signatureStore, advertisementStore, additionalInfo, parentDeliveryId);
    this.setItemsFromDelivery();
    this.allCodesLen = delivery.allCodesLen;
  }

  @action
  addScannedCode(code: string, codeStr: string) {
    if (!this.checkValidation(code, codeStr) || !code) {
      return;
    }
    this.setScanned(code);
    this.getCodeAdditionalInfo(code);
  }

  private setItemsFromDelivery() {
    this.copiedData = this.delivery.items.map((item: DeliveryItemModelExtended) => {
      const codes = item.allCodes.map(code => new CodeModelExtended(code));
      return new DeliveryItemModelExtended(toJS(item), codes);
    });
  }

  private setScanned(code: string) {
    for (let copy of this.copiedData) {
      const found: CodeModelExtended | undefined = copy.getCode(code);
      if (found) {
        if (found.scanned) return;
        found.setScanned(true);
        this.setNewCodeItem(copy);
        this.setCurrentCode(code);
      }
    }
  }

  @action
  private checkValidation(code?: string, codeStr?: string): boolean {
    this.showError = false;
    this.setErrorWarningTimeout();
    this.errors.clear();

    if (codeStr && hasRuChars(codeStr)) {
      const err = this.isInputMode
        ? "Код маркировки не должен содержать русские буквы"
        : "Русская раскладка клавиатуры. Переключите на английскую и повторите сканирование";
      this.errors.set("lang", err);
      return false;
    }

    if (!code || (isSgtin(code) && ScanHelper.isCurrentMode(ScanMode.Scanner) && codeStr && !isDataMatrix(codeStr))) {
      // Логируем некорректный код, т.к. слишком много обращений по этому поводу
      // eslint-disable-next-line no-console
      console.log(`Некорректный код - ${codeStr}`);
      const err = this.isInputMode ? "Неверный формат кода" : "Неверный формат кода. Продолжайте сканирование.";
      this.errors.set("wrongCode", err);
      return false;
    }

    const foundItem = this.copiedData.find(copy => copy.getCode(code));
    if (foundItem && foundItem.status === DeliveryItemStatus.Recalled) {
      this.errors.set("notFound", "Код маркировки отозван поставщиком.");
      return false;
    }

    const foundLowerCaseCode = this.copiedData.find(copy => copy.getCode(code, { caseSensitive: false }));
    if (!foundItem && foundLowerCaseCode) {
      this.errors.set("notFound", `Проверьте регистр. В сведениях поставщика есть код ${foundLowerCaseCode.code}`);
      return false;
    }

    if (!foundItem) {
      const err = this.isInputMode
        ? "Кода маркировки нет в сведениях поставщика"
        : "Кода маркировки нет в сведениях поставщика. Отсканируйте другой товар.";
      this.errors.set("notFound", err);
      return false;
    }
    return true;
  }

  @action
  async save() {
    if (ScanHelper.isCurrentMode(ScanMode.Phone)) {
      this.pollCodes.stop();
      await this.pollScannedCodesFunc();
    }
    this.delivery.setItems(this.copiedData);

    try {
      this.loadingState.set("onSave", true);
      await this.onSaveCodes();
    } finally {
      this.loadingState.set("onSave", false);
    }

    this.copiedData = [];
  }

  @action
  discardAll() {
    this.copiedData.forEach(item => {
      item.allCodes.forEach(code => code.setScanned(false));
    });
    this.resetFields();
  }

  @action.bound
  discardCode = (code: string) => {
    this.copiedData.find(item => {
      const codeModel = item.getCode(code);
      if (codeModel) codeModel.setScanned(false);
      return codeModel;
    });
    this.resetFields();
  };

  @action.bound
  discardItem(item: DeliveryItemModelExtended): void {
    const index = this.copiedData.indexOf(item);
    if (index !== -1) {
      this.copiedData[index].allCodes.forEach(x => {
        x.scanned = false;
      });
    }
    this.deletedCodes = this.deletedCodes.concat(item.allCodes.map(codeModel => codeModel.code));
    this.resetFields();
  }

  @action
  protected async pollScannedCodesFunc() {
    const codes = await this.store.getScannedCodes(this.deliveryId);
    if (codes) {
      codes.forEach(code => this.setScanned(code));
    }
  }

  @computed
  get allScannedLen() {
    return this.copiedData.reduce(
      (count: number, item: DeliveryItemModelExtended) => (count += item.ssccScanLen + item.sgtinScanLen),
      0
    );
  }

  @computed
  get allScanned(): boolean {
    return this.allCodesLen === this.allScannedLen;
  }

  @computed
  get warnErrorDescription(): string {
    return this.errors.values().next().value;
  }

  @computed
  get warnErrorTitle(): string {
    return this.warnErrorDescription;
  }

  createQRCode() {
    ScanHelper.createQRCode(QRCodeId, this.deliveryId);
  }

  @action.bound
  public toggleShowDeleteValidated(item: DeliveryItemModelExtended) {
    if (item.allCodes.some(code => code.scanned === true)) {
      this.showDelete = !this.showDelete;
    }
  }
}
