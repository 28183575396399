import React from "react";
import { observer } from "mobx-react";
import DocumentTitle from "react-document-title";
import { MarkingTitle } from "StringResx";
import {
  AcceptanceRoute,
  GreetingRoute,
  RootRoute,
  SettingsRoute,
  ShipmentRoute,
  TransferRoute,
  PackingRoute,
  CirculationRoute,
} from "routes";
import { AcceptanceIndexPage } from "features/Acceptance/AcceptanceIndexPage";
import { Header } from "./Header/Header";
import { ContentWrapper, ContentBody, ContentRoot, RouteContentWrapper } from "./index";
import { theme } from "styles/theme";
import { Menu } from "../Menu/Menu";
import { ShipmentIndexPage } from "features/Shipment/ShipmentIndexPage";
import { Redirect, Route, Switch } from "react-router-dom";
import { MenuVM } from "../Menu/MenuVM";
import { MainFooter } from "./MainFooter";
import { SettingsPage } from "features/Settings/SettingsPage";
import { TransferIndexPage } from "features/Transfer/TransferIndexPage";
import { GreetingIndexPage } from "features/Greeting/GreetingIndexPage";
import { CirculationIndexPage } from "features/Circulation/CirculationIndexPage";
import { PackingPageWrapper } from "features/Packing/PackingPageWrapper";
import { BalanceText } from "Common/BalanceText/BalanceText";
import { RouteComponentProps } from "react-router";
import { BalanceStore } from "stores/BalanceStore";
import { CodesEndsBlock } from "Common/CodesEndsBlock/CodesEndsBlock";
import { HeaderRoot } from "./Content.styles";

interface LayoutProps extends RouteComponentProps {}

export const Layout = observer(({ location }: LayoutProps) => {
  return (
    <DocumentTitle title={MarkingTitle}>
      <ContentRoot data-tid="Root">
        {location.pathname !== SettingsRoute.baseRoute && <BalanceText top />}
        <HeaderRoot>
          <Header data-tid="HeadBlock" />
        </HeaderRoot>
        <ContentBody>
          <ContentWrapper background={theme.colors.white} shadow>
            <Switch>
              <Route exact={true} path={GreetingRoute.route} component={GreetingIndexPage} />
              <Route path={RootRoute}>
                <Menu data-tid="Navigation" vm={MenuVM} />
                <RouteContentWrapper>
                  {BalanceStore.isSubscriptionExpired ? (
                    <>
                      <Switch>
                        <Redirect exact={true} from={RootRoute} to={SettingsRoute.getHref()} push />
                        <Route exact={true} path={SettingsRoute.route} component={SettingsPage} />
                        <CodesEndsBlock />
                      </Switch>
                    </>
                  ) : (
                    <Switch>
                      <Redirect exact={true} from={RootRoute} to={AcceptanceRoute.getHref()} push />
                      <Route exact={true} path={AcceptanceRoute.route} component={AcceptanceIndexPage} />
                      <Route exact={true} path={ShipmentRoute.route} component={ShipmentIndexPage} />
                      <Route exact={true} path={SettingsRoute.route} component={SettingsPage} />
                      <Route exact={true} path={PackingRoute.route} component={PackingPageWrapper} />
                      <Route exact={true} path={TransferRoute.route} component={TransferIndexPage} />
                      <Route exact={true} path={CirculationRoute.route} component={CirculationIndexPage} />
                    </Switch>
                  )}
                </RouteContentWrapper>
              </Route>
            </Switch>
          </ContentWrapper>
          <MainFooter />
        </ContentBody>
      </ContentRoot>
    </DocumentTitle>
  );
});
