import styled from "styles/styled-components";

export const EditorWrap = styled.div`
  width: 655px;
`;

export const EditorEmailWrap = styled.div`
  margin: 8px 0 10px;
`;

export const EditorAdminTextWrap = styled.div`
  margin: 8px 0 10px;
`;

interface IEditorTitleProps {
  required?: boolean;
  marginTop?: string;
}

export const EditorTitle = styled.div<IEditorTitleProps>`
  margin-top: ${props => props.marginTop};

  &:after {
    content: "*";
    display: ${props => (props.required ? "inline-block" : "none")};
    color: ${props => props.theme.textColors.red};
    position: relative;
    top: -3px;
    right: -1px;
  }
`;

interface ICrownIconWrapProps {
  active: boolean;
}

export const CrownIconWrap = styled.div<ICrownIconWrapProps>`
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  color: ${props => (props.active ? props.theme.colors.orange : props.theme.colors.gray)};
`;

export const TokenWrapper = styled.div`
  margin: 10px 0 8px;
`;

export const EditorText = styled.div`
  margin: 10px 0 8px;
`;
