import * as QRCode from "qrcode";
import { UserApi } from "api/UserApi";
import { MobileTokenModel } from "typings/server";

export enum ScanMode {
  Phone = "phone",
  Scanner = "scanner",
  Input = "input",
}

interface IQRCodeData {
  token: string;
  deliveryId?: string;
  farmaHost: string;
  positionId?: string;
}

export class ScanHelper {
  // token info per user
  static tokenData: MobileTokenModel;
  static timer: number;
  static lastParameters: any;

  static setScanMode(mode: ScanMode) {
    localStorage.setItem("scanMode", mode);
  }

  static isCurrentMode(scanMode: ScanMode) {
    const mode = localStorage.getItem("scanMode");
    return mode === scanMode;
  }

  static getMode(): ScanMode {
    return (localStorage.getItem("scanMode") as ScanMode) || ScanMode.Scanner;
  }

  static async getToken(): Promise<string> {
    if (!ScanHelper.tokenData || !ScanHelper.timer) {
      ScanHelper.tokenData = await UserApi.getToken();
      ScanHelper.startTimer();
    }
    return ScanHelper.tokenData.token;
  }

  // Works only for 1 qr-code on page
  // auto-update token and rerender qrcode
  static startTimer() {
    const time = ScanHelper.tokenData ? ScanHelper.tokenData.lifetimeMs : 60 * 60 * 1000;

    this.timer = window.setTimeout(async () => {
      clearTimeout(this.timer);
      this.timer = 0;
      await ScanHelper.getToken();
      // try to rerender
      ScanHelper.createQRCode.apply(ScanHelper, ScanHelper.lastParameters);
    }, time);
  }

  static async getQRCodeData(deliveryId?: string, positionId?: string): Promise<IQRCodeData> {
    let host = window.location.host;

    // for localhost
    if (process.env["REACT_APP_API_URL"]) {
      const url = new URL(process.env["REACT_APP_API_URL"]);
      host = url.hostname;
    }

    const token = await ScanHelper.getToken();

    return {
      token: token,
      deliveryId: deliveryId,
      farmaHost: host,
      positionId,
    };
  }

  static async createQRCode(DOMElementID: string, deliveryId?: string, options?: any, positionId?: string) {
    // save last parametrs for auto rerender
    this.lastParameters = [DOMElementID, deliveryId, options, positionId];
    const defaultOptions = { width: 123, errorCorrectionLevel: "L", margin: 3, ...options };
    const data = await ScanHelper.getQRCodeData(deliveryId, positionId);

    const canvas = document.getElementById(DOMElementID);
    if (canvas) {
      QRCode.toCanvas(canvas, JSON.stringify(data), defaultOptions, (error: Error) => {
        if (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
    }
  }
}
