import { DeliveryTopBlock, DeliveryHeader } from "Common/Styles/MainPage.styles";
import { Link } from "components";
import { Content } from "components/Layout";
import { SubscriptionEndedIcon } from "icons";
import React from "react";
import { OrderMoreCodesUrl } from "StringResx";
import { MainWrap, SubscriptionIconWrap, SubscriptionTextWrap } from "./CodesEndsBlock.styles";

export function CodesEndsBlock() {
  return (
    <Content>
      <DeliveryTopBlock>
        <DeliveryHeader>
          <h2>Закончилась подписка</h2>
        </DeliveryHeader>
      </DeliveryTopBlock>
      <MainWrap>
        <SubscriptionIconWrap>
          <SubscriptionEndedIcon />
        </SubscriptionIconWrap>
        <SubscriptionTextWrap>
          <p>Закончилась подписка на сервис, поэтому операции по маркировке товаров недоступны.</p>
          <p>
            Для продления обслуживания оставьте{" "}
            <Link href={OrderMoreCodesUrl} target="_blank">
              заявку на оплату
            </Link>{" "}
            или позвоните по номеру 8 800 500-34-03, и мы поможем продлить подписку.
          </p>
        </SubscriptionTextWrap>
      </MainWrap>
    </Content>
  );
}
