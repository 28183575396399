import { action, computed, observable } from "mobx";
import { UserApi } from "api/UserApi";
import { IUserStore } from "stores/UserStore";
import { DeliveriesStore } from "stores/DeliveriesStore";
import { DepartmentModel } from "typings/server";

export class HeaderVM {
  @observable innQuery: string = "";
  @observable departmentQuery: string = "";
  @observable isIndicatiorDotHidden: boolean = false;

  maxDepartmentsToShow = 10;

  constructor(readonly userStore: IUserStore) {}

  @computed get currentDepartment() {
    return this.userStore.currentDepartment;
  }

  @computed get hasNewOperationsForAnyDepartment() {
    // Вернём true, если есть какие-либо новые операции для любого подразделения
    return Object.values(DeliveriesStore.counters).some(item => item?.newAccepancesCount || item?.newShipmentsCount);
  }

  hasNewOperationsForCurrentDepartment(departmentId: string | undefined) {
    if (!departmentId) {
      return false;
    }
    const currentDepartment = DeliveriesStore.counters[departmentId];
    return (
      !!currentDepartment?.newAccepancesCount ||
      !!currentDepartment?.newShipmentsCount ||
      !!currentDepartment?.newDestructionsCount
    );
  }

  onLogout = async () => {
    await UserApi.logout();
  };

  chooseDepartment = async (dep: DepartmentModel) => {
    await this.userStore.setCurrentDep(dep);
  };

  async changeOrganization() {
    await UserApi.chooseOrganizationByInn(this.innQuery);
  }

  @action
  setIsIndicatiorDotHidden(value: boolean) {
    this.isIndicatiorDotHidden = value;
  }

  @action
  onChangeInn = (val: string) => {
    this.innQuery = val.trim();
  };

  @action
  onChangeDepartment = (val?: string) => {
    this.departmentQuery = val || "";
  };

  @computed
  get isShowSearchBar(): boolean {
    const { availableDepartments } = this.userStore.user;
    return availableDepartments ? availableDepartments.length > this.maxDepartmentsToShow : false;
  }

  get showLinkToSettings(): boolean {
    const { availableOrganizations } = this.userStore.user;
    return availableOrganizations ? availableOrganizations.length > 1 : false;
  }

  @computed
  get availableDepartments(): DepartmentModel[] {
    const { availableDepartments } = this.userStore.user;
    const query = this.departmentQuery.toLowerCase().trim();
    return availableDepartments
      ? availableDepartments.filter(x => {
          const name = x.name || x.address;
          return name.toLowerCase().includes(query);
        })
      : [];
  }

  get userNameOrEmail(): string {
    return this.userStore.firstAndLastName || this.userStore.email || "";
  }

  get canChangeOrgByInn(): boolean {
    return this.userStore.user.hasView;
  }
}
