import { AcceptusType } from "typings/server";

export const orderedAcceptusTypes = [
  AcceptusType.Direct,
  AcceptusType.Reverse,
  AcceptusType.UnregisteredCounteragent,
  AcceptusType.UnregisteredDepartment,
  AcceptusType.Eeu,
  AcceptusType.Simplified,
  AcceptusType.SuspendedReturn,
  AcceptusType.StateDispatch,
];
