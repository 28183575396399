import React, { useState, useMemo } from "react";
import { observer } from "mobx-react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { SettingsRoute } from "routes";
import { DepartmentsPage } from "./DepartmentsPage/DepartmentsPage";
import { Content } from "components/Layout";
import { BalanceWrap, NoRights, OrgInn, OrgNameWrap } from "./SettingsPage.styles";
import { Loader, Select, Tabs } from "components";
import { SettingsSubRoute } from "routes/SettingsRoute";
import { EmployeesPage } from "./EmployeesPage/EmployeesPage";
import { SettingsPageVM } from "./SettingsPageVM";
import WarningIcon from "@skbkontur/react-icons/Warning";
import { SettingsStore } from "stores/SettingsStore";
import { UserStore } from "stores/UserStore";
import { SignatureStore } from "stores/SignatureStore";
import { RequisitesPage } from "./RequisitesPage/RequisitesPage";
import { OrganizationModel } from "typings/server";
import { RegistratorsPage } from "./RegistratorsPage/RegistratorsPage";
import { BalanceText } from "Common/BalanceText/BalanceText";
import { Separator } from "styles/separator";
import { ThemeContext as ReactUiThemeContext, FLAT_THEME, ThemeFactory } from "@skbkontur/react-ui";
import { theme } from "styles/theme";

interface SettingsPageProps extends RouteComponentProps<SettingsRoute> {
  vm: SettingsPageVM;
}

const SettingsPageInternal = withRouter(
  observer((props: SettingsPageProps) => {
    const { match, history, location, vm } = props;
    const path = match.params.type || SettingsSubRoute.Employees;
    const [active, setActive] = useState(path);

    const DepartmentsPageWrap = () => <DepartmentsPage vm={vm} />;
    const EmployeesPageWrap = () => <EmployeesPage vm={vm} />;
    const RequisitesPageWrap = () => <RequisitesPage vm={vm} />;
    const RegistratorsPageWrap = () => <RegistratorsPage vm={vm} />;

    const onChangeTab = (id: string) => {
      setActive(id as SettingsSubRoute);
      history.push(SettingsRoute.getHref(id));
    };

    const onChangeOrg = async (org: OrganizationModel) => {
      await vm.changeOrganization(org);
      window.location.reload();
    };

    const renderOrganization = (org: OrganizationModel) => org?.name;

    const showSelect = vm.availableOrganizations && vm.availableOrganizations.length > 1;

    const updatedTheme = useMemo(() => {
      return ThemeFactory.create(
        {
          tabColorFocus: theme.colors.bluePrimary,
          tabBorderWidth: "3px",
        },
        FLAT_THEME
      );
    }, []);

    return (
      <Content data-tid="SettingsPage" padding={"24px 24px 24px 38px"}>
        <Loader data-tid="Loader" active={!!vm.loaders.get("page")}>
          <h2>Настройки</h2>
          <OrgNameWrap>
            {showSelect ? (
              <Select<OrganizationModel>
                data-tid="Organization"
                onValueChange={onChangeOrg}
                value={vm.organization}
                renderValue={renderOrganization}
                renderItem={renderOrganization}
                items={vm.availableOrganizations}
                maxWidth="600px"
              />
            ) : (
              vm.organization?.name
            )}
            <OrgInn>{vm.organization && `ИНН ${vm.organization.inn}`}</OrgInn>
          </OrgNameWrap>
          <BalanceWrap>
            <BalanceText />
          </BalanceWrap>

          <ReactUiThemeContext.Provider value={updatedTheme}>
            <Tabs data-tid="SettingsTabs" value={active} onValueChange={onChangeTab}>
              <Tabs.Tab id={SettingsSubRoute.Employees}>Сотрудники</Tabs.Tab>
              <Tabs.Tab id={SettingsSubRoute.Registrators}>Регистраторы выбытия</Tabs.Tab>
              <Tabs.Tab id={SettingsSubRoute.Departments}>Подразделения</Tabs.Tab>
              <Tabs.Tab id={SettingsSubRoute.Requisites}>Реквизиты ИС МДЛП</Tabs.Tab>
            </Tabs>
          </ReactUiThemeContext.Provider>

          <Separator />

          <Switch location={location}>
            <Route exact path={SettingsRoute.baseRoute} component={EmployeesPageWrap} />
            <Route exact path={SettingsRoute.getHref(SettingsSubRoute.Departments)} component={DepartmentsPageWrap} />
            <Route exact path={SettingsRoute.getHref(SettingsSubRoute.Employees)} component={EmployeesPageWrap} />
            <Route exact path={SettingsRoute.getHref(SettingsSubRoute.Requisites)} component={RequisitesPageWrap} />
            <Route exact path={SettingsRoute.getHref(SettingsSubRoute.Registrators)} component={RegistratorsPageWrap} />
          </Switch>
        </Loader>
      </Content>
    );
  })
);

export const SettingsPage = () => {
  const vm = SettingsPageVM.getInstance(SettingsStore, UserStore, SignatureStore);

  if (!vm.hasAccess) {
    return (
      <NoRights>
        <WarningIcon />
        &nbsp;&nbsp;Недостаточно прав для просмотра настроек
      </NoRights>
    );
  }

  return <SettingsPageInternal vm={vm} />;
};
