import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import { Button } from "components";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { CertificatesList } from "../../../CertificatesList/CertificatesList";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { PageHeader } from "../Common/PageHeader/PageHeader";
import { DocumentLite } from "@skbkontur/react-icons";
import { ErrorSignBlock } from "../../../CertificatesList/shared";
import { WithdrawalSidePage } from "../Common/SidePage";
import { RollbackButton } from "Common/Rollback/RollbackButton/RollbackButton";
import { RollbackModal } from "Common/Rollback/RollbackModal/RollbackModal";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { DeleteBtnWrap, deleteBtnTheme } from "Common/DetailsPage/DetailsPage.styles";
import TrashIcon from "@skbkontur/react-icons/Trash";
import { SuccessSignBlock } from "Common/SuccessSignBlock/SuccessSignBlock";
import { DestructionEditorModal } from "features/Circulation/Editor/DestructionEditorModal";
import { CirculationPageVM } from "../CirculationPageVM";
import { DeliveryType } from "typings/server";
import { DestructionModal } from "./destructionModal";
import { CancelDestructionModal } from "./cancelDestructionModal";
import { DestructionStages } from "./destructonStages";
import { DestructionApi } from "api/DestructionApi";

interface IPageProps extends RouteComponentProps {
  vm: CirculationPageVM;
}

export const DestructionPage = withRouter(
  observer((props: IPageProps) => {
    const { vm, history } = props;

    useEffect(() => () => vm.handleUnmount(), [vm]);

    const save = () => {
      return vm.save();
    };

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const toggleEditorModal = () => vm.toggleModal("editorModal");

    const toggleScanPage = () => vm.toggleModal("scanPage");

    const toggleRollbackModal = () => vm.toggleModal("rollback");

    const onRollback = async () => {
      await vm.rollbackDeliveryStatus();
      toggleRollbackModal();
    };

    const onRollbackButtonClick = async () => {
      if (vm.shouldOpenRollbackModal) {
        toggleRollbackModal();
      } else {
        vm.rollbackDeliveryStatus();
      }
    };

    const toggleDeleteModal = () => vm.toggleModal("delete");

    const toggleDestructionModal = async () => vm.toggleModal("destruction");

    const toggleCancelDestructionModal = () => vm.toggleModal("cancelDestruction");

    const onDelete = async () => {
      await vm.deleteDelivery();
      toggleDeleteModal();
      history.push(CirculationRoute.destructionBaseRoute);
    };

    const goBackBlock = <GoToBlock name="К списку операций" href={CirculationRoute.destructionBaseRoute} />;

    const saveDraft = () => {
      vm.changeType(DeliveryType.Destruction);
      history.push(CirculationRoute.destructionBaseRoute);
    };

    useEffect(() => {
      if (vm.delivery.type === DeliveryType.Destruction) {
        DestructionApi.getSendingToDestruction(vm.delivery.id).then(destruction => {
          vm.destruction = destruction;
        });
      }
    }, []);

    useEffect(() => {
      // При закрытии попапа - сходим в стейт,
      // чтобы актуализировать модель в нашей vm (в попапе своя vm)
      if (!vm.modalMap.get("editorModal")) {
        DestructionApi.getSendingToDestruction(vm.delivery.id).then(destruction => {
          vm.destruction = destruction;
        });
      }
    }, [vm.modalMap.get("editorModal")]);

    useEffect(() => {
      // Если дочерняя операция уже появилась - прелоадеры второго документа нужно скрыть
      if (vm.delivery.childDeliveries.length) {
        vm.onSendingSecondDocument(false);
      }
    }, [vm.delivery.childDeliveries.length]);

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding="24px 24px 24px 38px">
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title="Подписание документа"
              signText="Документ будет подписан при помощи сертификата:"
              renderSuccessBlock={() => (
                <SuccessSignBlock route={CirculationRoute.baseRoute} textSubString="операций" />
              )}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={vm.sign}
            />
          )}
          {vm.modalMap.get("editorModal") && (
            <DestructionEditorModal onClose={toggleEditorModal} delivery={vm.delivery} destruction={vm.destruction} />
          )}

          {vm.modalMap.get("cancelDestruction") && (
            <CancelDestructionModal
              deliveryId={vm.delivery.id}
              onClose={toggleCancelDestructionModal}
              updateModel={vm.updateWaitingForCounterpartyModel}
              onStartSendingSecondDocument={vm.onSendingSecondDocument}
            />
          )}
          {vm.modalMap.get("scanPage") && (
            <WithdrawalSidePage
              additionalInfo={vm.additionalInfo}
              close={toggleScanPage}
              delivery={vm.delivery}
              save={vm.saveItems}
            />
          )}
          {vm.modalMap.get("rollback") && (
            <RollbackModal
              headerText="Вернуться к черновику?"
              bodyText="Передача на уничтожение станет доступна для редактирования и повторной отправки в ИС МДЛП."
              onClose={toggleRollbackModal}
              onRollback={onRollback}
              isLoading={vm.loadingMap.get("rollback")}
            />
          )}
          {vm.modalMap.get("delete") && (
            <DeleteDeliveryModal
              deliveryType={vm.delivery.type}
              isDeliveryError={vm.isFailedStatus}
              isScannedCodes={!!vm.delivery.allCodesLen}
              isLoading={vm.loadingMap.get("delete")}
              onClose={toggleDeleteModal}
              onDelete={onDelete}
            />
          )}
          {vm.modalMap.get("destruction") && (
            <DestructionModal
              destruction={vm.destruction}
              delivery={vm.delivery}
              onClose={toggleDestructionModal}
              updateModel={vm.updateWaitingForCounterpartyModel}
              onStartSendingSecondDocument={vm.onSendingSecondDocument}
            />
          )}

          <PageHeader vm={vm} type={DeliveryType.Destruction} goBackBlock={goBackBlock} onEdit={toggleEditorModal} />

          <DestructionStages vm={vm} />
        </Content>

        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showSignBtn && (
                <Button data-tid="Sign" onClick={toggleCertificatesModal} size="large" use="primary" width="200px">
                  Подписать
                </Button>
              )}
              {vm.showDestructionButton && (
                <Button data-tid="Destruction" onClick={toggleDestructionModal} size="large" use="primary">
                  Перейти к отправке на уничтожение
                </Button>
              )}
              {vm.showDestructionButton && (
                <Button data-tid="CancelDestruction" onClick={toggleCancelDestructionModal} size="large" use="default">
                  Отменить передачу на уничтожение
                </Button>
              )}
              {vm.showSaveBtn && (
                <Button
                  data-tid="CreateDocument"
                  use="primary"
                  onClick={save}
                  size="large"
                  loading={vm.loadingMap.get("creatingDocument")}
                  disabled={!vm.delivery.allCodesLen}
                >
                  Завершить передачу на уничтожение
                </Button>
              )}
              {vm.showRollbackBtn && (
                <RollbackButton onClick={onRollbackButtonClick} size="large" hintText={vm.rollbackHintText}>
                  Вернуться к черновику
                </RollbackButton>
              )}
              {vm.showDeleteBtn && (
                <DeleteBtnWrap>
                  <Button
                    data-tid="DeleteDocument"
                    onClick={toggleDeleteModal}
                    theme={deleteBtnTheme}
                    use="link"
                    icon={<TrashIcon />}
                  >
                    {vm.isFailedStatus ? "Удалить передачу на уничтожение" : "Удалить черновик"}
                  </Button>
                </DeleteBtnWrap>
              )}
            </FooterBtnWrapper>
            {vm.isEditable && (
              <Button data-tid="SaveAndClose" use="link" onClick={saveDraft} size={"medium"} icon={<DocumentLite />}>
                Сохранить и выйти
              </Button>
            )}
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
