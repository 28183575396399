import React from "react";
import { CurrencyLabelProps, CurrencyLabel } from "@skbkontur/react-ui";

interface ICustomCurrencyLabelProps extends Partial<CurrencyLabelProps> {
  value: number;
}

export const CustomCurrencyLabel = (props: ICustomCurrencyLabelProps) => {
  let value;
  if (typeof props.value === "string") {
    value = parseFloat(parseFloat(props.value).toFixed(2));
  } else value = parseFloat(props.value!.toFixed(2));

  return <CurrencyLabel currencySymbol={""} fractionDigits={2} {...props} value={value} />;
};
