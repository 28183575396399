import styled from "styles/styled-components";

interface DeliveryStatusProps {
  color?: string;
}

export const DeliveryStatusSpan = styled.span<DeliveryStatusProps>`
  color: ${props => props.color};
`;

export const DeliveryListRow = styled.div`
  cursor: pointer;
  padding: 5px 0;

  &:hover {
    background-color: ${props => props.theme.colors.greySmoke};
  }
`;

export const DeliveryPagingWrapper = styled.div`
  margin-top: 16px;
  height: 55px;
  span {
    box-sizing: content-box;
  }
`;

export const AcceptusTypeLabelWrap = styled.div`
  max-width: 230px;
`;

export const DeleteWrap = styled.span`
  color: ${props => props.theme.textColors.lightGray2};

  &:hover {
    color: ${props => props.theme.textColors.red};
  }
`;
