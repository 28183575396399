import React from "react";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { UtdScanPageBody } from "features/ScannerSidePage/SidePages/UtdScan/UtdScanPageBody";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { UtdScanSidePageVM } from "features/ScannerSidePage/SidePages/UtdScan/UtdScanSidePageVM";
import { AcceptanceStore } from "stores/AcceptanceStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { SignatureStore } from "stores/SignatureStore";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface ISidePageProps {
  close(): void;
  onSave(): Promise<void>;
  good: UtdPositionModelExtended;
  additionalInfo: CodesAdditionalInfo;
}

export const ReverseAcceptanceScannerSidePage: React.FC<ISidePageProps> = (props: ISidePageProps) => {
  const { additionalInfo, onSave, good } = props;
  const vm = new UtdScanSidePageVM(AcceptanceStore, SignatureStore, AdvertisementStore, additionalInfo, good, onSave);

  return (
    <ScannerSidePageContainer scanText={"Отсканируйте коды маркировки"} headerText={good.name} vm={vm} {...props}>
      <UtdScanPageBody vm={vm} />
    </ScannerSidePageContainer>
  );
};
