import styled from "styles/styled-components";

export const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StatusIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 14px;
  height: 14px;
`;
