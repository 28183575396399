import React from "react";
import { TextBlock } from "./ErrorPage.styles";
import { CloudsPage } from "./CloudsPage";

interface NotFoundPageProps {}

// todo: импортировать из common, не работает импорт файлов png
export function NotFoundPage(_props: NotFoundPageProps) {
  return (
    <CloudsPage>
      <h1>Страница не найдена</h1>
      <TextBlock>
        <p>
          Возможно, вы ошиблись, набирая адрес, или данная страница&nbsp;удалена. Еcли адрес страницы верный — напишите
          нам об этом на <a href="mailto:help@kontur.ru">help@kontur.ru</a>
          &nbsp;или позвоните: 8 800 500-50-80.
        </p>
        <p>
          <a href="/">Перейти на главную</a>
        </p>
      </TextBlock>
    </CloudsPage>
  );
}
