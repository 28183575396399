import styled from "styles/styled-components";

export const Footer = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.greyLight};
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 36px;
  padding-right: 36px;
  z-index: 1;
`;

export const FooterSticky = styled.div`
  position: sticky;
  bottom: -1px;
  display: flex;
  background-color: ${props => props.theme.colors.greyLight};
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 36px;
  padding-right: 36px;
  z-index: 3;
`;

export const FooterBtnWrapper = styled.div`
  min-height: 42px; // btn large height
  & > * {
    margin-right: 10px;
  }
`;
