import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { TransferRoute } from "routes";
import { Loader } from "components/Loader";
import { SignatureStore } from "stores/SignatureStore";
import { DeliveryModel, DeliveryType } from "typings/server";
import { TransferPage } from "./TransferPage/TransferPage";
import { TransferPageVM } from "./TransferPage/TransferPageVM";
import { TransferStore } from "stores/TransferStore";
import { UserStore } from "stores/UserStore";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";

export const DetailsPageWrapper = (props: RouteComponentProps<TransferRoute>) => {
  const { match } = props;
  const id = match.params.operationId;
  const [loading, setLoading] = useState<boolean>(true);
  const [delivery, setDelivery] = useState<DeliveryModel | null>();

  useEffect(() => {
    if (id) {
      TransferStore.getItem(id, { force: true })
        .then(delivery => {
          TransferStore.setSelected(delivery);
          setDelivery(delivery);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) return <Loader active />;
  if (!delivery) return <>Поставка не найдена</>;

  return (
    <DeliveryTypeContext.Provider value={delivery.type}>
      <TransferPage
        vm={new TransferPageVM(TransferStore, SignatureStore, UserStore, DeliveryType.Transference)}
        {...props}
      />
    </DeliveryTypeContext.Provider>
  );
};
