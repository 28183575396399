import styled from "styles/styled-components";

export const ModalContent = styled.div`
  max-width: 450px;
`;

export const WarnContent = styled.div`
  display: flex;
`;

export const UnscannedText = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

export const WarnIconWrapText = styled.div`
  margin-right: 16px;
`;

export const WarningBlock = styled.div`
  background-color: ${props => props.theme.colors.yellowPale};
  margin-top: 18px;
  display: flex;
  padding: 15px 10px;
  border-radius: 3px;
`;

export const IconWrap = styled.div`
  color: ${props => props.theme.colors.orangeDark};
  margin-right: 10px;
`;
