import React, { memo, useCallback } from "react";
import { observer } from "mobx-react";
import { Button, Modal, Select, Tooltip } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { ReentryEditorModalVM } from "./ReentryEditorModalVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { DocumentHeader, EditorLabel, ModalBodyInner, HelpDotIconWrap } from "../EditorModal.styles";
import { UserStore } from "stores/UserStore";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { EditorFields } from "../EditorFields";
import { IType } from "typings/dictionaries";
import { CirculationSubRoute } from "routes/CirculationRoute";
import { WithdrawalCause } from "typings/server";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { HelpTooltip } from "./HelpTooltip";

interface IOperationModalProps {
  onClose(): void;
  delivery?: WithdrawalDeliveryModel;
}

export const ReentryEditorModal = memo(
  (props: IOperationModalProps) => {
    const { delivery } = props;
    const vm = new ReentryEditorModalVM(WithdrawalStore, UserStore, delivery);
    return <ReentryModalInternal {...props} vm={vm} />;
  },
  // memo + () => true - не перерендериваем компонент
  () => true
);

interface Props extends IOperationModalProps, RouteComponentProps<CirculationRoute> {
  vm: ReentryEditorModalVM;
}

const ReentryModalInternal = withRouter(
  observer((props: Props) => {
    const { vm, history, onClose } = props;

    const getReasonError = () => vm.errors.get("reasonRequired");

    const getReturnReasonError = () => {
      if (vm.withdrawalCause?.value !== WithdrawalCause.HealthCare) {
        return;
      }

      return "При выводе из оборота по причине выдачи для оказания медицинской помощи возрат должен быть ненадлежащего качества";
    };

    const getHelpTooltip = () => {
      return <HelpTooltip />;
    };

    const renderItem = useCallback((item: IType) => item.label, []);

    const onSave = async () => {
      try {
        const id = await vm.save();
        if (id) {
          history.push(CirculationRoute.getHref(id, CirculationSubRoute.reentry));
        } else {
          onClose();
        }
      } catch (e) {
        // empty promise for validation fail
        if (e) {
          throw e;
        }
      }
    };

    return (
      <Modal data-testid="ReentryEditorModal" data-tid="ReentryEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>
          Повторный ввод в оборот
          <Tooltip trigger="hover" pos="right top" render={getHelpTooltip}>
            <HelpDotIconWrap>
              <HelpDotIcon />
            </HelpDotIconWrap>
          </Tooltip>
        </Modal.Header>
        <Modal.Body>
          <ModalBodyInner>
            <Grid cols="135px 1fr">
              <EditorFields.Sender vm={vm} required row={1} />

              <EditorFields.ReportDate vm={vm} required row={2} />

              <GridCell col={1} row={3} padding="15px 4px 2px 4px">
                <EditorLabel required>Причина вывода из оборота</EditorLabel>
              </GridCell>

              <GridCell col={2} row={3}>
                <Tooltip trigger="hover" render={getReasonError}>
                  <Select<IType>
                    data-testid="WithdrawalCauses"
                    placeholder="Выберите причину вывода из оборота"
                    width="100%"
                    maxWidth="320px"
                    items={vm.withdrawalCauses}
                    onValueChange={vm.setWithdrawalCause}
                    renderItem={renderItem}
                    renderValue={renderItem}
                    error={!!getReasonError()}
                    value={vm.withdrawalCause}
                    maxMenuHeight={250}
                  />
                </Tooltip>
              </GridCell>

              <GridCell col={1} row={4} padding="15px 2px">
                <EditorLabel>Причина возврата</EditorLabel>
              </GridCell>

              <GridCell col={2} row={4}>
                <Tooltip trigger="hover" render={getReturnReasonError} pos="right top" allowedPositions={["right top"]}>
                  <Select<IType>
                    data-tid="ReturnReasons"
                    placeholder="Выберите причину возврата"
                    disabled={vm.withdrawalCause?.value === WithdrawalCause.HealthCare}
                    width="100%"
                    maxWidth="320px"
                    items={vm.returnReasons}
                    onValueChange={vm.setReturnReasons}
                    renderItem={renderItem}
                    renderValue={renderItem}
                    value={vm.returnReason}
                    maxMenuHeight={250}
                  />
                </Tooltip>
              </GridCell>

              <GridCell colStart={1} colEnd={3} row={5}>
                <DocumentHeader>Документ-основание</DocumentHeader>
              </GridCell>

              <EditorFields.DocumentNumber vm={vm} required={vm.isDocumentInfoRequired} row={6} />

              <EditorFields.DocumentDate vm={vm} required={vm.isDocumentInfoRequired} row={7} />
            </Grid>
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer panel>
          <FooterBtnWrapper>
            <Button data-testid="Submit" use="primary" size={"large"} onClick={onSave}>
              {vm.isNew ? "Добавить повторный ввод в оборот" : "Сохранить"}
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  })
);
