import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { CreatingDocWrap, DateAndFIOWrap } from "Common/Stages/Stages.styles";
import { UnpackingPageVM } from "../UnpackingPageVM";
import { PageInfoBlock } from "./PageInfoBlock/PageInfoBlock";
import { OperationsStatusNames } from "Common/Status/StatusesName";
import { DateAndUser } from "Common/Stages/DateAndUser";
import { CirculationWarning } from "Common/CirculationWarning/CirculationWarning";

interface IStagesProps {
  vm: UnpackingPageVM;
}

export const UnpackingStagesBlock: React.FC<IStagesProps> = observer(({ vm }: IStagesProps) => {
  useEffect(() => {
    if (vm.delivery.status === DeliveryStatus.Processing) {
      // Если delivery переходит в Processing, стадия должна быть развернута
      // Например, при возврате в состояние черновика.
      vm.onCollapse(false);
    }
  }, [vm.delivery.status]);

  return (
    <DeliveryStagesBlock>
      <DeliveryStatusBlock
        data-tid="ProcessingStage"
        size={vm.getTitleSize(vm.stages[DeliveryStage.Processing])}
        name={vm.stages[DeliveryStage.Processing].name}
        status={vm.stages[DeliveryStage.Processing].progress}
        collapsed={vm.collapsed}
        alwaysVisibleComp={
          <>
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.Processing}
            />
            {vm.isShowCirculationWarning && <CirculationWarning isError={vm.isFailedStatus} />}
          </>
        }
        onCollapse={vm.onCollapse}
      >
        <PageInfoBlock vm={vm} />
      </DeliveryStatusBlock>
      <DeliveryStatusBlock
        data-tid="SigningStage"
        status={vm.stages[DeliveryStage.Signing].progress}
        name={vm.stages[DeliveryStage.Signing].name}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Signing])}
        error={vm.stages[DeliveryStage.Signing].errorInfo}
        alwaysVisibleComp={
          <>
            {vm.delivery.status === DeliveryStatus.CreatingDocument && (
              <CreatingDocWrap>
                <Spinner type="mini" caption={OperationsStatusNames[DeliveryStatus.CreatingDocument]} />
              </CreatingDocWrap>
            )}
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.Signing}
            />
          </>
        }
      />

      <DeliveryStatusBlock
        data-tid="SendingStage"
        status={vm.stages[DeliveryStage.Sending].progress}
        name={vm.getSendingStepName(vm.stages[DeliveryStage.Sending])}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Sending])}
        error={vm.stages[DeliveryStage.Sending].errorInfo}
        alwaysVisibleComp={
          <DateAndFIOWrap data-tid="CompleteInfo">
            {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Sending])}
          </DateAndFIOWrap>
        }
      />

      <DeliveryStatusBlock
        data-tid="DoneStage"
        status={vm.stages[DeliveryStage.Done].progress}
        name={vm.stages[DeliveryStage.Done].name}
        error={vm.stages[DeliveryStage.Done].errorInfo}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
      />
    </DeliveryStagesBlock>
  );
});
