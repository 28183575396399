import React from "react";
import { observer } from "mobx-react";
import { DepartmentsTitle, TextWrapper } from "./GreetingsIndexPage.styles";
import { Link, RouterLink } from "components";
import { departmentDirectoriesInstruction, MDLPCabinetUrl, SupportPhone } from "StringResx";
import { DepartmentsTable } from "../Settings/DepartmentsPage/DepartmentsTable/DepartmetsTable";
import { SettingsPageVM } from "../Settings/SettingsPageVM";
import { DepartmentModel } from "typings/server";
import { UserStore } from "stores/UserStore";
import { RootRoute } from "routes";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";

interface IDepartmentsSubPageProps {
  vm: SettingsPageVM;
  authUrl: string;
}

export const DepartmentsSubPage = observer((props: IDepartmentsSubPageProps) => {
  const { vm, authUrl } = props;

  const onSelectDepartment = async (dep: DepartmentModel) => {
    await UserStore.setCurrentDep(dep);
    window.location.href = RootRoute;
  };

  return (
    <>
      {vm.departments?.length ? (
        <>
          <TextWrapper>
            <DepartmentsTitle>
              Подразделения загружены из &nbsp;
              <Link href={MDLPCabinetUrl} target={"_blank"}>
                ИС МДЛП
              </Link>
              .
              <br />
              Выберете подразделение для работы с маркированными товарами.
            </DepartmentsTitle>
            <Link href={departmentDirectoriesInstruction} icon={<HelpDotIcon />} target="_blank">
              Заполнение справочников подразделений
            </Link>
          </TextWrapper>
          <DepartmentsTable vm={vm} onSelect={onSelectDepartment} />
        </>
      ) : (
        <div>
          <TextWrapper>
            <DepartmentsTitle>
              Мы не нашли подразделений, проверьте пожалуйста&nbsp;
              <Link href={MDLPCabinetUrl} target={"_blank"}>
                личный кабинет ИС МДЛП,
              </Link>
              <br />
              или вернитесь на <RouterLink to={authUrl}>предыдущий шаг</RouterLink> и попробуйте авторизоваться еще раз.
            </DepartmentsTitle>
            <Link href={departmentDirectoriesInstruction} icon={<HelpDotIcon />} target="_blank">
              Заполнение справочников подразделений
            </Link>
          </TextWrapper>
          <p>
            Если ошибка повторяется, обратитесь в техподдержку&nbsp;
            <Link href={`tel:${SupportPhone}`}>{SupportPhone}</Link>
          </p>
        </div>
      )}
    </>
  );
});
