import React, { PropsWithChildren, useState } from "react";
import { observer } from "mobx-react";
import { Button, Modal } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { WarningRedIcon } from "icons";
import { getEnding } from "helpers/endings";
import { ModalContent, UnscannedText, WarnContent, WarnIconWrapText } from "./DeliveryConfirmation.styles";

interface IUnregisteredWarningProps {
  onSave(): Promise<void>;
  onClose(): void;
  unscannedLen: number;
  title: string;
  errorText: string;
  isSimplified?: boolean;
}

export const DeliveryConfirmation: React.FC<IUnregisteredWarningProps> = observer(
  (props: PropsWithChildren<IUnregisteredWarningProps>) => {
    const [error, setError] = useState("");
    const { title, errorText, onSave, isSimplified } = props;

    const onComplete = async () => {
      try {
        await onSave();
        props.onClose();
      } catch (e) {
        setError(errorText);
        throw e;
      }
    };

    const { unscannedLen } = props;
    const ending = getEnding(unscannedLen, "товаре", "товарах", "товарах");

    const onClose = () => props.onClose();

    return (
      <Modal data-tid="DeliveryConfirmationModal" onClose={onClose}>
        <Modal.Header sticky>{title}</Modal.Header>
        <Modal.Body>
          <ModalContent>
            {error ? (
              <WarnContent>
                {!!unscannedLen && (
                  <WarnIconWrapText>
                    <WarningRedIcon />
                  </WarnIconWrapText>
                )}
                {error}
              </WarnContent>
            ) : (
              <WarnContent>
                {!!unscannedLen && (
                  <WarnIconWrapText>
                    <WarningRedIcon />
                  </WarnIconWrapText>
                )}
                <div>
                  {!!unscannedLen && (
                    <>
                      <UnscannedText>
                        В {unscannedLen} {ending} остались неотсканированные коды
                      </UnscannedText>
                      В ИС МДЛП будут отправлены только отсканированные коды
                      {isSimplified ? " по упрощенной 702 схеме" : ""}. Для отправки всех кодов отсканируйте оставшиеся
                      товары. <br />
                    </>
                  )}

                  {props.children}
                </div>
              </WarnContent>
            )}
          </ModalContent>
        </Modal.Body>
        <Modal.Footer panel sticky>
          <FooterBtnWrapper>
            {!error && (
              <Button data-tid="Submit" size={"large"} use="primary" onClick={onComplete}>
                Завершить
              </Button>
            )}
            <Button data-tid="Cancel" size={"large"} onClick={onClose}>
              {error ? "Закрыть" : "Отменить"}
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  }
);
