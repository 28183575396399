import React from "react";
import { observer } from "mobx-react";
import {
  ClientInfoBlockAddress,
  ClientInfoBlockInn,
  ClientInfoBlockLabel,
  ClientInfoBlockTitle,
  ClientTopBlock,
  ClientTopBlockSupplier,
  DownloadBtnWrap,
  GoToDetailPageWrapper,
  TitlePageEditWrap,
  TitlePageWrapper,
} from "Common/PageHeader/PageHeader.styles";
import { UnpackingPageVM } from "../UnpackingPageVM";
import { getFormattedDate } from "helpers/date";
import DownloadIcon from "@skbkontur/react-icons/Download";
import { Button, Spinner } from "components";

interface IPageHeaderProps {
  vm: UnpackingPageVM;
  goBackBlock: JSX.Element;
  onDownload(): void;
}

export const PageHeader: React.FC<IPageHeaderProps> = observer((props: IPageHeaderProps) => {
  const { vm, goBackBlock, onDownload } = props;
  const isDownloading = vm.loadingMap.get("download");
  return (
    <div data-tid="PageHeader">
      <GoToDetailPageWrapper>{goBackBlock}</GoToDetailPageWrapper>

      <TitlePageWrapper>
        <h2 data-tid="Header">
          Расформирование от {vm.delivery.reportDate && getFormattedDate(vm.delivery.reportDate)}
        </h2>
        <TitlePageEditWrap>
          {vm.hasDocuments && (
            <DownloadBtnWrap>
              <Button
                data-tid="DownloadDocuments"
                use="link"
                icon={isDownloading ? <Spinner type="mini" caption="" /> : <DownloadIcon />}
                onClick={onDownload}
                size="small"
                disabled={isDownloading}
              >
                Скачать документы
              </Button>
            </DownloadBtnWrap>
          )}
        </TitlePageEditWrap>
      </TitlePageWrapper>

      <ClientTopBlock>
        <ClientTopBlockSupplier>
          <ClientInfoBlockLabel>Место операции</ClientInfoBlockLabel>
          <ClientInfoBlockTitle data-tid="SupplierName">{vm.supplier?.name}</ClientInfoBlockTitle>
          <ClientInfoBlockInn data-tid="SupplierInn">ИНН {vm.supplier?.inn}</ClientInfoBlockInn>
          <ClientInfoBlockAddress data-tid="SupplierAddress">{vm.supplier?.address}</ClientInfoBlockAddress>
        </ClientTopBlockSupplier>
      </ClientTopBlock>
    </div>
  );
});
