import { action, computed, observable, toJS } from "mobx";
import { hasRuChars } from "helpers/lang";
import { getGtinFromSgtin, isDataMatrix, isSgtin, isSscc, normalizeDataMatrix } from "helpers/codes";
import { IErrorObj, IScannerSidePageVM } from "../../shared";
import { IStore } from "stores/shared";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { ISignatureStore } from "stores/SignatureStore";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { ScanHelper, ScanMode } from "../../ScanHelper";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { BaseScanPageVM, ScanValidationErrors } from "../BaseScanPageVM";
import { QRCodeId } from "../../QrCode/QrCodeBlock";
import { DeliveryType } from "typings/server";
import { IAdvertisementStore } from "stores/AdvertisementStore";
import { CodesApi } from "api/CodesApi";

export interface IFreeScanError {
  code: ScanValidationErrors;
  errorObj: IErrorObj;
}

export interface FractionValidationInfo {
  totalPartsErr?: string;
  partErr?: string;
}

interface IFreeScanDeliveryModel {
  id: string;
  updateItems(items: DeliveryItemModelExtended[]): void;
  items: DeliveryItemModelExtended[];
  type: DeliveryType;
}

export class FreeScanSidePageVM<T extends IStore>
  extends BaseScanPageVM<IStore, DeliveryItemModelExtended, IErrorObj>
  implements IScannerSidePageVM {
  @observable fractionsValidationMap: Map<string, FractionValidationInfo> = new Map();
  isSubmitted = false;
  public submitCallback: null | { (): void } = null;
  public scanCallback: null | { (gtin: string): void } = null;
  constructor(
    store: T,
    signatureStore: ISignatureStore,
    advertisementStore: IAdvertisementStore,
    additionalInfo: CodesAdditionalInfo,
    private readonly delivery: IFreeScanDeliveryModel,
    readonly onSaveCodes: (id: string) => Promise<void>,
    readonly getCustomValidations?: (
      code: string,
      copiedData: DeliveryItemModelExtended[]
    ) => undefined | IFreeScanError
  ) {
    super(
      store,
      signatureStore,
      advertisementStore,
      additionalInfo,
      delivery.id,
      delivery.type === DeliveryType.DisposalWithRegistrator
    );
    this.setItemsFromDelivery();
  }

  @action
  async addScannedCode(code: string, codeStr: string) {
    // clear server error, clear last codeAdditionalInfo
    if (!this.tokenExpired) {
      this.serverErrors.clear();
    }
    if (!this.checkValidation(code, codeStr)) {
      // не сохраняем невалидный код, смотри updateItemName
      if (this.errors.size) this.currentCode = undefined;
      return;
    }
    // Добавляя код, положим его статус выбытия в стор, если он есть
    this.setCurrentScanCodeStatus(code);

    const item = this.getItem(code, this.isDisposalWithRegistrator ? codeStr : undefined);

    const index = this.copiedData.findIndex(i => item === i);
    if (index !== -1) {
      this.copiedData.splice(index, 1);
    }
    this.copiedData.unshift(item);
    if (this.isSetNewCode) {
      this.setNewCode(code);
    }
    this.setCurrentCode(code);
    this.setNewCodeItem(item);
    this.getCodeAdditionalInfo(code, item);
    this.scanCallback?.(item.gtin);
  }

  @action
  async getCodeStatus(code: string) {
    return CodesApi.getCodesStatus([code]);
  }

  private getItem(code: string, codeStr?: string): DeliveryItemModelExtended {
    let itemExist: DeliveryItemModelExtended | undefined;

    if (isSgtin(code)) {
      const gtin = getGtinFromSgtin(code);
      itemExist = this.copiedData.find(i => i.gtin === gtin);
    }

    const codeModel = new CodeModelExtended({ code, scanned: true, dataMatrix: normalizeDataMatrix(codeStr) });
    if (itemExist) {
      itemExist.tryToAddCode(codeModel);
      return itemExist;
    } else {
      return new DeliveryItemModelExtended({ code }, [codeModel]);
    }
  }

  @action
  updateItemName(code?: string) {
    if (!code) {
      return;
    }
    const item = this.copiedData.find(item => item.code === code);
    if (item) {
      this.getCodeAdditionalInfo(code, item);
      // Добавляя код, положим его статус выбытия в стор, если он есть
      this.setCurrentScanCodeStatus(code);
    }
  }

  // Экшен, который ходит за статусом по выбытому коду (если это нужно) и хранит инфу о нем в сторе
  // Дальше мы будем использовать эту инфу во вьюмодели дополнительной инфы о коде
  @action
  private setCurrentScanCodeStatus(code: string) {
    if (
      this.store.selectedDelivery?.type === DeliveryType.Disposal ||
      this.store.selectedDelivery?.type === DeliveryType.DisposalWithRegistrator ||
      this.store.selectedDelivery?.type === DeliveryType.Withdrawal
    ) {
      // Перед запросом очистим значение в сторе
      this.store.setCurrentScanCodeStatus?.(undefined);
      CodesApi.getCodesStatus([code])
        .then(codeStatus => {
          this.store.setCurrentScanCodeStatus?.(codeStatus);
        })
        .catch(this.handleErrors);
    }
  }

  @action
  private checkValidation(code: string, codeStr?: string): boolean {
    this.showError = false;
    this.showWarn = false;
    this.errors.clear();
    this.warns.clear();
    this.setErrorWarningTimeout();
    if (codeStr && hasRuChars(codeStr)) {
      this.errors.set("lang", {
        title: "Код маркировки не должен содержать русские буквы",
        description: "Русская раскладка клавиатуры. Переключите на английскую и повторите сканирование.",
      });
      this.showError = true;
      return false;
    }

    if (
      (!isSgtin(code) && !isSscc(code)) ||
      (isSgtin(code) && ScanHelper.isCurrentMode(ScanMode.Scanner) && codeStr && !isDataMatrix(codeStr))
    ) {
      // Логируем некорректный код, т.к. слишком много обращений по этому поводу
      // eslint-disable-next-line no-console
      console.log(`Некорректный код - ${codeStr}`);
      this.errors.set("wrongCode", {
        title: "Неверный формат кода",
        description: "Неверный формат кода. Продолжайте сканирование.",
      });
      this.showError = true;
      return false;
    }

    const existingScannedItem = this.copiedData.find(item => item.getCode(code));
    if (existingScannedItem) {
      this.scanCallback?.(existingScannedItem.gtin);
      const err = this.mode === ScanMode.Input ? "Этот код уже есть в списке" : "Этот код уже сканировали";
      this.warns.set("alreadyExist", err);
      this.setNewCode(code);
      // show additional info again
      this.getCodeAdditionalInfo(code).then(() => {
        const found = this.copiedData.find(item => item.getCode(code));
        if (found) this.setNewCodeItem(found);
      });
      this.showWarn = true;
      return false;
    }

    const customError = this.getCustomValidations?.(code, this.copiedData);
    if (customError) {
      this.errors.set(customError.code, customError.errorObj);
      this.showError = true;
      return false;
    }

    return true;
  }

  @action
  checkAllFractionDisposalValidation() {
    this.isSubmitted = true;
    this.copiedData.forEach(item => {
      item.sgtinCodes.forEach(codeModel =>
        this.checkFractionDisposalValidation(codeModel.code, codeModel.part, codeModel.totalParts)
      );
    });
  }

  @action.bound
  checkFractionDisposalValidation(code: string, part?: number, totalParts?: number) {
    if (!this.isSubmitted) {
      return;
    }
    let validationObj: FractionValidationInfo = {};
    if (part != null && totalParts != null && part > totalParts) {
      validationObj.partErr = "Выводимая часть упаковки должна быть меньше общего количества долей";
    }
    if (part != null && totalParts == null) {
      validationObj.totalPartsErr = "При указании части упаковки необходимо также указать общее количество долей";
    }
    if (part === 0) {
      validationObj.partErr = "Некорректное значение";
    }
    if (totalParts === 0) {
      validationObj.totalPartsErr = "Некорректное значение";
    }
    if (validationObj.partErr || validationObj.totalPartsErr) {
      this.fractionsValidationMap.set(code, validationObj);
    } else {
      this.fractionsValidationMap.delete(code);
    }
  }

  @action
  validateSubmit() {
    this.checkAllFractionDisposalValidation();
    this.submitCallback?.();
    return this.fractionsValidationMap.size === 0;
  }

  @action
  async save() {
    this.loadingState.set("onSave", true);
    const deliveryItemsOld = this.delivery.items;
    try {
      if (this.isPhoneMode) {
        // stop polling and get fresh codes from the server before closing side page
        this.pollCodes.stop();
        await this.pollScannedCodesFunc();
      }
      this.delivery.updateItems(this.copiedData);
      await this.onSaveCodes(this.deliveryId);
    } catch (e) {
      this.delivery.updateItems(deliveryItemsOld);
      throw e;
    } finally {
      this.loadingState.set("onSave", false);
    }
  }

  @action
  discardAll() {
    this.copiedData = [];
    this.resetFields();
  }

  @action
  discardCode = (code: string) => {
    const foundItem = this.copiedData.find(item => item.getCode(code));
    if (foundItem) {
      if (foundItem.allCodes.length === 1) {
        this.copiedData.splice(this.copiedData.indexOf(foundItem), 1);
      } else {
        foundItem.removeCode(code);
      }
    }
    this.deletedCodes.push(code);
    this.resetFields();
  };

  @action
  discardItem(item: DeliveryItemModelExtended): void {
    const ind = this.copiedData.indexOf(item);
    if (ind !== -1) {
      this.copiedData.splice(ind, 1);
    }
    this.deletedCodes = this.deletedCodes.concat(item.allCodes.map(codeModel => codeModel.code));
    this.showError = false;
    this.showWarn = false;
    this.showCurrentCode = false;
  }

  @action
  protected async pollScannedCodesFunc() {
    const items = await this.store.getItems(this.deliveryId);
    if (items) {
      items.forEach(item => {
        const { code } = item;
        const itemExist = this.copiedData.find(i => i.getCode(code));
        if (!this.deletedCodes.includes(code) && !itemExist) {
          const codeModel = new CodeModelExtended({ code, scanned: true });

          if (isSgtin(code)) {
            const gtin = getGtinFromSgtin(code);
            const gtinExist = this.copiedData.find(i => i.gtin === gtin);
            if (gtinExist) {
              gtinExist.tryToAddCode(codeModel);
              return;
            }
          }

          this.copiedData.unshift(new DeliveryItemModelExtended(item, [codeModel]));
        }
      });
    }
  }

  @computed
  get allScannedLen(): number {
    return this.copiedData.reduce((sum, item) => (sum += item.allCodes.length), 0);
  }

  @computed
  get warnErrorDescription(): string {
    const warn = this.warns.values().next().value;
    const err = this.errors.values().next().value;
    const errDescription = err ? err.description : "";
    return errDescription || warn;
  }

  @computed
  get warnErrorTitle(): string {
    const warn = this.warns.values().next().value;
    const err = this.errors.values().next().value;
    const errTitle = err ? err.title : "";
    return errTitle || warn;
  }

  @computed
  get disabledSaveBtn(): boolean {
    return !(this.copiedData && this.copiedData.length);
  }

  @computed
  get isDisposalWithRegistrator(): boolean {
    return this.delivery.type === DeliveryType.DisposalWithRegistrator;
  }

  createQRCode() {
    ScanHelper.createQRCode(QRCodeId, this.deliveryId);
  }

  private setItemsFromDelivery() {
    this.copiedData = this.delivery.items.map((item: DeliveryItemModelExtended) => {
      const codes = item.allCodes.map(code => new CodeModelExtended(code));
      return new DeliveryItemModelExtended(toJS(item), codes);
    });
  }

  setSubmitCallback = (callback: () => void) => {
    this.submitCallback = callback;
  };

  setScanCallback = (callback: (gtin: string) => void) => {
    this.scanCallback = callback;
  };

  @computed
  get isSetNewCode() {
    return this.delivery.type === DeliveryType.Disposal || this.delivery.type === DeliveryType.DisposalWithRegistrator;
  }
}
