import styled from "styles/styled-components";
import { blink } from "styles/animations";
import { CheckIcon } from "icons";
import { fontFamily } from "styles/global.styles";

export const minScannerHeaderHeight = "178px";
export const minScannerLoaderHeight = "200px";

export const scanHeaderWidth = 658;

export const ScannerHeader = styled.div`
  position: relative;
  margin-bottom: 15px;
  min-height: ${minScannerHeaderHeight};
`;

interface IScannerHeaderContentWrapProps {
  topOffset?: boolean;
}

export const ScannerHeaderContentWrap = styled.div<IScannerHeaderContentWrapProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${props => props.topOffset && "25px"};
`;

export const ScanModes = styled.div`
  display: flex;
  padding-left: 30px;
  padding-right: 10px;
`;

export const ScanModesTitle = styled.div`
  margin-right: 12px;
`;

export const ScannerHeaderWrap = styled.div`
  text-align: center;
  margin-bottom: 12px;
  margin-top: -11px;
  /* Это сделано намеренно, чтобы высота для всех сканирований была одинаковая */
  height: ${minScannerLoaderHeight};
`;

export const ScanErrorText = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.textColors.red};
`;

export const AllScannedText = styled.div`
  display: inline-block;
  position: relative;
`;

export const CheckIconStyled = styled(CheckIcon)`
  width: 41px;
  height: 41px;
  position: absolute;
  top: -15px;
  left: -50px;
  z-index: 5;
`;

export const ScanWarnText = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.orangeDark};
`;

const imgHeight = "121px";
const imgWidth = "256px";

export const ScannerImgWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 27px;
`;

export const ScannerImg = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/scanner.png);
  background-repeat: no-repeat;
  height: ${imgHeight};
  width: ${imgWidth};
`;

export const ScannerErrorImg = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/scanner-error.png);
  background-repeat: no-repeat;
  height: ${imgHeight};
  width: ${imgWidth};
`;

export const ScannerLangErrorImg = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/scanner-lang-error.png);
  background-repeat: no-repeat;
  height: ${imgHeight};
  width: ${imgWidth};
`;

export const ScannerSsccImg = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/scanner-sscc.png);
  background-repeat: no-repeat;
  height: 134px;
  width: ${imgWidth};
`;

export const ScannerSsccErrorImg = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/scanner-sscc-error.png);
  background-repeat: no-repeat;
  height: 134px;
  width: ${imgWidth};
`;

export const HideWrap = styled.span``;

interface IDiscardBlockWrapProps {
  isInfo?: boolean;
  isInnerCode?: boolean;
}

export const DiscardBlockWrap = styled.span<IDiscardBlockWrapProps>`
  display: none;
`;

export const DiscardTopBlockWrap = styled.span`
  margin-left: 25px;
`;

interface IScannerRow {
  isNew?: boolean;
  isRecalled?: boolean;
  isInfo?: boolean;
  sidePage?: boolean;
}

export const ScannerRow = styled.div<IScannerRow>`
  position: relative;
  animation: ${props => props.isNew && blink};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-iteration-count: 2;
  background-color: ${props => props.isNew && props.theme.colors.greenLight};
  cursor: ${props => (props.isInfo ? "pointer" : "inherit")};

  color: ${props => props.isRecalled && props.theme.textColors.lightGray};

  & span {
    color: ${props => props.isRecalled && props.theme.textColors.lightGray};
  }

  &:hover {
    background-color: ${props => (props.isInfo ? props.theme.colors.greySmoke : props.theme.colors.white)};

    ${DiscardBlockWrap} {
      display: inline-block;
      opacity: 1;
      background-color: ${props => (props.isInfo ? props.theme.colors.greySmoke : props.theme.colors.white)};
    }

    ${HideWrap} {
      display: none;
    }
  }
`;

export const DiscardBtnWrap = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`;

export const DiscardBtnContainer = styled.div`
  margin-left: 30px;
  margin-top: 8px;
`;

export const CodesWrap = styled.div`
  margin-bottom: 70px;
`;

export const ScanAndCheckTableWrap = styled.div`
  margin-bottom: 45px;
`;

export const ServerErrorWrap = styled.div`
  white-space: pre-line;
  text-align: left;
  line-height: 20px;
  color: ${props => props.theme.colors.darkGray};
`;

interface ModeBtnProps {
  active?: boolean;
}

export const ModeBtn = styled.button<ModeBtnProps>`
  height: 21px;
  padding: 2px 6px 3px;
  line-height: 6px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  position: relative;
  background: ${props => (props.active ? props.theme.colors.bluePrimary : "transparent")};
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  font-family: ${fontFamily};
  color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.darkBlue)};

  &:hover {
    background-color: ${props => (props.active ? props.theme.colors.bluePrimary : props.theme.colors.lightBackground)};
  }
`;

export const SsccCodeContentBlock = styled.div`
  color: ${props => props.theme.textColors.lightenGray};
`;

export const CollapseIconWrap = styled.span`
  position: absolute;
  top: 8px;
  left: -16px;
  margin-right: 3px;
  color: ${props => props.theme.colors.darkGray};
`;

export const AdvertisementWrap = styled.div`
  position: absolute;
  top: 100px;
  right: 140px;
`;

interface NameWrap {
  bold: boolean;
}

export const NameWrap = styled.span<NameWrap>`
  font-weight: ${props => (props.bold ? "600" : "inherit")};
`;
