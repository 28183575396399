const gtinRegex = /^[0-9]{14}$/;

export function isGtin(code: string): boolean {
  return gtinRegex.test(code);
}

const sgtinRegex = /^[0-9]{14}[!-"%-/a-zA-Z0-9]{13}$/;

export function isSgtin(code: string): boolean {
  return sgtinRegex.test(code);
}

const ssccRegex = /^(\d{18})$/;

export function isSscc(code: string): boolean {
  return ssccRegex.test(code);
}

// формирование датаматрикс в постановлении правительства 1556 (II пукт 5)
// https://xn--80ajghhoc2aj1c8b.xn--p1ai/upload/iblock/a40/PP-RF-ot-14.12.2018-_-1556-s-uchyetom-izmeneniy-1118_1.pdf

// 01, 21, 91, 92 - идентификаторы применения
// dataMatrix = (необязательный 232 ascii код, \xE8) + 01 + gtin(14 цифр) + 21 + serial number(13 цифр, букв латиницы и спецсимволы) + 91 ... + 92 + ...
const dataMatrixReg = /^è?01([0-9]{14})21([!-"%-/a-zA-Z0-9]{13})(.|Unidentified)?91.{4}(.|Unidentified)?92.{44}$/;

export function isDataMatrix(code: string): boolean {
  return dataMatrixReg.test(code);
}

export function getSgtinFromDataMatrix(str: string): string | undefined {
  const match = str.match(dataMatrixReg);

  if (match) {
    // sgtin = gtin(14 цифр) + serial number(13 цифр, букв латиницы и спецсимволы)
    return match[1] + match[2];
  }
}

export function getGtinFromDataMatrix(code: string) {
  const sgtin = getSgtinFromDataMatrix(code) as string;
  return getGtinFromSgtin(sgtin);
}

export function getGtinFromSgtin(str: string): string | undefined {
  if (isGtin(str)) return str;
  if (isSgtin(str)) return str.slice(0, 14);
}

// sscc barcode = ("FNC1" или "]C1" или "]e0" функциональный код) + 00 + sscc
// *примечание, допускаем отсутствие 00 https://yt.skbkontur.ru/issue/MARKDRUGS-152
const ssccBarCodeRegex = /^.{0,3}(00)?(\d{18})$/;

export function getSsccFromBarcode(str: string): string | undefined {
  const match = str.match(ssccBarCodeRegex);
  // пытаемся найти совпадения "00 + sscc" и вернуть sscc
  if (match) {
    return match[2];
  }
}

// 01 ? + gtin + 21 ? + serial number
const sgtinStrRegex = /^(?:\(?01\)?)?([0-9]{14})(?:\(?21\)?)?([!-"%-/a-zA-Z0-9]{13})$/;
// sscc barcode = 00 or (00) ? + sscc
const ssccStrBarCodeRegex = /^.{0,3}\(?00\)?(\d{18})$/;

export function getSgtinOrSsccFromString(str: string): string | undefined {
  if (isSscc(str) || isSgtin(str)) return str;

  // если сканируют сканером групповой код в поле ввода
  const sscc = getSsccFromBarcode(str);
  if (sscc) return sscc;

  // если сканируют датаматрикс сканером в поле ввода
  const sgtin = getSgtinFromDataMatrix(str);
  if (sgtin) return sgtin;

  const ssccMatch = str.match(ssccStrBarCodeRegex);

  if (ssccMatch) {
    return ssccMatch[1];
  }

  const sgtinMatch = str.match(sgtinStrRegex);
  if (sgtinMatch) {
    return sgtinMatch[1] + sgtinMatch[2];
  }

  return undefined;
}

export function normalizeDataMatrix(dataMatrix?: string) {
  if (!dataMatrix) {
    return undefined;
  }
  const sym = String.fromCodePoint(29);

  // Обрабатываем кейс, когда сканер просто не ставит ничего на месте символа 28
  if (dataMatrix.length === 83) {
    dataMatrix = dataMatrix.slice(0, 31) + sym + dataMatrix.slice(31);
    dataMatrix = dataMatrix.slice(0, 38) + sym + dataMatrix.slice(38);
    // Обрабатываем кейс, когда сканер ставит не тот символ вместо символа 28
  } else if (dataMatrix.length === 85 && dataMatrix[31] !== sym && dataMatrix[38] !== sym) {
    dataMatrix = dataMatrix.slice(0, 31) + sym + dataMatrix.slice(32);
    dataMatrix = dataMatrix.slice(0, 38) + sym + dataMatrix.slice(39);
    // Обрабатываем специфичный кейс, когда сканер ставит Unidentified вместо символа 28
  } else if (dataMatrix.includes("Unidentified")) {
    dataMatrix = dataMatrix.slice(0, 31) + sym + dataMatrix.slice(43);
    dataMatrix = dataMatrix.slice(0, 38) + sym + dataMatrix.slice(50);
  }

  return dataMatrix;
}
