import React from "react";
import { CertSuccessWrap, SuccessIconWrap, SuccessTextBlock } from "../../CertificatesList/CertificateList.styles";
import { DocSignedIcon } from "icons";
import { RouterLink } from "components";

interface SuccessSignBlockProps {
  route: string;
  textSubString: string;
}

export function SuccessSignBlock({ route, textSubString }: SuccessSignBlockProps) {
  return (
    <CertSuccessWrap>
      <SuccessIconWrap>
        <DocSignedIcon />
      </SuccessIconWrap>
      <div>
        <p>Документ подписан.</p>
        <SuccessTextBlock>
          Отправка в ИС МДЛП займет некоторое время.
          <br />
          <RouterLink to={route}>Вернуться к списку {textSubString}</RouterLink>
        </SuccessTextBlock>
      </div>
    </CertSuccessWrap>
  );
}
