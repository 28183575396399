import styled from "styles/styled-components";

export const OrgInn = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;

export const OrgNameWrap = styled.div`
  margin-top: 15px;
  margin-bottom: 12px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
`;

export const NoRights = styled.div`
  margin: 40px 40px;
  font-size: 16px;
  color: ${props => props.theme.colors.orangeDark};
`;

export const TableWrap = styled.div`
  margin-left: -4px;
  margin-right: -4px;
`;

export const BalanceWrap = styled.div`
  margin-bottom: 15px;
`;
