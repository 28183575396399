import React from "react";
import { observer } from "mobx-react";
import { TransferPageVM } from "../../../TransferPage/TransferPageVM";
import { CodesBlock, Container, ScanPanel, ScanPanelText } from "./PageInfoBlock.styles";
import { Button } from "components";
import BarcodeScannerIcon from "@skbkontur/react-icons/BarcodeScanner";
import { ScannedLargeText } from "Common/ScannedItemsCount/ScannedItemsCount.styles";
import { getEnding } from "helpers/endings";
import { CodesTable } from "Common/GoodsTable/CodesTable/CodesTable";

interface IPageInfoBlockProps {
  vm: TransferPageVM;
}

export const PageInfoBlock: React.FC<IPageInfoBlockProps> = observer((props: IPageInfoBlockProps) => {
  const { vm } = props;

  const onScan = () => {
    vm.toggleModal("scanPage");
    // blur(), because scanner triggers button click on "enter"
    if (window.document.activeElement && (window.document.activeElement as HTMLElement).blur) {
      (window.document.activeElement as HTMLElement).blur();
    }
  };

  const showTable = vm.delivery.items && !!vm.delivery.items.length;

  return (
    <Container>
      {vm.isEditable && (
        <>
          <ScanPanelText>Отсканируйте коды маркировки товаров для перемещения этих товаров получателю</ScanPanelText>
          <ScanPanel>
            <CodesBlock>
              <ScannedLargeText>{vm.delivery.allCodesLen} </ScannedLargeText>
              &nbsp;&nbsp;{getEnding(vm.delivery.allCodesLen, "код", "кода", "кодов")} маркировки
            </CodesBlock>
            <Button onClick={onScan} size={"large"} icon={<BarcodeScannerIcon />}>
              {vm.delivery.allCodesLen ? "Продолжить сканировать товары" : "Сканировать товары"}
            </Button>
          </ScanPanel>
        </>
      )}

      {showTable && <CodesTable items={vm.delivery.items} additionalInfo={vm.additionalInfo} />}
    </Container>
  );
});
