import { action, observable } from "mobx";
import { CreateRecallModel, CreateRefusalModel } from "typings/server";

type TErrors = "operationDateRequired" | "invalidOperationDate" | "reasonRequired";

export class RecallModalVM {
  @observable operationDate: string = new Date().toISOString();
  @observable reason: string = "";
  @observable isSubmitted: boolean = false;
  @observable confirmPaused: boolean = false;
  @observable errors: Map<TErrors, string> = new Map();
  private isDateModified: boolean = false;
  readonly maxDate: string = new Date().toISOString();

  constructor(readonly deliveryId: string, readonly onConfirmFunc: (model: CreateRecallModel) => Promise<void>) {}

  @action
  setOperationDate(date?: string) {
    this.isDateModified = true;
    this.operationDate = date || new Date().toISOString();
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (!this.operationDate) {
      this.errors.set("operationDateRequired", "Укажите дату отмены");
    }

    if (new Date(this.operationDate) > new Date()) {
      this.errors.set("invalidOperationDate", "Дата не должна быть больше текущей даты и времени");
    }

    if (!this.reason) {
      this.errors.set("reasonRequired", "Укажите причину отзыва");
    }
    return !this.errors.size;
  }

  @action
  setReason = (msg: string) => {
    this.reason = msg;
    if (this.isSubmitted) {
      this.validate();
    }
  };

  @action
  changeConfirmPaused() {
    this.confirmPaused = !this.confirmPaused;
  }

  onConfirm = async (): Promise<void> => {
    this.isSubmitted = true;
    if (this.validate()) {
      const model = new CreateRefusalModel();
      if (this.isDateModified) {
        model.operationDate = this.operationDate;
      }
      model.reason = this.reason.trim();
      model.deliveryId = this.deliveryId;
      model.confirmPaused = this.confirmPaused;

      await this.onConfirmFunc(model);
    } else return Promise.reject();
  };
}
