import React from "react";
import { observer } from "mobx-react";
import { getFormattedDate } from "helpers/date";
import { AcceptanceTypesNames } from "typings/dictionaries";
import {
  ClientInfoBlockAddress,
  ClientInfoBlockInn,
  ClientInfoBlockLabel,
  ClientInfoBlockTitle,
  ClientTopBlock,
  ClientTopBlockSupplier,
  DownloadBtnWrap,
  GoToDetailPageWrapper,
  RequisitesWrap,
  TitlePageEditWrap,
  TitlePageWrapper,
} from "Common/PageHeader/PageHeader.styles";
import { CounteragentInfoModel } from "typings/server";
import { Button, Spinner } from "components";
import EditIcon from "@skbkontur/react-icons/Edit";
import DownloadIcon from "@skbkontur/react-icons/Download";
import { DetailPageLoadingName } from "features/Common/DeliveryActions";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { RequisitesBlock } from "Common/RequisitesBlock/RequisitesBlock";

interface IHeaderPageVM {
  delivery: AcceptanceDeliveryModel;
  supplier: CounteragentInfoModel;
  recipient?: CounteragentInfoModel;
  supplierAddress: string;
  hasDocuments: boolean;
  loadingMap: Map<DetailPageLoadingName, boolean>;
  isAnyRequisites: boolean;
  isRequisitesOpen: boolean;
  toggleIsRequisitesOpen: () => void;
}

interface IPageHeaderProps {
  vm: IHeaderPageVM;
  showEditor?: boolean;
  goBackBlock: JSX.Element;
  shortTitle?: boolean;
  onEdit?(): void;
  onDownload(): void;
}

export const PageHeader: React.FC<IPageHeaderProps> = observer((props: IPageHeaderProps) => {
  const { vm, onEdit, showEditor, goBackBlock, shortTitle, onDownload } = props;
  const isDownloading = vm.loadingMap.get("download");
  return (
    <div data-tid="PageHeader">
      <GoToDetailPageWrapper>{goBackBlock}</GoToDetailPageWrapper>

      <TitlePageWrapper>
        <h2 data-tid="Header">
          {shortTitle ? "Приемка" : AcceptanceTypesNames[String(vm.delivery.acceptusType)]}
          &nbsp;№&nbsp;
          {vm.delivery.documentNumber}&nbsp;от&nbsp;
          {vm.delivery.documentDate && getFormattedDate(vm.delivery.documentDate)}
        </h2>
        <TitlePageEditWrap>
          {vm.hasDocuments && (
            <DownloadBtnWrap>
              <Button
                data-tid="DownloadDocuments"
                use="link"
                icon={isDownloading ? <Spinner type="mini" caption="" /> : <DownloadIcon />}
                onClick={onDownload}
                size="small"
                disabled={isDownloading}
              >
                Скачать документы
              </Button>
            </DownloadBtnWrap>
          )}
          {showEditor && (
            <Button data-tid="Edit" use={"link"} onClick={onEdit} size={"small"} icon={<EditIcon />}>
              Редактировать
            </Button>
          )}
        </TitlePageEditWrap>
      </TitlePageWrapper>

      <ClientTopBlock>
        <ClientTopBlockSupplier>
          <ClientInfoBlockLabel>Отправитель</ClientInfoBlockLabel>
          <ClientInfoBlockTitle data-tid="SupplierName">{vm.supplier.name}</ClientInfoBlockTitle>
          <ClientInfoBlockInn data-tid="SupplierInn">ИНН {vm.supplier.inn}</ClientInfoBlockInn>
          <ClientInfoBlockAddress data-tid="SupplierAddress">{vm.supplierAddress}</ClientInfoBlockAddress>
        </ClientTopBlockSupplier>
        <ClientTopBlockSupplier>
          <ClientInfoBlockLabel>Получатель</ClientInfoBlockLabel>
          <ClientInfoBlockTitle data-tid="RecipientName">{vm.recipient?.name}</ClientInfoBlockTitle>
          <ClientInfoBlockInn data-tid="RecipientInn">ИНН {vm.recipient?.inn}</ClientInfoBlockInn>
          <ClientInfoBlockAddress data-tid="RecipientAddress">{vm.recipient?.address}</ClientInfoBlockAddress>
        </ClientTopBlockSupplier>
      </ClientTopBlock>

      {vm.isAnyRequisites && (
        <RequisitesWrap>
          <RequisitesBlock
            isOpen={vm.isRequisitesOpen}
            toggle={vm.toggleIsRequisitesOpen}
            contractNumber={vm.delivery.contractNumber}
            contractType={vm.delivery.contractType}
            financingType={vm.delivery.financingType}
            turnoverType={vm.delivery.turnoverType}
          />
        </RequisitesWrap>
      )}
    </div>
  );
});
