import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import { RegistratorEditModel, RegistratorEmbeddedModel, RegistratorSettingsModel } from "typings/server";
import { DevicesInfo, RegistratorDisposeStatusModel, RegistratorQueueBody } from "typings/registrator";

class RegistratorsApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getAvailableRegistrators(): Promise<RegistratorEmbeddedModel[]> {
    return this.httpClient.get("");
  }

  getAllRegistrators(): Promise<RegistratorSettingsModel[]> {
    return this.httpClient.get("all");
  }

  addNewRegistrator(model: RegistratorEditModel) {
    return this.httpClient.post("", model);
  }

  editRegistrator(id: string, model: RegistratorEditModel) {
    return this.httpClient.put(id, model);
  }

  deleteRegistrator(id: string) {
    return this.httpClient.delete(id);
  }

  async getDeviceInfo(model: RegistratorEditModel): Promise<DevicesInfo> {
    const address = `https://${model.ipAddress}/v1/deviceInfo`;
    const proxyAddress = this.getAddressWithLocalProxy(address);
    const headers = this.buildRVAuthHeaders(model.login, model.password);
    const options: RequestInit = { headers, mode: "cors" };
    const res = await fetch(proxyAddress, options);
    const json = await res.json();
    if (json.statusCode === 401) {
      throw json.statusCode;
    }
    if (json.error || json.statusCode === 502 || json.body?.error) {
      throw new Error("Ошибка соединения");
    }
    return json.body;
  }

  async queueUpDisposal(model: RegistratorEmbeddedModel, body: RegistratorQueueBody): Promise<string> {
    const address = `https://${model.ipAddress}/v1/requests`;
    const proxyAddress = this.getAddressWithLocalProxy(address);
    const headers = this.buildRVAuthHeaders(model.login, model.password);

    const options: RequestInit = { headers, mode: "cors", method: "POST", body: JSON.stringify(body) };

    const res = await fetch(proxyAddress, options);
    const json = await res.json();
    if (json.statusCode === 400) {
      throw new Error("Ошибка формата запроса");
    }
    if (json.statusCode === 409) {
      throw new Error("Задание с заданным id уже существует");
    }
    if (json.error || json.statusCode === 502 || json.body?.error) {
      throw new Error("Ошибка соединения");
    }
    return body.rvRequestId;
  }

  async getRequestStatus(model: RegistratorEmbeddedModel, rvRequestId: string): Promise<RegistratorDisposeStatusModel> {
    const address = `https://${model.ipAddress}/v1/requests/${rvRequestId}`;
    const proxyAddress = this.getAddressWithLocalProxy(address);
    const headers = this.buildRVAuthHeaders(model.login, model.password);
    const options: RequestInit = { headers, mode: "cors" };
    const res = await fetch(proxyAddress, options);
    const json = await res.json();
    if (json.error || json.statusCode === 502 || json.body?.error) {
      throw new Error("Ошибка соединения");
    }
    return json.body;
  }

  private buildRVAuthHeaders(login: string, password: string) {
    const base64Credentials = btoa(`${login}:${password}`);
    const headers = new Headers();
    headers.append("Authorization", `Basic ${base64Credentials}`);
    return headers;
  }

  private getAddressWithLocalProxy(url: string) {
    // работа с РВ идёт через прокси-сервер, который хостится у пользователя на локалхосте
    return `http://127.0.0.1:27311/transparent/${url}`;
  }
}

export const RegistratorsApi = new RegistratorsApiInternal(getHttpClient(drugsApi + "/registrators"));
