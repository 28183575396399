import React from "react";
import { ReactComponent as AcceptedIcon } from "./check-status-icon.svg";

interface IAcceptedStatusIconProps extends React.ComponentProps<any> {
  size?: "default" | "medium" | "large";
}
const largeProps = { width: "19px", height: "16px", strokeWidth: "3" };

export const AcceptedStatusIcon: React.FC<IAcceptedStatusIconProps> = (props: IAcceptedStatusIconProps) => {
  const { size } = props;
  let iconProps = { ...props, ...(size === "large" && largeProps) };

  // @ts-ignore
  return <AcceptedIcon {...iconProps} />;
};
