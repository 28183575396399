import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import {
  NameText,
  GridItemWrap,
  ItemIconWrap,
  ItemName,
  ItemNameWrap,
  RejectedStatusIcon,
  ScannedIcon,
  StatusReason,
  TableItemHr,
  AdditionalInfoWrap,
} from "./GoodsTable.styles";
import { DeliveryItemStatus } from "typings/server";
import { AcceptedStatusIcon, RecalledStatusIcon } from "icons";
import { Error } from "@skbkontur/react-icons";
import { Capitalized, NoWrap } from "styles/shared.styles";
import { ScannedItemsCount } from "../../ScannedItemsCount/ScannedItemsCount";
import { CurrencyLabel } from "components";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { AdditionalInfoSgtinTable } from "../../AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "../../AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { ArrowChevronRight, ArrowChevronDown } from "@skbkontur/react-icons";
import { CollapseIcon } from "../CodeItem/CodeItem.styles";
import { AdditionalInfoVM } from "../../AdditionalInfo/AdditionalInfoVM";

interface IGoodsTableItemProps {
  vm: AdditionalInfoVM;
  good: DeliveryItemModelExtended;
  cols: string;
  showOnlyAccepted?: boolean;
  hidePrices?: boolean;
  isOpened?: boolean;
  allCodesExpanded: boolean;
}

export const GoodsTableItem = observer((props: IGoodsTableItemProps) => {
  const { good, cols, hidePrices, showOnlyAccepted, vm, allCodesExpanded } = props;
  const [isExpanded, setIsExpanded] = useState(allCodesExpanded);

  const toggleOpenedState = () => {
    if (!hasAdditionalInfo) {
      return;
    }
    setIsExpanded(v => !v);
  };

  useEffect(() => {
    setIsExpanded(allCodesExpanded);
  }, [allCodesExpanded]);

  const hasAdditionalInfo = vm.checkIfGoodHasAdditionalInfo(good);

  return (
    <div data-tid="GoodsTableItem">
      <GridItemWrap collapsible={hasAdditionalInfo}>
        <ItemIconWrap top="14px" left="-13px">
          {good.status === DeliveryItemStatus.Accepted ||
            (good.status === DeliveryItemStatus.WaitingForCounterparty && (
              <ScannedIcon top={"0"}>
                <AcceptedStatusIcon />
              </ScannedIcon>
            ))}
          {good.status === DeliveryItemStatus.Error && (
            <ScannedIcon color={"red"} top={"-1px"} left={"-3px"}>
              <Error />
            </ScannedIcon>
          )}
          {good.status === DeliveryItemStatus.Recalled && (
            <ScannedIcon color={"grey"} top={"1px"} left={"-1px"}>
              <RecalledStatusIcon />
            </ScannedIcon>
          )}
          {good.status === DeliveryItemStatus.Rejected && (
            <ScannedIcon color={"orange"} top={"1px"} left={"-1px"}>
              <RejectedStatusIcon />
            </ScannedIcon>
          )}
        </ItemIconWrap>
        <Grid
          rows={"repeat(18px 15px)"}
          paddingCells={"14px 5px"}
          cols={cols}
          cellsBorderBottom={hasAdditionalInfo ? !isExpanded : true}
          onClick={toggleOpenedState}
        >
          <GridCell col={1} row={1}>
            <ItemNameWrap>
              <ItemName>
                {hasAdditionalInfo && (
                  <CollapseIcon>{isExpanded ? <ArrowChevronDown /> : <ArrowChevronRight />}</CollapseIcon>
                )}
                <NameText
                  isAccepted={good.status === DeliveryItemStatus.Accepted}
                  isAnyAdditionalInfo={!!vm.additionalInfo.codesInfo.size && !hasAdditionalInfo}
                >
                  <Capitalized data-tid="Name">{good.name}</Capitalized>
                </NameText>
              </ItemName>
              <StatusReason offsetLeft={hasAdditionalInfo}>{good.statusReason}</StatusReason>
            </ItemNameWrap>
          </GridCell>
          <GridCell col={2} row={1} textAlign="left" padding={"14px 10px"}>
            <ScannedItemsCount
              allLen={good.ssccCodes.length}
              scannedLen={showOnlyAccepted && good.status === DeliveryItemStatus.Accepted ? good.ssccScanLen : 0}
              status={good.status}
              codeText
              dataTid="ScannedSsccCount"
            />
          </GridCell>
          <GridCell col={3} row={1} textAlign="left" padding={"14px 10px"}>
            <ScannedItemsCount
              allLen={good.sgtinCodes.length}
              scannedLen={showOnlyAccepted && good.status === DeliveryItemStatus.Accepted ? good.sgtinScanLen : 0}
              status={good.status}
              codeText
              dataTid="ScannedSgtinCount"
            />
          </GridCell>
          <GridCell data-tid="AllItemsCount" col={4} row={1}>
            {good.allItemsCount || ""}
          </GridCell>
          {!hidePrices && (
            <>
              <GridCell col={5} row={1} textAlign="right">
                <NoWrap>
                  <CurrencyLabel data-tid="Cost" value={good.cost} />
                </NoWrap>
              </GridCell>
              <GridCell col={6} row={1} textAlign="right">
                <NoWrap>
                  <CurrencyLabel data-tid="Vat" value={good.vat} />
                </NoWrap>
              </GridCell>
            </>
          )}
        </Grid>
      </GridItemWrap>
      {isExpanded && hasAdditionalInfo && (
        <AdditionalInfoWrap>
          <AdditionalInfoSgtinTable vm={vm} codes={good.sgtinCodes} />
          <AdditionalInfoSsccTable vm={vm} codes={good.ssccCodesFlat} />
          <TableItemHr />
        </AdditionalInfoWrap>
      )}
    </div>
  );
});
