import React, { memo, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Modal, Radio, RadioGroup, Input } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { DestructionEditorModalVM } from "./DestructionEditorModalVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { DocumentHeader, RadioRoot, ModalBodyInner, Label, EditorLabel } from "../EditorModal.styles";
import { UserStore } from "stores/UserStore";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { EditorFields } from "../EditorFields";
import { CirculationSubRoute } from "routes/CirculationRoute";
import { FiasSearch, FiasAPI, FiasAddress } from "@skbkontur/react-ui-addons";
import { FIASUrl } from "StringResx";
import { DocumentNumber } from "features/Circulation/Common/DocumentNumber";
import { DocumentDate } from "../../Common/DocumentDate";
import { DestructionModel } from "typings/server";

interface IOperationModalProps {
  delivery?: WithdrawalDeliveryModel;
  destruction?: DestructionModel | undefined;
  onClose(): void;
}

const api = new FiasAPI(FIASUrl);

export const DestructionEditorModal = memo(
  (props: IOperationModalProps) => {
    const { delivery } = props;
    const vm = new DestructionEditorModalVM(WithdrawalStore, UserStore, delivery);
    return <DestructionModalInternal {...props} vm={vm} />;
  },
  // memo + () => true - не перерендериваем компонент
  () => true
);

interface Props extends IOperationModalProps, RouteComponentProps<CirculationRoute> {
  vm: DestructionEditorModalVM;
}

const DestructionModalInternal = withRouter(
  observer((props: Props) => {
    const { vm, history, destruction, onClose } = props;

    const [addressFias, setAddressFias] = useState<FiasAddress>(new FiasAddress());

    const [inn, setInn] = useState(destruction?.recipient?.inn || "");
    const [kpp, setKpp] = useState(destruction?.recipient?.kpp || "");

    const [contractDocumentNumber, setContractDocumentNumber] = useState(destruction?.documentNumber || "");
    const [actDocumentNumber, setActDocumentNumber] = useState(destruction?.actNumber || "");

    const [contractDocumentDate, setContractDocumentDate] = useState<string | undefined>(
      destruction?.documentDate || ""
    );
    const [actDocumentDate, setActDocumentDate] = useState<string | undefined>(destruction?.actDate || "");

    const orgDefaultValue = destruction?.recipient?.inn.length === 12 ? "ip" : "ul";
    const [destructionOrg, setDestructionOrg] = useState<"ul" | "ip">(orgDefaultValue);

    const changeAddressFias = (address: FiasAddress) => {
      vm.addressFias = address;
      setAddressFias(address);
    };

    const onSave = async () => {
      const newDestructionValue = {
        id: destruction?.id,
        inn,
        kpp,
        address: addressFias.getFullText(),
        addressFias: addressFias.getFiasId(),
        contractDocumentNumber,
        actDocumentNumber,
        contractDocumentDate,
        actDocumentDate,
        destructionOrg,
        destructionMethod: destruction?.destructionMethod,
        destructionCause: destruction?.destructionCause,
        destructionReason: destruction?.destructionReason,
        rznDecision: destruction?.rznDecision,
      };

      try {
        const id = await vm.save(newDestructionValue);
        if (id) {
          history.push(CirculationRoute.getHref(id, CirculationSubRoute.destruction));
        } else {
          onClose();
        }
      } catch (e) {
        // empty promise for validation fail
        if (e) {
          throw e;
        }
      }
    };

    const changeContractDocumentNumber = (v: string) => {
      vm.getContractDocumentNumberError = undefined;
      setContractDocumentNumber(v);
    };

    const changeContractDocumentDate = (v?: string) => {
      vm.getContractDocumentDateError = undefined;
      setContractDocumentDate(v);
    };

    const changeActDocumentNumber = (v: string) => {
      vm.getActDocumentNumberError = undefined;
      setActDocumentNumber(v);
    };

    const changeActDocumentDate = (v?: string) => {
      vm.getActDocumentDateError = undefined;
      setActDocumentDate(v);
    };

    useEffect(() => {
      FiasAddress.getAddress(api, {
        fiasId: destruction?.recipient?.addressFias,
      }).then(address => {
        setAddressFias(address);
      });
    }, [destruction?.recipient?.addressFias]);

    return (
      <Modal data-tid="DestructionEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>Передача на уничтожение</Modal.Header>
        <Modal.Body>
          <ModalBodyInner>
            <Grid cols="135px 1fr">
              <EditorFields.Sender vm={vm} required row={1} />
              <EditorFields.ReportDate vm={vm} required row={2} title="Дата передачи на уничтожение" />
              <GridCell colStart={1} colEnd={4} row={3} padding="0px 4px">
                <Label>Организация, выполняющая уничтожение</Label>
              </GridCell>
              <GridCell colStart={1} colEnd={4} row={4} padding="15px 2px">
                <RadioGroup
                  onValueChange={(v: any) => {
                    setDestructionOrg(v as "ul" | "ip");
                  }}
                  name="DestructionOrg"
                  value={destructionOrg}
                  defaultValue="ul"
                  style={{ display: "flex" }}
                >
                  <RadioRoot>
                    <Radio value="ul">Юридическое лицо</Radio>
                  </RadioRoot>
                  <RadioRoot>
                    <Radio value="ip">Индивидуальный предприниматель</Radio>
                  </RadioRoot>
                </RadioGroup>
              </GridCell>
              <GridCell col={1} row={5} padding="15px 4px 2px 4px">
                <EditorLabel required>ИНН</EditorLabel>
              </GridCell>
              <GridCell col={2} row={5}>
                <Input
                  data-tid="INN"
                  width="170px"
                  placeholder="Введите ИНН"
                  value={inn}
                  onValueChange={setInn}
                  error={!!vm.getInnError}
                />
              </GridCell>
              {destructionOrg === "ul" && (
                <>
                  <GridCell col={1} row={6} padding="15px 4px 2px 4px">
                    <EditorLabel required>КПП</EditorLabel>
                  </GridCell>
                  <GridCell col={2} row={6}>
                    <Input
                      data-tid="KPP"
                      width="170px"
                      placeholder="Введите КПП"
                      value={kpp}
                      onValueChange={setKpp}
                      error={!!vm.getKppError && !kpp}
                    />
                  </GridCell>
                </>
              )}

              <GridCell col={1} row={7} padding="15px 4px 27px 4px">
                <EditorLabel required>Адрес склада</EditorLabel>
              </GridCell>
              <GridCell col={2} row={7}>
                <FiasSearch
                  api={api}
                  address={addressFias}
                  onValueChange={changeAddressFias}
                  error={vm.getAddressFiasError && !addressFias.getFullText()}
                  placeholder="Начните вводить адрес, например: Москва, Внуково"
                />
              </GridCell>
              <GridCell colStart={1} colEnd={3} row={8}>
                <DocumentHeader>Договор передачи на уничтожение</DocumentHeader>
              </GridCell>
              <DocumentNumber
                value={contractDocumentNumber}
                setValue={changeContractDocumentNumber}
                row={9}
                getError={() => vm.getContractDocumentNumberError}
              />
              <DocumentDate
                row={10}
                documentDate={contractDocumentDate || ""}
                setDocumentDate={changeContractDocumentDate}
                getError={() => vm.getContractDocumentDateError}
              />

              <GridCell colStart={1} colEnd={3} row={11} padding="18px 8px 8px 4px">
                <DocumentHeader>Акт передачи на уничтожение</DocumentHeader>
              </GridCell>
              <DocumentNumber
                value={actDocumentNumber}
                setValue={changeActDocumentNumber}
                row={12}
                getError={() => vm.getActDocumentNumberError}
              />
              <DocumentDate
                row={13}
                documentDate={actDocumentDate || ""}
                setDocumentDate={changeActDocumentDate}
                getError={() => vm.getActDocumentDateError}
              />
            </Grid>
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer panel>
          <FooterBtnWrapper>
            <Button data-testid="Submit" use="primary" size={"large"} onClick={onSave}>
              {vm.isNew ? "Добавить передачу на уничтожение" : "Сохранить"}
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  })
);
