import React from "react";
import { WarningText } from "components/WarningText/WarningText";
import { Wrapper } from "./CirculationWarning.styles";

interface CirculationWarningProps {
  isError: boolean;
}

export const CirculationWarning = ({ isError }: CirculationWarningProps) => {
  const text = (
    <>
      {isError
        ? "Ошибки получены для товаров с датой ввода в оборот после 1 февраля 2021 г."
        : "В переданных сведениях товары с датой ввода в оборот после 1 февраля 2021 г."}
      <br />
      {isError
        ? " Для таких товаров дистрибьютеру необходимо передать сведения в ИС МДЛП без ошибок."
        : "Для таких товаров дистрибьютеру необходимо дождаться успешного завершения операции."}
    </>
  );

  return (
    <Wrapper>
      <WarningText text={text} level="warning" />
    </Wrapper>
  );
};
