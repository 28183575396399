import { DeliveryStatus } from "typings/server";

export enum DeliveryStage {
  Shipped,
  Processing,
  Signing,
  Sending,
  WaitingForCounterparty,
  Done,
}

export const AcceptanceStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Поставщик отгрузил товары",
  [DeliveryStage.Processing]: "Приемка товаров",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Согласование поставщиком",
  [DeliveryStage.Done]: "Завершено",
};

export const ShipmentStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Покупатель отправил сведения в ИС МДЛП",
  [DeliveryStage.Processing]: "Отгрузка товаров",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Согласование получателем",
  [DeliveryStage.Done]: "Завершено",
};

export const ShipmentRecallStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Отзыв отгрузки",
  [DeliveryStage.Processing]: "Отзыв отгрузки",
  [DeliveryStage.Signing]: "Отзыв отгрузки",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "",
  [DeliveryStage.Done]: "Отгрузка отозвана",
};

export const UnpackingStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Черновик",
  [DeliveryStage.Processing]: "Расформирование агрегатов",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Согласование получателем",
  [DeliveryStage.Done]: "Завершено",
};

export const ExtractionStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Черновик",
  [DeliveryStage.Processing]: "Изъятие из агрегата",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Согласование получателем",
  [DeliveryStage.Done]: "Завершено",
};

export const TransferStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Черновик",
  [DeliveryStage.Processing]: "Перемещение",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Согласование получателем",
  [DeliveryStage.Done]: "Завершено",
};

export const TransferRecallStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Отмена перемещения",
  [DeliveryStage.Processing]: "Отмена перемещения",
  [DeliveryStage.Signing]: "Отмена перемещения",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "",
  [DeliveryStage.Done]: "Перемещение отменено",
};

export const WithdrawalStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Черновик",
  [DeliveryStage.Processing]: "Вывод из оборота",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Согласование получателем",
  [DeliveryStage.Done]: "Завершено",
};

export const ReentryStageNames: Record<DeliveryStage | string, string> = {
  ...WithdrawalStageNames,
  [DeliveryStage.Processing]: "Повторный ввод в оборот",
};

export const DestructionStageNames: Record<DeliveryStage | string, string> = {
  [DeliveryStage.Shipped]: "Черновик",
  [DeliveryStage.Processing]: "Передача на уничтожение",
  [DeliveryStage.Signing]: "Подписание",
  [DeliveryStage.Sending]: "Отправлено в ИС МДЛП",
  [DeliveryStage.WaitingForCounterparty]: "Уничтожение",
  [DeliveryStatus.Recalled]: "Передача на уничтожение отменена",
  [DeliveryStage.Done]: "Завершено",
};

export const mapDeliveryToAcceptance: Record<DeliveryStatus | string, DeliveryStage> = {
  [DeliveryStatus.Draft]: DeliveryStage.Shipped,
  [DeliveryStatus.New]: DeliveryStage.Shipped,
  [DeliveryStatus.Processing]: DeliveryStage.Processing,
  [DeliveryStatus.CreatingDocument]: DeliveryStage.Signing,
  [DeliveryStatus.Signing]: DeliveryStage.Signing,
  [DeliveryStatus.Signed]: DeliveryStage.Signing,
  [DeliveryStatus.Sending]: DeliveryStage.Sending,
  [DeliveryStatus.MdlpProcessing]: DeliveryStage.Sending,
  [DeliveryStatus.WaitingForCounterparty]: DeliveryStage.WaitingForCounterparty,
  [DeliveryStatus.PartiallyFailedIncompleted]: DeliveryStage.WaitingForCounterparty,
  [DeliveryStatus.PartiallyAccepted]: DeliveryStage.Sending,
  [DeliveryStatus.PartiallyFailed]: DeliveryStage.Sending,
  [DeliveryStatus.Success]: DeliveryStage.Done,
  [DeliveryStatus.Failed]: DeliveryStage.Done,
  [DeliveryStatus.RecallFailed]: DeliveryStage.Done,
  [DeliveryStatus.PartiallyRecalled]: DeliveryStage.Done,
  [DeliveryStatus.Recalled]: DeliveryStage.Done,
  [DeliveryStatus.Rejected]: DeliveryStage.Done,
  [DeliveryStatus.PartiallyRejected]: DeliveryStage.Done,
};
