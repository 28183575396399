import { RouteName, GreetingStep } from "./RouteNames";

export class GreetingRoute {
  public static readonly title = "Начало работы с маркировкой";
  public static readonly baseRoute = `/${RouteName.Greeting}`;
  public static readonly route = `${GreetingRoute.baseRoute}/:step?`;

  public static getHref(step?: GreetingStep): string {
    return step ? `${this.baseRoute}/${step}` : this.baseRoute;
  }
}
