import React, { FC, useMemo } from "react";
import { isTestEnv } from "../../../enviroment";
import { Tooltip } from "components";
import { ThemeContext as ReactUiThemeContext, FLAT_THEME } from "@skbkontur/react-ui";
import { colors } from "styles/colors";
import { theme } from "styles/theme";

interface AdvertisementBlockProps {
  text: string;
  width?: string;
  isDisplayed: boolean;
  noPin?: boolean;
  onClose(): void;
}

export const AdvertisementBlock: FC<AdvertisementBlockProps> = props => {
  const { text, width, isDisplayed, noPin, onClose } = props;

  const defaultTheme = useMemo(() => {
    return {
      ...FLAT_THEME,
      tooltipBorderRadius: "4px",
      tooltipCloseBtnPadding: "8px",
      tooltipCloseBtnColor: theme.colors.white,
      tooltipCloseBtnHoverColor: theme.colors.white,
      tooltipPinSize: noPin ? "0" : FLAT_THEME.tooltipPinSize,
      popupDropShadow: "none",
      popupBackground: theme.colors.bluePrimary,
      popupBorderColor: theme.colors.bluePrimary,
      tooltipMargin: "11px",
    };
  }, [noPin]);

  if (isTestEnv()) {
    return null;
  }

  const renderPromo = () => {
    if (!isDisplayed) {
      return null;
    }

    return (
      <div
        style={{
          width: width || "221px",
          textAlign: "start",
          background: colors.bluePrimary,
          color: colors.white,
          lineHeight: "20px",
        }}
      >
        {text}
      </div>
    );
  };

  return (
    <ReactUiThemeContext.Provider value={defaultTheme}>
      <Tooltip trigger="opened" pos="right middle" render={renderPromo} onCloseClick={onClose}>
        {props.children}
      </Tooltip>
    </ReactUiThemeContext.Provider>
  );
};
