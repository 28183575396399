import styled from "styles/styled-components";

interface HeaderWrapProps {
  isActive: boolean;
  tableHeaderPaddings?: "small" | "middle";
}

export const HeaderWrap = styled.div<HeaderWrapProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 27px;
  padding: ${props => (props.tableHeaderPaddings === "small" ? "0 8px 0 0" : "0 8px 0 10px")};
  margin-top: -3px;
  background: ${props => props.isActive && props.theme.colors.greyLight};
  border-radius: 2px 2px 0px 0px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.greyLight};
  }
`;

export const IconWrap = styled.span`
  margin: 1px 0 0 5px;
`;
