import React, { useMemo } from "react";
import { observer } from "mobx-react";
import {
  ClientInfoBlockAddress,
  ClientInfoBlockInn,
  ClientInfoBlockLabel,
  ClientInfoBlockTitle,
  ClientTopBlock,
  ClientTopBlockSupplier,
  DownloadBtnWrap,
  GoToDetailPageWrapper,
  TitlePageEditWrap,
  TitlePageWrapper,
} from "Common/PageHeader/PageHeader.styles";
import { getFormattedDate } from "helpers/date";
import EditIcon from "@skbkontur/react-icons/Edit";
import { Button, Spinner } from "components";
import DownloadIcon from "@skbkontur/react-icons/Download";
import { DeliveryType } from "typings/server";
import { CirculationPageVM } from "../../CirculationPageVM";

interface IPageHeaderProps {
  vm: CirculationPageVM;
  goBackBlock: JSX.Element;
  type: DeliveryType.Withdrawal | DeliveryType.Reentry | DeliveryType.Destruction;
  onEdit(): void;
}

export const PageHeader: React.FC<IPageHeaderProps> = observer((props: IPageHeaderProps) => {
  const { vm, goBackBlock, type, onEdit } = props;
  const isDownloading = vm.loadingMap.get("download");

  const isReentry = type === DeliveryType.Reentry;
  const isDestruction = type === DeliveryType.Destruction;

  const title = useMemo(() => {
    if (isDestruction) {
      return `Передача на уничтожение${"\u00A0"}№${"\u00A0"}`;
    }
    if (isReentry) {
      return `Повторный ввод в оборот${"\u00A0"}№${"\u00A0"}`;
    }
    return `Вывод из оборота${"\u00A0"}№${"\u00A0"}`;
  }, [isDestruction, isReentry]);

  return (
    <div data-tid="PageHeader">
      <GoToDetailPageWrapper>{goBackBlock}</GoToDetailPageWrapper>

      <TitlePageWrapper>
        <h2 data-tid="Header">
          {title}
          {vm.delivery.documentNumber}&nbsp;от {vm.delivery.reportDate && getFormattedDate(vm.delivery.reportDate)}
        </h2>
        <TitlePageEditWrap>
          {vm.hasDocuments && (
            <DownloadBtnWrap>
              <Button
                data-tid="DownloadDocuments"
                use="link"
                icon={isDownloading ? <Spinner type="mini" caption="" /> : <DownloadIcon />}
                onClick={vm.prepareDocuments}
                size="small"
                disabled={isDownloading}
              >
                Скачать документы
              </Button>
            </DownloadBtnWrap>
          )}
          {vm.showEditor && (
            <Button data-tid="Edit" use="link" onClick={onEdit} size="small" icon={<EditIcon />}>
              Редактировать
            </Button>
          )}
        </TitlePageEditWrap>
      </TitlePageWrapper>

      <ClientTopBlock>
        <ClientTopBlockSupplier>
          <ClientInfoBlockLabel>Место операции</ClientInfoBlockLabel>
          <ClientInfoBlockTitle data-tid="SupplierName">{vm.supplier?.name}</ClientInfoBlockTitle>
          <ClientInfoBlockInn data-tid="SupplierInn">ИНН {vm.supplier?.inn}</ClientInfoBlockInn>
          <ClientInfoBlockAddress data-tid="SupplierAddress">{vm.supplier?.address}</ClientInfoBlockAddress>
        </ClientTopBlockSupplier>

        {vm.destructorName && vm.destructorAddress && vm.destructorInnKpp && (
          <ClientTopBlockSupplier>
            <ClientInfoBlockLabel>Место уничтожения</ClientInfoBlockLabel>
            <ClientInfoBlockTitle data-tid="DestructorName">{vm.destructorName}</ClientInfoBlockTitle>
            <ClientInfoBlockInn data-tid="DestructorInnKpp">{vm.destructorInnKpp}</ClientInfoBlockInn>
            <ClientInfoBlockAddress data-tid="DestructorAddress">{vm.destructorAddress}</ClientInfoBlockAddress>
          </ClientTopBlockSupplier>
        )}
      </ClientTopBlock>
    </div>
  );
});
