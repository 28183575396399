import React, { useContext } from "react";
import { observer } from "mobx-react";
import {
  AdvertisementWrap,
  AllScannedText,
  CheckIconStyled,
  ModeBtn,
  ScanErrorText,
  ScanModes,
  ScanModesTitle,
  ScannerErrorImg,
  ScannerLangErrorImg,
  ScannerHeader,
  ScannerHeaderContentWrap,
  ScannerHeaderWrap,
  ScannerImg,
  ScannerImgWrap,
  ScanWarnText,
} from "./ScannerSidePage.styles";
import { Loader } from "components/Loader";
import { Gapped } from "components";
import { ScanMode } from "./ScanHelper";
import { QrCodeBlock } from "./QrCode/QrCodeBlock";
import { ServerErrors } from "./Errors/ServerErrors";
import { AdditionalInfo } from "./AdditionalInfo/AdditionalInfo";
import { ScannerAdditionalInfoMaxWidth } from "./AdditionalInfo/AdditionalInfo.styles";
import { ScannerInput } from "./ScannerInput/ScannerInput";
import { IScannerSidePageVM } from "./shared";
import { TextGreen } from "styles/shared.styles";
import { AcceptedStatusIcon } from "icons";
import { AdvertisementBlock } from "Common/AdvertisementBlock/AdvertisementBlock";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { DeliveryType } from "typings/server";

interface IScanHeaderProps {
  vm: IScannerSidePageVM;
  scanText: string;
  placeholderText?: string;
  hideSgtinImg?: boolean;
  onlyScanner?: boolean;
  customScannerImg?: JSX.Element;
  customScannerErrorImg?: JSX.Element;
}

export const ScanHeader = observer((props: IScanHeaderProps) => {
  const { vm, scanText, customScannerErrorImg, customScannerImg, placeholderText, hideSgtinImg, onlyScanner } = props;

  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;

  const changeMode = (mode: ScanMode) => {
    vm.changeMode(mode);
    blur();
  };

  const blur = () => {
    const activeElement = window.document.activeElement as HTMLElement;
    // убираем фокус с кнопки
    activeElement?.blur?.();
  };

  const tryToAuth = () => vm.toggleModal?.("mdlpModal");

  const onCloseAggregationAdvertisement = () => vm.setAdvertisementShown("massDisaggregation");
  const onCloseFractionDisposalAdvertisement = () => vm.setAdvertisementShown("fractionDisposal");

  const scannerImg = () => {
    if (vm.errors.get("lang")) {
      return <ScannerLangErrorImg />;
    }
    if (!!vm.errors.size) {
      return customScannerErrorImg || <ScannerErrorImg />;
    }
    return customScannerImg || <ScannerImg />;
  };

  const getText = () => {
    if (vm.errors.size) {
      return <ScanErrorText>{vm.warnErrorDescription}</ScanErrorText>;
    }
    if (vm.warns && vm.warns.size) {
      return <ScanWarnText>{vm.warnErrorDescription}</ScanWarnText>;
    }
    if (vm.allScanned) {
      return (
        <AllScannedText>
          <CheckIconStyled />
          <TextGreen>Все товары совпадают со сведениями поставщика</TextGreen>
        </AllScannedText>
      );
    }
    if (!vm.showCurrentCode) {
      return scanText;
    }
    if (vm.ssccScanned) {
      return (
        <TextGreen>
          <AcceptedStatusIcon />
          &nbsp;Групповой код отсканирован, продолжайте сканирование или перейдите к расформированию
        </TextGreen>
      );
    }
    return (
      <TextGreen>
        <AcceptedStatusIcon />
        &nbsp;Код маркировки добавлен, продолжайте сканирование
      </TextGreen>
    );
  };

  return (
    <ScannerHeaderWrap>
      <ScanModes>
        <ScanModesTitle>Сканировать:</ScanModesTitle>
        <Gapped gap={8}>
          <ModeBtn
            data-tid="SelectScannerMode"
            onClick={() => changeMode(ScanMode.Scanner)}
            active={vm.mode === ScanMode.Scanner}
          >
            2D-сканером
          </ModeBtn>
          {!onlyScanner && (
            <Gapped gap={8}>
              <ModeBtn
                data-tid="SelectPhoneMode"
                onClick={() => changeMode(ScanMode.Phone)}
                active={vm.mode === ScanMode.Phone}
              >
                Телефоном
              </ModeBtn>
              <ModeBtn
                data-tid="SelectInputMode"
                onClick={() => changeMode(ScanMode.Input)}
                active={vm.mode === ScanMode.Input}
              >
                Ввести вручную
              </ModeBtn>
            </Gapped>
          )}
        </Gapped>
      </ScanModes>

      <ScannerHeader>
        {vm.mode === ScanMode.Phone && (
          <ScannerHeaderContentWrap topOffset>
            <QrCodeBlock allScanned={vm.allScanned} />
          </ScannerHeaderContentWrap>
        )}
        {vm.mode === ScanMode.Scanner && (
          <Loader
            active={!!vm.loadingState.get("additionalInfo")}
            showChildren
            caption="Загрузка дополнительной информации"
          >
            <ScannerHeaderContentWrap>
              {vm.serverErrors && vm.serverErrors.size ? (
                <ServerErrors deliveryType={deliveryType} tryToAuth={tryToAuth} errors={vm.serverErrors} />
              ) : (
                <>
                  {vm.codeAdditionalInfo && !vm.errors.size ? (
                    <AdditionalInfo
                      info={vm.codeAdditionalInfo}
                      maxWidth={ScannerAdditionalInfoMaxWidth}
                      discardCode={vm.discardCode}
                    />
                  ) : (
                    <ScannerImgWrap>{scannerImg()}</ScannerImgWrap>
                  )}
                </>
              )}
            </ScannerHeaderContentWrap>
            {getText()}
          </Loader>
        )}
        {vm.mode === ScanMode.Input && (
          <ScannerHeaderContentWrap>
            <ScannerInput vm={vm} placeholderText={placeholderText} hideSgtinImg={hideSgtinImg} />
          </ScannerHeaderContentWrap>
        )}

        <AdvertisementWrap>
          <AdvertisementBlock
            text="Теперь вы можете расформировать несколько агрегатов за один раз"
            isDisplayed={deliveryType === DeliveryType.Unpacking && !!vm.advertisementsMap.get("massDisaggregation")}
            noPin
            onClose={onCloseAggregationAdvertisement}
          />
          <AdvertisementBlock
            text={"Теперь вы можете вывести из оборота часть упаковки"}
            onClose={onCloseFractionDisposalAdvertisement}
            noPin
            isDisplayed={
              (deliveryType === DeliveryType.Disposal || deliveryType === DeliveryType.DisposalWithRegistrator) &&
              !!vm.advertisementsMap.get("fractionDisposal")
            }
          />
        </AdvertisementWrap>
      </ScannerHeader>
    </ScannerHeaderWrap>
  );
});
