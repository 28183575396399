import React from "react";
import { observer } from "mobx-react";
import { CirculationPageVM } from "../CirculationPageVM";
import { Container, Reason } from "../Common/PageInfoBlock/PageInfoBlock.styles";
import { CodesTable } from "Common/GoodsTable/CodesTable/CodesTable";
import { DestructionMethodsNames } from "features/Circulation/WithdrawalDictionary";

interface Props {
  vm: CirculationPageVM;
}

export const DestructionInfoBlock = observer((props: Props) => {
  const { vm } = props;
  const showTable = vm.delivery.items && !!vm.delivery.items.length;

  return (
    <Container>
      {vm.destruction?.destructionMethod && (
        <Reason>
          <span title="Способ уничтожения">{DestructionMethodsNames[vm.destruction.destructionMethod]}</span>
        </Reason>
      )}
      {showTable && <CodesTable items={vm.delivery.items} additionalInfo={vm.additionalInfo} />}
    </Container>
  );
});
