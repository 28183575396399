import styled from "styles/styled-components";
import { IThemeInterface } from "styles/theme";
import { colors } from "styles/colors";

interface DropzoneProps {
  isDragActive: boolean;
  theme: IThemeInterface;
}

const bcolor = colors.greyWhisperDark;
const bcolorActive = colors.blueActive;

export const DropzoneRoot = styled.div<DropzoneProps>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, ${bcolor} 50%, transparent 50%),
    linear-gradient(to right, ${bcolor} 50%, transparent 50%),
    linear-gradient(to bottom, ${bcolor} 50%, transparent 50%),
    linear-gradient(to bottom, ${bcolor} 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;

  &:hover,
  &:visited,
  &:focus,
  &:active {
    outline: none;
  }

  &:active,
  &:focus {
    background-image: linear-gradient(to right, ${bcolorActive} 50%, transparent 50%),
      linear-gradient(to right, ${bcolorActive} 50%, transparent 50%),
      linear-gradient(to bottom, ${bcolorActive} 50%, transparent 50%),
      linear-gradient(to bottom, ${bcolorActive} 50%, transparent 50%);
  }
`;
