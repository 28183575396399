import styled from "styles/styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  min-height: calc(100vh - 170px); // ie 11 support
  padding: 24px 32px;
`;

export const Footer = styled.div`
  position: sticky;
  z-index: 5;
  bottom: 0;
  left: 0;
  padding: 20px 34px;
  background: ${props => props.theme.colors.greyLight};
`;

export const CertificateWrapper = styled.div`
  max-width: 980px;
`;

export const DepartmentsTitle = styled.p`
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 15px;
`;

export const ErrorWrap = styled.div`
  width: 655px;
  background-color: ${props => props.theme.colors.redBackground};
  padding: 17px 10px 18px 24px;
  line-height: 27px;
  margin-bottom: 20px;

  h4 {
    margin-bottom: 5px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
