import { action, computed, observable } from "mobx";
import { DeliveryStatus } from "typings/server";

import { BaseShipmentPageVM } from "../Common/BaseShipmentPageVM";

type ReverseShipmentErrors = "reportDateRequired" | "invalidReportDate";

export class ReverseShipmentPageVM extends BaseShipmentPageVM {
  @observable public errors: Map<ReverseShipmentErrors, string> = new Map();

  @computed
  get showFooter(): boolean {
    return this.showFinishAcceptanceBtn || this.showSignBtn || this.showRollbackBtn;
  }

  @computed
  get showRollbackBtn(): boolean {
    return this.delivery.status === DeliveryStatus.Signing;
  }

  @computed
  get showFinishAcceptanceBtn(): boolean {
    const status = this.delivery.status;
    return status === DeliveryStatus.New || status === DeliveryStatus.Draft || status === DeliveryStatus.Processing;
  }

  @computed
  get isAllScanned(): boolean {
    return this.delivery.items.every(item => item.isAllScanned);
  }

  @computed
  get unscannedLen(): number {
    return this.delivery.allCodesLen - this.delivery.allScannedLen;
  }

  @action
  async save(): Promise<void> {
    this.toggleLoading("creatingDocument");
    await this.actions.save(this.delivery);
  }

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.deliveryStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.deliveryStore.getItem(this.delivery.id, { force: true });
    this.deliveryStore.setSelected(item);
    this.toggleLoading("rollback");
  }
}
