import React from "react";
import { SignatureStore } from "stores/SignatureStore";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { FreeScanSidePageBody } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageBody";
import { PackingDeliveryModel } from "models/Delivery/PackingDeliveryModel";
import { PackingStore } from "stores/PackingStore";
import { FreeScanSidePageVM } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageVM";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { extractionValidations } from "./ExtractionValidations";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface ISidePageProps {
  close(): void;
  save(id: string): Promise<void>;
  delivery: PackingDeliveryModel;
  additionalInfo?: CodesAdditionalInfo;
  isNew?: boolean;
}

// React.memo + () => true - не обновляет компонент
export const ExtractionSidePage: React.FC<ISidePageProps> = React.memo(
  (props: ISidePageProps) => {
    const { delivery, save, isNew } = props;
    const additionalInfo = props.additionalInfo || new CodesAdditionalInfo(delivery.id, []);
    const customValidations = (code: string, copiedItems: DeliveryItemModelExtended[]) =>
      extractionValidations(code, copiedItems);

    const vm = new FreeScanSidePageVM(
      PackingStore,
      SignatureStore,
      AdvertisementStore,
      additionalInfo,
      delivery,
      save,
      customValidations
    );

    return (
      <ScannerSidePageContainer
        scanText={"Отсканируйте коды маркировки изымаемых товаров"}
        headerText={"Изъятие из агрегата"}
        saveBtn={isNew ? "Перейти к изъятию" : "Сохранить"}
        vm={vm}
        {...props}
      >
        <FreeScanSidePageBody vm={vm} />
      </ScannerSidePageContainer>
    );
  },
  () => true
);
