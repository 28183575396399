import styled from "styles/styled-components";
import { CheckIcon } from "icons";

export const LinkWrap = styled.div`
  margin-top: 8px;
`;

export const QRCodeTextWrap = styled.div`
  text-align: left;
  max-width: 225px;
`;

const canvasH = "125px";

export const CanvasWrap = styled.div`
  width: ${canvasH};
  height: ${canvasH};
  border: 1px solid ${props => props.theme.colors.gray};
  margin-right: 20px;
  position: relative;
  z-index: 2;

  & canvas {
    width: 123px;
    height: 123px;
  }
`;

export const ScannerQRCodeWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 140px;
  align-items: center;
  padding-top: 5px;
`;

export const AllScanned = styled.div`
  display: flex;
  color: ${props => props.theme.textColors.green};
  text-align: left;
  margin-right: 45px;
  align-items: center;

  span {
    margin-left: 20px;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 63px;
`;
