import React from "react";
import { observer } from "mobx-react";
import { Button } from "components";
import { FilterTextWrap, RowWrap } from "./FilterResetRow.styles";
import { getEnding } from "helpers";

interface FilterResetRowProps {
  plural: [string, string, string];
  totalSize: number;
  paddingLeft?: string;
  resetFilters(): void;
}

export const FilterResetRow = observer(
  ({ plural: [one, few, many], totalSize, paddingLeft, resetFilters }: FilterResetRowProps) => {
    const onReset = () => resetFilters();
    return (
      <RowWrap paddingLeft={paddingLeft}>
        <FilterTextWrap>{getPluralizedFilterText(one, few, many)}</FilterTextWrap>
        <Button data-tid="ResetAllFilters" use="link" onClick={onReset}>
          Сбросить фильтры
        </Button>
      </RowWrap>
    );

    function getPluralizedFilterText(one: string, few: string, many: string) {
      const firstPart = getEnding(totalSize, "Найдена", "Найдено", "Найдено");
      const secondPart = getEnding(totalSize, `${totalSize} ${one}`, `${totalSize} ${few}`, `${totalSize} ${many}`);
      return (
        <>
          {firstPart}&nbsp;<b>{secondPart}</b>
        </>
      );
    }
  }
);
