import styled, { keyframes } from "styles/styled-components";

interface LoaderStyleProps {
  minHeight?: string;
}

const spinnerCircleOffsetKeyFrames = keyframes`
        0% { stroke-dashoffset: 0; }
         100% { stroke-dashoffset: -231.25%; }
       `;

const spinnerCircleLengthKeyFrames = keyframes`
         0% { stroke-dasharray: 62.5%, 168.75%; }
         50% {  stroke-dasharray: 187.5%, 43.75%; }
         100% { stroke-dasharray: 62.5%, 168.75%; }
                `;

const spinnerCircleRotateKeyFrames = keyframes`
                         100% { transform: rotate(360deg); }
                       `;

export const LoaderRoot = styled.div<LoaderStyleProps>`
  min-height: ${props => props.minHeight};
`;

export const CustomLoaderRoot = styled.svg`
  animation: ${spinnerCircleOffsetKeyFrames} 1s cubic-bezier(0.5, 0.2, 0.5, 0.8) infinite,
    ${spinnerCircleLengthKeyFrames} 2s cubic-bezier(0.36, 0.14, 0.38, 0.69) infinite,
    ${spinnerCircleRotateKeyFrames} 2s linear infinite;
`;
