import { PopupPosition } from "@skbkontur/react-ui/internal/Popup";
import React, { useEffect, useRef, useState } from "react";
import { Hint } from "../index";

import { Ellipsis, NoEllipsis } from "./EllipsisText.styles";

interface EllipsisTextProps {
  children?: null | React.ReactChild;
  width: number;
  hintPos?: PopupPosition | "top" | "left" | "bottom" | "right";
  hintWidthCoefficient?: number;
}

export function EllipsisText({ children, width, hintPos, hintWidthCoefficient = 1.25 }: EllipsisTextProps) {
  const childContainer = useRef<HTMLDivElement>(null);
  const [isChildrenLong, setIsChildrenLong] = useState(false);
  useEffect(() => {
    if (children && childContainer.current) {
      setIsChildrenLong(childContainer.current.offsetWidth > width);
    }
  }, [children, width]);

  if (children === undefined) {
    return null;
  }

  return (
    <>
      {isChildrenLong ? (
        <Hint text={children} pos={hintPos || "bottom"} maxWidth={width * hintWidthCoefficient}>
          <Ellipsis width={`${width}px`}>{children}</Ellipsis>
        </Hint>
      ) : (
        <NoEllipsis ref={childContainer}>{children}</NoEllipsis>
      )}
    </>
  );
}
