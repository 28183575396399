import React, { PropsWithChildren, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, SidePage, EllipsisText } from "components";
import { DiscardBtnContainer, DiscardBtnWrap, scanHeaderWidth } from "./ScannerSidePage.styles";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { IScannerSidePageVM } from "./shared";
import { getSgtinFromDataMatrix, getSsccFromBarcode } from "helpers/codes";
import RefreshIcon from "@skbkontur/react-icons/Refresh";
import { ScanHelper, ScanMode } from "./ScanHelper";
import { QRCodeId } from "./QrCode/QrCodeBlock";
import { YMetrika } from "YMetrika";
import { ScanHeader } from "./ScanHeader";
import { ScannerHandler } from "Common/ScannerHandler/ScannerHandler";

interface IScanSidePageProps {
  close(): void;
  scanText: string;
  headerText?: string;
  saveBtn?: string;
  placeholderText?: string;
  hideSgtinImg?: boolean;
  onlyScanner?: boolean;
  customScannerImg?: JSX.Element;
  customScannerErrorImg?: JSX.Element;
  vm: IScannerSidePageVM;
}

export const ScannerSidePageContainer = observer((props: PropsWithChildren<IScanSidePageProps>) => {
  const {
    vm,
    headerText,
    scanText,
    saveBtn,
    customScannerImg,
    customScannerErrorImg,
    placeholderText,
    hideSgtinImg,
    onlyScanner,
  } = props;

  useEffect(() => {
    if (vm.mode === ScanMode.Phone) ScanHelper.createQRCode(QRCodeId, vm.deliveryId, null, vm.positionId);
  }, [vm]);

  // unmount
  useEffect(() => () => vm.handleUnmount(), [vm]);

  const onClose = () => {
    if (vm.setCurrentCode) vm.setCurrentCode(undefined);
    props.close();
  };

  const onSave = async () => {
    // Запускаем валидацию, только если она есть в VM
    if (vm.validateSubmit && !vm.validateSubmit?.()) {
      return;
    }
    await vm.save();
    onClose();
  };

  const handleScanCode = (code: string) => {
    if (vm.mode !== ScanMode.Scanner) {
      return;
    }
    if (code) {
      // try to guess sgtin or sscc code is
      const sscc = getSsccFromBarcode(code);
      const normalized = sscc || getSgtinFromDataMatrix(code);
      if (vm.setCurrentCode) vm.setCurrentCode(normalized);
      vm.addScannedCode(normalized, code);

      // счетчик успешно отсканированных сканером кодов
      if (!vm.errors.size && !(vm.warns && vm.warns.size)) {
        YMetrika.sendEvent("scanCodeEvent");
      }
      // счетчик попыток отсканировать код
      YMetrika.sendEvent("attemptsToAddCode");
    }
  };

  const onDiscard = () => {
    vm.discardAll();
    blur();
  };

  const blur = () => {
    const activeElement = window.document.activeElement as HTMLElement;
    // убираем фокус с кнопки
    if (activeElement && activeElement.blur) {
      activeElement.blur();
    }
  };

  return (
    <SidePage data-tid="ScannerSidePage" onClose={onClose} blockBackground ignoreBackgroundClick width="850px">
      <SidePage.Header>
        {headerText ? <EllipsisText width={scanHeaderWidth}>{headerText}</EllipsisText> : "Проверка маркировки"}
      </SidePage.Header>
      <SidePage.Body>
        <ScanHeader
          vm={vm}
          scanText={scanText}
          placeholderText={placeholderText}
          hideSgtinImg={hideSgtinImg}
          customScannerErrorImg={customScannerErrorImg}
          customScannerImg={customScannerImg}
          onlyScanner={onlyScanner}
        />
        {vm.mode === ScanMode.Scanner && <ScannerHandler onScan={handleScanCode} />}
        <SidePage.Container>{props.children}</SidePage.Container>
      </SidePage.Body>
      <SidePage.Footer panel>
        {vm.mode !== ScanMode.Phone && (
          <DiscardBtnWrap>
            <DiscardBtnContainer>
              <Button
                data-tid="DiscardAll"
                use={"link"}
                size={"small"}
                onClick={onDiscard}
                type={"button"}
                icon={<RefreshIcon />}
              >
                Сбросить результат и начать сканирование заново
              </Button>
            </DiscardBtnContainer>
          </DiscardBtnWrap>
        )}
        <FooterBtnWrapper>
          <Button
            data-tid="Submit"
            size={"large"}
            onClick={onSave}
            disabled={!!vm.disabledSaveBtn}
            use="primary"
            loading={!!vm.loadingState.get("onSave")}
          >
            {saveBtn || "Сохранить"}
          </Button>
          <Button data-tid="Cancel" size={"large"} onClick={onClose}>
            Отменить
          </Button>
        </FooterBtnWrapper>
      </SidePage.Footer>
    </SidePage>
  );
});
