import React, { useState } from "react";
import { SsccCodeContent } from "./SsccCodeContent";
import { ArrowChevronRight, ArrowChevronDown, Undo } from "@skbkontur/react-icons";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import {
  CollapseIconWrap,
  DiscardBlockWrap,
  HideWrap,
  ScannerRow,
} from "features/ScannerSidePage/ScannerSidePage.styles";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import { Button } from "components";
import { UnpackingSidePageVM } from "./UnpackingSidePageVM";
import { observer } from "mobx-react";

interface UnpackingSidePageBodyItemProps {
  vm: UnpackingSidePageVM;
  isNew: boolean;
  code: string;
  index: number;
  additionalInfo?: CodeAdditionalInfoModel;
  cols: string;
  allCodesExpanded: boolean;
}

export const UnpackingSidePageBodyItem = observer(
  ({ vm, code, additionalInfo, index, cols, isNew, allCodesExpanded }: UnpackingSidePageBodyItemProps) => {
    const isShowContent = vm.showCodeContent(additionalInfo);
    const [isExpanded, setExpanded] = useState(allCodesExpanded);

    const onDiscard = () => vm.discardCode(code);
    const onOpenInfo = () => {
      setExpanded(v => !v);
    };

    return (
      <>
        <ScannerRow sidePage isInfo={isShowContent} isNew={isNew} onClick={onOpenInfo}>
          {isShowContent && (
            <CollapseIconWrap>{isExpanded ? <ArrowChevronDown /> : <ArrowChevronRight />}</CollapseIconWrap>
          )}
          <Grid cols={cols}>
            <GridCell col={1} row={1}>
              {index + 1}
            </GridCell>
            <GridCell col={2} row={1}>
              {code}
            </GridCell>
            <GridCell col={3} row={1}>
              {additionalInfo?.name}
            </GridCell>

            <GridCell col={4} row={1} textAlign={"right"}>
              <HideWrap>{additionalInfo?.containedSsccCount || "—"}</HideWrap>
            </GridCell>
            <GridCell col={5} row={1} textAlign={"right"}>
              <HideWrap>{additionalInfo?.containedSgtinsCount || "—"}</HideWrap>
            </GridCell>

            <GridCell colStart={4} colEnd={6} row={1} textAlign="right">
              <DiscardBlockWrap>
                <Button use={"link"} size={"small"} onClick={onDiscard} icon={<Undo />}>
                  Отменить сканирование
                </Button>
              </DiscardBlockWrap>
            </GridCell>
          </Grid>
        </ScannerRow>
        {isShowContent && <SsccCodeContent ssccCodeContent={additionalInfo} isOpened={isExpanded} sidePage />}
      </>
    );
  }
);
