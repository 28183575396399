import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";

class WelcomeApiInternal {
  constructor(private httpClient: IHttpClient) {}

  createUser(inviteId: string): Promise<void> {
    return this.httpClient.post("", null, { inviteId });
  }
}

export const WelcomeApi = new WelcomeApiInternal(getHttpClient(drugsApi + "/welcome"));
