import React, { useState } from "react";
import { InputProps } from "@skbkontur/react-ui";
import { ClockIconStyled, TimeInputWrap } from "./TimeInput.styles";
import { Input } from "../index";

interface TimeInputProps {
  onChangeTime(value: string): void;
}

interface TimeInputPropsExtended extends InputProps, TimeInputProps {}

export const TimeInput: React.FC<TimeInputPropsExtended> = ({ error, value, onChangeTime }: TimeInputPropsExtended) => {
  const [time, setTime] = useState(value);

  const handleChangeTime = (time: string) => {
    setTime(time);
    const formatted = formatTime(time);
    onChangeTime(formatted);

    // второй и первый setValue нужен для обновления значения в инпуте
    // мы сеттим только отформатированное значение времени, сперва засетили 25:00 часов -> value стало 23
    // если затем мы сеттим 24 часа -> value вновь стало 23, а значит props value не изменилось и компонент не перерисовался
    setTimeout(() => {
      setTime(formatted);
    });
  };

  // todo: написать тесты на этот метод
  const formatTime = (time: string): string => {
    const [hours = "", minutes = ""] = time.split(":");
    let newHours = hours.padEnd(2, "0");
    let newMinutes = minutes.padEnd(2, "0");
    if (+newHours > 23) {
      newHours = "23";
    }
    if (+newMinutes > 59) {
      newMinutes = "59";
    }
    return `${newHours}:${newMinutes}`;
  };

  return (
    <TimeInputWrap>
      <Input
        width="90px"
        alwaysShowMask
        mask="29:69"
        formatChars={{ "2": "[0-2]", "9": "[0-9]", "6": "[0-6]" }}
        maskChar="_"
        rightIcon={<ClockIconStyled />}
        onValueChange={handleChangeTime}
        value={time}
        error={error}
      />
    </TimeInputWrap>
  );
};
