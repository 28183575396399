import { action, computed, observable } from "mobx";
import { DepartmentModel, EmployeeModel, EmployeeRole } from "typings/server";
import { EmployeeRoleBitwise } from "typings/client";

export class EmployeeModelExtended extends EmployeeModel {
  @observable roleBitwise: number;
  @observable availableDepartments: DepartmentModel[];

  constructor(model?: EmployeeModel) {
    super();
    if (model) {
      Object.assign(this, model);
      this.fio = model.fio ? model.fio.trim() : "";
      this.setRoleBitwise(model.role);
    }
  }

  @action
  setRole(role: EmployeeRole) {
    this.role = role;
    this.setRoleBitwise(role);
  }

  @action
  setRoleBitwise(role: EmployeeRole) {
    const roleToArr = (role as string).split(", ");
    this.roleBitwise = roleToArr.reduce((result, item) => (result |= EmployeeRoleBitwise[item]), 1);
  }

  @action
  setDepartments(departments: DepartmentModel[]) {
    this.availableDepartments = departments;
  }

  @computed
  get isAdmin(): boolean {
    return (
      (this.roleBitwise & EmployeeRoleBitwise[EmployeeRole.Admin]) === EmployeeRoleBitwise[EmployeeRole.Admin] ||
      this.role === EmployeeRole.Admin
    );
  }

  @computed
  get isAllDepartmentsUser(): boolean {
    return (
      (this.roleBitwise & EmployeeRoleBitwise[EmployeeRole.AllDepartmentsUser]) ===
        EmployeeRoleBitwise[EmployeeRole.AllDepartmentsUser] || this.role === EmployeeRole.AllDepartmentsUser
    );
  }

  @computed
  get hasAccessToAllDepartments(): boolean {
    return (
      (this.roleBitwise & EmployeeRoleBitwise[EmployeeRole.AllDepartmentsUser]) ===
        EmployeeRoleBitwise[EmployeeRole.AllDepartmentsUser] || this.role === EmployeeRole.AllDepartmentsUser
    );
  }
}
