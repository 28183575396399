import React from "react";
import { observer } from "mobx-react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { getFormattedDate } from "helpers";
import { Code, SgtinCodeText, CopyIconWrap, InfoBlock, UndoIconWrap, InfoRoot } from "../AdditionalInfo.styles";
import { Copy, Undo } from "@skbkontur/react-icons";
import { Button, Hint, Toast } from "components";
import { copyToBuffer } from "helpers/copy";
import { DiscardBlockWrap, HideWrap } from "features/ScannerSidePage/ScannerSidePage.styles";
import { AdditionalInfoVM } from "../AdditionalInfoVM";
import { ExtraField, ExtraFieldInfo, RenderProps } from "../extraFields/extraFields";
import { CodeModelExtended } from "models/Code/CodeModelExtended";

interface IAdditionalInfoTableItemProps {
  vm: AdditionalInfoVM;
  codeModel: CodeModelExtended;
  canBeDiscarded: boolean;
  alwaysVisible?: boolean;
  onDiscardCode?: (code: string) => void;
  isNewCode?: boolean;
}

export const AdditionalInfoSgtinTableItem = observer((props: IAdditionalInfoTableItemProps) => {
  const { vm, codeModel, onDiscardCode, canBeDiscarded, alwaysVisible, isNewCode } = props;

  const codeInfo = vm.additionalInfo.codesInfo.get(codeModel.code);

  if (!codeInfo && !alwaysVisible) return null;

  let lastColNumber = vm.isSidePage ? 3 : 4;

  const code = codeInfo?.code || codeModel.code;

  const copy = () => {
    copyToBuffer(code);
    Toast.push("Код скопирован");
  };

  const discard = () => {
    onDiscardCode?.(code);
  };

  return (
    <InfoBlock
      data-tid="AdditionalInfoSgtinTableItem"
      isNew={isNewCode}
      canBeDiscarded={canBeDiscarded && !vm.showExtraField}
      sidePage={vm.isSidePage}
    >
      <InfoRoot>
        <Grid cols={vm.sgtinCols} paddingCells={"7px 4px"}>
          <GridCell col={1} row={1}>
            <Code data-tid="Copy" onClick={copy}>
              <SgtinCodeText data-tid="Code">{code}</SgtinCodeText>
              <Hint text={"Скопировать"}>
                <CopyIconWrap>
                  <Copy />
                </CopyIconWrap>
              </Hint>
            </Code>
          </GridCell>
          <GridCell data-tid="BatchInfo" col={2} row={1}>
            {codeInfo?.batch || "—"}
          </GridCell>
          {!vm.isSidePage && (
            <GridCell data-tid="ReleaseDate" col={3} row={1}>
              {(codeInfo?.releaseDate && getFormattedDate(codeInfo.releaseDate)) || "—"}
            </GridCell>
          )}
          <GridCell col={lastColNumber} row={1}>
            {canBeDiscarded && !vm.showExtraField && (
              <DiscardBlockWrap>
                <Button data-tid="DiscardCode" use={"link"} size={"small"} onClick={discard} icon={<Undo />}>
                  Отменить сканирование
                </Button>
              </DiscardBlockWrap>
            )}
            <HideWrap data-tid="ExpirationDate">
              {(codeInfo?.expirationDate && getFormattedDate(codeInfo.expirationDate)) || "—"}
            </HideWrap>
          </GridCell>
          {vm.showExtraField && vm.extraField && (
            <>
              <GridCell
                col={lastColNumber + 1}
                row={1}
                alignSelf="center"
                padding={vm.extraField.type === ExtraField.FractionDisposal ? "2px 0px" : "8px 4px"}
              >
                {vm.extraField.renderField(getExtraFieldProps(vm.extraField))}
              </GridCell>

              {vm.isSidePage && vm.extraField?.type === ExtraField.FractionDisposal && canBeDiscarded && (
                <GridCell col={lastColNumber + 2} row={1} alignSelf="center" padding="0px">
                  <Hint text="Отменить сканирование">
                    <UndoIconWrap>
                      <Undo data-tid="DiscardCode" onClick={discard} />
                    </UndoIconWrap>
                  </Hint>
                </GridCell>
              )}
            </>
          )}
        </Grid>
      </InfoRoot>
    </InfoBlock>
  );

  function getExtraFieldProps(extraField: ExtraFieldInfo): RenderProps {
    switch (extraField.type) {
      case ExtraField.CirculationDate:
        return { codeInfo };
      case ExtraField.FractionDisposal:
        return {
          vm: vm,
          codeModel: codeModel,
          defaultTotalParts: codeInfo?.containingElementaryCount || 0,
        };
      default:
        const never: never = extraField.type;
        return never;
    }
  }
});
