import React, { useEffect, useMemo } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { PackingRoute } from "routes";
import { DeliveryHeader, DeliveryTopBlock, UpdateBlock } from "../Common/Styles/MainPage.styles";
import { SyncInfo } from "../Common/SyncInfo/SyncInfo";
import { PackingStore } from "stores/PackingStore";
import { SignatureStore } from "stores/SignatureStore";
import { UserStore } from "stores/UserStore";
import { PackingSubRoute } from "routes/PackingRoute";
import { DeliveryType } from "typings/server";
import { MdlpModal } from "../CertificatesList/MdlpModal";
import { ICertificate } from "@skbkontur/plugin-js";
import { SYNC_POLL_INTERVAL } from "stores/shared";
import { MainPage as UnpackingMainPage } from "./Unpacking/MainPage/MainPage";
import { MainPage as ExtractionMainPage } from "./Extraction/MainPage/MainPage";
import { HeadWrap, IndexWrap, TabsWrap } from "./PackingIndexPage.styles";
import { Loader, Tabs } from "components";
import { Separator } from "styles/separator";
import { observer } from "mobx-react";
import { PackingIndexPageVM } from "./PackingIndexPageVM";
import { DeliveriesStore } from "stores/DeliveriesStore";
import { capitalize } from "lodash-es";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { ThemeContext as ReactUiThemeContext, FLAT_THEME, ThemeFactory } from "@skbkontur/react-ui";
import { theme } from "styles/theme";

interface PackingIndexPageInnerProps extends RouteComponentProps<PackingRoute> {
  pageVM: PackingIndexPageVM;
}

const PackingIndexPageInner = observer((props: PackingIndexPageInnerProps) => {
  const { pageVM, history } = props;
  const { listVM } = pageVM;

  const toggleMdlpModal = () => listVM.toggleModal("mdlpModal");

  const onSign = async (cert: ICertificate) => {
    await listVM.onSing(cert);
    await listVM.synchronizeAndUpdate(SYNC_POLL_INTERVAL);
  };

  // unmount
  useEffect(() => {
    return () => {
      listVM.handleUnmount();
    };
  }, [listVM]);

  const setTab = (tab: string) => {
    history.push(PackingRoute.getHref(undefined, tab as PackingSubRoute));
  };

  const updatedTheme = useMemo(() => {
    return ThemeFactory.create(
      {
        tabColorFocus: theme.colors.bluePrimary,
        tabBorderWidth: "3px",
      },
      FLAT_THEME
    );
  }, []);

  return (
    <IndexWrap>
      {listVM.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={toggleMdlpModal}
          title={"Доступ в ИС МДЛП"}
          sign={onSign}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}

      <HeadWrap>
        <DeliveryTopBlock>
          <DeliveryHeader>
            <h2>{PackingRoute.title}</h2>
            <UpdateBlock>
              <SyncInfo vm={listVM} />
            </UpdateBlock>
          </DeliveryHeader>

          <ReactUiThemeContext.Provider value={updatedTheme}>
            <TabsWrap>
              <Tabs value={listVM.types[0].toLowerCase()} onValueChange={setTab}>
                <Tabs.Tab id={PackingSubRoute.unpacking}>Расформирование</Tabs.Tab>
                <Tabs.Tab id={PackingSubRoute.extraction}>Изъятие</Tabs.Tab>
              </Tabs>
            </TabsWrap>
          </ReactUiThemeContext.Provider>
          <Separator />
        </DeliveryTopBlock>
      </HeadWrap>

      {listVM.loadingState.get("start") ? (
        <Loader active minHeight={"80vh"} />
      ) : (
        <Switch>
          <Redirect exact from={PackingRoute.baseRoute} to={PackingRoute.unpackingBaseRoute} push />
          <Route path={PackingRoute.unpackingBaseRoute} exact>
            <DeliveryTypeContext.Provider value={DeliveryType.Unpacking}>
              <UnpackingMainPage {...props} />
            </DeliveryTypeContext.Provider>
          </Route>
          <Route path={PackingRoute.extractionBaseRoute} exact>
            <DeliveryTypeContext.Provider value={DeliveryType.Extraction}>
              <ExtractionMainPage {...props} />
            </DeliveryTypeContext.Provider>
          </Route>
        </Switch>
      )}
    </IndexWrap>
  );
});

interface PackingPageProps extends RouteComponentProps<PackingRoute> {}

export const PackingIndexPage = (props: PackingPageProps) => {
  const { match } = props;
  const type = match.params.type
    ? ((capitalize(PackingSubRoute[match.params.type]) as unknown) as DeliveryType)
    : DeliveryType.Unpacking;

  useEffect(() => {
    PackingStore.changeType(type);
  }, [type]);

  // change deliveries type before init DeliveriesMainPageVM

  const vm = useMemo(() => new PackingIndexPageVM(PackingStore, SignatureStore, UserStore, DeliveriesStore, type), []);

  useEffect(() => {
    vm.setType(type);
  }, [type]);

  return <PackingIndexPageInner {...props} pageVM={vm} />;
};
