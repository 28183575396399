import React, { FC } from "react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { StatusItem } from "Common/Status/StatusItem";
import { FilterDropdown } from "Common/Filter/FilterDropdown/FilterDropdown";
import {
  DisposalReason,
  DisposalReasonNames,
  ReturnReasonNames,
  WithdrawalCauseInReentryNames,
  WithdrawalReasonNames,
} from "features/Circulation/WithdrawalDictionary";
import { DeliveryType } from "typings/server";
import { colsDefault, colsForDestruction, colsForReentry } from "./OperationsList";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { observer } from "mobx-react";

interface Props {
  listType: DeliveryType.Withdrawal | DeliveryType.Reentry | DeliveryType.Destruction;
  vm: DeliveriesMainPageVM;
  type: DeliveryType;
}

export const OperationsHeader: FC<Props> = observer(({ listType, vm, type }) => {
  if (listType === DeliveryType.Withdrawal) {
    return (
      <Grid cols={colsDefault} headerBorderBottom>
        <GridCellHeader col={1} row={1} padding="8px 4px 0 10px">
          Дата операции
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          № документа
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign="left">
          <FilterDropdown
            data-tid="StatusFilter"
            value={vm.allowedStatuses}
            caption="Статус"
            vm={vm}
            renderCustomItemLabel={value => <StatusItem status={value.name} type={type} />}
          />
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign="left">
          Вывод из оборота
        </GridCellHeader>
        <GridCellHeader col={5} row={1} textAlign="left">
          Тип вывода из оборота
        </GridCellHeader>
        <GridCellHeader col={6} row={1} textAlign="left">
          <FilterDropdown
            data-tid="WithdrawalReasonFilter"
            value={vm.allowedWithdrawalReason}
            caption="Причина"
            vm={vm}
            renderCustomItemLabel={value => {
              return value.name === DisposalReason.MedicalAssistance
                ? DisposalReasonNames[value.name]
                : WithdrawalReasonNames[value.name];
            }}
          />
        </GridCellHeader>
        <GridCellHeader col={7} row={1}></GridCellHeader>
      </Grid>
    );
  }

  if (listType === DeliveryType.Destruction) {
    return (
      <Grid cols={colsForDestruction} headerBorderBottom>
        <GridCellHeader col={1} row={1} padding="8px 4px 0 10px">
          Дата операции
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Договор
        </GridCellHeader>
        <GridCellHeader col={3} row={1}>
          Акт передачи на уничтожение
        </GridCellHeader>
        <GridCellHeader col={4} row={1}>
          Акт об уничтожении
        </GridCellHeader>
        <GridCellHeader col={5} row={1} textAlign="left" padding="8px 72px 0 10px">
          <FilterDropdown
            data-tid="StatusFilter"
            value={vm.allowedStatuses}
            caption="Статус"
            vm={vm}
            renderCustomItemLabel={value => <StatusItem status={value.name} type={type} />}
          />
        </GridCellHeader>
        <GridCellHeader col={6} row={1} textAlign="left">
          Организация, выполняющая уничтожение
        </GridCellHeader>
      </Grid>
    );
  }

  return (
    <Grid cols={colsForReentry} headerBorderBottom>
      <GridCellHeader col={1} row={1} padding="8px 4px 0 10px">
        Дата операции
      </GridCellHeader>
      <GridCellHeader col={2} row={1}>
        № документа
      </GridCellHeader>
      <GridCellHeader col={3} row={1} textAlign="left">
        <FilterDropdown
          data-tid="StatusFilter"
          value={vm.allowedStatuses}
          caption="Статус"
          vm={vm}
          renderCustomItemLabel={value => <StatusItem status={value.name} type={type} />}
        />
      </GridCellHeader>

      <GridCellHeader col={4} row={1} textAlign="left">
        <FilterDropdown
          data-tid="WithdrawalCausesFilter"
          value={vm.allowedWithdrawalCauses}
          caption="Причина вывода из оборота"
          tableHeaderPaddings="small"
          vm={vm}
          renderCustomItemLabel={value => {
            return WithdrawalCauseInReentryNames[value.name];
          }}
        />
      </GridCellHeader>
      <GridCellHeader col={5} row={1} textAlign="left">
        <FilterDropdown
          data-tid="ReturnReasonFilter"
          value={vm.allowedReturnReasons}
          caption="Причина возврата"
          vm={vm}
          renderCustomItemLabel={value => ReturnReasonNames[value.name]}
        />
      </GridCellHeader>
      <GridCellHeader col={6} row={1}></GridCellHeader>
    </Grid>
  );
});
