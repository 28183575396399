import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";

class AdminApiInternal {
  constructor(private httpClient: IHttpClient) {}

  refreshUserFio(portalId: string): Promise<void> {
    return this.httpClient.post(`users/portal/${portalId}`);
  }
}

export const AdminApi = new AdminApiInternal(getHttpClient(drugsApi + "/admin"));
