import { action, computed, observable } from "mobx";
import { ISignatureStore } from "stores/SignatureStore";
import { AcceptusType, CreateRefusalModel, DeliveryStatus, DeliveryType } from "typings/server";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { PositionsReportModelExtended } from "models/PositionsReportModelExtended";
import { IAcceptanceStore } from "stores/AcceptanceStore";
import { IUserStore } from "stores/UserStore";
import { ProgressStage } from "features/Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { ReportsApi } from "api/ReportsApi";
import { BaseAcceptancePageVM } from "../Common/BaseAcceptancePageVM";
import { IStage } from "models/Delivery/shared";

export class ReverseAcceptancePageVM extends BaseAcceptancePageVM {
  @observable selectedGood: UtdPositionModelExtended;
  @observable isDateChanged: boolean = false;
  @observable rejectDate: string = new Date().toISOString();

  constructor(
    deliveryStore: IAcceptanceStore,
    documentStore: ISignatureStore,
    userStore: IUserStore,
    type: DeliveryType,
    isNew: boolean
  ) {
    super(deliveryStore, documentStore, userStore, type);

    if (isNew) {
      this.toggleModal("editorModal");
    }
  }

  //#region Computed
  @computed
  get hasAllRequiredFields(): boolean {
    const { financingType, contractType, counteragentDepartment, receiveType, recipient } = this.delivery;
    if (this.isSimplified) {
      return Boolean(financingType && contractType);
    }

    const hasSupplier = counteragentDepartment?.id;
    return Boolean(hasSupplier && financingType && receiveType && recipient && contractType);
  }

  @computed
  get canCompleteAcceptance(): boolean {
    return !!this.delivery.allCodesLen;
  }

  @computed
  get areAllPositionsHaveCodes(): boolean {
    return this.delivery.utdPositions.every(pos => pos.allCodesLen !== 0);
  }

  @computed
  get unscannedPositionsLength(): number {
    return this.delivery.utdPositions.reduce((sum: number, position) => {
      if (!position.isAllScanned) {
        sum += 1;
      }
      return sum;
    }, 0);
  }

  @computed
  get showEditor(): boolean {
    return (
      this.delivery.status === DeliveryStatus.New ||
      this.delivery.status === DeliveryStatus.Processing ||
      this.delivery.status === DeliveryStatus.Draft
    );
  }

  @computed
  get showWaitingForCounterpartyStage(): boolean {
    return (
      this.delivery.status === DeliveryStatus.WaitingForCounterparty ||
      this.delivery.status === DeliveryStatus.PartiallyFailedIncompleted
    );
  }

  @computed
  get isFailedStatus() {
    const { status } = this.delivery;
    return (
      status === DeliveryStatus.Failed ||
      status === DeliveryStatus.PartiallyFailed ||
      status === DeliveryStatus.RecallFailed
    );
  }

  @computed
  get isPartiallyFailed() {
    return this.delivery.isPartiallyFailed;
  }

  @computed
  get waitingForCounterpartyStageName(): string {
    // для старых поставок без частичных отзывов
    if (this.delivery.status === DeliveryStatus.Recalled) return "Отзыв поставщиком";
    if (this.delivery.isRejected) return "Отказ от переданных сведений";
    return this.stages[DeliveryStage.WaitingForCounterparty].name;
  }

  @computed
  get isSimplified(): boolean {
    return this.delivery.acceptusType === AcceptusType.Simplified;
  }

  @computed
  get isResended(): boolean {
    // Если в ПОА есть два и более чилдов, и какой-то из них не в конечной стадии (успех/отклонение/отзыв), значит он вернулся после переотправки
    return (
      this.delivery.childDeliveries.length > 1 &&
      this.delivery.childDeliveries.some(
        item =>
          item.status === DeliveryStatus.Signing ||
          item.status === DeliveryStatus.Sending ||
          item.status === DeliveryStatus.MdlpProcessing ||
          item.status === DeliveryStatus.CreatingDocument
      )
    );
  }

  @computed
  get doneBlockTitle(): string {
    if (this.delivery.status === DeliveryStatus.Recalled) return "Приемка отозвана";
    return this.stages[DeliveryStage.Done].name;
  }

  @computed
  get processingStepName(): string {
    if (this.isSimplified) {
      return "Приемка по упрощенной схеме";
    }

    if (this.isResended) {
      return "Создание черновика";
    }

    return this.stages[DeliveryStage.Processing].name;
  }

  //#endregion

  //#region Buttons
  @computed
  get showFinishAcceptanceBtn(): boolean {
    const status = this.delivery.status;
    return status === DeliveryStatus.New || status === DeliveryStatus.Draft || status === DeliveryStatus.Processing;
  }

  @computed
  get showDeleteBtn(): boolean {
    const {
      selectedDelivery: { status, acceptusType },
      isCanBeDeleted,
    } = this.deliveryStore;
    return isCanBeDeleted(status, acceptusType) || status === DeliveryStatus.Failed;
  }

  @computed
  get showToggleIsRejectBtn(): boolean {
    return (
      (this.showWaitingForCounterpartyStage || this.delivery.status === DeliveryStatus.PartiallyFailedIncompleted) &&
      !this.loadingMap.get("reject")
    );
  }

  @computed
  get showRejectBtn(): boolean {
    const { isRejected, status } = this.delivery;
    return isRejected && (this.showWaitingForCounterpartyStage || status === DeliveryStatus.CreatingDocument);
  }

  @computed
  get showTryAgainBtn(): boolean {
    return this.isPartiallyFailed && !this.delivery.isRejected;
  }

  @computed
  get showFooter(): boolean {
    return (
      this.showTryAgainBtn ||
      this.showFinishAcceptanceBtn ||
      this.showSignBtn ||
      !!this.rollbackBtnText ||
      this.showDeleteBtn ||
      this.showToggleIsRejectBtn ||
      this.showRejectBtn
    );
  }

  @computed
  get rollbackBtnText(): string | undefined {
    // При переотправке возможности вернуться в черновик мы не даем
    if (this.isResended) {
      return;
    }
    const { status } = this.delivery;
    switch (status) {
      case DeliveryStatus.Failed:
      case DeliveryStatus.Recalled:
      case DeliveryStatus.Rejected:
        return "Вернуться к приемке";
      case DeliveryStatus.Signing:
        return "Вернуться к черновику";
    }
  }

  @computed
  get rollbackHintText(): string {
    const { status } = this.delivery;
    return status === DeliveryStatus.Signing ? "Для редактирования приемки" : "Для редактирования и повторной приемки";
  }

  @computed
  get shouldOpenRollbackModal(): boolean {
    const { status } = this.delivery;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.Recalled || status === DeliveryStatus.Rejected;
  }
  //#endregion

  //#region API helpers
  @action
  async rejectAll() {
    const model: CreateRefusalModel = {
      deliveryId: this.delivery.id,
      reason: this.delivery.recallReason,
    };
    if (this.isDateChanged) {
      model.operationDate = this.rejectDate;
    }

    await ReportsApi.reject(model);
    this.actions.startPolling(this.delivery.id);

    this.delivery.setNextStage(
      DeliveryStatus.CreatingDocument,
      DeliveryStage.Processing,
      {
        completionDate: new Date().toISOString(),
      },
      this.userStore.fullName
    );
    this.toggleLoading("reject");
  }

  @action.bound
  async resend(): Promise<void> {
    this.toggleLoading("resend");
    // Отправляем запрос на переотправку части сведений
    await ReportsApi.resend(this.delivery.id);
    // Получаем обновленную операцию
    const item = await this.deliveryStore.getItem(this.delivery.id, { force: true });
    // Обновляем модель
    await this.actions.pooling(this.delivery);
    this.deliveryStore.setSelected(item);
    this.toggleLoading("resend");
  }

  @action
  async save(): Promise<void> {
    this.toggleLoading("creatingDocument");
    this.actions.sendReportDateMetrics(this.delivery.reportDate);
    await this.actions.save(this.delivery);
  }

  savePositions = async () => {
    const reportModel = new PositionsReportModelExtended(this.delivery.id, this.delivery.utdPositions);
    await this.deliveryStore.savePositions(reportModel);
    if (this.delivery.status === DeliveryStatus.New) {
      this.actions.updateStatus(DeliveryStatus.Processing, DeliveryStage.Processing, this.delivery);
    }
  };

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.deliveryStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.deliveryStore.getItem(this.delivery.id, { force: true });
    this.deliveryStore.setSelected(item);
    this.toggleLoading("rollback");
  }

  @action async deleteDelivery() {
    this.toggleLoading("delete");
    await this.deliveryStore.deleteDelivery(this.delivery.id);
    this.toggleLoading("delete");
  }
  //#endregion

  //#region Other helpers
  validateReject() {
    this.errors.clear();
    this.validateDate(this.rejectDate);
    this.validateReason(this.activeDelivery.isRejected);
    return !this.errors.size;
  }

  @action
  setRejectReason = (val: string) => {
    this.activeDelivery.recallReason = val;
    if (this.submitted) this.validateReject();
  };

  @action
  setRejectDate = (date?: string) => {
    this.rejectDate = date || "";
    this.isDateChanged = true;
    if (this.submitted) this.validateReject();
  };

  getSendingStepName(stage: IStage): string {
    return stage.progress === ProgressStage.Planned ? "Отправка в ИС МДЛП" : "Отправлено в ИС МДЛП";
  }

  @action
  setSelectedGood(good: UtdPositionModelExtended) {
    this.selectedGood = good;
  }

  @action
  canComplete(): boolean {
    if (!this.hasAllRequiredFields) {
      this.errors.set("requiredFields", "Для добавления приемки заполните оставшиеся поля");
      this.toggleModal("editorModal");
      return false;
    } else {
      this.errors.delete("requiredFields");
    }

    if (!this.areAllPositionsHaveCodes || this.isSimplified) {
      this.toggleModal("confirmation");
      return false;
    }
    return true;
  }

  @action toggleRejectAll() {
    this.delivery.toggleRejectAllStatus?.();
  }
  //#endregion
}
