import React from "react";
import { observer } from "mobx-react";
import { TokenInputType, TokenProps } from "@skbkontur/react-ui";
import { TokenInput, Token, Hint } from "components";
import { TextGrey } from "styles/shared.styles";
import { TokenInputWrap } from "./RegistratorEditor.styles";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";

interface EmployeeTokenInputProps {
  allEmployees: EmployeeModelExtended[];
  selectedEmployees: EmployeeModelExtended[];
  setSelectedEmployees(selected: EmployeeModelExtended[]): void;
}

export const EmployeeTokenInput = observer(
  ({ allEmployees, selectedEmployees, setSelectedEmployees }: EmployeeTokenInputProps) => {
    const getItems = (q: string = "") => {
      const query = q.toLowerCase();
      return Promise.resolve(
        allEmployees.filter(employee => {
          const name = employee.fio.toLowerCase();
          return name.includes(query);
        })
      );
    };

    const renderItem = (employee: EmployeeModelExtended) => employee.fio || employee.email;

    const renderToken = (employee: EmployeeModelExtended, tokenProps: Partial<TokenProps>) => (
      <Token {...tokenProps} key={employee.id} disabled={employee.isAdmin} onDoubleClick={() => null}>
        {employee.isAdmin ? (
          <Hint maxWidth="235px" text="Администратор имеет доступ ко всем регистраторам выбытия">
            {employee.fio || employee.email}
          </Hint>
        ) : (
          employee.fio || employee.email
        )}
      </Token>
    );

    const valueToString = (employee: EmployeeModelExtended) => employee.fio || (employee.email as string);

    return (
      <>
        <TokenInputWrap>
          <TokenInput<EmployeeModelExtended>
            data-tid="EmployeeTokenInput"
            type={TokenInputType.WithReference}
            selectedItems={selectedEmployees}
            onValueChange={setSelectedEmployees}
            getItems={getItems}
            width={"100%"}
            renderToken={renderToken}
            renderItem={renderItem}
            valueToString={valueToString}
          />
        </TokenInputWrap>
        <TextGrey>Сотрудники получат доступ к регистратору выбытия</TextGrey>
      </>
    );
  }
);
