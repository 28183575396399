import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import { DepartmentModel, DepartmentsModel } from "typings/server";

class DepartmentsApiInternal {
  constructor(private httpClient: IHttpClient) {}

  synchronize(): Promise<DepartmentModel[]> {
    return this.httpClient.post("synchronization");
  }

  getDepartments(): Promise<DepartmentsModel> {
    return this.httpClient.get("");
  }
}

export const DepartmentsApi = new DepartmentsApiInternal(getHttpClient(drugsApi + "/departments"));
