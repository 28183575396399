import React from "react";
import { observer } from "mobx-react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { DepartmentStatusNames, DepartmentTypeNames } from "typings/dictionaries";
import { DepartmentModel } from "typings/server";
import { TableItem } from "../DepartmetsPage.styles";

interface IDepartmentsTableItemProps {
  cols: string;
  department: DepartmentModel;
  onSelect?: (dep: DepartmentModel) => void;
}

export const DepartmentsTableItem = observer((props: IDepartmentsTableItemProps) => {
  const { department, cols, onSelect } = props;

  const onSelectDep = () => {
    if (onSelect) {
      onSelect(department);
    }
  };

  return (
    <TableItem data-tid="DepartmentsTableItem" selectable={!!onSelect} onClick={onSelectDep}>
      <Grid cols={cols} paddingCells={"13px 4px 16px"} key={department.id}>
        <GridCell data-tid="Address" col={1} row={1}>
          {department.address}
        </GridCell>
        <GridCell data-tid="Status" col={2} row={1}>
          {DepartmentStatusNames[department.status]}
        </GridCell>
        <GridCell data-tid="Type" col={3} row={1}>
          {DepartmentTypeNames[department.type]}
        </GridCell>
      </Grid>
    </TableItem>
  );
});
