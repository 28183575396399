import React from "react";
import {
  StatusName,
  ErrorText,
  StatusTitleBlockSM,
  StatusIconSM,
  DeliveryStatusWrapSM,
  ChildContent,
  ErrorWrap,
  LinkWrap,
  BillingErrorText,
} from "./DeliveryStatus.styles";
import { ErrorInfoModel } from "typings/server";
import { ProgressStage } from "./DeliveryStatusBlock";
import { Link, Spinner } from "components";
import { HelpDot } from "@skbkontur/react-icons";
import { MDLPErrorsInstruction, OrderMoreCodesUrl } from "StringResx";
import { SpinnerWrap } from "Common/Stages/Stages.styles";

export interface DeliveryStatusBlockProps {
  status: ProgressStage;
  name: string;
  empty?: boolean;
  error?: ErrorInfoModel;
  showSpinner?: boolean;
  spinnerCaption?: string;
}

export const DeliveryStatusBlockSM: React.FunctionComponent<DeliveryStatusBlockProps> = ({
  name,
  status,
  error,
  showSpinner,
  spinnerCaption,
}: DeliveryStatusBlockProps) => {
  if (status === ProgressStage.Planned) return null;

  return (
    <DeliveryStatusWrapSM>
      <StatusTitleBlockSM>
        <StatusIconSM status={status} hasError={!!error} size={"6px"} />
        <StatusName size={"sm"}>{name}</StatusName>
        {showSpinner && (
          <SpinnerWrap>
            <Spinner type="mini" caption={spinnerCaption || "Проверка"} />
          </SpinnerWrap>
        )}
      </StatusTitleBlockSM>
      <ChildContent>
        {error && (
          <>
            <ErrorWrap>
              <ErrorText>{error.errorDescription}</ErrorText>
              <LinkWrap>
                <Link href={MDLPErrorsInstruction} icon={<HelpDot />} target="_blank">
                  Как исправить ошибки ИС МДЛП
                </Link>
              </LinkWrap>
            </ErrorWrap>
            {error.errorDescription.includes("Ошибка отправки: недостаточно оплаченных кодов") && (
              <BillingErrorText>
                Для продления обслуживания{" "}
                <Link href={OrderMoreCodesUrl} target="_blank">
                  оставьте заявку на оплату
                </Link>
                . После продления вы сможете заново отправить сведения по этой операции в ИС МДЛП.
              </BillingErrorText>
            )}
          </>
        )}
      </ChildContent>
    </DeliveryStatusWrapSM>
  );
};
