import React, { useContext, useState } from "react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { observer } from "mobx-react";
import { GoodsScannerTableItem } from "./GoodsScannerTableItem";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { AdditionalInfoVM } from "../../AdditionalInfo/AdditionalInfoVM";
import { ExpandAllIconWrap, TableHeadCellWrap } from "../GoodsTable/GoodsTable.styles";
import { CollapseAllIcon, ExpandAllIcon } from "icons";
import { DeliveryType } from "typings/server";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";

interface IGoodsTableProps {
  goods: UtdPositionModelExtended[];
  onClick(good: UtdPositionModelExtended): void;
  editable: boolean;
  additionalInfo: CodesAdditionalInfo;
  hidePrices?: boolean;
}

export const GoodsScannerTable: React.FC<IGoodsTableProps> = observer((props: IGoodsTableProps) => {
  const { goods, onClick, editable, additionalInfo, hidePrices } = props;
  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;
  const vm = React.useMemo(() => new AdditionalInfoVM(goods, additionalInfo, false, deliveryType, extraFields), [
    goods,
  ]);

  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const cols = `3.5fr 1fr 1fr 85px ${hidePrices ? "" : "190px 140px"}`;
  return (
    <div data-tid="GoodsTable">
      <Grid cols={cols} headerBorderBottom paddingCells={"8px 5px"} paddingHeaderCells={"8px 5px"}>
        <GridCellHeader col={1} row={1}>
          <TableHeadCellWrap data-tid="ToggleAllCodes" hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
            <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
            Наименование
          </TableHeadCellWrap>
        </GridCellHeader>
        <GridCellHeader col={2} row={1} textAlign="left" padding={"8px 10px"}>
          Агрегат
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign="left" padding={"8px 10px"}>
          Штучный товар
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign="left" padding={"8px 4px 8px 10px"}>
          Кол-во, шт
        </GridCellHeader>
        {!hidePrices && (
          <>
            <GridCellHeader col={5} row={1} textAlign="right">
              Цена (с НДС) за ед. товара,&nbsp;₽
            </GridCellHeader>
            <GridCellHeader col={6} row={1} textAlign="right">
              НДС за ед. товара,&nbsp;₽
            </GridCellHeader>
          </>
        )}
      </Grid>
      {goods.map((good, index) => (
        <GoodsScannerTableItem
          good={good}
          key={index}
          cols={cols}
          onClick={onClick}
          editable={editable}
          vm={vm}
          hidePrices={hidePrices}
          allCodesExpanded={allCodesExpanded}
        />
      ))}
    </div>
  );
});
