import { createGlobalStyle } from "./styled-components";
import { colors, textColors } from "./colors";
// @ts-ignore
import notificationCss from "!!raw-loader!react-notifications-component/dist/theme.css";

export const fontFamily = `"Lab Grotesque", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif`;

export const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    padding: 0 80px;
    font-family: ${fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${textColors.default};
    background: ${colors.white};
    font-size: 14px;
    line-height: 20px;
}

h1 {
    font-weight: 300;
    font-size: 42px;
    line-height: 50px;
    margin: 0;
}

h2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
}

h3 {
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
}

h4 {
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    line-height: 25px;
}

b {
  font-weight: 600;
}

* {
  box-sizing: border-box;
}
`;

// todo: убрать, добавить свой тип сообщений
export const NotificationStyle = createGlobalStyle`
  ${notificationCss}

  .react-notification-root {
    z-index: 10000; // react-ui side page z-index = 9040
  }

  .notification-danger {
    background-color: rgba(255, 255, 255, 0.9);
    border-left: none;
    max-width: 500px;

    .notification-content .notification-title,
    .notification-content .notification-message {
      color: ${textColors.darkRed}
    }

    .notification-close {
      background-color: transparent;

      &:after {
        color: ${textColors.darkGray};
        font-size: 16px;
      }
    }

  .notification-warning {
    background-color: rgba(246, 156, 0, 0.75);
    border-left: none;
    max-width: 500px;

    .notification-close {
      background-color: transparent;
    }
   }
  }
`;
