import React from "react";
import { observer } from "mobx-react";
import { GridCell } from "components/Grid/Grid.styles";
import { DepartmentTypeNames, FinancingTypeNamesArr, IType } from "typings/dictionaries";
import { Button, Hint, Input, Select, Tooltip } from "components";
import { ItemComment, ItemWrap, RefreshIconWrap } from "./Editor.style";
import { Refresh2 } from "@skbkontur/react-icons";
import {
  ContractType,
  CounteragentDepartmentModel,
  DeliveryModel,
  DepartmentModel,
  FinancingType,
} from "typings/server";
import { EditorLabel } from "../../Circulation/Editor/EditorModal.styles";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

interface IEditorModalVM {
  departments?: DepartmentModel[];
  counteragentSelectedDepartment?: CounteragentDepartmentModel;
  errors: Map<any, string>;
  deliveryCopy: DeliveryModel;
  reportDate: string;
  maxDate: string;
  contractType?: IType;
  financingType?: IType;
  contractTypeArr: IType[];

  updateDeliveryCopy(delivery: Partial<DeliveryModel>): void;
  setSelectedDepartment?(dep: DepartmentModel): void;
  setFinancingType(type: FinancingType): void;
  onRefresh?(): void;
  setReportDate(date?: string): void;
}

export interface IFieldProps {
  vm: IEditorModalVM;
  row: number;
  margin?: string;
  padding?: string;
  required?: boolean;
}

interface IReportDateProps extends IFieldProps {
  label: string;
}

export class EditorFields extends React.Component {
  static RecipientAddress = observer((props: IFieldProps) => {
    const { vm, row, margin } = props;

    const renderAddress = (item: DepartmentModel) => item.address;

    const renderItemAddress = (item: DepartmentModel) => (
      <ItemWrap>
        {item.address}
        <br />
        <ItemComment>
          {item.type ? DepartmentTypeNames[item.type] : ""}
          {item.mdlpSubjectId ? `, № ${item.mdlpSubjectId}` : ""}
        </ItemComment>
      </ItemWrap>
    );

    const setSelectedDepartment = (dep: DepartmentModel) => vm.setSelectedDepartment && vm.setSelectedDepartment(dep);

    return (
      <GridCell row={row} col={2} margin={margin}>
        <Tooltip render={() => vm.errors.get("requiredAddress")}>
          <Select<DepartmentModel>
            data-tid="SupplierAddress"
            items={vm.departments}
            renderValue={renderAddress}
            renderItem={renderItemAddress}
            value={vm.counteragentSelectedDepartment as DepartmentModel}
            onValueChange={setSelectedDepartment}
            placeholder={vm.departments && vm.departments.length ? "Выберите нужный адрес" : "Не найдено"}
            error={!!vm.errors.get("requiredAddress")}
            maxWidth={"460px"}
            width={"100%"}
          />
        </Tooltip>

        <RefreshIconWrap>
          <Hint text={"Обновить адреса"}>
            <Button data-tid="RefreshAddresses" use={"link"} onClick={vm.onRefresh}>
              <Refresh2 />
            </Button>
          </Hint>
        </RefreshIconWrap>
      </GridCell>
    );
  });

  static DocumentDateAndNumber = observer((props: IFieldProps) => {
    const { vm, row, padding } = props;

    const onChangeDocumentNumber = (num?: string) => vm.updateDeliveryCopy({ documentNumber: num });

    const onChangeDocumentDate = (num?: string) => vm.updateDeliveryCopy({ documentDate: num });

    const getDocumentNumberError = () => vm.errors.get("requiredDocumentNumber");

    const getDocumentDateError = () => vm.errors.get("requiredDocumentDate");

    return (
      <>
        <GridCell row={row} col={1} padding={padding}>
          № документа
        </GridCell>
        <GridCell row={row} col={2}>
          <Tooltip render={getDocumentNumberError}>
            <Input
              data-tid="DocumentNumber"
              onValueChange={onChangeDocumentNumber}
              value={vm.deliveryCopy.documentNumber}
              error={!!getDocumentNumberError()}
              width="120px"
            />
          </Tooltip>
        </GridCell>

        <GridCell row={row + 1} col={1}>
          Дата документа
        </GridCell>
        <GridCell row={row + 1} col={2}>
          <DateTimePicker
            data-tid="DocumentDate"
            value={vm.deliveryCopy.documentDate || ""}
            onChange={onChangeDocumentDate}
            getReportDateError={getDocumentDateError}
            withoutTime
          />
        </GridCell>
      </>
    );
  });

  static ReportDate = observer((props: IReportDateProps) => {
    const { vm, row, label, margin } = props;

    const getReportDateError = () => vm.errors.get("invalidReportDate");

    return (
      <>
        <GridCell row={row} col={1} margin={margin}>
          {label}
        </GridCell>
        <GridCell row={row} col={2}>
          <DateTimePicker
            data-tid="ReportDate"
            value={vm.reportDate}
            onChange={vm.setReportDate}
            getReportDateError={getReportDateError}
            maxDate={vm.maxDate}
          />
        </GridCell>
      </>
    );
  });

  static ContractType = observer((props: IFieldProps) => {
    const { vm, row } = props;

    const renderValue = (item: IType) => {
      // select does't reset value
      if (!vm.contractType) return "";
      return item?.label;
    };

    const renderItem = (item: IType) => item?.label;

    const onChangeContractType = (type: IType) => vm.updateDeliveryCopy({ contractType: type.value as ContractType });

    const getContractTypeErr = () => vm.errors.get("requiredContractType");

    return (
      <>
        <GridCell row={row} col={1}>
          Тип договора
        </GridCell>
        <GridCell row={row} col={2}>
          <Tooltip render={getContractTypeErr}>
            <Select<IType>
              data-tid="ContractType"
              items={vm.contractTypeArr}
              onValueChange={onChangeContractType}
              value={vm.contractType}
              renderValue={renderValue}
              renderItem={renderItem}
              placeholder={"Выберите"}
              width={"250px"}
              error={!!getContractTypeErr()}
            />
          </Tooltip>
        </GridCell>
      </>
    );
  });

  static FinancingType = observer((props: IFieldProps) => {
    const { vm, row } = props;

    const renderValue = (item: IType) => item.label;

    const onChangeFinancingType = (type: IType) => vm.setFinancingType(type.value as FinancingType);

    const getFinancingTypeError = () => vm.errors.get("requiredFinancingType");

    return (
      <>
        <GridCell row={row} col={1}>
          Источник финансирования
        </GridCell>
        <GridCell row={row} col={2}>
          <Tooltip render={getFinancingTypeError}>
            <Select<IType>
              data-tid="FinancingType"
              items={FinancingTypeNamesArr}
              value={vm.financingType}
              renderValue={renderValue}
              renderItem={renderValue}
              onValueChange={onChangeFinancingType}
              placeholder={"Выберите"}
              width={"250px"}
              error={!!getFinancingTypeError()}
            />
          </Tooltip>
        </GridCell>
      </>
    );
  });

  static ContractNumber = observer((props: IFieldProps) => {
    const { vm, row, required } = props;

    const onChangeContractNumber = (num?: string) => vm.updateDeliveryCopy({ contractNumber: num });

    const getContractNumError = () => vm.errors.get("requiredContractNumber");

    return (
      <>
        <GridCell row={row} col={1}>
          <EditorLabel required={required}>Реестровый № контракта</EditorLabel>
        </GridCell>
        <GridCell row={row} col={2}>
          <Tooltip render={getContractNumError}>
            <Input
              data-tid="ContractNumber"
              onValueChange={onChangeContractNumber}
              value={vm.deliveryCopy.contractNumber}
              error={!!getContractNumError()}
            />
          </Tooltip>
        </GridCell>
      </>
    );
  });
}
