import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Loader, Modal, Select, Tooltip } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { ErrorText, HintText, ModalWrapper } from "Common/Editor/Editor.style";
import { AcceptanceEditorVM } from "./AcceptanceEditorVM";
import { ICertificate } from "@skbkontur/plugin-js";
import { TextGrey } from "styles/shared.styles";
import { AcceptanceStore } from "stores/AcceptanceStore";
import { UserStore } from "stores/UserStore";
import { IType, ReceiveTypeNamesArr } from "typings/dictionaries";
import { SignatureStore } from "stores/SignatureStore";
import { MdlpModal } from "../../../../CertificatesList/MdlpModal";
import { EditorFields } from "Common/Editor/EditorFields";
import { AcceptusType, ReceiveType } from "typings/server";
import { UtdTitle } from "features/Acceptance/MainPage/UtdTitle/UtdTitle";

interface IEditorModalProps {
  onClose(): void;
  onComplete?(): void;
  errorText?: string;
  consignorInn: string;
}

interface IEditorModalInternalProps extends IEditorModalProps {
  vm: AcceptanceEditorVM;
}

export const EditorModalInternal: React.FC<IEditorModalInternalProps> = observer((props: IEditorModalInternalProps) => {
  const { vm, errorText, onComplete } = props;

  const onClose = () => props.onClose();

  useEffect(() => {
    if (errorText) {
      vm.validate();
    }
  }, [vm, errorText]);

  const onCloseCertListModal = () => {
    vm.setNeedToSign(false);
  };

  const onSign = async (cert: ICertificate) => {
    await vm.onSign(cert);
    await vm.findDepartments();
  };

  const renderValue = (item: IType) => item.label;

  const onChangeReceiveType = (type: IType) =>
    vm.updateDeliveryCopy({ ...vm.deliveryCopy, receiveType: type.value as ReceiveType });

  const onSaveAndValidate = async () => {
    vm.setSubmitted(true);
    vm.validate();
    if (vm.isValid) {
      await vm.save();
      if (onComplete) onComplete();
      updateUrlSearchParams();
      onClose();
    }
  };

  const onFillOutLater = () => {
    updateUrlSearchParams();
    onClose();
  };

  const getReceiveTypeErr = () => vm.errors.get("requiredReceiveType");

  const updateUrlSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("new");
    window.history.replaceState({}, document.title, url.href);
  };

  if (vm.needToSign) {
    return <MdlpModal onClose={onCloseCertListModal} title={"Доступ в ИС МДЛП"} sign={onSign} />;
  }

  return (
    <Modal data-tid="EditorModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>
        <UtdTitle isSimplified={vm.deliveryCopy.acceptusType === AcceptusType.Simplified} />
      </Modal.Header>
      <Modal.Body>
        <ModalWrapper>
          <HintText>
            {errorText ||
              (!!vm.errors.size && (
                <ErrorText>{errorText || "Для добавления приемки заполните оставшиеся поля"}</ErrorText>
              ))}
          </HintText>
          <Loader data-tid="Loader" active={vm.loading} minHeight="500px">
            <Grid alignItems={"baseline"} cols={"200px 1fr"}>
              <GridCell row={1} col={1}>
                Отправитель
              </GridCell>
              <GridCell row={1} col={2}>
                <span data-tid="SupplierName">{vm.deliveryCopy.supplier?.name}</span>
                <br />
                <TextGrey data-tid="SupplierAddress">{vm.consignorOrSupplierAddress}</TextGrey>
              </GridCell>

              {vm.isSimplified && (
                <>
                  <GridCell row={2} col={1}>
                    ИНН отправителя
                  </GridCell>
                  <GridCell data-tid="SupplierInn" row={2} col={2} margin={"0 0 5px"}>
                    {vm.deliveryCopy.supplier?.inn}
                  </GridCell>
                  <GridCell row={3} col={1}>
                    КПП отправителя
                  </GridCell>
                  <GridCell data-tid="SupplierKpp" row={3} col={2} margin={"0 0 5px"}>
                    {vm.deliveryCopy.supplier?.kpp || "—"}
                  </GridCell>
                </>
              )}

              {!vm.isSimplified && (
                <>
                  <GridCell row={4} col={1}>
                    Адрес отправителя
                  </GridCell>
                  <EditorFields.RecipientAddress row={4} vm={vm} />
                </>
              )}

              <GridCell row={5} col={1}>
                Получатель
              </GridCell>
              <GridCell row={5} col={2} margin={"0 0 16px"}>
                <span data-tid="RecipientName">{vm.companyName}</span>
                <br />
                <TextGrey data-tid="RecipientAddress">{vm.currentUserDepartment?.address}</TextGrey>
              </GridCell>

              <EditorFields.DocumentDateAndNumber row={6} vm={vm} />

              <EditorFields.ReportDate row={8} vm={vm} label={"Дата и время приемки"} margin={"0 0 40px"} />

              <GridCell row={9} col={1}>
                Тип операции
              </GridCell>
              <GridCell row={9} col={2}>
                <Tooltip render={getReceiveTypeErr}>
                  <Select<IType>
                    data-tid="ReceiveType"
                    items={ReceiveTypeNamesArr.filter(
                      x => vm.deliveryCopy.acceptusType === AcceptusType.Simplified || x.value !== ReceiveType.Regrading
                    )}
                    value={vm.receiveType}
                    renderValue={renderValue}
                    renderItem={renderValue}
                    onValueChange={onChangeReceiveType}
                    error={!!getReceiveTypeErr()}
                    placeholder={"Выберите"}
                    width={"250px"}
                  />
                </Tooltip>
              </GridCell>

              <EditorFields.FinancingType row={10} vm={vm} />

              <EditorFields.ContractType row={11} vm={vm} />

              <EditorFields.ContractNumber row={12} vm={vm} />
            </Grid>
          </Loader>
        </ModalWrapper>
      </Modal.Body>

      <Modal.Footer panel sticky>
        <FooterBtnWrapper>
          <Button data-tid="Submit" use="primary" size={"large"} onClick={onSaveAndValidate}>
            Сохранить
          </Button>
          <Button data-tid="Cancel" onClick={onFillOutLater} size={"large"}>
            Отменить
          </Button>
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  );
});

export const AcceptanceEditor = (props: IEditorModalProps) => {
  const vm = new AcceptanceEditorVM(AcceptanceStore, UserStore, SignatureStore, props.consignorInn);
  return <EditorModalInternal vm={vm} {...props} />;
};
