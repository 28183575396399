import styled from "styles/styled-components";

export const InfoText = styled.div`
  margin-bottom: 2px;
`;

export const ErrorText = styled.div`
  word-break: break-word;
  margin-bottom: 8px;
`;

export const ErrorWrap = styled.span`
  cursor: pointer;
  margin-right: 15px;
  color: ${props => props.theme.textColors.red};
`;

export const TooltipContent = styled.div`
  max-width: 530px;
`;
