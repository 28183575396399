import React from "react";
import { ReactComponent as RecalledIcon } from "./cross-status-icon.svg";

interface IRecalledStatusIconProps extends React.ComponentProps<any> {
  size?: "default" | "medium" | "large";
}
const largeProps = { width: "25px", height: "20px", viewBox: "0 0 14 14" };

export const RecalledStatusIcon: React.FC<IRecalledStatusIconProps> = (props: IRecalledStatusIconProps) => {
  const { size } = props;
  let iconProps = { ...props, ...(size === "large" && largeProps) };

  // @ts-ignore
  return <RecalledIcon {...iconProps} />;
};
