import { action, computed, observable, toJS } from "mobx";
import {
  CounteragentDepartmentModel,
  DeliveryItemModel,
  DeliveryItemStatus,
  DeliveryModel,
  DeliveryStatus,
  StepInfoModel,
} from "typings/server";
import { DeliveryStage, TransferRecallStageNames, TransferStageNames } from "features/Common/DeliveryDictionary";
import { DeliveryItemModelExtended } from "./ItemModel/DeliveryItemModelExtended";
import { DeliveryActions } from "./DeliveryModelActions";
import { IMappedStepInfoModel, IStage } from "./shared";
import { CodeModelExtended } from "../Code/CodeModelExtended";

export class TransferDeliveryModel extends DeliveryModel {
  @observable stages: Record<DeliveryStage | string, IStage> = Object.create({});
  @observable recallStages: Record<DeliveryStage | string, IStage> = Object.create({});
  @observable status: DeliveryStatus;
  @observable reportDate: string | undefined;
  @observable counteragentDepartment?: CounteragentDepartmentModel;
  @observable documentDate: string;
  @observable documentNumber?: string;
  @observable items: DeliveryItemModelExtended[];
  @observable isRecalled: boolean;
  @observable stepsInfo: { [key in DeliveryStatus]: StepInfoModel } = Object.create({});
  @observable childDeliveries: TransferDeliveryModel[] = [];
  private readonly actions: DeliveryActions;

  constructor(readonly deliveryModel: DeliveryModel) {
    super();
    Object.assign(this, deliveryModel);
    this.actions = new DeliveryActions(deliveryModel);

    // группировка кодов по имени
    if (deliveryModel.items) {
      const groupByName = this.getGroupedCodes(deliveryModel.items, "name");
      this.items = this.processItems(deliveryModel.items, groupByName);
    }

    // шаги поставки
    if (deliveryModel.stepsInfo) {
      const mappedSteps = this.mapSteps(deliveryModel.stepsInfo);
      this.setStages(mappedSteps);
    }

    if (this.childDeliveries?.length) {
      this.childDeliveries = this.childDeliveries.map(child => new TransferDeliveryModel(child));
    } else this.childDeliveries = [];

    this.recursiveUnpacking = deliveryModel.recursiveUnpacking || false;
    //шаги отмены
    const recalledChild = this.childDeliveries.find(
      child =>
        child.allCodesLen === child.recalledCodesLen ||
        (child.status === DeliveryStatus.RecallFailed && child.allCodesLen === child.failedCodesLen)
    );

    this.isRecalled = !!recalledChild;
    if (recalledChild?.stepsInfo) {
      const recallMappedSteps = this.mapSteps(recalledChild.stepsInfo);
      this.setRecallStages(recallMappedSteps);
    }
  }

  /**
   * добавляем к stepsInfo информацию о шаге приемки
   * @param steps
   */
  private mapSteps(steps: { [key in DeliveryStatus]: StepInfoModel }): IMappedStepInfoModel[] {
    return this.actions.mapSteps(steps);
  }

  /**
   * получаем из stepsInfo шаги приемки для отображения в интерфейсе
   * @param mappedSteps
   */
  @action
  private setStages(mappedSteps: IMappedStepInfoModel[]) {
    const isCompleted = this.isRecalled;
    this.stages = this.actions.getStages(mappedSteps, TransferStageNames, { isCompleted });
  }

  @action
  private setRecallStages(recallMappedSteps: IMappedStepInfoModel[]) {
    const isPlanned = !this.isRecalled;
    this.recallStages = this.actions.getStages(recallMappedSteps, TransferRecallStageNames, {
      isCompleted: false,
      isPlanned,
    });
  }

  @action
  setNextStage(status: DeliveryStatus, stageName: DeliveryStage, stage: Partial<IStage>, userName: string) {
    // old status
    const info = { status: this.status, completionDate: stage.completionDate, userName };
    // update status
    this.status = status;

    if (this.isRecalled) {
      this.recallStages = this.actions.setNextStage(this.recallStages, stageName, stage);
    } else {
      this.stages = this.actions.setNextStage(this.stages, stageName, stage);
      this.stepsInfo = this.actions.updateStepInfo(this.stepsInfo, info);
    }
  }

  /**
   * группировка кодов по имени товара, либо по id
   * т.к. для одного товара 2 кода - 2 элемента массива с одном именем/id
   * @param items
   * @param groupBy
   */
  private getGroupedCodes(items: DeliveryItemModel[], groupBy: string): Record<string, CodeModelExtended[]> {
    return this.actions.getGroupedCodes(items, { groupBy, isAllScanned: false, showGtin: true });
  }

  private processItems(
    goods: DeliveryItemModel[],
    codeGroup: Record<string, CodeModelExtended[]>
  ): DeliveryItemModelExtended[] {
    return this.actions.processItems(goods, codeGroup, { showGtin: true });
  }

  @action
  setReportDate(val: string | undefined) {
    this.reportDate = val;
  }

  @action
  updateDelivery(delivery: DeliveryModel) {
    if (delivery.documentDate) this.documentDate = delivery.documentDate;
    this.documentNumber = delivery.documentNumber;
    this.counteragentDepartment = delivery.counteragentDepartment;
    this.setReportDate(delivery.reportDate);
  }

  @action
  updateItems(items: DeliveryItemModelExtended[]) {
    this.items = items;
  }

  @action
  setStatus(status: DeliveryStatus) {
    this.status = status;
  }

  @action
  setRecalled(val: boolean) {
    this.isRecalled = val;
  }

  @computed
  get allCodesLen() {
    return this.items.reduce((sum: number, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length), 0);
  }

  @computed
  get allItemsCodes(): string[] {
    return this.items.map(item => item.allCodes.map(codeItem => codeItem.code)).flat();
  }

  // поставка активна, если с ней можно совершить действие и у нее нет подпоставок
  @computed
  get isActive(): boolean {
    const editable = [
      DeliveryStatus.Processing,
      DeliveryStatus.New,
      DeliveryStatus.Signing,
      DeliveryStatus.Signed,
      DeliveryStatus.CreatingDocument,
    ];
    return editable.includes(this.status) && this.childDeliveries.length === 0 && !this.isRecalled;
  }

  @action
  addChildDelivery(deliveryId?: string) {
    const delivery = new TransferDeliveryModel({
      ...toJS(this.deliveryModel),
      items: [],
      status: DeliveryStatus.Processing,
      childDeliveries: [],
      id: deliveryId || "",
      reportDate: undefined,
    });
    this.childDeliveries.push(delivery);
  }

  @computed
  get acceptedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Accepted);
  }

  @computed
  get failedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Error);
  }

  @computed
  get recalledCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Recalled);
  }

  private getDeliveryCodesLenByStatus(status: DeliveryItemStatus): number {
    return this.items.reduce(
      (count: number, item: DeliveryItemModelExtended) =>
        (count += item.status === status && item.allCodes ? item.allCodes.length : 0),
      0
    );
  }
}
