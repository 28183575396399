import React, { SyntheticEvent } from "react";
import { getFormattedDate } from "helpers/date";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { AcceptanceTypesNames } from "typings/dictionaries";
import { StatusItem } from "Common/Status/StatusItem";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { DeleteWrap, DeliveryListRow } from "Common/DeliveriesList/DeliveriesList.styles";
import TrashIcon from "@skbkontur/react-icons/Trash";

interface AcceptanceDeliveriesListItemProps {
  delivery: DeliveryIndexModel;
  onClick: (doc: DeliveryIndexModel) => void;
  onDelete: (e: SyntheticEvent<HTMLElement>, delivery: DeliveryIndexModel) => void;
  cols: string;
  type: DeliveryType;
  isCanBeDeleted: boolean;
}

export const DeliveriesListItem = ({
  cols,
  type,
  delivery,
  isCanBeDeleted,
  onDelete,
  onClick,
}: AcceptanceDeliveriesListItemProps) => {
  const { status, counteragentName, documentNumber, date, acceptusType } = delivery;
  const handleRowClick = () => onClick(delivery);
  const handleOpenDeleteModal = (e: SyntheticEvent<HTMLElement>) => onDelete(e, delivery);

  return (
    <DeliveryListRow onClick={handleRowClick}>
      <Grid cols={cols}>
        <GridCell data-tid="CounteragentName" col={1} row={1} margin="0 0 0 6px">
          {counteragentName || "Без названия"}
        </GridCell>
        <GridCell data-tid="DocumentNumber" col={2} row={1} textAlign="left">
          {documentNumber}
        </GridCell>
        <GridCell data-tid="DocumentDate" col={3} row={1} textAlign="left">
          {date && getFormattedDate(date)}
        </GridCell>
        <GridCell data-tid="AcceptusType" col={4} row={1} textAlign="left" margin="0 0 0 9px">
          {acceptusType && AcceptanceTypesNames[acceptusType]}
        </GridCell>
        <GridCell col={5} row={1} textAlign="left" margin="0 0 0 9px">
          <StatusItem type={type} status={status} />
        </GridCell>
        <GridCell col={6} row={1} textAlign="center">
          {isCanBeDeleted && (
            <DeleteWrap data-tid="DeleteIcon" onClick={handleOpenDeleteModal}>
              <TrashIcon />
            </DeleteWrap>
          )}
        </GridCell>
      </Grid>
    </DeliveryListRow>
  );
};
