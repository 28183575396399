import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { AcceptanceRoute } from "routes";
import { AcceptanceStore } from "stores/AcceptanceStore";
import { SignatureStore } from "stores/SignatureStore";
import { Loader } from "components/Loader";
import { AcceptusType, DeliveryModel, DeliveryType } from "typings/server";
import { ReverseAcceptancePage } from "./ReverseAcceptance/ReverseAcceptancePage";
import { DirectAcceptancePage } from "./DirectAcceptance/DirectAcceptancePage";
import { ReverseAcceptancePageVM } from "./ReverseAcceptance/ReverseAcceptancePageVM";
import { DirectAcceptancePageVM } from "./DirectAcceptance/DirectAcceptancePageVM";
import { UserStore } from "stores/UserStore";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";

export const DetailsPageWrapper = (props: RouteComponentProps<AcceptanceRoute>) => {
  const { match, history } = props;
  const id = match.params.deliveryId;
  const [loading, setLoading] = useState<boolean>(true);
  const [delivery, setDelivery] = useState<DeliveryModel | null>(null);

  useEffect(() => {
    if (id) {
      AcceptanceStore.getItem(id, { force: true })
        .then(delivery => {
          // redirect to parent delivery
          if (delivery.parentDeliveryId) {
            history.replace(delivery.parentDeliveryId);
          }
          AcceptanceStore.setSelected(delivery);
          setDelivery(delivery);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) return <Loader active />;
  if (!delivery) return <>Поставка не найдена</>;

  const params = new URLSearchParams(window.location.search);
  const isNew = params.has("new");

  if (
    delivery.acceptusType === AcceptusType.Direct ||
    delivery.acceptusType === AcceptusType.StateDispatch ||
    delivery.acceptusType === AcceptusType.SuspendedReturn
  )
    return (
      <DeliveryTypeContext.Provider value={delivery.type}>
        <DirectAcceptancePage
          vm={new DirectAcceptancePageVM(AcceptanceStore, SignatureStore, UserStore, DeliveryType.Acceptance)}
          {...props}
        />
      </DeliveryTypeContext.Provider>
    );

  return (
    <DeliveryTypeContext.Provider value={delivery.type}>
      <ReverseAcceptancePage
        vm={new ReverseAcceptancePageVM(AcceptanceStore, SignatureStore, UserStore, DeliveryType.Acceptance, isNew)}
        {...props}
      />
    </DeliveryTypeContext.Provider>
  );
};
