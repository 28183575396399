import React from "react";
import { observer } from "mobx-react";
import { Button, Input, Loader, Modal, Select, Tooltip } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { ShipmentStore } from "stores/ShipmentStore";
import { UserStore } from "stores/UserStore";
import { ErrorText, HintText, ModalWrapper } from "Common/Editor/Editor.style";
import { ShipmentEditorFields } from "../Direct/ShipmentEditorFields";
import { EditorFields } from "Common/Editor/EditorFields";
import { ShipmentEAEUCountriesEditorVM } from "./ShipmentEAEUCountriesEditorVM";
import { EAEUCountriesArr, IType } from "typings/dictionaries";

interface IEditorModalProps {
  onClose(): void;
  errorText?: string;
  onComplete?(): Promise<void>;
}

interface IEditorModalInternalProps extends IEditorModalProps {
  vm: ShipmentEAEUCountriesEditorVM;
}

export const EditorModalInternal: React.FC<IEditorModalInternalProps> = observer((props: IEditorModalInternalProps) => {
  const { vm, onComplete, errorText } = props;

  const onClose = () => props.onClose();

  const onSave = async () => {
    await vm.onSave();
    if (onComplete) onComplete();
    updateUrlSearchParams();
    onClose();
  };

  const fillOutLater = () => {
    updateUrlSearchParams();
    onClose();
  };

  const updateUrlSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("new");
    window.history.replaceState({}, document.title, url.href);
  };

  const renderValue = (item: IType) => item.label;

  const renderCountryErr = () => vm.errors.get("requiredCountry");

  const renderInnErr = () => vm.errors.get("requiredInn");

  return (
    <Modal data-tid="EditorModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>Новая отгрузка в страну ЕАЭС</Modal.Header>
      <Modal.Body>
        <ModalWrapper>
          <HintText>
            {errorText ||
              (!!vm.errors.size && (
                <ErrorText>{errorText || "Для добавления отгрузки заполните оставшиеся поля"}</ErrorText>
              ))}
          </HintText>
          <Loader data-tid="Loader" active={vm.loading} minHeight="400px">
            <Grid alignItems={"baseline"} cols={"230px 1fr"}>
              <ShipmentEditorFields.Supplier vm={vm} row={1} />

              <ShipmentEditorFields.Recipient vm={vm} row={2} />

              <GridCell row={3} col={1}>
                Страна получателя
              </GridCell>
              <GridCell row={3} col={2}>
                <Tooltip render={renderCountryErr}>
                  <Select<IType>
                    data-tid="Country"
                    items={EAEUCountriesArr}
                    renderValue={renderValue}
                    renderItem={renderValue}
                    width={"250px"}
                    placeholder={"Выберите"}
                    onValueChange={vm.setCountry}
                    value={vm.country}
                    error={!!renderCountryErr()}
                  />
                </Tooltip>
              </GridCell>

              <GridCell row={4} col={1} alignSelf={"start"}>
                Код налогоплательщика в стране регистрации (аналог ИНН)
              </GridCell>
              <GridCell row={4} col={2}>
                <Tooltip render={renderInnErr}>
                  <Input
                    data-tid="ForeignInn"
                    width={"250px"}
                    placeholder={"Введите код налогоплательщика"}
                    value={vm.inn}
                    onValueChange={vm.setInn}
                    error={!!renderInnErr()}
                  />
                </Tooltip>
              </GridCell>

              <EditorFields.DocumentDateAndNumber vm={vm} row={6} padding={"16px 4px"} />

              <EditorFields.ReportDate vm={vm} row={8} label={"Дата и время отгрузки"} margin={"0 0 40px"} />

              <EditorFields.ContractType vm={vm} row={10} />
            </Grid>
          </Loader>
        </ModalWrapper>
      </Modal.Body>

      <Modal.Footer panel sticky>
        <FooterBtnWrapper>
          <Button data-tid="Submit" use="primary" size={"large"} onClick={onSave}>
            Сохранить
          </Button>
          <Button data-tid="Cancel" size={"large"} onClick={fillOutLater}>
            Отменить
          </Button>
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  );
});

export const ShipmentEAEUCountiesEditor = (props: IEditorModalProps) => {
  const vm = new ShipmentEAEUCountriesEditorVM(ShipmentStore, UserStore, props.errorText);
  return <EditorModalInternal vm={vm} {...props} />;
};
