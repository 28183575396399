import { action, computed, observable } from "mobx";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { IUserStore } from "stores/UserStore";
import { IWithdrawalStore } from "stores/WithdrawalStore";

export type TBaseErrors = "invalidDocDate" | "docDateRequired" | "docNumberRequired";

export abstract class BaseEditorModalVM<TError> {
  @observable documentDate: string = "";
  @observable documentNumber: string = "";
  @observable errors: Map<TError, string> = new Map();
  @observable isSubmitted: boolean = false;

  readonly isNew: boolean = true;
  readonly maxDate: string = new Date().toISOString();

  constructor(
    protected readonly store: IWithdrawalStore,
    protected readonly userStore: IUserStore,
    protected readonly delivery?: WithdrawalDeliveryModel
  ) {
    if (delivery) {
      this.setByDelivery(delivery);
      this.isNew = false;
    }
  }

  @computed
  get companyName(): string {
    return this.userStore.organization?.name || "";
  }

  @computed
  get currentDepartmentAddress(): string {
    if (this.delivery) return this.delivery.supplier?.address || "";
    return this.userStore.currentDepartment?.address || "";
  }

  abstract validate(): boolean;

  abstract setByDelivery(delivery: WithdrawalDeliveryModel): void;

  @action
  setDocumentDate = (date?: string) => {
    this.documentDate = date || "";
    if (this.isSubmitted) {
      this.validate();
    }
  };

  @action
  setDocumentNumber = (num: string) => {
    this.documentNumber = num.trim();
    if (this.isSubmitted) this.validate();
  };
}
