//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PortalUserInfo
{
	public lastName: string;
	public firstName: string;
	public middleName: string;
	public email: string;
	public farmaId: string;
}
export class UserPrivilegesRequest
{
	public email: string;
	public isSuperUser: boolean;
	public isSuperAdmin: boolean;
}
export class CodeErrorModel
{
	public code: string;
	public errorCode: number;
	public description: string;
}
export class CodesInfoRequest
{
	public deliveryId: string;
	public codes: string[];
}
export class CodesRequest
{
	public codes: string[];
}
export class PartialDisposalModel
{
	public soldPart?: SoldPartModel;
	public packageSize?: number;
}
export class SgtinInfoModel
{
	public code: string;
	public name: string;
	public batch: string;
	public releaseDate?: string;
	public expirationDate?: string;
	public circulationEntryDate?: string;
	public containingSscc?: string;
	public producer?: string;
	public containingElementaryCount?: number;
}
export class SoldPartModel
{
	public numerator: number;
	public denominator: number;
}
export class SsccInfoModel
{
	public code: string;
	public name: string;
	public containedSsccCount: number;
	public containedSgtinsCount: number;
	public gtin: string;
	public producer?: string;
	public childCodesInfo: any[];
}
export class CounteragentInfoModel
{
	public name: string;
	public inn: string;
	public kpp?: string;
	public address: string;
	public addressFias?: string;
	public country?: Country;
}
export class CounteragentModel
{
	public organizationId: string;
	public isRegisteredInMdlp: boolean;
	public departments: DepartmentModel[];
}
export class CounteragentDepartmentModel
{
	public id: string;
	public address: string;
}
export class DeliveriesFilterModel
{
	public documentNumber?: string;
	public sscc?: string;
	public allowedCounteragents?: string[];
	public allowedAcceptusTypes?: AcceptusType[];
	public allowedStatuses?: DeliveryStatus[];
	public allowedTypes?: DeliveryType[];
	public allowedWithdrawalReasons?: WithdrawalReason[];
	public allowedWithdrawalCauses?: WithdrawalCause[];
	public allowedReturnReasons?: ReturnReason[];
	public allowedDestinations?: string[];
	public startFrom?: number;
	public count?: number;
}
export class DeliveriesModel
{
	public deliveries: DeliveryIndexModel[];
	public totalSize: number;
	public presenceInfo: PresenceInfoModel;
}
export class DeliveryIndexModel
{
	public id: string;
	public type: DeliveryType;
	public counteragentName?: string;
	public documentNumber?: string;
	public document2Number?: string;
	public document3Number?: string;
	public date?: string;
	public acceptusType?: AcceptusType;
	public status?: DeliveryStatus;
	public unpackingSscc?: string;
	public containingSsccCount?: number;
	public containingSgtinCount?: number;
	public destination?: string;
	public withdrawalReason?: WithdrawalReason;
	public withdrawalCause?: WithdrawalCause;
	public returnReason?: ReturnReason;
	public extractingDrug?: string;
	public extractingCount?: number;
	public codesSyncStatus?: CodesSyncStatus;
	public codesSyncDateTimeUtc?: string;
	public codesSyncHasFailed?: boolean;
}
export class DeliveryItemCodeStatusModel
{
	public firstSyncedDateTimeUtc: string;
	public lastSyncedDateTimeUtc: string;
	public mdlpStatus: string;
	public syncStatus: CodeSyncStatus;
}
export class DeliveryItemModel
{
	public positionId?: string;
	public name?: string;
	public code: string;
	public dataMatrix?: string;
	public status?: DeliveryItemStatus;
	public statusInfo?: DeliveryItemStatusInfoModel;
	public codeStatusInfo?: DeliveryItemCodeStatusModel;
	public cost?: number;
	public vat?: number;
	public itemsCount?: number;
	public part?: number;
	public totalParts?: number;
}
export class DeliveryItemStatusInfoModel
{
	public reason: string;
	public date?: string;
}
export class DepartmentCountersModel
{
	public departmentId: string;
	public newShipmentsCount: number;
	public newAccepancesCount: number;
	public newDestructionsCount: number;
}
export class ErrorInfoModel
{
	public errorDescription: string;
}
export class NewDeliveriesCountModel
{
	public departmentsCounters: DepartmentCountersModel[];
}
export class PatchDeliveryModel
{
	public documentNumber: string;
	public documentDate?: string;
	public documentCode: string;
	public documentCodeName: string;
	public counteragentDepartmentId: string;
	public contractType?: ContractType;
	public contractNumber: string;
	public turnoverType?: TurnoverType;
	public receiveType?: ReceiveType;
	public financingType?: FinancingType;
	public recursiveUnpacking?: boolean;
	public reportDate?: string;
	public withdrawalReason?: WithdrawalReason;
	public withdrawalCause?: WithdrawalCause;
	public returnReason?: ReturnReason;
	public counteragentOrganizationId: string;
	public unregisteredCounteragent?: boolean;
	public counteragentCountry?: Country;
	public counteragentInn: string;
	public registratorId: string;
}
export class PresenceInfoModel
{
	public types: DeliveryType[];
	public statuses: DeliveryStatus[];
	public acceptusTypes: AcceptusType[];
	public withdrawalReasons: WithdrawalReason[];
	public withdrawalCauses: WithdrawalCause[];
	public returnReasons: ReturnReason[];
	public counteragents: string[];
	public destinations: string[];
}
export class StepInfoModel
{
	public completionDate: string;
	public completionUserName?: string;
	public errorInfo?: ErrorInfoModel;
}
export class SyncInfoModel
{
	public deliveriesSyncStatusTime?: string;
	public deliveriesSyncStatusResult?: SyncResult;
	public deliveriesSyncStatusError?: string;
}
export class UtdPositionModel
{
	public id: string;
	public name: string;
	public cost: number;
	public vat: number;
	public quantity: number;
	public sum: number;
}
export class DepartmentModel
{
	public id: string;
	public name?: string;
	public address: string;
	public status: DepartmentStatus;
	public type: DepartmentType;
	public mdlpSubjectId: string;
}
export class DepartmentsModel
{
	public departments: DepartmentModel[];
	public syncTime?: string;
}
export class DestructModel
{
	public operationDate?: string;
	public destructionMethod?: DestructionMethod;
	public actNumber: string;
	public actDate: string;
}
export abstract class ModifyDestructionModelBase
{
	public operationDate?: string;
	public inn: string;
	public kpp: string;
	public address: string;
	public addressFias: string;
	public documentNumber: string;
	public documentDate?: string;
	public actNumber: string;
	public actDate?: string;
}
export class EmployeeModel
{
	public id: string;
	public fio: string;
	public email?: string;
	public currentUser: boolean;
	public role: EmployeeRole;
	public availableDepartments?: DepartmentModel[];
	public portalId: string;
}
export abstract class EmployeeModificationModelBase
{
	public isAdmin: boolean;
	public allDepartments: boolean;
	public availableDepartments?: string[];
}
export class CreateDisposalModel
{
	public operationDate?: string;
	public documentNumber: string;
	public documentDate?: string;
}
export class CreateDisposalWithRegistratorModel
{
	public documentNumber: string;
	public documentDate?: string;
	public documentCode?: string;
	public documentCodeName: string;
	public registratorId: string;
}
export class CreateRecallModel
{
	public deliveryId: string;
	public operationDate?: string;
	public reason?: string;
}
export class CreateReentryModel
{
	public operationDate?: string;
	public withdrawalCause?: WithdrawalCause;
	public returnReason?: ReturnReason;
	public documentNumber?: string;
	public documentDate?: string;
}
export class CreateRefusalModel
{
	public deliveryId: string;
	public operationDate?: string;
	public reason: string;
	public confirmPaused?: boolean;
}
export class CreateTrasferenceModel
{
	public destinationDepartmentId: string;
	public operationDate?: string;
	public documentNumber: string;
	public documentDate?: string;
}
export class CreateWithdrawalModel
{
	public reason?: WithdrawalReason;
	public operationDate?: string;
	public documentNumber?: string;
	public documentDate?: string;
}
export class NewDeliveryModel
{
	public id: string;
	public documentNumber?: string;
	public documentDate?: string;
	public supplier: CounteragentInfoModel;
	public recipient: CounteragentInfoModel;
}
export abstract class OperationModelBase
{
	public id: string;
	public status: DeliveryStatus;
	public stepsInfo: { [key in DeliveryStatus]: StepInfoModel };
	public hasDocuments: boolean;
}
export class PostAuthCodeModel
{
	public code: string;
	public signature: string;
}
export class CodeModel
{
	public code: string;
	public seriesNumber?: string;
}
export class CodesReportModel
{
	public deliveryId: string;
	public reportDate?: string;
	public confirmPaused?: boolean;
	public codes?: string[];
	public allIncomingCodes?: boolean;
	public rejectUnscanned?: boolean;
	public rejectReason?: string;
}
export class DeliveryItemDisposalReportModel
{
	public dataMatrix: string;
	public name?: string;
	public part?: number;
	public totalParts?: number;
}
export class DeliveryItemReportModel
{
	public code: string;
	public name?: string;
	public part?: number;
	public totalParts?: number;
}
export class PositionReportModel
{
	public positionId: string;
	public codeInfo: CodeModel[];
}
export class PositionsReportModel
{
	public deliveryId: string;
	public positionReports: PositionReportModel[];
}
export class RequisitesModel
{
	public memberId: string;
	public clientId: string;
	public secret: string;
}
export class TaskModel
{
	public id: string;
	public state: TaskState;
	public result?: string;
}
export class MobileTokenModel
{
	public token: string;
	public lifetimeMs: number;
}
export class OrganizationModel
{
	public id: string;
	public name: string;
	public inn: string;
}
export class UserInfoModel
{
	public firstName?: string;
	public lastName?: string;
	public middleName?: string;
	public email?: string;
	public role?: EmployeeRole;
	public chosenOrganizationId?: string;
	public chosenDepartmentId?: string;
	public availableOrganizations?: OrganizationModel[];
	public availableDepartments?: DepartmentModel[];
	public needConfiguration: boolean;
	public hasView: boolean;
}
export abstract class OperationWithChildrenModelBase extends OperationModelBase
{
	public childDeliveries: DeliveryModel[];
	public parentDeliveryId?: string;
}
export class DeliveryModel extends OperationWithChildrenModelBase
{
	public documentNumber?: string;
	public documentDate?: string;
	public documentCode?: string;
	public documentCodeName?: string;
	public type: DeliveryType;
	public acceptusType?: AcceptusType;
	public contractType?: ContractType;
	public contractNumber?: string;
	public turnoverType?: TurnoverType;
	public receiveType?: ReceiveType;
	public financingType?: FinancingType;
	public recursiveUnpacking?: boolean;
	public confirmPaused?: boolean;
	public items?: DeliveryItemModel[];
	public utdPositions?: UtdPositionModel[];
	public reportDate?: string;
	public scannedCodes?: string[];
	public trustedAcceptance?: boolean;
	public rejectUnscanned?: boolean;
	public recallReason?: string;
	public withdrawalReason?: WithdrawalReason;
	public withdrawalCause?: WithdrawalCause;
	public returnReason?: ReturnReason;
	public counteragentDepartment?: CounteragentDepartmentModel;
	public supplier?: CounteragentInfoModel;
	public recipient?: CounteragentInfoModel;
	public registratorTaskId?: string;
	public registratorId?: string;
	public codesSyncStatus?: CodesSyncStatus;
	public codesSyncDate?: string;
}
export class CreateDestructionModel extends ModifyDestructionModelBase
{
}
export class DestructionModel extends OperationWithChildrenModelBase
{
	public documentNumber: string;
	public documentDate?: string;
	public actNumber: string;
	public actDate?: string;
	public items: DeliveryItemModel[];
	public reportDate?: string;
	public destructionCause?: DestructionCause;
	public destructionReason?: DestructionReason;
	public rznDecision?: string;
	public destructionMethod?: DestructionMethod;
	public supplier?: CounteragentInfoModel;
	public recipient?: CounteragentInfoModel;
}
export class PutDestructionModel extends ModifyDestructionModelBase
{
	public destructionCause?: DestructionCause;
	public destructionReason?: DestructionReason;
	public rznDecision: string;
}
export class EmployeePatchModel extends EmployeeModificationModelBase
{
}
export class InviteModel extends EmployeeModificationModelBase
{
	public email: string;
}
export class RegistratorEditModel
{
	public name?: string;
	public model: string;
	public serialNumber: string;
	public ipAddress: string;
	public login: string;
	public password: string;
	public departmentId: string;
	public employeesWithAccess: string[];
}
export class RegistratorEmbeddedModel
{
	public id: string;
	public name?: string;
	public model: string;
	public serialNumber: string;
	public ipAddress: string;
	public login: string;
	public password: string;
}
export class RegistratorSettingsEmployeeModel
{
	public id: string;
	public fio: string;
	public admin: boolean;
}
export class RegistratorSettingsModel
{
	public id: string;
	public name?: string;
	public model: string;
	public serialNumber: string;
	public ipAddress: string;
	public login: string;
	public password: string;
	public departmentId: string;
	public employeesWithAccess: RegistratorSettingsEmployeeModel[];
}
export class CodeInfoModel
{
	public code: string;
	public status: string;
	public isDisposalAllowed: boolean;
	public isSuccessfullyDisposed?: boolean;
	public isOwner: boolean;
	public isProperDepartment: boolean;
}
export class CodeInfoResponseModel
{
	public items: CodeInfoModel[];
}
export class BillingInfoModel
{
	public subscriptions: BillingSubscriptionModel[];
}
export class BillingSubscriptionModel
{
	public from?: string;
	public to?: string;
	public rest: number;
	public amount: number;
	public limitation: BillingResourceLimitation;
}
export class MdlpError
{
	public statusCode: number;
	public description: string;
	public errorCode: string;
}
export enum AcceptusType { 
	Direct = "Direct", 
	Reverse = "Reverse", 
	UnregisteredDepartment = "UnregisteredDepartment", 
	UnregisteredCounteragent = "UnregisteredCounteragent", 
	Eeu = "Eeu", 
	Simplified = "Simplified", 
	StateDispatch = "StateDispatch", 
	SuspendedReturn = "SuspendedReturn"
}
export enum ContractType { 
	PurchaseAndSale = "PurchaseAndSale", 
	Fee = "Fee", 
	Agency = "Agency", 
	Free = "Free", 
	Return = "Return", 
	Government = "Government", 
	Consignment = "Consignment", 
	OwnFunds = "OwnFunds"
}
export enum Country { 
	Armenia = "Armenia", 
	Belarus = "Belarus", 
	Kazakhstan = "Kazakhstan", 
	Kyrgyzstan = "Kyrgyzstan"
}
export enum DeliveryItemStatus { 
	Unprocessed = "Unprocessed", 
	Accepted = "Accepted", 
	Rejected = "Rejected", 
	Recalled = "Recalled", 
	Error = "Error", 
	WaitingForCounterparty = "WaitingForCounterparty"
}
export enum DeliveryStatus { 
	Draft = "Draft", 
	New = "New", 
	Processing = "Processing", 
	CreatingDocument = "CreatingDocument", 
	Signing = "Signing", 
	Signed = "Signed", 
	Sending = "Sending", 
	MdlpProcessing = "MdlpProcessing", 
	WaitingForCounterparty = "WaitingForCounterparty", 
	Success = "Success", 
	Failed = "Failed", 
	Recalled = "Recalled", 
	RecallFailed = "RecallFailed", 
	PartiallyAccepted = "PartiallyAccepted", 
	Rejected = "Rejected", 
	PartiallyFailed = "PartiallyFailed", 
	Deleted = "Deleted", 
	PartiallyRecalled = "PartiallyRecalled", 
	PartiallyRejected = "PartiallyRejected", 
	PartiallyFailedIncompleted = "PartiallyFailedIncompleted"
}
export enum DeliveryType { 
	Acceptance = "Acceptance", 
	Shipment = "Shipment", 
	Transference = "Transference", 
	Disposal = "Disposal", 
	Unpacking = "Unpacking", 
	Withdrawal = "Withdrawal", 
	Extraction = "Extraction", 
	Enclosure = "Enclosure", 
	DisposalWithRegistrator = "DisposalWithRegistrator", 
	Reentry = "Reentry", 
	Destruction = "Destruction"
}
export enum FinancingType { 
	OwnFunds = "OwnFunds", 
	FederalFunds = "FederalFunds", 
	RegionalFunds = "RegionalFunds", 
	NonBugetedFondsFunds = "NonBugetedFondsFunds", 
	MixedBudgetFunds = "MixedBudgetFunds"
}
export enum ReceiveType { 
	Arrival = "Arrival", 
	Return = "Return", 
	Regrading = "Regrading"
}
export enum TurnoverType { 
	Sale = "Sale", 
	Return = "Return"
}
export enum WithdrawalReason { 
	SelectiveAudit = "SelectiveAudit", 
	CustomAudit = "CustomAudit", 
	FederalSupervision = "FederalSupervision", 
	ClinicalResearch = "ClinicalResearch", 
	PharmaceuticalExamination = "PharmaceuticalExamination", 
	Shortage = "Shortage", 
	SpecimensExtraction = "SpecimensExtraction", 
	WriteOff = "WriteOff", 
	AfterExperiment = "AfterExperiment", 
	Spoilage = "Spoilage", 
	IncompleteConsumerPackage = "IncompleteConsumerPackage", 
	MedicalGoodsProduction = "MedicalGoodsProduction", 
	DrugsProduction = "DrugsProduction", 
	QualityAudit = "QualityAudit", 
	Archive = "Archive", 
	Theft = "Theft"
}
export enum WithdrawalCause { 
	WriteOff = "WriteOff", 
	Reexport = "Reexport", 
	SpecimensExtraction = "SpecimensExtraction", 
	Receipt = "Receipt", 
	HealthCare = "HealthCare", 
	UnregisteredCounteragentShipment = "UnregisteredCounteragentShipment", 
	SelectiveAudit = "SelectiveAudit", 
	EeuShipment = "EeuShipment", 
	Retail = "Retail", 
	Export = "Export", 
	ClinicalResearch = "ClinicalResearch"
}
export enum ReturnReason { 
	GoodQuality = "GoodQuality", 
	PoorQuality = "PoorQuality"
}
export enum DestructionCause { 
	OwnerDecision = "OwnerDecision", 
	RznDecision = "RznDecision", 
	CourtDecision = "CourtDecision"
}
export enum DestructionReason { 
	Bad = "Bad", 
	Illegal = "Illegal", 
	Fake = "Fake", 
	Spoiled = "Spoiled", 
	OutOfDate = "OutOfDate", 
	ProducerRecall = "ProducerRecall", 
	RegistryDeletion = "RegistryDeletion"
}
export enum DestructionMethod { 
	Smashing = "Smashing", 
	Fragmentation = "Fragmentation", 
	Burning = "Burning"
}
export enum CodesSyncStatus { 
	Syncing = "Syncing", 
	SyncedSuccess = "SyncedSuccess", 
	SyncedFailed = "SyncedFailed", 
	NotSyncing = "NotSyncing"
}
export enum CodeSyncStatus { 
	Syncing = "Syncing", 
	SyncedSuccess = "SyncedSuccess", 
	SyncedFail = "SyncedFail", 
	SyncExpired = "SyncExpired", 
	SyncedError = "SyncedError"
}
export enum BillingResourceLimitation { 
	Limit = "Limit", 
	Unlimit = "Unlimit"
}
export enum DepartmentStatus { 
	Active = "Active", 
	Inactive = "Inactive", 
	DeactivationProcess = "DeactivationProcess"
}
export enum DepartmentType { 
	Branch = "Branch", 
	SafeWarehouse = "SafeWarehouse"
}
export enum EmployeeRole { 
	Admin = "Admin", 
	User = "User", 
	AllDepartmentsUser = "AllDepartmentsUser"
}
export enum SyncResult { 
	Success = "Success", 
	Fail = "Fail", 
	InProcess = "InProcess"
}
export enum TaskState { 
	Queued = "Queued", 
	Executing = "Executing", 
	Processed = "Processed", 
	Error = "Error"
}
