import { isEqual } from "lodash-es";
import { action, computed, observable } from "mobx";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";
import { ISettingsStore } from "stores/SettingsStore";
import { IUserStore } from "stores/UserStore";
import {
  DepartmentModel,
  RegistratorEditModel,
  RegistratorSettingsEmployeeModel,
  RegistratorSettingsModel,
} from "typings/server";

type TErrors =
  | "requiredIpAddress"
  | "requiredLogin"
  | "requiredPassword"
  | "requiredDepartment"
  | "incorrectLoginOrPassword"
  | "invalidIpAddress";

export class RegistratorEditorVM {
  @observable name: string = "";
  @observable ipAddress: string = "";
  @observable login: string = "";
  @observable password: string = "";
  @observable department?: DepartmentModel;
  @observable selectedEmployees: EmployeeModelExtended[] = [];
  @observable isAllEmployees: boolean;
  @observable noConnectionToRegistrator: boolean = false;
  @observable errors: Map<TErrors, string> = new Map();
  @observable submitted: boolean;
  @observable isLoading: boolean = false;

  constructor(
    readonly settingsStore: ISettingsStore,
    readonly userStore: IUserStore,
    readonly onSave: (model: RegistratorEditModel, isNew: boolean) => Promise<void>,
    readonly selectedRegistrator?: RegistratorSettingsModel
  ) {
    if (selectedRegistrator) {
      this.name = selectedRegistrator.name ?? "";
      this.login = selectedRegistrator.login;
      this.password = selectedRegistrator.password;
      this.ipAddress = selectedRegistrator.ipAddress;
      this.department = settingsStore.allDepartments.find(x => x.id === selectedRegistrator.departmentId);
      this.selectedEmployees = this.getEmployeesFromExtendedEmployees(
        selectedRegistrator.employeesWithAccess,
        this.settingsStore.employees
      );
      this.isAllEmployees = isEqual(
        this.filterEmployeesByDepartment(this.selectedEmployees, this.department).map(x => x.id),
        this.availableEmployees.map(x => x.id)
      );
    }
  }

  @computed
  get availableDepartments() {
    return this.userStore.user.availableDepartments;
  }

  @computed
  get availableEmployees(): EmployeeModelExtended[] {
    return this.filterEmployeesByDepartment(this.settingsStore.employees, this.department);
  }

  @action
  setName = (name: string) => {
    this.name = name;
  };

  @action
  setIpAddress = (ip: string) => {
    this.ipAddress = ip.trim();
    if (this.submitted) {
      this.validate();
    }
  };

  @action
  setLogin = (login: string) => {
    this.login = login;
    if (this.submitted) {
      this.validate();
    }
  };

  @action
  setPassword = (password: string) => {
    this.password = password;
    if (this.submitted) {
      this.validate();
    }
  };

  @action
  setDepartment = (department: DepartmentModel) => {
    this.selectedEmployees = this.filterEmployeesByDepartment(this.selectedEmployees, department);
    this.department = department;
    if (this.submitted) {
      this.validate();
    }
  };

  @action
  setSelectedEmployees = (employees: EmployeeModelExtended[]) => {
    this.selectedEmployees = employees;
  };

  @action
  setIsAllEmployees = (isAll: boolean) => {
    this.isAllEmployees = isAll;
  };

  @action
  validate = () => {
    this.errors.clear();

    if (!this.ipAddress) {
      this.errors.set("requiredIpAddress", "Укажите ip-адрес регистратора выбытия");
    }

    if (!this.login) {
      this.errors.set("requiredLogin", "Укажите логин регистратора выбытия");
    }

    if (!this.password) {
      this.errors.set("requiredPassword", "Укажите пароль регистратора выбытия");
    }

    if (!this.department) {
      this.errors.set("requiredDepartment", "Укажите подразделение");
    }
  };

  @action
  save = async () => {
    this.submitted = true;
    this.noConnectionToRegistrator = false;
    this.validate();
    if (!this.errors.size && this.department) {
      const employeesWithAccess = this.isAllEmployees
        ? this.availableEmployees.map(x => x.id)
        : this.selectedEmployees.filter(x => !x.isAdmin).map(x => x.id);

      const data = {
        name: this.name.trim(),
        login: this.login.trim(),
        password: this.password.trim(),
        ipAddress: this.ipAddress.trim(),
        departmentId: this.department.id,
        employeesWithAccess,
      };

      const model: RegistratorEditModel = this.selectedRegistrator
        ? { ...this.selectedRegistrator, ...data }
        : { ...data, model: "", serialNumber: "" };

      this.isLoading = true;
      try {
        await this.onSave(model, !this.selectedRegistrator);
      } catch (e) {
        if (e === 401) {
          return this.errors.set("incorrectLoginOrPassword", "Неверный логин или пароль");
        }
        this.noConnectionToRegistrator = true;
      } finally {
        this.isLoading = false;
      }
    }
  };

  private getEmployeesFromExtendedEmployees(
    registratorEmployees: RegistratorSettingsEmployeeModel[],
    extendedEmployees: EmployeeModelExtended[]
  ): EmployeeModelExtended[] {
    return extendedEmployees.filter(extEmp => registratorEmployees.some(regEmp => extEmp.id === regEmp.id));
  }

  private filterEmployeesByDepartment(
    employees: EmployeeModelExtended[],
    department?: DepartmentModel
  ): EmployeeModelExtended[] {
    const regularEmployees = employees.filter(x => !x.isAdmin);
    if (!department) {
      return regularEmployees;
    }
    return regularEmployees.filter(
      x => x.isAllDepartmentsUser || x.availableDepartments?.some(dep => dep.id === department.id)
    );
  }
}
