import { action, computed, observable } from "mobx";
import { DeliveryItemModel, DeliveryModel, DeliveryStatus, DeliveryType, StepInfoModel } from "typings/server";
import { DeliveryStage, ExtractionStageNames, UnpackingStageNames } from "Common/DeliveryDictionary";
import { DeliveryItemModelExtended } from "./ItemModel/DeliveryItemModelExtended";
import { DeliveryActions } from "./DeliveryModelActions";
import { IMappedStepInfoModel, IStage } from "./shared";
import { CodeModelExtended } from "../Code/CodeModelExtended";

export class PackingDeliveryModel extends DeliveryModel {
  // шаги операции для отображения в интерфейсе
  @observable public stages: Record<DeliveryStage | string, IStage> = Object.create({});
  @observable public status: DeliveryStatus;
  @observable public reportDate: string | undefined;
  @observable stepsInfo: { [key in DeliveryStatus]: StepInfoModel };
  @observable items: DeliveryItemModelExtended[];
  @observable scannedCodes: string[];

  private readonly actions: DeliveryActions;
  readonly sgtinCodesLen: number;
  readonly ssccCodesLen: number;
  readonly code: string;

  constructor(delivery: DeliveryModel) {
    super();
    Object.assign(this, delivery);

    // отсканированный код агрегата
    this.code = delivery.scannedCodes ? delivery.scannedCodes[0] : "";
    this.actions = new DeliveryActions(delivery);

    // группировка кодов по имени
    if (delivery.items) {
      const groupByName = this.getGroupedCodes(delivery.items, "name");
      this.items = this.processItems(delivery.items, groupByName);
    }

    if (delivery.stepsInfo) {
      const mappedSteps = this.mapSteps(delivery.stepsInfo);
      this.setAcceptanceStages(mappedSteps);
    }

    this.recursiveUnpacking = delivery.recursiveUnpacking || false;

    this.ssccCodesLen = this.items.reduce((sum: number, item) => (sum += item.ssccCodes.length), 0);
    this.sgtinCodesLen = this.items.reduce((sum: number, item) => (sum += item.sgtinCodes.length), 0);
  }

  /**
   * добавляем к stepsInfo информацию о шаге приемки
   * @param steps
   */
  private mapSteps(steps: { [key in DeliveryStatus]: StepInfoModel }): IMappedStepInfoModel[] {
    return this.actions.mapSteps(steps);
  }

  /**
   * получаем из stepsInfo шаги приемки для отображения в интерфейсе
   * @param mappedSteps
   */
  @action
  private setAcceptanceStages(mappedSteps: IMappedStepInfoModel[]) {
    // todo:
    const stageName = this.type === DeliveryType.Unpacking ? UnpackingStageNames : ExtractionStageNames;
    this.stages = this.actions.getStages(mappedSteps, stageName);
  }

  @action
  setNextStage(status: DeliveryStatus, stageName: DeliveryStage, stage: Partial<IStage>, userName: string) {
    const info = { status: this.status, completionDate: stage.completionDate, userName };
    // update status
    this.status = status;
    this.stages = this.actions.setNextStage(this.stages, stageName, stage);
    this.stepsInfo = this.actions.updateStepInfo(this.stepsInfo, info);
  }

  /**
   * группировка кодов по имени товара, либо по id
   * т.к. для одного товара 2 кода - 2 элемента массива с одном именем/id
   * @param items
   * @param groupBy
   */
  private getGroupedCodes(items: DeliveryItemModel[], groupBy: string): Record<string, CodeModelExtended[]> {
    return this.actions.getGroupedCodes(items, { groupBy, isAllScanned: false, showGtin: true });
  }

  private processItems(
    goods: DeliveryItemModel[],
    codeGroup: Record<string, CodeModelExtended[]>
  ): DeliveryItemModelExtended[] {
    return this.actions.processItems(goods, codeGroup, { showGtin: true });
  }

  @action
  setReportDate(val: string | undefined) {
    this.reportDate = val;
  }

  @action
  updateItems(items: DeliveryItemModelExtended[]) {
    this.items = items;
  }

  @action
  updateScannedCodes(codes: string[]) {
    this.scannedCodes = codes;
  }

  @computed
  get allCodesLen() {
    return this.items.reduce((sum: number, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length), 0);
  }

  @computed
  get allItemsCodes(): string[] {
    return this.items.map(item => item.allCodes.map(codeItem => codeItem.code)).flat();
  }
}
