import React from "react";
import { DeliveryStatus, DeliveryType } from "typings/server";
import { DeliveryStatusSpan } from "../DeliveriesList/DeliveriesList.styles";
import {
  AcceptanceDeliveryStatusNames,
  ShipmentDeliveryStatusNames,
  OperationsStatusNames,
  DestructionDeliveryStatusNames,
} from "./StatusesName";
import { DeliveryStatuses, DestructionStatuses, OperationStatuses } from "./Statuses";

interface StatusItemProps {
  type: DeliveryType;
  status?: DeliveryStatus;
  isDisabled?: boolean;
}

function getStatusColor(status: DeliveryStatus | undefined, type: DeliveryType): string {
  switch (type) {
    case DeliveryType.Unpacking:
    case DeliveryType.Transference:
    case DeliveryType.Withdrawal:
    case DeliveryType.Reentry:
    case DeliveryType.Disposal:
    case DeliveryType.DisposalWithRegistrator:
    case DeliveryType.Extraction:
      return (status && OperationStatuses[status].color) || "";
    case DeliveryType.Destruction:
      return (status && DestructionStatuses[status].color) || "";
    default:
      return (status && DeliveryStatuses[status].color) || "";
  }
}

function getStatusIcon(status: DeliveryStatus | undefined, type: DeliveryType): JSX.Element | null {
  switch (type) {
    case DeliveryType.Unpacking:
    case DeliveryType.Transference:
    case DeliveryType.Withdrawal:
    case DeliveryType.Reentry:
    case DeliveryType.Disposal:
    case DeliveryType.DisposalWithRegistrator:
    case DeliveryType.Extraction:
      return (status && OperationStatuses[status].icon) || null;
    case DeliveryType.Destruction:
      return (status && DestructionStatuses[status].icon) || null;

    default:
      return (status && DeliveryStatuses[status].icon) || null;
  }
}

function getStatusNameByDeliveryType(type: DeliveryType, status: DeliveryStatus): string {
  switch (type) {
    case DeliveryType.Acceptance:
      return AcceptanceDeliveryStatusNames[status];
    case DeliveryType.Shipment:
      return ShipmentDeliveryStatusNames[status];
    case DeliveryType.Destruction:
      return DestructionDeliveryStatusNames[status];
    case DeliveryType.Unpacking:
    case DeliveryType.Transference:
    case DeliveryType.Withdrawal:
    case DeliveryType.Reentry:
    case DeliveryType.Disposal:
    case DeliveryType.DisposalWithRegistrator:
    case DeliveryType.Extraction:
      return OperationsStatusNames[status];
    default:
      return "";
  }
}

export function StatusItem({ status, type }: StatusItemProps) {
  const deliveryStatusName = status && getStatusNameByDeliveryType(type, status);
  const color = getStatusColor(status, type);
  const icon = getStatusIcon(status, type);

  return (
    <DeliveryStatusSpan data-tid="Status" color={color}>
      {icon}&nbsp;{deliveryStatusName}
    </DeliveryStatusSpan>
  );
}
