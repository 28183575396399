import styled from "styles/styled-components";
import { theme } from "styles/theme";
import { RecalledStatusIcon } from "icons";
import { CollapseIcon } from "../CodeItem/CodeItem.styles";

interface IGoodNameProps {
  isAccepted?: boolean;
  hasAdditionalInfo: boolean;
}

export const GoodName = styled.span<IGoodNameProps>`
  display: inline-flex;
  color: ${props => props.isAccepted && props.theme.textColors.green};
  cursor: ${props => (props.hasAdditionalInfo ? "pointer" : "inherit")};

  &:hover {
    color: ${props => props.hasAdditionalInfo && props.theme.colors.darkBlue};

    ${CollapseIcon} {
      color: ${props => props.hasAdditionalInfo && props.theme.colors.darkBlue};
    }
  }
`;

export const BarcodeScannerIconWrap = styled.div`
  display: none;
  position: relative;
  top: calc(50% - 10px);
  left: 0;
  color: ${props => props.theme.colors.darkBlue};
`;

type TColor = "red" | "green" | "grey" | "orange";

interface AllScannedIconProps {
  top?: string;
  left?: string;
  color?: TColor;
}

export const ScannedIcon = styled.div<AllScannedIconProps>`
  position: relative;
  top: ${props => props.top || "14px"};
  left: ${props => props.left || 0};
  color: ${props => getColor(props.color)};
`;

function getColor(color?: TColor) {
  if (color === "red") return theme.textColors.red;
  if (color === "grey") return theme.textColors.lightGray;
  if (color === "orange") return theme.textColors.warningText;
  return theme.textColors.green;
}

interface ITableScannerRowProps {
  editable: boolean;
  hideScanner: boolean;
  hasAdditionalInfo: boolean;
}

export const TableScannerRow = styled.div<ITableScannerRowProps>`
  position: relative;
  cursor: ${props => (props.editable || props.hasAdditionalInfo) && "pointer"};

  &:hover {
    background-color: ${props =>
      props.editable || props.hasAdditionalInfo
        ? props.editable
          ? props.theme.colors.lightBlue
          : props.theme.colors.lightBackground
        : "inherit"};
    ${BarcodeScannerIconWrap} {
      display: ${props => props.editable && !props.hideScanner && "block"};
    }
    ${ScannedIcon} {
      display: ${props => props.editable && !props.hideScanner && "none"};
    }
  }
`;

interface INameTextProps {
  isAnyAdditionalInfo: boolean;
  isAccepted?: boolean;
}

export const NameText = styled.span<INameTextProps>`
  padding-left: ${props => (props.isAnyAdditionalInfo ? "14px" : "initial")};
  color: ${props => (props.isAccepted ? props.theme.textColors.green : "inherit")};
`;

export const ItemNameWrap = styled.div`
  position: relative;
`;

interface ItemIconWrapProps {
  top: string;
  left: string;
}

export const ItemIconWrap = styled.div<ItemIconWrapProps>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  height: 100%;
`;

interface IStatusReasonProps {
  offsetLeft?: boolean;
}

export const StatusReason = styled.div<IStatusReasonProps>`
  line-height: 20px;
  color: ${props => props.theme.textColors.lightGray2};
  margin-left: ${props => props.offsetLeft && "18px"};
`;

export const RejectedStatusIcon = styled(RecalledStatusIcon)`
  path {
    fill: ${props => props.theme.textColors.warningText};
  }
`;

export const TableItemHr = styled.hr`
  border-color: ${props => props.theme.colors.lightBackground};
  border-width: 1px;
  border-bottom: none;
  margin: 0;
`;

export const ItemName = styled.div`
  display: flex;
`;

interface IGridItemWrapProps {
  collapsible?: boolean;
}

export const GridItemWrap = styled.div<IGridItemWrapProps>`
  position: relative;
  cursor: ${props => (props.collapsible ? "pointer" : "inherit")};

  &:hover {
    background-color: ${props => props.collapsible && props.theme.colors.lightBackground};
  }
`;

export const ExpandAllIconWrap = styled.div`
  position: absolute;
  display: none;
  top: 1px;
  left: -3px;
`;

interface ITableHeadCellWrapProps {
  hasAddInfo?: boolean;
  top?: string;
  right?: string;
}

export const TableHeadCellWrap = styled.div<ITableHeadCellWrapProps>`
  position: relative;
  top: ${props => props.top && props.top};
  right: ${props => props.right && props.right};
  padding-left: ${props => props.hasAddInfo && "15px"};
  cursor: ${props => (props.hasAddInfo ? "pointer" : "inherit")};

  ${ExpandAllIconWrap} {
    display: ${props => (props.hasAddInfo ? "block" : "none")};
  }
`;

interface AdditionalInfoWrap {
  marginLeft?: string;
}

export const AdditionalInfoWrap = styled.div<AdditionalInfoWrap>`
  margin: ${props => `3px 0 0 ${props.marginLeft || "13px"} `};
`;
