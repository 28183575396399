import React from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { GoodsTable } from "Common/GoodsTable/GoodsTable/GoodsTable";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { DirectAcceptancePageVM } from "../DirectAcceptancePageVM";
import { DateAndFIOWrap, GoodsFormWrap, GoodsTableWrap } from "Common/Stages/Stages.styles";
import { DirectAcceptanceStagesPart } from "./DirectAcceptanceStagesPart";
import { DeliveryStatus } from "typings/server";
import { AcceptedCodes, RecalledCodes, RejectedCodes } from "Common/Stages/CodesStatuses/CodesStatuses";

interface IAcceptanceStagesProps {
  vm: DirectAcceptancePageVM;
}

export const DirectAcceptanceStagesBlock: React.FC<IAcceptanceStagesProps> = observer(
  (props: IAcceptanceStagesProps) => {
    const { vm } = props;

    const shippedDate = (
      <DateAndFIOWrap data-tid="CompleteInfo">
        {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Draft])}
      </DateAndFIOWrap>
    );
    const onCollapse = () => vm.onCollapseDelivery(vm.delivery.id);

    return (
      <DeliveryStagesBlock>
        {vm.delivery.childDeliveries.length && !vm.delivery.isActive ? (
          <DeliveryStatusBlock
            data-tid="ShippedStage"
            name={vm.stages[DeliveryStage.Shipped].name}
            status={vm.stages[DeliveryStage.Shipped].progress}
            error={vm.stages[DeliveryStage.Shipped].errorInfo}
            collapsed={!vm.isDeliveryOpenedState.get(vm.delivery.id)}
            alwaysVisibleComp={shippedDate}
            onCollapse={onCollapse}
          >
            <GoodsFormWrap>
              <AcceptedCodes delivery={vm.delivery} acceptedCodesLen={vm.delivery.acceptedCodesLen} />
              <RecalledCodes recalledCodesLen={vm.delivery.recalledCodesLen} />
              <RejectedCodes vm={vm} rejectedCodesLen={vm.delivery.rejectedCodesLen} delivery={vm.delivery} />
              <GoodsTableWrap shortTable={vm.isStateDispatch}>
                <GoodsTable
                  goods={vm.delivery.items}
                  showOnlyAccepted
                  hidePrices={vm.isStateDispatch || vm.isSuspendedReturn}
                  additionalInfo={vm.additionalInfo}
                />
              </GoodsTableWrap>
            </GoodsFormWrap>
          </DeliveryStatusBlock>
        ) : (
          <DeliveryStatusBlock
            data-tid="ShippedStage"
            name={vm.stages[DeliveryStage.Shipped].name}
            status={vm.stages[DeliveryStage.Shipped].progress}
            error={vm.stages[DeliveryStage.Shipped].errorInfo}
            alwaysVisibleComp={shippedDate}
          />
        )}
        {vm.delivery.childDeliveries.map(child => (
          <DirectAcceptanceStagesPart
            data-tid="ChildStage"
            key={child.id}
            delivery={child}
            vm={vm}
            isPartial={vm.delivery.allCodesLen !== child.allCodesLen}
          />
        ))}

        {vm.showDeliveryAcceptanceBlock && (
          <DirectAcceptanceStagesPart data-tid="ProcessingStage" delivery={vm.delivery} vm={vm} />
        )}

        {vm.showCompleteStage && (
          <DeliveryStatusBlock
            data-tid="DoneStage"
            status={vm.stages[DeliveryStage.Done].progress}
            name={vm.stages[DeliveryStage.Done].name}
            size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
          />
        )}
      </DeliveryStagesBlock>
    );
  }
);
