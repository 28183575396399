import { action, computed, observable } from "mobx";
import { ISignatureStore } from "stores/SignatureStore";
import { DeliveryStatus, DeliveryType, StepInfoModel } from "typings/server";
import { ICertificate } from "@skbkontur/plugin-js";
import { DeliveryActions, DetailPageModalNames, DetailPageLoadingName } from "Common/DeliveryActions";
import { IPackingStore } from "stores/PackingStore";
import { IStage } from "models/Delivery/shared";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { IUserStore } from "stores/UserStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";

type DirectAcceptanceErrors = "invalidReportDate";

export class ExtractionPageVM {
  @observable public errors: Map<DirectAcceptanceErrors, string> = new Map();
  @observable submitted: boolean = false;
  @observable collapsed: boolean;
  @observable additionalInfo: CodesAdditionalInfo;
  private actions: DeliveryActions;
  private readonly currentDate: string = new Date().toISOString();
  protected readonly documentStore: ISignatureStore;
  public readonly maxDate: string;

  constructor(
    private readonly packingStore: IPackingStore,
    documentStore: ISignatureStore,
    userStore: IUserStore,
    type: DeliveryType
  ) {
    this.documentStore = documentStore;
    this.collapsed = this.stages[DeliveryStage.Processing].progress === ProgressStage.Done;
    this.maxDate = new Date().toISOString();

    this.actions = new DeliveryActions(packingStore, documentStore, userStore, type);

    if (this.packingStore.selectedDelivery.status === DeliveryStatus.CreatingDocument) {
      this.actions.startPolling(this.delivery.id);
    }
    this.additionalInfo = new CodesAdditionalInfo(this.delivery.id, this.delivery.allItemsCodes);
  }

  @action
  toggleModal(modalName: DetailPageModalNames) {
    return this.actions.toggleModal(modalName);
  }

  @action
  toggleLoading(loadingName: DetailPageLoadingName) {
    return this.actions.toggleLoading(loadingName);
  }

  @action
  onCollapse = (val?: boolean) => {
    if (val !== undefined) this.collapsed = val;
    else {
      this.collapsed = !this.collapsed;
    }
  };

  @action
  save = async () => {
    this.toggleLoading("creatingDocument");
    await this.saveDraft();
    this.actions.sendReportDateMetrics(this.delivery.reportDate);
    await this.actions.save(this.delivery);
  };

  @action
  saveItems = async () => {
    await this.packingStore.saveItems(this.delivery.items, this.delivery.id);
  };

  @action
  async saveDraft(): Promise<void> {
    this.setSubmitted(true);
    if (this.isValid()) {
      // Перезатирает неотправленные поля модели PatchDeliveryModel
      await this.packingStore.saveDelivery({ ...this.delivery }, this.delivery.id);
      if (this.delivery.status === DeliveryStatus.New) {
        this.actions.updateStatus(DeliveryStatus.Processing, DeliveryStage.Processing, this.delivery);
      }
    } else {
      this.onCollapse(false);
      return Promise.reject();
    }
  }

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.packingStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.packingStore.getItem(this.delivery.id, { force: true });
    this.packingStore.setSelected(item);
    this.toggleLoading("rollback");
  }

  @action async deleteDelivery() {
    this.toggleLoading("delete");
    await this.packingStore.deleteDelivery(this.delivery.id);
    this.toggleLoading("delete");
  }

  @action
  sign = async (cert: ICertificate) => {
    await this.actions.sign(cert, [this.delivery]);
  };

  @action
  prepareDocuments = async () => {
    this.toggleLoading("download");
    try {
      const taskId = await this.packingStore.prepareDocuments(this.delivery.id);
      this.actions.startPolling(taskId, "download");
    } catch (e) {
      this.toggleLoading("download");
      throw e;
    }
  };

  @action
  handleUnmount() {
    return this.actions.handleUnmount();
  }

  @action.bound
  setReportDate = (date?: string) => {
    this.delivery.setReportDate(date);
    if (this.submitted) this.validateDate();
  };

  @action
  validateDate() {
    this.errors.delete("invalidReportDate");

    const now = new Date();
    now.setHours(now.getHours() + 1); // дата создания операции больше текущей даты на пару минут, добавляем час к текущей дате
    const isFutureDate = this.delivery.reportDate && new Date(this.delivery.reportDate) > now;
    if (isFutureDate) {
      this.errors.set(
        "invalidReportDate",
        "Невалидная дата. Дата приемки не должна быть больше текущей даты и времени"
      );
    }
  }

  @action
  setSubmitted(val: boolean) {
    this.submitted = val;
  }

  @action
  isValid(): boolean {
    this.errors.clear();
    this.validateDate();

    return !this.errors.size;
  }

  @computed
  get stages(): Record<DeliveryStage | string, IStage> {
    return this.delivery.stages;
  }

  @computed
  get delivery() {
    return this.packingStore.selectedDelivery;
  }

  @computed
  get modalMap() {
    return this.actions.modalMap;
  }

  @computed
  get loadingMap() {
    return this.actions.loadingMap;
  }

  @computed
  get showSignBtn(): boolean {
    const status = this.packingStore.selectedDelivery.status;
    return status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
  }

  @computed
  get showExtractBtn(): boolean {
    const status = this.packingStore.selectedDelivery.status;
    return status === DeliveryStatus.New || status === DeliveryStatus.Draft || status === DeliveryStatus.Processing;
  }

  @computed
  get showRollbackBtn(): boolean {
    const { status } = this.packingStore.selectedDelivery;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.Signing;
  }

  @computed
  get rollbackHintText(): string {
    const { status } = this.delivery;
    return status === DeliveryStatus.Signing ? "Для редактирования изъятия" : "Для редактирования и повторного изъятия";
  }

  @computed
  get shouldOpenRollbackModal(): boolean {
    const { status } = this.packingStore.selectedDelivery;

    return status === DeliveryStatus.Failed;
  }

  @computed
  get showFooter(): boolean {
    return this.showSignBtn || this.showExtractBtn || this.showRollbackBtn || this.showDeleteBtn;
  }

  @computed
  get isFailedStatus() {
    const { status } = this.packingStore.selectedDelivery;
    return (
      status === DeliveryStatus.Failed ||
      status === DeliveryStatus.PartiallyFailed ||
      status === DeliveryStatus.RecallFailed
    );
  }

  @computed
  get showDeleteBtn(): boolean {
    const {
      selectedDelivery: { status },
      isCanBeDeleted,
    } = this.packingStore;
    return isCanBeDeleted(status) || status === DeliveryStatus.Failed;
  }

  @computed
  get isEditable(): boolean {
    return this.delivery.status === DeliveryStatus.New || this.delivery.status === DeliveryStatus.Processing;
  }

  @computed
  get supplier() {
    return this.delivery.supplier;
  }

  @computed
  get hasDocuments() {
    return this.delivery.hasDocuments;
  }

  @computed
  get reportDate() {
    return this.delivery.reportDate || this.currentDate;
  }

  @computed
  get isShowCirculationWarning(): boolean {
    const { parentDeliveryId, stepsInfo, items } = this.delivery;
    const { isFailedCodesAppliedByNewRules, isAnyCodeAppliedByNewRules } = this.additionalInfo;

    const singnedStepDate = stepsInfo[DeliveryStatus.Signed]?.completionDate;
    const mdlpStepDate = stepsInfo[DeliveryStatus.MdlpProcessing]?.completionDate;

    const waitingTime = 15 * 60 * 1000;

    return (
      !parentDeliveryId &&
      (this.isFailedStatus || !mdlpStepDate) &&
      Date.now() > +new Date(singnedStepDate) + waitingTime &&
      (this.isFailedStatus ? isFailedCodesAppliedByNewRules(items) : isAnyCodeAppliedByNewRules())
    );
  }

  getCompletionUser(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionUser(stepInfo);
  }

  getCompletionDate(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionDate(stepInfo);
  }

  getTitleSize(stage: IStage) {
    return this.actions.getTitleSize(this.delivery.stages, stage);
  }

  getSendingStepName(stage: IStage): string {
    return stage.progress === ProgressStage.Planned ? "Отправка в ИС МДЛП" : "Отправлено в ИС МДЛП";
  }
}
