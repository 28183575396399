import styled from "styles/styled-components";
import { DiscardBlockWrap } from "../ScannerSidePage.styles";

interface IInfoBlockProps {
  maxWidth: string;
  isInputMode?: boolean;
}

export const InfoBlock = styled.div<IInfoBlockProps>`
  border: ${props => !props.isInputMode && `1px solid ${props.theme.colors.borderGray}`};
  padding: ${props => (props.isInputMode ? "0 2px" : "12px 8px 12px 16px")};
  margin-top: 18px;
  width: 100%;
  max-width: ${props => props.maxWidth};
  margin-bottom: 12px;
  position: relative;
  text-align: left;

  &:before {
    ${props => !props.isInputMode && `content: "";`};
    position: absolute;
    border-left: 1px solid ${props => props.theme.colors.borderGray};
    border-bottom: 1px solid ${props => props.theme.colors.borderGray};
    transform: rotate(-45deg);
    bottom: -7px;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: ${props => props.theme.colors.white};
  }

  &:hover {
    ${DiscardBlockWrap} {
      display: block;
      opacity: 1;
    }
  }
`;

export const ScannerAdditionalInfoMaxWidth = 700;

export const InputAdditionalInfoMaxWidth = 738;

export const SgtinProducerWidth = 160;

export const SgtinProducerWidthInputMode = 250;

export const SsccProducerWidth = 250;

export const InfoTitle = styled.div`
  font-weight: 600;
  line-height: 22px;
`;

interface CodeProps {
  bold: boolean;
}

export const Code = styled.div<CodeProps>`
  display: inline-block;
  color: ${props => !props.bold && props.theme.textColors.secondaryText};
  margin-bottom: 3px;
  font-weight: ${props => (props.bold ? "600" : "inherit")};
`;

export const AcceptedText = styled.div`
  display: inline-block;
  color: ${props => props.theme.textColors.green};
  margin-left: 20px;
`;
