import styled from "styles/styled-components";

export const MainWrap = styled.div`
  display: flex;
  margin: 35px 0 0 16px;
`;

export const SubscriptionTextWrap = styled.div`
  width: 540px;

  & p {
    margin: 0;
  }

  & p:first-child {
    margin-bottom: 10px;
  }
`;

export const SubscriptionIconWrap = styled.div`
  margin-right: 25px;
`;
