import { RouteName } from "./RouteNames";

export enum CirculationSubRoute {
  withdrawal = "withdrawal",
  reentry = "reentry",
  destruction = "destruction",
}

class CirculationRouteParams {
  operationId?: string;
  type?: CirculationSubRoute;
}

export class CirculationRoute extends CirculationRouteParams {
  public static readonly title = "Ввод и вывод\n\rиз оборота";
  public static readonly baseRoute = `/${RouteName.Circulation}`;
  public static readonly typeRoute = `/${RouteName.Circulation}/:type`;
  public static readonly route = `${CirculationRoute.baseRoute}/:type?/:operationId?`;

  public static readonly withdrawalBaseRoute = `${CirculationRoute.baseRoute}/${CirculationSubRoute.withdrawal}`;
  public static readonly withdrawalRoute = `${CirculationRoute.baseRoute}/${CirculationSubRoute.withdrawal}/:operationId`;

  public static readonly reentryBaseRoute = `${CirculationRoute.baseRoute}/${CirculationSubRoute.reentry}`;
  public static readonly reentryRoute = `${CirculationRoute.baseRoute}/${CirculationSubRoute.reentry}/:operationId`;

  public static readonly destructionBaseRoute = `${CirculationRoute.baseRoute}/${CirculationSubRoute.destruction}`;
  public static readonly destructionRoute = `${CirculationRoute.baseRoute}/${CirculationSubRoute.destruction}/:operationId`;

  public static getHref(id?: string, type?: CirculationSubRoute): string {
    let route = this.baseRoute;

    if (type) {
      route += `/${type}`;
    }

    if (id) {
      route += `/${id}`;
    }

    return route;
  }
}
