import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Toggle } from "components";
import BarcodeScannerIcon from "@skbkontur/react-icons/BarcodeScanner";
import { DirectAcceptancePageVM } from "../../../DirectAcceptance/DirectAcceptancePageVM";
import {
  CheckBoxWrap,
  ChildWrap,
  CodesBlock,
  Container,
  ControlPanel,
  DateText,
  DateWrap,
  ScanPanel,
  ScanPanelText,
  ToggleWrap,
} from "./GoodsForm.styles";
import { TextGreen } from "styles/shared.styles";
import { ScannedItemsCountLarge } from "Common/ScannedItemsCount/ScannedItemsCountLarge";
import { getEnding } from "helpers/endings";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

interface IGoodsFormProps {
  vm: DirectAcceptancePageVM;
  delivery: AcceptanceDeliveryModel;
  isPartial?: boolean;
}

export const GoodsForm: React.FC<IGoodsFormProps> = observer((props: PropsWithChildren<IGoodsFormProps>) => {
  const { vm, delivery, isPartial } = props;

  const onReject = (e: any) => {
    e.preventDefault();
    vm.setRejected();
  };

  const toggleScanSidePage = () => {
    vm.toggleModal("scanPage");
    // blur(), because scanner triggers button click on "enter"
    const activeElement = window.document.activeElement as HTMLElement;
    if (activeElement && activeElement.blur) {
      activeElement.blur();
    }
  };

  const isAllScanned = delivery.items.every(item => item.isAllScanned);

  const onChangeReportDate = (val?: string) => vm.setReportDate(val);

  const changeConfirmPaused = (e: any) => {
    e.preventDefault();
    vm.setConfirmPaused(!vm.activeDelivery.confirmPaused);
  };

  const getReportDateError = () => vm.errors.get("invalidReportDate");

  return (
    <Container>
      <ControlPanel>
        <DateWrap>
          <DateText>Дата приемки</DateText>
          <DateTimePicker
            value={vm.reportDate}
            onChange={onChangeReportDate}
            getReportDateError={getReportDateError}
            maxDate={vm.maxDate}
            minDate={vm.minDate}
          />
        </DateWrap>
        <ToggleWrap onClick={changeConfirmPaused}>
          <Toggle checked={vm.activeDelivery.confirmPaused} />
          &nbsp;&nbsp;Приемка приостановленного товара
        </ToggleWrap>
      </ControlPanel>

      <h4>Товары</h4>
      <ScanPanelText>
        {isAllScanned ? (
          <TextGreen>Все {isPartial && "оставшиеся"} товары совпадают со сведениями поставщика</TextGreen>
        ) : (
          "Отсканируйте коды маркировки для проверки или сделайте доверительную приемку"
        )}
      </ScanPanelText>
      <ScanPanel>
        <CodesBlock>
          <ScannedItemsCountLarge scannedLen={delivery.allScannedLen} allLen={delivery.allCodesLen} />
          &nbsp;&nbsp;
          {delivery.allScannedLen
            ? "кодов маркировки"
            : getEnding(delivery.allCodesLen, "код маркировки", "кода маркировки", "кодов маркировки")}
        </CodesBlock>

        <Button
          onClick={toggleScanSidePage}
          size={"large"}
          disabled={delivery.rejectUnscanned}
          icon={<BarcodeScannerIcon />}
        >
          {delivery.allScannedLen ? "Продолжить сканировать товары" : "Сканировать товары"}
        </Button>

        {!vm.isAllScanned && (
          <CheckBoxWrap onClick={onReject}>
            <Checkbox checked={delivery.rejectUnscanned} />
            &nbsp;&nbsp;
            {delivery.allScannedLen
              ? "Отказаться от всех неотсканированных товаров"
              : "Отказаться от всех товаров поставки"}
          </CheckBoxWrap>
        )}
      </ScanPanel>
      <ChildWrap>{props.children}</ChildWrap>
    </Container>
  );
});
