import { action, computed, observable } from "mobx";
import {
  DeliveryItemModel,
  DeliveryItemStatus,
  DeliveryModel,
  DeliveryStatus,
  DeliveryType,
  StepInfoModel,
  WithdrawalReason,
} from "typings/server";
import {
  DeliveryStage,
  DestructionStageNames,
  ReentryStageNames,
  WithdrawalStageNames,
} from "features/Common/DeliveryDictionary";
import { DeliveryItemModelExtended } from "./ItemModel/DeliveryItemModelExtended";
import { DeliveryActions } from "./DeliveryModelActions";
import { IMappedStepInfoModel, IStage } from "./shared";
import { CodeModelExtended } from "../Code/CodeModelExtended";

export class WithdrawalDeliveryModel extends DeliveryModel {
  @observable stages: Record<DeliveryStage | string, IStage> = Object.create({});
  @observable status: DeliveryStatus;
  @observable reportDate: string | undefined;
  @observable withdrawalReason?: WithdrawalReason;
  @observable items: DeliveryItemModelExtended[];
  @observable stepsInfo: { [key in DeliveryStatus]: StepInfoModel } = Object.create({});
  @observable childDeliveries: WithdrawalDeliveryModel[] = [];

  private readonly actions: DeliveryActions;
  private deliveryType: DeliveryType;

  constructor(delivery: DeliveryModel) {
    super();
    Object.assign(this, delivery);
    this.actions = new DeliveryActions(delivery);
    this.deliveryType = delivery.type;

    // группировка кодов по имени
    if (delivery.items) {
      const groupByName = this.getGroupedCodes(delivery.items, "name");
      this.items = this.processItems(delivery.items, groupByName);
    }

    // шаги поставки
    if (delivery.stepsInfo) {
      const mappedSteps = this.mapSteps(delivery.stepsInfo);
      this.setStages(mappedSteps);
    }

    this.recursiveUnpacking = delivery.recursiveUnpacking || false;

    if (this.childDeliveries?.length) {
      this.childDeliveries = this.childDeliveries.map(child => new WithdrawalDeliveryModel(child));
    } else this.childDeliveries = [];
  }

  /**
   * добавляем к stepsInfo информацию о шаге приемки
   * @param steps
   */
  private mapSteps(steps: { [key in DeliveryStatus]: StepInfoModel }): IMappedStepInfoModel[] {
    return this.actions.mapSteps(steps);
  }

  /**
   * получаем из stepsInfo шаги приемки для отображения в интерфейсе
   * @param mappedSteps
   */
  @action
  private setStages(mappedSteps: IMappedStepInfoModel[]) {
    const getNames = () => {
      switch (this.deliveryType) {
        case DeliveryType.Reentry:
          return ReentryStageNames;
        case DeliveryType.Destruction:
          return DestructionStageNames;
        default:
          return WithdrawalStageNames;
      }
    };
    const names = getNames();
    this.stages = this.actions.getStages(mappedSteps, names);
  }

  @action
  setNextStage(status: DeliveryStatus, stageName: DeliveryStage, stage: Partial<IStage>, userName: string) {
    // old status
    const info = { status: this.status, completionDate: stage.completionDate, userName };
    // update status
    this.status = status;
    this.stages = this.actions.setNextStage(this.stages, stageName, stage);
    this.stepsInfo = this.actions.updateStepInfo(this.stepsInfo, info);
  }

  /**
   * группировка кодов по имени товара, либо по id
   * т.к. для одного товара 2 кода - 2 элемента массива с одном именем/id
   * @param items
   * @param groupBy
   */
  private getGroupedCodes(items: DeliveryItemModel[], groupBy: string): Record<string, CodeModelExtended[]> {
    return this.actions.getGroupedCodes(items, { groupBy, isAllScanned: false, showGtin: true });
  }

  private processItems(
    goods: DeliveryItemModel[],
    codeGroup: Record<string, CodeModelExtended[]>
  ): DeliveryItemModelExtended[] {
    return this.actions.processItems(goods, codeGroup, { showGtin: true });
  }

  @action
  setReportDate(val: string | undefined) {
    this.reportDate = val;
  }

  @action
  updateDelivery(delivery: DeliveryModel) {
    this.documentDate = delivery.documentDate || "";
    this.documentNumber = delivery.documentNumber;
    this.withdrawalReason = delivery.withdrawalReason;
    this.setReportDate(delivery.reportDate);
    this.registratorId = delivery.registratorId;
    this.documentCode = delivery.documentCode;
    this.documentCodeName = delivery.documentCodeName;
  }

  @action
  updateItems(items: DeliveryItemModelExtended[]) {
    this.items = items;
  }

  @action
  setStatus(status: DeliveryStatus) {
    this.status = status;
  }

  @action
  setRegistratorTaskId(id: string) {
    this.registratorTaskId = id;
  }

  @computed
  get allItemsCodes(): string[] {
    return this.items.map(item => item.allCodes.map(codeItem => codeItem.code)).flat();
  }

  @computed
  get allCodesLen() {
    return this.items.reduce((sum: number, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length), 0);
  }

  @computed
  get isDisposal(): boolean {
    return this.type === DeliveryType.Disposal;
  }

  @computed
  get isDisposalWithRegistrator(): boolean {
    return this.type === DeliveryType.DisposalWithRegistrator;
  }

  // поставка активна, если с ней можно совершить действие и у нее нет подпоставок
  @computed
  get isActive(): boolean {
    const editable = [
      DeliveryStatus.Processing,
      DeliveryStatus.New,
      DeliveryStatus.Signing,
      DeliveryStatus.Signed,
      DeliveryStatus.CreatingDocument,
    ];
    return editable.includes(this.status) && this.childDeliveries.length === 0;
  }

  @computed
  get allScannedLen(): number {
    return this.items.reduce((count: number, item) => (count += item.ssccScanLen + item.sgtinScanLen), 0);
  }

  @action
  setItems(items: DeliveryItemModelExtended[]) {
    items.forEach(item => {
      const foundItem = this.items.find(i => i.name === item.name && item.status === i.status);
      if (foundItem) foundItem.setCodes(item.allCodes);
    });
  }

  @computed
  get acceptedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Accepted);
  }

  @computed
  get failedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Error);
  }

  private getDeliveryCodesLenByStatus(status: DeliveryItemStatus): number {
    return this.items.reduce(
      (count: number, item: DeliveryItemModelExtended) =>
        (count += item.status === status && item.allCodes ? item.allCodes.length : 0),
      0
    );
  }
}
