import styled from "styles/styled-components";

export const CertListBody = styled.div`
  width: 655px;
  min-height: 250px;
  font-size: 16px;
  line-height: 22px;
`;

export const CertErrWrap = styled.div`
  display: flex;
  align-items: center;
`;

interface ICertErrImgProps {
  marginTop?: string;
}

export const CertErrImg = styled.img<ICertErrImgProps>`
  margin: ${props => props.marginTop || "20px"} 23px 0;
`;

export const AccessToMdlpModalBlock = styled.div`
  min-width: 655px;
  min-height: 250px;
  font-size: 16px;
  line-height: 22px;
`;

export const ErrorNoCertBody = styled.div`
  display: flex;
`;

export const ErrorTextWrapper = styled.div`
  margin-left: 28px;
`;

export const CertSuccessWrap = styled.div`
  display: flex;
  padding-top: 15px;
`;

export const SuccessIconWrap = styled.div`
  margin-left: 25px;
  margin-right: 33px;
  margin-top: 18px;
`;

export const SuccessTextBlock = styled.p`
  line-height: 30px;
`;

export const ErrorList = styled.ul`
  padding-left: 0;
  list-style: none;

  li {
    margin-bottom: 8px;
  }
`;

export const ErrorText = styled.div`
  margin-top: 10px;
`;

export const DocErrorText = styled.div`
  margin-top: 20px;
`;
