import styled from "styles/styled-components";
import { theme } from "styles/theme";

export type Level = "normal" | "warning" | "error";

interface WarningBlockProps {
  maxWidth?: string;
  top?: boolean;
  level: Level;
}

export const WarningBlock = styled.div<WarningBlockProps>`
  display: inline-block;
  width: ${props => (props.top ? "100%" : "initial")};
  max-width: ${props => (props.top ? "initial" : props.maxWidth || "650px")};
  text-align: ${props => (props.top ? "center" : "initial")};
  background: ${props => getBackground(props.level)};
  padding: ${props => (props.level === "normal" ? "0px" : "10px")};
  border-radius: 3px;
`;

export const WarningIconWrap = styled.span`
  margin-right: 7px;
  color: ${props => props.theme.colors.orangeDark};
`;

function getBackground(level: Level) {
  switch (level) {
    case "normal":
      return "initial";
    case "warning":
      return theme.colors.yellowPale;
    case "error":
      return theme.colors.redBackground;
    default:
      const never: never = level;
      return never;
  }
}
