import React, { useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { TableWrap } from "../PageInfoBlock.styles";
import { UnpackingPageVM } from "../../../UnpackingPageVM";
import { UnpackingCodesTableItem } from "./UnpackingCodesTableItem";
import { TableHeadCellWrap, ExpandAllIconWrap } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { ExpandAllIcon, CollapseAllIcon } from "icons";

interface UnpackingCodesTableProps {
  vm: UnpackingPageVM;
}

export const UnpackingCodesTable: React.FC<UnpackingCodesTableProps> = observer((props: UnpackingCodesTableProps) => {
  const { vm } = props;
  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const cols = "173px 3.5fr 1fr 1.5fr";

  return (
    <TableWrap>
      <Grid cols={cols} headerBorderBottom paddingHeaderCells="8px 5px">
        <GridCellHeader col={1} row={1}>
          <TableHeadCellWrap hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
            <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
            Код агрегата
          </TableHeadCellWrap>
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Наименование
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign={"right"}>
          Агрегат
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign={"right"}>
          Штучный товар
        </GridCellHeader>
      </Grid>
      {vm.scannedCodes.map(code => (
        <UnpackingCodesTableItem
          vm={vm}
          cols={cols}
          key={code}
          code={code}
          additionalInfo={vm.additionalInfo.codesInfo.get(code)}
          allCodesExpanded={allCodesExpanded}
        />
      ))}
    </TableWrap>
  );
});
