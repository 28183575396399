import styled from "styles/styled-components";

export const HelpIconWrap = styled.div`
  display: inline-block;
  color: ${props => props.theme.colors.darkBlue};
  margin-left: 7px;
  position: relative;
  font-size: 18px;
  cursor: pointer;
`;

interface TipTextProps {
  maxWidth?: string;
}

export const TipText = styled.div<TipTextProps>`
  max-width: ${props => props.maxWidth || "300px"};
  padding-bottom: 5px;

  ol {
    padding-left: 16px;
    li {
      margin-bottom: 15px;
    }
  }
`;

export const TipBlock = styled.div`
  display: flex;
  margin-top: 10px;
`;

interface TipIconWrapProps {
  color?: string;
}

export const TipIconWrap = styled.div<TipIconWrapProps>`
  margin-right: 5px;
  color: ${props => props.color || "default"};
`;

export const VideoLinkWrap = styled.div`
  margin-top: 10px;
`;
