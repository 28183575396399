import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import { Button } from "components";
import { RouteComponentProps, withRouter } from "react-router";
import { PackingRoute } from "routes";
import { CertificatesList } from "../../../../CertificatesList/CertificatesList";
import { ErrorSignBlock } from "../../../../CertificatesList/shared";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { ExtractionPageVM } from "./ExtractionPageVM";
import { PageHeader } from "./PageHeader/PageHeader";
import { ExtractionStagesBlock } from "./Stages/ExtractionStagesBlock";
import { DocumentLite } from "@skbkontur/react-icons";
import { SuccessSignBlock } from "../../../../Common/SuccessSignBlock/SuccessSignBlock";
import { ExtractionSidePage } from "../../ExtractionScanner/ExtractionSidePage";
import { RollbackButton } from "Common/Rollback/RollbackButton/RollbackButton";
import { RollbackModal } from "Common/Rollback/RollbackModal/RollbackModal";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { DeleteBtnWrap, deleteBtnTheme } from "Common/DetailsPage/DetailsPage.styles";
import TrashIcon from "@skbkontur/react-icons/Trash";

interface IExtractionPageProps extends RouteComponentProps {
  vm: ExtractionPageVM;
}

export const ExtractionPage = withRouter(
  observer((props: IExtractionPageProps) => {
    const { vm, history } = props;

    // unmount
    useEffect(() => {
      return () => {
        vm.handleUnmount();
      };
    }, [vm]);

    const extract = () => vm.save();

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const toggleScanPageModal = () => vm.toggleModal("scanPage");

    const toggleRollbackModal = () => vm.toggleModal("rollback");

    const onRollback = async () => {
      await vm.rollbackDeliveryStatus();
      toggleRollbackModal();
    };

    const onRollbackButtonClick = async () => {
      if (vm.shouldOpenRollbackModal) {
        toggleRollbackModal();
      } else {
        vm.rollbackDeliveryStatus();
      }
    };

    const toggleDeleteModal = () => vm.toggleModal("delete");

    const onDelete = async () => {
      await vm.deleteDelivery();
      toggleDeleteModal();
      history.push(PackingRoute.extractionBaseRoute);
    };

    const goBackBlock = <GoToBlock name={"К списку операций"} href={PackingRoute.extractionBaseRoute} />;

    const saveDraft = async () => {
      await vm.saveDraft();
      history.push(PackingRoute.extractionBaseRoute);
    };

    const successBlock = () => <SuccessSignBlock route={PackingRoute.extractionBaseRoute} textSubString="операций" />;

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding={"24px 24px 24px 38px"}>
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title={"Подписание документа"}
              signText={"Документ будет подписан при помощи сертификата:"}
              renderSuccessBlock={successBlock}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={vm.sign}
            />
          )}

          {vm.modalMap.get("scanPage") && (
            <ExtractionSidePage
              close={toggleScanPageModal}
              save={vm.saveItems}
              delivery={vm.delivery}
              additionalInfo={vm.additionalInfo}
            />
          )}

          {vm.modalMap.get("rollback") && (
            <RollbackModal
              headerText="Вернуться к черновику?"
              bodyText="Изъятие станет доступно для редактирования и повторной отправки в ИС МДЛП."
              onClose={toggleRollbackModal}
              onRollback={onRollback}
              isLoading={vm.loadingMap.get("rollback")}
            />
          )}
          {vm.modalMap.get("delete") && (
            <DeleteDeliveryModal
              deliveryType={vm.delivery.type}
              isDeliveryError={vm.isFailedStatus}
              isScannedCodes={Boolean(vm.delivery.allCodesLen)}
              isLoading={vm.loadingMap.get("delete")}
              onClose={toggleDeleteModal}
              onDelete={onDelete}
            />
          )}

          <PageHeader vm={vm} goBackBlock={goBackBlock} onDownload={vm.prepareDocuments} />

          <ExtractionStagesBlock vm={vm} />
        </Content>

        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showExtractBtn && (
                <Button
                  data-tid="CreateDocument"
                  use="primary"
                  width={"200px"}
                  onClick={extract}
                  loading={vm.loadingMap.get("creatingDocument")}
                >
                  Изъять из агрегата
                </Button>
              )}
              {vm.showSignBtn && (
                <Button data-tid="Sign" onClick={toggleCertificatesModal} use={"primary"} width={"200px"}>
                  Подписать
                </Button>
              )}
              {vm.showRollbackBtn && (
                <RollbackButton onClick={onRollbackButtonClick} size="large" hintText={vm.rollbackHintText}>
                  Вернуться к черновику
                </RollbackButton>
              )}
              {vm.showDeleteBtn && (
                <DeleteBtnWrap>
                  <Button
                    data-tid="DeleteDocument"
                    onClick={toggleDeleteModal}
                    theme={deleteBtnTheme}
                    use="link"
                    icon={<TrashIcon />}
                  >
                    {vm.isFailedStatus ? "Удалить изъятие" : "Удалить черновик"}
                  </Button>
                </DeleteBtnWrap>
              )}
            </FooterBtnWrapper>
            {vm.isEditable && (
              <Button data-tid="SaveAndExit" use="link" onClick={saveDraft} size={"small"} icon={<DocumentLite />}>
                Сохранить и выйти
              </Button>
            )}
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
