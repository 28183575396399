export enum RouteName {
  Acceptance = "acceptance",
  Welcome = "welcome",
  Shipment = "shipment",
  Settings = "settings",
  Pack = "pack",
  Greeting = "greeting",
  Transfer = "transfer",
  Circulation = "circulation",
}

export enum GreetingStep {
  Authorization = "authorization",
  Department = "department",
}
