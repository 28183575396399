import styled from "styles/styled-components";

export const MainFooterWrap = styled.div`
  color: ${props => props.theme.textColors.lightenGray2};
  margin: 24px 0;
`;

export const Logo = styled.span`
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 3px;
  font-weight: 700;
`;

export const LogoSpan = styled.span`
  display: inline-block;
  vertical-align: bottom;
  color: ${props => props.theme.colors.darkGray};
  margin-bottom: 3px;
  margin-left: 3px;
`;

export const FooterColWrap = styled.div`
  line-height: 24px;
`;

export const SupportLinkWrap = styled.span`
  position: relative;
`;

export const SupportIconWrap = styled.span`
  position: absolute;
  top: -3px;
  left: -19px;
`;
