import { CreateDisposalModel, DeliveryModel } from "typings/server";
import { action, observable } from "mobx";
import { DeliveriesApi } from "api/DeliveryApi";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { BaseEditorModalVM, TBaseErrors } from "../BaseEditorModalVM";

type TError = TBaseErrors | "invalidOperationDate";

export class DisposalEditorModalVM extends BaseEditorModalVM<TError> {
  @observable operationDate: string = new Date().toISOString();

  private isOperationDateModified: boolean = false;

  @action
  setOperationDate = (date?: string) => {
    this.operationDate = date || "";
    this.isOperationDateModified = true;
    if (this.isSubmitted) {
      this.validate();
    }
  };

  @action
  async save(): Promise<string | void> {
    this.isSubmitted = true;
    if (this.validate()) {
      const model = new DeliveryModel();
      const reportDate =
        this.isOperationDateModified || (this.delivery && this.delivery.reportDate) ? this.operationDate : undefined;
      const operationDate = this.isOperationDateModified ? this.operationDate : undefined;

      const data = {
        reportDate, // for editing current delivery
        operationDate, // for new withdrawal delivery
        documentDate: this.documentDate || "",
        documentNumber: this.documentNumber,
      };
      Object.assign(model, data);

      if (this.isNew) {
        return await this.createNewOperation(data);
      }

      return await this.patchOperation(model);
    } else {
      return Promise.reject();
    }
  }

  async createNewOperation(model: CreateDisposalModel): Promise<string> {
    return await DeliveriesApi.addNewDisposal(model);
  }

  async patchOperation(model: DeliveryModel): Promise<void> {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    await this.store.saveDelivery(model, this.delivery!.id);
    this.delivery!.updateDelivery(model);
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (this.operationDate && new Date(this.operationDate) > new Date()) {
      this.errors.set("invalidOperationDate", "Дата не должна быть больше текущей даты и времени");
    }

    if (this.documentDate && new Date(this.documentDate) > new Date()) {
      this.errors.set("invalidDocDate", "Дата не должна быть больше текущей даты и времени");
    }

    if (!this.documentDate) {
      this.errors.set("docDateRequired", "Укажите дату документа-основания");
    }

    if (!this.documentNumber) {
      this.errors.set("docNumberRequired", "Укажите номер документа-основания");
    }
    return !this.errors.size;
  }

  @action
  setByDelivery(delivery: WithdrawalDeliveryModel) {
    this.operationDate = delivery.reportDate ? new Date(delivery.reportDate).toISOString() : new Date().toISOString();
    if (delivery.documentDate) {
      this.documentDate = new Date(delivery.documentDate).toISOString();
    }
    this.documentNumber = delivery.documentNumber || "";
  }
}
