import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ArrowChevronRight, Delete } from "@skbkontur/react-icons";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { Button, Input, Tooltip } from "components";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { ArrowWrap, DeleteIconWrap, Divider, TooltipTextWrap, PartTitle } from "./FractionDisposal.styles";

export interface FractionDisposalProps {
  vm: AdditionalInfoVM;
  codeModel: CodeModelExtended;
  defaultTotalParts?: number;
}

export const FractionDisposal = observer((props: FractionDisposalProps) => {
  const { codeModel, defaultTotalParts, vm } = props;
  const { code, totalParts, part, setPart, setTotalPart } = codeModel;

  const [showInputs, setShowInputs] = useState(false);

  const toggleShowInputs = () => {
    if (!showInputs) {
      vm.getPartialInfo(code);
    }
    setShowInputs(isShow => !isShow);
  };

  const { fractionValidationMap, fractionDisposalValidation, isSidePage } = vm;
  const validationInfo = fractionValidationMap?.get(code);

  useEffect(() => {
    if (defaultTotalParts && !totalParts) {
      setTotalPart(defaultTotalParts);
    }
  }, [defaultTotalParts]);

  useEffect(() => {
    if (part) {
      setShowInputs(true);
    }
  }, []);

  const onChangeTotalParts = (newTotalParts: string) => {
    const normalized = normalizeInput(newTotalParts, totalParts);
    fractionDisposalValidation?.(code, part, normalized);
    setTotalPart(normalized);
  };
  const onChangePart = (newPart: string) => {
    const normalized = normalizeInput(newPart, part);
    fractionDisposalValidation?.(code, normalized, totalParts);
    setPart(normalized);
  };

  const onClickPart = () => {
    if (!vm.codePartialInfo) {
      // Если инфы нет - попробуем запросить ее по клику
      // это нужно, если, например не было токена, его получили и кликают в инпут
      vm.getPartialInfo(code);
    }
  };

  const partTooltip = () => {
    if (validationInfo?.partErr) {
      return () => <TooltipTextWrap>{validationInfo?.partErr}</TooltipTextWrap>;
    }

    if (!!vm.codePartialInfo && !!vm.partialSuggestText(code) && vm.checkIsPartInvalid(part, code)) {
      return () => <TooltipTextWrap>{vm.partialSuggestText(code)}</TooltipTextWrap>;
    }
    return () => null;
  };

  const totalPartsTooltip = () => {
    if (validationInfo?.totalPartsErr) {
      return () => <TooltipTextWrap>{validationInfo?.totalPartsErr}</TooltipTextWrap>;
    }
    return () => null;
  };

  if (!isSidePage) {
    return (
      <div data-tid="FractionDisposal">
        {part ? (
          <>
            <span data-tid="FractionPartReadonly">{part}</span> из{" "}
            <span data-tid="FractionTotalPartsReadonly">{totalParts}</span>
          </>
        ) : (
          "—"
        )}
      </div>
    );
  }
  return showInputs ? (
    <div data-tid="FractionDisposal">
      <Tooltip render={partTooltip()} trigger="hover">
        <Input
          data-tid="FractionPart"
          value={part != null ? String(part) : ""}
          onValueChange={onChangePart}
          onClick={onClickPart}
          placeholder="Часть"
          width="57px"
          error={!!validationInfo?.partErr}
          warning={!!vm.checkIsPartInvalid(part, code)}
        />
      </Tooltip>
      <Divider>из</Divider>
      <Tooltip render={totalPartsTooltip()} trigger="hover">
        <Input
          data-tid="FractionTotalsPart"
          value={totalParts != null ? String(totalParts) : ""}
          onValueChange={onChangeTotalParts}
          placeholder="Общее кол-во"
          width="114px"
          error={!!validationInfo?.totalPartsErr}
        />
      </Tooltip>
      <DeleteIconWrap>
        <Delete data-tid="HideInputs" onClick={toggleShowInputs} />
      </DeleteIconWrap>
    </div>
  ) : (
    <Button data-tid="ShowInputs" use="link" onClick={toggleShowInputs} theme={{ linkButtonLineHeight: "32px" }}>
      <PartTitle>Вывести часть упаковки</PartTitle>
      <ArrowWrap>
        <ArrowChevronRight />
      </ArrowWrap>
    </Button>
  );

  function normalizeInput(input: string, prevVal?: number): number | undefined {
    if (input === "") {
      return undefined;
    }
    const parsed = parseInt(input);
    return Object.is(parsed, NaN) ? prevVal : parsed;
  }
});
