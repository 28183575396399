import { DeliveryStatus } from "typings/server";

export const AcceptanceDeliveryStatusNames: Record<DeliveryStatus, string> = {
  [DeliveryStatus.Draft]: "Черновик",
  [DeliveryStatus.New]: "Новая поставка",
  [DeliveryStatus.Processing]: "Идет приемка товара",
  [DeliveryStatus.Signing]: "Подписание",
  [DeliveryStatus.Signed]: "Подписание",
  [DeliveryStatus.Sending]: "Отправка",
  [DeliveryStatus.CreatingDocument]: "Создание документа",
  [DeliveryStatus.MdlpProcessing]: "Ожидание ИС МДЛП",
  [DeliveryStatus.WaitingForCounterparty]: "Ожидание поставщика",
  [DeliveryStatus.Success]: "Завершено",
  [DeliveryStatus.Failed]: "Ошибка",
  [DeliveryStatus.Recalled]: "Отозвано поставщиком",
  [DeliveryStatus.PartiallyRecalled]: "Частично отозвано\n\rпоставщиком",
  [DeliveryStatus.PartiallyAccepted]: "Частично принято",
  [DeliveryStatus.Rejected]: "Отказ от товаров",
  [DeliveryStatus.RecallFailed]: "Ошибка отказа/отзыва",
  [DeliveryStatus.PartiallyFailed]: "Завершено с ошибками",
  [DeliveryStatus.PartiallyFailedIncompleted]: "Частичная ошибка",
  [DeliveryStatus.Deleted]: "",
  [DeliveryStatus.PartiallyRejected]: "Частичный отказ от товаров",
};
export const ShipmentDeliveryStatusNames: Record<DeliveryStatus, string> = {
  [DeliveryStatus.Draft]: "Черновик",
  [DeliveryStatus.New]: "Новая отгрузка",
  [DeliveryStatus.Processing]: "Идет отгрузка товара",
  [DeliveryStatus.Signing]: "Подписание",
  [DeliveryStatus.Signed]: "Подписание",
  [DeliveryStatus.Sending]: "Отправка",
  [DeliveryStatus.CreatingDocument]: "Создание документа",
  [DeliveryStatus.MdlpProcessing]: "Ожидание ИС МДЛП",
  [DeliveryStatus.WaitingForCounterparty]: "Ожидание получателя",
  [DeliveryStatus.Success]: "Завершено",
  [DeliveryStatus.Recalled]: "Отозвано поставщиком",
  [DeliveryStatus.RecallFailed]: "Ошибка отказа/отзыва",
  [DeliveryStatus.PartiallyAccepted]: "Частично принято",
  [DeliveryStatus.Rejected]: "Отказ получателя",
  [DeliveryStatus.Failed]: "Ошибка",
  [DeliveryStatus.PartiallyFailed]: "Завершено с ошибками",
  [DeliveryStatus.PartiallyFailedIncompleted]: "Частичная ошибка",
  [DeliveryStatus.PartiallyRecalled]: "Частично отозвано\n\rпоставщиком",
  [DeliveryStatus.Deleted]: "",
  [DeliveryStatus.PartiallyRejected]: "Частичный отказ получателя",
};

export const DestructionDeliveryStatusNames: Record<DeliveryStatus, string> = {
  [DeliveryStatus.Draft]: "Черновик",
  [DeliveryStatus.New]: "Черновик",
  [DeliveryStatus.Processing]: "Черновик",
  [DeliveryStatus.Signing]: "Подписание",
  [DeliveryStatus.Signed]: "Подписание",
  [DeliveryStatus.Sending]: "Отправка",
  [DeliveryStatus.CreatingDocument]: "Создание документа",
  [DeliveryStatus.MdlpProcessing]: "Ожидание ИС МДЛП",
  [DeliveryStatus.WaitingForCounterparty]: "Уничтожение",
  [DeliveryStatus.Success]: "Завершено",
  [DeliveryStatus.Recalled]: "Отмена",
  [DeliveryStatus.RecallFailed]: "Ошибка отказа/отзыва",
  [DeliveryStatus.PartiallyAccepted]: "Частично принято",
  [DeliveryStatus.Rejected]: "Отказ от товаров",
  [DeliveryStatus.Failed]: "Ошибка",
  [DeliveryStatus.PartiallyFailed]: "Завершено с ошибками",
  [DeliveryStatus.PartiallyFailedIncompleted]: "Частичная ошибка",
  [DeliveryStatus.PartiallyRecalled]: "Частично отозвано\n\rпоставщиком",
  [DeliveryStatus.Deleted]: "",
  [DeliveryStatus.PartiallyRejected]: "Частичный отказ от товаров",
};

export const OperationsStatusNames: Record<DeliveryStatus, string> = {
  [DeliveryStatus.Draft]: "Черновик",
  [DeliveryStatus.New]: "Черновик",
  [DeliveryStatus.Processing]: "Черновик",
  [DeliveryStatus.Signing]: "Подписание",
  [DeliveryStatus.Signed]: "Подписание",
  [DeliveryStatus.Sending]: "Отправка",
  [DeliveryStatus.CreatingDocument]: "Создание документа",
  [DeliveryStatus.MdlpProcessing]: "Ожидание ИС МДЛП",
  [DeliveryStatus.WaitingForCounterparty]: "Ожидание поставщика",
  [DeliveryStatus.Success]: "Завершено",
  [DeliveryStatus.Recalled]: "Отмена",
  [DeliveryStatus.RecallFailed]: "Ошибка отказа/отзыва",
  [DeliveryStatus.PartiallyAccepted]: "Частично принято",
  [DeliveryStatus.Rejected]: "Отказ от товаров",
  [DeliveryStatus.Failed]: "Ошибка",
  [DeliveryStatus.PartiallyFailed]: "Завершено с ошибками",
  [DeliveryStatus.PartiallyFailedIncompleted]: "Частичная ошибка",
  [DeliveryStatus.PartiallyRecalled]: "Частично отозвано\n\rпоставщиком",
  [DeliveryStatus.Deleted]: "",
  [DeliveryStatus.PartiallyRejected]: "Частичный отказ от товаров",
};
