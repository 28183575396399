import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { List, Item, ActiveItem, ItemCaption } from "./ProgressMenu.styles";

interface IProgressItemsProps extends RouteComponentProps {
  items: { url: string; title: string }[];
}

export const ProgressMenu = withRouter((props: IProgressItemsProps) => {
  const { items, match } = props;

  return (
    <List>
      {items.map(({ title, url }) => {
        const isActive = match && url === match.url;
        const CurrentItem = isActive ? ActiveItem : Item;
        return (
          <CurrentItem key={title}>
            <ItemCaption isActive={isActive}>{title}</ItemCaption>
          </CurrentItem>
        );
      })}
    </List>
  );
});
