import React, { memo, useState } from "react";
import { observer } from "mobx-react";
import { Button, Modal, Select } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
// @TODO пошарить в коммон все, что нужно
import { DocumentHeader, EditorLabel, ModalBodyInner } from "../../Editor/EditorModal.styles";
import { UserStore } from "stores/UserStore";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { EditorFields } from "../../Editor/EditorFields";
import { DestructionEditorModalVM } from "../../Editor/DestructionEditorModal/DestructionEditorModalVM";
import { IType } from "typings/dictionaries";
import { DestructionMethodsNamesArr } from "features/Circulation/WithdrawalDictionary";
import { DestructionMethod, DestructionModel } from "typings/server";
import { DocumentDate } from "../../Common/DocumentDate";
import { DocumentNumber } from "../../Common/DocumentNumber";
import { DestructionApi } from "api/DestructionApi";

interface IOperationModalProps {
  onClose(): void;
  delivery: WithdrawalDeliveryModel;
  destruction?: DestructionModel;
  updateModel: () => void;
  onStartSendingSecondDocument: (v: boolean) => void;
}

export const DestructionModal = memo(
  (props: IOperationModalProps) => {
    const { destruction, delivery } = props;
    const vm = new DestructionEditorModalVM(WithdrawalStore, UserStore, delivery);
    return <DestructionModalInternal {...props} vm={vm} destruction={destruction} />;
  },
  // memo + () => true - не перерендериваем компонент
  () => true
);

interface Props extends IOperationModalProps, RouteComponentProps<CirculationRoute> {
  vm: DestructionEditorModalVM;
}

const DestructionModalInternal = withRouter(
  observer((props: Props) => {
    const { vm, destruction, updateModel, onStartSendingSecondDocument, onClose } = props;

    // Данные полей
    const [actDocumentNumber, setActDocumentNumber] = useState("");
    const [actDocumentDate, setActDocumentDate] = useState<string | undefined>("");
    const [destructionMethod, setDestructionMethod] = useState<IType>();

    // Ошибки
    const [showErrors, setShowErrors] = useState(false);

    const onDestructionMethodChange = (v: IType) => {
      setDestructionMethod(v);
    };

    const onDestructionClick = async () => {
      const isErrors = !destructionMethod || !actDocumentNumber || !actDocumentDate;
      setShowErrors(isErrors);

      try {
        if (!destruction?.id || isErrors) {
          return;
        }
        const model = {
          operationDate: vm.operationDate,
          actNumber: actDocumentNumber,
          actDate: actDocumentDate!,
          destructionMethod: destructionMethod?.value as DestructionMethod,
        };
        onStartSendingSecondDocument(true);
        await DestructionApi.destruct(destruction.id, model);
        await updateModel();
        onClose();
      } catch (e) {
        throw e;
      }
    };

    const renderItem = (item: IType) => item.label;

    return (
      <Modal data-tid="DestructionEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>Уничтожение</Modal.Header>
        <Modal.Body>
          <ModalBodyInner>
            <Grid cols="130px 1fr">
              <EditorFields.Sender vm={vm} required row={1} />
              <EditorFields.ReportDate vm={vm} required row={2} title="Дата уничтожения" />
              <GridCell col={1} row={3} padding="15px 4px 2px 4px" margin="0 0 24px 0">
                <EditorLabel breakText required>
                  Способ уничтожения
                </EditorLabel>
              </GridCell>
              <GridCell col={2} row={3} margin="0 0 24px 0">
                <Select<IType>
                  data-tid="DestructionMethod"
                  placeholder="Выберите способ уничтожения"
                  width="100%"
                  maxWidth="480px"
                  items={DestructionMethodsNamesArr}
                  onValueChange={onDestructionMethodChange}
                  renderItem={renderItem}
                  renderValue={renderItem}
                  value={destructionMethod}
                  maxMenuHeight={250}
                  error={!destructionMethod && showErrors}
                />
              </GridCell>
              <GridCell colStart={1} colEnd={3} row={4}>
                <DocumentHeader>Акт об уничтожении</DocumentHeader>
              </GridCell>
              <DocumentNumber
                value={actDocumentNumber}
                setValue={setActDocumentNumber}
                row={5}
                getError={() => (showErrors && !actDocumentNumber ? "Укажите номер акта уничтожения" : undefined)}
              />
              <DocumentDate
                row={6}
                documentDate={actDocumentDate || ""}
                setDocumentDate={setActDocumentDate}
                getError={() => (showErrors && !actDocumentDate ? "Укажие дату акта уничтожения" : undefined)}
              />
            </Grid>
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer panel>
          <FooterBtnWrapper>
            <Button data-testid="Submit" use="primary" size={"large"} onClick={onDestructionClick}>
              Отправить на уничтожение
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отменить
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  })
);
