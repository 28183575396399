import styled from "styles/styled-components";

export const Container = styled.div`
  margin-left: 6px;
`;

export const ControlPanel = styled.div`
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;
  align-items: center;
`;

export const DateText = styled.div`
  margin-right: 17px;
`;
