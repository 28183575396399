import styled from "styles/styled-components";

export const MenuItemCopy = styled.div`
  width: 100%
  cursor: pointer;
  display: block;
  line-height: 18px;
  padding: 6px 18px 7px 8px;
  position: relative;
  text-decoration: none;

  &:hover {
    background: ${props => props.theme.colors.lightBackground};
  }
`;
