import React, { memo, FC } from "react";
import { MenuItemFixed } from "./MenuItemFixed";
import { MenuItem as KitMenuItem, EllipsisText } from "../../../";
import { DepartmentTypeNames } from "typings/dictionaries";
import { DepartmentModel } from "typings/server";
import { ErrorBoundary } from "../../../ErrorBoundary";
import { Row, Dot } from "./MenuItem.styles";

interface Props {
  department: DepartmentModel;
  isSelected: boolean;
  hasNew: boolean;
  chooseDepartment: (department: DepartmentModel) => Promise<void>;
}

export const MenuItem: FC<Props> = memo(({ department, isSelected, hasNew, chooseDepartment }) => {
  const chooseDep = async () => {
    try {
      await chooseDepartment(department);
      window.location.reload();
    } catch (e) {
      ErrorBoundary.showErrorNotification("Не смогли сменить подразделение", "Ошибка");
      throw e;
    }
  };
  return (
    <KitMenuItem
      component={MenuItemFixed}
      onClick={chooseDep}
      state={isSelected ? "selected" : null}
      comment={
        <EllipsisText hintPos="right" width={480} hintWidthCoefficient={0.65}>
          <>
            {department.type ? DepartmentTypeNames[department.type] : ""}
            ,&nbsp;
            {department.mdlpSubjectId ? `№ ${department.mdlpSubjectId}` : ""}
          </>
        </EllipsisText>
      }
    >
      <Row>
        <EllipsisText hintPos="right" width={hasNew ? 448 : 480} hintWidthCoefficient={0.65}>
          {department.name || department.address}
        </EllipsisText>
        {hasNew && <Dot />}
      </Row>
    </KitMenuItem>
  );
});
