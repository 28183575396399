import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import { EmployeeModel, EmployeePatchModel, InviteModel } from "typings/server";

class EmployeesApiInternal {
  constructor(private httpClient: IHttpClient) {}

  invite(model: InviteModel): Promise<void> {
    return this.httpClient.post("invite", model);
  }

  getEmployees(): Promise<EmployeeModel[]> {
    return this.httpClient.get("");
  }

  delete(id: string): Promise<void> {
    return this.httpClient.delete(id);
  }

  patch(id: string, model: EmployeePatchModel): Promise<void> {
    return this.httpClient.patch(id, model);
  }
}

export const EmployeesApi = new EmployeesApiInternal(getHttpClient(drugsApi + "/employees"));
