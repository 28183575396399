import styled from "styles/styled-components";

export const DeliveryStagesBlock = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const UserNameSeparator = styled.span`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 20px;
`;

export const GoodsWrapperBlock = styled.div`
  padding: 11px 5px 0 15px;
`;
