import { observable } from "mobx";

export type Advertisement =
  | "massDisaggregation"
  | "disposalRegistrator"
  | "stateDispatch"
  | "balance"
  | "fractionDisposal"
  | "destruction"
  | "statuses"
  | "suspendedReturn";

type AdvertisementSerialized = {
  [key in Advertisement]: boolean;
};

const advKey = "showAdvertisement";

class AdvertisementStoreInternal {
  @observable isShowAdvertisementMap: Map<Advertisement, boolean> = new Map();

  private currentDate = Date.now();

  // Здесь задаётся дата, до которой будет показываться промка
  defaultValues: AdvertisementSerialized = {
    disposalRegistrator: this.currentDate < new Date("2021-9-20").getTime(),
    massDisaggregation: this.currentDate < new Date("2021-9-20").getTime(),
    stateDispatch: this.currentDate < new Date("2021-9-20").getTime(),
    balance: this.currentDate < new Date("2021-9-20").getTime(),
    fractionDisposal: this.currentDate < new Date("2021-9-20").getTime(),
    destruction: this.currentDate < new Date("2021-10-20").getTime(),
    statuses: this.currentDate < new Date("2021-11-20").getTime(),
    suspendedReturn: this.currentDate < new Date("2021-12-01").getTime(),
  };

  constructor() {
    this.getAllAdvertisementsStatus();
  }

  public setAdvertisementStatus(key: Advertisement, value: boolean) {
    this.isShowAdvertisementMap.set(key, value);
    const serialized = Object.fromEntries(this.isShowAdvertisementMap);
    localStorage.setItem(advKey, JSON.stringify(serialized));
  }

  private getAllAdvertisementsStatus() {
    const serialized = this.getSerializedFromStorage();
    for (let key in serialized) {
      const typedKey = key as Advertisement;
      this.isShowAdvertisementMap.set(typedKey, serialized[typedKey]);
    }
  }

  private getSerializedFromStorage(): AdvertisementSerialized {
    const strVal = localStorage.getItem(advKey);
    return strVal ? this.normalizeStorageData(strVal) : this.defaultValues;
  }

  private normalizeStorageData(strVal: string) {
    const parsed: AdvertisementSerialized = JSON.parse(strVal);
    const parsedKeys = Object.keys(parsed);

    for (let key in this.defaultValues) {
      if (!parsedKeys.includes(key)) {
        parsed[key as Advertisement] = true;
      }
    }
    return parsed;
  }
}

export interface IAdvertisementStore extends AdvertisementStoreInternal {}

export const AdvertisementStore = new AdvertisementStoreInternal();
