import { action, computed, observable, toJS } from "mobx";
import {
  AcceptusType,
  CounteragentDepartmentModel,
  CounteragentInfoModel,
  DeliveryItemModel,
  DeliveryItemStatus,
  DeliveryModel,
  DeliveryStatus,
  DepartmentModel,
  ErrorInfoModel,
  PatchDeliveryModel,
  StepInfoModel,
  UtdPositionModel,
} from "typings/server";
import { ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStage, ShipmentStageNames } from "Common/DeliveryDictionary";
import { UtdPositionModelExtended } from "./ItemModel/UtdPositionModelExtended";
import { DeliveryItemModelExtended } from "./ItemModel/DeliveryItemModelExtended";
import { copyOnlyExistingProperties } from "helpers/object";
import { DeliveryActions } from "./DeliveryModelActions";

export interface IAcceptanceDeliveryStage {
  acceptanceStage?: DeliveryStage;
  name: string;
  progress: ProgressStage;
  completionDate?: string;
  completionUserName?: string;
  errorInfo?: ErrorInfoModel;
}

export class ShipmentDeliveryModel extends DeliveryModel {
  // шаги отгрузки для отображения в интерфейсе
  @observable stages: Record<DeliveryStage | string, IAcceptanceDeliveryStage> = Object.create({});
  @observable items: DeliveryItemModelExtended[];
  @observable utdPositions: UtdPositionModelExtended[];
  @observable status: DeliveryStatus;
  @observable supplier: CounteragentInfoModel;
  @observable confirmPaused: boolean;
  @observable reportDate: string | undefined;
  @observable stepsInfo: { [key in DeliveryStatus]: StepInfoModel };
  @observable acceptusType: AcceptusType;
  @observable isRecalled: boolean;
  @observable isRejected: boolean;
  @observable isPartiallyFailed: boolean;
  @observable counteragentDepartment: CounteragentDepartmentModel;
  @observable childDeliveries: ShipmentDeliveryModel[] = [];
  @observable parentDelivery?: ShipmentDeliveryModel;
  allCodesLength: number = 0;
  private readonly actions: DeliveryActions;

  constructor(readonly deliveryModel: DeliveryModel) {
    super();
    Object.assign(this, deliveryModel);
    this.deliveryModel = deliveryModel;
    this.actions = new DeliveryActions(deliveryModel);
    this.confirmPaused = deliveryModel.confirmPaused || false;
    if (deliveryModel.items) {
      this.items = this.getExtendedItems(deliveryModel.items);
      if (deliveryModel.utdPositions) {
        this.utdPositions = this.getExtendedUtdPositions(deliveryModel.items, deliveryModel.utdPositions);
      }
    }

    if (this.childDeliveries?.length) {
      this.childDeliveries = this.childDeliveries.map(child => {
        const childModel = new ShipmentDeliveryModel(child);
        childModel.parentDelivery = this;
        return childModel;
      });
    } else this.childDeliveries = [];

    if (deliveryModel.stepsInfo) {
      this.setStages(deliveryModel.stepsInfo);
    }

    this.isRecalled =
      this.items?.some(x => x.status === DeliveryItemStatus.Recalled) ||
      (this.status === DeliveryStatus.RecallFailed && this.allItemsCodes.length === this.failedCodesLen);

    this.isRejected =
      this.items?.some(x => x.status === DeliveryItemStatus.Rejected) ||
      (this.status === DeliveryStatus.RecallFailed && this.allItemsCodes.length === this.failedCodesLen);

    this.isPartiallyFailed = this.childDeliveries?.some(x => x.status === DeliveryStatus.Failed);
  }

  private getExtendedItems(items: DeliveryItemModel[]): DeliveryItemModelExtended[] {
    // для всех AcceptusType, кроме обратного акцепта, все, что приходит в items – это отсканированные коды
    const scanned = this.acceptusType !== AcceptusType.Reverse;
    const groupByName = this.actions.getGroupedCodes(items, { groupBy: "name", isAllScanned: scanned });
    return this.actions.processItems(items, groupByName);
  }

  private getExtendedUtdPositions(
    items: DeliveryItemModel[],
    utdPositions: UtdPositionModel[]
  ): UtdPositionModelExtended[] {
    const scanned = this.acceptusType !== AcceptusType.Reverse;
    const groupById = this.actions.getGroupedCodes(items, { groupBy: "positionId", isAllScanned: scanned });
    return this.actions.processUtdPositions(utdPositions, groupById);
  }

  /**
   * получаем из stepsInfo шаги приемки для отображения в интерфейсе
   * @param delivery
   */
  @action
  private setStages(stepsInfo: { [key in DeliveryStatus]: StepInfoModel }) {
    const mappedSteps = this.actions.mapSteps(stepsInfo);
    const isCompleted = this.isRecalled;
    this.stages = this.actions.getStages(mappedSteps, ShipmentStageNames, { isCompleted });
  }

  @action
  setItems(items: DeliveryItemModelExtended[]) {
    this.items = [...items];
  }

  @action
  setNextStage(
    status: DeliveryStatus,
    stageName: DeliveryStage,
    stage: Partial<IAcceptanceDeliveryStage>,
    userName: string
  ) {
    // old status
    const info = { status: this.status, completionDate: stage.completionDate, userName };
    // update status
    this.status = status;

    this.stages = this.actions.setNextStage(this.stages, stageName, stage);
    this.stepsInfo = this.actions.updateStepInfo(this.stepsInfo, info);
  }

  @action
  update(model: PatchDeliveryModel, acceptusType: AcceptusType, counteragentDepartment?: DepartmentModel) {
    copyOnlyExistingProperties(this, model);
    this.acceptusType = acceptusType;
    if (this.recipient) {
      if (model.counteragentInn) this.recipient.inn = model.counteragentInn;
      if (model.counteragentCountry) this.recipient.country = model.counteragentCountry;
    }

    if (counteragentDepartment) {
      this.counteragentDepartment = counteragentDepartment;
    }
  }

  @action
  setConfirmPaused(val: boolean) {
    this.confirmPaused = val;
  }

  @action
  setReportDate(val: string | undefined) {
    this.reportDate = val;
  }

  @action
  setRecalled(val: boolean) {
    this.isRecalled = val;
  }

  @action
  addChildDelivery(overrideFields?: Partial<ShipmentDeliveryModel>) {
    const delivery = new ShipmentDeliveryModel({
      ...toJS(this.deliveryModel),
      items: [],
      status: DeliveryStatus.Processing,
      childDeliveries: [],
      id: "new",
      reportDate: undefined,
      ...overrideFields,
    });
    this.childDeliveries.push(delivery);
  }

  @action
  replaceChildDelivery(delivery: DeliveryModel) {
    const ind = this.childDeliveries.findIndex(d => d.id === delivery.id);
    if (ind !== -1) {
      const acceptanceDelivery = new ShipmentDeliveryModel(delivery);
      this.childDeliveries.splice(ind, 1, acceptanceDelivery);
    }
  }

  @computed
  get allScannedLen(): number {
    return this.items.reduce(
      (count: number, item: DeliveryItemModelExtended) => (count += item.ssccScanLen + item.sgtinScanLen),
      0
    );
  }

  @computed
  get allItemsCodes(): string[] {
    return this.items.map(item => item.allCodes.map(codeItem => codeItem.code)).flat();
  }

  @computed
  get allCodesLen(): number {
    if (this.acceptusType === AcceptusType.Reverse) {
      if (!this.allCodesLength)
        this.allCodesLength = this.items.reduce(
          (sum, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length),
          0
        );
      return this.allCodesLength;
    }
    return this.utdPositions.reduce((sum, item) => (sum += item.ssccCodes.length + item.sgtinCodes.length), 0);
  }

  // поставка активна, если с ней можно совершить действие и у нее нет подпоставок
  @computed
  get isActive(): boolean {
    const editable = [
      DeliveryStatus.Processing,
      DeliveryStatus.New,
      DeliveryStatus.Signing,
      DeliveryStatus.Signed,
      DeliveryStatus.CreatingDocument,
    ];
    return editable.includes(this.status) && this.childDeliveries.length === 0 && !this.isRecalled;
  }

  @computed
  get acceptedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Accepted);
  }

  @computed
  get failedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Error);
  }

  @computed
  get recalledCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Recalled);
  }

  @computed
  get rejectedCodesLen() {
    return this.getDeliveryCodesLenByStatus(DeliveryItemStatus.Rejected);
  }

  private getDeliveryCodesLenByStatus(status: DeliveryItemStatus): number {
    return this.items.reduce(
      (count: number, item: DeliveryItemModelExtended) =>
        (count += item.status === status && item.allCodes ? item.allCodes.length : 0),
      0
    );
  }
}
