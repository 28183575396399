import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { Button, Input, Modal, Toast, Toggle, Tooltip } from "components";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { TextDarkRed, TextGrey } from "styles/shared.styles";
import { CrownIconWrap, EditorAdminTextWrap, EditorEmailWrap, EditorTitle, EditorWrap } from "./EmployeeEditor.styles";
import CrownIcon from "@skbkontur/react-icons/Crown";
import { EmployeeEditorVM } from "./EmployeeEditorVM";
import { DepartmentModel } from "typings/server";
import { EmployeeDepartments } from "./EmployeeDepartments";
import { SettingsStore } from "stores/SettingsStore";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";
import { UserStore } from "stores/UserStore";
import { Link } from "@skbkontur/react-ui";
import { isDevelopmentEnv } from "helpers/environment";

interface EmployeeEditorModalInternal extends IEmployeeEditorModalProps {
  vm: EmployeeEditorVM;
}

interface IEmployeeEditorModalProps {
  selectedEmployee?: EmployeeModelExtended;
  onClose(): void;
}

const EmployeeEditorModalInternal: React.FC<EmployeeEditorModalInternal> = observer(
  (props: EmployeeEditorModalInternal) => {
    const { vm } = props;

    const onClose = () => props.onClose();

    const onToggleAdminRole = () => vm.toggleAdminRole();

    const toggleAllDepartments = () => vm.toggleAllDepartments();

    const changeAvailableDepartments = (deps: DepartmentModel[]) => vm.changeDepartments(deps);

    const onChangeEmail = (value: string) => {
      vm.changeEmail(value);
    };

    const onBlur = (e: SyntheticEvent) => {
      e.preventDefault();
      vm.validateEmail();
    };

    const renderEmailError = () => vm.errors.get("requiredEmail") || vm.errors.get("invalidEmail");

    const save = async () => {
      if (vm.isValid()) {
        await vm.save();
        Toast.push(vm.isNew ? "Приглашение отправлено" : "Сохранено");
        onClose();
      }
    };

    return (
      <Modal data-tid="EmployeeEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>
          {!vm.isNew ? vm.employeeModel.fio || vm.employeeModel.email : "Приглашение сотрудника по электронной почте"}
        </Modal.Header>
        <Modal.Body>
          <EditorWrap>
            {vm.isShowPortalLink && !vm.isNew && (
              <Link
                href={
                  isDevelopmentEnv()
                    ? `https://adminv2.testkontur.ru/users/${vm.employeeModel.portalId}`
                    : `https://adminv2.kontur.ru/users/${vm.employeeModel.portalId}`
                }
                target="_blank"
              >
                Найти на портале
              </Link>
            )}

            {vm.errors.has("duplicateInvite") && <TextDarkRed>&nbsp;{vm.errors.get("duplicateInvite")}</TextDarkRed>}
            <Grid cols={"142px 1fr"}>
              <GridCell col={1} row={1} minHeight={"50px"}>
                <EditorTitle required={vm.isNew} marginTop={vm.isNew ? "7px" : "0px"}>
                  Эл. почта
                </EditorTitle>
              </GridCell>
              <GridCell col={2} row={1}>
                {!vm.isNew ? (
                  vm.employeeModel.email || "—"
                ) : (
                  <>
                    <Tooltip trigger={"hover"} render={renderEmailError}>
                      <Input
                        data-tid="Email"
                        placeholder={"Адрес электронной почты сотрудника"}
                        width={"410px"}
                        onValueChange={onChangeEmail}
                        onBlur={onBlur}
                        autoFocus
                        error={vm.errors.has("invalidEmail") || vm.errors.has("requiredEmail")}
                      />
                    </Tooltip>
                    <EditorEmailWrap>
                      <TextGrey>
                        На адрес будет выслано приглашение в организацию.
                        <br />
                        Почта сотрудника будет его логином для входа.
                      </TextGrey>
                    </EditorEmailWrap>
                  </>
                )}
              </GridCell>
              <GridCell col={1} row={2} minHeight={"104px"}>
                {!vm.isNew ? "Доступ" : "Роль"}
              </GridCell>
              <GridCell col={2} row={2}>
                <Toggle data-tid="IsAdmin" checked={vm.isAdmin} onValueChange={onToggleAdminRole}>
                  <CrownIconWrap active={vm.isAdmin}>
                    <CrownIcon />
                  </CrownIconWrap>
                  Администратор
                </Toggle>
                <EditorAdminTextWrap>
                  <TextGrey>
                    Администратор может добавлять и удалять других сотрудников
                    <br /> и настраивать права доступа к подразделениям
                  </TextGrey>
                </EditorAdminTextWrap>
              </GridCell>
              <GridCell col={1} row={3} minHeight={"120px"}>
                <EditorTitle required={vm.isNew}>Подразделение</EditorTitle>
              </GridCell>
              <GridCell col={2} row={3}>
                <EmployeeDepartments
                  error={vm.errors.get("requiredDepartment")}
                  allDepartmentsChecked={vm.allDepartmentsChecked}
                  allDepartments={vm.allDepartments}
                  isAdmin={vm.isAdmin}
                  availableDepartments={vm.employeeModel.availableDepartments}
                  toggleAllDepartments={toggleAllDepartments}
                  changeAvailableDepartments={changeAvailableDepartments}
                />
              </GridCell>
            </Grid>
          </EditorWrap>
        </Modal.Body>
        <Modal.Footer sticky panel>
          <Button data-tid="Submit" use={"primary"} onClick={save} disabled={!vm.isEditable} size={"large"}>
            {vm.isNew ? "Пригласить" : "Сохранить"}
          </Button>
          &nbsp;&nbsp;
          <Button data-tid="Cancel" onClick={onClose} size={"large"}>
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export const EmployeeEditorModal = React.memo(
  (props: IEmployeeEditorModalProps) => {
    const { selectedEmployee } = props;
    const vm = new EmployeeEditorVM(UserStore, SettingsStore, selectedEmployee);

    return <EmployeeEditorModalInternal {...props} vm={vm} />;
  },
  () => true
);
