import React, { memo, useContext, FC, useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { UtdScanItemRow } from "./UtdScanItemRow";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { CodesWrap } from "../../ScannerSidePage.styles";
import { UtdScanSidePageVM } from "./UtdScanSidePageVM";
import { ScannerCounter } from "../../ScannerCounter/ScannerCounter";
import { EmptyCodesRow } from "./EmptyCodesRow";
import { MdlpModal } from "features/CertificatesList/MdlpModal";
import { ICertificate } from "@skbkontur/plugin-js";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { ExpandAllIconWrap, TableHeadCellWrap } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { ExpandAllIcon, CollapseAllIcon } from "icons";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { DeliveryType } from "typings/server";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";

interface ScannerPageBodyProps {
  vm: UtdScanSidePageVM;
}

export const UtdScanPageBody = observer((props: ScannerPageBodyProps) => {
  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const { vm } = props;
  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;
  const additionalInfoVM = React.useMemo(
    () => new AdditionalInfoVM(vm.sortedCodes, vm.additionalInfo, true, deliveryType, extraFields),
    [vm.sortedCodes]
  );

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
  };

  const onCloseMdlpModal = () => vm.toggleModal("mdlpModal");
  const onDismiss = () => {
    onCloseMdlpModal();
    vm.setCertNotFoundError();
  };

  const onDiscardItem = (item: CodeModelExtended) => vm.discardItem(item);
  const onDiscardCode = (code: string) => vm.discardCode(code);

  return (
    <>
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={onCloseMdlpModal}
          title={"Доступ в ИС МДЛП"}
          sign={onSign}
          onDismiss={onDismiss}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}

      <ScannerCounter title={"Отсканировано кодов маркировки"} allScannedLen={vm.allScannedLen} />
      <TableHeadCellWrap top="7px" right="13px" hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
        <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
      </TableHeadCellWrap>
      <Grid cols={"40px 4fr 1fr 1fr"} cellsBorderBottom>
        <GridCellHeader col={1} row={1}>
          №
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Коды маркировки
        </GridCellHeader>
      </Grid>

      <Table
        sortedCodes={vm.sortedCodes}
        newCode={vm.newCode}
        additionalInfoVM={additionalInfoVM}
        newCodeItem={vm.newCodeItem}
        count={vm.sortedCodes.length}
        allCodesExpanded={allCodesExpanded}
        onDiscardItem={onDiscardItem}
        onDiscardCode={onDiscardCode}
      />
    </>
  );
});

interface TableProps {
  sortedCodes: CodeModelExtended[];
  newCode?: string;
  additionalInfoVM: AdditionalInfoVM;
  newCodeItem?: CodeModelExtended;
  count: number;
  allCodesExpanded: boolean;
  onDiscardItem: (item: CodeModelExtended) => void;
  onDiscardCode: (code: string) => void;
}

const Table: FC<TableProps> = memo(
  props => {
    const { sortedCodes, newCodeItem, allCodesExpanded, additionalInfoVM, count, onDiscardItem, onDiscardCode } = props;

    if (!sortedCodes.length) {
      return (
        <CodesWrap data-tid="ScannedCodes">
          <EmptyCodesRow />
        </CodesWrap>
      );
    }

    return (
      <CodesWrap data-tid="ScannedCodes">
        {sortedCodes.map((item, i) => (
          <UtdScanItemRow
            isNew={newCodeItem === item}
            item={item}
            index={count - 1 - i}
            allCodesExpanded={allCodesExpanded}
            key={item.index}
            onDiscardItem={onDiscardItem}
            onDiscardCode={onDiscardCode}
            additionalInfoVM={additionalInfoVM}
          />
        ))}
      </CodesWrap>
    );
  },
  (prevProps, nextProps) => prevProps.count === nextProps.count
);
