import React, { PropsWithChildren, useMemo } from "react";
import {
  ThemeContext as ReactUiThemeContext,
  Dropdown,
  DropdownProps,
  FLAT_THEME,
  ThemeFactory,
} from "@skbkontur/react-ui";
import { theme } from "styles/theme";

export const ThemedDropdown = (props: PropsWithChildren<DropdownProps>) => {
  const updatedTheme = useMemo(() => {
    return ThemeFactory.create(
      {
        fontSizeMedium: theme.font.mediumSize,
        btnFontSizeMedium: theme.font.mediumSize,
        controlPaddingYSmall: theme.btn.paddingTopM,
        btnDefaultHoverBg: theme.colors.bgHoverGreyLight,
        btnDefaultHoverBorderColor: theme.colors.borderColorHover,
        dropdownMenuHoverBg: theme.colors.bluePrimary,
      },
      FLAT_THEME
    );
  }, []);

  return (
    <ReactUiThemeContext.Provider value={updatedTheme}>
      <Dropdown {...props} size={props.size || "small"}>
        {props.children}
      </Dropdown>
    </ReactUiThemeContext.Provider>
  );
};
