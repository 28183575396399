import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import {
  CodeErrorModel,
  CodeInfoResponseModel,
  CodesInfoRequest,
  PartialDisposalModel,
  SgtinInfoModel,
  SsccInfoModel,
} from "typings/server";

class CodesApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getCodesInfo(
    codesInfo: CodesInfoRequest,
    signal?: AbortSignal
  ): Promise<Array<SsccInfoModel | SgtinInfoModel | CodeErrorModel>> {
    return this.httpClient.post("", codesInfo, undefined, undefined, signal);
  }

  getPartialInfo(code: string): Promise<PartialDisposalModel> {
    return this.httpClient.post(`partial/${code}`);
  }

  getCodesStatus(codes: string[]): Promise<CodeInfoResponseModel> {
    return this.httpClient.post(`statuses`, { codes });
  }
}

export const CodesApi = new CodesApiInternal(getHttpClient(drugsApi + "/codes"));
