import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { PackingRoute } from "routes";
import { Loader } from "components/Loader";
import { PackingStore } from "stores/PackingStore";
import { ExtractionPage } from "./ExtractionPage/ExtractionPage";
import { ExtractionPageVM } from "./ExtractionPage/ExtractionPageVM";
import { SignatureStore } from "stores/SignatureStore";
import { DeliveryModel, DeliveryType } from "typings/server";
import { UserStore } from "stores/UserStore";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";

export const DetailsPageWrapper = (props: RouteComponentProps<PackingRoute>) => {
  const { match } = props;
  const id = match.params.operationId;
  const [loading, setLoading] = useState<boolean>(true);
  const [delivery, setDelivery] = useState<DeliveryModel | null>(null);

  useEffect(() => {
    if (id) {
      PackingStore.getItem(id, { force: true })
        .then(delivery => {
          PackingStore.setSelected(delivery);
          PackingStore.changeType(delivery.type);
          setDelivery(delivery);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) return <Loader active minHeight={"80vh"} />;
  if (!delivery) return <>Поставка не найдена</>;

  return (
    <DeliveryTypeContext.Provider value={delivery.type}>
      <ExtractionPage
        vm={new ExtractionPageVM(PackingStore, SignatureStore, UserStore, DeliveryType.Extraction)}
        {...props}
      />
    </DeliveryTypeContext.Provider>
  );
};
