import React, { memo } from "react";
import { Input, Tooltip } from "components";
import { GridCell } from "components/Grid/Grid.styles";
import { EditorLabel } from "../Editor/EditorModal.styles";

interface Props {
  row: number;
  value: string;
  getError: () => string | undefined;
  setValue: (v: string) => void;
}

export const DocumentNumber = memo((props: Props) => {
  const { row, value, setValue, getError } = props;

  return (
    <>
      <GridCell col={1} row={row} padding={"15px 4px 8px"}>
        <EditorLabel required>№ документа</EditorLabel>
      </GridCell>
      <GridCell col={2} row={row}>
        <Tooltip trigger="hover" render={getError}>
          <Input
            width="120px"
            data-testid="DocumentNumber"
            value={value}
            onValueChange={setValue}
            error={!!getError()}
          />
        </Tooltip>
      </GridCell>
    </>
  );
});
