import React from "react";
import { observer } from "mobx-react";
import { Button, Modal } from "components";
import { Footer, FooterBtnWrapper } from "components/Layout/Footer.styles";
import {
  AcceptanceModalText,
  AcceptedIconWrap,
  Msg,
  RecalledText,
  RecallIconWrap,
  TrustedText,
} from "./TrustedAcceptanceModal.styles";
import { DirectAcceptancePageVM } from "../../DirectAcceptance/DirectAcceptancePageVM";
import { AcceptedStatusIcon, CheckIconGreen, RecalledStatusIcon } from "icons";
import { getEnding } from "helpers/endings";

interface ITrustedAcceptModalProps {
  vm: DirectAcceptancePageVM;
}

export const TrustedAcceptModal: React.FC<ITrustedAcceptModalProps> = observer((props: ITrustedAcceptModalProps) => {
  const { vm } = props;
  const onClose = () => vm.toggleModal("trustedAccept");
  const onAccept = () => {
    vm.setSubmitted(true);
    vm.onTrustedAccept();
  };

  const sendCodes = vm.activeDelivery.allCodesLen - vm.recalledLength;

  return (
    <Modal data-tid="TrustedAcceptModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>Доверительная приемка</Modal.Header>
      <Modal.Body>
        <AcceptanceModalText>
          {!!vm.recalledLength ? (
            <>
              <RecalledText>
                <RecallIconWrap>
                  <RecalledStatusIcon size={"large"} />
                </RecallIconWrap>
                <span>
                  <b>
                    {vm.recalledLength} {getEnding(vm.recalledLength, "код отозван", "кода отозвано", "кодов отозвано")}
                  </b>
                  &nbsp; поставщиком
                </span>
              </RecalledText>
              <RecalledText>
                <AcceptedIconWrap>
                  <AcceptedStatusIcon size={"large"} />
                </AcceptedIconWrap>
                <span>
                  <b>
                    {sendCodes} из {vm.activeDelivery.allCodesLen} кодов
                  </b>
                  &nbsp;будут приняты и отправлены в ИС МДЛП.
                  <br /> Сверка товаров будет завершена.
                </span>
              </RecalledText>
            </>
          ) : (
            <TrustedText>
              <AcceptedIconWrap>
                <CheckIconGreen />
              </AcceptedIconWrap>
              <span>
                {vm.activeDelivery.allCodesLen === 1 ? (
                  <Msg>Один код будет принят и отправлен в ИС МДЛП.</Msg>
                ) : (
                  <Msg>
                    Все&nbsp;
                    <b>
                      {vm.activeDelivery.allCodesLen} {getEnding(vm.activeDelivery.allCodesLen, "код", "кода", "кодов")}
                    </b>
                    &nbsp;будут приняты и отправлены в ИС МДЛП.
                  </Msg>
                )}
                Сверка товаров будет завершена.
              </span>
            </TrustedText>
          )}
        </AcceptanceModalText>
      </Modal.Body>
      <Footer>
        <FooterBtnWrapper>
          <Button data-tid="Submit" use="primary" size={"large"} onClick={onAccept}>
            Завершить
          </Button>
          <Button data-tid="Cancel" onClick={onClose} size={"large"}>
            Отменить
          </Button>
        </FooterBtnWrapper>
      </Footer>
    </Modal>
  );
});
