import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { DropdownMenu, Input } from "components";
import { FilterDropdownItem } from "./FilterDropdownItem/FilterDropdownItem";
import { FilterTableHeader } from "./FilterTableHeader/FilterTableHeader";
import {
  DropdownItemsWrap,
  DropdownMenuWrap,
  InputWrap,
  maxDropdownHeight,
  RegularHeader,
} from "./FilterDropdown.styles";
import { DeliveriesMainPageVM } from "../../DeliveriesMainPageVM";
import { FilterDropdownVM } from "./FilterDropdownVM";
import { WithFilterInfo } from "models/Filter/FilterModel";
import SearchIcon from "@skbkontur/react-icons/Search";

interface FilterDropdownProps<T> {
  value: WithFilterInfo<T>[];
  vm: DeliveriesMainPageVM;
  caption: string;
  isSearchAvailable?: boolean;
  tableHeaderPaddings?: "small" | "middle";
  renderCustomItemLabel?(value: WithFilterInfo<T>): JSX.Element | string;
}

function FilterDropdownInternal<T>({
  caption,
  vm,
  value,
  isSearchAvailable,
  tableHeaderPaddings,
  renderCustomItemLabel,
}: FilterDropdownProps<T>) {
  const filterQueryInput = useRef<typeof Input | null>(null);
  const itemsWrapper = useRef<HTMLDivElement | null>(null);
  const [inputWidth, setInputWidth] = useState<string | null>(null);

  const localVM = useMemo(() => new FilterDropdownVM<T>(vm, value), [vm]);
  useEffect(() => {
    localVM.setItems(value);
  }, [vm, value]);

  useEffect(() => {
    localVM.setIsPreserveFilters(false);
    return () => {
      if (!localVM.isPreserveFilters) {
        localVM.resetAllFiltersAndPage();
      }
    };
  }, []);

  if (localVM.isHeaderDisabled) {
    return <RegularHeader>{caption}</RegularHeader>;
  }

  return (
    // Враппер нужен, чтобы минимизировать отступ между хедером таблицы и линией в IE
    <DropdownMenuWrap>
      <DropdownMenu
        caption={
          <FilterTableHeader
            isActive={localVM.isActive}
            caption={caption}
            tableHeaderPaddings={tableHeaderPaddings}
            isAnyFilterApplied={localVM.isFilterApplied}
          />
        }
        menuMaxHeight={`${maxDropdownHeight}px`}
        onOpen={onDropdownOpen}
        onClose={onDropdownClose}
      >
        <DropdownItemsWrap ref={itemsWrapper}>
          {isSearchAvailable && localVM.items.length > 10 && (
            <InputWrap>
              <Input
                data-tid="SearchInput"
                value={localVM.filterQuery}
                ref={filterQueryInput}
                onValueChange={localVM.setFilterQuery}
                rightIcon={<SearchIcon />}
                placeholder={"Поиск по названию"}
                width={inputWidth || "100%"}
              />
            </InputWrap>
          )}
          {localVM.filteredItems.map((x: WithFilterInfo<T>) => (
            <FilterDropdownItem
              key={(x.name as unknown) as string}
              value={x}
              onClick={localVM.handleItemClick}
              renderCustomItemLabel={renderCustomItemLabel}
            />
          ))}
        </DropdownItemsWrap>
      </DropdownMenu>
    </DropdownMenuWrap>
  );

  function onDropdownOpen() {
    setInputWidth(width => width || (itemsWrapper.current && `${itemsWrapper.current.offsetWidth}px`));
    localVM.setIsActive(true);
    localVM.setFilterQuery("");

    // @TODO разобраться с типом
    const current = filterQueryInput.current as any;
    setTimeout(() => current?.focus(), 0);
  }

  function onDropdownClose() {
    localVM.setIsActive(false);
  }
}

export const FilterDropdown = observer(FilterDropdownInternal);
