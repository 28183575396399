import { BillingApi } from "api/BillingApi";
import { action, computed, observable } from "mobx";
import { BillingResourceLimitation, BillingSubscriptionModel } from "typings/server";

class BalanceStoreInternal {
  @observable allRestCount: number;
  @observable allAmountCount: number;
  @observable lastSubscriptionDate: string;
  @observable isUnlimit: boolean;
  private subscriptions: BillingSubscriptionModel[] = [];

  @action
  async getBillingInfo() {
    const info = await BillingApi.getBillingInfo();
    this.subscriptions = info.subscriptions.sort(this.sortSubscriptions);
    this.setProcessedCountsAndDate();
  }

  @computed
  get isRestCodesEmpty() {
    return this.allRestCount === 0;
  }

  @computed
  get isRestCodesLessThanCertainAmount() {
    return this.allRestCount !== 0 && this.allRestCount / this.allAmountCount < 0.1;
  }

  @computed
  get isSubscriptionExpiredSoon() {
    const now = Date.now();
    const subscriptionTimestamp = +new Date(this.lastSubscriptionDate);
    const monthMilliseconds = 1000 * 60 * 60 * 24 * 30;
    return subscriptionTimestamp - now < monthMilliseconds;
  }

  @computed
  get isSubscriptionExpired() {
    return this.subscriptions.length === 0;
  }

  private sortSubscriptions(first: BillingSubscriptionModel, second: BillingSubscriptionModel) {
    const firstTimestamp = Number(new Date(first.to!));
    const secondTimestamp = Number(new Date(second.to!));
    return firstTimestamp - secondTimestamp;
  }

  private setProcessedCountsAndDate() {
    if (!this.subscriptions.length) {
      return { amountCount: 0, restCount: 0, date: "" };
    }

    let amountCount = this.subscriptions[0].amount;
    let restCount = this.subscriptions[0].rest;
    let date = this.subscriptions[0].to!;
    this.isUnlimit = this.subscriptions[0].limitation === BillingResourceLimitation.Unlimit;

    for (let i = 1; i < this.subscriptions.length; i++) {
      const currentFrom = +new Date(this.subscriptions[i].from!);
      const prevTo = +new Date(this.subscriptions[i - 1].to!);
      if (prevTo < currentFrom) {
        break;
      }

      if (this.subscriptions[i].limitation === BillingResourceLimitation.Unlimit) {
        this.isUnlimit = true;
      }
      amountCount += this.subscriptions[i].amount;
      restCount += this.subscriptions[i].rest;
      date = this.subscriptions[i].to!;
    }

    this.allAmountCount = amountCount;
    this.allRestCount = restCount;
    this.lastSubscriptionDate = date;
  }
}

export interface IBalanceStore extends BalanceStoreInternal {}

export const BalanceStore = new BalanceStoreInternal();
