import React, { SyntheticEvent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { Capitalized, NoWrap } from "styles/shared.styles";
import { ScannedItemsCount } from "../../ScannedItemsCount/ScannedItemsCount";
import { CurrencyLabel } from "components";
import {
  BarcodeScannerIconWrap,
  GoodName,
  RejectedStatusIcon,
  ScannedIcon,
  ItemIconWrap,
  StatusReason,
  TableItemHr,
  TableScannerRow,
  NameText,
  AdditionalInfoWrap,
} from "../GoodsTable/GoodsTable.styles";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { DeliveryItemStatus } from "typings/server";
import { AcceptedStatusIcon, RecalledStatusIcon } from "icons";
import { Error } from "@skbkontur/react-icons";
import { CodesByStatus } from "../../CodesByStatus/CodesByStatus";
import { AdditionalInfoVM } from "../../AdditionalInfo/AdditionalInfoVM";
import { AdditionalInfoSgtinTable } from "../../AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "../../AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { CollapseIcon } from "../CodeItem/CodeItem.styles";
import { ArrowChevronRight, ArrowChevronDown, BarcodeScanner } from "@skbkontur/react-icons";

interface IGoodsScannerTableItemProps {
  good: UtdPositionModelExtended;
  cols: string;
  editable: boolean;
  vm: AdditionalInfoVM;
  hidePrices?: boolean;
  allCodesExpanded: boolean;
  onClick(good: UtdPositionModelExtended): void;
}

export const GoodsScannerTableItem: React.FC<IGoodsScannerTableItemProps> = observer(
  ({ good, cols, editable, onClick, vm, hidePrices, allCodesExpanded }: IGoodsScannerTableItemProps) => {
    const [hideScanner, setHideScanner] = useState(false);

    const [isExpanded, setIsExpanded] = useState(!allCodesExpanded);

    const toggleOpenedInfo = (e: SyntheticEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsExpanded(v => !v);
    };

    useEffect(() => {
      setIsExpanded(allCodesExpanded);
    }, [allCodesExpanded]);

    const hasAdditionalInfo = vm.checkIfGoodHasAdditionalInfo(good);
    const row = 2;
    const handleClick = () => {
      if (editable) onClick(good);
    };

    const setScannerView = (isHide: boolean) => {
      if (hasAdditionalInfo) {
        setHideScanner(isHide);
      }
    };

    return (
      <div data-tid="GoodsTableItem">
        <TableScannerRow
          onClick={editable ? handleClick : toggleOpenedInfo}
          editable={editable}
          hideScanner={hideScanner}
          hasAdditionalInfo={hasAdditionalInfo}
        >
          <ItemIconWrap top="-1px" left="-20px">
            <BarcodeScannerIconWrap>
              <BarcodeScanner />
            </BarcodeScannerIconWrap>
            {!editable && (
              <>
                {good.positionStatus === DeliveryItemStatus.Accepted && (
                  <ScannedIcon top={"15px"} left={"5px"}>
                    <AcceptedStatusIcon />
                  </ScannedIcon>
                )}
                {good.positionStatus === DeliveryItemStatus.Error && (
                  <ScannedIcon color={"red"} top={"14px"} left={"5px"}>
                    <Error />
                  </ScannedIcon>
                )}
                {good.positionStatus === DeliveryItemStatus.Recalled && (
                  <ScannedIcon color={"grey"} top={"15px"} left={"5px"}>
                    <RecalledStatusIcon />
                  </ScannedIcon>
                )}
                {good.positionStatus === DeliveryItemStatus.Rejected && (
                  <ScannedIcon color={"orange"} top={"15px"} left={"5px"}>
                    <RejectedStatusIcon />
                  </ScannedIcon>
                )}
              </>
            )}
          </ItemIconWrap>
          <Grid cols={cols} paddingCells={"14px 5px"}>
            <GridCell col={1} row={row}>
              <GoodName
                onClick={hasAdditionalInfo && editable ? toggleOpenedInfo : undefined}
                isAccepted={good.positionStatus === DeliveryItemStatus.Accepted}
                hasAdditionalInfo={hasAdditionalInfo && editable}
                onMouseEnter={() => setScannerView(true)}
                onMouseLeave={() => setScannerView(false)}
              >
                {hasAdditionalInfo && (
                  <CollapseIcon>{isExpanded ? <ArrowChevronDown /> : <ArrowChevronRight />}</CollapseIcon>
                )}
                <NameText isAnyAdditionalInfo={!!vm.additionalInfo.codesInfo.size && !hasAdditionalInfo}>
                  <Capitalized data-tid="Name">{good.name}</Capitalized>
                </NameText>
              </GoodName>
              <StatusReason>{good.statusReason}</StatusReason>
            </GridCell>
            <GridCell col={2} row={row} textAlign="left" padding={"14px 10px"}>
              {good.positionStatus ? (
                <ScannedItemsCount
                  allLen={good.ssccCodes.length}
                  scannedLen={good.ssccScanLen}
                  codeText
                  status={good.positionStatus}
                  dataTid="ScannerSsccCount"
                />
              ) : (
                <CodesByStatus dataTid="ScannerSsccCount" allLen={good.ssccCodes.length} good={good} type={"sscc"} />
              )}
            </GridCell>
            <GridCell col={3} row={row} textAlign="left" padding="14px 10px">
              {good.positionStatus ? (
                <ScannedItemsCount
                  allLen={good.sgtinCodes.length}
                  scannedLen={good.sgtinScanLen}
                  codeText
                  status={good.positionStatus}
                  dataTid="ScannerSgtinCount"
                />
              ) : (
                <CodesByStatus dataTid="ScannerSgtinCount" allLen={good.sgtinCodes.length} good={good} type={"sgtin"} />
              )}
            </GridCell>
            <GridCell data-tid="Quantity" col={4} row={row} textAlign="left" padding={"14px 10px"}>
              {good.quantity}
            </GridCell>
            {!hidePrices && (
              <>
                <GridCell col={5} row={row} textAlign="right">
                  <NoWrap>
                    <CurrencyLabel data-tid="Cost" value={good.cost} />
                  </NoWrap>
                </GridCell>
                <GridCell col={6} row={row} textAlign="right">
                  <NoWrap>
                    <CurrencyLabel data-tid="Vat" value={good.vat} />
                  </NoWrap>
                </GridCell>
              </>
            )}
          </Grid>
        </TableScannerRow>
        {isExpanded && hasAdditionalInfo && (
          <AdditionalInfoWrap>
            <AdditionalInfoSgtinTable vm={vm} codes={good.sgtinCodes} />
            <AdditionalInfoSsccTable vm={vm} codes={good.ssccCodesFlat} />
            <TableItemHr />
          </AdditionalInfoWrap>
        )}
      </div>
    );
  }
);
