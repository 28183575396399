import React, { FC, memo, useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { CodesWrap } from "features/ScannerSidePage/ScannerSidePage.styles";
import { MdlpModal } from "features/CertificatesList/MdlpModal";
import { ICertificate } from "@skbkontur/plugin-js";
import { UnpackingSidePageBodyItem } from "./UnpackingSidePageBodyItem";
import { ScannerCounter } from "features/ScannerSidePage/ScannerCounter/ScannerCounter";
import { ScanMode } from "features/ScannerSidePage/ScanHelper";
import { EmptyCodesRow } from "features/ScannerSidePage/SidePages/UtdScan/EmptyCodesRow";
import { UnpackingSidePageVM } from "./UnpackingSidePageVM";
import { ExpandAllIconWrap, TableHeadCellWrap } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { CollapseAllIcon, ExpandAllIcon } from "icons";

interface UnpackingSidePageBodyProps {
  vm: UnpackingSidePageVM;
}

const cols = "0.3fr 173px 3.5fr 1fr 1.5fr";

export const UnpackingSidePageBody = observer((props: UnpackingSidePageBodyProps) => {
  const { vm } = props;

  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const onCloseMdlpModal = () => {
    vm.toggleModal("mdlpModal");
  };

  const onDismiss = () => {
    onCloseMdlpModal();
    vm.setCertNotFoundError();
  };

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
  };

  return (
    <>
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={onCloseMdlpModal}
          title={"Доступ в ИС МДЛП"}
          sign={onSign}
          onDismiss={onDismiss}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}
      <ScannerCounter
        title={`${vm.mode === ScanMode.Input ? "Добавлено" : "Отсканировано"} кодов агрегатов`}
        allScannedLen={vm.copiedData.length}
      />
      <TableHeadCellWrap top="7px" right="13px" hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
        <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
      </TableHeadCellWrap>
      <Grid cols={cols} cellsBorderBottom>
        <GridCellHeader col={1} row={1}>
          №
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Код агрегата
        </GridCellHeader>
        <GridCellHeader col={3} row={1}>
          Наименование
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign={"right"}>
          Агрегат
        </GridCellHeader>
        <GridCellHeader col={5} row={1} textAlign={"right"}>
          Штучный товар
        </GridCellHeader>
      </Grid>

      <Table vm={vm} count={vm.copiedData.length} allCodesExpanded={allCodesExpanded} />
    </>
  );
});

interface TableProps {
  count: number;
  allCodesExpanded: boolean;
  vm: UnpackingSidePageVM;
}

const Table: FC<TableProps> = memo(
  props => {
    const { vm, count, allCodesExpanded } = props;

    if (!vm.copiedData.length) {
      return (
        <CodesWrap>
          <EmptyCodesRow />
        </CodesWrap>
      );
    }
    return (
      <CodesWrap>
        {vm.copiedData.map((code, index) => (
          <UnpackingSidePageBodyItem
            allCodesExpanded={allCodesExpanded}
            vm={vm}
            code={code}
            isNew={vm.newCodeItem === code}
            additionalInfo={vm.additionalInfo.codesInfo.get(code)}
            key={code}
            index={count - 1 - index}
            cols={cols}
          />
        ))}
      </CodesWrap>
    );
  },
  (prevProps, nextProps) => prevProps.count === nextProps.count
);
