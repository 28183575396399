import {
  AcceptusType,
  ContractType,
  Country,
  DepartmentStatus,
  DepartmentType,
  FinancingType,
  ReceiveType,
  TurnoverType,
} from "./server";

export interface Dictionary<T> {
  [index: string]: T;
}

export const AcceptanceTypesNames: Record<string | AcceptusType, string> = {
  [AcceptusType.Direct]: "Прямой акцепт",
  [AcceptusType.Reverse]: "Обратный акцепт",
  [AcceptusType.Simplified]: "По упрощенной схеме",
  [AcceptusType.SuspendedReturn]: "Возврат приостановленного товара",
  [AcceptusType.StateDispatch]: "Государственное лекарственное обеспечение",
};

export const ShipmentTypesNames: Record<string | AcceptusType, string> = {
  [AcceptusType.Direct]: "Прямой акцепт",
  [AcceptusType.Reverse]: "Обратный акцепт",
  [AcceptusType.UnregisteredDepartment]: "Отгрузка на незарегистрированное в ИС МДЛП место деятельности",
  [AcceptusType.UnregisteredCounteragent]: "Отгрузка незарегистрированному в ИС МДЛП получателю",
  [AcceptusType.Eeu]: "Отгрузка в страну ЕАЭС",
  [AcceptusType.Simplified]: "По упрощенной схеме",
  [AcceptusType.StateDispatch]: "Государственное лекарственное обеспечение",
  [AcceptusType.SuspendedReturn]: "Возврат приостановленного товара",
};

export const UnregisteredDepartmentAddress = "Место деятельности не зарегистрировано в ИС МДЛП";

export interface IType {
  label: string;
  value: string;
}

export function getArrayFromDictionary(dict: Record<string, string>): IType[] {
  return Object.keys(dict).map(key => {
    return { label: dict[key], value: key };
  });
}

export const FinancingTypeNames: Record<FinancingType | string, string> = {
  [FinancingType.OwnFunds]: "Собственные средства",
  [FinancingType.FederalFunds]: "Средства федерального бюджета",
  [FinancingType.RegionalFunds]: "Средства регионального бюджета",
  [FinancingType.NonBugetedFondsFunds]: "Средства бюджетов внебюджетных фондов",
  [FinancingType.MixedBudgetFunds]: "Смешанные средства бюджетов",
};
export const FinancingTypeNamesArr: IType[] = getArrayFromDictionary(FinancingTypeNames);

export const ContractTypeNames: Record<ContractType | string, string> = {
  [ContractType.PurchaseAndSale]: "Купля продажа",
  [ContractType.Fee]: "Комиссия",
  [ContractType.Agency]: "Агентский договор",
  [ContractType.Free]: "Передача на безвозмездной основе",
  [ContractType.Return]: "Возврат контрактному производителю",
  [ContractType.Government]: "Государственное лекарственное обеспечение",
  [ContractType.Consignment]: "Договор консигнации",
  [ContractType.OwnFunds]: "Собственные средства",
};
export const ContractTypeNamesArr: IType[] = getArrayFromDictionary(ContractTypeNames);

export const ReceiveTypeNames: Record<ReceiveType | string, string> = {
  [ReceiveType.Arrival]: "Поступление",
  [ReceiveType.Return]: "Возврат",
  [ReceiveType.Regrading]: "Пересорт",
};
export const ReceiveTypeNamesArr: IType[] = getArrayFromDictionary(ReceiveTypeNames);

export const TurnoverTypeNames: Record<TurnoverType | string, string> = {
  [TurnoverType.Sale]: "Продажа",
  [TurnoverType.Return]: "Возврат",
};
export const TurnoverTypeNamesArr: IType[] = getArrayFromDictionary(TurnoverTypeNames);

export const DepartmentStatusNames: Record<DepartmentStatus | string, string> = {
  [DepartmentStatus.Active]: "Действует",
  [DepartmentStatus.Inactive]: "Не действует",
  [DepartmentStatus.DeactivationProcess]: "В процессе приостановления",
};

export const DepartmentTypeNames: Record<DepartmentType | string, string> = {
  [DepartmentType.Branch]: "Место деятельности",
  [DepartmentType.SafeWarehouse]: "Место ответственного хранения",
};

export const EAEUCountries: Record<Country | string, string> = {
  [Country.Armenia]: "Армения",
  [Country.Belarus]: "Беларусь",
  [Country.Kazakhstan]: "Казахстан",
  [Country.Kyrgyzstan]: "Киргизия",
};
export const EAEUCountriesArr: IType[] = getArrayFromDictionary(EAEUCountries);
