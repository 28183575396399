import React, { useEffect } from "react";
import { observer } from "mobx-react";
import AddIcon from "@skbkontur/react-icons/Add";
import { TransferRoute } from "routes";
import { Button, Input, Link, Loader } from "components";
import { RouteComponentProps } from "react-router";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { Content } from "components/Layout";
import { ICertificate } from "@skbkontur/plugin-js";
import { SignatureStore } from "stores/SignatureStore";
import { UserStore } from "stores/UserStore";
import {
  DeliveryDropdownWrap,
  DeliveryH3,
  DeliveryHeader,
  DeliveryTopBlock,
  UpdateBlock,
  InputWrap,
} from "Common/Styles/MainPage.styles";
import { SyncInfo } from "Common/SyncInfo/SyncInfo";
import { List } from "./MainPage.styles";
import { MdlpModal } from "../../CertificatesList/MdlpModal";
import { OperationsList } from "./OperationsList/OperationsList";
import { TransferStore } from "stores/TransferStore";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { TransferEditorModal } from "../TransferEditorModal/TransferEditorModal";
import { SYNC_POLL_INTERVAL } from "stores/shared";
import { DeliveriesStore } from "stores/DeliveriesStore";
import SearchIcon from "@skbkontur/react-icons/Search";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { AdvertisementStore } from "stores/AdvertisementStore";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { transferenceInstruction } from "StringResx";

interface IMainPageProps extends RouteComponentProps<TransferRoute> {
  vm: DeliveriesMainPageVM;
}

const DELIVERY_TYPE = DeliveryType.Transference;

const MainPageInternal: React.FC<IMainPageProps> = observer((props: IMainPageProps) => {
  const { vm, history } = props;

  // unmount
  useEffect(() => {
    return () => {
      vm.handleUnmount();
    };
  }, [vm]);

  const goToDetailsPage = (doc: DeliveryIndexModel) => {
    vm.setIsPreserveFilters(true);
    if (doc.id) history.push(TransferRoute.getHref(doc.id));
  };

  const toggleMdlpModal = () => vm.toggleModal("mdlpModal");

  const toggleNewTransferModal = () => vm.toggleModal("newDelivery");

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
    await vm.synchronizeAndUpdate(SYNC_POLL_INTERVAL);
  };

  const toggleDeleteModal = () => vm.toggleModal("delete");

  const onDelete = async () => {
    toggleDeleteModal();
    await vm.deleteDelivery();
  };

  if (vm.loadingState.get("start")) return <Loader active />;

  return (
    <Content data-tid="MainPage">
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={toggleMdlpModal}
          title={"Доступ в ИС МДЛП"}
          sign={onSign}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}
      {vm.modalState.get("delete") && vm.deliveryToDelete && (
        <DeleteDeliveryModal
          deliveryType={vm.deliveryToDelete.type}
          onDelete={onDelete}
          onClose={toggleDeleteModal}
          getDeliveryItems={vm.getDeliveryItems}
          isLoading={vm.loadingState.get("delete")}
        />
      )}
      {vm.modalState.get("newDelivery") && <TransferEditorModal onClose={toggleNewTransferModal} />}

      <DeliveryTopBlock>
        <DeliveryHeader>
          <h2>{TransferRoute.title}</h2>
          <UpdateBlock>
            <SyncInfo vm={vm} />
          </UpdateBlock>
        </DeliveryHeader>
        <DeliveryDropdownWrap>
          <div>
            <Button data-tid="CreateNewOperation" onClick={toggleNewTransferModal} icon={<AddIcon />}>
              Добавить перемещение
            </Button>
            <InputWrap>
              <Input
                data-tid="DocumentNumberSearch"
                value={vm.documentNumberQuery}
                onValueChange={vm.changeDocumentNumberQuery}
                width={"250px"}
                placeholder="Поиск по номеру документа"
                rightIcon={<SearchIcon />}
              />
            </InputWrap>
          </div>
          <Link data-tid="InfoLink" href={transferenceInstruction} icon={<HelpDotIcon />} target="_blank">
            Перемещение
          </Link>
        </DeliveryDropdownWrap>
        <DeliveryH3>Операции</DeliveryH3>
      </DeliveryTopBlock>
      <List>
        <OperationsList
          data-tid="OperationsList"
          operations={vm.deliveries}
          onClick={goToDetailsPage}
          type={DELIVERY_TYPE}
          vm={vm}
        />
      </List>
    </Content>
  );
});

export const MainPage = (props: RouteComponentProps<TransferRoute>) => {
  const vm = new DeliveriesMainPageVM(TransferStore, SignatureStore, UserStore, DeliveriesStore, AdvertisementStore, [
    DELIVERY_TYPE,
  ]);
  return <MainPageInternal vm={vm} {...props} />;
};
