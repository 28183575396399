import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { Paging } from "components";
import { DeliveriesListItem } from "./DeliveriesListItem";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { DeliveryPagingWrapper, AcceptusTypeLabelWrap } from "Common/DeliveriesList/DeliveriesList.styles";
import { EmptyDeliveries } from "../MainPage.styles";
import { Loader } from "components";
import { StatusItem } from "Common/Status/StatusItem";
import { AcceptanceTypesNames } from "typings/dictionaries";
import { FilterDropdown } from "Common/Filter/FilterDropdown/FilterDropdown";
import { FilterResetRow } from "Common/Filter/FilterResetRow/FilterResetRow";

interface DeliveriesListProps {
  vm: DeliveriesMainPageVM;
  onClick: (doc: DeliveryIndexModel) => void;
  type: DeliveryType;
}

const cols = "24fr 6fr 6fr 11fr 9fr 50px";

export const DeliveriesList = observer((props: DeliveriesListProps) => {
  const { onClick, type, vm } = props;
  const { deliveries, activePage, pagesCount, setActivePage } = props.vm;
  const isLoadingList = Boolean(vm.loadingState.get("list"));
  const isLoadingListImmediate = Boolean(vm.loadingState.get("listImmediate"));
  const onDelete = (e: SyntheticEvent<HTMLElement>, delivery: DeliveryIndexModel) => {
    e.stopPropagation();
    vm.setDeliveryToDelete(delivery);
    vm.toggleModal("delete");
  };

  return (
    <>
      <Grid cols={cols} headerBorderBottom>
        <GridCellHeader col={1} row={1} padding="8px 4px 0 0">
          <FilterDropdown
            data-tid="AllowedCounteragentsFilter"
            value={vm.allowedCounteragents}
            caption="Отправитель"
            vm={vm}
            isSearchAvailable
          />
        </GridCellHeader>
        <GridCellHeader col={2} row={1} textAlign="left">
          № документа
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign="left">
          Дата документа
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign="left">
          <FilterDropdown
            data-tid="AcceptusTypeFilter"
            value={vm.allowedAcceptusType}
            caption="Тип приемки"
            vm={vm}
            renderCustomItemLabel={value => (
              <AcceptusTypeLabelWrap>{AcceptanceTypesNames[value.name]}</AcceptusTypeLabelWrap>
            )}
          />
        </GridCellHeader>
        <GridCellHeader col={5} row={1} textAlign="left">
          <FilterDropdown
            data-tid="StatusFilter"
            value={vm.allowedStatuses}
            caption="Статус"
            vm={vm}
            renderCustomItemLabel={value => <StatusItem status={value.name} type={type} />}
          />
        </GridCellHeader>
        <GridCellHeader col={6} row={1}></GridCellHeader>
      </Grid>

      <Loader
        data-tid="Loader"
        active={isLoadingList || isLoadingListImmediate}
        showChildren
        immediate={isLoadingListImmediate}
      >
        {vm.isShowFilterResetRow && (
          <FilterResetRow
            totalSize={vm.totalSize}
            plural={["поставка", "поставки", "поставок"]}
            resetFilters={vm.resetAllFiltersAndPage}
            paddingLeft="10px"
          />
        )}
        {deliveries.map(delivery => (
          <DeliveriesListItem
            data-tid="OperationsListItem"
            type={type}
            cols={cols}
            key={delivery.id}
            delivery={delivery}
            onClick={onClick}
            onDelete={onDelete}
            isCanBeDeleted={vm.isCanBeDeleted(delivery)}
          />
        ))}
        {pagesCount > 1 && (
          <DeliveryPagingWrapper>
            <Paging
              style={{ boxSizing: "content-box" }}
              activePage={activePage}
              onPageChange={setActivePage}
              pagesCount={pagesCount}
            />
          </DeliveryPagingWrapper>
        )}
        {vm.isShowEmptyMessageBlock && <EmptyDeliveries>Поставок еще нет</EmptyDeliveries>}
      </Loader>
    </>
  );
});
