import { action, observable } from "mobx";
import { CreateRecallModel } from "typings/server";

type TErrors = "operationDateRequired" | "invalidOperationDate";

export class CancellationModalVM {
  @observable operationDate: string = new Date().toISOString();
  @observable reason: string = "";
  @observable isSubmitted: boolean = false;
  @observable errors: Map<TErrors, string> = new Map();
  readonly maxDate: string;

  constructor(readonly deliveryId: string, readonly onConfirmFunc: (model: CreateRecallModel) => Promise<void>) {
    this.maxDate = new Date().toISOString();
  }

  @action.bound
  setOperationDate(date?: string) {
    this.operationDate = date ?? "";
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (!this.operationDate) {
      this.errors.set("operationDateRequired", "Укажите дату отмены");
    }
    if (this.operationDate && new Date(this.operationDate) > new Date()) {
      this.errors.set("invalidOperationDate", "Дата не должна быть больше текущей даты и времени");
    }
    return !this.errors.size;
  }

  @action
  setReason = (msg: string) => {
    this.reason = msg;
  };

  @action
  onConfirm = async (): Promise<void> => {
    this.isSubmitted = true;
    if (this.validate()) {
      const data = new CreateRecallModel();
      data.operationDate = this.operationDate;
      data.reason = this.reason.trim();
      data.deliveryId = this.deliveryId;
      try {
        await this.onConfirmFunc(data);
      } catch (e) {
        return Promise.reject(e);
      }
    } else return Promise.reject();
  };
}
