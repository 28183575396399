import React from "react";

interface ScannerHandlerProps {
  onScan: (code: string) => void;
}

interface ScannerHandlerState {
  code: string;
}

export class ScannerHandler extends React.Component<ScannerHandlerProps, ScannerHandlerState> {
  private timeout: any;
  private mounted: boolean = true;
  private readonly minCodeLength = 7;

  constructor(props: ScannerHandlerProps) {
    super(props);
    this.state = {
      code: "",
    };
  }

  componentDidMount(): void {
    document.addEventListener("keypress", this.handleKeyPress);
    document.addEventListener("keydown", this.disableSystemShortcuts);
  }

  componentWillUnmount(): void {
    this.mounted = false;
    document.removeEventListener("keypress", this.handleKeyPress);
    document.removeEventListener("keydown", this.disableSystemShortcuts);
    clearTimeout(this.timeout);
  }

  render() {
    return null;
  }

  private handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      this.sendScanCode();
      return;
    }

    // фикс для ff mozilla
    if (e.key === "/") {
      e.preventDefault();
    }

    this.setState(({ code }) => ({ code: code + e.key }));

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.mounted) {
        this.completeScan(this.state.code);
      }
    }, 200);
  };

  private completeScan = (code: string) => {
    if (code.length < this.minCodeLength) {
      this.setState({ code: "" });
    } else {
      this.sendScanCode();
    }
  };

  private sendScanCode = () => {
    if (this.state.code.length === 0) {
      return;
    }

    this.props.onScan(this.state.code);

    if (this.mounted) {
      clearTimeout(this.timeout);
      this.setState({ code: "" });
    }
  };
  private disableSystemShortcuts(e: KeyboardEvent) {
    if (e.key === "F3") {
      e.preventDefault();
    }
  }
}
