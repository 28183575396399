import {
  AcceptusType,
  CodeInfoResponseModel,
  DeliveryIndexModel,
  DeliveryItemModel,
  DeliveryStatus,
  DeliveryType,
  RegistratorEmbeddedModel,
} from "typings/server";
import { ISyncPollingOptions } from "./DeliveriesStore";
import { FilterModel } from "models/Filter/FilterModel";

export interface ILoadOptions {
  force?: boolean;
  allowedTypes?: DeliveryType[];
  startFrom?: number;
}

export interface IStore {
  deliveries: DeliveryIndexModel[];
  filters: FilterModel;
  selectedDelivery: any;
  totalSize: number;
  registrators?: RegistratorEmbeddedModel[];

  setActivePage: (page: number) => Promise<void>;

  startPollingDeliveries(page: number): Promise<void>;

  restartPollingDeliveries(options?: ISyncPollingOptions): Promise<void>;

  stopPollingDeliveries(): void;

  updateDeliveries(): Promise<void>;

  getScannedCodes(id: string): Promise<string[]>;

  getItems(id: string): Promise<DeliveryItemModel[]>;

  changeType?(type: DeliveryType): void;

  saveItems?(items: DeliveryItemModel[], id: string): void;

  deleteDelivery(id: string): Promise<void>;

  isCanBeDeleted(status?: DeliveryStatus, acceptusType?: AcceptusType): boolean;

  getAvailableRegistrators?(): Promise<void>;

  setCurrentScanCodeStatus?(codeStatus: CodeInfoResponseModel | undefined): void;
}

export const SYNC_POLL_INTERVAL = 5000;
export const POLL_INTERVAL = 60000;

export const DELIVERIES_PER_PAGE = 30;
