import React, { memo, useState, useCallback } from "react";
import { Button, Loader, Modal } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
// @TODO пошарить в коммон все, что нужно
import { ModalBodyInner, EditorLabel } from "../../Editor/EditorModal.styles";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { Textarea } from "@skbkontur/react-ui";
import { DestructionApi } from "api/DestructionApi";

interface Props {
  deliveryId: string;
  updateModel: () => void;
  onStartSendingSecondDocument: (v: boolean) => void;
  onClose(): void;
}

export const CancelDestructionModal = memo((props: Props) => {
  const { deliveryId, updateModel, onStartSendingSecondDocument, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [operationDate, setOperationDate] = useState(new Date().toISOString());
  const [reason, setReason] = useState("");

  const [showErrors, setShowErrors] = useState(false);

  const onDateChange = useCallback(
    (v: string | undefined) => {
      if (!v) {
        return;
      }
      if (showErrors) {
        setShowErrors(false);
      }
      setOperationDate(v);
    },
    [showErrors]
  );

  const submit = useCallback(async () => {
    if (!operationDate) {
      setShowErrors(true);
      return;
    }
    setIsLoading(true);
    onStartSendingSecondDocument(true);
    await DestructionApi.recall({ deliveryId, operationDate, reason });
    await updateModel();
    onClose();
  }, [operationDate, reason, deliveryId]);

  const getOperationDateError = useCallback(() => {
    if (!showErrors) {
      return;
    }

    if (!operationDate) {
      return "Укажите дату операции";
    }
  }, [showErrors, operationDate]);

  return (
    <Modal data-tid="CancelDestructionModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>Отмена передачи на уничтожение</Modal.Header>
      <Modal.Body>
        <ModalBodyInner>
          {!isLoading ? (
            <Grid cols="135px 1fr">
              <>
                <GridCell col={1} row={1} style={{ display: "flex", alignItems: "end" }}>
                  <EditorLabel breakText required>
                    Дата отмены
                  </EditorLabel>
                </GridCell>
                <GridCell col={2} row={1}>
                  <DateTimePicker
                    data-tid="ReportDate"
                    value={operationDate}
                    onChange={onDateChange}
                    getReportDateError={getOperationDateError}
                  />
                </GridCell>

                <GridCell col={1} row={2} padding="20px 0 0 0">
                  <EditorLabel breakText>Причина отмены</EditorLabel>
                </GridCell>
                <GridCell col={2} row={2}>
                  <Textarea width="100%" autoResize resize={undefined} onValueChange={setReason} />
                </GridCell>
              </>
            </Grid>
          ) : (
            <Loader active />
          )}
        </ModalBodyInner>
      </Modal.Body>
      <Modal.Footer panel>
        <FooterBtnWrapper>
          <Button data-testid="Submit" use="primary" size="large" disabled={isLoading} onClick={submit}>
            Подписать и отменить передачу на уничтожение
          </Button>
          <Button data-tid="Cancel" onClick={onClose} size="large">
            Отменить
          </Button>
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  );
});
