import { DateTime } from "luxon";

export interface IDateOptions {}

/**
 * return formatted "ru" date, for example "30.11.2019"
 * @param date
 * @param options
 */
export function getFormattedDate(date: string, options?: any): string {
  const d = new Date(date);
  if (d.toDateString() !== "Invalid Date") {
    if (options) {
      // .replace(/\u200E/g,'') ie 11 баг, подставляет нечитабельные символы
      const formatted = d.toLocaleString("ru", options).replace(/\u200E/g, "");
      // возвращаем дату без " г."
      return formatted.replace(/\sг\.,?/, "");
    }
    return d.toLocaleDateString("ru").replace(/\u200E/g, "");
  }

  return date;
}

export function getISODateFromString(date: string, format: string, utc: boolean = false): string | undefined {
  const d = DateTime.fromFormat(date, format, { zone: utc ? "utc" : "local" });
  if (d.isValid) {
    return d.toISO();
  }
}

export function getTimeFromISODate(date: string): string | undefined {
  const d = DateTime.fromISO(date);
  if (d.isValid) {
    return d.toLocal().toFormat("HH:mm");
  }
}

//18 ноября 15:10
export const longDateTimeOptions = { month: "long", day: "2-digit", hour: "2-digit", minute: "2-digit" };
//18 ноября 2020 15:10
export const fullDateAndTimeOptions = {
  month: "long",
  day: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};
//18 ноября 2019
export const longDateOptions = { month: "long", day: "2-digit", year: "numeric" };

//18.11.2019 15:50
export const dateFormatWithTime = "dd.MM.yyyy HH:mm";
//18.11.2019
export const dateFormat = "dd.MM.yyyy";
