import React, { memo, useContext, useState, useEffect } from "react";
import { CollapseIconWrap, DiscardBlockWrap, HideWrap, NameWrap, ScannerRow } from "../../ScannerSidePage.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { TextGreen, TextGrey } from "styles/shared.styles";
import { Button } from "components";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { AdditionalInfoSgtinTable } from "Common/AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "Common/AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { TableItemHr } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { ArrowChevronRight, ArrowChevronDown, Undo } from "@skbkontur/react-icons";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { DeliveryType } from "typings/server";

interface FreeScanItemRowProps {
  item: DeliveryItemModelExtended;
  index: number;
  currentCode?: string;
  isNewItem?: boolean;
  newCode?: string;
  additionalInfoVM: AdditionalInfoVM;
  itemCodesLength: number;
  allCodesExpanded: boolean;
  onDiscardItem(item: DeliveryItemModelExtended): void;
  onDiscardCode(code: string): void;
}

export const FreeScanItemRow: React.FC<FreeScanItemRowProps> = memo(
  ({ item, index, isNewItem, newCode, additionalInfoVM, onDiscardItem, allCodesExpanded, onDiscardCode }) => {
    const [isExpanded, setExpanded] = useState(allCodesExpanded);
    const hasAdditionalInfo = additionalInfoVM.checkIfGoodHasAdditionalInfo(item);

    useEffect(() => {
      // Для выбытия вновь добавленный код раскрываем, чтобы показать возможность дробного вывода
      if (deliveryType === DeliveryType.Disposal || deliveryType === DeliveryType.DisposalWithRegistrator) {
        if (!!isNewItem) {
          setExpanded(true);
        }
      }
    }, []);

    const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;

    const alwaysVisible =
      deliveryType === DeliveryType.Disposal || deliveryType === DeliveryType.DisposalWithRegistrator;
    const isShowAdditionalInfo = hasAdditionalInfo || alwaysVisible;

    function onOpenInfo() {
      if (isShowAdditionalInfo) {
        setExpanded(v => !v);
      }
    }

    function onDiscard(e: any) {
      e.stopPropagation();
      onDiscardItem(item);
    }

    return (
      <>
        <ScannerRow
          data-tid="ScannerRow"
          isNew={isNewItem && !newCode}
          isInfo={isShowAdditionalInfo}
          onClick={onOpenInfo}
        >
          {isShowAdditionalInfo && (
            <CollapseIconWrap>{isExpanded ? <ArrowChevronDown /> : <ArrowChevronRight />}</CollapseIconWrap>
          )}
          <Grid cols={"40px 1fr 3fr 200px"} cellsBorderBottom={!isExpanded}>
            <GridCell col={1} row={1}>
              <TextGrey>{index + 1}</TextGrey>
            </GridCell>
            <GridCell data-tid="ScannedItemType" col={2} row={1}>
              <NameWrap bold={isExpanded}>
                <TextGreen>{item.gtin ? "Штучный товар" : "Агрегат"}</TextGreen>
              </NameWrap>
            </GridCell>
            <GridCell data-tid="ScannedItemName" col={3} row={1}>
              <NameWrap bold={isExpanded}>
                <TextGreen>{item.name}</TextGreen>
              </NameWrap>
            </GridCell>
            <GridCell data-tid="DiscardButton" col={4} row={1} textAlign="right">
              <DiscardBlockWrap isInfo={isShowAdditionalInfo}>
                <Button use="link" size="small" onClick={onDiscard} icon={<Undo />}>
                  Отменить сканирование
                </Button>
              </DiscardBlockWrap>
              <HideWrap>
                <TextGreen>{item.allCodes.length}</TextGreen>
              </HideWrap>
            </GridCell>
          </Grid>
        </ScannerRow>
        {isExpanded && isShowAdditionalInfo && (
          <>
            <AdditionalInfoSgtinTable
              vm={additionalInfoVM}
              codes={item.sgtinCodes}
              onDiscardCode={onDiscardCode}
              alwaysVisible={alwaysVisible}
              newCode={newCode}
            />
            <AdditionalInfoSsccTable vm={additionalInfoVM} codes={item.ssccCodesFlat} onDiscardCode={onDiscardCode} />
            <TableItemHr />
          </>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.itemCodesLength === nextProps.itemCodesLength &&
    prevProps.item.code === nextProps.item.code &&
    prevProps.isNewItem === nextProps.isNewItem &&
    prevProps.index === nextProps.index &&
    prevProps.newCode === nextProps.newCode &&
    prevProps.additionalInfoVM === nextProps.additionalInfoVM
);
