import { isSgtin, isSscc } from "helpers/codes";
import { IFreeScanError } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageVM";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { ScanValidationErrors } from "features/ScannerSidePage/SidePages/BaseScanPageVM";

export function extractionValidations(
  code: string,
  copiedItems: DeliveryItemModelExtended[]
): undefined | IFreeScanError {
  if (isSgtin(code) && copiedItems.some(i => i.ssccLen)) {
    return {
      code: "wrongCode" as ScanValidationErrors,
      errorObj: {
        title: "Код штучного товара. В одной операции не могут быть коды агрегата и штучного товара",
        description:
          "Код штучного товара. В одной операции не могут быть коды агрегата и штучного товара одновременно.",
      },
    };
  }

  if (isSscc(code) && copiedItems.some(i => i.sgtinLen)) {
    return {
      code: "wrongCode" as ScanValidationErrors,
      errorObj: {
        title: "Код агрегата. В одной операции не могут быть коды агрегата и штучного товара",
        description: "Код агрегата. В одной операции не могут быть коды агрегата и штучного товара одновременно.",
      },
    };
  }
}
