import React, { useMemo, useState, useCallback, FC, useEffect } from "react";
import { observer } from "mobx-react";
import { CirculationPageVM } from "../../CirculationPageVM";
import {
  CodesBlock,
  Container,
  Reason,
  ScanPanel,
  ScanPanelText,
  ReturnName,
  DestructionBlock,
} from "./PageInfoBlock.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { Button, Input, Select } from "components";
import BarcodeScannerIcon from "@skbkontur/react-icons/BarcodeScanner";
import { ScannedLargeText } from "Common/ScannedItemsCount/ScannedItemsCount.styles";
import { getEnding } from "helpers/endings";
import { CodesTable } from "Common/GoodsTable/CodesTable/CodesTable";
import { DeliveryStatus, DeliveryType, DestructionCause, DestructionReason } from "typings/server";
import { LabelRequired } from "styles/shared.styles";
import { IType } from "typings/dictionaries";
import {
  DestructionCausesNames,
  DestructionCausesNamesArr,
  DestructionReasonsNames,
  DestructionReasonsNamesArr,
} from "features/Circulation/WithdrawalDictionary";
import { WithdrawalCodesTable } from "../WithdrawalCodesTable";

interface IPageInfoBlockProps {
  vm: CirculationPageVM;
}

export const PageInfoBlock: FC<IPageInfoBlockProps> = observer((props: IPageInfoBlockProps) => {
  const { vm } = props;
  const isDestruction = vm.delivery.type === DeliveryType.Destruction;
  const isProccessing = vm.delivery.status === DeliveryStatus.Processing;

  const onScan = () => {
    vm.toggleModal("scanPage");
    const activeElement = window.document.activeElement as HTMLElement | null;
    // blur(), because scanner triggers button click on "enter"
    if (activeElement && activeElement.blur) {
      activeElement.blur();
    }
  };

  // @TODO покрыть тестом
  const getShowTable = (): boolean => {
    // Если айтемов нет - нет вопросов, не покажем табличку
    if (!vm.delivery.items?.length) {
      return false;
    }
    // Если операция - не вывод из оборота, покажем эту таблицу, иначе - будет показана
    // собственная таблица для вывода из оборота
    if (
      vm.delivery.type !== DeliveryType.Withdrawal &&
      vm.delivery.type !== DeliveryType.DisposalWithRegistrator &&
      vm.delivery.type !== DeliveryType.Disposal
    ) {
      return true;
    } else {
      // Если это вывод из оборота, таблицы показываем в зависимости от стадии
      if (
        vm.delivery.status === DeliveryStatus.Draft ||
        vm.delivery.status === DeliveryStatus.Sending ||
        vm.delivery.status === DeliveryStatus.Signing ||
        vm.delivery.status === DeliveryStatus.Signed ||
        vm.delivery.status === DeliveryStatus.Processing ||
        vm.delivery.status === DeliveryStatus.New ||
        vm.delivery.status === DeliveryStatus.CreatingDocument ||
        vm.delivery.status === DeliveryStatus.WaitingForCounterparty ||
        vm.delivery.status === DeliveryStatus.Failed
      ) {
        return true;
      }
      // Если в ЛЮБОМ из элементов нет значения codeStatus - покажем эту таблицу,
      // иначе - особую таблицу со статусами (для вывода из оборота)
      return !vm.delivery.items[0].allCodes[0].codeMdlpStatus;
    }
  };
  const showTable = getShowTable();

  const showWithdrawalTable = !!vm.delivery.items?.length && !showTable;

  const scanText = useMemo(() => {
    switch (vm.delivery.type) {
      case DeliveryType.Reentry:
        return "Отсканируйте коды маркировки товаров для повторного ввода в оборот";
      case DeliveryType.Destruction:
        return "Отсканируйте коды маркировки товаров для передачи на уничтожение";
      default:
        return "Отсканируйте коды маркировки товаров для вывода из оборота";
    }
  }, [vm.delivery.type]);

  return (
    <Container>
      <Reason>
        <span title="Причина вывода из оборота">{vm.reasonName}</span>
        {!!vm.returnName && <ReturnName title="Причина возврата">{vm.returnName}</ReturnName>}
        {isDestruction && !isProccessing && (
          <>
            {vm.destruction?.destructionCause && (
              <span title="Основание передачи на уничтожение">
                {DestructionCausesNames[vm.destruction.destructionCause]}
              </span>
            )}
            {!!vm.destruction?.destructionReason && (
              <ReturnName title="Причина передачи на уничтожение">
                {DestructionReasonsNames[vm.destruction.destructionReason]}
              </ReturnName>
            )}
          </>
        )}
      </Reason>
      <DestructionPart vm={vm} />
      <h4>Товары</h4>
      {vm.isEditable && (
        <>
          <ScanPanelText>{scanText}</ScanPanelText>
          <ScanPanel>
            <CodesBlock>
              <ScannedLargeText>{vm.delivery.allCodesLen} </ScannedLargeText>
              &nbsp;&nbsp;{getEnding(vm.delivery.allCodesLen, "код", "кода", "кодов")} маркировки
            </CodesBlock>
            <Button onClick={onScan} size={"large"} icon={<BarcodeScannerIcon />}>
              {vm.delivery.allCodesLen ? "Продолжить сканировать товары" : "Сканировать товары"}
            </Button>
          </ScanPanel>
        </>
      )}

      {showTable && <CodesTable items={vm.delivery.items} additionalInfo={vm.additionalInfo} />}
      {showWithdrawalTable && <WithdrawalCodesTable items={vm.delivery.items} additionalInfo={vm.additionalInfo} />}
    </Container>
  );
});

const DestructionPart = observer((props: IPageInfoBlockProps) => {
  const { vm } = props;

  const isDestruction = vm.delivery.type === DeliveryType.Destruction;
  const isProccessing = vm.delivery.status === DeliveryStatus.Processing;

  const [rznDecision, setRznDecision] = useState("");

  const [destructionCause, setDestructionCause] = useState<IType | undefined>();
  const [destructionReason, setDestructionReason] = useState<IType | undefined>();

  // Ниже - два костыля, по логике, useState должен нормально проставить initialValue,
  // Но, скорее всего, что-то происходит ассинхронно, и к моменту, когда
  // срабатывает setState, начальное значение еще === undefined,
  // жизнь заставила меняя закостылять, прости...
  useEffect(() => {
    if (!!destructionReason?.value) {
      return;
    }
    const initialDestructionReason = vm.destruction?.destructionReason
      ? { label: DestructionReasonsNames[vm.destruction.destructionReason], value: vm.destruction.destructionReason }
      : undefined;
    setDestructionReason(initialDestructionReason);
  }, [vm.destruction?.destructionReason]);

  useEffect(() => {
    if (!!destructionCause?.value) {
      return;
    }
    const initialDestructionCause = !!vm.destruction?.destructionCause
      ? { label: DestructionCausesNames[vm.destruction.destructionCause], value: vm.destruction.destructionCause }
      : undefined;
    setDestructionCause(initialDestructionCause);
  }, [vm.destruction?.destructionCause]);

  const onRznDecisionChange = (v: string) => {
    setRznDecision(v);
    if (!vm.destruction) {
      return;
    }
    vm.destruction.rznDecision = v;
  };

  const onDestructionCauseChange = (v: IType) => {
    setDestructionCause(v);
    if (!vm.destruction) {
      return;
    }
    vm.destruction.destructionCause = v.value as DestructionCause;
  };

  const onDestructionReasonChange = (v: IType) => {
    setDestructionReason(v);
    if (!vm.destruction) {
      return;
    }
    vm.destruction.destructionReason = v.value as DestructionReason;
  };

  const renderItem = useCallback((item: IType) => item.label, []);

  if (!isDestruction) {
    return null;
  }

  if (isProccessing) {
    return (
      <DestructionBlock>
        <Grid cols="272px 1fr">
          <GridCell col={1} row={1} padding="15px 4px 2px 4px">
            <LabelRequired>Основание передачи на уничтожение</LabelRequired>
          </GridCell>
          <GridCell col={2} row={1}>
            <Select<IType>
              data-tid="DestructionCause"
              placeholder="Выберите основание"
              width="100%"
              maxWidth="320px"
              items={DestructionCausesNamesArr}
              onValueChange={onDestructionCauseChange}
              renderItem={renderItem}
              renderValue={renderItem}
              value={destructionCause}
              maxMenuHeight={250}
              error={!destructionCause?.value && vm.showErrors}
            />
          </GridCell>
          {destructionCause?.value === DestructionCause.RznDecision && (
            <>
              <GridCell col={1} row={2} padding="15px 4px 2px 4px">
                Реквизиты решения Росздравнадзора о выводе ЛП из оборота
              </GridCell>
              <GridCell col={2} row={2}>
                <Input data-tid="RznDecision" width="320px" value={rznDecision} onValueChange={onRznDecisionChange} />
              </GridCell>
            </>
          )}
          <GridCell col={1} row={3} padding="15px 4px 2px 4px">
            Причина передачи на уничтожение
          </GridCell>
          <GridCell col={2} row={3}>
            <Select<IType>
              data-tid="DestructionReason"
              placeholder="Выберите причину"
              width="100%"
              maxWidth="320px"
              items={DestructionReasonsNamesArr}
              onValueChange={onDestructionReasonChange}
              renderItem={renderItem}
              renderValue={renderItem}
              value={destructionReason}
              maxMenuHeight={250}
            />
          </GridCell>
        </Grid>
      </DestructionBlock>
    );
  }
  return null;
});
