import React, { FC, useContext, memo, useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { ScanAndCheckItemRow } from "./ScanAndCheckItemRow";
import { ScanAndCheckSidePageVM } from "./ScanAndCheckSidePageVM";
import { ScanAndCheckTableWrap } from "../../ScannerSidePage.styles";
import { IStore } from "stores/shared";
import { ScannerCounter } from "../../ScannerCounter/ScannerCounter";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { ExpandAllIconWrap, TableHeadCellWrap } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { ExpandAllIcon, CollapseAllIcon } from "icons";
import { DeliveryType } from "typings/server";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";

export interface IDirectAcceptanceScanPageBodyProps {
  vm: ScanAndCheckSidePageVM<IStore>;
}

const cols = "40px 4fr 1fr 1fr";

export const ScanAndCheckPageBody = observer((props: IDirectAcceptanceScanPageBodyProps) => {
  const { vm } = props;

  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;
  const additionalInfoVM = React.useMemo(
    () =>
      new AdditionalInfoVM(
        vm.copiedData,
        vm.additionalInfo,
        true,
        deliveryType,
        extraFields,
        undefined,
        undefined,
        vm.handleErrors
      ),
    [vm.copiedData]
  );

  return (
    <ScanAndCheckTableWrap>
      <ScannerCounter
        title={"Проверено кодов маркировки"}
        allScannedLen={vm.allScannedLen}
        allCodesLen={vm.allCodesLen}
      />
      <TableHeadCellWrap top="7px" right="13px" hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
        <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
      </TableHeadCellWrap>
      <Grid cols={cols} cellsBorderBottom>
        <GridCellHeader col={1} row={1}>
          №
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Наименование
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign="right">
          Агрегат
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign="right">
          Штучный товар
        </GridCellHeader>
      </Grid>

      <Table
        copiedData={vm.copiedData}
        additionalInfoVM={additionalInfoVM}
        count={vm.copiedData.length}
        showDelete={vm.showDelete}
        toggleShowDeleteValidated={vm.toggleShowDeleteValidated}
        onDiscardItem={vm.discardItem}
        onDiscardCode={vm.discardCode}
        allCodesExpanded={allCodesExpanded}
      />
    </ScanAndCheckTableWrap>
  );
});

interface TableProps {
  copiedData: DeliveryItemModelExtended[];
  newCode?: string;
  additionalInfoVM: AdditionalInfoVM;
  newCodeItem?: DeliveryItemModelExtended;
  count: number;
  allCodesExpanded: boolean;
  showDelete: boolean;
  onDiscardItem: (item: DeliveryItemModelExtended) => void;
  onDiscardCode: (code: string) => void;
  toggleShowDeleteValidated: (item: DeliveryItemModelExtended) => void;
}

const Table: FC<TableProps> = memo(
  ({
    copiedData,
    newCodeItem,
    additionalInfoVM,
    count,
    showDelete,
    allCodesExpanded,
    onDiscardItem,
    onDiscardCode,
    toggleShowDeleteValidated,
  }) => {
    return (
      <>
        {copiedData.map((item, i) => (
          <ScanAndCheckItemRow
            isNew={newCodeItem === item}
            item={item}
            index={count - 1 - i}
            key={item.name}
            additionalInfoVM={additionalInfoVM}
            cols={cols}
            allCodesExpanded={allCodesExpanded}
            discardItem={onDiscardItem}
            discardCode={onDiscardCode}
            showDelete={showDelete}
            toggleShowDelete={toggleShowDeleteValidated}
          />
        ))}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.count === nextProps.count
);
