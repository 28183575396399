import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { GoodsWrapperBlock } from "Common/Styles/DetailsPage.styles";
import { GoodsTable } from "Common/GoodsTable/GoodsTable/GoodsTable";
import { DirectAcceptancePageVM } from "../DirectAcceptancePageVM";
import { GoodsTableWrap, RecalledWrap } from "Common/Stages/Stages.styles";
import { DeliveryStatus } from "typings/server";
import { GoodsForm } from "./GoodsForm/GoodsForm";
import { AcceptedCodes, FailedCodes, RecalledCodes, RejectedCodes } from "Common/Stages/CodesStatuses/CodesStatuses";
import { StagesPartVM } from "../../Common/Stages/StagesPartVM";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliverySendingSteps } from "Common/Stages/DeliverySendingSteps/DeliverySendingSteps";
import { AcceptanceDeliveryStatusNames } from "Common/Status/StatusesName";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { CirculationWarning } from "Common/CirculationWarning/CirculationWarning";

interface IAcceptanceStagesPartProps {
  delivery: AcceptanceDeliveryModel;
  vm: DirectAcceptancePageVM;
  isPartial?: boolean;
}

export const DirectAcceptanceStagesPart = observer((props: IAcceptanceStagesPartProps) => {
  const { delivery, vm, isPartial } = props;

  const stagesVM = useMemo(() => new StagesPartVM(delivery, vm.additionalInfo, isPartial), [delivery]);

  const onCollapse = () => vm.onCollapseDelivery(delivery.id);

  const showOnlyCounter = delivery.status !== DeliveryStatus.New && delivery.status !== DeliveryStatus.Processing;

  return (
    <DeliveryStatusBlock
      size={delivery.isActive ? "large" : "medium"}
      name={stagesVM.title}
      status={delivery.stages[DeliveryStage.Processing].progress}
      collapsed={!vm.isDeliveryOpenedState.get(delivery.id)}
      alwaysVisibleComp={
        <>
          <DeliverySendingSteps
            vm={stagesVM}
            delivery={delivery}
            stages={delivery.stages}
            statusNames={AcceptanceDeliveryStatusNames}
          />
          {stagesVM.isShowCirculationWarning && <CirculationWarning isError={stagesVM.isFailedStatus} />}
        </>
      }
      onCollapse={onCollapse}
      error={stagesVM.isFailedStatus}
      isRejected={stagesVM.isRejected}
      isRecalled={delivery.status === DeliveryStatus.Recalled}
    >
      <GoodsWrapperBlock>
        {showOnlyCounter ? (
          <RecalledWrap>
            <AcceptedCodes delivery={delivery} acceptedCodesLen={delivery.acceptedCodesLen} />
            <FailedCodes failedCodesLen={delivery.failedCodesLen} />
            <RecalledCodes recalledCodesLen={delivery.recalledCodesLen} />
            <RejectedCodes vm={vm} rejectedCodesLen={delivery.rejectedCodesLen} delivery={delivery} />
          </RecalledWrap>
        ) : (
          <GoodsForm delivery={delivery} vm={vm} isPartial={isPartial}>
            <RecalledCodes recalledCodesLen={delivery.recalledCodesLen} />
            <RejectedCodes vm={vm} rejectedCodesLen={delivery.rejectedCodesLen} delivery={delivery} />
          </GoodsForm>
        )}
        <GoodsTableWrap shortTable={vm.isStateDispatch}>
          <GoodsTable
            goods={delivery.items}
            hidePrices={vm.isStateDispatch || vm.isSuspendedReturn}
            additionalInfo={vm.additionalInfo}
          />
        </GoodsTableWrap>
      </GoodsWrapperBlock>
    </DeliveryStatusBlock>
  );
});
