import { action, computed, observable } from "mobx";
import { AcceptusType, ContractType, Country, FinancingType, PatchDeliveryModel } from "typings/server";
import { IUserStore } from "stores/UserStore";
import { ContractTypeNamesArr, EAEUCountries, IType } from "typings/dictionaries";
import { DeliveriesApi } from "api/DeliveryApi";
import { copyOnlyExistingProperties } from "helpers/object";
import { IStore } from "stores/shared";
import { ShipmentEditorBaseVM } from "../ShipmentEditorBaseVM";
import { getLSValueOrSetIfNotExist } from "helpers/localStorage";

type EditorModalError =
  | "requiredCountry"
  | "requiredInn"
  | "invalidReportDate"
  | "requiredContractType"
  | "requiredDocumentNumber"
  | "requiredDocumentDate";

export class ShipmentEAEUCountriesEditorVM extends ShipmentEditorBaseVM<EditorModalError> {
  @observable country: IType;

  constructor(deliveryStore: IStore, userStore: IUserStore, errorText?: string) {
    super(deliveryStore, userStore);
    // Берем дефолтное значения из LS если они там есть
    this.deliveryCopy.contractType = getLSValueOrSetIfNotExist<ContractType>({
      scope: this.scope,
      key: "ContractType",
      value: ContractType.PurchaseAndSale,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });

    if (this.recipient?.country) {
      this.country = { label: EAEUCountries[this.recipient.country], value: this.recipient.country };
    }

    if (errorText) {
      this.onShowError();
    }
  }

  @action
  async saveDelivery() {
    this.loading = true;
    try {
      const delivery = this.getDeliveryPatchModel();
      // Перезатирает неотправленные поля модели PatchDeliveryModel
      await DeliveriesApi.updateDelivery(delivery, this.deliveryCopy.id);
      this.deliveryStore.selectedDelivery.update(delivery, AcceptusType.Eeu);
    } finally {
      this.loading = false;
    }
  }

  private getDeliveryPatchModel(): PatchDeliveryModel {
    const delivery = new PatchDeliveryModel();
    copyOnlyExistingProperties(delivery, this.deliveryCopy);
    delivery.counteragentCountry = this.country.value as Country;
    delivery.counteragentInn = this.inn;
    delivery.reportDate = this.isDateModified ? this.deliveryCopy.reportDate : "";

    return delivery;
  }

  @action
  protected validate() {
    this.errors.clear();

    if (!this.deliveryCopy.documentDate) {
      this.errors.set("requiredDocumentDate", "Укажите дату документа");
    }

    if (!this.deliveryCopy.documentNumber) {
      this.errors.set("requiredDocumentNumber", "Укажите номер документа");
    }

    if (!this.contractType) {
      this.errors.set("requiredContractType", "Укажите тип договора");
    }

    if (!this.country) {
      this.errors.set("requiredCountry", "Укажите страну получателя");
    }

    if (!this.inn) {
      this.errors.set("requiredInn", "Укажите код налогоплательщика");
    }

    this.validateDate();
  }

  @action
  protected validateDate() {
    this.errors.delete("invalidReportDate");

    if (this.deliveryCopy.reportDate && new Date(this.deliveryCopy.reportDate) > new Date()) {
      this.errors.set("invalidReportDate", "Дата не должна быть больше текущей даты и времени");
    }
  }

  @action
  setCountry = (country: IType) => {
    this.country = country;
    if (this.submitted) this.validate();
  };

  @action
  setInn = (inn: string) => {
    this.inn = inn.trim();
    if (this.submitted) this.validate();
  };

  @action
  setFinancingType(type: FinancingType) {
    this.deliveryCopy = { ...this.deliveryCopy, financingType: type };
  }

  @computed
  get contractTypeArr(): IType[] {
    return ContractTypeNamesArr;
  }
}
