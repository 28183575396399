import React from "react";
import { Link } from "../index";
import { Grid, GridCell } from "../Grid/Grid.styles";
import { FooterColWrap, LogoSpan, Logo, MainFooterWrap, SupportLinkWrap } from "./MainFooter.styles";
import { KonturUrl } from "StringResx";
import { SupportCenter } from "support";

export const MainFooter = () => {
  return (
    <MainFooterWrap>
      <Grid cols="60fr 230px 92px 180px" paddingCells="0">
        <GridCell col={1} row={1}>
          <FooterColWrap>
            <Logo>
              <Link href={KonturUrl} use="grayed" target="_blank">
                СКБ Контур
              </Link>
            </Logo>
            <LogoSpan> с 1988 года</LogoSpan>
          </FooterColWrap>
        </GridCell>
        <GridCell col={4} row={1} textAlign="right">
          <FooterColWrap>
            <SupportLinkWrap>
              <Link onClick={SupportCenter.open} use="grayed">
                Центр поддержки
              </Link>
            </SupportLinkWrap>
          </FooterColWrap>
        </GridCell>
      </Grid>
    </MainFooterWrap>
  );
};
