import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import { Button, Toast } from "components";
import { DocumentLite } from "@skbkontur/react-icons";
import { RouteComponentProps, withRouter } from "react-router";
import { AcceptanceRoute } from "routes";
import { TrustedAcceptModal } from "./TrustedAcceptanceModal/TrustedAcceptanceModal";
import { PageHeader } from "../Common/PageHeader/PageHeader";
import { CertificatesList } from "../../../CertificatesList/CertificatesList";
import { ICertificate } from "@skbkontur/plugin-js";
import { DirectAcceptanceStagesBlock } from "./Stages/DirectAcceptanceStagesBlock";
import { ErrorSignBlock } from "../../../CertificatesList/shared";
import { DirectAcceptancePageVM } from "./DirectAcceptancePageVM";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { DirectAcceptanceSidePage } from "./DirectAcceptanceSidePage";
import { ConfirmationModal } from "./ConfirmationModal/ConfirmationModal";
import { SuccessSignBlock } from "Common/SuccessSignBlock/SuccessSignBlock";
import { RollbackButton } from "Common/Rollback/RollbackButton/RollbackButton";

interface IAcceptanceDetailsPageProps extends RouteComponentProps {
  vm: DirectAcceptancePageVM;
}

export const DirectAcceptancePage = withRouter(
  observer((props: IAcceptanceDetailsPageProps) => {
    const { vm, history } = props;

    // unmount
    useEffect(() => {
      return () => {
        vm.handleUnmount();
      };
    }, [vm]);

    const toggleTrustedModal = () => vm.toggleModal("trustedAccept");

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const onSign = async (cert: ICertificate): Promise<any> => vm.sign(cert);

    const saveDraft = async () => {
      vm.setSubmitted(true);
      if (vm.validate()) {
        await vm.saveDraft();
        history.push(AcceptanceRoute.baseRoute);
      } else {
        vm.onCollapseDelivery(vm.activeDelivery.id, true);
      }
    };

    const toggleScanSidePage = () => vm.toggleModal("scanPage");

    const save = async () => {
      vm.setSubmitted(true);
      if (vm.validate()) {
        if (vm.isAllScanned) {
          await vm.save();
        } else {
          vm.toggleModal("confirmation");
        }
      } else {
        vm.onCollapseDelivery(vm.activeDelivery.id, true);
      }
    };

    const saveItems = async () => {
      await vm.saveItems();
    };

    const onComplete = async () => {
      vm.setSubmitted(true);
      if (vm.validate()) {
        await vm.save();
        vm.toggleModal("confirmation");
        vm.onCollapseDelivery(vm.activeDelivery.id, false);
        Toast.push("Приемка завершена. Создаем документ");
      } else {
        // раскрываем приемку, показываем ошибки
        vm.onCollapseDelivery(vm.activeDelivery.id, true);
      }
    };

    const acceptRest = () => {
      vm.addChildDelivery();
      scrollToBottom();
    };

    const tryAgain = () => {
      vm.addChildDelivery();
      scrollToBottom();
    };

    function scrollToBottom() {
      const t = setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
        clearTimeout(t);
      }, 100);
    }

    const goBackBlock = <GoToBlock name={"К списку поставок"} href={AcceptanceRoute.baseRoute} />;

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding={"24px 24px 24px 38px"}>
          {vm.modalMap.get("trustedAccept") && <TrustedAcceptModal vm={vm} />}
          {vm.modalMap.get("scanPage") && (
            <DirectAcceptanceSidePage
              additionalInfo={vm.additionalInfo}
              save={saveItems}
              close={toggleScanSidePage}
              delivery={vm.activeDelivery}
              parentDeliveryId={vm.delivery.id}
            />
          )}
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title={"Подписание документа"}
              signText={"Документ будет подписан при помощи сертификата:"}
              renderSuccessBlock={() => <SuccessSignBlock route={AcceptanceRoute.baseRoute} textSubString="поставок" />}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={onSign}
            />
          )}
          {vm.modalMap.get("confirmation") && (
            <ConfirmationModal
              vm={vm}
              onComplete={onComplete}
              errorText={"Не смогли завершить приемку. Закройте окно и попробуйте еще раз позднее"}
            />
          )}

          <PageHeader
            vm={vm}
            goBackBlock={goBackBlock}
            shortTitle={vm.isStateDispatch || vm.isSuspendedReturn}
            onDownload={vm.prepareDocuments}
          />
          <DirectAcceptanceStagesBlock vm={vm} />
        </Content>

        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showSignBtn && (
                <Button
                  data-tid="Sign"
                  onClick={toggleCertificatesModal}
                  use={"primary"}
                  size={"large"}
                  width={"200px"}
                >
                  Подписать
                </Button>
              )}
              {vm.showRollbackBtn && (
                <RollbackButton onClick={vm.rollbackDeliveryStatus} size="large" hintText={vm.rollbackHintText}>
                  Вернуться к черновику
                </RollbackButton>
              )}
              {vm.showAcceptBtns && (
                <>
                  <Button
                    data-tid="CreateDocument"
                    onClick={save}
                    use="primary"
                    size={"large"}
                    loading={vm.loadingMap.get("creatingDocument")}
                    disabled={!vm.isAnyItemHasScannedCode}
                  >
                    Приемка отсканированных товаров
                  </Button>
                  <Button
                    data-tid="OpenTrustedModal"
                    onClick={toggleTrustedModal}
                    size={"large"}
                    loading={vm.loadingMap.get("creatingDocument")}
                  >
                    Доверительная приемка
                  </Button>
                </>
              )}
              {vm.showEndBtns && (
                <Button data-tid="Finish" onClick={save} use="primary" size={"large"}>
                  Завершить
                </Button>
              )}
              {vm.showTryAgainBtn && (
                <Button data-tid="TryAgain" onClick={tryAgain} size={"large"}>
                  Повторить приемку
                </Button>
              )}
              {vm.showAcceptRestBtn && (
                <Button data-tid="AcceptRest" onClick={acceptRest} size={"large"}>
                  Принять остальные товары
                </Button>
              )}
            </FooterBtnWrapper>
            {vm.areGoodsEditable && (
              <Button data-tid="SaveAndClose" use="link" onClick={saveDraft} size={"medium"} icon={<DocumentLite />}>
                Сохранить и выйти
              </Button>
            )}
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
