import React from "react";
import { observer } from "mobx-react";
import { ScannerCounterCodesCount, ScanTitle } from "./ScannerCounter.styles";

interface IScannerCounterProps {
  title: string;
  allScannedLen: number;
  allCodesLen?: number;
}

export const ScannerCounter: React.FC<IScannerCounterProps> = observer((props: IScannerCounterProps) => {
  const { allScannedLen, allCodesLen, title } = props;
  return (
    <ScanTitle data-tid="ScannerCounterTitle">
      {title}&nbsp;
      {allCodesLen ? (
        <ScannerCounterCodesCount color={allScannedLen === allCodesLen ? "green" : "default"}>
          <b>{allScannedLen}</b> из <b>{allCodesLen}</b>
        </ScannerCounterCodesCount>
      ) : (
        <ScannerCounterCodesCount>
          <b>{allScannedLen}</b>
        </ScannerCounterCodesCount>
      )}
    </ScanTitle>
  );
});
