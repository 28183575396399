import React from "react";
import { ScanAndCheckPageBody } from "features/ScannerSidePage/SidePages/ScanAndCheck/ScanAndCheckPageBody";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { ScanAndCheckSidePageVM } from "features/ScannerSidePage/SidePages/ScanAndCheck/ScanAndCheckSidePageVM";
import { AcceptanceStore } from "stores/AcceptanceStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { SignatureStore } from "stores/SignatureStore";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface ISidePageProps {
  close(): void;
  save(): Promise<void>;
  delivery: AcceptanceDeliveryModel;
  parentDeliveryId: string;
  additionalInfo: CodesAdditionalInfo;
}

export const DirectAcceptanceSidePage: React.FC<ISidePageProps> = (props: ISidePageProps) => {
  const { delivery, parentDeliveryId, save, additionalInfo } = props;
  const vm = new ScanAndCheckSidePageVM(
    AcceptanceStore,
    SignatureStore,
    AdvertisementStore,
    additionalInfo,
    save,
    delivery,
    parentDeliveryId
  );
  return (
    <ScannerSidePageContainer scanText={"Отсканируйте коды маркировки для проверки"} vm={vm} {...props}>
      <ScanAndCheckPageBody vm={vm} />
    </ScannerSidePageContainer>
  );
};
