import { isSgtin, isSscc } from "helpers/codes";
import { action, computed, observable } from "mobx";
import { CodeModelExtended } from "../../Code/CodeModelExtended";
import { DeliveryItemStatusInfoModel } from "typings/server";

export class BaseDeliveryItemModel {
  public cost: number;
  public vat: number;
  public statusInfo: DeliveryItemStatusInfoModel;
  @observable public name: string;
  @observable public sgtinCodes: CodeModelExtended[] = [];
  @observable public ssccCodes: CodeModelExtended[] = [];

  @action
  setCodes(codes: CodeModelExtended[]) {
    this.ssccCodes = [];
    this.sgtinCodes = [];
    codes.forEach(codeItem => {
      const { code } = codeItem;
      if (isSscc(code)) {
        this.ssccCodes.push(new CodeModelExtended(codeItem));
      } else if (isSgtin(code)) {
        this.sgtinCodes.push(new CodeModelExtended(codeItem));
      }
    });
  }

  getCode(code: string, options: { caseSensitive: boolean } = { caseSensitive: true }): CodeModelExtended | undefined {
    const { caseSensitive } = options;

    const sgtin = this.findCode(this.sgtinCodes, code, caseSensitive);
    const sscc = this.findCode(this.ssccCodes, code, caseSensitive);

    return sscc || sgtin;
  }

  protected findCode(codes: CodeModelExtended[], code: string, caseSensitive: boolean): CodeModelExtended | undefined {
    const lowerCase = code.toLowerCase();
    return codes.find(codeItem => {
      if (!caseSensitive) return String(codeItem.code).toLowerCase() === lowerCase;
      return codeItem.code === code;
    });
  }

  @action
  removeCode(code: string) {
    const ssccInd = this.getCodeIndex(code, this.ssccCodes);
    const sgtinInd = this.getCodeIndex(code, this.sgtinCodes);
    if (ssccInd !== undefined) {
      this.ssccCodes.splice(ssccInd, 1);
    } else if (sgtinInd !== undefined) {
      this.sgtinCodes.splice(sgtinInd, 1);
    }
  }

  getCodeIndex(code: string, arr: CodeModelExtended[]): number | undefined {
    const index = arr.findIndex(codeItem => codeItem.code === code);
    if (index !== -1) return index;
  }

  protected getScannedLength(codes: CodeModelExtended[]): number {
    return codes.reduce((count: number, code: CodeModelExtended) => {
      if (code.scanned) count += 1;
      return count;
    }, 0);
  }

  protected getScannedCodes(codes: CodeModelExtended[]): string[] {
    const scanned: string[] = [];
    codes.forEach(item => {
      if (item.scanned) scanned.push(item.code);
    });
    return scanned;
  }

  @computed
  get allScannedCodes(): string[] {
    return [this.sgtinCodes, this.ssccCodes].map(codes => this.getScannedCodes(codes)).flat();
  }

  @computed
  get allCodesWithDataMatrixes(): CodeModelExtended[] {
    return this.allCodes.filter(x => x.dataMatrix);
  }

  @computed
  get allCodesFlat(): string[] {
    return this.ssccCodesFlat.concat(this.sgtinCodesFlat);
  }

  @computed
  get ssccCodesFlat(): string[] {
    return this.ssccCodes.map(c => c.code);
  }

  @computed
  get sgtinCodesFlat(): string[] {
    return this.sgtinCodes.map(c => c.code);
  }

  @computed
  get ssccScanLen(): number {
    return this.getScannedLength(this.ssccCodes);
  }

  @computed
  get ssccLen(): number {
    return this.ssccCodes.length;
  }

  @computed
  get sgtinScanLen(): number {
    return this.getScannedLength(this.sgtinCodes);
  }

  @computed
  get sgtinLen(): number {
    return this.sgtinCodes.length;
  }

  @computed
  get statusReason(): string | undefined {
    if (!this.statusInfo) return undefined;
    return this.statusInfo.reason;
  }

  @computed
  get allCodes(): CodeModelExtended[] {
    return this.ssccCodes.concat(this.sgtinCodes) || [];
  }
}
