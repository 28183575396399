import React, { useEffect } from "react";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import { Button } from "components";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { ShipmentRoute } from "routes";
import { CertificatesList } from "../../../CertificatesList/CertificatesList";
import { ICertificate } from "@skbkontur/plugin-js";
import { ErrorSignBlock } from "../../../CertificatesList/shared";
import { ReverseShipmentPageVM } from "./ReverseShipmentPageVM";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { ReverseShipmentStagesBlock } from "./Stages/ReverseShipmentStagesBlock";
import { ShipmentConfirmation } from "./ShipmentConfirmation/ShipmentConfirmation";
import { PageHeader } from "../Common/PageHeader/PageHeader";
import { SuccessSignBlock } from "Common/SuccessSignBlock/SuccessSignBlock";
import { RollbackButton } from "Common/Rollback/RollbackButton/RollbackButton";

interface IReverseShipmentPageProps extends RouteComponentProps {
  vm: ReverseShipmentPageVM;
}

export const ReverseShipmentPage = withRouter(
  observer((props: IReverseShipmentPageProps) => {
    const { vm } = props;

    // unmount
    useEffect(() => {
      return () => {
        vm.handleUnmount();
      };
    }, [vm]);

    const onConfirm = () => vm.toggleModal("confirmation");

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const onSign = async (cert: ICertificate): Promise<any> => vm.sign(cert);

    const onAccept = async () => await vm.save();

    const goBackBlock = <GoToBlock name={"К списку отгрузок"} href={ShipmentRoute.baseRoute} />;

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding={"24px 24px 24px 38px"}>
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title={"Подписание документа"}
              signText={"Документ будет подписан при помощи сертификата:"}
              renderSuccessBlock={() => <SuccessSignBlock route={ShipmentRoute.baseRoute} textSubString="отгрузок" />}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={onSign}
            />
          )}

          {vm.modalMap.get("confirmation") && (
            <ShipmentConfirmation
              onClose={onConfirm}
              onAccept={onAccept}
              isLoading={!!vm.loadingMap.get("creatingDocument")}
            />
          )}

          <PageHeader vm={vm} goBackBlock={goBackBlock} onDownload={vm.prepareDocuments} />

          <ReverseShipmentStagesBlock vm={vm} />
        </Content>

        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showSignBtn ? (
                <Button
                  data-tid="Sign"
                  onClick={toggleCertificatesModal}
                  use={"primary"}
                  size={"large"}
                  width={"200px"}
                >
                  Подписать
                </Button>
              ) : (
                <Button
                  data-tid="CreateDocument"
                  use="primary"
                  onClick={onConfirm}
                  size={"large"}
                  loading={vm.loadingMap.get("creatingDocument")}
                >
                  Подтвердить отгрузку
                </Button>
              )}
              {!!vm.showRollbackBtn && (
                <RollbackButton onClick={vm.rollbackDeliveryStatus} size="large" hintText="Для редактирования отгрузки">
                  Вернуться к черновику
                </RollbackButton>
              )}
            </FooterBtnWrapper>
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
