import styled from "styles/styled-components";

export const InviteBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 27px 0 20px;
`;

export const FIOWrap = styled.div`
  padding-left: 23px;
  position: relative;
`;

export const AdminIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${props => props.theme.colors.orange};
`;

export const HelpDotIconWrap = styled.span`
  margin-right: 10px;
  font-size: 14px;
  color: ${props => props.theme.colors.bluePrimary};
`;

export const HelpTooltipText = styled.div`
  white-space: pre-line;
`;

export const DepartmentsWrap = styled.div`
  line-height: 30px;
  margin-top: -5px;
`;

export const RefreshIconWrap = styled.div`
  display: none;
  color: ${props => props.theme.textColors.lightGray2}
  text-align: center;
  font-size: 14px;

  &:hover {
    color: ${props => props.theme.textColors.darkGray}
  }
`;

export const TrashIconWrap = styled.div`
  display: none;
  color: ${props => props.theme.textColors.lightGray2}
  text-align: center;
  font-size: 14px;

  &:hover {
    color: ${props => props.theme.textColors.red}
  }
`;

export const EmployeeWrap = styled.div`
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.lightBackground};

    ${TrashIconWrap}, ${RefreshIconWrap} {
      display: block;
    }
  }
`;
