import React from "react";
import { observer } from "mobx-react";
import { Button, Input, Link, Toast, Tooltip } from "components";
import { MDLPCabinetUrl, MDLPRequisitesInstruction } from "StringResx";
import { SettingsPageVM } from "../SettingsPageVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { BottomText, BtnWrap, FooterWrap, IconWrap, RequisiteLabel, TopText } from "./RequisitesPage.styles";
import {
  ClientIdHelpSidePage,
  HelpSidePage,
  RegNumberHelpSidePage,
  SystemClientSecretSidePage,
  SystemHelpSidePage,
} from "./HelpSidePage/HelpSidePage";
import EyeClosedIcon from "@skbkontur/react-icons/EyeClosed";
import { EyeOpened } from "@skbkontur/react-icons";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";

interface IRequisitesPageProps {
  vm: SettingsPageVM;
  withoutFooter?: boolean;
}

export const RequisitesPage: React.FC<IRequisitesPageProps> = observer((props: IRequisitesPageProps) => {
  const { vm, withoutFooter } = props;

  const onOpenRegNumberHelpType = () => {
    onOpenHelp();
    vm.setHelpType("regNumber");
  };

  const onOpenSystemHelpType = () => {
    onOpenHelp();
    vm.setHelpType("system");
  };

  const onOpenClientHelpType = () => {
    onOpenHelp();
    vm.setHelpType("client");
  };

  const onOpenClientSecretHelpType = () => {
    onOpenHelp();
    vm.setHelpType("clientSecret");
  };

  const onOpenHelp = () => {
    if (!vm.modals.get("help")) {
      vm.toggleModal("help");
    }
  };

  const onCloseHelp = () => {
    vm.toggleModal("help");
  };

  const onSave = async () => {
    await vm.onSaveRequisites();
    Toast.push("Сохранено");
  };

  const onCancel = () => vm.onDiscard();

  const eyeIcon = vm.showEyeIcon && (
    <IconWrap>
      {vm.showSecret ? <EyeOpened onClick={vm.toggleSecret} /> : <EyeClosedIcon onClick={vm.toggleSecret} />}
    </IconWrap>
  );

  const getSecretKeyWarn = () => vm.errors.get("wrongSecretKey");

  return (
    <div data-tid="RequisitesPage">
      {vm.modals.get("help") && (
        <HelpSidePage data-tid="HelpSidePage" onClose={onCloseHelp}>
          {vm.activeHelpType === "system" && SystemHelpSidePage}
          {vm.activeHelpType === "regNumber" && RegNumberHelpSidePage}
          {vm.activeHelpType === "client" && ClientIdHelpSidePage}
          {vm.activeHelpType === "clientSecret" && SystemClientSecretSidePage}
        </HelpSidePage>
      )}
      <TopText>
        <div>
          Проверьте, что организация зарегистрирована в&nbsp;
          <Link href={MDLPCabinetUrl} target={"_blank"}>
            ИС МДЛП
          </Link>
        </div>
        <Link href={MDLPRequisitesInstruction} icon={<HelpDotIcon />} target="_blank">
          Заполнение реквизитов ИС МДЛП
        </Link>
      </TopText>
      <h4>Идентификатор участника</h4>
      <Grid cols={"255px 350px 1fr"} paddingCells={"13px 0"}>
        <GridCell col={"1"} row={"1"}>
          <RequisiteLabel>Регистрационный номер участника</RequisiteLabel>
        </GridCell>
        <GridCell col={"2"} row={"1"}>
          <Input
            data-tid="MemberId"
            autoComplete={"off"}
            autoFocus
            spellCheck={false}
            type={"text"}
            placeholder={"c1ceeb46-4bbb-4858-909b-934e1184fa3a"}
            width={"100%"}
            value={vm.requisites.memberId}
            onValueChange={vm.onChangeMemberId}
          />
          <BtnWrap>
            <Button use={"link"} size={"small"} onClick={onOpenRegNumberHelpType}>
              Как посмотреть регистрационный номер участника
            </Button>
          </BtnWrap>
        </GridCell>

        <GridCell col={"1/4"} row={"2"} colSp={"4"}>
          <h4>Учетная система</h4>
          <BottomText>
            Идентификатор клиента и секретный ключ выдаются при добавлении учетной системы в ИС МДЛП
            <br />
            <Button use={"link"} size={"small"} onClick={onOpenSystemHelpType}>
              Как добавить учетную систему
            </Button>
          </BottomText>
        </GridCell>

        <GridCell col={"1"} row={"3"}>
          <RequisiteLabel>Идентификатор клиента</RequisiteLabel>
        </GridCell>
        <GridCell col={"2"} row={"3"}>
          <Input
            data-tid="ClientId"
            autoComplete={"off"}
            type={"text"}
            placeholder={"2f27d73a-9a7f-48df-b17f-381ae1184caa4"}
            width={"100%"}
            spellCheck={false}
            value={vm.requisites.clientId}
            onValueChange={vm.onChangeClientId}
          />
          <BtnWrap>
            <Button use={"link"} size={"small"} onClick={onOpenClientHelpType}>
              Как посмотреть идентификатор клиента
            </Button>
          </BtnWrap>
        </GridCell>

        <GridCell col={"1"} row={"4"}>
          <RequisiteLabel>Секретный ключ</RequisiteLabel>
        </GridCell>
        <GridCell col={"2"} row={"4"}>
          <Tooltip render={getSecretKeyWarn}>
            <Input
              data-tid="Secret"
              autoComplete={"off"}
              type={vm.showSecret ? "text" : "password"}
              placeholder={"d1d3364a-1e02-40c2-957f-dbae1184rnjve"}
              width={"100%"}
              spellCheck={false}
              value={vm.requisites.secret}
              rightIcon={eyeIcon}
              onValueChange={vm.onChangeSecretKey}
              error={vm.errors.has("wrongSecretKey")}
            />
          </Tooltip>
          <BtnWrap>
            <Button use={"link"} size={"small"} onClick={onOpenClientSecretHelpType}>
              Как посмотреть секретный ключ
            </Button>
          </BtnWrap>
        </GridCell>
      </Grid>

      {!withoutFooter && (
        <FooterWrap title={"Для сохранения заполните все обязательные поля"}>
          <Button data-tid="Submit" disabled={vm.isDisabledBtn} use={"primary"} size={"large"} onClick={onSave}>
            Сохранить
          </Button>
          &nbsp;&nbsp;
          <Button data-tid="Cancel" disabled={vm.isDisabledBtn} size={"large"} onClick={onCancel}>
            Отменить
          </Button>
        </FooterWrap>
      )}
    </div>
  );
});
