import React from "react";
import { Warning } from "@skbkontur/react-icons";
import { IconWrap, WarningBlock } from "./ConfirmationTexts.styles";

interface IConfirmationTextProps {
  allScanned: boolean;
}

export const UnregisteredWarning = (props: IConfirmationTextProps) => {
  const { allScanned } = props;
  return (
    <>
      {allScanned && "В ИС МДЛП будут отправлены сведения об отгрузке незарегистрированному получателю."}
      <WarningBlock>
        <IconWrap>
          <Warning />
        </IconWrap>
        <span>
          Все товары будут выведены из оборота по причине
          <br />
          отгрузки на незарегистрированное место деятельности
        </span>
      </WarningBlock>
    </>
  );
};

export const EAEUWarning = (props: IConfirmationTextProps) => {
  const { allScanned } = props;
  return (
    <>
      {allScanned && "В ИС МДЛП будут отправлены сведения об отгрузке в страны ЕАЭС."}
      <WarningBlock>
        <IconWrap>
          <Warning />
        </IconWrap>
        <span>
          Все товары будут выведены из оборота по причине <br /> отгрузки на территорию государства-члена ЕАЭС
        </span>
      </WarningBlock>
    </>
  );
};
