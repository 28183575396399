import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import { ICertificate } from "@skbkontur/plugin-js";
import { AcceptanceRoute } from "routes";
import { Button, Hint, Toast } from "components";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { CertificatesList } from "../../../CertificatesList/CertificatesList";
import { PageHeader } from "../Common/PageHeader/PageHeader";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import DocumentLite from "@skbkontur/react-icons/DocumentLite";
import { ErrorSignBlock } from "../../../CertificatesList/shared";
import { ReverseAcceptancePageVM } from "./ReverseAcceptancePageVM";
import { ReverseAcceptanceStages } from "./Stages/ReverseAcceptanceStagesBlock";
import { AcceptanceEditor } from "./Editor/AcceptanceEditor";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { ReverseAcceptanceScannerSidePage } from "./ReverseAcceptanceScannerSidePage";
import { DeliveryConfirmation } from "../../../Common/Confirmation/DeliveryConfirmation";
import { SimplifiedWarning } from "./Confirmation/ConfirmationTexts";
import { RollbackButton } from "features/Common/Rollback/RollbackButton/RollbackButton";
import { RollbackModal } from "features/Common/Rollback/RollbackModal/RollbackModal";
import TrashIcon from "@skbkontur/react-icons/Trash";
import { DeleteDeliveryModal } from "features/Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { deleteBtnTheme, DeleteBtnWrap, RejectBtnWrap } from "features/Common/DetailsPage/DetailsPage.styles";
import { SuccessSignBlock } from "features/Common/SuccessSignBlock/SuccessSignBlock";
import { SimplifiedAcceptanceStages } from "./Stages/SimplifiedAcceptanceStages";

interface IReverseAcceptanceDetailsPageProps extends RouteComponentProps {
  vm: ReverseAcceptancePageVM;
}

export const ReverseAcceptancePage = withRouter(
  observer((props: IReverseAcceptanceDetailsPageProps) => {
    const { vm, history } = props;

    // unmount
    useEffect(() => () => vm.handleUnmount(), [vm]);

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const toggleRollbackModal = () => vm.toggleModal("rollback");

    const onRollback = async () => {
      await vm.rollbackDeliveryStatus();
      toggleRollbackModal();
    };

    const onRollbackButtonClick = async () => {
      if (vm.shouldOpenRollbackModal) {
        toggleRollbackModal();
      } else {
        vm.rollbackDeliveryStatus();
      }
    };

    const onSign = async (cert: ICertificate): Promise<any> => vm.sign(cert, vm.isResended);

    const saveDraft = async () => {
      history.push(AcceptanceRoute.baseRoute);
    };

    const toggleScanSidePage = () => vm.toggleModal("scanPage");

    const onComplete = async () => {
      vm.setSubmitted(true);
      if (vm.canComplete()) {
        await save();
      }
    };

    const save = async () => {
      await vm.save();
      Toast.push("Приемка завершена. Создаем документ");
    };

    const resend = async () => {
      await vm.resend();
    };

    const toggleEditorModal = () => vm.toggleModal("editorModal");

    const toggleConfirmationModal = () => vm.toggleModal("confirmation");

    const toggleDeleteModal = () => vm.toggleModal("delete");

    const onDelete = async () => {
      await vm.deleteDelivery();
      toggleDeleteModal();
      history.push(AcceptanceRoute.baseRoute);
    };

    const toggleRejectAll = () => vm.toggleRejectAll();
    const rejectAll = async () => {
      vm.setSubmitted(true);
      if (vm.validateReject()) {
        await vm.rejectAll();
      }
    };

    const goBackBlock = <GoToBlock name={"К списку поставок"} href={AcceptanceRoute.baseRoute} />;

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding={"24px 24px 24px 38px"}>
          {vm.modalMap.get("editorModal") && (
            <AcceptanceEditor
              consignorInn={vm.consignorInn}
              onClose={toggleEditorModal}
              errorText={vm.errors.get("requiredFields")}
              onComplete={vm.submitted ? onComplete : undefined}
            />
          )}
          {vm.modalMap.get("scanPage") && (
            <ReverseAcceptanceScannerSidePage
              close={toggleScanSidePage}
              good={vm.selectedGood}
              additionalInfo={vm.additionalInfo}
              onSave={vm.savePositions}
            />
          )}
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title={"Подписание документа"}
              renderSuccessBlock={() => <SuccessSignBlock route={AcceptanceRoute.baseRoute} textSubString="поставок" />}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={onSign}
            />
          )}
          {vm.modalMap.get("confirmation") && (
            <DeliveryConfirmation
              unscannedLen={vm.unscannedPositionsLength}
              onSave={save}
              title={"Завершение приемки"}
              errorText={"Не смогли завершить приемку. Закройте окно и попробуйте еще раз позднее"}
              onClose={toggleConfirmationModal}
              isSimplified={vm.isSimplified}
            >
              {vm.isSimplified && <SimplifiedWarning unscannedLen={vm.unscannedPositionsLength} />}
            </DeliveryConfirmation>
          )}
          {vm.modalMap.get("rollback") && (
            <RollbackModal
              headerText="Вернуться к приемке?"
              bodyText="Приемка станет доступна для редактирования и повторной отправки в ИС МДЛП."
              onClose={toggleRollbackModal}
              onRollback={onRollback}
              isLoading={vm.loadingMap.get("rollback")}
            />
          )}
          {vm.modalMap.get("delete") && (
            <DeleteDeliveryModal
              deliveryType={vm.delivery.type}
              isDeliveryError={vm.isFailedStatus}
              isScannedCodes={Boolean(vm.delivery.allScannedLen)}
              isLoading={vm.loadingMap.get("delete")}
              onClose={toggleDeleteModal}
              onDelete={onDelete}
            />
          )}

          <PageHeader
            vm={vm}
            showEditor={vm.showEditor}
            goBackBlock={goBackBlock}
            shortTitle={vm.isSimplified}
            onEdit={toggleEditorModal}
            onDownload={vm.prepareDocuments}
          />
          {vm.isSimplified ? <SimplifiedAcceptanceStages vm={vm} /> : <ReverseAcceptanceStages vm={vm} />}
        </Content>
        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showFinishAcceptanceBtn && (
                <Button
                  data-tid="CreateDocument"
                  use="primary"
                  loading={vm.loadingMap.get("creatingDocument")}
                  disabled={!vm.canCompleteAcceptance}
                  size={"large"}
                  onClick={onComplete}
                >
                  Завершить приемку
                </Button>
              )}
              {vm.showSignBtn && (
                <Button data-tid="Sign" onClick={toggleCertificatesModal} use="primary" size="large" width="200px">
                  Подписать
                </Button>
              )}
              {vm.showTryAgainBtn && (
                <Hint text="Исправьте ошибки и повторно отправьте сведения в ИС МДЛП">
                  <Button data-tid="TryAgain" onClick={resend} size="large">
                    Отправить повторно
                  </Button>
                </Hint>
              )}
              {!!vm.rollbackBtnText && (
                <RollbackButton onClick={onRollbackButtonClick} size="large" hintText={vm.rollbackHintText}>
                  {vm.rollbackBtnText}
                </RollbackButton>
              )}
              {vm.showDeleteBtn && (
                <DeleteBtnWrap>
                  <Button
                    data-tid="DeleteDocument"
                    onClick={toggleDeleteModal}
                    theme={deleteBtnTheme}
                    use="link"
                    icon={<TrashIcon />}
                  >
                    {vm.isFailedStatus ? "Удалить приемку" : "Удалить черновик"}
                  </Button>
                </DeleteBtnWrap>
              )}
              {vm.showRejectBtn && (
                <RejectBtnWrap>
                  <Button
                    data-tid="Reject"
                    use="primary"
                    size="large"
                    onClick={rejectAll}
                    loading={vm.loadingMap.get("reject")}
                  >
                    Завершить
                  </Button>
                </RejectBtnWrap>
              )}
              {vm.showToggleIsRejectBtn && (
                <Button data-tid="OpenRejectInfo" size="large" onClick={toggleRejectAll}>
                  {vm.delivery.isRejected ? "Отменить" : "Отказаться от переданных сведений по приемке"}
                </Button>
              )}
            </FooterBtnWrapper>
            {vm.areGoodsEditable && (
              <Button data-tid="SaveAndClose" use="link" onClick={saveDraft} size={"medium"} icon={<DocumentLite />}>
                Сохранить и выйти
              </Button>
            )}
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
