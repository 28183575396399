import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import { DeliveryItemDisposalReportModel } from "typings/server";
import { RegistratorDisposeStatusResultModel, RegistratorQueueBody } from "typings/registrator";

class DisposalApiInternal {
  constructor(private httpClient: IHttpClient) {}

  saveDisposalCodes(items: DeliveryItemDisposalReportModel[], deliveryId: string): Promise<void> {
    return this.httpClient.post("draft", items, { deliveryId });
  }

  saveTaskResult(model: RegistratorDisposeStatusResultModel, deliveryId: string): Promise<void> {
    return this.httpClient.post("saveTaskResult", model, { deliveryId });
  }

  generateRequest(deliveryId: string): Promise<RegistratorQueueBody> {
    return this.httpClient.post("generateRequest", null, { deliveryId });
  }

  updateStatus(deliveryId: string): Promise<void> {
    return this.httpClient.post("updateStatus", null, { deliveryId });
  }
}

export const DisposalApi = new DisposalApiInternal(getHttpClient(drugsApi + "/disposal"));
