import styled from "styles/styled-components";

export const ButtonWrap = styled.span`
  margin-right: 10px;
`;

export const Fill = styled.div`
  width: 100%;
  padding-bottom: 15px;
`;
