import { RouteName } from "./RouteNames";

class TransferRouteParams {
  operationId?: string;
}

export class TransferRoute extends TransferRouteParams {
  public static readonly title = "Перемещение";
  public static readonly baseRoute = `/${RouteName.Transfer}`;
  public static readonly route = `${TransferRoute.baseRoute}/:operationId?`;

  public static getHref(id?: string): string {
    return id ? `${this.baseRoute}/${id}` : this.baseRoute;
  }
}
