import styled from "styles/styled-components";

export const EmptyDeliveries = styled.div`
  margin-top: 10px;
  margin-left: 9px;
`;

export const List = styled.div`
  max-width: 800px;
  margin: 14px 0 0 7px;
`;
