import styled from "styles/styled-components";

export const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${props => props.theme.colors.orangeDark};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
