import React, { useState } from "react";
import { Button, Modal } from "components";
import { ErrorModalImg, ErrorModalImgWrap, UnpackModalText } from "./UnpackConfirmation.styles";
import { Footer, FooterBtnWrapper } from "components/Layout/Footer.styles";

interface IUnpackConfirmationProps {
  onClose(): void;
  onConfirm(): void;
  isLoading: boolean;
}

export const UnpackConfirmation: React.FC<IUnpackConfirmationProps> = (props: IUnpackConfirmationProps) => {
  const [error, setError] = useState(false);

  const onClose = () => props.onClose();

  const onAccept = async () => {
    try {
      await props.onConfirm();
      onClose();
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Modal data-tid="UnpackConfirmationModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>Расформирование на 1 уровень</Modal.Header>
      <Modal.Body>
        <UnpackModalText>
          {error ? (
            <ErrorModalImgWrap>
              <ErrorModalImg />
              Не смогли расформировать агрегат. Закройте окно и попробуйте еще раз позднее.
            </ErrorModalImgWrap>
          ) : (
            <>
              Агрегат будет расформирован на 1 уровень.
              <p>При наличии нескольких уровней вложенности агрегата расформируйте каждый уровень отдельно.</p>
            </>
          )}
        </UnpackModalText>
      </Modal.Body>
      <Footer>
        <FooterBtnWrapper>
          {!error && (
            <Button data-tid="Submit" use="primary" size={"large"} onClick={onAccept} loading={props.isLoading}>
              Расформировать
            </Button>
          )}
          <Button data-tid="Cancel" onClick={onClose} size={"large"}>
            Отменить
          </Button>
        </FooterBtnWrapper>
      </Footer>
    </Modal>
  );
};
