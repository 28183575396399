import React from "react";
import { MenuItemCopy } from "./MenuItemFixed.styles";

interface MenuItemFixedProps {
  className: string;
  tabIndex: number;
  onClick(): void;
  onMouseOver(): void;
  onMouseLeave(): void;
}

// Выпиливаем прокидывание onMouseOver, чтобы не сбрасывался фокус с инпута при ховере MenuItem
export const MenuItemFixed: React.FC<MenuItemFixedProps> = props => {
  const { onMouseOver, ...rest } = props;
  return <MenuItemCopy {...rest}></MenuItemCopy>;
};
