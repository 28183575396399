import React from "react";
import { observer } from "mobx-react";
import { DepartmentModel } from "typings/server";
import { ComboBox, Tooltip } from "@skbkontur/react-ui";
import { TextGrey } from "styles/shared.styles";
import { ComboBoxWrap } from "./RegistratorEditor.styles";

interface RegistratorComboBoxProps {
  department?: DepartmentModel;
  availableDepartments: DepartmentModel[];
  setDepartment(department: DepartmentModel): void;
  renderError(): string | undefined;
}

export const DepartmentComboBox = observer(
  ({ availableDepartments, department, setDepartment, renderError }: RegistratorComboBoxProps) => {
    const getItems = (q: string) => {
      const query = q.toLowerCase();
      return Promise.resolve(
        availableDepartments.filter(dep => {
          const name = (dep.name || dep.address || "").toLowerCase();
          return name.includes(query);
        })
      );
    };

    const onChange = (dep: DepartmentModel) => setDepartment(dep);

    const renderItem = (item: DepartmentModel) => item.name || item.address;

    return (
      <>
        <ComboBoxWrap>
          <Tooltip render={renderError} pos="right middle">
            <ComboBox
              data-tid="DepartmentComboBox"
              getItems={getItems}
              value={department}
              onValueChange={onChange}
              renderItem={renderItem}
              renderValue={renderItem}
              valueToString={renderItem}
              width={"100%"}
              error={!!renderError()}
            />
          </Tooltip>
        </ComboBoxWrap>
        <TextGrey>Регистратор выбытия привяжется к выбранному подразделению</TextGrey>
      </>
    );
  }
);
