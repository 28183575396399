import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import { Button } from "components";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { CertificatesList } from "../../../CertificatesList/CertificatesList";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { CirculationPageVM } from "../CirculationPageVM";
import { PageHeader } from "../Common/PageHeader/PageHeader";
import { StagesBlock } from "../Common/StagesBlock";
import { DocumentLite } from "@skbkontur/react-icons";
import { ErrorSignBlock } from "../../../CertificatesList/shared";
import { WithdrawalEditorModal } from "../../Editor/WithdrawalEditorModal/WithdrawalEditorModal";
import { WithdrawalSidePage } from "../Common/SidePage";
import { DisposalEditorModal } from "../../Editor/DisposalEditorModal/DisposalEditorModal";
import { RollbackButton } from "Common/Rollback/RollbackButton/RollbackButton";
import { RollbackModal } from "Common/Rollback/RollbackModal/RollbackModal";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { DeleteBtnWrap, deleteBtnTheme } from "Common/DetailsPage/DetailsPage.styles";
import TrashIcon from "@skbkontur/react-icons/Trash";
import { SuccessSignBlock } from "Common/SuccessSignBlock/SuccessSignBlock";
import { DeliveryType } from "typings/server";
import { DisposalRegistratorEditorModal } from "../../Editor/DisposalRegistratorEditorModal/DisposalRegistratorEditorModal";
import { DisposalWithRegistratorStagesBlock } from "./DisposalWithRegistrator/DisposalWithRegistratorStagesBlock";

interface IPageProps extends RouteComponentProps {
  vm: CirculationPageVM;
}

export const WithdrawalPage = withRouter(
  observer((props: IPageProps) => {
    const { vm, history } = props;

    // unmount
    useEffect(() => () => vm.handleUnmount(), [vm]);

    const save = () => vm.save();

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const toggleEditorModal = () => vm.toggleModal("editorModal");

    const toggleScanPage = () => vm.toggleModal("scanPage");

    const toggleRollbackModal = () => vm.toggleModal("rollback");

    const onRollback = async () => {
      await vm.rollbackDeliveryStatus();
      toggleRollbackModal();
    };

    const onRollbackButtonClick = async () => {
      if (vm.shouldOpenRollbackModal) {
        toggleRollbackModal();
      } else {
        vm.rollbackDeliveryStatus();
      }
    };

    const toggleDeleteModal = () => vm.toggleModal("delete");

    const onDelete = async () => {
      await vm.deleteDelivery();
      toggleDeleteModal();
      history.push(CirculationRoute.baseRoute);
    };

    const goBackBlock = <GoToBlock name="К списку операций" href={CirculationRoute.baseRoute} />;

    const saveDraft = () => {
      history.push(CirculationRoute.baseRoute);
    };

    const getEditor = () => {
      switch (vm.delivery.type) {
        case DeliveryType.Withdrawal:
          return <WithdrawalEditorModal onClose={toggleEditorModal} delivery={vm.delivery} />;
        case DeliveryType.Disposal:
          return <DisposalEditorModal onClose={toggleEditorModal} delivery={vm.delivery} />;
        case DeliveryType.DisposalWithRegistrator:
          return (
            <DisposalRegistratorEditorModal
              onClose={toggleEditorModal}
              delivery={vm.delivery}
              registrators={vm.registrators}
            />
          );
        default:
          return null;
      }
    };

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding={"24px 24px 24px 38px"}>
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title={"Подписание документа"}
              signText={"Документ будет подписан при помощи сертификата:"}
              renderSuccessBlock={() => (
                <SuccessSignBlock route={CirculationRoute.baseRoute} textSubString="операций" />
              )}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={vm.sign}
            />
          )}
          {vm.modalMap.get("editorModal") && getEditor()}
          {vm.modalMap.get("scanPage") && (
            <WithdrawalSidePage
              additionalInfo={vm.additionalInfo}
              close={toggleScanPage}
              delivery={vm.delivery}
              save={vm.saveItems}
            />
          )}
          {vm.modalMap.get("rollback") && (
            <RollbackModal
              headerText="Вернуться к черновику?"
              bodyText="Вывод из оборота станет доступен для редактирования и повторной отправки в ИС МДЛП."
              onClose={toggleRollbackModal}
              onRollback={onRollback}
              isLoading={vm.loadingMap.get("rollback")}
            />
          )}
          {vm.modalMap.get("delete") && (
            <DeleteDeliveryModal
              deliveryType={vm.delivery.type}
              isDeliveryError={vm.isFailedStatus}
              isScannedCodes={Boolean(vm.delivery.allCodesLen)}
              isLoading={vm.loadingMap.get("delete")}
              onClose={toggleDeleteModal}
              onDelete={onDelete}
            />
          )}

          <PageHeader vm={vm} type={DeliveryType.Withdrawal} goBackBlock={goBackBlock} onEdit={toggleEditorModal} />

          {vm.isDisposalWithRegistrator ? <DisposalWithRegistratorStagesBlock vm={vm} /> : <StagesBlock vm={vm} />}
        </Content>

        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showSignBtn && (
                <Button
                  data-tid="Sign"
                  onClick={toggleCertificatesModal}
                  size={"large"}
                  use={"primary"}
                  width={"200px"}
                >
                  Подписать
                </Button>
              )}
              {vm.showSaveBtn && (
                <Button
                  data-tid="CreateDocument"
                  use="primary"
                  onClick={save}
                  size={"large"}
                  loading={vm.loadingMap.get("creatingDocument")}
                  disabled={!vm.delivery.allCodesLen}
                >
                  Завершить вывод из оборота
                </Button>
              )}
              {vm.showRollbackBtn && (
                <RollbackButton onClick={onRollbackButtonClick} size="large" hintText={vm.rollbackHintText}>
                  Вернуться к черновику
                </RollbackButton>
              )}
              {vm.showDeleteBtn && (
                <DeleteBtnWrap>
                  <Button
                    data-tid="DeleteDocument"
                    onClick={toggleDeleteModal}
                    theme={deleteBtnTheme}
                    use="link"
                    icon={<TrashIcon />}
                  >
                    {vm.isFailedStatus ? "Удалить вывод из оборота" : "Удалить черновик"}
                  </Button>
                </DeleteBtnWrap>
              )}
            </FooterBtnWrapper>
            {vm.isEditable && (
              <Button data-tid="SaveAndClose" use="link" onClick={saveDraft} size={"medium"} icon={<DocumentLite />}>
                Сохранить и выйти
              </Button>
            )}
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
