import { IPluginBase64Content, IPluginBase64Sink } from "@skbkontur/plugin-js/lib/Plugin/api/plugin";
import { ReportsApi } from "api/ReportsApi";
import { DownloadResult } from "typings/client";
import { SignApi } from "api/SignApi";
import { ICertificate, Plugin } from "@skbkontur/plugin-js";
import { getBase64FromBlob } from "helpers/base64";
import { MdlpAuthApi } from "api/MdlpAuthApi";

class SignatureStoreInternal {
  private plugin: Plugin = new Plugin();

  async sign(deliveryId: string, cert: ICertificate): Promise<any> {
    const doc = await this.getDocument(deliveryId);
    const signature = await this.getBase64BlobSignature(doc.data, cert);
    return this.saveSignature(deliveryId, signature.data);
  }

  private getBase64BlobSignature(data: Blob, cert: ICertificate): Promise<IPluginBase64Content> {
    return new Promise((resolve, reject) => {
      try {
        getBase64FromBlob(data, (base64: string) => {
          const source: IPluginBase64Content = { type: "base64", data: base64 };
          const sink: IPluginBase64Sink = { type: "base64" };

          const contents = this.plugin.sign({ source, sink, certs: [cert] });
          return resolve(contents);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  public async createDocument(deliveryId: string): Promise<void> {
    return ReportsApi.create(deliveryId);
  }

  public async sendDocument(deliveryId: string): Promise<void> {
    return ReportsApi.send(deliveryId);
  }

  public async getDocument(deliveryId: string): Promise<DownloadResult> {
    return await SignApi.getDocumentToSign(deliveryId);
  }

  public async saveSignature(deliveryId: string, signature: string): Promise<any> {
    return await SignApi.saveSignature(deliveryId, signature);
  }

  async getCode(cert: ICertificate): Promise<{ code: string; blob: Blob }> {
    const thumbprint = cert.thumbprint();
    const result = await MdlpAuthApi.getCode(thumbprint);
    const code = await new Response(result.data).text();
    return {
      code,
      blob: result.data,
    };
  }

  async signCode(cert: ICertificate): Promise<void> {
    const { code, blob } = await this.getCode(cert);
    const signature = await this.getBase64BlobSignature(blob, cert);
    return await MdlpAuthApi.signCode({ code, signature: signature.data });
  }
}

export interface ISignatureStore extends SignatureStoreInternal {}

export const SignatureStore = new SignatureStoreInternal();
