import React from "react";
import { observer } from "mobx-react";
import { SettingsPageVM } from "../SettingsPageVM";
import { Button, Link, Toast, Tooltip } from "components";
import { HelpDotIconWrap, HelpTooltipText, InviteBlock } from "./Employees.styles";
import UserIcon from "@skbkontur/react-icons/User";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { TableWrap } from "../SettingsPage.styles";
import { EmployeeItem } from "./EmployeeItem";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { DeletionModal } from "../Common/DeletionModal/DeletionModal";
import { EmployeeEditorModal } from "./EmployeeEditor/EmployeeEditorModal";
import { employeesInstruction } from "StringResx";

interface EmployeesPageProps {
  vm: SettingsPageVM;
}

export const EmployeesPage = observer((props: EmployeesPageProps) => {
  const { vm } = props;
  const cols = "1fr 1fr 1fr 42px 42px";

  const invite = () => vm.toggleModal("employeeEditor");

  const renderHelpTooltip = () => (
    <HelpTooltipText>
      Администратор может добавлять и<br />
      удалять других сотрудников и настраивать
      <br />
      права доступа к подразделениям
    </HelpTooltipText>
  );

  const onDelete = async () => {
    await vm.deleteEmployee();
    vm.toggleModal("deleteEmployee");
    Toast.push("Сотрудник удален");
  };

  const onCloseRemovalModal = () => vm.toggleModal("deleteEmployee");

  const onCloseEditor = () => {
    vm.toggleModal("employeeEditor");
    vm.setSelectedEmployee(undefined);
  };

  return (
    <div data-tid="EmployeesPage">
      {vm.modals.get("deleteEmployee") && (
        <DeletionModal header="Удалить сотрудника?" onClose={onCloseRemovalModal} onDelete={onDelete} />
      )}
      {vm.modals.get("employeeEditor") && (
        <EmployeeEditorModal onClose={onCloseEditor} selectedEmployee={vm.selectedEmployee} />
      )}
      <InviteBlock>
        <Button data-tid="InviteEmployee" onClick={invite} icon={<UserIcon />}>
          Пригласить сотрудника
        </Button>
        <Link href={employeesInstruction} icon={<HelpDotIcon />} target="_blank">
          Как добавить сотрудника
        </Link>
      </InviteBlock>
      <TableWrap>
        <Grid cols={cols} headerBorderBottom>
          <GridCellHeader col={1} row={1}>
            <Tooltip trigger={"hover"} render={renderHelpTooltip} pos={"top center"} allowedPositions={["top center"]}>
              <HelpDotIconWrap>
                <HelpDotIcon />
              </HelpDotIconWrap>
            </Tooltip>
            Сотрудник
          </GridCellHeader>
          <GridCellHeader col={2} row={1}>
            Эл. почта
          </GridCellHeader>
          <GridCellHeader col={3} row={1}>
            Доступные подразделения
          </GridCellHeader>
          <GridCellHeader col={4} row={1} />
        </Grid>
        {vm.employees.map(employee => (
          <EmployeeItem key={employee.id} vm={vm} employee={employee} cols={cols} />
        ))}
      </TableWrap>
    </div>
  );
});
