import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { getFormattedDate } from "helpers/date";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { DeleteWrap, DeliveryListRow } from "Common/DeliveriesList/DeliveriesList.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { StatusItem } from "Common/Status/StatusItem";
import TrashIcon from "@skbkontur/react-icons/Trash";

interface AcceptanceDeliveriesListItemProps {
  operation: DeliveryIndexModel;
  cols: string;
  type: DeliveryType;
  onClick: (doc: DeliveryIndexModel) => void;
  onDelete: (e: SyntheticEvent<HTMLElement>, delivery: DeliveryIndexModel) => void;
  isCanBeDeleted: boolean;
}

export const OperationsListItem = observer(
  ({ cols, type, operation, onClick, onDelete, isCanBeDeleted }: AcceptanceDeliveriesListItemProps) => {
    const { status, date, extractingCount, extractingDrug } = operation;
    const handleRowClick = () => onClick(operation);
    const handleOpenDeleteModal = (e: SyntheticEvent<HTMLElement>) => onDelete(e, operation);

    return (
      <DeliveryListRow onClick={handleRowClick}>
        <Grid cols={cols}>
          <GridCell data-tid="DocumentDate" col={1} row={1} margin="0 0 0 5px">
            {date && getFormattedDate(date)}
          </GridCell>
          <GridCell col={2} row={1} textAlign="left" margin="0 0 0 9px">
            <StatusItem type={type} status={status} />
          </GridCell>
          <GridCell data-tid="ExtractingDrug" col={3} row={1} textAlign="left">
            {extractingDrug}
          </GridCell>
          <GridCell data-tid="ExtractingCount" col={4} row={1} textAlign="left">
            {extractingCount}
          </GridCell>
          <GridCell col={5} row={1} textAlign="center">
            {isCanBeDeleted && (
              <DeleteWrap data-tid="DeleteIcon" onClick={handleOpenDeleteModal}>
                <TrashIcon />
              </DeleteWrap>
            )}
          </GridCell>
        </Grid>
      </DeliveryListRow>
    );
  }
);
