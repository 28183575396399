import React from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock, GoodsWrapperBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { GoodsScannerTable } from "Common/GoodsTable/GoodsScannerTable/GoodsScannerTable";
import { CreatingDocWrap, DateAndFIOWrap, GoodsHeadWrapper, RecalledWrap, ScanText } from "Common/Stages/Stages.styles";
import { ReverseAcceptancePageVM } from "../ReverseAcceptancePageVM";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { AcceptanceDeliveryStatusNames } from "Common/Status/StatusesName";
import { ReversedAcceptanceStagesPart } from "./ReverseAcceptanceStagesPart";
import { AcceptedCodes, FailedCodes, RecalledCodes } from "Common/Stages/CodesStatuses/CodesStatuses";
import { DateAndUser } from "Common/Stages/DateAndUser";

interface IAcceptanceStagesProps {
  vm: ReverseAcceptancePageVM;
}

export const SimplifiedAcceptanceStages: React.FC<IAcceptanceStagesProps> = observer(
  ({ vm }: IAcceptanceStagesProps) => {
    const onClick = (good: UtdPositionModelExtended) => {
      vm.setSelectedGood(good);
      vm.toggleModal("scanPage");
    };

    const onCollapse = () => vm.onCollapseDelivery(vm.delivery.id);

    return (
      <DeliveryStagesBlock>
        <DeliveryStatusBlock
          data-tid="ShippedStage"
          name={"Создание черновика"}
          status={vm.stages[DeliveryStage.Shipped].progress}
          error={vm.stages[DeliveryStage.Shipped].errorInfo}
          alwaysVisibleComp={
            <DateAndFIOWrap data-tid="CompleteInfo">
              {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Draft])}
            </DateAndFIOWrap>
          }
        />
        <DeliveryStatusBlock
          data-tid="ProcessingStage"
          size={vm.getTitleSize(vm.stages[DeliveryStage.Processing])}
          name={vm.processingStepName}
          status={vm.stages[DeliveryStage.Processing].progress}
          collapsed={!vm.isDeliveryOpenedState.get(vm.delivery.id)}
          alwaysVisibleComp={
            <DateAndUser
              delivery={vm.delivery}
              getCompletionDate={vm.getCompletionDate}
              getCompletionUser={vm.getCompletionUser}
              step={DeliveryStatus.Processing}
            />
          }
          onCollapse={onCollapse}
        >
          <GoodsWrapperBlock>
            <GoodsHeadWrapper>
              {vm.areGoodsEditable && <ScanText>Выберите товар из списка и отсканируйте коды маркировки</ScanText>}
            </GoodsHeadWrapper>
            <RecalledWrap>
              <AcceptedCodes delivery={vm.delivery} acceptedCodesLen={vm.delivery.acceptedCodesLen} />
              <FailedCodes failedCodesLen={vm.delivery.failedCodesLen} />
              <RecalledCodes recalledCodesLen={vm.delivery.recalledCodesLen} />
            </RecalledWrap>
            <GoodsScannerTable
              goods={vm.delivery.utdPositions}
              onClick={onClick}
              editable={vm.areGoodsEditable}
              additionalInfo={vm.additionalInfo}
            />
          </GoodsWrapperBlock>
        </DeliveryStatusBlock>

        <DeliveryStatusBlock
          data-tid="SigningStage"
          status={vm.stages[DeliveryStage.Signing].progress}
          name={vm.stages[DeliveryStage.Signing].name}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Signing])}
          error={vm.stages[DeliveryStage.Signing].errorInfo}
          alwaysVisibleComp={
            <>
              {vm.delivery.status === DeliveryStatus.CreatingDocument && (
                <CreatingDocWrap>
                  <Spinner type="mini" caption={AcceptanceDeliveryStatusNames[DeliveryStatus.CreatingDocument]} />
                </CreatingDocWrap>
              )}
              <DateAndUser
                step={DeliveryStatus.Signing}
                delivery={vm.delivery}
                getCompletionUser={vm.getCompletionUser}
                getCompletionDate={vm.getCompletionDate}
              />
            </>
          }
        />

        <DeliveryStatusBlock
          data-tid="SendingStage"
          status={vm.stages[DeliveryStage.Sending].progress}
          name={vm.getSendingStepName(vm.stages[DeliveryStage.Sending])}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Sending])}
          error={vm.stages[DeliveryStage.Sending].errorInfo}
          alwaysVisibleComp={
            <DateAndFIOWrap data-tid="CompleteInfo">
              {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Sending])}
            </DateAndFIOWrap>
          }
        />

        {vm.showWaitingForCounterpartyStage && (
          <DeliveryStatusBlock
            data-tid="WaitingForCounterpartyStage"
            status={vm.stages[DeliveryStage.WaitingForCounterparty].progress}
            name={vm.waitingForCounterpartyStageName}
            size={vm.getTitleSize(vm.stages[DeliveryStage.WaitingForCounterparty])}
            error={vm.stages[DeliveryStage.WaitingForCounterparty].errorInfo}
            isRecalled={vm.delivery.status === DeliveryStatus.Recalled}
            alwaysVisibleComp={
              <DateAndFIOWrap data-tid="CompleteInfo">
                {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.WaitingForCounterparty])}
              </DateAndFIOWrap>
            }
          />
        )}

        {vm.delivery.childDeliveries.map(child => (
          <ReversedAcceptanceStagesPart
            data-tid="ChildStage"
            delivery={child}
            vm={vm}
            key={child.id}
            isPartial={child.allCodesLen !== vm.delivery.allCodesLen}
          />
        ))}

        <DeliveryStatusBlock
          data-tid="DoneStage"
          status={vm.stages[DeliveryStage.Done].progress}
          name={vm.doneBlockTitle}
          error={vm.stages[DeliveryStage.Done].errorInfo}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
        />
      </DeliveryStagesBlock>
    );
  }
);
