import styled from "styles/styled-components";

export const List = styled.div`
  display: inline-flex;
  position: relative;
  margin: 30px auto 60px 46px;

  &::before {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    left: 5px;
    margin-top: -1px;
    height: 2px;
    background: ${props => props.theme.colors.bgColorActive};
    content: "";
  }
`;

interface ItemProps {
  isActive: boolean | null;
}

export const Item = styled.div`
  display: flex;
  position: relative;
  padding: 5.5px;
  border: 2px solid ${props => props.theme.colors.green};
  border-radius: 50%;
  margin-left: 200px;
  font-weight: 600;
  background: ${props => props.theme.colors.green};
  box-shadow: 0 0 0 8px ${props => props.theme.colors.white};

  &:first-child {
    margin-left: 0;
  }
`;

export const ActiveItem = styled(Item)`
  background: ${props => props.theme.colors.white};
  font-weight: 400;

  & ~ ${Item} {
    color: ${props => props.theme.colors.bgColorActive};
    background: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.bgColorActive};
    box-shadow: none;
  }
`;

export const ItemCaption = styled.div<ItemProps>`
  position: absolute;
  top: ${({ isActive }) => (isActive ? "18px" : "26px")};
  width: 160px;
  margin: 0 -80px;
  text-align: center;
  font-size: ${({ isActive }) => (isActive ? "22px" : "16px")};
`;
