import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { AcceptanceRoute } from "routes";
import { ShipmentStore } from "stores/ShipmentStore";
import { SignatureStore } from "stores/SignatureStore";
import { Loader } from "components/Loader";
import { AcceptusType, DeliveryModel, DeliveryType } from "typings/server";
import { ReverseShipmentPage } from "./ReverseShipment/ReverseShipmentPage";
import { ReverseShipmentPageVM } from "./ReverseShipment/ReverseShipmentPageVM";
import { DirectShipmentPage } from "./DirectShipment/DirectShipmentPage";
import { DirectShipmentPageVM } from "./DirectShipment/DirectShipmentPageVM";
import { UserStore } from "stores/UserStore";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";

export const DetailsPageWrapper = (props: RouteComponentProps<AcceptanceRoute>) => {
  const { match } = props;
  const id = match.params.deliveryId;
  const [loading, setLoading] = useState<boolean>(true);
  const [delivery, setDelivery] = useState<DeliveryModel | null>();

  useEffect(() => {
    if (id) {
      ShipmentStore.getItem(id, { force: true })
        .then(delivery => {
          ShipmentStore.setSelected(delivery);
          setDelivery(delivery);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) return <Loader active />;
  if (!delivery) return <>Поставка не найдена</>;

  if (delivery.acceptusType === AcceptusType.Reverse) {
    return (
      <DeliveryTypeContext.Provider value={delivery.type}>
        <ReverseShipmentPage
          vm={new ReverseShipmentPageVM(ShipmentStore, SignatureStore, UserStore, DeliveryType.Shipment)}
          {...props}
        />
      </DeliveryTypeContext.Provider>
    );
  }

  return (
    <DeliveryTypeContext.Provider value={delivery.type}>
      <DirectShipmentPage
        vm={new DirectShipmentPageVM(ShipmentStore, SignatureStore, UserStore, DeliveryType.Shipment)}
        {...props}
      />
    </DeliveryTypeContext.Provider>
  );
};
