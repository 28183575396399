import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MainPage } from "./MainPage/MainPage";
import { DetailsPageWrapper } from "./DetailsPage/DetailsPageWrapper";
import { CirculationRoute } from "routes";

export function CirculationIndexPage() {
  return (
    <Switch>
      <Redirect exact from={CirculationRoute.baseRoute} to={CirculationRoute.withdrawalBaseRoute} push />
      <Route path={CirculationRoute.typeRoute} exact component={MainPage} />
      <Route path={CirculationRoute.reentryRoute} exact component={DetailsPageWrapper} />
      <Route path={CirculationRoute.withdrawalRoute} exact component={DetailsPageWrapper} />
      <Route path={CirculationRoute.destructionRoute} exact component={DetailsPageWrapper} />
    </Switch>
  );
}
