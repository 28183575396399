import React from "react";
import { TransferStore } from "stores/TransferStore";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { SignatureStore } from "stores/SignatureStore";
import { TransferDeliveryModel } from "models/Delivery/TransferDeliveryModel";
import { FreeScanSidePageBody } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageBody";
import { FreeScanSidePageVM } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageVM";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface ISidePageProps {
  close(): void;
  save(): Promise<void>;
  delivery: TransferDeliveryModel;
  additionalInfo: CodesAdditionalInfo;
}

export const TransferSidePage: React.FC<ISidePageProps> = (props: ISidePageProps) => {
  const { delivery, additionalInfo, save } = props;
  const vm = new FreeScanSidePageVM(TransferStore, SignatureStore, AdvertisementStore, additionalInfo, delivery, save);
  return (
    <ScannerSidePageContainer
      scanText={"Отсканируйте коды маркировки для перемещения товаров"}
      headerText={"Сканирование кодов маркировки товаров"}
      vm={vm}
      {...props}
    >
      <FreeScanSidePageBody vm={vm} />
    </ScannerSidePageContainer>
  );
};
