import { IUserStore } from "stores/UserStore";
import { CreateDisposalWithRegistratorModel, DeliveryModel, RegistratorEmbeddedModel } from "typings/server";
import { action, computed, observable } from "mobx";
import { DeliveriesApi } from "api/DeliveryApi";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { IWithdrawalStore } from "stores/WithdrawalStore";
import { BaseEditorModalVM, TBaseErrors } from "../BaseEditorModalVM";

type TError = TBaseErrors | "docTypeRequired" | "registratorRequired" | "docNumberInvalid" | "OKUDCodeInvalid";

export class DisposalRegistratorEditorModalVM extends BaseEditorModalVM<TError> {
  @observable documentType?: string = "Требование-накладная";
  @observable OKUDCode?: string = "0504204";
  @observable registrator?: RegistratorEmbeddedModel;

  constructor(
    store: IWithdrawalStore,
    userStore: IUserStore,
    readonly registrators: RegistratorEmbeddedModel[],
    delivery?: WithdrawalDeliveryModel
  ) {
    super(store, userStore, delivery);
    this.initRegistrator(delivery);
  }

  @action
  setDocumentType = (type: string) => {
    this.documentType = type;
    if (this.isSubmitted) this.validate();
  };

  @action
  setOKUDCode = (code: string) => {
    this.OKUDCode = code;
    if (this.isSubmitted) this.validate();
  };

  @action
  setRegistrator = (registrator: RegistratorEmbeddedModel) => {
    this.registrator = registrator;
    if (this.isSubmitted) this.validate();
  };

  @action
  async save(): Promise<string | void> {
    this.isSubmitted = true;
    if (this.validate()) {
      const model = new DeliveryModel();
      const data: CreateDisposalWithRegistratorModel = {
        documentDate: this.documentDate as string,
        documentNumber: this.documentNumber,
        documentCode: this.OKUDCode || "",
        documentCodeName: this.documentType as string,
        registratorId: (this.registrator as RegistratorEmbeddedModel).id,
      };
      Object.assign(model, data);

      return this.isNew ? await this.createNewOperation(data) : await this.patchOperation(model);
    } else {
      return Promise.reject();
    }
  }

  async createNewOperation(model: CreateDisposalWithRegistratorModel): Promise<string> {
    return await DeliveriesApi.addNewDisposalWithRegistrar(model);
  }

  async patchOperation(model: DeliveryModel): Promise<void> {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    await this.store.saveDelivery(model, this.delivery!.id);
    this.delivery!.updateDelivery(model);
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (this.documentDate && new Date(this.documentDate) > new Date()) {
      this.errors.set("invalidDocDate", "Дата не должна быть больше текущей даты и времени");
    }

    if (!this.documentDate) {
      this.errors.set("docDateRequired", "Укажите дату документа-основания");
    }

    if (this.OKUDCode && this.OKUDCode.length > 16) {
      this.errors.set("OKUDCodeInvalid", "Длина кода не может превышать 16 символов");
    }

    if (!this.documentNumber) {
      this.errors.set("docNumberRequired", "Укажите номер документа-основания");
    }
    if (this.documentNumber && this.documentNumber.length > 16) {
      this.errors.set("docNumberInvalid", "Длина номера не может превышать 16 символов");
    }

    if (!this.documentType) {
      this.errors.set("docTypeRequired", "Укажите тип документа");
    }

    if (!this.registrator) {
      this.errors.set("registratorRequired", "Укажите регистратор выбытия");
    }
    return !this.errors.size;
  }

  @action
  setByDelivery(delivery: WithdrawalDeliveryModel) {
    if (delivery.documentDate) {
      this.documentDate = new Date(delivery.documentDate).toISOString();
    }
    this.documentNumber = delivery.documentNumber || "";
    this.documentType = delivery.documentCodeName;
    this.OKUDCode = delivery.documentCode;
  }

  @computed
  get isDisposalWithRegistrator() {
    return true;
  }

  private initRegistrator(delivery?: WithdrawalDeliveryModel) {
    if (delivery) {
      this.registrator = this.registrators.find(x => x.id === delivery.registratorId);
    } else if (this.registrators.length === 1) {
      this.registrator = this.registrators[0];
    }
  }
}
