import React from "react";
import { observer } from "mobx-react";
import { SettingsPageVM } from "../SettingsPageVM";
import { SynInfoBlock, SynInfoBlockWrap, SynInfoText } from "./DepartmetsPage.styles";
import { Button, Link, Spinner } from "components";
import RefreshIcon from "@skbkontur/react-icons/Refresh";
import { TextDarkRed } from "styles/shared.styles";
import { ICertificate } from "@skbkontur/plugin-js";
import { MdlpModal } from "../../CertificatesList/MdlpModal";
import { DepartmentsTable } from "./DepartmentsTable/DepartmetsTable";
import { departmentDirectoriesInstruction } from "StringResx";
import HelpDonIcon from "@skbkontur/react-icons/HelpDot";

interface DepartmentsPageProps {
  vm: SettingsPageVM;
}

export const DepartmentsPage: React.FC<DepartmentsPageProps> = observer((props: DepartmentsPageProps) => {
  const { vm } = props;

  const synchronize = async () => {
    await vm.synchronizeDepartments();
    await vm.getDepartments();
  };

  const toggleMdlpModal = () => vm.toggleModal("mdlpModal");

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
    await synchronize();
  };

  return (
    <div data-tid="DepartmentsPage">
      {vm.modals.get("mdlpModal") && (
        <MdlpModal
          onClose={toggleMdlpModal}
          sign={onSign}
          title={"Доступ в ИС МДЛП"}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}

      <SynInfoBlockWrap>
        <SynInfoBlock>
          {vm.loaders.get("sync") && <Spinner type="mini" caption="Синхронизация..." />}
          {!vm.errors.has("sync") && !vm.loaders.get("sync") && (
            <>
              {vm.departmentsSyncTime && <SynInfoText>Данные от {vm.departmentsSyncTime}</SynInfoText>}
              <Button data-tid="Synchronize" use="link" onClick={synchronize} size="small" icon={<RefreshIcon />}>
                Синхронизировать с ИС МДЛП
              </Button>
            </>
          )}
          {vm.errors.has("sync") && !vm.loaders.get("sync") && (
            <>
              <SynInfoText>
                <TextDarkRed>{vm.errors.get("sync")}</TextDarkRed>
              </SynInfoText>
              <Button data-tid="TryAgain" use="link" onClick={synchronize} size={"small"} icon={<RefreshIcon />}>
                Попробовать еще раз
              </Button>
            </>
          )}
        </SynInfoBlock>
        <Link href={departmentDirectoriesInstruction} icon={<HelpDonIcon />} target="_blank">
          Заполнение справочников подразделений
        </Link>
      </SynInfoBlockWrap>
      <DepartmentsTable vm={vm} />
    </div>
  );
});
