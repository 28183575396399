import { HttpClient } from "api/HttpClient/FetchHttpClient";
import { headersDefault, THeaders } from "api/HttpClient/shared";
import { UserStore } from "stores/UserStore";

export const headers = {
  "Cache-Control": "must-revalidate, private",
  Pragma: "no-cache",
};

export const headersFormData: THeaders = {
  ...headers,
  "Content-Type": "multipart/form-data",
};

export const AUTH_REQUIRED_STATUS = 401;
export const MDLP_AUTH_REQUIRED_STATUS = 412;
export const MDLP_ERROR_STATUS = 419;
export const MDLP_SERVER_ERROR = 500;
export const NOT_FOUND_STATUS = 404;
export const INTERNAL_SERVER_ERROR = 500;
export const BILLING_STATUS = 402;
export const DUPLICATE_STATUS = 409;

export function getHeaders() {
  const depId = (UserStore.user && UserStore.currentDepartment && UserStore.currentDepartment.id) || "";
  return {
    ...headersDefault,
    "X-ORGANIZATION-ID": UserStore.currentOrgId,
    "X-DEPARTMENT-ID": depId,
  };
}

export function getHttpClient(baseUrl: string) {
  return new HttpClient(baseUrl, getHeaders);
}
