const secretKeyReg = /[^\w|\d|\-|<|>]/;

export function checkSecretKey(key: string): boolean | string {
  const match = key.match(secretKeyReg);
  if (match && match[0]) {
    if (match[0] === " ") return '"пробел"';
    return match[0];
  }
  return false;
}
