import styled from "styles/styled-components";

export const ServerError = styled.div`
  display: flex;
  margin: 30px 0 0;
  height: 116px;
`;
export const ServerErrorText = styled.div`
  text-align: left;
  max-width: 350px;
  margin-left: 24px;
  margin-top: -5px;
  margin-right: 24px;

  p {
    margin-top: 7px;
    margin-bottom: 7px;
  }
`;

export const WarningBlock = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
`;

export const WarningDescription = styled.div`
  display: block;
  color: ${props => props.theme.colors.orangeDark};
`;

export const WarningText = styled.div`
  margin-left: 10px;
`;

export const WarningIconWrap = styled.div`
  position: relative;
  top: 7px;
`;
