import { computed } from "mobx";
import { AcceptusType, DeliveryStatus, StepInfoModel } from "typings/server";
import { DeliveryActions } from "Common/DeliveryActions";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";

export class StagesPartVM {
  constructor(
    public delivery: AcceptanceDeliveryModel,
    public additionalInfo: CodesAdditionalInfo,
    public isPartial?: boolean,
    public parentDelivery?: AcceptanceDeliveryModel
  ) {}
  @computed
  // @TODO вынести в отдельную функцию и покрыть тестом как в Shipment/DetailsPage/Common/StagesPartVM.ts
  get title() {
    if (this.isRejected) {
      if (this.delivery.acceptusType === AcceptusType.Direct) {
        return this.isPartialRejectRecall ? "Частичный отказ от товаров" : "Отказ от товаров";
      }
      return this.isPartialRejectRecall ? "Частичный отказ от переданных сведений" : "Отказ от переданных сведений";
    }
    if (this.isRecalled) {
      return this.isPartialRejectRecall ? "Частичный отзыв поставщиком" : "Отзыв поставщиком";
    }
    if (this.delivery.acceptusType === AcceptusType.StateDispatch) {
      return "Приемка в рамках государственного лекарственного обеспечения";
    }

    if (this.delivery.acceptusType === AcceptusType.SuspendedReturn) {
      return "Возврат приостановленного товара";
    }

    if (this.isDeliveryAgreed) {
      return this.isPartial ? "Частичное согласование поставщиком" : "Согласование поставщиком";
    }

    if (this.isFailedStatus && this.isPartial) {
      return "Частичная ошибка приемки товаров";
    }

    return this.isPartial ? "Частичная приемка товаров" : "Приемка товаров";
  }

  @computed
  get isPartialRejectRecall() {
    return this.parentDelivery
      ? this.delivery.allItemsCodes.length !==
          this.parentDelivery.allItemsCodes.length - this.parentDelivery.failedCodesLen
      : false;
  }

  @computed
  get isRejected() {
    return this.delivery.isRejected;
  }

  @computed
  get isRecalled() {
    return this.delivery.isRecalled;
  }

  @computed
  get isSend() {
    return this.delivery.stepsInfo[DeliveryStatus.Sending]?.completionDate;
  }

  @computed
  get isSigned() {
    // Если операция в стадии создания документа или подписания, значит
    // Она вернулась повторно, и старую подпись показывать не нужно
    if (
      this.delivery.status === DeliveryStatus.Signing ||
      this.delivery.status === DeliveryStatus.CreatingDocument ||
      (this.delivery.status === DeliveryStatus.Recalled && this.isPartial)
    ) {
      return;
    }
    return (
      this.delivery.stepsInfo[DeliveryStatus.Signing]?.completionDate ||
      this.parentDelivery?.stepsInfo[DeliveryStatus.Signing]?.completionDate
    );
  }
  @computed
  get isCompleted() {
    const { status } = this.delivery;
    return (
      status === DeliveryStatus.Success ||
      status === DeliveryStatus.Recalled ||
      status === DeliveryStatus.Rejected ||
      this.delivery.stepsInfo[DeliveryStatus.MdlpProcessing]?.completionDate
    );
  }

  @computed
  get showSpinner() {
    return Boolean(
      this.isSend &&
        !this.isCompleted &&
        this.delivery.status !== DeliveryStatus.Failed &&
        this.delivery.status !== DeliveryStatus.Signed
    );
  }

  @computed
  get sendingStageName(): string {
    if (this.isCompleted) {
      return "Отправлено в ИС МДЛП";
    } else if (this.isSend) {
      return "Ожидание ИС МДЛП";
    } else return "Отправка в ИС МДЛП";
  }

  @computed
  get isFailedStatus(): boolean {
    const { status } = this.delivery;
    const isRecallFailed = status === DeliveryStatus.RecallFailed && !!this.delivery.parentDeliveryId;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.PartiallyFailed || isRecallFailed;
  }

  @computed
  get isDeliveryAgreed(): boolean {
    // для обратного акцепта child delivery в состоянии WaitingForCounterparty означает согласовано поставщиком
    return this.delivery.status === DeliveryStatus.WaitingForCounterparty;
  }

  @computed
  get isShowCirculationWarning(): boolean {
    const { parentDeliveryId, stepsInfo, items } = this.delivery;
    const { isAnyCodeAppliedByNewRules, isFailedCodesAppliedByNewRules } = this.additionalInfo;

    const singnedStepDate = stepsInfo[DeliveryStatus.Signed]?.completionDate;
    const mdlpStepDate = stepsInfo[DeliveryStatus.MdlpProcessing]?.completionDate;

    const waitingTime = 15 * 60 * 1000;

    return (
      !parentDeliveryId &&
      (this.isFailedStatus || !mdlpStepDate) &&
      Date.now() > +new Date(singnedStepDate) + waitingTime &&
      (this.isFailedStatus ? isFailedCodesAppliedByNewRules(items) : isAnyCodeAppliedByNewRules())
    );
  }

  @computed
  get isPartiallyFailed(): boolean {
    const status = this.parentDelivery?.status;
    return status === DeliveryStatus.PartiallyFailedIncompleted || status === DeliveryStatus.PartiallyFailed;
  }

  @computed
  get showPartialStages(): boolean {
    return !this.isCompleted || this.isFailedStatus;
  }

  getCompletionDate(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionDate(stepInfo);
  }

  getCompletionUser(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionUser(stepInfo);
  }
}
