import { action, computed } from "mobx";
import { CodeModelExtended } from "../../Code/CodeModelExtended";
import { BaseDeliveryItemModel } from "./BaseDeliveryItemModel";
import { isSgtin, isSscc } from "helpers/codes";
import { DeliveryItemModel, DeliveryItemStatus } from "typings/server";

export class UtdPositionModelExtended extends BaseDeliveryItemModel {
  public quantity: number;
  public sum: number;
  public id: string;

  constructor(item: Partial<DeliveryItemModel>, codes?: CodeModelExtended[]) {
    super();
    Object.assign(this, item);
    this.name = item.name || item.code || "Без названия";
    this.cost = item.cost || 0;
    this.vat = item.vat || 0;
    this.sum = this.sum || 0;
    if (codes) this.setCodes(codes);
  }

  @computed
  get code(): string {
    return this.sgtinCodesFlat[0] || this.ssccCodesFlat[0];
  }

  @computed
  get allCodesLen(): number {
    return this.ssccCodes.length + this.sgtinCodes.length;
  }

  @computed
  get isAllScanned(): boolean {
    const sgtinScanLen = this.getScannedLength(this.sgtinCodes);
    const ssccScanLen = this.getScannedLength(this.ssccCodes);
    if (sgtinScanLen >= this.quantity && sgtinScanLen !== 0) {
      return true;
    }
    return ssccScanLen > 0;
  }

  @computed
  get positionStatus(): DeliveryItemStatus | undefined {
    const firstCodeStatus = this.allCodes && this.allCodes[0] && this.allCodes[0].status;
    if (firstCodeStatus && this.allCodes.every(c => c.status === firstCodeStatus)) {
      return firstCodeStatus;
    }
    return undefined;
  }

  @action
  tryToAddCode(code: string): CodeModelExtended {
    const exist = this.allCodes.find(codeModel => codeModel.code === code);
    if (exist) return exist;

    const index = this.ssccCodes.length + this.sgtinCodes.length + 1;
    const codeItem = new CodeModelExtended({
      code: code,
      scanned: true,
      index,
    });

    if (isSscc(code)) {
      this.ssccCodes.unshift(codeItem);
    } else if (isSgtin(code)) {
      this.sgtinCodes.unshift(codeItem);
    }

    return codeItem;
  }
}
