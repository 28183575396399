import React from "react";
import { observer } from "mobx-react";
import { getEnding } from "helpers/endings";
import { TextGrey } from "styles/shared.styles";
import { ScannedGreenText, ScannedStatusText } from "./ScannedItemsCount.styles";
import { DeliveryItemStatus } from "typings/server";

interface IScanItemsCount {
  allLen: number;
  scannedLen: number;
  codeText?: boolean; // добавляет "код", "кода", "кодов"
  status?: DeliveryItemStatus;
  dataTid?: string;
}

export const ScannedItemsCount = observer(({ allLen, scannedLen, codeText, status, dataTid }: IScanItemsCount) => {
  if (!allLen) return <>—</>;

  if (status && status !== DeliveryItemStatus.Unprocessed) {
    return (
      <ScannedStatusText status={status}>
        <span data-tid={dataTid}>{allLen}</span> {codeText && getEnding(allLen, "код", "кода", "кодов")}
      </ScannedStatusText>
    );
  }

  if (!scannedLen)
    return (
      <TextGrey>
        <span data-tid={dataTid}>{allLen}</span> {codeText && getEnding(allLen, "код", "кода", "кодов")}
      </TextGrey>
    );
  if (allLen === scannedLen) {
    return (
      <ScannedGreenText>
        <span data-tid={dataTid}>{scannedLen}</span> {codeText && getEnding(scannedLen, "код", "кода", "кодов")}
      </ScannedGreenText>
    );
  }

  return (
    <>
      <ScannedGreenText data-tid={dataTid}>{scannedLen}</ScannedGreenText>&nbsp;
      <TextGrey>
        из {allLen} {codeText && "кодов"}
      </TextGrey>
    </>
  );
});
