import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { GoodsTableItem } from "./GoodsTableItem";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { AdditionalInfoVM } from "../../AdditionalInfo/AdditionalInfoVM";
import { CollapseAllIcon, ExpandAllIcon } from "icons";
import { ExpandAllIconWrap, TableHeadCellWrap } from "./GoodsTable.styles";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { DeliveryType } from "typings/server";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";

interface IGoodsTableProps {
  goods: DeliveryItemModelExtended[];
  showOnlyAccepted?: boolean;
  hidePrices?: boolean;
  additionalInfo: CodesAdditionalInfo;
}

export const GoodsTable: React.FC<IGoodsTableProps> = observer((props: IGoodsTableProps) => {
  const { goods, showOnlyAccepted, hidePrices, additionalInfo } = props;
  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;
  const vm = React.useMemo(() => new AdditionalInfoVM(goods, additionalInfo, false, deliveryType, extraFields), [
    goods,
  ]);
  const cols = `3.5fr 1fr 1fr 0.8fr ${hidePrices ? "" : "1.4fr 1.25fr"}`;

  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  return (
    <div data-tid="GoodsTable">
      <Grid rows={"repeat(18px 15px)"} paddingCells={"14px 5px"} cols={cols} headerBorderBottom>
        <GridCellHeader col={1} row={1}>
          <TableHeadCellWrap data-tid="ToggleAllCodes" hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
            <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
            Наименование
          </TableHeadCellWrap>
        </GridCellHeader>
        <GridCellHeader col={2} row={1} textAlign="left" padding={"8px 10px"}>
          Агрегат
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign="left" padding={"8px 10px"}>
          Штучный товар
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign="left" padding={"8px 5px"}>
          Кол-во, шт
        </GridCellHeader>
        {!hidePrices && (
          <>
            <GridCellHeader col={5} row={1} textAlign="right" padding={"8px 5px"}>
              Цена (с НДС) за ед. товара,&nbsp;₽
            </GridCellHeader>
            <GridCellHeader col={6} row={1} textAlign="right" padding={"8px 5px"}>
              НДС за ед. товара,&nbsp;₽
            </GridCellHeader>
          </>
        )}
      </Grid>

      {goods.map(good => (
        <GoodsTableItem
          good={good}
          cols={cols}
          key={good.code}
          hidePrices={hidePrices}
          showOnlyAccepted={showOnlyAccepted}
          vm={vm}
          allCodesExpanded={allCodesExpanded}
        />
      ))}
    </div>
  );
});
