import React from "react";
import { DisplayedErrorCodes } from "@skbkontur/certificates-list";
import { Link } from "components";
import { CertWithHand } from "icons";
import { DiagnosticUrl, PluginInstallUrl, SupportPhone } from "StringResx";
import { ErrorNoCertBody, ErrorTextWrapper } from "./CertificateList.styles";

const NoValidCert = () => (
  <ErrorNoCertBody>
    <CertWithHand />
    <ErrorTextWrapper>
      Действующих сертификатов КЭП не обнаружено:
      <p>—&nbsp;&nbsp;проверьте, что сертификат КЭП вставлен в компьютер,</p>
      <p>—&nbsp;&nbsp;срок действия сертификата не истек,</p>
      <p>—&nbsp;&nbsp;сертификат выпущен на организацию</p>
    </ErrorTextWrapper>
  </ErrorNoCertBody>
);

const NoPlugin = () => (
  <div>
    <p>
      Установите на компьютер&nbsp;
      <Link href={PluginInstallUrl} target={"_blank"}>
        плагин
      </Link>
      &nbsp;КриптоПРО CSP для работы с сертификатами, затем пройдите&nbsp;
      <Link href={DiagnosticUrl} target={"_blank"}>
        диагностику.
      </Link>
    </p>
    <p>
      Если ошибка повторяется, обратитесь в техподдержку <Link href={`tel:${SupportPhone}`}>{SupportPhone}</Link>
    </p>
  </div>
);

const NoCryptoPro = () => (
  <div>
    <p>
      Установите на компьютер КриптоПРО CSP для работы с сертификатами, затем пройдите&nbsp;
      <Link href={DiagnosticUrl} target={"_blank"}>
        диагностику.
      </Link>
    </p>
    <p>
      Если ошибка повторяется, обратитесь в техподдержку <Link href={`tel:${SupportPhone}`}>{SupportPhone}</Link>
    </p>
  </div>
);

export const CustomCertErrors = {
  [DisplayedErrorCodes.NoPlugin]: NoPlugin,
  [DisplayedErrorCodes.NoCryptoPro]: NoCryptoPro,
  [DisplayedErrorCodes.NoValidCertificates]: NoValidCert,
  [DisplayedErrorCodes.NoCertificates]: NoValidCert,
  [DisplayedErrorCodes.NoCertificatesByFilter]: NoValidCert,
};
