import { computed } from "mobx";
import { DeliveryItemStatus, DeliveryStatus } from "typings/server";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";

export class StagesPartVM {
  constructor(public delivery: WithdrawalDeliveryModel) {}

  @computed
  get title() {
    if (this.isPartialSended) {
      return "Частичная отправка в ИС МДЛП";
    }
    if (this.isPartialProcessedWithError) {
      return "Частичная ошибка отправки на регистратор выбытия";
    }
    if (this.isPartialMdlpError) {
      return "Частичная ошибка отправки в ИС МДЛП";
    }
    if (this.isPartialProcessed) {
      return "Частичная отправка на регистратор выбытия";
    }
    return "Отправка на регистратор выбытия";
  }

  @computed
  get isPartialProcessed() {
    const { childDeliveries, parentDeliveryId } = this.delivery;
    return childDeliveries.some(x => x.stepsInfo[DeliveryStatus.Processing]?.errorInfo) && !parentDeliveryId;
  }

  @computed
  get isPartialProcessedWithError() {
    const { stepsInfo } = this.delivery;
    const processingStep = stepsInfo[DeliveryStatus.Processing];
    return processingStep?.errorInfo;
  }

  @computed
  get isPartialSended() {
    const { stepsInfo, parentDeliveryId } = this.delivery;
    const mdlpProcessignStep = stepsInfo[DeliveryStatus.MdlpProcessing];
    return mdlpProcessignStep?.completionDate && !mdlpProcessignStep.errorInfo && parentDeliveryId;
  }

  @computed
  get isPartialMdlpError() {
    const { stepsInfo, parentDeliveryId } = this.delivery;
    const mdlpProcessignStep = stepsInfo[DeliveryStatus.MdlpProcessing];
    return this.isErrorStatus && mdlpProcessignStep?.errorInfo && parentDeliveryId;
  }

  @computed
  get isErrorStatus() {
    const { status } = this.delivery;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.PartiallyFailed;
  }

  @computed
  get deliveryItems() {
    const { items } = this.delivery;
    return this.isPartialProcessed ? items.filter(x => x.status !== DeliveryItemStatus.Error) : items;
  }

  @computed
  get isCollapsible() {
    return this.delivery.parentDeliveryId || this.isPartialProcessed;
  }
}
