import { action, observable } from "mobx";
import {
  DeliveryIndexModel,
  DeliveryItemModel,
  DeliveryItemReportModel,
  DeliveryModel,
  DeliveryStatus,
  DeliveryType,
  PatchDeliveryModel,
} from "typings/server";
import { ILoadOptions } from "./shared";
import { DeliveriesApi } from "api/DeliveryApi";
import { PackingDeliveryModel } from "models/Delivery/PackingDeliveryModel";
import { DeliveryStoreActions } from "./DeliveryStoreActions";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { ReportsApi } from "api/ReportsApi";
import { DeliveriesStore, IDeliveryStore, ISyncPollingOptions } from "./DeliveriesStore";
import { FilterModel } from "models/Filter/FilterModel";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";

class PackingStoreInternal {
  @observable deliveries: DeliveryIndexModel[] = [];
  @observable public selectedDelivery: PackingDeliveryModel;
  @observable totalSize: number;
  type = DeliveryType.Unpacking;
  readonly filters: FilterModel;
  private readonly actions: DeliveryStoreActions<PackingDeliveryModel>;
  private readonly deliveriesStore: IDeliveryStore = DeliveriesStore;

  constructor() {
    this.actions = new DeliveryStoreActions();
    this.filters = new FilterModel([this.type]);
  }

  @action
  updateDeliveries = async () => {
    const filters = this.filters.getFullFiltersInfo();
    const result = await DeliveriesApi.getAll(filters);
    if (result) {
      this.totalSize = result.totalSize;
      this.setDeliveries(result.deliveries);
      this.filters.setPagesCount(result.totalSize);
      this.filters.setAllowedStatuses(result.presenceInfo.statuses, this.type);
    }
  };

  @action
  setActivePage = async (page: number) => {
    this.filters.setActivePage(page);
    await this.updateDeliveries();
  };

  @action
  setDeliveries(deliveries: DeliveryIndexModel[]) {
    this.deliveries = deliveries;
  }

  @action
  async getItem(id: string, options?: Partial<ILoadOptions>): Promise<DeliveryModel> {
    return this.actions.getItem(this.selectedDelivery, id, options);
  }

  @action
  setSelected(delivery: DeliveryModel) {
    this.selectedDelivery = new PackingDeliveryModel(delivery);
  }

  @action
  async startPollingDeliveries(page: number) {
    await this.setActivePage(page);
    this.restartPollingDeliveries({ fireImmediately: true });
  }

  @action
  async restartPollingDeliveries(options?: ISyncPollingOptions) {
    await this.deliveriesStore.restartSyncInfoPolling(this.updateDeliveries, options);
  }

  @action stopPollingDeliveries() {
    this.deliveriesStore.stopSyncInfoPolling();
  }

  @action changeType(type: DeliveryType) {
    this.type = type;
    this.filters.setDefaultAllowedTypes([type]);
  }

  async createDelivery(type: DeliveryType) {
    const id = await DeliveriesApi.addNewOperation(type);
    return new PackingDeliveryModel({
      ...new DeliveryModel(),
      id,
      scannedCodes: [],
      items: [],
      type: type,
      stepsInfo: Object.create({}),
    });
  }

  async saveDelivery(delivery: Partial<PatchDeliveryModel>, id: string) {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    return this.actions.saveDelivery(delivery, id);
  }

  async saveItems(items: DeliveryItemModelExtended[], id: string) {
    // todo: move to actions
    const reportItems: DeliveryItemReportModel[] = [];
    items.map(item =>
      item.allCodes.map(codeModel =>
        reportItems.push({
          code: codeModel.code,
          name: item.realName,
        })
      )
    );
    return await ReportsApi.saveItems(reportItems, id);
  }

  @action
  async deleteDelivery(deliveryId: string) {
    await this.actions.deleteDelivery(deliveryId);
    await this.updateDeliveries();
  }

  async prepareDocuments(deliveryId: string) {
    return this.actions.prepareDocuments(deliveryId);
  }

  async checkDocumentsStatus(taskId: string) {
    return this.actions.checkDocumentsStatus(taskId);
  }

  async rollbackDeliveryStatus(deliveryId: string) {
    return this.actions.rollbackDeliveryStatus(deliveryId);
  }

  async getScannedCodes(deliveryId: string): Promise<string[]> {
    return this.actions.getDeliveryProperty(deliveryId, "scannedCodes");
  }

  async saveCodes(options: Partial<CodesReportModelExtended>) {
    return this.actions.saveCodes(options);
  }

  async getItems(deliveryId: string): Promise<DeliveryItemModel[]> {
    return this.actions.getDeliveryProperty(deliveryId, "items");
  }

  isCanBeDeleted(status?: DeliveryStatus) {
    return status === DeliveryStatus.Draft || status === DeliveryStatus.Processing;
  }
}

export interface IPackingStore extends PackingStoreInternal {}

export const PackingStore = new PackingStoreInternal();
