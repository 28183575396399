import { DeliveryStatus } from "typings/server";

export const acceptanceStatuses = [
  DeliveryStatus.New,
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.WaitingForCounterparty,
  DeliveryStatus.PartiallyAccepted,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.PartiallyFailedIncompleted,
  DeliveryStatus.Failed,
  DeliveryStatus.Recalled,
  DeliveryStatus.PartiallyRecalled,
  DeliveryStatus.Rejected,
  DeliveryStatus.PartiallyRejected,
];

export const shipmentStatuses = [
  DeliveryStatus.New,
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.WaitingForCounterparty,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.PartiallyFailedIncompleted,
  DeliveryStatus.RecallFailed,
  DeliveryStatus.PartiallyRecalled,
  DeliveryStatus.Failed,
  DeliveryStatus.Recalled,
  DeliveryStatus.Rejected,
  DeliveryStatus.PartiallyRejected,
];

export const transferStatuses = [
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.RecallFailed,
  DeliveryStatus.Failed,
  DeliveryStatus.Recalled,
];

// расформирование и изъятие с одними статусами
export const packingStatuses = [
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.Failed,
];

export const withdrawalStatuses = [
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.Failed,
];

export const reentryStatuses = [
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.Failed,
];

export const destructionStatuses = [
  DeliveryStatus.Processing,
  DeliveryStatus.Signing,
  DeliveryStatus.Sending,
  DeliveryStatus.CreatingDocument,
  DeliveryStatus.MdlpProcessing,
  DeliveryStatus.WaitingForCounterparty,
  DeliveryStatus.Success,
  DeliveryStatus.PartiallyFailed,
  DeliveryStatus.Failed,
];
