import React, { useContext } from "react";
import { observer } from "mobx-react";
import { ServerErrors } from "../Errors/ServerErrors";
import { Loader } from "components/Loader";
import { AdditionalInfo } from "../AdditionalInfo/AdditionalInfo";
import { InputAdditionalInfoMaxWidth } from "../AdditionalInfo/AdditionalInfo.styles";
import { AdditionalInfoWrap, ImgBlock, InputHeaderBlock, minLoaderH, SgtinImg, SsccImg } from "./ScannerInput.styles";
import { IScannerInputProps } from "./ScannerInput";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { DeliveryType } from "typings/server";

interface IInputHeaderBlockProps extends IScannerInputProps {}

export const InputHeader = observer((props: IInputHeaderBlockProps) => {
  const { vm, hideSgtinImg } = props;

  const tryToAuth = () => vm.toggleModal!("mdlpModal");

  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;

  return (
    <InputHeaderBlock>
      {vm.serverErrors && vm.serverErrors.size ? (
        <ServerErrors deliveryType={deliveryType} tryToAuth={tryToAuth} errors={vm.serverErrors} />
      ) : (
        <Loader
          active={!!vm.loadingState.get("additionalInfo")}
          showChildren
          minHeight={minLoaderH}
          caption={"Загрузка дополнительной информации"}
        >
          {vm.codeAdditionalInfo && !vm.errors.size ? (
            <AdditionalInfoWrap>
              <AdditionalInfo
                maxWidth={InputAdditionalInfoMaxWidth}
                info={vm.codeAdditionalInfo}
                discardCode={vm.discardCode}
                isInputMode
              />
            </AdditionalInfoWrap>
          ) : (
            <ImgBlock>
              <SsccImg
                alt={"Образец кода sscc"}
                src={`${process.env.PUBLIC_URL}/img/scanner/sscc.png`}
                srcSet={`${process.env.PUBLIC_URL}/img/scanner/sscc2x.png`}
              />
              {!hideSgtinImg && (
                <SgtinImg
                  alt={"Образец кода sgtin"}
                  src={`${process.env.PUBLIC_URL}/img/scanner/sgtin.png`}
                  srcSet={`${process.env.PUBLIC_URL}/img/scanner/sgtin2x.png`}
                />
              )}
            </ImgBlock>
          )}
        </Loader>
      )}
    </InputHeaderBlock>
  );
});
