import { action, observable } from "mobx";
import { isSgtin, isSscc, Logger } from "helpers";
import { CodeErrorModel, DeliveryItemStatus, SgtinInfoModel, SsccInfoModel } from "typings/server";
import { CodesApi } from "api/CodesApi";
import { DeliveryItemModelExtended } from "../Delivery/ItemModel/DeliveryItemModelExtended";

export class CodesAdditionalInfo {
  private readonly firstLoadPromise: Promise<void>;
  private abortController: AbortController;
  @observable codesInfo: Map<string, CodeAdditionalInfoModel> = new Map();

  constructor(private readonly deliveryId: string, codes: string[], firstLoad: boolean = true) {
    if (firstLoad) this.firstLoadPromise = this.loadDeliveryCodesInfo(codes);
  }

  @action
  private loadDeliveryCodesInfo(codes: string[] = [], silent: boolean = true): Promise<void> {
    this.abortController = new AbortController();
    return CodesApi.getCodesInfo({ deliveryId: this.deliveryId, codes }, this.abortController.signal)
      .then(info => {
        if (info) {
          this.setCodes(info);
        } else {
          return Promise.reject();
        }
      })
      .catch(e => {
        Logger.error({ error: e })
        
        if (silent) {          
          return Promise.reject();
        } else return Promise.reject(e);
      });
  }

  abort() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  @action
  async getCodeInfo(code: string): Promise<CodeAdditionalInfoModel> {
    // дожидаемся первоначальной загрузки всей инфы по кодам
    try {
      await this.firstLoadPromise;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    if (this.codesInfo.has(code)) {
      return this.codesInfo.get(code)!;
    } else {
      await this.loadDeliveryCodesInfo([code], false);
      return this.codesInfo.get(code)!;
    }
  }

  @action
  private setCodes(codes: Array<SsccInfoModel | SgtinInfoModel | CodeErrorModel>) {
    // по коду может прийти 2 модели: с уже существующей инфой из базы и с ошибкой,
    // показываем доступную инфу, не показываем ошибку
    const codesUniq = codes.filter(codeModel => {
      const anotherCodeInfo = codes.find(c => c.code === codeModel.code && c !== codeModel);
      return !(anotherCodeInfo && (codeModel as CodeErrorModel).errorCode !== undefined);
    });

    codesUniq.forEach(codeInfo => {
      this.codesInfo.set(codeInfo.code, new CodeAdditionalInfoModel(codeInfo));
    });
  }

  checkCodesHaveAdditionalInfo(codes: string[]): boolean {
    return codes.some(code => this.codesInfo.get(code));
  }

  isAnyCodeAppliedByNewRules = (): boolean => {
    for (let [_key, val] of this.codesInfo) {
      if (val.isEnteredCirculationAfterNewRules) {
        return true;
      }
    }
    return false;
  };

  isFailedCodesAppliedByNewRules = (items: DeliveryItemModelExtended[]): boolean => {
    const failedItemsCodesFlat = items
      .filter(x => x.status === DeliveryItemStatus.Error)
      .map(x => x.allCodesFlat)
      .flat();
    for (let [_key, value] of this.codesInfo) {
      for (let item of failedItemsCodesFlat) {
        if (item === value.code && value.isEnteredCirculationAfterNewRules) {
          return true;
        }
      }
    }
    return false;
  };
}

export class CodeAdditionalInfoModel {
  code: string;
  errorCode?: number;
  description?: string;

  // SsccInfoModel
  name?: string;
  containedSsccCount?: number;
  containedSgtinsCount?: number;
  gtin?: string;
  producer?: string;
  childCodesInfo?: Partial<SgtinInfoModel | SsccInfoModel>[];

  // SgtinInfoModel
  batch?: string;
  releaseDate?: string;
  expirationDate?: string;
  circulationEntryDate?: string;
  containingSscc?: string;
  containingElementaryCount?: number;

  constructor(info: SsccInfoModel | SgtinInfoModel | CodeErrorModel) {
    Object.assign(this, info);
  }

  get isError(): boolean {
    return this.errorCode != null && this.description !== undefined;
  }

  get isSscc(): boolean {
    return isSscc(this.code);
  }

  get isGtin(): boolean {
    return isSgtin(this.code);
  }

  get isEnteredCirculationAfterNewRules(): boolean {
    if (!this.circulationEntryDate) {
      return false;
    }
    let newRulesDate = new Date("2021-02-01T00:00:00.000Z");
    return +new Date(this.circulationEntryDate) > +newRulesDate;
  }
}
