import React from "react";
import { RouteComponentProps } from "react-router";
import { ShipmentRoute } from "routes";
import { Route, Switch } from "react-router-dom";
import { MainPage } from "./MainPage/MainPage";
import { DetailsPageWrapper } from "./DetailsPage/DetailsPageWrapper";

interface ShipmentPageProps extends RouteComponentProps<ShipmentRoute> {}

export function ShipmentIndexPage(_props: ShipmentPageProps) {
  return (
    <Switch>
      <Route path={ShipmentRoute.baseRoute} exact component={MainPage} />
      <Route path={ShipmentRoute.route} exact component={DetailsPageWrapper} />
    </Switch>
  );
}
