import styled from "styles/styled-components";

export const DeliveryDropdownWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-left: 1px;
`;

export const DeliveryH3 = styled.h3`
  margin-top: 20px;
  margin-left: 1px;
  font-size: 20px;
  font-weight: 500;
`;

export const DeliveryTopBlock = styled.div`
  padding-left: 16px;
`;

export const DeliveryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UpdateBlock = styled.div`
  padding-top: 5px;
  line-height: 19px;
  text-align: right;
  min-height: 43px;
`;

export const DeliveryTable = styled.div`
  padding-top: 14px;
  margin-left: 8px;
`;

export const MenuHeaderText = styled.div`
  color: ${props => props.theme.textColors.lightGray2};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 10px 6px;
`;

export const InputWrap = styled.span`
  margin-left: 17px;
`;
