import React from "react";
import { observer } from "mobx-react";
import { Button, Input, Modal, Toggle, Tooltip } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { RecallModalVM } from "./RecallModalVM";
import { CreateRecallModel } from "typings/server";
import { LabelRequired, ModalWrap, TextAreaWrap, ToggleWrap } from "./RecallModal.styles";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

interface IRecallModalInternalProps extends IRecallModalProps {
  vm: RecallModalVM;
}

const RecallModalInternal: React.FC<IRecallModalInternalProps> = observer((props: IRecallModalInternalProps) => {
  const { vm } = props;

  const onClose = () => props.onClose();

  const onConfirm = async () => {
    await vm.onConfirm();
    onClose();
  };

  const setDate = (d?: string) => {
    vm.setOperationDate(d);
  };

  const changeConfirmPaused = (_e: any) => {
    vm.changeConfirmPaused();
  };

  const getOperationDateError = () => vm.errors.get("operationDateRequired") || vm.errors.get("invalidOperationDate");

  const getReasonError = () => vm.errors.get("reasonRequired");

  return (
    <Modal data-tid="RecallModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>Отзыв отгрузки</Modal.Header>
      <Modal.Body>
        <ModalWrap>
          <Grid cols={"130px 1fr"}>
            <GridCell col={1} row={1}>
              <LabelRequired>Дата отзыва</LabelRequired>
            </GridCell>
            <GridCell col={2} row={1}>
              <DateTimePicker
                data-tid="OperationDate"
                value={vm.operationDate}
                maxDate={vm.maxDate}
                onChange={setDate}
                getReportDateError={getOperationDateError}
              />
            </GridCell>

            <GridCell col={1} row={2}>
              <LabelRequired>Причина отзыва</LabelRequired>
            </GridCell>
            <GridCell col={2} row={2}>
              <Tooltip render={getReasonError} trigger={"hover"}>
                <TextAreaWrap>
                  <Input
                    data-tid="RecallReason"
                    value={vm.reason}
                    onValueChange={vm.setReason}
                    width={"500px"}
                    error={!!getReasonError()}
                  />
                </TextAreaWrap>
              </Tooltip>
            </GridCell>

            <GridCell col={2} row={3}>
              <ToggleWrap>
                <Toggle data-tid="ConfirmPaused" checked={vm.confirmPaused} onValueChange={changeConfirmPaused}>
                  Отзыв приостановленного товара
                </Toggle>
              </ToggleWrap>
            </GridCell>
          </Grid>
        </ModalWrap>
      </Modal.Body>
      <Modal.Footer panel sticky>
        <FooterBtnWrapper>
          <Button data-tid="Submit" use="primary" size={"large"} onClick={onConfirm}>
            Подписать и отозвать отгрузку
          </Button>
          <Button data-tid="Cancel" onClick={onClose} size={"large"}>
            Отмена
          </Button>
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  );
});

interface IRecallModalProps {
  onClose(): void;
  onConfirm(model: CreateRecallModel): Promise<void>;
  deliveryId: string;
}

// React.memo + () => true - не обновляет компонент
export const RecallModal = React.memo(
  (props: IRecallModalProps) => {
    const vm = new RecallModalVM(props.deliveryId, props.onConfirm);
    return <RecallModalInternal {...props} vm={vm} />;
  },
  () => true
);
