import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { GoToBlockWrap, GoToIcon, GoToName } from "./GoTo.styles";
import { ArrowChevronLeft } from "@skbkontur/react-icons";

interface IGoToBlock extends RouteComponentProps {
  href: string;
  name: string;
}

function GoToBlockInternal({ href, name, history }: IGoToBlock) {
  const onClick = () => history.push(href);
  return (
    <GoToBlockWrap data-tid="GoBack" onClick={onClick}>
      <GoToIcon>
        <ArrowChevronLeft />
      </GoToIcon>
      <GoToName>{name}</GoToName>
    </GoToBlockWrap>
  );
}

export const GoToBlock = withRouter(GoToBlockInternal);
