import styled from "styles/styled-components";

export const UnpackModalText = styled.div`
  font-size: 16px;
  line-height: 25px;
  max-width: 650px;
`;

export const ErrorModalImg = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/error-code.png);
  background-repeat: no-repeat;
  height: 88px;
  width: 88px;
  display: inline-block;
`;

export const ErrorModalImgWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
