import React, { PropsWithChildren } from "react";
import { Link } from "components";
import { AcceptanceRoute } from "routes";
import { CertInstructionsUrl, DiagnosticUrl, ZnakSupportPhone } from "StringResx";
import { CertErrImg, CertErrWrap, DocErrorText, ErrorList, ErrorText } from "./CertificateList.styles";
import { HelpDot, Mail, Settings } from "@skbkontur/react-icons";

export const ErrorSignBlock = (props: PropsWithChildren<{ children: string }>) => (
  <CertErrWrap>
    <CertErrImg src={`${process.env.PUBLIC_URL}/img/error-cert.png`} alt={"ошибка"} marginTop={"0px"} />
    <DocErrorText>
      При подписании документа произошла ошибка.
      <p>{props.children}</p>
      {ErrorListBlock}
    </DocErrorText>
  </CertErrWrap>
);

export const DefaultErrorSignBlock = (props: PropsWithChildren<{ children: string }>) => (
  <CertErrWrap>
    <CertErrImg src={`${process.env.PUBLIC_URL}/img/error-cert.png`} alt={"ошибка"} />
    <ErrorText>
      <p>{props.children || "Произошла ошибка"}</p>
      {ErrorListBlock}
    </ErrorText>
  </CertErrWrap>
);

const ErrorListBlock = (
  <ErrorList>
    <li>
      <Link href={CertInstructionsUrl} target={"_blank"}>
        <HelpDot /> Инструкция по работе с сертификатами
      </Link>
    </li>
    <li>
      <Link href={DiagnosticUrl} target={"_blank"}>
        <Settings /> Диагностика
      </Link>
    </li>
    <li>
      <Link href={AcceptanceRoute.supportRoute} target={"_blank"}>
        <Mail /> Центр поддержки
      </Link>
    </li>
  </ErrorList>
);

export const notFoundCertError = (
  <CertErrWrap>
    <CertErrImg src={`${process.env.PUBLIC_URL}/img/error-cert.png`} alt={"ошибка"} />
    <div>
      <p>
        Ошибка авторизации в ИС МДЛП. Возможно сертификат организации не зарегистрирован в национальной системе цифровой
        маркировки «Честный знак».
      </p>
      <p>
        Зарегистрируйте сертификат организации, либо обратитесь в информационный центр «Честный знак» по номеру
        <br />
        <Link href={`tel:${ZnakSupportPhone}`}>{ZnakSupportPhone}</Link>.
      </p>
    </div>
  </CertErrWrap>
);
