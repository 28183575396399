import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock, ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { AcceptusType, DeliveryStatus } from "typings/server";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { CreatingDocWrap, DateAndFIOWrap, StageGeyText } from "Common/Stages/Stages.styles";
import { DirectShipmentPageVM } from "../DirectShipment/../DirectShipmentPageVM";
import { DirectShipmentStagesPart } from "./DirectShipmentStagesPart";

interface IShipmentStagesProps {
  vm: DirectShipmentPageVM;
}

export const DirectShipmentStagesBlock: React.FC<IShipmentStagesProps> = observer(({ vm }: IShipmentStagesProps) => {
  useEffect(() => {
    // По дефолту разворачиваем первую стадию (но не для всех акцептов)
    if (
      vm.delivery.status === DeliveryStatus.Success &&
      (vm.delivery.acceptusType === AcceptusType.SuspendedReturn ||
        vm.delivery.acceptusType === AcceptusType.StateDispatch)
    ) {
      return;
    }
    vm.onCollapseDelivery(`ProcessingStage_${vm.delivery.id}`, !vm.isPartiallyFailed && !vm.isResended);
  }, []);

  return (
    <DeliveryStagesBlock>
      {/* Для 627 сообщения и переотправки не показываем этот блок */}
      {!vm.is627message && !vm.isResended && (
        <DirectShipmentStagesPart
          data-tid="ShippedStage"
          vm={vm}
          delivery={vm.delivery}
          // Для процессинга мы показываем неразворачиваемый черновик
          hideChildren={vm.delivery.status === DeliveryStatus.Processing}
          overrideFields={{
            name: "Создание черновика",
            size: "medium",
            status: vm.stages[DeliveryStage.Shipped].progress,
            error: vm.stages[DeliveryStage.Shipped].errorInfo,
            collapsed:
              vm.delivery.status !== DeliveryStatus.Processing &&
              !vm.isDeliveryOpenedState.get(`ShippedStage_${vm.delivery.id}`),
            onCollapse:
              vm.delivery.status !== DeliveryStatus.Processing
                ? () => vm.onCollapseDelivery(`ShippedStage_${vm.delivery.id}`)
                : undefined,
            alwaysVisibleComp: (
              <DateAndFIOWrap data-tid="CompleteInfo">
                {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Draft])}
              </DateAndFIOWrap>
            ),
            isRecalled: false,
            isRejected: false,
          }}
        />
      )}
      {vm.is627message && (
        <DirectShipmentStagesPart
          data-tid="ProcessingStage"
          delivery={vm.delivery}
          vm={vm}
          overrideFields={{
            name: vm.processingStepName,
            isRejected: false,
            isRecalled: false,
            collapsed: !vm.isDeliveryOpenedState.get(`ProcessingStage_${vm.delivery.id}`),
            onCollapse: () => vm.onCollapseDelivery(`ProcessingStage_${vm.delivery.id}`),
            alwaysVisibleComp: (
              <DateAndFIOWrap data-tid="CompleteInfo">
                {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Draft])}
              </DateAndFIOWrap>
            ),
          }}
        />
      )}
      {!vm.is627message && !vm.isPartiallyFailed && !vm.isAllChildrenSucceed && (
        <DirectShipmentStagesPart
          data-tid="ProcessingStage"
          delivery={vm.delivery}
          vm={vm}
          overrideFields={{
            name: vm.processingStepName,
            isRejected: false,
            isRecalled: false,
            collapsed: !vm.isDeliveryOpenedState.get(`ProcessingStage_${vm.delivery.id}`),
            onCollapse: () => vm.onCollapseDelivery(`ProcessingStage_${vm.delivery.id}`),
          }}
        />
      )}

      {vm.delivery.childDeliveries.map(child => (
        <DirectShipmentStagesPart
          data-tid="ChildStage"
          delivery={child}
          vm={vm}
          key={child.id}
          isPartial={child.allItemsCodes.length !== vm.delivery.allItemsCodes.length}
          parentDelivery={vm.delivery}
          overrideFields={{
            collapsed: !vm.isDeliveryOpenedState.get(child.id),
            onCollapse: () => vm.onCollapseDelivery(child.id),
          }}
        />
      ))}

      {vm.showWaitingForCounterpartyStep && (
        <DeliveryStatusBlock
          data-tid="WaitingForCounterpartyStage"
          status={vm.stages[DeliveryStage.WaitingForCounterparty].progress}
          name={vm.stages[DeliveryStage.WaitingForCounterparty].name}
          size={vm.getTitleSize(vm.stages[DeliveryStage.WaitingForCounterparty])}
          error={vm.stages[DeliveryStage.WaitingForCounterparty].errorInfo}
          alwaysVisibleComp={
            <>
              {vm.stages[DeliveryStage.WaitingForCounterparty].progress === ProgressStage.InProgress && (
                <CreatingDocWrap leftOffset={"322px"}>
                  <StageGeyText>Ждем ответа получателя</StageGeyText>
                </CreatingDocWrap>
              )}

              <DateAndFIOWrap data-tid="CompleteInfo">
                {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.WaitingForCounterparty])}
              </DateAndFIOWrap>
            </>
          }
        />
      )}

      {vm.showCompleteStage && (
        <DeliveryStatusBlock
          data-tid="DoneStage"
          status={vm.stages[DeliveryStage.Done].progress}
          name={vm.doneStageTitle}
          error={vm.stages[DeliveryStage.Done].errorInfo}
          size={vm.doneStageSize}
        />
      )}
    </DeliveryStagesBlock>
  );
});
