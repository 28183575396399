import React, { useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { Button, Link, Spinner, Tooltip } from "components";
import RefreshIcon from "@skbkontur/react-icons/Refresh";
import { ErrorText, ErrorWrap, InfoText, TooltipContent } from "./SyncInfo.styles";
import { SYNC_POLL_INTERVAL } from "stores/shared";
import { SyncResult } from "typings/server";
import { HelpLite } from "@skbkontur/react-icons";
import { SupportCenter } from "support";
import { ZnakEmail, ZnakSupportPhone } from "StringResx";

interface SyncInfoModelVM {
  syncResult: SyncResult | undefined;
  syncErrorMessage: string | undefined;
  synchronizeAndUpdate(interval?: number): void;
  lastUpdateTime: string;
}

interface SyncInfoModelProps {
  vm: SyncInfoModelVM;
}

export const SyncInfo: React.FC<SyncInfoModelProps> = observer(({ vm }: SyncInfoModelProps) => {
  const synchronize = () => vm.synchronizeAndUpdate(SYNC_POLL_INTERVAL);
  const isMDLPUnavailable = useMemo(() => vm.syncErrorMessage?.includes("Сервер ИС МДЛП недоступен"), [
    vm.syncErrorMessage,
  ]);

  const tooltipRef = useRef<Tooltip>(null);
  return (
    <div data-tid="SyncInfo">
      {(vm.syncResult === SyncResult.Success || !vm.syncResult) && (
        <>
          {vm.lastUpdateTime && <InfoText>Данные и статусы от {vm.lastUpdateTime}</InfoText>}
          <Button data-tid="Synchronize" use="link" onClick={synchronize} size="small" icon={<RefreshIcon />}>
            Синхронизировать с ИС МДЛП
          </Button>
        </>
      )}
      {vm.syncResult === SyncResult.InProcess && <Spinner type="mini" caption="Синхронизация..." />}
      {vm.syncResult === SyncResult.Fail && (
        <>
          {vm.lastUpdateTime && <InfoText>Данные и статусы от {vm.lastUpdateTime}</InfoText>}
          <Tooltip ref={tooltipRef} render={renderError} trigger="click" closeButton={false} pos="top center">
            <ErrorWrap>
              <HelpLite /> {isMDLPUnavailable ? "Сервер ИС МДЛП недоступен" : "Ошибка синхронизации"}
            </ErrorWrap>
          </Tooltip>
          <Button data-tid="TrySynchronizeAgain" use="link" onClick={synchronize} size={"small"} icon={<RefreshIcon />}>
            Повторить попытку
          </Button>
        </>
      )}
    </div>
  );

  function renderError() {
    const openSupportCenter = () => {
      tooltipRef.current?.hide();
      SupportCenter.open();
    };

    return (
      <TooltipContent>
        <ErrorText>{vm.syncErrorMessage}</ErrorText>
        {isMDLPUnavailable ? (
          <>
            Попробуйте позже. Если ошибка останется, обратитесь в Честный Знак: напишите на почту{" "}
            <Link href={`mailto:${ZnakEmail}`}>support@crpt.ru</Link> или позвоните по телефону{" "}
            <Link href={`tel:${ZnakSupportPhone}`}>8 800 222-15-23</Link>.
          </>
        ) : (
          <>
            Попробуйте еще раз. Если ошибка останется, обратитесь в{" "}
            <Link onClick={openSupportCenter}>центр поддержки.</Link>
          </>
        )}
      </TooltipContent>
    );
  }
});
