import React, { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus, DeliveryType } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { CreatingDocWrap, DateAndFIOWrap } from "Common/Stages/Stages.styles";
import { CirculationPageVM } from "../CirculationPageVM";
import { PageInfoBlock } from "./PageInfoBlock/PageInfoBlock";
import { OperationsStatusNames } from "Common/Status/StatusesName";
import { DateAndUser } from "Common/Stages/DateAndUser";

interface IStagesProps {
  vm: CirculationPageVM;
}

export const StagesBlock: FC<IStagesProps> = observer((props: IStagesProps) => {
  const { vm } = props;

  const onCollapse = () => vm.onCollapseDelivery(vm.delivery.id);

  useEffect(() => {
    if (vm.delivery.status === DeliveryStatus.Processing) {
      // Если delivery переходит в Processing, стадия должна быть развернута
      // Например, при возврате в состояние черновика.
      vm.onCollapseDelivery(vm.delivery.id, true);
    }
    // Для вывода из оборота стадию нужно развернуть, если операция завершилась,
    // так как там будет пробивалка статусов ко кодам!
    if (
      vm.delivery.type === DeliveryType.Withdrawal ||
      vm.delivery.type === DeliveryType.DisposalWithRegistrator ||
      vm.delivery.type === DeliveryType.Disposal
    ) {
      const notFinishedStages = [
        DeliveryStatus.Processing,
        DeliveryStatus.New,
        DeliveryStatus.Signing,
        DeliveryStatus.Signed,
        DeliveryStatus.CreatingDocument,
      ];

      if (!notFinishedStages.includes(vm.delivery.status)) {
        vm.onCollapseDelivery(vm.delivery.id, true);
      }
    }
  }, [vm.delivery.status]);

  return (
    <DeliveryStagesBlock>
      <DeliveryStatusBlock
        data-tid="ProcessingStage"
        size={vm.getTitleSize(vm.stages[DeliveryStage.Processing])}
        name={vm.stages[DeliveryStage.Processing].name}
        status={vm.stages[DeliveryStage.Processing].progress}
        collapsed={!vm.isDeliveryOpenedState.get(vm.delivery.id)}
        alwaysVisibleComp={
          <DateAndUser
            delivery={vm.delivery}
            getCompletionUser={vm.getCompletionUser}
            getCompletionDate={vm.getCompletionDate}
            step={DeliveryStatus.Processing}
          />
        }
        onCollapse={onCollapse}
      >
        <PageInfoBlock vm={vm} />
      </DeliveryStatusBlock>

      <DeliveryStatusBlock
        data-tid="SigningStage"
        status={vm.stages[DeliveryStage.Signing].progress}
        name={vm.stages[DeliveryStage.Signing].name}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Signing])}
        error={vm.stages[DeliveryStage.Signing].errorInfo}
        alwaysVisibleComp={
          <>
            {vm.delivery.status === DeliveryStatus.CreatingDocument && !vm.delivery.childDeliveries.length && (
              <CreatingDocWrap>
                <Spinner type="mini" caption={OperationsStatusNames[DeliveryStatus.CreatingDocument]} />
              </CreatingDocWrap>
            )}
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.CreatingDocument}
            />
          </>
        }
      />

      <DeliveryStatusBlock
        data-tid="SendingStage"
        status={vm.stages[DeliveryStage.Sending].progress}
        name={vm.getSendingStepName(vm.stages[DeliveryStage.Sending])}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Sending])}
        error={vm.stages[DeliveryStage.Sending].errorInfo}
        alwaysVisibleComp={
          <DateAndFIOWrap data-tid="CompleteInfo">
            {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Sending])}
          </DateAndFIOWrap>
        }
      />
      <DeliveryStatusBlock
        data-tid="DoneStage"
        status={vm.stages[DeliveryStage.Done].progress}
        name={vm.stages[DeliveryStage.Done].name}
        error={vm.stages[DeliveryStage.Done].errorInfo}
        size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
      />
    </DeliveryStagesBlock>
  );
});
