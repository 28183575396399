import { action, computed, observable } from "mobx";
import { DepartmentModel, EmployeeRole } from "typings/server";
import { isEmail } from "helpers/email";
import { EmployeePatchModelExtended } from "models/Employee/EmployeePatchModelExtended";
import { DUPLICATE_STATUS } from "api/shared";
import { ISettingsStore } from "stores/SettingsStore";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";
import { IUserStore } from "stores/UserStore";

type EmployeeEditorErrors = "invalidEmail" | "requiredEmail" | "requiredDepartment" | "duplicateInvite";

export class EmployeeEditorVM {
  @observable employeeModel: EmployeeModelExtended;
  @observable isAdmin: boolean;
  @observable allDepartmentsChecked: boolean;
  @observable errors: Map<EmployeeEditorErrors, string> = new Map();
  isNew: boolean;
  allDepartments: DepartmentModel[] = [];
  isShowPortalLink: boolean;

  constructor(readonly userStore: IUserStore, private readonly settingsStore: ISettingsStore, selectedEmployee?: EmployeeModelExtended) {
    this.isNew = !selectedEmployee;
    this.isShowPortalLink = userStore.user.hasView;    
    this.allDepartments = settingsStore.allDepartments;
    this.employeeModel = new EmployeeModelExtended(selectedEmployee);
    if (selectedEmployee) {
      const availableDepartments: DepartmentModel[] = selectedEmployee ? selectedEmployee.availableDepartments : [];
      this.employeeModel.setDepartments(availableDepartments);
    } else {
      this.employeeModel.setRole(EmployeeRole.User);
    }
    this.isAdmin = this.employeeModel.isAdmin;
    this.allDepartmentsChecked = this.employeeModel.hasAccessToAllDepartments;
  }

  @computed
  get isEditable(): boolean {
    return !(this.employeeModel && this.employeeModel.currentUser);
  }

  @action
  toggleAdminRole() {
    this.isAdmin = !this.isAdmin;
  }

  @action
  toggleAllDepartments() {
    this.allDepartmentsChecked = !this.allDepartmentsChecked;
  }

  @action
  changeDepartments(deps: DepartmentModel[]) {
    if (deps && deps.length) {
      this.errors.delete("requiredDepartment");
    }
    this.employeeModel.setDepartments(deps);
  }

  @action
  changeEmail(email: string) {
    this.employeeModel.email = email;
  }

  @action
  async save(): Promise<void> {
    this.employeeModel.setRole(this.getRole());
    const model = new EmployeePatchModelExtended(this.employeeModel, this.isAdmin, this.allDepartmentsChecked);
    if (this.isNew) {
      try {
        await this.settingsStore.invite(model);
      } catch (e: any) {
        if (e && e.status && e.status === DUPLICATE_STATUS) {
          this.errors.set("duplicateInvite", "Приглашение на этот email уже отправлено");
          return;
        }
        throw e;
      }
    } else {
      await this.settingsStore.changeEmployee(this.employeeModel, model);
    }
  }

  private getRole(): EmployeeRole {
    if (this.isAdmin) {
      return EmployeeRole.Admin;
    } else if (this.allDepartmentsChecked) {
      return EmployeeRole.AllDepartmentsUser;
    }
    return EmployeeRole.User;
  }

  @action
  validateEmail(options?: { required?: boolean }) {
    this.errors.delete("invalidEmail");
    if (this.employeeModel.email) {
      this.errors.delete("requiredEmail");
    } else if (options && options.required) {
      this.errors.set("requiredEmail", "Укажите email");
    }
    if (this.employeeModel.email) {
      const isValidEmail = isEmail(this.employeeModel.email);
      if (!isValidEmail) {
        this.errors.set("invalidEmail", "Невалидный email");
      }
    }
  }

  @action
  isValid(): boolean {
    this.errors.clear();
    if (this.isNew) this.validateEmail({ required: true });
    const isAnyDepartment = this.employeeModel.availableDepartments && this.employeeModel.availableDepartments.length;
    if (!this.isAdmin && !this.allDepartmentsChecked && !isAnyDepartment) {
      this.errors.set("requiredDepartment", "Укажите хотя бы одно подразделение");
    }
    return this.errors.size === 0;
  }
}
