import React from "react";
import { observer } from "mobx-react";
import { GridCell } from "components/Grid/Grid.styles";
import { EditorLabel, OKUDInputWrap, OKUDWrap } from "./EditorModal.styles";
import { TextGrey } from "styles/shared.styles";
import { ComboBox, Input, Tooltip } from "components";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { RegistratorEmbeddedModel } from "typings/server";

interface IWithdrawalFieldsProps {
  row: number;
  title?: string;
  required?: boolean;
  vm: {
    companyName: string;
    currentDepartmentAddress: string;
    errors: Map<string, string>;
    operationDate?: string;
    maxDate: string;
    documentNumber: string;
    documentDate: string;
    documentType?: string;
    OKUDCode?: string;
    registrator?: RegistratorEmbeddedModel;
    registrators?: RegistratorEmbeddedModel[];
    isDisposalWithRegistrator?: boolean;

    setOperationDate?(date?: string): void;
    setDocumentDate(date?: string): void;
    setDocumentNumber(num: string): void;
    setDocumentType?(type: string): void;
    setOKUDCode?(code: string): void;
    setRegistrator?(registrator: RegistratorEmbeddedModel): void;
  };
}

export class EditorFields extends React.Component {
  static Sender = (props: IWithdrawalFieldsProps) => {
    const { vm, row, required } = props;
    return (
      <>
        <GridCell col={1} row={row}>
          <EditorLabel required={required}>Отправитель</EditorLabel>
        </GridCell>

        <GridCell col={2} row={row}>
          <span data-tid="CompanyName">{vm.companyName}</span>
          <br />
          <TextGrey data-tid="CurrentDepartmentAddress">{vm.currentDepartmentAddress}</TextGrey>
        </GridCell>
      </>
    );
  };

  static ReportDate = observer((props: IWithdrawalFieldsProps) => {
    const { vm, row, required, title } = props;
    const getOperationDateError = () => vm.errors.get("invalidOperationDate");
    return (
      <>
        <GridCell col={1} row={row} padding="25px ​4px 2px 4px">
          <EditorLabel breakText required={required}>
            {title || "Дата вывода из оборота"}
          </EditorLabel>
        </GridCell>
        <GridCell col={2} row={row}>
          <DateTimePicker
            data-tid="ReportDate"
            value={vm.operationDate as string}
            maxDate={vm.maxDate}
            onChange={vm.setOperationDate as (date?: string) => void}
            getReportDateError={getOperationDateError}
          />
        </GridCell>
      </>
    );
  });

  static DocumentNumber = observer((props: IWithdrawalFieldsProps) => {
    const { vm, row, required } = props;

    const getDocNumError = () =>
      required
        ? vm.errors.get("docNumberInvalid") || vm.errors.get("docNumberRequired")
        : vm.errors.get("docNumberInvalid");

    return (
      <>
        <GridCell col={1} row={row} padding={"15px 4px 8px"}>
          <EditorLabel required={required}>№ документа</EditorLabel>
        </GridCell>
        <GridCell col={2} row={row}>
          <Tooltip trigger="hover" render={getDocNumError}>
            <Input
              width={"120px"}
              data-testid="DocumentNumber"
              value={vm.documentNumber}
              onValueChange={vm.setDocumentNumber}
              error={!!getDocNumError()}
            />
          </Tooltip>
        </GridCell>
      </>
    );
  });

  static DocumentDate = observer((props: IWithdrawalFieldsProps) => {
    const { vm, row, required } = props;

    const getDocDateError = () => vm.errors.get("invalidDocDate") || vm.errors.get("docDateRequired");

    return (
      <>
        <GridCell col={1} row={row} padding={"15px 4px 8px"}>
          <EditorLabel required={required}>Дата документа</EditorLabel>
        </GridCell>
        <GridCell col={2} row={row}>
          <DateTimePicker
            data-testid="DocumentDate"
            value={vm.documentDate}
            onChange={vm.setDocumentDate}
            getReportDateError={getDocDateError}
            maxDate={vm.maxDate}
            withoutTime
            utc={vm.isDisposalWithRegistrator}
          />
        </GridCell>
      </>
    );
  });

  static DocumentType = observer((props: IWithdrawalFieldsProps) => {
    const { vm, row, required } = props;

    const getDocNumError = () => (required ? vm.errors.get("docTypeRequired") : null);
    const getOKUDCodeError = () => vm.errors.get("OKUDCodeInvalid");

    return (
      <>
        <GridCell col={1} row={row} padding={"15px 4px 8px"}>
          <EditorLabel required={required}>Тип документа</EditorLabel>
        </GridCell>
        <GridCell col={2} row={row}>
          <Tooltip trigger={"hover"} render={getDocNumError}>
            <Input
              data-tid="DocumentType"
              width={"200px"}
              value={vm.documentType}
              onValueChange={vm.setDocumentType}
              error={!!getDocNumError()}
            />
          </Tooltip>
          <OKUDWrap>
            <EditorLabel inside>Код ОКУД</EditorLabel>
            <OKUDInputWrap>
              <Tooltip trigger={"hover"} render={getOKUDCodeError}>
                <Input
                  data-tid="OKUDCode"
                  width={"120px"}
                  value={vm.OKUDCode}
                  onValueChange={vm.setOKUDCode}
                  error={!!getOKUDCodeError()}
                />
              </Tooltip>
            </OKUDInputWrap>
          </OKUDWrap>
        </GridCell>
      </>
    );
  });

  static Registrator = observer((props: IWithdrawalFieldsProps) => {
    const { vm, row, required } = props;

    const getItems = (q: string) => {
      const query = q.toLowerCase();
      return Promise.resolve(
        (vm.registrators as RegistratorEmbeddedModel[]).filter(reg => {
          const name = reg.name?.toLowerCase();
          return name?.includes(query);
        })
      );
    };

    const onChange = (dep: RegistratorEmbeddedModel) => vm.setRegistrator && vm.setRegistrator(dep);

    const renderItem = (item: RegistratorEmbeddedModel) => item.name ?? "";

    const renderRegistratorError = () => (required ? vm.errors.get("registratorRequired") : null);

    return (
      <>
        <GridCell col={1} row={row} padding={"15px 4px 8px"}>
          <EditorLabel breakText required={required}>
            Регистратор выбытия
          </EditorLabel>
        </GridCell>
        <GridCell col={2} row={row}>
          <Tooltip render={renderRegistratorError} pos="right middle">
            <ComboBox
              data-tid="Registrator"
              getItems={getItems}
              value={vm.registrator}
              onValueChange={onChange}
              renderItem={renderItem}
              renderValue={renderItem}
              valueToString={renderItem}
              width={"100%"}
              error={!!renderRegistratorError()}
            />
          </Tooltip>
        </GridCell>
      </>
    );
  });
}
