import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { ICertificate } from "@skbkontur/plugin-js";
import { ShipmentRoute } from "routes";
import { Button, Hint, Toast } from "components";
import { Content, ContentWrapperWithFooter } from "components/Layout";
import { CertificatesList } from "../../../CertificatesList/CertificatesList";
import { FooterBtnWrapper, FooterSticky } from "components/Layout/Footer.styles";
import DocumentLite from "@skbkontur/react-icons/DocumentLite";
import { ErrorSignBlock } from "../../../CertificatesList/shared";
import { DirectShipmentPageVM } from "./DirectShipmentPageVM";
import { GoToBlock } from "components/GoToBlock/GoTo";
import { DirectShipmentStagesBlock } from "./Stages/DirectShipmentStagesBlock";
import { ShipmentEditor } from "./Editors/Direct/ShipmentEditor";
import { ShipmentScannerSidePage } from "./ShipmentScannerSidePage/ShipmentScannerSidePage";
import { PageHeader } from "../Common/PageHeader/PageHeader";
import { EAEUWarning, UnregisteredWarning } from "./ShipmentConfirmation/ConfirmationTexts";
import { ShipmentEAEUCountiesEditor } from "./Editors/EAEUCountries/ShipmentEAEUCountriesEditor";
import { DeliveryConfirmation } from "Common/Confirmation/DeliveryConfirmation";
import { RecallModal } from "./RecallModal/RecallModal";
import { RollbackButton } from "Common/Rollback/RollbackButton/RollbackButton";
import { RollbackModal } from "Common/Rollback/RollbackModal/RollbackModal";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { DeleteBtnWrap, deleteBtnTheme } from "Common/DetailsPage/DetailsPage.styles";
import TrashIcon from "@skbkontur/react-icons/Trash";
import { SuccessSignBlock } from "Common/SuccessSignBlock/SuccessSignBlock";

interface IDirectShipmentPageProps extends RouteComponentProps {
  vm: DirectShipmentPageVM;
}

export const DirectShipmentPage = withRouter(
  observer((props: IDirectShipmentPageProps) => {
    const { vm, history } = props;

    // mount
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      if (params.has("new")) {
        vm.toggleModal("editorModal");
      }
    }, [vm]);

    // unmount
    useEffect(() => () => vm.handleUnmount(), [vm]);

    const toggleCertificatesModal = () => vm.toggleModal("certList");

    const onSign = async (cert: ICertificate): Promise<any> => vm.sign(cert, vm.isResended);

    const saveDraft = async () => {
      history.push(ShipmentRoute.baseRoute);
    };

    const resend = async () => {
      await vm.resend();
    };

    const toggleScanSidePage = () => vm.toggleModal("scanPage");

    const onComplete = async () => {
      vm.setSubmitted();
      if (vm.canComplete()) {
        await save();
      }
    };

    const save = async () => {
      await vm.save();
      Toast.push("Отгрузка завершена. Создаем документ");
    };

    const toggleEditorModal = () => vm.toggleModal("editorModal");

    const toggleRecallModal = () => vm.toggleModal("recall");

    const toggleConfirmationModal = () => vm.toggleModal("confirmation");

    const toggleRollbackModal = () => vm.toggleModal("rollback");

    const onRollback = async () => {
      await vm.rollbackDeliveryStatus();
      toggleRollbackModal();
    };

    const onRollbackButtonClick = async () => {
      if (vm.shouldOpenRollbackModal) {
        toggleRollbackModal();
      } else {
        vm.rollbackDeliveryStatus();
      }
    };

    const toggleDeleteModal = () => vm.toggleModal("delete");

    const onDelete = async () => {
      await vm.deleteDelivery();
      toggleDeleteModal();
      history.push(ShipmentRoute.baseRoute);
    };

    const goBackBlock = <GoToBlock name={"К списку отгрузок"} href={ShipmentRoute.baseRoute} />;

    return (
      <ContentWrapperWithFooter>
        <Content data-tid="OperationCard" padding={"24px 24px 24px 38px"}>
          {vm.modalMap.get("editorModal") &&
            (vm.isEAEUCountry ? (
              <ShipmentEAEUCountiesEditor
                onClose={toggleEditorModal}
                onComplete={vm.submitted ? onComplete : undefined}
                errorText={vm.errors.get("requiredFields")}
              />
            ) : (
              <ShipmentEditor
                onClose={toggleEditorModal}
                onComplete={vm.submitted ? onComplete : undefined}
                errorText={vm.errors.get("requiredFields")}
              />
            ))}
          {vm.modalMap.get("scanPage") && (
            <ShipmentScannerSidePage
              close={toggleScanSidePage}
              good={vm.selectedGood}
              onSave={vm.savePositions}
              additionalInfo={vm.additionalInfo}
            />
          )}
          {vm.modalMap.get("certList") && (
            <CertificatesList
              title={"Подписание документа"}
              renderSuccessBlock={() => <SuccessSignBlock route={ShipmentRoute.baseRoute} textSubString="отгрузок" />}
              errorBlock={ErrorSignBlock}
              onClose={toggleCertificatesModal}
              sign={onSign}
            />
          )}
          {vm.modalMap.get("confirmation") && (
            <DeliveryConfirmation
              onClose={toggleConfirmationModal}
              onSave={save}
              unscannedLen={vm.unscannedPositionsLen}
              errorText={"Не смогли завершить отгрузку, попробуйте еще раз позднее"}
              title={"Завершение отгрузки"}
            >
              {(vm.unregisteredCounteragent || vm.unregisteredDepartment) && (
                <UnregisteredWarning allScanned={!vm.unscannedPositionsLen} />
              )}
              {vm.isEAEUCountry && <EAEUWarning allScanned={!vm.unscannedPositionsLen} />}
            </DeliveryConfirmation>
          )}
          {vm.modalMap.get("recall") && (
            <RecallModal onConfirm={vm.onRecall} deliveryId={vm.delivery.id} onClose={toggleRecallModal} />
          )}
          {vm.modalMap.get("rollback") && (
            <RollbackModal
              headerText="Вернуться к отгрузке?"
              bodyText="Отгрузка станет доступна для редактирования и повторной отправки в ИС МДЛП."
              onClose={toggleRollbackModal}
              onRollback={onRollback}
              isLoading={vm.loadingMap.get("rollback")}
            />
          )}
          {vm.modalMap.get("delete") && (
            <DeleteDeliveryModal
              deliveryType={vm.delivery.type}
              isDeliveryError={vm.isFailedStatus}
              isScannedCodes={Boolean(vm.delivery.allScannedLen)}
              isLoading={vm.loadingMap.get("delete")}
              onClose={toggleDeleteModal}
              onDelete={onDelete}
            />
          )}

          <PageHeader
            vm={vm}
            showEditor={vm.areGoodsEditable}
            goBackBlock={goBackBlock}
            onEdit={toggleEditorModal}
            onDownload={vm.prepareDocuments}
          />
          <DirectShipmentStagesBlock vm={vm} />
        </Content>
        {vm.showFooter && (
          <FooterSticky>
            <FooterBtnWrapper>
              {vm.showSignBtn && (
                <Button
                  data-tid="Sign"
                  onClick={toggleCertificatesModal}
                  use={"primary"}
                  size={"large"}
                  width={"200px"}
                >
                  Подписать
                </Button>
              )}
              {vm.areGoodsEditable && (
                <Button
                  data-tid="CreateDocument"
                  use="primary"
                  disabled={vm.disableCompleteBtn}
                  size={"large"}
                  onClick={onComplete}
                  loading={vm.loadingMap.get("creatingDocument")}
                >
                  Завершить отгрузку
                </Button>
              )}
              {vm.showTryAgainBtn && (
                <Hint text="Исправьте ошибки и повторно отправьте сведения в ИС МДЛП">
                  <Button data-tid="TryAgain" onClick={resend} size="large">
                    Отправить повторно
                  </Button>
                </Hint>
              )}
              {vm.showRecallBtn && (
                <Button
                  data-tid="OpenRecallModal"
                  size={"large"}
                  onClick={toggleRecallModal}
                  loading={vm.isRecallBtnLoading}
                >
                  Отозвать отгрузку
                </Button>
              )}
              {!!vm.rollbackBtnText && (
                <RollbackButton onClick={onRollbackButtonClick} size="large" hintText={vm.rollbackHintText}>
                  {vm.rollbackBtnText}
                </RollbackButton>
              )}
              {vm.showDeleteBtn && (
                <DeleteBtnWrap>
                  <Button
                    data-tid="DeleteDocument"
                    onClick={toggleDeleteModal}
                    theme={deleteBtnTheme}
                    use="link"
                    icon={<TrashIcon />}
                  >
                    {vm.isFailedStatus ? "Удалить отгрузку" : "Удалить черновик"}
                  </Button>
                </DeleteBtnWrap>
              )}
            </FooterBtnWrapper>
            {vm.areGoodsEditable && (
              <Button data-tid="SaveAndClose" use="link" onClick={saveDraft} size={"small"} icon={<DocumentLite />}>
                Сохранить и выйти
              </Button>
            )}
          </FooterSticky>
        )}
      </ContentWrapperWithFooter>
    );
  })
);
