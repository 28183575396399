import { Link, Tooltip } from "components";
import React from "react";
import { HelpDot, HelpBook, Video } from "@skbkontur/react-icons";
import { MDLPInstructionHref702, reverseAcceptanceInstruction, VideoInstruction702 } from "StringResx";
import { HelpIconWrap, TipBlock, TipText, VideoLinkWrap } from "./UtdTitle.styles";

interface UtdTitleProps {
  isSimplified: boolean;
}

export function UtdTitle({ isSimplified }: UtdTitleProps) {
  return (
    <span>
      {isSimplified ? "Приемка по упрощенной схеме" : "Приемка по обратному акцепту"}
      <HelpIconWrap>
        <Tooltip
          trigger={"click"}
          render={isSimplified ? renderInfoSimplified : renderInfoReverse}
          pos={"right top"}
          allowedPositions={["right top", "bottom center", "right middle"]}
        >
          <HelpDot />
        </Tooltip>
      </HelpIconWrap>
    </span>
  );

  function renderInfoReverse() {
    return (
      <TipText>
        Применяется, когда поставщик в момент отгрузки не формирует сведения в ИС МДЛП, сведения о приемке
        маркированного товара первым осуществляет получатель.
        <TipBlock>
          <Link href={reverseAcceptanceInstruction} icon={<HelpDot />} target="_blank">
            Приемка по обратному акцепту
          </Link>
        </TipBlock>
      </TipText>
    );
  }

  function renderInfoSimplified() {
    return (
      <TipText>
        Применяется в случаях возникновения нештатных ситуаций при работе с&nbsp;ИС&nbsp;МДЛП:
        <ol>
          <li>Не удается передать сведения о приемке в&nbsp;ИС&nbsp;МДЛП.</li>
          <li>Поставщик не может согласовать приемку обратным акцептом из-за ошибок ИС&nbsp;МДЛП.</li>
        </ol>
        <Link href={MDLPInstructionHref702} target={"_blank"}>
          <HelpBook /> Инструкция по упрощенной 702 схеме
        </Link>
        <VideoLinkWrap>
          <Link href={VideoInstruction702} target={"_blank"}>
            <Video /> Видеоинструкция
          </Link>
        </VideoLinkWrap>
      </TipText>
    );
  }
}
