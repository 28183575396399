import styled from "styles/styled-components";

export const HelpText = styled.div`
  margin-bottom: 20px;
`;

export const ErrorBlock = styled.div`
  padding: 10px 15px;
  margin-bottom: 10px;
  background-color: ${props => props.theme.colors.redBackground};
  border-radius: 3px;
`;

export const AddButtonWrap = styled.span`
  margin-right: 10px;
`;

export const TokenInputWrap = styled.div`
  margin: 15px 0 6px;
`;

export const ComboBoxWrap = styled.div`
  margin-bottom: 6px;
`;
