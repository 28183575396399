import { DiscardBlockWrap, HideWrap } from "features/ScannerSidePage/ScannerSidePage.styles";
import { blink } from "styles/animations";
import styled from "styles/styled-components";

interface InfoBlockProps {
  canBeDiscarded: boolean;
  sidePage: boolean;
  isNew?: boolean;
}

export const InfoBlock = styled.div<InfoBlockProps>`
  display: flex;
  align-items: center;
  animation: ${props => props.isNew && blink};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-iteration-count: 2;
  background-color: ${props => props.isNew && props.theme.colors.greenLight};
  color: ${props => props.theme.textColors.lightenGray};
  min-height: 38px;

  &:hover {
    ${DiscardBlockWrap} {
      display: ${props => (props.sidePage ? (props.canBeDiscarded ? "inline-block" : "none") : "none")};
      opacity: 1;
    }
    ${HideWrap} {
      display: ${props => (props.sidePage ? (props.canBeDiscarded ? "none" : "inherit") : "inherit")};
    }
  }
`;

export const InfoRoot = styled.div`
  flex-basis: 100%;
`;

export const Header = styled.div`
  color: ${props => props.theme.textColors.lightenGray};
  background-color: ${props => props.theme.colors.greyLight};
  margin-bottom: 3px;
`;

export const CopyIconWrap = styled.div`
  position: relative;
  visibility: hidden;
  transition: visibility 0ms linear 50ms;
`;

export const SgtinCodeText = styled.div`
  flex-basis: 245px;
`;

export const SsccCodeText = styled.div`
  flex-basis: 170px;
`;

export const Code = styled.div`
  cursor: pointer;
  margin: -7px 0;
  padding: 7px 0;
  display: flex;

  &:hover {
    ${CopyIconWrap} {
      visibility: visible;
    }
  }
`;

export const TableWrap = styled.div`
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TooltipTextWrapper = styled.div`
  width: 225px;
`;

export const TooltipSubTextWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ProducerTextWrapper = styled.div`
  margin: 2px 0 10px 6px;
`;

export const UndoIconWrap = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.darkBlue};
`;
