import React, { useEffect, FC, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { CirculationRoute } from "routes";
import { Tabs, Tooltip } from "components";
import { RouteComponentProps } from "react-router";
import { DeliveryType } from "typings/server";
import { Content } from "components/Layout";
import { ICertificate } from "@skbkontur/plugin-js";
import { SignatureStore } from "stores/SignatureStore";
import { UserStore } from "stores/UserStore";
import { DeliveryHeader, DeliveryTopBlock, UpdateBlock } from "Common/Styles/MainPage.styles";
import { SyncInfo } from "Common/SyncInfo/SyncInfo";
import { TabsWrap, Dot } from "./MainPage.styles";
import { MdlpModal } from "../../CertificatesList/MdlpModal";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { SYNC_POLL_INTERVAL } from "stores/shared";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { DeliveriesStore } from "stores/DeliveriesStore";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { AdvertisementStore } from "stores/AdvertisementStore";
import { Separator } from "styles/separator";
import { CirculationSubRoute } from "routes/CirculationRoute";
import { Redirect, Route, Switch } from "react-router-dom";
import { Withdrawal } from "./Withdrawal";
import { Reentry } from "./Reentry";
import { Destruction } from "./Destruction";
import { ThemeContext as ReactUiThemeContext, FLAT_THEME, ThemeFactory } from "@skbkontur/react-ui";
import { theme } from "styles/theme";

export const DELIVERY_TYPES = [DeliveryType.Withdrawal, DeliveryType.Disposal, DeliveryType.DisposalWithRegistrator];

export interface IMainPageProps extends RouteComponentProps<CirculationRoute> {
  vm: DeliveriesMainPageVM;
}

const MainPageInternal: FC<IMainPageProps> = observer(props => {
  const isReentry = props.history.location.pathname.includes(CirculationSubRoute.reentry);
  const isDestruction = props.history.location.pathname.includes(CirculationSubRoute.destruction);

  const initialValue = useMemo(() => {
    if (isReentry) {
      return CirculationSubRoute.reentry;
    }
    if (isDestruction) {
      return CirculationSubRoute.destruction;
    }

    return CirculationSubRoute.withdrawal;
  }, [isReentry, isDestruction]);

  const typeByTabName = (tab: CirculationSubRoute) => {
    switch (tab) {
      case CirculationSubRoute.reentry:
        return DeliveryType.Reentry;
      case CirculationSubRoute.destruction:
        return DeliveryType.Destruction;
      default:
        return DeliveryType.Withdrawal;
    }
  };

  const [selectedTab, setSelectedTab] = useState<CirculationSubRoute>(initialValue);

  const { history, vm } = props;

  // unmount
  useEffect(() => {
    return () => {
      vm.handleUnmount();
    };
  }, []);

  useEffect(() => {
    // Если повторный ввод в оборот невозможен, но мы находимся этом табе
    // (такое возможно при смене подразделения/восстановлении) - переключим таб на "Вывод из оборота"
    if (vm.isReentryAndDestructionImposible && selectedTab === CirculationSubRoute.reentry) {
      setTab(CirculationSubRoute.withdrawal);
    }
    if (vm.isReentryAndDestructionImposible && selectedTab === CirculationSubRoute.destruction) {
      setTab(CirculationSubRoute.withdrawal);
    }
  }, []);

  const updatedTheme = useMemo(() => {
    return ThemeFactory.create(
      {
        tabColorFocus: theme.colors.bluePrimary,
        tabBorderWidth: "3px",
      },
      FLAT_THEME
    );
  }, []);

  const toggleMdlpModal = () => vm.toggleModal("mdlpModal");

  const toggleDeleteModal = () => vm.toggleModal("delete");

  const onDelete = async () => {
    toggleDeleteModal();
    await vm.deleteDelivery();
  };

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
    await vm.synchronizeAndUpdate(SYNC_POLL_INTERVAL);
  };

  const setTab = (tab: string) => {
    history.push(CirculationRoute.getHref(undefined, tab as CirculationSubRoute));
    WithdrawalStore.changeType(typeByTabName(tab as CirculationSubRoute));
    vm.resetAllFiltersAndPage();
    setSelectedTab(tab as CirculationSubRoute);
  };

  const getReentryImposibleTooltip = () => {
    if (!vm.isReentryAndDestructionImposible) {
      return;
    }

    return (
      <>
        Повторный ввод в оборот нельзя выполнить с места ответственного хранения.
        <br />
        Для повторного ввода в оборот выберете место деятельности.
      </>
    );
  };

  const getDestructionImposibleTooltip = () => {
    if (!vm.isReentryAndDestructionImposible) {
      return;
    }

    return (
      <>
        Передачу на уничтожение и уничтожение нельзя выполнить с места ответственного хранения.
        <br />
        Для передачи на уничтожение и уничтожения выберете место деятельности.
      </>
    );
  };

  // Чтобы наверняка поменять таб в сторе
  useEffect(() => {
    if (isDestruction) {
      WithdrawalStore.changeType(typeByTabName(CirculationSubRoute.destruction));
      return;
    }

    if (isReentry) {
      WithdrawalStore.changeType(typeByTabName(CirculationSubRoute.reentry));
      return;
    }

    WithdrawalStore.changeType(typeByTabName(CirculationSubRoute.withdrawal));
  }, [isReentry, isDestruction]);

  return (
    <Content data-tid="MainPage">
      <DeliveryTopBlock>
        <DeliveryHeader>
          <h2>{CirculationRoute.title}</h2>
          <UpdateBlock>
            <SyncInfo vm={vm} />
          </UpdateBlock>
        </DeliveryHeader>

        <ReactUiThemeContext.Provider value={updatedTheme}>
          <TabsWrap>
            <Tabs value={selectedTab} onValueChange={setTab}>
              <Tabs.Tab id={CirculationSubRoute.withdrawal}>Вывод из оборота</Tabs.Tab>
              <Tooltip style={{ maxWidth: 344 }} pos="top center" trigger="hover" render={getReentryImposibleTooltip}>
                <Tabs.Tab id={CirculationSubRoute.reentry} disabled={vm.isReentryAndDestructionImposible}>
                  Повторный ввод в оборот
                </Tabs.Tab>
              </Tooltip>
              <Tooltip
                style={{ maxWidth: 344 }}
                pos="top center"
                trigger="hover"
                render={getDestructionImposibleTooltip}
              >
                <Tabs.Tab id={CirculationSubRoute.destruction} disabled={vm.isReentryAndDestructionImposible}>
                  Уничтожение
                  {vm.hasNewDestructions && <Dot />}
                </Tabs.Tab>
              </Tooltip>
            </Tabs>
          </TabsWrap>
        </ReactUiThemeContext.Provider>
        <Separator />
      </DeliveryTopBlock>
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={toggleMdlpModal}
          title="Доступ в ИС МДЛП"
          sign={onSign}
          successText="Доступ в ИС МДЛП открыт на 30 минут"
        />
      )}
      {vm.modalState.get("delete") && vm.deliveryToDelete && (
        <DeleteDeliveryModal
          deliveryType={vm.deliveryToDelete.type}
          onDelete={onDelete}
          onClose={toggleDeleteModal}
          getDeliveryItems={vm.getDeliveryItems}
          isLoading={vm.loadingState.get("delete")}
        />
      )}

      <Switch>
        {vm.isReentryAndDestructionImposible && (
          <Redirect exact from={CirculationRoute.reentryBaseRoute} to={CirculationRoute.withdrawalBaseRoute} push />
        )}
        {vm.isReentryAndDestructionImposible && (
          <Redirect exact from={CirculationRoute.destructionBaseRoute} to={CirculationRoute.withdrawalBaseRoute} push />
        )}
        <Redirect exact from={CirculationRoute.baseRoute} to={CirculationRoute.withdrawalBaseRoute} push />
        <Route path={CirculationRoute.withdrawalBaseRoute} exact>
          <Withdrawal {...props} />
        </Route>
        <Route path={CirculationRoute.reentryBaseRoute} exact>
          <Reentry {...props} />
        </Route>
        <Route path={CirculationRoute.destructionBaseRoute} exact>
          <Destruction {...props} />
        </Route>
      </Switch>
    </Content>
  );
});

export const MainPage = (props: RouteComponentProps<CirculationRoute>) => {
  const isReentry = props.history.location.pathname.includes(CirculationSubRoute.reentry);
  const isDestruction = props.history.location.pathname.includes(CirculationSubRoute.destruction);

  const DELIVERY_TYPES = useMemo(() => {
    if (isReentry) {
      return [DeliveryType.Reentry];
    }
    if (isDestruction) {
      return [DeliveryType.Destruction];
    }
    return [DeliveryType.Withdrawal, DeliveryType.Disposal, DeliveryType.DisposalWithRegistrator];
  }, [isReentry, isDestruction]);

  useEffect(() => {
    if (isReentry) {
      WithdrawalStore.changeType(DeliveryType.Reentry);
      return;
    }

    if (isDestruction) {
      WithdrawalStore.changeType(DeliveryType.Destruction);
      return;
    }

    WithdrawalStore.changeType(DeliveryType.Withdrawal);
  }, [isReentry, isDestruction]);

  const vm = useMemo(
    () =>
      new DeliveriesMainPageVM(
        WithdrawalStore,
        SignatureStore,
        UserStore,
        DeliveriesStore,
        AdvertisementStore,
        DELIVERY_TYPES
      ),
    []
  );
  useEffect(() => {
    vm.setType(DELIVERY_TYPES);
  }, [isReentry, isDestruction]);

  return <MainPageInternal vm={vm} {...props} />;
};
