import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { SsccCodeContent } from "features/ScannerSidePage/SidePages/UnpackingSidePage/SsccCodeContent";
import { ArrowChevronRight, ArrowChevronDown } from "@skbkontur/react-icons";
import { ScannerRow } from "features/ScannerSidePage/ScannerSidePage.styles";
import { UnpackingPageVM } from "../../../UnpackingPageVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import { AdditionalInfoWrap, NameText } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { CollapseIcon } from "Common/GoodsTable/CodeItem/CodeItem.styles";

interface UnpackingCodesTableItemProps {
  vm: UnpackingPageVM;
  cols: string;
  code: string;
  allCodesExpanded: boolean;
  additionalInfo?: CodeAdditionalInfoModel;
}

export const UnpackingCodesTableItem = observer(
  ({ vm, cols, code, allCodesExpanded, additionalInfo }: UnpackingCodesTableItemProps) => {
    const isShowAdditionalInfo = vm.showCodeContent(code);
    const [isExpanded, setIsExpaned] = useState(!allCodesExpanded);
    const onToggle = () => {
      setIsExpaned(v => !v);
    };

    useEffect(() => {
      setIsExpaned(allCodesExpanded);
    }, [allCodesExpanded]);

    return (
      <>
        <ScannerRow onClick={onToggle} isInfo={!!additionalInfo}>
          <Grid cols={cols} paddingCells="8px 5px">
            <GridCell col={1} row={1}>
              {isShowAdditionalInfo && (
                <CollapseIcon>{isExpanded ? <ArrowChevronRight /> : <ArrowChevronDown />}</CollapseIcon>
              )}
              <NameText isAnyAdditionalInfo={!!vm.additionalInfo.codesInfo.size && !additionalInfo}>{code}</NameText>
            </GridCell>
            <GridCell col={2} row={1}>
              {additionalInfo?.name}
            </GridCell>

            <GridCell col={3} row={1} textAlign={"right"}>
              {additionalInfo?.containedSsccCount || vm.delivery.ssccCodesLen || "—"}
            </GridCell>
            <GridCell col={4} row={1} textAlign={"right"}>
              {additionalInfo?.containedSgtinsCount || vm.delivery.sgtinCodesLen || "—"}
            </GridCell>
          </Grid>
        </ScannerRow>

        {isShowAdditionalInfo && (
          <AdditionalInfoWrap marginLeft={"17px"}>
            <SsccCodeContent ssccCodeContent={additionalInfo} isOpened={isExpanded} />
          </AdditionalInfoWrap>
        )}
      </>
    );
  }
);
