import React, { SyntheticEvent, Fragment } from "react";
import { observer } from "mobx-react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { SettingsPageVM } from "../SettingsPageVM";
import { Hint } from "components";
import { AdminIcon, DepartmentsWrap, EmployeeWrap, FIOWrap, RefreshIconWrap, TrashIconWrap } from "./Employees.styles";
import CrownIcon from "@skbkontur/react-icons/Crown";
import TrashIcon from "@skbkontur/react-icons/Trash";
import RefreshIcon from "@skbkontur/react-icons/Refresh";
import { EmployeeModelExtended } from "models/Employee/EmployeeModelExtended";

interface IEmployeeItemProps {
  vm: SettingsPageVM;
  employee: EmployeeModelExtended;
  cols: string;
}

export const EmployeeItem: React.FC<IEmployeeItemProps> = observer((props: IEmployeeItemProps) => {
  const { cols, vm, employee } = props;

  const openEditor = () => {
    vm.setSelectedEmployee(employee);
    vm.toggleModal("employeeEditor");
  };

  const onDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    vm.setSelectedEmployee(employee);
    vm.toggleModal("deleteEmployee");
  };

  const onRefreshFio = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    await vm.refreshEmployeeFio(employee.portalId);
  };

  return (
    <EmployeeWrap data-tid="EmployeeItem">
      <Grid onClick={openEditor} cols={cols} paddingCells={"13px 4px 16px"}>
        <GridCell col={1} row={1}>
          <FIOWrap>
            {employee.isAdmin && (
              <AdminIcon>
                <Hint text="Администратор">
                  <CrownIcon />
                </Hint>
              </AdminIcon>
            )}
            {employee.currentUser && "Вы: "}
            <span data-tid="Name">{employee.fio || employee.email || "—"}</span>
          </FIOWrap>
        </GridCell>
        <GridCell data-tid="Email" col={2} row={1}>
          {employee.email || "—"}
        </GridCell>
        <GridCell data-tid="AvailableDepartments" col={3} row={1}>
          {employee.hasAccessToAllDepartments || employee.isAdmin ? (
            "Все"
          ) : (
            <DepartmentsWrap>
              {employee.availableDepartments &&
                employee.availableDepartments.map(dep => (
                  <Fragment key={dep.id}>
                    {dep.name || dep.address}
                    <br />
                  </Fragment>
                ))}
            </DepartmentsWrap>
          )}
        </GridCell>
        <GridCell data-tid="UpdateFio" col={4} row={1} onClick={onRefreshFio}>
          <RefreshIconWrap>
            <Hint text="Обновить ФИО">
              <RefreshIcon />
            </Hint>
          </RefreshIconWrap>
        </GridCell>
        <GridCell data-tid="Delete" col={5} row={1} onClick={onDelete}>
          <TrashIconWrap>
            <TrashIcon />
          </TrashIconWrap>
        </GridCell>
      </Grid>
    </EmployeeWrap>
  );
});
