import React from "react";
import { observer } from "mobx-react";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { Code, CopyIconWrap, InfoBlock, SsccCodeText } from "../AdditionalInfo.styles";
import { Copy, Undo } from "@skbkontur/react-icons";
import { Button, Hint, Toast } from "components";
import { copyToBuffer } from "helpers/copy";
import { DiscardBlockWrap, HideWrap } from "features/ScannerSidePage/ScannerSidePage.styles";
import { AdditionalInfoVM } from "../AdditionalInfoVM";

interface IAdditionalInfoTableItemProps {
  vm: AdditionalInfoVM;
  codeInfo?: CodeAdditionalInfoModel;
  canBeDiscarded: boolean;
  onDiscardCode?: (code: string) => void;
}

export const AdditionalInfoSsccTableItem = observer((props: IAdditionalInfoTableItemProps) => {
  const { codeInfo, vm, onDiscardCode, canBeDiscarded } = props;

  if (!codeInfo) return null;

  const copy = () => {
    copyToBuffer(codeInfo.code);
    Toast.push("Код скопирован");
  };

  const discard = () => {
    onDiscardCode?.(codeInfo.code);
  };

  return (
    <InfoBlock data-tid="AdditionalInfoSsccTableItem" canBeDiscarded={canBeDiscarded} sidePage={!!onDiscardCode}>
      <Grid fullSize cols={vm.ssccCols} paddingCells={"7px 4px"}>
        <GridCell col={1} row={1}>
          <Code data-tid="Copy" onClick={copy}>
            <SsccCodeText data-tid="Code">{codeInfo.code}</SsccCodeText>
            <Hint text={"Скопировать"}>
              <CopyIconWrap>
                <Copy />
              </CopyIconWrap>
            </Hint>
          </Code>
        </GridCell>
        <GridCell data-tid="Gtin" col={2} row={1} alignSelf="start">
          {codeInfo.gtin || "—"}
        </GridCell>
        <GridCell data-tid="ContainedSsccCount" col={3} row={1}>
          {codeInfo.containedSsccCount || "—"}
        </GridCell>
        <GridCell col={4} row={1}>
          <HideWrap data-tid="ContainedSgtinsCount">{codeInfo.containedSgtinsCount || "—"}</HideWrap>
        </GridCell>
        <GridCell col={4} row={1} textAlign="right">
          {onDiscardCode && canBeDiscarded && (
            <DiscardBlockWrap>
              <Button data-tid="DiscardCode" use={"link"} size={"small"} onClick={discard} icon={<Undo />}>
                Отменить сканирование
              </Button>
            </DiscardBlockWrap>
          )}
        </GridCell>
      </Grid>
    </InfoBlock>
  );
});
