import styled from "styles/styled-components";
import { StatusSize, ProgressStage } from "./DeliveryStatusBlock";
import { colors, statusColors } from "styles/colors";
import { mediaWidth } from "styles/media";

interface StatusTitleBlockProps {
  size?: StatusSize;
  collapsible?: boolean;
  isPlanned?: boolean;
}

const largeFontSize = "22px";
const mediumFontSize = "16px";
const defaultFontSize = mediumFontSize;

export const StatusTitleBlock = styled.div<StatusTitleBlockProps>`
  display: inline-flex;
  align-items: center;
  font-size: ${props => (props.size === "large" ? largeFontSize : defaultFontSize)};
  font-weight: ${props => props.size !== "large" && 600};
  line-height: ${props => (props.size === "large" ? "30px" : "25px")};
  cursor: ${props => props.collapsible && "pointer"};
  color: ${props => {
    if (props.collapsible) return colors.darkBlue;
    if (props.isPlanned) return statusColors.grey;
    return "initial";
  }};
`;

export const StatusTitleBlockSM = styled.div<StatusTitleBlockProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
`;

interface StatusIconProps {
  status?: ProgressStage;
  size?: string;
  hasError?: boolean;
  isRecalledByClient?: boolean;
  isRecalled?: boolean;
  isRejected?: boolean;
}

interface IStatusNameProps {
  size?: "sm" | "m";
}

export const StatusName = styled.span<IStatusNameProps>`
  margin-left: ${props => (props.size === "sm" ? "7px" : "14px")};
  margin-right: 3px;
  line-height: 24px;
`;

export const StatusIconArrow = styled.span`
  line-height: 17px;
`;

type TIconSize = "default" | "small";

function getStatusIconBorderColor(props: StatusIconProps, size?: TIconSize) {
  const { hasError, isRecalledByClient, isRecalled, isRejected, status } = props;

  if (hasError) {
    return statusColors.red;
  }

  if (isRejected || isRecalledByClient) {
    return statusColors.orange;
  }

  if (isRecalled) {
    return statusColors.darkGray;
  }

  switch (status) {
    case ProgressStage.Done:
      return statusColors.green;
    case ProgressStage.InProgress:
      if (size === "small") return colors.transparent;
      return statusColors.green;
    case ProgressStage.Planned:
      return statusColors.grey;
    default:
      return colors.transparent;
  }
}

function getStatusIconBgColor(props: StatusIconProps, size?: TIconSize) {
  const { hasError, isRecalled, status, isRejected, isRecalledByClient } = props;
  if (hasError) {
    return statusColors.red;
  }

  if (isRecalledByClient) {
    return statusColors.orange;
  }

  if (isRejected) {
    if (status === ProgressStage.Done) return statusColors.orange;
    return colors.white;
  }

  if (isRecalled) {
    return statusColors.darkGray;
  }

  if (status === ProgressStage.Done) {
    return statusColors.green;
  } else {
    if (size === "small") return statusColors.darkGray;
    return colors.white;
  }
}
const defaultStatusIconSize = "15px";

export const StatusIcon = styled.div<StatusIconProps>`
  display: inline-block;
  width: ${props => props.size || defaultStatusIconSize};
  height: ${props => props.size || defaultStatusIconSize};
  border-radius: ${props => props.size || defaultStatusIconSize};
  border-width: 2px;
  border-style: solid;
  border-color: ${props => getStatusIconBorderColor(props)};
  background-color: ${props => getStatusIconBgColor(props)};
  z-index: 1;
`;

export const StatusIconSM = styled.div<StatusIconProps>`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => getStatusIconBorderColor(props, "small")};
  background-color: ${props => getStatusIconBgColor(props, "small")};
  z-index: 1;
`;

interface StatusBlockContentProps {
  collapsed?: boolean;
}

export const StatusBlockContent = styled.div<StatusBlockContentProps>`
  opacity: ${props => (props.collapsed ? "0" : "1")};
  max-height: ${props => (props.collapsed ? "0" : "auto")};
  display: ${props => props.collapsed && "none"};
`;

interface StatusBlockProps {
  finished: boolean;
}

export const StatusBlock = styled.div<StatusBlockProps>`
  border-left: 2px solid;
  border-left-color: ${statusColors.grey};
  padding-left: 21px;
  margin-left: 6px;
  padding-bottom: 15px;
  min-height: ${props => (props.finished ? "20px" : "40px")};

  ${props =>
    !props.finished &&
    `
    padding-top: 9px;
    margin-top: -9px;
    margin-bottom: -9px;
  `}
`;

export const DeliveryStatusWrapSM = styled.div`
  position: relative;
  margin-top: 7px;
`;

interface CollapsedTextBlock {
  additionalMargin: boolean;
}

export const CollapsedTextBlock = styled.div<CollapsedTextBlock>`
  margin-top: ${props => (props.additionalMargin ? "5px" : "0")};
`;

export const DeliveryStatusWrap = styled.div`
  position: relative;

  &:last-of-type {
    ${StatusBlock} {
      border-left: none;
    }
  }
`;

export const ChildContent = styled.div`
  margin-left: 12px;
`;

export const ErrorText = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 750px;
  white-space: pre-line;
  line-height: 20px;
  color: ${props => props.theme.textColors.darkRed};
  margin-left: 1px;
  padding-top: 8px;
  margin-bottom: 5px;

  @media (max-width: ${mediaWidth.notebook}) {
    max-width: 650px;
  }
`;

export const ErrorWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LinkWrap = styled.div`
  white-space: nowrap;
  margin-right: 15px;
`;

export const BillingErrorText = styled.div`
  max-width: 650px;
`;
