import { RouteName } from "./RouteNames";

export enum SettingsSubRoute {
  Departments = "departments",
  Employees = "employees",
  Requisites = "requisites",
  Registrators = "registrators",
}

class SettingsRouteParams {
  type?: SettingsSubRoute;
}

export class SettingsRoute extends SettingsRouteParams {
  public static readonly baseRoute = `/${RouteName.Settings}`;
  public static readonly route = `${SettingsRoute.baseRoute}/:type?`;
  public static readonly title = "Настройки";

  public static getHref(type?: SettingsSubRoute | string): string {
    return `${this.baseRoute}/${type || ""}`;
  }
}
