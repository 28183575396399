import { RouteName } from "./RouteNames";

export enum PackingSubRoute {
  unpacking = "unpacking",
  extraction = "extraction",
}

class PackingRouteParams {
  operationId?: string;
  type?: PackingSubRoute;
}

export class PackingRoute extends PackingRouteParams {
  public static readonly title = "Операции\n\rс агрегатами";
  public static readonly baseRoute = `/${RouteName.Pack}`;
  public static readonly typeRoute = `/${RouteName.Pack}/:type`;
  public static readonly route = `${PackingRoute.baseRoute}/:type?/:operationId?`;

  public static readonly unpackingBaseRoute = `${PackingRoute.baseRoute}/${PackingSubRoute.unpacking}`;
  public static readonly unpackingRoute = `${PackingRoute.baseRoute}/${PackingSubRoute.unpacking}/:operationId`;

  public static readonly extractionBaseRoute = `${PackingRoute.baseRoute}/${PackingSubRoute.extraction}`;
  public static readonly extractionRoute = `${PackingRoute.baseRoute}/${PackingSubRoute.extraction}/:operationId`;

  public static getHref(id?: string, type?: PackingSubRoute): string {
    let route = this.baseRoute;

    if (type) {
      route += `/${type}`;
    }

    if (id) {
      route += `/${id}`;
    }

    return route;
  }
}
