import React from "react";
import { observer } from "mobx-react";
import { RegistratorEditorVM } from "./RegistratorEditorVM";
import { Modal, Input, Checkbox, Button, Tooltip, PasswordInput, Link } from "components";
import { AddButtonWrap, ErrorBlock, HelpText } from "./RegistratorEditor.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { EditorTitle } from "../../EmployeesPage/EmployeeEditor/EmployeeEditor.styles";
import { UserStore } from "stores/UserStore";
import { DepartmentComboBox } from "./DepartmentComboBox";
import { SettingsStore } from "stores/SettingsStore";
import { EmployeeTokenInput } from "./EmployeeTokenInput";
import { RegistratorEditModel, RegistratorSettingsModel } from "typings/server";
import HelpBookIcon from "@skbkontur/react-icons/HelpBook";

interface RegistratorEditorProps {
  onClose(): void;
  onSave(model: RegistratorEditModel, isNew: boolean): Promise<void>;
  selectedRegistrator?: RegistratorSettingsModel;
}

interface RegistratorEditorInternalProps extends RegistratorEditorProps {
  vm: RegistratorEditorVM;
}

const RegistratorEditorInternal = observer(({ vm, onClose, selectedRegistrator }: RegistratorEditorInternalProps) => {
  const cols = "120px 1fr";

  const renderLoginError = () => vm.errors.get("requiredLogin") || vm.errors.get("incorrectLoginOrPassword");

  const renderPasswordError = () => vm.errors.get("requiredPassword") || vm.errors.get("incorrectLoginOrPassword");

  const renderIpAddressError = () => vm.errors.get("requiredIpAddress") || vm.errors.get("invalidIpAddress");

  const renderDepartmentError = () => vm.errors.get("requiredDepartment");

  return (
    <Modal data-tid="RegistratorEditor" ignoreBackgroundClick width={"650px"} onClose={onClose}>
      <Modal.Header>
        {vm.selectedRegistrator ? "Редактирование регистратора выбытия" : "Добавление регистратора выбытия"}
      </Modal.Header>
      <Modal.Body>
        <HelpText>
          Для добавления и привязки регистратора выбытия воспользуйтесь инструкцией
          <br />
          <Link
            icon={<HelpBookIcon />}
            href="https://support.kontur.ru/pages/viewpage.action?pageId=63307838"
            target="_blank"
          >
            Как добавить регистратор выбытия
          </Link>
        </HelpText>
        {vm.noConnectionToRegistrator && (
          <ErrorBlock>
            <h4>Нет соединения с регистратором выбытия</h4>
            Проверьте правильно ли указан IP-адрес, по которому доступен регистратор выбытия. Устройство должно быть
            включено и подключено к локальной сети. Убедитесь, что запущена утилита для регистратора.
          </ErrorBlock>
        )}
        <Grid cols={cols}>
          <GridCell col={1} row={1} padding={"15px 4px 8px"} margin={"0 0 30px 0"}>
            <EditorTitle>Название</EditorTitle>
          </GridCell>
          <GridCell col={2} row={1}>
            <Input
              data-tid="Name"
              value={vm.name}
              onValueChange={vm.setName}
              placeholder="Название регистратора выбытия"
            />
          </GridCell>
          <GridCell col={1} row={2} padding={"15px 4px 8px"}>
            <EditorTitle required>IP-адрес</EditorTitle>
          </GridCell>
          <GridCell col={2} row={2}>
            <Tooltip render={renderIpAddressError} pos="right middle">
              <Input
                data-tid="IpAddress"
                value={vm.ipAddress}
                onValueChange={vm.setIpAddress}
                placeholder="10.58.10.253:8080"
                error={!!renderIpAddressError()}
              />
            </Tooltip>
          </GridCell>
          <GridCell col={1} row={3} padding={"15px 4px 8px"}>
            <EditorTitle required>Логин</EditorTitle>
          </GridCell>
          <GridCell col={2} row={3}>
            <Tooltip render={renderLoginError} pos="right middle">
              <Input
                data-tid="Login"
                value={vm.login}
                onValueChange={vm.setLogin}
                placeholder="Логин регистратора выбытия"
                error={!!renderLoginError()}
              />
            </Tooltip>
          </GridCell>
          <GridCell col={1} row={4} padding={"15px 4px 8px"} margin={"0 0 30px 0"}>
            <EditorTitle required>Пароль</EditorTitle>
          </GridCell>
          <GridCell col={2} row={4}>
            <Tooltip render={renderPasswordError} pos="right middle">
              <PasswordInput
                data-tid="Password"
                value={vm.password}
                onValueChange={vm.setPassword}
                placeholder="Пароль регистратора выбытия"
                error={!!renderPasswordError()}
              />
            </Tooltip>
          </GridCell>
          <GridCell col={1} row={5} padding={"15px 4px 8px"}>
            <EditorTitle required>Подразделение</EditorTitle>
          </GridCell>
          <GridCell col={2} row={5}>
            <DepartmentComboBox
              department={vm.department}
              setDepartment={vm.setDepartment}
              availableDepartments={vm.availableDepartments ?? []}
              renderError={renderDepartmentError}
            />
          </GridCell>
          <GridCell col={1} row={6}>
            <EditorTitle>Сотрудники</EditorTitle>
          </GridCell>
          <GridCell col={2} row={6}>
            <Checkbox data-tid="ToggleIsAllEmployees" checked={vm.isAllEmployees} onValueChange={vm.setIsAllEmployees}>
              Все текущие сотрудники подразделения
            </Checkbox>
            {!vm.isAllEmployees && (
              <EmployeeTokenInput
                allEmployees={vm.availableEmployees}
                selectedEmployees={vm.selectedEmployees}
                setSelectedEmployees={vm.setSelectedEmployees}
              />
            )}
          </GridCell>
        </Grid>
      </Modal.Body>
      <Modal.Footer panel>
        <AddButtonWrap>
          <Button data-tid="Submit" use="primary" onClick={vm.save} size="large" loading={vm.isLoading}>
            {selectedRegistrator ? "Сохранить" : "Добавить"}
          </Button>
        </AddButtonWrap>
        <Button data-tid="Cancel" onClick={onClose} size="large">
          Отменить
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export const RegistratorEditor = React.memo(
  (props: RegistratorEditorProps) => {
    const vm = new RegistratorEditorVM(SettingsStore, UserStore, props.onSave, props.selectedRegistrator);

    return <RegistratorEditorInternal vm={vm} {...props} />;
  },
  () => true
);
