//getEnding(6, 'яблоко', 'яблока', 'яблок')
export function getEnding(count: number, one: string, two: string, five: string): string {
  count = Math.abs(count);
  count %= 100;
  if (count >= 5 && count <= 20) {
    return five;
  }
  count %= 10;
  if (count === 1) {
    return one;
  }
  if (count >= 2 && count <= 4) {
    return two;
  }
  return five;
}
