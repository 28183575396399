import { RouteName } from "./RouteNames";

export const RootRoute = "/";
export const WelcomeRoute = `/${RouteName.Welcome}`;

export { AcceptanceRoute } from "./AcceptanceRoute";
export { ShipmentRoute } from "./ShipmentRoute";
export { SettingsRoute } from "./SettingsRoute";
export { PackingRoute } from "./PackingRoute";
export { GreetingRoute } from "./GreetingRoute";
export { TransferRoute } from "./TransferRoute";
export { CirculationRoute } from "./CirculationRoute";
