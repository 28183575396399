import styled from "styles/styled-components";

interface EllipsisProps {
  width: string;
}

export const Ellipsis = styled.div<EllipsisProps>`
  display: inline-block;
  width: ${props => props.width};
  vertical-align: text-top;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const NoEllipsis = styled.span`
  white-space: nowrap;
`;
