import {
  DeliveryItemModel,
  DeliveryModel,
  DeliveryStatus,
  ErrorInfoModel,
  StepInfoModel,
  UtdPositionModel,
  DeliveryType,
} from "typings/server";
import { ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStage, mapDeliveryToAcceptance } from "Common/DeliveryDictionary";
import { enumToStringArray } from "helpers/enum";
import { getUniqueByKeys } from "helpers/array";
import { CodeModelExtended } from "../Code/CodeModelExtended";
import { UtdPositionModelExtended } from "./ItemModel/UtdPositionModelExtended";
import { DeliveryItemModelExtended } from "./ItemModel/DeliveryItemModelExtended";
import { IMappedStepInfoModel, IStage } from "./shared";
import { getGtinFromSgtin } from "helpers/codes";

interface IDeliveryStage {
  acceptanceStage?: DeliveryStage;
  name: string;
  progress: ProgressStage;
  completionDate?: string;
  completionUserName?: string;
  errorInfo?: ErrorInfoModel;
}

export class DeliveryActions extends DeliveryModel {
  constructor(delivery: DeliveryModel) {
    super();
    Object.assign(this, delivery);
  }

  processItems(
    goods: Partial<DeliveryItemModel>[],
    codeGroup: Record<string, CodeModelExtended[]>,
    options?: { showGtin?: boolean }
  ): DeliveryItemModelExtended[] {
    const namedItems = this.getNamedItems(goods, options && options.showGtin);
    const namedItemsWithStatusReason = this.getItemsWithStatusReason(namedItems);

    // соотносим items по имени и статусу, и причине в статусе
    const uniqueItems = getUniqueByKeys(namedItemsWithStatusReason, ["name", "status", "reason"]);
    // @ts-ignore
    return uniqueItems.map((item: DeliveryItemModel) => {
      const codes = codeGroup[item.name as string].filter(codeModel => {
        return (
          codeModel.status === item.status && codeModel.statusReason === (item.statusInfo && item.statusInfo.reason)
        );
      });
      return new DeliveryItemModelExtended(item, codes);
    });
  }

  private getItemsWithStatusReason(items: Partial<DeliveryItemModel>[]) {
    return items.map(item => {
      if (item.statusInfo && item.statusInfo.reason) {
        return { ...item, reason: item.statusInfo.reason };
      }
      return item;
    });
  }

  processUtdPositions(
    utdPositions: UtdPositionModel[],
    codeGroup: Record<string, CodeModelExtended[]>
  ): UtdPositionModelExtended[] {
    return utdPositions.map(item => new UtdPositionModelExtended(item, codeGroup[item.id]));
  }

  /**
   * группировка кодов по имени товара, либо по id
   * т.к. для одного товара 2 кода - 2 элемента массива с одном именем/id
   * @param items
   * @param options
   * isAllScanned
   * showGtin - показывать gtin вместо sgtin
   */
  getGroupedCodes(
    items: Partial<DeliveryItemModel>[],
    options: {
      groupBy: string;
      isAllScanned?: boolean;
      showGtin?: boolean;
    }
  ): Record<string, CodeModelExtended[]> {
    // @TODO важный кусок кода - покрыть тестом!
    const { groupBy, isAllScanned, showGtin } = options;
    const codesGroup: Record<string, CodeModelExtended[]> = {};
    const namedItems = this.getNamedItems(items, showGtin);

    namedItems.forEach(item => {
      const isScanned = isAllScanned || this.scannedCodes?.includes(item.code!) || false;
      // @ts-ignore
      const itemGroupParam = item[groupBy];
      const statusReason = item.statusInfo && item.statusInfo.reason;
      const codeModel = new CodeModelExtended({
        code: item.code!,
        scanned: isScanned,
        codeMdlpStatus: item.codeStatusInfo?.mdlpStatus,
        codeSyncStatus: item.codeStatusInfo?.syncStatus,
        status: item.status,
        index: codesGroup[itemGroupParam]?.length ?? -1 + 1,
        statusReason,
        itemsCount: item.itemsCount,
        dataMatrix: item.dataMatrix,
        part: item.part,
        totalParts: item.totalParts,
      });
      if (codesGroup[itemGroupParam]) codesGroup[itemGroupParam].push(codeModel);
      else codesGroup[itemGroupParam] = [new CodeModelExtended(codeModel)];
    });
    return codesGroup;
  }

  getNamedItems(items: Partial<DeliveryItemModel>[], showGtin: boolean = false) {
    return items.map(item => {
      const code = item.code as string;
      const gtin = showGtin ? getGtinFromSgtin(code) : "";
      return { ...item, name: item.name || gtin || code, realName: item.name };
    });
  }

  /**
   * добавляем к stepsInfo информацию о шаге приемки
   * @param steps
   */
  mapSteps(steps: { [key in DeliveryStatus]?: StepInfoModel }): IMappedStepInfoModel[] {
    return Object.entries(steps).map(([key, stepInfo]) => {
      const deliveryStatus = DeliveryStatus[key as DeliveryStatus];
      return {
        ...(stepInfo as StepInfoModel),
        key,
        acceptanceStage: mapDeliveryToAcceptance[deliveryStatus],
      };
    });
  }

  /**
   * получаем из stepsInfo шаги операции для отображения в интерфейсе
   * @param mappedSteps
   * @param names
   * @param options
   * isCompleted - все этапы пройдены
   * isPlanned - этапы только запланированы (например этапы отмены)
   */
  getStages(
    mappedSteps: IMappedStepInfoModel[],
    names: Record<DeliveryStage | string, string>,
    options?: {
      isCompleted?: boolean;
      isPlanned?: boolean;
    }
  ): Record<DeliveryStage | string, IDeliveryStage> {
    const isCompleted = options && options.isCompleted;
    const isPlanned = options && options.isPlanned;

    const currentStage = this.getCurrentStage(mappedSteps);

    const stages: Record<DeliveryStage | string, IDeliveryStage> = Object.create({});

    const arr = enumToStringArray(DeliveryStage);
    arr.forEach(stage => {
      const deliveryStage = DeliveryStage[stage] as any;
      const stepInfo: IMappedStepInfoModel = mappedSteps.reduce((info, item) => {
        // информация о шаге по самой поздней дате
        if (item.acceptanceStage === deliveryStage) {
          if ((info && item.completionDate > info.completionDate) || Object.entries(info).length === 0) {
            info = item;
          }
        }
        return info;
      }, {} as IMappedStepInfoModel);
      stages[deliveryStage] = {
        name: names[deliveryStage],
        progress: this.getProgress(deliveryStage, currentStage, stepInfo, isCompleted, isPlanned),
        // @ts-ignore ошибка, что поле будет переписано. т.к. всё работает, оставляю так
        acceptanceStage: deliveryStage,
        ...stepInfo,
      };
    });

    return stages;
  }

  /**
   * если текущий статус поставки failed - возвращаем acceptanceStage на котором произошла ошибка
   * @param mappedSteps
   */
  getCurrentStage(mappedSteps: IMappedStepInfoModel[]): DeliveryStage {
    if (this.status === DeliveryStatus.Failed) {
      const stepInfo = mappedSteps.find(step => step.errorInfo);
      return stepInfo ? stepInfo.acceptanceStage : mapDeliveryToAcceptance[this.status];
    }
    return mapDeliveryToAcceptance[this.status];
  }

  getProgress(
    stage: DeliveryStage,
    currentStage: DeliveryStage,
    stepInfo?: Partial<IMappedStepInfoModel>,
    isCompleted?: boolean,
    isPlanned?: boolean
  ): ProgressStage {
    // @TODO обязательно покрыть это тестом, странная комбинаторика ниже - страдай
    if (this.type === DeliveryType.Destruction && !!this.childDeliveries.length) {
      // Если для уничтожения появилась дочерняя операция - основную операцию помечаем как выполненную
      if (
        stage === DeliveryStage.Signing ||
        stage === DeliveryStage.Sending ||
        stage === DeliveryStage.WaitingForCounterparty
      ) {
        return ProgressStage.Done;
      }
    }

    if (stage === DeliveryStage.Shipped || isCompleted) {
      return ProgressStage.Done;
    }

    if (isPlanned) {
      return ProgressStage.Planned;
    }

    if (stepInfo && stepInfo.completionDate) {
      if (currentStage === stage && currentStage !== DeliveryStage.Done) {
        return ProgressStage.InProgress;
      }
      return ProgressStage.Done;
    }
    if (currentStage === DeliveryStage.Done) {
      return ProgressStage.Done;
    }
    if (stage === DeliveryStage.Processing && currentStage === DeliveryStage.Shipped) {
      return ProgressStage.InProgress;
    }

    if (currentStage > stage) {
      return ProgressStage.Done;
    } else if (currentStage === stage) {
      return ProgressStage.InProgress;
    }
    return ProgressStage.Planned;
  }

  setNextStage(
    stages: Record<DeliveryStage | string, IStage>,
    stageName: DeliveryStage,
    stage: Partial<IStage>
  ): Record<DeliveryStage | string, IStage> {
    stages[stageName] = { ...stages[stageName], progress: ProgressStage.Done, ...stage };
    // next stage
    stages[stageName + 1] = {
      ...stages[stageName + 1],
      progress: ProgressStage.InProgress,
    };
    return { ...stages };
  }

  updateStepInfo(
    stepsInfo: { [key in DeliveryStatus]: StepInfoModel },
    info: { status: DeliveryStatus; completionDate?: string; userName: string }
  ) {
    const { status, completionDate, userName } = info;

    stepsInfo[status] = stepsInfo[status] || Object.create({});
    stepsInfo[status].completionDate = completionDate || new Date().toISOString();
    if (userName) stepsInfo[status].completionUserName = userName;
    return { ...stepsInfo };
  }
}
