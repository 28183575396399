import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock, GoodsWrapperBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { DateAndFIOWrap, GoodsTableWrap, RecalledWrap } from "Common/Stages/Stages.styles";
import { ReverseAcceptancePageVM } from "../ReverseAcceptancePageVM";
import { ReversedAcceptanceStagesPart } from "./ReverseAcceptanceStagesPart";
import { RejectedCodes } from "Common/Stages/CodesStatuses/CodesStatuses";
import { GoodsTable } from "Common/GoodsTable/GoodsTable/GoodsTable";
import { GoodsForm } from "../GoodsForm/GoodsForm";

interface IAcceptanceStagesProps {
  vm: ReverseAcceptancePageVM;
}

export const ReverseAcceptanceStages: React.FC<IAcceptanceStagesProps> = observer(({ vm }: IAcceptanceStagesProps) => {
  useEffect(() => {
    // По дефолту разворачиваем стадию приемки
    vm.onCollapseDelivery(`ProcessingStage_${vm.delivery.id}`, !vm.isPartiallyFailed && !vm.isResended);
  }, []);

  return (
    <DeliveryStagesBlock>
      {!vm.isResended && (
        <ReversedAcceptanceStagesPart
          data-tid="ShippedStage"
          vm={vm}
          delivery={vm.delivery}
          // Для процессинга мы показываем неразворачиваемый черновик
          hideChildren={vm.delivery.status === DeliveryStatus.Processing}
          overrideFields={{
            name: "Создание черновика",
            size: "medium",
            status: vm.stages[DeliveryStage.Shipped].progress,
            error: vm.stages[DeliveryStage.Shipped].errorInfo,
            collapsed:
              vm.delivery.status !== DeliveryStatus.Processing &&
              !vm.isDeliveryOpenedState.get(`ShippedStage_${vm.delivery.id}`),
            onCollapse:
              vm.delivery.status !== DeliveryStatus.Processing
                ? () => vm.onCollapseDelivery(`ShippedStage_${vm.delivery.id}`)
                : undefined,
            alwaysVisibleComp: (
              <DateAndFIOWrap data-tid="CompleteInfo">
                {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Draft])}
              </DateAndFIOWrap>
            ),
            isRecalled: false,
            isRejected: false,
          }}
        />
      )}

      {!vm.isPartiallyFailed && (
        <ReversedAcceptanceStagesPart
          data-tid="ProcessingStage"
          delivery={vm.delivery}
          vm={vm}
          overrideFields={{
            name: vm.processingStepName,
            collapsed: !vm.isDeliveryOpenedState.get(`ProcessingStage_${vm.delivery.id}`),
            onCollapse: () => vm.onCollapseDelivery(`ProcessingStage_${vm.delivery.id}`),
            isRejected: false,
            isRecalled: false,
          }}
        />
      )}

      {vm.delivery.childDeliveries.map(child => (
        <ReversedAcceptanceStagesPart
          data-tid="ChildStage"
          delivery={child}
          vm={vm}
          key={child.id}
          isPartial={child.allCodesLen !== vm.delivery.allCodesLen}
          parentDelivery={vm.delivery}
          overrideFields={{
            collapsed: !vm.isDeliveryOpenedState.get(child.id),
            onCollapse: () => vm.onCollapseDelivery(child.id),
          }}
        />
      ))}

      {vm.showWaitingForCounterpartyStage && (
        <DeliveryStatusBlock
          data-tid="WaitingForCounterpartyStage"
          status={vm.stages[DeliveryStage.WaitingForCounterparty].progress}
          name={vm.waitingForCounterpartyStageName}
          size={vm.getTitleSize(vm.stages[DeliveryStage.WaitingForCounterparty])}
          error={vm.stages[DeliveryStage.WaitingForCounterparty].errorInfo}
          isRecalled={false}
          isRejected={false}
          alwaysVisibleComp={
            <DateAndFIOWrap data-tid="CompleteInfo">
              {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.WaitingForCounterparty])}
            </DateAndFIOWrap>
          }
        >
          {vm.delivery.isRejected && (
            <GoodsWrapperBlock>
              <GoodsForm
                errors={vm.errors}
                rejectDate={vm.rejectDate}
                setRejectDate={vm.setRejectDate}
                maxDate={vm.maxDate}
                minDate={vm.minDate}
              />
              <GoodsTableWrap>
                <RecalledWrap>
                  <RejectedCodes vm={vm} delivery={vm.delivery} rejectedCodesLen={vm.delivery.rejectedCodesLen} />
                </RecalledWrap>
                <GoodsTable goods={vm.delivery.items} additionalInfo={vm.additionalInfo} />
              </GoodsTableWrap>
            </GoodsWrapperBlock>
          )}
        </DeliveryStatusBlock>
      )}

      {vm.showCompleteStage && (
        <DeliveryStatusBlock
          data-tid="DoneStage"
          status={vm.stages[DeliveryStage.Done].progress}
          name={vm.doneBlockTitle}
          error={vm.stages[DeliveryStage.Done].errorInfo}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
        />
      )}
    </DeliveryStagesBlock>
  );
});
