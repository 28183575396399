import React, { useState } from "react";
import { Button, Modal } from "components";
import { Footer, FooterBtnWrapper } from "components/Layout/Footer.styles";
import { ErrorModalImg, ErrorModalImgWrap, ModalText } from "./ShipmentConfirmations.styles";

interface IShipmentConfirmationProps {
  onClose(): void;
  onAccept(): void;
  isLoading: boolean;
}

export const ShipmentConfirmation: React.FC<IShipmentConfirmationProps> = (props: IShipmentConfirmationProps) => {
  const [error, setError] = useState(false);

  const onClose = () => props.onClose();

  const onAccept = async () => {
    try {
      await props.onAccept();
      onClose();
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Modal data-tid="ShipmentConfirmationModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>Подтверждение отгрузки</Modal.Header>
      <Modal.Body>
        <ModalText>
          {error ? (
            <ErrorModalImgWrap>
              <ErrorModalImg />
              Не смогли завершить отгрузку. Закройте окно и попробуйте еще раз позднее
            </ErrorModalImgWrap>
          ) : (
            <>
              В ИС МДЛП будут отправлены сведения, полностью совпадающие со сведениями покупателя.
              <p>Обработка документа будет завершена.</p>
            </>
          )}
        </ModalText>
      </Modal.Body>
      <Footer>
        <FooterBtnWrapper>
          {!error && (
            <Button data-tid="Submit" use="primary" onClick={onAccept} size={"large"} loading={props.isLoading}>
              Подтвердить
            </Button>
          )}
          <Button data-tid="Cancel" onClick={onClose} size={"large"}>
            Отмена
          </Button>
        </FooterBtnWrapper>
      </Footer>
    </Modal>
  );
};
