export const drugsApi = "/api/drugs/v1";

export function isTestEnv() {
  return process.env["IS_TEST_ENV"];
}

export function setTestEnvCookies() {
  document.cookie = "auth.sid=localUserSid";
}

export function prepareEnvironment() {
  if (isTestEnv()) {
    setTestEnvCookies();
  }
}
