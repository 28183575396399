import { Link, Tooltip } from "components";
import React from "react";
import { directShipmentInstruction, MDLPInstructionHref471, MDLPInstructionHref417 } from "StringResx";
import { AcceptusType } from "typings/server";
import { HelpIconWrap, TipBlock, TipIconWrap, TipText } from "features/Acceptance/MainPage/UtdTitle/UtdTitle.styles";
import { textColors } from "styles/colors";
import { Warning, HelpBook, HelpDot } from "@skbkontur/react-icons";

interface UtdTitleProps {
  acceptusType: AcceptusType;
}

export function UtdTitle({ acceptusType }: UtdTitleProps) {
  const content = getUtdContent();
  return (
    <span>
      {getUtdTitle()}
      {content && (
        <HelpIconWrap>
          <Tooltip
            trigger={"click"}
            render={content}
            pos={"right top"}
            allowedPositions={["right top", "bottom center", "right middle"]}
          >
            <HelpDot />
          </Tooltip>
        </HelpIconWrap>
      )}
    </span>
  );

  function renderStateDispatchInfo() {
    return (
      <TipText maxWidth="325px">
        Применяется в случаях смены владельца товаров в рамках государственного лекарственного обеспечения (471 схема).
        <TipBlock>
          <TipIconWrap color={textColors.warningText}>
            <Warning />
          </TipIconWrap>
          <span>
            Изначально товары должны быть получены с источником финансирования: средства <b>федерального</b> или&nbsp;
            <b>регионального</b>&nbsp;бюджета.
          </span>
        </TipBlock>
        <Link href={MDLPInstructionHref471} target="_blank">
          <TipBlock>
            <TipIconWrap>
              <HelpBook />
            </TipIconWrap>
            Инструкция по возврату приостановленного товара поставщику
          </TipBlock>
        </Link>
      </TipText>
    );
  }

  function renderDirectShipmentInfo() {
    return (
      <TipText>
        Применяется, когда отправитель первый формирует сведения в ИС МДЛП.
        <TipBlock>
          <Link href={directShipmentInstruction} icon={<HelpDot />} target="_blank">
            Отгрузка по прямому акцепту
          </Link>
        </TipBlock>
      </TipText>
    );
  }

  function renderSuspendedReturnInfo() {
    return (
      <TipText maxWidth="280px">
        Применяется, когда на балансе обнаружен приостановленный товар. Такой товар необходимо вернуть поставщику.
        <Link href={MDLPInstructionHref417} target="_blank">
          <TipBlock>
            <TipIconWrap>
              <HelpBook />
            </TipIconWrap>
            Инструкция по обороту товаров в рамках государственного лекарственного обеспечения
          </TipBlock>
        </Link>
      </TipText>
    );
  }

  function getUtdTitle() {
    switch (acceptusType) {
      case AcceptusType.Direct:
        return "Отгрузка по прямому акцепту";
      case AcceptusType.Eeu:
        return "Новая отгрузка в страну EАЭС";
      case AcceptusType.StateDispatch:
        return (
          <>
            Смена владельца в рамках государственного <br /> лекарственного обеспечения
          </>
        );
      case AcceptusType.SuspendedReturn:
        return "Возврат приостановленного товара поставщику";
      case AcceptusType.UnregisteredCounteragent:
        return "Отгрузка незарегестрированному в ИС МДЛП получателю";
      case AcceptusType.UnregisteredDepartment:
        return "Отгрузка на незарегестированное в ИС МДЛП место деятельности";
      default:
        throw new Error("Неподдерживаемый тип отгрузки");
    }
  }

  function getUtdContent() {
    switch (acceptusType) {
      case AcceptusType.Direct:
        return renderDirectShipmentInfo;
      case AcceptusType.StateDispatch:
        return renderStateDispatchInfo;
      case AcceptusType.SuspendedReturn:
        return renderSuspendedReturnInfo;
      case AcceptusType.Eeu:
      case AcceptusType.UnregisteredCounteragent:
      case AcceptusType.UnregisteredDepartment:
        return null;
      default:
        throw new Error("Неподдерживаемый тип отгрузки");
    }
  }
}
