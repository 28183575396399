import React from "react";
import { observer } from "mobx-react";
import { Checkbox, Token, TokenInput, Tooltip } from "components";
import { EditorText, TokenWrapper } from "./EmployeeEditor.styles";
import { TextGrey } from "styles/shared.styles";
import { DepartmentModel } from "typings/server";
import { TokenInputType } from "@skbkontur/react-ui";

interface IEmployeeDepartmentsProps {
  allDepartmentsChecked?: boolean;
  isAdmin?: boolean;
  availableDepartments: DepartmentModel[];
  allDepartments: DepartmentModel[];
  toggleAllDepartments(): void;
  changeAvailableDepartments(deps: DepartmentModel[]): void;
  error?: string;
}

export const EmployeeDepartments: React.FC<IEmployeeDepartmentsProps> = observer((props: IEmployeeDepartmentsProps) => {
  const { allDepartments, error, isAdmin, availableDepartments, toggleAllDepartments, allDepartmentsChecked } = props;

  if (isAdmin) {
    return <>Администратор имеет доступ ко всем подразделениям</>;
  }

  const getItems = (q: string) => {
    const query = q.toLowerCase();
    return Promise.resolve(
      allDepartments.filter(dep => {
        const name = (dep.name || dep.address || "").toLowerCase();
        return name.includes(query);
      })
    );
  };

  const renderItem = (item: DepartmentModel) => item.name || item.address;

  const onChangeDeps = (deps: DepartmentModel[]) => props.changeAvailableDepartments(deps);

  const renderError = () => error;

  return (
    <>
      <Checkbox checked={allDepartmentsChecked} onValueChange={toggleAllDepartments}>
        Все подразделения
      </Checkbox>

      {allDepartmentsChecked ? (
        <EditorText>Сотрудник имеет доступ ко всем подразделениям</EditorText>
      ) : (
        <>
          <TokenWrapper>
            <Tooltip trigger={"hover"} render={renderError}>
              <TokenInput<DepartmentModel>
                data-tid="EmployeeDepartments"
                type={TokenInputType.WithReference}
                width={"100%"}
                error={!!error}
                getItems={getItems}
                onValueChange={onChangeDeps}
                selectedItems={availableDepartments}
                valueToString={renderItem}
                renderItem={renderItem}
                renderValue={renderItem}
                renderToken={(item, { isActive, onClick, onRemove }) => (
                  <Token key={item.id} isActive={isActive} onClick={onClick} onRemove={onRemove}>
                    {item.name || item.address}
                  </Token>
                )}
              />
            </Tooltip>
          </TokenWrapper>
          <TextGrey>Сотрудник имеет доступ к выбранным подразделениям</TextGrey>
        </>
      )}
    </>
  );
});
