export function getUniqueBy<T extends { [key: string]: any }>(arr: T[], key: string): T[] {
  return arr.filter((item, index) => {
    const itemInArr = arr.find(i => i[key] === item[key]);
    return itemInArr ? arr.indexOf(itemInArr) === index : false;
  });
}

export function getUniqueByKeys<T extends { [key: string]: any }>(arr: T[], keys: string[]): T[] {
  return arr.filter((item, index) => {
    const itemInArr = arr.find(i => keys.every(key => i[key] === item[key]));
    return itemInArr ? arr.indexOf(itemInArr) === index : false;
  });
}

/**
 * "property" - direct sorting
 * "-property" - reverse sorting
 * @param property
 */
export function getSortByFunc(property: string) {
  const isReversed = property[0] === "-";
  const prop = isReversed ? property.slice(1) : property;
  const ratio = isReversed ? -1 : 1;

  return (a: any, b: any) => {
    // a меньше b по некоторому критерию сортировки
    if (a[prop] < b[prop]) {
      return -1 * ratio;
    }
    // a больше b по некоторому критерию сортировки
    if (a[prop] > b[prop]) {
      return 1 * ratio;
    }
    // a должно быть равным b
    return 0;
  };
}
