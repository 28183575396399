import React, { memo } from "react";
import { GridCell } from "components/Grid/Grid.styles";
import { DateTimePicker } from "components";
import { EditorLabel } from "../Editor/EditorModal.styles";

interface Props {
  documentDate: string;
  setDocumentDate: (v?: string) => void;
  getError: () => string | undefined;
  row: number;
}

export const DocumentDate = memo((props: Props) => {
  const maxDate = new Date().toISOString();
  const { documentDate, row, setDocumentDate, getError } = props;
  return (
    <>
      <GridCell col={1} row={row} padding="15px 4px 8px">
        <EditorLabel required>Дата документа</EditorLabel>
      </GridCell>
      <GridCell col={2} row={row}>
        <DateTimePicker
          data-testid="DocumentDate"
          value={documentDate}
          onChange={setDocumentDate}
          getReportDateError={getError}
          maxDate={maxDate}
          withoutTime
        />
      </GridCell>
    </>
  );
});
