import React, { PropsWithChildren } from "react";
import { DropzoneRoot } from "./Dropzone.styles";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

interface DropzoneProps {
  accept?: string | string[];
  multiple?: boolean;
  maxSize?: number;
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
}

export function Dropzone(props: PropsWithChildren<DropzoneProps>) {
  const { children, onDrop, accept, multiple, maxSize } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    maxSize,
    accept,
  });

  return (
    <DropzoneRoot data-tid="Dropzone" {...getRootProps()} isDragActive={isDragActive}>
      <input data-tid="HiddenInput" {...getInputProps()} />
      {children}
    </DropzoneRoot>
  );
}
