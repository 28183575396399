import React from "react";
import { observer } from "mobx-react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { Wrapper, Content, Footer, CertificateWrapper, ErrorWrap } from "./GreetingsIndexPage.styles";
import { GreetingRoute } from "routes";
import { GreetingStep } from "routes/RouteNames";
import { Button, Gapped, Loader, RouterLink, Toast } from "components";
import { ProgressMenu } from "./ProgressMenu/ProgressMenu";
import { SettingsStore } from "stores/SettingsStore";
import { UserStore } from "stores/UserStore";
import { SettingsPageVM } from "../Settings/SettingsPageVM";
import { SignatureStore } from "stores/SignatureStore";
import { RequisitesPage } from "../Settings/RequisitesPage/RequisitesPage";
import { CustomCertErrors } from "../CertificatesList/CryptoProErrors";
import { CertificateListControl } from "@skbkontur/certificates-list";
import { CertificateListVM } from "../CertificatesList/CertificateListVM";
import { DepartmentsSubPage } from "./DepartmentsSubPage";

const items = {
  requisites: {
    title: "Реквизиты",
    url: GreetingRoute.getHref(),
  },
  authorization: {
    title: "Авторизация",
    url: GreetingRoute.getHref(GreetingStep.Authorization),
  },
  department: {
    title: "Подразделение",
    url: GreetingRoute.getHref(GreetingStep.Department),
  },
};

export const GreetingIndexPage = withRouter(
  observer((props: RouteComponentProps) => {
    const vm = SettingsPageVM.getInstance(SettingsStore, UserStore, SignatureStore);
    const { history } = props;

    const onSaveRequisites = async () => {
      await vm.onSaveRequisites();
      history.push(items.authorization.url);
      Toast.push("Сохранено");
    };

    const onSing = async () => {
      if (vm.certificate) {
        await vm.auth(vm.certificate);
        history.push(items.department.url);
        Toast.push("Доступ в ИС МДЛП открыт на 30 минут");
      }
    };

    return (
      <Wrapper>
        <Content>
          <h2>Начало работы с маркировкой</h2>
          <ProgressMenu items={Object.values(items)} />
          <Switch>
            <Route exact path={items.requisites.url}>
              <RequisitesPage vm={vm} withoutFooter />
            </Route>
            <Route exact path={items.authorization.url}>
              {vm.errors.has("auth") && (
                <ErrorWrap>
                  <h4>Не удалось авторизоваться в ИС МДЛП</h4>
                  1. <RouterLink to={items.requisites.url}>Проверьте реквизиты</RouterLink> из личного кабинета ИС МДЛП
                  <br />
                  2. Выберите сертификат, с которым регистрировались в ИС МДЛП, и попробуйте еще раз.
                  <br />
                  Если проблема остается, позвоните по номеру 8 800 500-50-80
                  <br />
                  {vm.errors.get("auth")}
                </ErrorWrap>
              )}
              <p>
                Для обмена данными с ИС МДЛП подпишите разрешение.
                <br />
                <br />
                Доступ для организации будет открыт при помощи сертификата:
              </p>
              <Loader active={!!vm.loaders.get("auth")} minHeight={"250px"}>
                <CertificateWrapper>
                  <CertificateListControl
                    storeTypes={CertificateListVM.storeTypes}
                    onClick={vm.setCertificate}
                    customErrors={CustomCertErrors}
                    customFilter={CertificateListVM.filterCertificate}
                  />
                </CertificateWrapper>
              </Loader>
            </Route>
            <Route exact path={items.department.url}>
              <DepartmentsSubPage vm={vm} authUrl={items.authorization.url} />
            </Route>
          </Switch>
        </Content>
        <Switch>
          <Route path={items.authorization.url}>
            <Footer>
              <Gapped gap={8}>
                <RouterLink to={items.requisites.url}>
                  <Button arrow={"left"} size={"medium"}>
                    Назад
                  </Button>
                </RouterLink>
                <Button use={"primary"} arrow size={"medium"} disabled={!vm.certificate} onClick={onSing}>
                  Подписать
                </Button>
              </Gapped>
            </Footer>
          </Route>
          <Route path={items.department.url}>
            <div />
          </Route>
          <Route path={items.requisites.url}>
            <Footer>
              <Button disabled={vm.isDisabledBtn} use={"primary"} onClick={onSaveRequisites} arrow size={"medium"}>
                Далее
              </Button>
            </Footer>
          </Route>
        </Switch>
      </Wrapper>
    );
  })
);
