import { CodeModelExtended, ICode } from "../../Code/CodeModelExtended";
import { action, computed, observable } from "mobx";
import { BaseDeliveryItemModel } from "./BaseDeliveryItemModel";
import { DeliveryItemModel, DeliveryItemStatus } from "typings/server";
import { getGtinFromSgtin, isSgtin, isSscc } from "helpers/codes";
import { copyOnlyExistingProperties } from "helpers";

export class DeliveryItemModelExtended extends BaseDeliveryItemModel {
  public code: string;
  public gtin: string;
  public dataMatrix: string;
  public positionId: string;
  // для сохранения на бэке передаем только настоящие имена
  public realName: string = ""; // withdrawal, transfer, DeliveryModelActions.ts, getNamedItems
  @observable status: DeliveryItemStatus | undefined;

  constructor(item: Partial<DeliveryItemModel>, codes?: CodeModelExtended[]) {
    super();
    Object.assign(this, item);
    if (codes) {
      this.setCodes(codes);
      this.setGtin();
    }

    // для сохранения на бэке передаем только настоящие имена
    if (
      !this.allCodes.some(cm => cm.code === item.name) &&
      item.name !== this.gtin &&
      item.name !== item.code &&
      item.name
    ) {
      this.realName = item.name;
    }
    this.name = item.name || this.gtin || item.code || "Без названия";

    this.cost = item.cost || 0;
    this.vat = item.vat || 0;
  }

  @computed
  get allItemsCount() {
    return this.allCodes.reduce((sum, code) => {
      if (code.itemsCount) sum += code.itemsCount;
      // для штучных товаров itemsCount === 1
      if (code.isGtin) sum += 1;
      return sum;
    }, 0);
  }

  private setGtin() {
    if (this.sgtinCodes && this.sgtinCodes.length) {
      this.gtin = getGtinFromSgtin(this.sgtinCodes[0].code) as string;
    }
  }

  @action
  tryToAddCode(codeItem: ICode) {
    const { code } = codeItem;
    if (this.getCode(code)) return;
    if (isSscc(code)) this.ssccCodes.push(new CodeModelExtended(codeItem));
    else if (isSgtin(code)) this.sgtinCodes.push(new CodeModelExtended(codeItem));
  }

  @action
  setStatus(status: DeliveryItemStatus) {
    this.status = status;
    this.allCodes.forEach(code => (code.status = status));
  }

  @action
  updateName(name: string) {
    this.name = name;
    this.realName = name;
  }

  moveScannedCodesToNewItem(): DeliveryItemModelExtended | undefined {
    if ((!this.sgtinScanLen && !this.ssccScanLen) || this.isAllScanned) return;
    const scanned: CodeModelExtended[] = [];
    this.allCodes.forEach(item => {
      if (item.scanned) {
        scanned.push(item);
        this.removeCode(item.code);
      }
    });

    const newItem = new DeliveryItemModel();
    copyOnlyExistingProperties(newItem, this);
    // for react key in loop
    newItem.code = scanned[0].code;
    this.code = this.allCodes[0] && this.allCodes[0].code;

    return new DeliveryItemModelExtended(newItem, scanned);
  }

  @action
  addCodes(codes: CodeModelExtended[]) {
    codes.forEach(codeItem => this.tryToAddCode(codeItem));
  }

  @computed
  get isAllScanned(): boolean {
    const sgtinScanLen = this.getScannedLength(this.sgtinCodes);
    const ssccScanLen = this.getScannedLength(this.ssccCodes);
    return sgtinScanLen === this.sgtinCodes.length && ssccScanLen === this.ssccCodes.length;
  }

  @computed
  get isUnprocessedStatus(): boolean {
    return !this.status || this.status === DeliveryItemStatus.Unprocessed;
  }
}
