import { action, computed } from "mobx";
import { AcceptusType, DeliveryStatus } from "typings/server";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { YMetrika } from "YMetrika";
import { BaseAcceptancePageVM } from "../Common/BaseAcceptancePageVM";

export class DirectAcceptancePageVM extends BaseAcceptancePageVM {
  //#region Computed
  @computed
  get isAnyItemHasScannedCode(): boolean {
    const items = this.activeDelivery.items;
    if (!items) return false;
    return items.some(item => item.sgtinScanLen || item.ssccScanLen);
  }

  @computed
  get reportDate() {
    return this.activeDelivery.reportDate || this.delivery.reportDate || this.currentDate;
  }

  @computed
  get hasMainDeliveryUnprocessedItems(): boolean {
    return Boolean(this.delivery.unprocessedItems.length);
  }

  @computed
  get recalledLength() {
    return this.activeDelivery.recalledCodesLen;
  }

  @computed
  get isStateDispatch(): boolean {
    return this.delivery.acceptusType === AcceptusType.StateDispatch;
  }

  @computed
  get isSuspendedReturn(): boolean {
    return this.delivery.acceptusType === AcceptusType.SuspendedReturn;
  }

  @computed
  get showDeliveryAcceptanceBlock() {
    const { status, childDeliveries } = this.delivery;
    return (
      !(
        status === DeliveryStatus.PartiallyAccepted ||
        (status === DeliveryStatus.Success && childDeliveries.length) ||
        (status === DeliveryStatus.Recalled && childDeliveries.length)
      ) && this.delivery === this.activeDelivery
    );
  }
  //#endregion

  //#region Buttons
  @computed
  get showFooter(): boolean {
    const { status, isActive, childDeliveries } = this.delivery;
    const isAnyDeliveryActive = isActive || childDeliveries.some(d => d.isActive);
    return (
      this.showRollbackBtn ||
      (status !== DeliveryStatus.Success &&
        status !== DeliveryStatus.Sending &&
        status !== DeliveryStatus.MdlpProcessing &&
        status !== DeliveryStatus.CreatingDocument &&
        status !== DeliveryStatus.WaitingForCounterparty &&
        (this.hasMainDeliveryUnprocessedItems || isAnyDeliveryActive))
    );
  }

  @computed
  get showRollbackBtn(): boolean {
    return this.delivery.status === DeliveryStatus.Signing;
  }

  @computed
  get rollbackHintText(): string {
    const { status } = this.delivery;
    return status === DeliveryStatus.Signing ? "Для редактирования приемки" : "Для редактирования и повторной приемки";
  }

  @computed
  get showAcceptRestBtn(): boolean {
    const status = this.delivery.status;
    return (
      (status === DeliveryStatus.PartiallyAccepted || status === DeliveryStatus.Processing) &&
      this.hasMainDeliveryUnprocessedItems &&
      !this.showTryAgainBtn &&
      !this.showSignBtn &&
      !this.showAcceptBtns &&
      !this.showEndBtns
    );
  }

  @computed
  get showTryAgainBtn(): boolean {
    const isAnyDeliveryFailed = this.delivery.childDeliveries.some(d => d.status === DeliveryStatus.Failed);
    return (
      isAnyDeliveryFailed &&
      this.hasMainDeliveryUnprocessedItems &&
      !this.showSignBtn &&
      !this.showAcceptBtns &&
      !this.showEndBtns
    );
  }

  // принять товары
  @computed
  get showAcceptBtns(): boolean {
    const status = this.activeDelivery.status;
    return (
      (status === DeliveryStatus.New || status === DeliveryStatus.Draft || status === DeliveryStatus.Processing) &&
      !this.activeDelivery.rejectUnscanned
    );
  }

  // завершить приемку
  @computed
  get showEndBtns(): boolean {
    const status = this.activeDelivery.status;
    return (
      (status === DeliveryStatus.New || status === DeliveryStatus.Draft || status === DeliveryStatus.Processing) &&
      this.activeDelivery.rejectUnscanned
    );
  }
  //#endregion

  //#region API helpers
  @action
  async save(allIncomingCodes?: boolean): Promise<void> {
    if (this.validate()) {
      this.toggleLoading("creatingDocument");
      await this.saveItems(allIncomingCodes);
      this.actions.sendReportDateMetrics(this.activeDelivery.reportDate);
      // сохраняем родительский документ, но подписываем все дочерние или родительский док
      await this.actions.save(this.delivery);
      if (this.activeDelivery) {
        this.actions.updateStatus(DeliveryStatus.CreatingDocument, DeliveryStage.Processing, this.activeDelivery);
      }
    }
  }

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.deliveryStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.deliveryStore.getItem(this.delivery.id, { force: true });
    this.deliveryStore.setSelected(item);
    this.toggleLoading("rollback");
  }

  async saveItems(allIncomingCodes?: boolean) {
    return await this.deliveryStore.saveCodes(
      new CodesReportModelExtended(this.activeDelivery, this.delivery.id, allIncomingCodes)
    );
  }

  @action
  async saveDraft(): Promise<void> {
    if (this.validate()) {
      await this.deliveryStore.saveCodes(new CodesReportModelExtended(this.activeDelivery, this.delivery.id));
      if (this.delivery.status === DeliveryStatus.New) {
        this.actions.updateStatus(DeliveryStatus.Processing, DeliveryStage.Processing, this.delivery);
      }
    }
  }

  async onTrustedAccept() {
    this.activeDelivery.setAllIncomingCodes();
    await this.save(true);
    this.toggleModal("trustedAccept");
  }
  //#endregion

  //#region Other helpers
  @action
  setConfirmPaused(val: boolean) {
    if (val) YMetrika.sendEvent("confirmPausedEvent");
    this.activeDelivery.setConfirmPaused(val);
  }

  @action
  setReportDate(date?: string) {
    this.activeDelivery.setReportDate(date);
    if (this.submitted) this.validateDate();
  }

  @action
  validate(): boolean {
    const { reportDate, rejectUnscanned } = this.activeDelivery;
    this.errors.clear();
    this.validateDate(reportDate);
    this.validateReason(rejectUnscanned);

    return !this.errors.size;
  }

  @action
  setRejected() {
    this.activeDelivery.toggleRejectUnscannedStatus();
  }

  @action
  setRejectReason = (val: string) => {
    this.activeDelivery.recallReason = val;
    if (this.submitted) this.validate();
  };
  //#endregion
}
