import { action, computed, observable } from "mobx";
import { getFormattedDate, longDateTimeOptions } from "helpers/date";
import { IStore } from "stores/shared";
import { AcceptusType, DeliveryIndexModel, DeliveryType, DepartmentType, SyncResult } from "typings/server";
import { DeliveriesApi } from "api/DeliveryApi";
import { ISignatureStore } from "stores/SignatureStore";
import { ICertificate } from "@skbkontur/plugin-js";
import { MDLP_AUTH_REQUIRED_STATUS } from "api/shared";
import { IUserStore } from "stores/UserStore";
import { IDeliveryStore } from "stores/DeliveriesStore";
import { WithFilterInfo } from "models/Filter/FilterModel";
import { debounce } from "lodash-es";
import { delay } from "helpers/promise";
import { Advertisement, IAdvertisementStore } from "stores/AdvertisementStore";

export type TModalName =
  | "utd"
  | "mdlpModal"
  | "sidePage"
  | "newDelivery"
  | "newWithdrawal"
  | "newReentry"
  | "newDisposal"
  | "newSendToDestruction"
  | "newDestruction"
  | "delete"
  | "newRegistrarDisposal";
export type TLoadingName = "list" | "listImmediate" | "start" | "delete";

export class DeliveriesMainPageVM {
  @observable modalState: Map<TModalName, boolean> = new Map();
  @observable loadingState: Map<TLoadingName, boolean> = new Map();
  @observable utdFileName: string;
  @observable types: DeliveryType[];
  @observable acceptusType: AcceptusType;
  @observable isShowFilterResetRow: boolean = false;
  @observable deliveryToDelete: DeliveryIndexModel | null = null;
  @observable isReentryAndDestructionImposible = false;
  debouncedUpdateFilter = debounce(this.updateFilter, 500);

  constructor(
    readonly store: IStore,
    readonly signatureStore: ISignatureStore,
    readonly userStore: IUserStore,
    readonly deliveriesStore: IDeliveryStore,
    readonly advertisementStore: IAdvertisementStore,
    types: DeliveryType[]
  ) {
    this.types = types;
    // Для места ответственного хранения повторный ввод в оборот невозможен
    this.isReentryAndDestructionImposible = this.userStore.currentDepartment?.type === DepartmentType.SafeWarehouse;
    this.init();
    if (this.isAnyFilterApplied) {
      this.isShowFilterResetRow = true;
    }
  }

  @action
  private async init() {
    if (this.store.startPollingDeliveries) {
      const { activePage } = this.store.filters;
      this.loadingState.set("start", true);
      try {
        await this.store.startPollingDeliveries(activePage);
      } finally {
        this.loadingState.set("start", false);
      }
    }

    await this.store.getAvailableRegistrators?.();
  }

  @action
  async synchronizeAndUpdate(interval?: number) {
    try {
      await DeliveriesApi.synchronize();
      await this.store.restartPollingDeliveries({ interval, fireImmediately: true });
    } catch (e: any) {
      if (e.status === MDLP_AUTH_REQUIRED_STATUS) {
        this.toggleModal("mdlpModal");
      } else throw e;
    }
  }

  @action toggleModal(name: TModalName) {
    this.modalState.set(name, !this.modalState.get(name));
  }

  @action handleUnmount() {
    this.store.stopPollingDeliveries();
  }

  @action setDeliveryToDelete(delivery: DeliveryIndexModel | null) {
    this.deliveryToDelete = delivery;
  }

  @action
  public setActivePage = async (page: number) => {
    try {
      this.loadingState.set("list", true);
      await this.store.setActivePage(page);
    } finally {
      this.loadingState.set("list", false);
    }
  };

  @action
  async setType(types: DeliveryType[]) {
    this.types = types;
    if (this.store.changeType) this.store.changeType(types[0]);
    this.loadingState.set("start", true);
    try {
      await this.setActivePage(1);
    } finally {
      this.loadingState.set("start", false);
    }
  }

  @action
  setAcceptusType(type: AcceptusType) {
    this.acceptusType = type;
  }

  @action
  async deleteDelivery() {
    if (this.deliveryToDelete) {
      this.loadingState.set("listImmediate", true);
      await this.store.deleteDelivery(this.deliveryToDelete.id);
      this.setDeliveryToDelete(null);
      this.loadingState.set("listImmediate", false);
    }
  }

  @action.bound
  async getDeliveryItems() {
    if (this.deliveryToDelete) {
      this.loadingState.set("delete", true);
      await delay(400);
      const items = await this.store.getItems(this.deliveryToDelete.id);
      this.loadingState.set("delete", false);
      return items;
    }
  }

  async onSing(cert: ICertificate) {
    await this.signatureStore.signCode(cert);
  }

  @computed
  get hasNewDestructions(): boolean {
    const currentDepartmentId = this.userStore.user ? this.userStore.currentDepartment?.id : undefined;
    return !!currentDepartmentId && !!this.deliveriesStore.counters[currentDepartmentId]?.newDestructionsCount;
  }

  @computed
  get isShowEmptyMessageBlock() {
    return this.deliveries.length === 0 && !this.isShowFilterResetRow && !this.loadingState.get("listImmediate");
  }

  @computed
  get deliveries(): DeliveryIndexModel[] {
    return this.store.deliveries || [];
  }

  @computed
  get activePage(): number {
    return this.store.filters.activePage;
  }

  @computed
  get pagesCount(): number {
    return this.store.filters.pagesCount || 1;
  }

  @computed
  get lastUpdateTime(): string {
    if (!this.deliveriesStore.syncTime) return "";
    return getFormattedDate(this.deliveriesStore.syncTime!.toISOString(), longDateTimeOptions);
  }

  @computed
  get syncResult(): SyncResult | undefined {
    return this.deliveriesStore.syncResult;
  }

  @computed
  get syncErrorMessage(): string | undefined {
    return this.deliveriesStore.syncErrorMessage;
  }

  isCanBeDeleted({ status, acceptusType }: DeliveryIndexModel) {
    return this.store.isCanBeDeleted(status, acceptusType);
  }

  @computed
  get canLoadUtd() {
    return this.userStore.hasAnyDepartment;
  }

  @computed
  get isAdmin() {
    return this.userStore.isAdmin;
  }

  @computed
  get allowedStatuses() {
    return this.store.filters.allowedStatuses;
  }

  @computed
  get allowedCounteragents() {
    return this.store.filters.allowedCounteragents;
  }

  @computed
  get isAnyFilterApplied() {
    return this.store.filters.isAnyFilterApplied;
  }

  @computed
  get totalSize() {
    return this.store.totalSize;
  }

  @computed
  get documentNumberQuery() {
    return this.store.filters.documentNumberQuery;
  }

  @computed
  get aggregateNumberQuery() {
    return this.store.filters.aggregateNumberQuery;
  }

  @computed
  get allowedDestinations() {
    return this.store.filters.allowedDestinations;
  }

  @computed
  get allowedAcceptusType() {
    return this.store.filters.allowedAcceptusType;
  }

  @computed
  get allowedWithdrawalReason() {
    return this.store.filters.allowedWithdrawalReason;
  }

  @computed
  get allowedWithdrawalCauses() {
    return this.store.filters.allowedWithdrawalCauses;
  }

  @computed
  get allowedReturnReasons() {
    return this.store.filters.allowedReturnReasons;
  }

  @computed
  get isPreserveFilters() {
    return this.store.filters.isPreserveFilters;
  }

  @computed
  get advertisementsMap() {
    return this.advertisementStore.isShowAdvertisementMap;
  }

  @computed
  get promotedDeliveryId() {
    // Первая операция, у которой есть codesSyncStatus - сейчас ее промотируем
    return this.deliveries.filter(delivery => !!delivery.codesSyncStatus)?.[0]?.id;
  }

  @action.bound
  setAdvertisementShown(key: Advertisement) {
    this.advertisementStore.setAdvertisementStatus(key, false);
  }

  @action.bound
  setIsPreserveFilters(isPreserve: boolean) {
    this.store.filters.setIsPreserveFilters(isPreserve);
  }

  @action.bound
  changeAggregateNumberQuery(query: string) {
    this.store.filters.setAggregateNumberQuery(query);
    this.debouncedUpdateFilter();
  }

  @action.bound
  changeDocumentNumberQuery(query: string) {
    this.store.filters.setDocumentNumberQuery(query);
    this.debouncedUpdateFilter();
  }

  @action.bound
  toggleFilter<T>(filter: WithFilterInfo<T>) {
    this.store.filters.toggleFilter<T>(filter);
    this.updateFilter();
  }

  @action.bound
  resetAllFiltersAndPage(withoutUpdate?: boolean) {
    this.store.filters.resetAllFiltersAndPage();
    if (!withoutUpdate) {
      this.updateFilter();
    }
  }

  @action.bound
  async updateFilter() {
    this.isShowFilterResetRow = false;
    this.loadingState.set("listImmediate", true);
    try {
      await this.store.updateDeliveries();
    } finally {
      this.loadingState.set("listImmediate", false);
      if (this.isAnyFilterApplied) {
        this.isShowFilterResetRow = true;
      }
    }
  }
}
