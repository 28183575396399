import React, { useState } from "react";
import { CertificatesList, ICertificatesListProps } from "./CertificatesList";
import { Button, Modal } from "components";
import { AccessToMdlpModalBlock } from "./CertificateList.styles";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";

interface IMdlpModalProps extends ICertificatesListProps {
  onClose(): void;
  onDismiss?(): void; // если пользователь отказался подписывать
}

export const MdlpModal: React.FC<IMdlpModalProps> = (props: IMdlpModalProps) => {
  const [showCertList, setShowCertList] = useState(false);

  const onClose = () => props.onClose();

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    } else {
      props.onClose();
    }
  };

  const onAgreeToSign = () => {
    setShowCertList(true);
  };

  return !showCertList ? (
    <Modal data-tid="MdlpModal" ignoreBackgroundClick onClose={onClose}>
      <Modal.Header sticky>Доступ в ИС МДЛП</Modal.Header>
      <Modal.Body>
        <AccessToMdlpModalBlock>
          Для обмена данными с ИС МДЛП подпишите разрешение.
          <p>Разрешение действует в течение 30 минут.</p>
        </AccessToMdlpModalBlock>
      </Modal.Body>
      <Modal.Footer sticky={false} panel>
        <FooterBtnWrapper>
          <Button data-tid="Sign" size={"large"} use="primary" onClick={onAgreeToSign} width={"189px"}>
            Подписать
          </Button>
          <Button data-tid="Cancel" size={"large"} onClick={onDismiss}>
            Отмена
          </Button>
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  ) : (
    <CertificatesList {...props} />
  );
};
