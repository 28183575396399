import { observable } from "mobx";
import { IWithdrawalStore } from "stores/WithdrawalStore";

export class AdditionalInfoVM {
  @observable codeWarning: { errorCode: number; description: string } | undefined = undefined;

  constructor(private readonly withdrawalStore: IWithdrawalStore) {
    this.setCodeWarning();
  }

  private setCodeWarning = () => {
    const codeStatus = this.withdrawalStore.currentScanCodeStatus?.items?.[0];
    const status = codeStatus?.status as keyof typeof ErrorMdlpWithdrawalStatuses;
    const isProperDepartment = codeStatus?.isProperDepartment;
    const isOwner = codeStatus?.isOwner;

    //   Код выдумал сам, посмотри, куда он дальше прокидывается, и сам поймешь:)
    const errorCode = 113;

    if (isOwner === false) {
      this.codeWarning = { errorCode, description: "Код маркировки принадлежит другому владельцу" };
      return;
    }

    if (isProperDepartment === false) {
      this.codeWarning = { errorCode, description: "Код маркировки не принадлежит текущему подразделению" };
      return;
    }

    //   Если инфы нет, или статус не является таким, по которому МДЛП вернет ошибку по коду - ничего не сделаем
    if (!status || !ErrorMdlpWithdrawalStatuses[status]) {
      return;
    }

    this.codeWarning = {
      errorCode,
      description: `Код маркировки ${ErrorMdlpWithdrawalStatuses[status].toLowerCase()}`,
    };
  };
}

const ErrorMdlpWithdrawalStatuses = {
  in_sale: "Продан в розницу",
  moved_for_disposal: "Передан на уничтожение",
  disposed: "Уничтожен",
  out_of_circulation: "Выведен из оборота",
  paused_circulation: "В статусе приостановлен",
  in_discount_prescription_sale: "Продан в розницу",
  in_medical_use: "Выдан для медицинского применения",
  moved_to_unregistered: "Отгружен на незарегистрированное место деятельности",
  experiment_outbound: "Выведен из оборота (накопленный в рамках эксперимента)",
  moved_to_eeu: "Отгружен в ЕАЭС",
  ofd_retail_error: "Продан в розницу с отклонением от требований в части выбытия ЛП",
  ofd_discount_prescription_error: "Отпущен по льготному рецепту (ККТ) с отклонением от требований в части выбытия ЛП",
  discount_prescription_error: "Отпущен по льготному рецепту с отклонением от требований в части выбытия ЛП",
  med_care_error: "Отпущен для медицинского применения с отклонением от требований в части выбытия ЛП",
  dispensing_by_document: "Выдан по документам",
  ooc_part_sale: "Частичная продажа, остаток списан",
  ooc_part_prescription_sale: "Частичный отпуск по ЛР, остаток списан",
  ooc_part_medical_use: "Частичное медицинское применение, остаток списан",
  ooc_part_dispensing_by_doc: "Частично выдан по документам, остаток списан",
};

export const MdlpWithdrawalCodeStatuses = {
  ...ErrorMdlpWithdrawalStatuses,
  in_circulation: "В обороте",
  marked: "Ожидает выпуска",
  lp_sampled: "Отобран образец",
  transfered_to_owner: "Ожидает подтверждения получения собственником",
  shipped: "Отгружен в РФ",
  arrived: "Ввезен на территорию РФ",
  declared: "Задекларирован",
  in_realization: "Отгружен",
  relabeled: "Перемаркирован",
  reexported: "Реэкспорт",
  released_contract: "Ожидает передачи собственнику",
  released_foreign: "Ожидает отгрузки в РФ",
  change_owner: "Ожидает подтверждения смены собственника",
  change_owner_state_gov: "Ожидает подтверждения получения новым владельцем",
  confirm_return_paused: "Ожидает подтверждения возврата приостановленных лекарственных препаратов",
  in_partial_medical_use: "Частично выдан для медицинского применения",
  in_partial_sale: "Частично продан в розницу",
  in_partial_discount_prescription_sale: "Частично отпущен по льготному рецепту",
  moved_to_warehouse: "Принят на склад из ЗТК",
  emission: "Эмитирован",
  transferred_for_release: "Ожидает подтверждения получения собственником до ввода в оборот",
  waiting_for_release: "Ожидает ввода в оборот собственником",
  emitted: "Эмитирован",
  marked_not_paid: "Ожидает выпуска, не оплачен",
  released_foreign_not_paid: "Ожидает отгрузки в РФ, не оплачен",
  expired_not_paid: "Истек срок ожидания оплаты",
  emitted_paid: "Эмитирован, готов к использованию",
  declared_warehouse: "Принят на склад из ЗТК",
  transferred_to_customs: "Передан для маркировки в ЗТК",
  transferred_to_importer: "Ожидает подтверждения импортером",
  in_arbitration: "В арбитраже",
  waiting_confirmation: "Ожидает подтверждения",
  transfer_to_productio: "Ожидает подтверждения возврата",
  waiting_change_property: "Ожидает подтверждения корректировки",
  eliminated: "Не использован",
  transferred_to_agent: "Отгружен по агентскому договору",
  awaiting_return_confirmation: "Ожидает подтверждения возврата иностранного ЛП",
  in_partial_dispensing_by_document: "Частично выдан по документам",
};
