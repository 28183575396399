import styled from "styles/styled-components";

export const SynInfoBlock = styled.div`
  margin-top: 26px;
  margin-bottom: 18px;
  min-height: 42px;
`;

export const SynInfoText = styled.div`
  margin-bottom: 4px;
  white-space: pre-line;
`;

export const SynInfoBlockWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ITableItemProps {
  selectable?: boolean;
}

export const TableItem = styled.div<ITableItemProps>`
  cursor: ${props => (props.selectable ? "pointer" : "inherit")};

  &:hover {
    background-color: ${props =>
      props.selectable ? props.theme.colors.lightBackground : props.theme.colors.transparent};
  }
`;
