import { action, observable } from "mobx";
import { DeliveryStage } from "./DeliveryDictionary";
import {
  DeliveryModel,
  DeliveryStatus,
  DeliveryType,
  PutDestructionModel,
  StepInfoModel,
  TaskModel,
} from "typings/server";
import { ICertificate } from "@skbkontur/plugin-js";
import { ISignatureStore } from "stores/SignatureStore";
import { Polling } from "helpers/polling";
import { IStage } from "models/Delivery/shared";
import { fullDateAndTimeOptions, getFormattedDate } from "helpers/date";
import { ProgressStage } from "./Status/DeliveryStatus/DeliveryStatusBlock";
import { ILoadOptions } from "stores/shared";
import { DeliveriesStore, IDeliveryStore } from "stores/DeliveriesStore";
import { IUserStore } from "stores/UserStore";
import { YMetrika } from "YMetrika";
import { DestructionApi } from "api/DestructionApi";

export type DetailPageModalNames =
  | "confirmation"
  | "trustedAccept"
  | "certList"
  | "scanPage"
  | "error"
  | "editorModal"
  | "recall"
  | "rollback"
  | "delete"
  | "cancelDestruction"
  | "destruction";

export type DetailPageLoadingName = "rollback" | "resend" | "delete" | "reject" | "download" | "creatingDocument";

export type PollingType = "status" | "download";

interface IDelivery {
  childDeliveries?: any;
  parentDelivery?: IDelivery;
  type: DeliveryType;
  id: string;
  reportDate?: string;
  status: DeliveryStatus;
  setNextStage(status: DeliveryStatus, stageName: DeliveryStage, stage: Partial<IStage>, userName: string): void;
  replaceChildDelivery?(delivery: DeliveryModel): void;
}

interface IDeliveryActionsStore {
  selectedDelivery: IDelivery;
  setSelected(delivery: DeliveryModel, type: DeliveryType): void;
  getItem(id: string, options?: Partial<ILoadOptions>): Promise<DeliveryModel>;
  prepareDocuments(deliveryId: string): Promise<string>;
  checkDocumentsStatus(taskId: string): Promise<TaskModel>;
}

export class DeliveryActions {
  @observable public modalMap: Map<DetailPageModalNames, boolean> = new Map();
  @observable public loadingMap: Map<DetailPageLoadingName, boolean> = new Map();
  private pollStatus: Map<string, Polling> = new Map();
  protected store: IDeliveryActionsStore;
  protected deliveryStore: IDeliveryStore;
  protected documentStore: ISignatureStore;
  protected userStore: IUserStore;
  protected readonly type: DeliveryType;

  constructor(store: IDeliveryActionsStore, documentStore: ISignatureStore, userStore: IUserStore, type: DeliveryType) {
    this.deliveryStore = DeliveriesStore;
    this.store = store;
    this.documentStore = documentStore;
    this.userStore = userStore;
    this.type = type;
  }

  @action
  toggleModal(modalName: DetailPageModalNames) {
    this.modalMap.set(modalName, !this.modalMap.get(modalName));
  }

  @action
  toggleLoading(loadingName: DetailPageLoadingName) {
    this.loadingMap.set(loadingName, !this.loadingMap.get(loadingName));
  }

  @action
  async save(delivery: IDelivery, model?: PutDestructionModel) {
    if (!!model) {
      await DestructionApi.updateSendingToDestruction({ id: delivery.id, model });
    }
    await this.documentStore.createDocument(delivery.id);
    this.pooling(delivery);
  }

  @action
  async pooling(delivery: IDelivery) {
    this.updateStatus(DeliveryStatus.CreatingDocument, DeliveryStage.Processing, delivery);
    this.startPolling(delivery.id);
  }

  sendReportDateMetrics(reportDate?: string) {
    // https://yt.skbkontur.ru/issue/MARKDRUGS-126
    // для частичной приемки учет даты из дочерней поставки
    // для вывода из оборота метрика не отправляется, тк дата может быть задана на сервере
    YMetrika.sendEvent(reportDate ? "reportDateChange" : "reportDateUnset");
  }

  startPolling(id: string, type: PollingType = "status") {
    const func = this.getPollingFunc(type);
    this.pollStatus.set(id, new Polling(func.bind(this, id)));
    this.pollStatus.get(id)!.start();
  }

  @action
  stopPolling(id: string) {
    const poll = this.pollStatus.get(id);
    if (poll) {
      poll.stop();
      this.pollStatus.delete(id);
    }
  }

  private async pollStatusFunc(deliveryId: string) {
    const delivery = await this.store.getItem(deliveryId, { force: true });
    if (!this.checkIfStatusNeedToBePolled(delivery.status)) {
      // update delivery
      if (delivery.id === this.store.selectedDelivery.id) {
        this.store.setSelected(delivery, this.type);
      } else {
        if (this.store.selectedDelivery.replaceChildDelivery)
          this.store.selectedDelivery.replaceChildDelivery(delivery);
      }

      if (this.loadingMap.get("creatingDocument")) {
        this.toggleLoading("creatingDocument");
      }
      this.checkIfChildDeliveriesNeedToBePolled(delivery);
      this.stopPolling(delivery.id);
      this.checkIfNeedToSign();
    }
  }

  private async pollDownloadFunc(taskId: string) {
    const { result } = await this.store.checkDocumentsStatus(taskId);
    if (result) {
      this.stopPolling(taskId);
      this.toggleLoading("download");
      // загружаем архив
      window.location.href = result;
    }
  }

  @action
  private checkIfNeedToSign() {
    const parentDelivery = this.store.selectedDelivery;
    const isAnyChildNeedToBeSigned =
      parentDelivery.childDeliveries &&
      parentDelivery.childDeliveries.some((d: IDelivery) => d.status === DeliveryStatus.Signing);
    // показываем окно с подписью, когда загрузились все документы и есть хотя бы один статус Signing
    if (!this.pollStatus.size && (isAnyChildNeedToBeSigned || parentDelivery.status === DeliveryStatus.Signing)) {
      this.toggleModal("certList");
    }
  }

  private checkIfChildDeliveriesNeedToBePolled = (delivery: DeliveryModel) => {
    if (!delivery.childDeliveries || !delivery.childDeliveries.length) {
      return;
    }

    // поллим все дочерние поставки пока не сменится статус
    delivery.childDeliveries.forEach(d => {
      if (this.checkIfStatusNeedToBePolled(d.status)) {
        this.startPolling(d.id);
      }
    });
  };

  private checkIfStatusNeedToBePolled(status: DeliveryStatus): boolean {
    return status === DeliveryStatus.CreatingDocument;
  }

  async sign(cert: ICertificate, deliveries: IDelivery[]): Promise<void> {
    await this.documentStore.signCode(cert);
    await Promise.all(
      deliveries.map(async delivery => {
        await this.documentStore.sign(delivery.id, cert);
        await this.sendDocument(delivery.id);
        this.updateStatus(DeliveryStatus.Sending, DeliveryStage.Signing, delivery);
        if (delivery.parentDelivery) {
          this.updateStatus(DeliveryStatus.Sending, DeliveryStage.Signing, delivery.parentDelivery);
        }
      })
    );
  }

  private async sendDocument(deliveryId: string): Promise<void> {
    return await this.documentStore.sendDocument(deliveryId);
  }

  updateStatus(newStatus: DeliveryStatus, currentStage: DeliveryStage, delivery: IDelivery, stage?: Partial<IStage>) {
    const deliveriesNeedUpdate = delivery.status === DeliveryStatus.New && newStatus !== DeliveryStatus.New;
    //  Если обновляем статусы для операции уничтожения - декремент точно нужен!
    const destructionsNeedUpdate = delivery.type === DeliveryType.Destruction;
    const freshDeliveriesNeedUpdate = deliveriesNeedUpdate || destructionsNeedUpdate;

    delivery.setNextStage(
      newStatus,
      currentStage,
      stage || {
        completionDate: new Date().toISOString(),
      },
      this.userStore.fullName
    );

    if (freshDeliveriesNeedUpdate) {
      this.deliveryStore.decrementCounter(this.type);
    }
  }

  handleUnmount() {
    this.pollStatus.forEach(val => {
      val.stop();
    });
    this.pollStatus.clear();
  }

  static getCompletionUser(stepInfo: StepInfoModel): string {
    return stepInfo ? stepInfo.completionUserName || "" : "";
  }

  static getCompletionDate(stepInfo: StepInfoModel): string {
    if (!stepInfo) return "";
    const date = stepInfo.completionDate || "";
    return `${getFormattedDate(date, fullDateAndTimeOptions)}`;
  }

  getTitleSize(stages: Record<string | DeliveryStage, IStage>, stage: IStage) {
    if (stage === stages[DeliveryStage.Done] && stage.progress === ProgressStage.Done) {
      return "large";
    }
    return stage.progress === ProgressStage.InProgress || stage.errorInfo ? "large" : "medium";
  }

  private getPollingFunc(type: PollingType) {
    switch (type) {
      case "status":
        return this.pollStatusFunc;
      case "download":
        return this.pollDownloadFunc;
      default:
        const neverCheck: never = type;
        return neverCheck;
    }
  }
}
