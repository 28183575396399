import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Modal } from "components";
import { Footer, FooterBtnWrapper } from "components/Layout/Footer.styles";
import { DirectAcceptancePageVM } from "../../DirectAcceptance/DirectAcceptancePageVM";
import { getEnding } from "helpers/endings";
import {
  ErrorModalText,
  IconWrap,
  PartiallyRejectedStatusIcon,
  TextWrap,
  Unscanned,
  Wrap,
} from "./ConfirmationModal.styles";
import { AcceptedStatusIcon, CheckIconGrey, RejectedStatusIcon } from "icons";
import { TextBold } from "styles/shared.styles";

interface IConfirmationModalProps {
  vm: DirectAcceptancePageVM;
  onComplete(): Promise<void>;
  errorText: string;
}

export const ConfirmationModal = observer((props: React.PropsWithChildren<IConfirmationModalProps>) => {
  const { vm, errorText } = props;
  const [error, setError] = useState("");

  const unscannedCodesLen = vm.activeDelivery.allCodesLen - vm.activeDelivery.allScannedLen;
  const unscannedEnding = getEnding(
    unscannedCodesLen,
    "код остался не отсканирован",
    "кода осталось не отсканировано",
    "кодов осталось не отсканировано"
  );

  const isAllRejected = vm.activeDelivery.allScannedLen === 0 && vm.activeDelivery.rejectUnscanned;
  const partiallyRejected = vm.activeDelivery.allScannedLen !== 0 && vm.activeDelivery.rejectUnscanned;
  const rejectedCodesLen = vm.activeDelivery.rejectedCodesLen;

  const allCodesEnding = getEnding(vm.activeDelivery.allCodesLen, "код", "кода", "кодов");

  const onClose = () => vm.toggleModal("confirmation");

  const save = async () => {
    try {
      await props.onComplete();
    } catch (e) {
      setError(errorText);
    }
  };

  const allRejectedBlock = (
    <Wrap>
      <RejectedStatusIcon />
      <TextWrap>
        <TextBold>
          {vm.activeDelivery.allCodesLen} {allCodesEnding} для отказа в приемке.
        </TextBold>
        <br />В ИС МДЛП будет отправлен отказ по всем кодам.
      </TextWrap>
    </Wrap>
  );

  const partiallyRejectedBlock = (
    <>
      <Wrap>
        <IconWrap>
          <AcceptedStatusIcon size={"large"} />
        </IconWrap>
        <TextWrap>
          <TextBold>
            {vm.activeDelivery.allScannedLen} из {vm.activeDelivery.allCodesLen} кодов
          </TextBold>
          &nbsp; будут приняты и отправлены в ИС МДЛП
          <br />
        </TextWrap>
      </Wrap>
      <Wrap marginTop={"8px"}>
        <IconWrap>
          <PartiallyRejectedStatusIcon size={"large"} />
        </IconWrap>
        <TextWrap>
          По&nbsp;
          <TextBold>
            {rejectedCodesLen} {getEnding(rejectedCodesLen, "коду", "кодам", "кодам")}
          </TextBold>
          &nbsp;будет отправлен отказ
        </TextWrap>
      </Wrap>
    </>
  );

  return (
    <Modal data-tid="ConfirmationModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>{isAllRejected ? "Завершение приемки отказом" : "Завершение приемки"}</Modal.Header>
      <Modal.Body>
        <ErrorModalText>
          {error ? (
            error
          ) : (
            <>
              {isAllRejected && allRejectedBlock}

              {partiallyRejected && partiallyRejectedBlock}

              {!isAllRejected && !partiallyRejected && (
                <Wrap>
                  <CheckIconGrey />
                  <TextWrap>
                    <Unscanned>
                      {unscannedCodesLen} {unscannedEnding}
                    </Unscanned>
                    <TextBold>
                      {vm.activeDelivery.allScannedLen} из {vm.activeDelivery.allCodesLen} кодов
                    </TextBold>
                    &nbsp; будут приняты и отправлены в ИС МДЛП.
                    <br />
                    Остальные коды можно принять позже.
                  </TextWrap>
                </Wrap>
              )}
            </>
          )}
        </ErrorModalText>
      </Modal.Body>
      <Footer>
        <FooterBtnWrapper>
          {!error && (
            <Button data-tid="Submit" size={"large"} use="primary" onClick={save}>
              Завершить
            </Button>
          )}
          <Button data-tid="Cancel" size={"large"} onClick={onClose}>
            {error ? "Закрыть" : "Отменить"}
          </Button>
        </FooterBtnWrapper>
      </Footer>
    </Modal>
  );
});
