export const colors = {
  black: "#000000",
  white: "#fff",
  bluePrimary: "#1F87EF",
  yellowPale: "#FFF0BC",
  orange: "#f69c00",
  orangeDark: "#D97E00",
  green: "#3f9726",
  greenLight: "#E2F7DC",
  greenDark: "#237b07",
  darkGray: "#808080",
  gray: "#DADADA",
  dimgray: "rgb(102, 102, 102)",
  greySmoke: "#F6f6f6",
  almostWhite: "#EBEBEB",
  greyWhisperDark: "#a0a0a0ab",
  greyLight: "#F2F2F2",
  lightBackground: "rgba(0, 0, 0, 0.05)",
  darkBackground: "rgba(0, 0, 0, 0.1)",
  redBackground: "#FFD6D6",
  red: "#CF0000",
  darkRed: "#b00616",
  lightBlue: "#EEF7FF",
  blue: "#1D85D0",
  darkBlue: "#015CCB",
  darkBlueHover: "rgb(27, 125, 195)",
  blueActive: "rgba(48, 114, 196, 0.55)",
  transparent: "transparent",
  bgColorHover: "rgba(0, 0, 0, 0.2)",
  borderColorHover: "rgba(0, 0, 0, 0.18)",
  bgColorActive: "rgba(0, 0, 0, 0.1)",
  borderGray: "rgba(0, 0, 0, 0.15)",
  blueMenuItem: "#1C7EDF",
  bgHoverGreyLight: "#F9F9F9",
};

export const textColors = {
  lightBlack: "rgba(0, 0, 0, 0.8)",
  default: "#333",
  darkGray: "#4A4A4A",
  gray: "#7C7C7C",
  lightGray: "#A0A0A0",
  lightGray2: "#808080",
  secondaryText: "#808080",
  lightenGray: "rgba(0, 0, 0, 0.5)",
  lightenGray2: "rgba(0, 0, 0, 0.37)",
  disabledWhite: "rgba(255, 255, 255, 0.5)",
  red: "#CE0014",
  darkRed: "#b00616",
  warningText: "#D97E00",
  green: "#228007",
};

export const brandColors = {
  default: "#2291FF",
};

export const statusColors = {
  green: "#3F9726",
  red: "#b00616",
  grey: "rgba(0, 0, 0, 0.15)",
  darkGray: "#A0A0A0",
  orange: "#f69c00",
};
