import { action, computed, observable } from "mobx";
import { WithFilterInfo } from "models/Filter/FilterModel";
import { DeliveriesMainPageVM } from "../../DeliveriesMainPageVM";

export class FilterDropdownVM<T> {
  @observable isActive = false;
  @observable items: WithFilterInfo<T>[];
  @observable filterQuery: string = "";
  deliveryVM: DeliveriesMainPageVM;

  constructor(deliveryVM: DeliveriesMainPageVM, items: WithFilterInfo<T>[]) {
    this.deliveryVM = deliveryVM;
    this.items = items;
  }

  @computed
  get isFilterApplied() {
    return this.items.some(x => x.isFilterApplied);
  }

  @computed
  get isHeaderDisabled() {
    return this.items.length === 0;
  }

  @computed
  get filteredItems(): WithFilterInfo<T>[] {
    const query = this.filterQuery.toLowerCase().trim();
    return this.items.filter(x => ((x.name as unknown) as string).toLowerCase().includes(query));
  }

  @computed
  get isPreserveFilters() {
    return this.deliveryVM.isPreserveFilters;
  }

  @action
  setItems(items: WithFilterInfo<T>[]) {
    this.items = items;
  }

  @action
  setIsActive(isActive: boolean) {
    this.isActive = isActive;
  }

  @action
  handleItemClick = (value: WithFilterInfo<T>) => {
    this.deliveryVM.toggleFilter(value);
  };

  @action.bound
  resetAllFiltersAndPage() {
    this.deliveryVM.resetAllFiltersAndPage(true);
  }

  @action.bound
  setIsPreserveFilters(isPreserve: boolean) {
    this.deliveryVM.setIsPreserveFilters(isPreserve);
  }

  @action.bound
  setFilterQuery(query: string | undefined) {
    this.filterQuery = query || "";
  }
}
