import React from "react";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { UtdScanPageBody } from "features/ScannerSidePage/SidePages/UtdScan/UtdScanPageBody";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { UtdScanSidePageVM } from "features/ScannerSidePage/SidePages/UtdScan/UtdScanSidePageVM";
import { ShipmentStore } from "stores/ShipmentStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { SignatureStore } from "stores/SignatureStore";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface IShipmentScannerSidePageProps {
  good: UtdPositionModelExtended;
  close(): void;
  onSave(): Promise<void>;
  additionalInfo: CodesAdditionalInfo;
}

export const ShipmentScannerSidePage: React.FC<IShipmentScannerSidePageProps> = (
  props: IShipmentScannerSidePageProps
) => {
  const { additionalInfo, good, onSave } = props;
  const vm = new UtdScanSidePageVM(ShipmentStore, SignatureStore, AdvertisementStore, additionalInfo, good, onSave);
  const title = good?.name;

  return (
    <ScannerSidePageContainer scanText={"Отсканируйте коды маркировки"} headerText={title} vm={vm} {...props}>
      <UtdScanPageBody vm={vm} />
    </ScannerSidePageContainer>
  );
};
