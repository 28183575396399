import { ArrowChevronDown, ArrowChevronUp } from "@skbkontur/react-icons";
import { Button } from "components";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { observer } from "mobx-react";
import React from "react";
import { TextGrey } from "styles/shared.styles";
import { ContractTypeNames, FinancingTypeNames, ReceiveTypeNames, TurnoverTypeNames } from "typings/dictionaries";
import { ContractType, FinancingType, ReceiveType, TurnoverType } from "typings/server";

interface RequisitesBlockProps {
  isOpen: boolean;
  toggle: () => void;
  receiveType?: ReceiveType;
  turnoverType?: TurnoverType;
  financingType?: FinancingType;
  contractType?: ContractType;
  contractNumber?: string;
}

export const RequisitesBlock = observer((props: RequisitesBlockProps) => {
  const { isOpen, toggle, receiveType, contractNumber, contractType, financingType, turnoverType } = props;

  return (
    <div data-tid="RequisitesBlock">
      <Button data-tid="ToggleRequisites" use="link" onClick={toggle}>
        {isOpen ? (
          <>
            Скрыть реквизиты <ArrowChevronUp />
          </>
        ) : (
          <>
            Показать все реквизиты <ArrowChevronDown />
          </>
        )}
      </Button>
      {isOpen && (
        <>
          <Grid cols="190px 300px" paddingCells="5px 0 0">
            {receiveType && (
              <>
                <GridCell row={1} col={1}>
                  <TextGrey>Тип операции</TextGrey>
                </GridCell>
                <GridCell data-tid="ReceiveType" row={1} col={2}>
                  {ReceiveTypeNames[receiveType]}
                </GridCell>
              </>
            )}
            {turnoverType && (
              <>
                <GridCell row={1} col={1}>
                  <TextGrey>Тип операции</TextGrey>
                </GridCell>
                <GridCell data-tid="TurnoverType" row={1} col={2}>
                  {TurnoverTypeNames[turnoverType]}
                </GridCell>
              </>
            )}
            {financingType && (
              <>
                <GridCell row={2} col={1}>
                  <TextGrey>Источник финансирования</TextGrey>
                </GridCell>
                <GridCell data-tid="FinancingType" row={2} col={2}>
                  {FinancingTypeNames[financingType]}
                </GridCell>
              </>
            )}
            {contractType && (
              <>
                <GridCell row={3} col={1}>
                  <TextGrey>Тип договора</TextGrey>
                </GridCell>
                <GridCell data-tid="ContractType" row={3} col={2}>
                  {ContractTypeNames[contractType]}
                </GridCell>
              </>
            )}
            {contractNumber && (
              <>
                <GridCell row={4} col={1}>
                  <TextGrey>Реестровый № контракта</TextGrey>
                </GridCell>
                <GridCell data-tid="ContractNumber" row={4} col={2}>
                  {contractNumber}
                </GridCell>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
});
