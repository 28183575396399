import React from "react";
import { observer } from "mobx-react";
import { MenuBlock } from "./Menu.styles";
import { MenuItem } from "./MenuItem";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { MenuVMInner } from "./MenuVM";
import { AdvertisementStore } from "stores/AdvertisementStore";
import { AdvertisementBlock } from "Common/AdvertisementBlock/AdvertisementBlock";
import { capitalize } from "lodash-es";
import { SettingsRoute } from "routes/SettingsRoute";
import { CirculationRoute } from "routes/CirculationRoute";

interface IMenuProps extends RouteComponentProps {
  vm: MenuVMInner;
}

function MenuInternal({ vm, location }: IMenuProps) {
  const isActive = (href: string) => !!location.pathname.includes(href);

  return (
    <MenuBlock>
      {vm.menuItems.map(item => {
        let tidPrefix = capitalize(item.href.substring(1));
        switch (item.name) {
          case CirculationRoute.title:
            return (
              <AdvertisementBlock
                text="Новинка: теперь вы можете передать на уничтожение и уничтожить товары"
                isDisplayed={!!AdvertisementStore.isShowAdvertisementMap.get("destruction")}
                onClose={() => AdvertisementStore.setAdvertisementStatus("destruction", false)}
                width="185px"
                key={item.name}
              >
                <MenuItem
                  data-tid={`${tidPrefix}Item`}
                  icon={item.icon}
                  href={item.href}
                  name={item.name}
                  active={isActive(item.href)}
                  count={item.count}
                />
              </AdvertisementBlock>
            );
          case SettingsRoute.title:
            return (
              <AdvertisementBlock
                text="Теперь вы можете посмотреть остаток оплаченных кодов маркировки"
                isDisplayed={!!AdvertisementStore.isShowAdvertisementMap.get("balance")}
                onClose={() => AdvertisementStore.setAdvertisementStatus("balance", false)}
                width="210px"
                key={item.name}
              >
                <MenuItem
                  data-tid={`${tidPrefix}Item`}
                  icon={item.icon}
                  href={item.href}
                  name={item.name}
                  active={isActive(item.href)}
                  count={item.count}
                />
              </AdvertisementBlock>
            );
          default:
            return (
              <MenuItem
                data-tid={`${tidPrefix}Item`}
                icon={item.icon}
                href={item.href}
                name={item.name}
                key={item.name}
                active={isActive(item.href)}
                count={item.count}
              />
            );
        }
      })}
    </MenuBlock>
  );
}

export const Menu = withRouter(observer(MenuInternal));
