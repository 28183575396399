import React from "react";
import { observer } from "mobx-react";
import { GridCell } from "components/Grid/Grid.styles";
import { Select, Tooltip } from "components";
import { IType, TurnoverTypeNamesArr } from "typings/dictionaries";
import { TextGrey } from "styles/shared.styles";
import { CounteragentInfoModel, DeliveryModel, TurnoverType } from "typings/server";

interface IShipmentEditorVM {
  errors: Map<any, string>;
  turnoverType?: IType;
  companyName: string;
  currentUserDepartmentAddress?: string;
  consigneeOrRecipientAddress?: string;
  recipient?: CounteragentInfoModel;
  updateDeliveryCopy(delivery: Partial<DeliveryModel>): void;
}

interface IFieldProps {
  vm: IShipmentEditorVM;
  row: number;
  margin?: string;
}

export class ShipmentEditorFields extends React.Component {
  static TurnoverType = observer((props: IFieldProps) => {
    const { vm, row } = props;

    const renderValue = (item: IType) => item.label;

    const onChangeTurnoverType = (type: IType) => vm.updateDeliveryCopy({ turnoverType: type.value as TurnoverType });

    const getTurnoverTypeError = () => vm.errors.get("requiredTurnoverType");

    return (
      <>
        <GridCell row={row} col={1}>
          Тип операции
        </GridCell>
        <GridCell row={row} col={2}>
          <Tooltip render={getTurnoverTypeError}>
            <Select<IType>
              data-tid="TurnoverType"
              placeholder={"Выберите"}
              items={TurnoverTypeNamesArr}
              value={vm.turnoverType}
              renderValue={renderValue}
              renderItem={renderValue}
              onValueChange={onChangeTurnoverType}
              error={!!getTurnoverTypeError()}
              width={"250px"}
            />
          </Tooltip>
        </GridCell>
      </>
    );
  });

  static Supplier = (props: IFieldProps) => {
    const { vm, row } = props;
    return (
      <>
        <GridCell row={row} col={1}>
          Отправитель
        </GridCell>

        <GridCell row={row} col={2}>
          <span data-tid="SupplierName">{vm.companyName}</span>
          <br />
          <TextGrey data-tid="SupplierAddress">{vm.currentUserDepartmentAddress}</TextGrey>
          <br />
        </GridCell>
      </>
    );
  };

  static Recipient = (props: IFieldProps) => {
    const { vm, row } = props;
    return (
      <>
        <GridCell row={row} col={1}>
          Получатель
        </GridCell>
        <GridCell row={row} col={2}>
          <span data-tid="RecipientName">{vm.recipient?.name}</span>
          <br />
          <TextGrey data-tid="RecipientAddress">{vm.consigneeOrRecipientAddress}</TextGrey>
        </GridCell>
      </>
    );
  };

  static RecipientInnKpp = observer((props: IFieldProps) => {
    const { vm, row, margin } = props;

    return (
      <>
        <GridCell row={row} col={1}>
          ИНН получателя
        </GridCell>
        <GridCell data-tid="RecipientInn" row={row} col={2}>
          {vm.recipient?.inn}
        </GridCell>

        <GridCell row={row + 1} col={1} margin={margin}>
          КПП получателя
        </GridCell>
        <GridCell data-tid="RecipientKpp" row={row + 1} col={2}>
          {vm.recipient?.kpp || "—"}
        </GridCell>
      </>
    );
  });
}
