import { action, computed, observable, toJS } from "mobx";
import { copyOnlyExistingProperties } from "helpers";
import { IStore } from "stores/shared";
import { IUserStore } from "stores/UserStore";
import { ContractTypeNames, IType } from "typings/dictionaries";
import { ContractType, DeliveryModel, FinancingType, TurnoverType } from "typings/server";
import { setLSValueInScope } from "helpers/localStorage";

export abstract class ShipmentEditorBaseVM<TError> {
  @observable loading: boolean = false;
  @observable deliveryCopy: DeliveryModel;
  @observable errors: Map<TError, string> = new Map();
  @observable inn: string = "";

  protected submitted: boolean = false;
  protected isDateModified: boolean = true;
  protected currentDate = new Date().toISOString();
  public readonly maxDate: string;
  public scope: string;

  constructor(protected readonly deliveryStore: IStore, protected readonly userStore: IUserStore) {
    this.deliveryCopy = new DeliveryModel();
    copyOnlyExistingProperties(this.deliveryCopy, toJS(this.deliveryStore.selectedDelivery));

    if (!this.deliveryStore.selectedDelivery.reportDate) {
      this.isDateModified = false;
    }

    this.inn = this.recipient?.inn || "";

    this.deliveryCopy.reportDate = this.deliveryStore.selectedDelivery.reportDate || this.currentDate;
    this.maxDate = this.currentDate;
    this.scope = `shipment${this.deliveryCopy.acceptusType}`;
  }

  protected abstract validate(): void;

  protected abstract validateDate(): void;

  abstract saveDelivery(): Promise<void>;

  @computed
  get reportDate() {
    return this.deliveryCopy.reportDate || this.currentDate;
  }

  @computed
  get currentUserDepartmentAddress(): string | undefined {
    return this.userStore.currentDepartment?.address;
  }

  @computed
  get contractType(): IType | undefined {
    const type = this.deliveryCopy.contractType;
    if (type) {
      return { label: ContractTypeNames[type], value: type };
    }
    return undefined;
  }

  @computed
  get companyName(): string {
    return this.userStore.organization?.name || "";
  }

  @computed
  get consigneeOrRecipientAddress() {
    return this.deliveryCopy.recipient?.address;
  }

  @computed
  get recipient() {
    return this.deliveryCopy.recipient;
  }

  @action
  updateDeliveryCopy(delivery: Partial<DeliveryModel>) {
    this.deliveryCopy = { ...this.deliveryCopy, ...delivery };
    if (this.submitted) this.validate();
  }

  @action
  onSave = async () => {
    this.submitted = true;
    this.validate();

    this.setDefaultsOnSave();

    if (!this.errors.size) {
      await this.saveDelivery();
    } else return Promise.reject();
  };

  @action.bound
  setReportDate(date?: string) {
    this.isDateModified = true;
    this.deliveryCopy = { ...this.deliveryCopy, reportDate: date };
    if (this.submitted) this.validateDate();
  }

  private setDefaultsOnSave() {
    // Сохраняем значения в localStorage как дефолты
    setLSValueInScope<TurnoverType>({
      scope: this.scope,
      key: "TurnoverType",
      value: this.deliveryCopy.turnoverType,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });
    setLSValueInScope<ContractType>({
      scope: this.scope,
      key: "ContractType",
      value: this.deliveryCopy.contractType,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });
    setLSValueInScope<FinancingType>({
      scope: this.scope,
      key: "FinancingType",
      value: this.deliveryCopy.financingType,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });
  }

  protected onShowError() {
    this.submitted = true;
    this.validate();
  }
}
