import React from "react";
import { CloudsPage } from "./CloudsPage";
import { TextBlock, IconWrap } from "./ErrorPage.styles";
import { AppEmail, AppMailTo, AuthLink } from "StringResx";
import { Mail, ArrowChevronRight } from "@skbkontur/react-icons";
import { ErrorTitle } from "./ErrorTitle";
import { SupportCenter } from "support";
import { Link } from "components";

interface ErrorPageProps {
  error?: any;
  chatLoaded?: boolean;
  onOpenChat?: () => void;
}

export function ErrorPage({ error, children }: React.PropsWithChildren<ErrorPageProps>) {
  if (!error) {
    return <>{children}</>;
  }

  return (
    <CloudsPage>
      <ErrorTitle status={error.status} />
      <TextBlock>
        <p>
          Попробуйте обновить страницу
          <br />
          Если ошибка повторяется, обратитесь в&nbsp;
          <Link onClick={SupportCenter.open}>техподдержку</Link>
          &#32;
          <br />
          <br />
          {error.status === 403 ? (
            <Link href={AuthLink}>
              На страницу входа
              <IconWrap>
                <ArrowChevronRight />
              </IconWrap>
            </Link>
          ) : (
            <Link icon={<Mail />} href={`${AppMailTo}?subject=Ошибка ${error.status}`}>
              {AppEmail}
            </Link>
          )}
        </p>
      </TextBlock>
    </CloudsPage>
  );
}
