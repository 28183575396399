import React, { SyntheticEvent, useMemo } from "react";
import { observer } from "mobx-react";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { OperationsListItem } from "./OperationsListItem";
import { Loader, Paging } from "components";
import { DeliveryPagingWrapper } from "Common/DeliveriesList/DeliveriesList.styles";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { EmptyDeliveries } from "../MainPage.styles";
import { FilterResetRow } from "Common/Filter/FilterResetRow/FilterResetRow";
import { OperationsHeader } from "./OperationsHeader";

export const colsDefault = "3.5fr 2.5fr 3.7fr 3.5fr 6fr 4.8fr 50px";
export const colsForReentry = "1.8fr 1.8fr 2fr 3.7fr 4.8fr 50px";
export const colsForDestruction = "1.5fr 0.85fr 2.7fr 1.9fr 2.3fr 3fr 50px";

interface IOperationsListProps {
  operations: DeliveryIndexModel[];
  listType: DeliveryType.Withdrawal | DeliveryType.Reentry | DeliveryType.Destruction;
  vm: DeliveriesMainPageVM;
  types: DeliveryType[];
  onClick: (doc: DeliveryIndexModel) => void;
}

export const OperationsList: React.FC<IOperationsListProps> = observer((props: IOperationsListProps) => {
  const { operations, vm, types, listType, onClick } = props;
  const isLoadingList = Boolean(vm.loadingState.get("list"));
  const isLoadingListImmediate = Boolean(vm.loadingState.get("listImmediate"));

  const emptyListText = useMemo(() => {
    switch (listType) {
      case DeliveryType.Withdrawal:
        return "Операций по выводу из оборота еще нет";
      case DeliveryType.Destruction:
        return "Операций по уничтожению еще нет";
      default:
        return "Операций по повторному вводу в оборот еще нет";
    }
  }, [listType]);

  const onDelete = (e: SyntheticEvent<HTMLElement>, delivery: DeliveryIndexModel) => {
    e.stopPropagation();
    vm.setDeliveryToDelete(delivery);
    vm.toggleModal("delete");
  };

  const cols = useMemo(() => {
    switch (listType) {
      case DeliveryType.Destruction:
        return colsForDestruction;
      case DeliveryType.Reentry:
        return colsForReentry;
      default:
        return colsDefault;
    }
  }, []);

  return (
    <>
      <OperationsHeader vm={vm} listType={listType} type={types[0]} />
      <Loader
        data-tid="Loader"
        active={isLoadingList || isLoadingListImmediate}
        immediate={isLoadingListImmediate}
        showChildren
      >
        {vm.isShowFilterResetRow && (
          <FilterResetRow
            totalSize={vm.totalSize}
            plural={["операция", "операции", "операций"]}
            resetFilters={vm.resetAllFiltersAndPage}
            paddingLeft="9px"
          />
        )}
        {operations.map(operation => (
          <OperationsListItem
            data-tid="OperationsListItem"
            cols={cols}
            key={operation.id}
            isPromoted={operation.id === vm.promotedDeliveryId && !!vm.advertisementsMap.get("statuses")}
            operation={operation}
            listType={listType}
            onClick={onClick}
            onDelete={onDelete}
            closePromo={() => vm.setAdvertisementShown("statuses")}
            isCanBeDeleted={vm.isCanBeDeleted(operation)}
          />
        ))}
        {vm.pagesCount > 1 && (
          <DeliveryPagingWrapper>
            <Paging activePage={vm.activePage} onPageChange={vm.setActivePage} pagesCount={vm.pagesCount} />
          </DeliveryPagingWrapper>
        )}
        {vm.isShowEmptyMessageBlock && <EmptyDeliveries>{emptyListText}</EmptyDeliveries>}
      </Loader>
    </>
  );
});
