import styled from "styles/styled-components";

export const PageInfoBlockWrap = styled.div`
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: 35px;
`;

export const DateWrap = styled.div`
  margin-bottom: 20px;
`;

export const DateText = styled.span`
  margin-right: 32px;
`;

export const Title = styled.h4`
  margin-bottom: 15px;
`;

export const TitleSM = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.005em;
  color: ${props => props.theme.textColors.lightenGray};
  margin-top: 16px;
`;

export const Count = styled.span`
  font-size: 22px;
  line-height: 30px;
  margin-right: 3px;
`;

export const TableWrap = styled.div`
  max-width: 885px;
`;
