import React from "react";

interface ErrorTitleProps {
  status: number;
}

export function ErrorTitle(props: ErrorTitleProps) {
  switch (props.status) {
    case 400:
      return (
        <h1>
          Ошибка на странице <sup>{props.status}</sup>
        </h1>
      );
    case 403:
      return (
        <h1>
          Доступ запрещен <sup>{props.status}</sup>
        </h1>
      );
    case 404:
      return (
        <h1>
          Страница не найдена <sup>{props.status}</sup>
        </h1>
      );
    case 408:
      return (
        <h1>
          Истекло время ожидания <sup>{props.status}</sup>
        </h1>
      );
    case 500:
      return (
        <h1>
          Произошла ошибка <sup>{props.status}</sup>
        </h1>
      );
    case 0:
      return <h1>Сервис недоступен</h1>;
    default:
      return (
        <h1>
          Сервис недоступен <sup>{props.status}</sup>
        </h1>
      );
  }
}
