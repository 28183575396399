import React, { PropsWithChildren, useMemo } from "react";
import { ThemeContext as ReactUiThemeContext, Link, LinkProps, FLAT_THEME, ThemeFactory } from "@skbkontur/react-ui";
import { theme } from "styles/theme";

interface Props extends LinkProps {
  theme?: typeof FLAT_THEME;
}

export const ThemedLink = React.memo((props: PropsWithChildren<Props>) => {
  const updatedTheme = useMemo(() => {
    const customPart = props.theme || {};
    return ThemeFactory.create(
      {
        linkColor: theme.colors.darkBlue,
        linkHoverColor: theme.colors.darkBlue,
        ...customPart,
      },
      FLAT_THEME
    );
  }, [props.theme]);

  return (
    <ReactUiThemeContext.Provider value={updatedTheme}>
      <Link {...props} />
    </ReactUiThemeContext.Provider>
  );
});
