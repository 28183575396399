import { action, computed, observable, toJS } from "mobx";
import { IShipmentStore } from "stores/ShipmentStore";
import { IUserStore } from "stores/UserStore";
import { ISignatureStore } from "stores/SignatureStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { AcceptusType, CounteragentInfoModel, DeliveryStatus, DeliveryType, StepInfoModel } from "typings/server";
import { DeliveryActions, DetailPageLoadingName, DetailPageModalNames } from "Common/DeliveryActions";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { IStage } from "models/Delivery/shared";
import { ShipmentDeliveryModel } from "models/Delivery/ShipmentDeliveryModel";
import { ICertificate } from "@skbkontur/plugin-js";
import { getFormattedDate } from "helpers/date";

export class BaseShipmentPageVM {
  actions: DeliveryActions;
  @observable isDeliveryOpenedState: Map<string, boolean> = new Map();
  @observable additionalInfo: CodesAdditionalInfo;
  @observable submitted: boolean = false;
  @observable isRequisitesOpen: boolean = false;

  constructor(
    readonly deliveryStore: IShipmentStore,
    readonly documentStore: ISignatureStore,
    readonly userStore: IUserStore,
    type: DeliveryType
  ) {
    this.actions = new DeliveryActions(deliveryStore, documentStore, userStore, type);
    if (this.deliveryStore.selectedDelivery.status === DeliveryStatus.CreatingDocument) {
      this.actions.startPolling(this.delivery.id);
    }

    if (this.activeDelivery && this.activeDelivery.isActive) {
      this.isDeliveryOpenedState.set(this.activeDelivery.id, true);
    }

    this.additionalInfo = new CodesAdditionalInfo(this.delivery.id, this.delivery.allItemsCodes);
  }

  // поставка в которой надо совершить действие
  @computed
  get activeDelivery() {
    return this.delivery.childDeliveries.find(child => child.isActive) || this.delivery;
  }

  @computed
  get isSimplifiedAccept(): boolean {
    return this.delivery.acceptusType === AcceptusType.Simplified;
  }

  @computed
  get is627message(): boolean {
    // Если это приемка по упрощенке и нет utdPositions, значит это 627 сообщение
    // https://wiki.skbkontur.ru/pages/viewpage.action?pageId=452662689
    return this.isSimplifiedAccept && !this.delivery.utdPositions.length;
  }

  @computed
  get hasDocuments() {
    return this.delivery.hasDocuments;
  }

  @computed
  get title(): string {
    if (this.is627message) {
      return this.delivery.stepsInfo.Draft.completionDate
        ? `По упрощенной схеме от ${getFormattedDate(this.delivery.stepsInfo.Draft.completionDate)}`
        : "";
    }

    if (!this.delivery.stepsInfo.Draft.completionDate) {
      return "";
    }

    if (!this.delivery.documentNumber) {
      return `Отгрузка от${"\u00A0"}${getFormattedDate(this.delivery.stepsInfo.Draft.completionDate)}`;
    }

    return `Отгрузка № ${this.delivery.documentNumber} от${"\u00A0"}${getFormattedDate(
      this.delivery.stepsInfo.Draft.completionDate
    )}`;
  }

  @computed
  get delivery(): ShipmentDeliveryModel {
    return this.deliveryStore.selectedDelivery;
  }

  @computed
  get isAllChildrenSucceed(): boolean {
    return (
      !!this.delivery.childDeliveries.length &&
      this.delivery.childDeliveries.every(child => child.status === DeliveryStatus.Success)
    );
  }

  @computed
  get supplier(): CounteragentInfoModel {
    return this.delivery.supplier;
  }

  @computed
  get recipient() {
    return this.delivery.recipient;
  }

  @computed
  get stages(): Record<DeliveryStage | string, IStage> {
    return this.delivery.stages;
  }

  @computed
  get showSignBtn(): boolean {
    const status = this.activeDelivery.status;
    return status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
  }

  @computed
  get areGoodsEditable(): boolean {
    const { status } = this.activeDelivery;
    return status === DeliveryStatus.New || status === DeliveryStatus.Processing;
  }

  @computed
  get recipientAddress() {
    const { counteragentDepartment } = this.delivery;
    return counteragentDepartment?.address || this.recipient?.address;
  }

  @computed
  get showCompleteStage(): boolean {
    const { status } = this.delivery;
    return (
      status === DeliveryStatus.Success ||
      status === DeliveryStatus.Recalled ||
      status === DeliveryStatus.Rejected ||
      status === DeliveryStatus.PartiallyRejected ||
      status === DeliveryStatus.PartiallyRecalled
    );
  }

  @computed
  get isFailedStatus(): boolean {
    const { status } = this.delivery;
    return (
      status === DeliveryStatus.Failed ||
      status === DeliveryStatus.RecallFailed ||
      status === DeliveryStatus.PartiallyFailed
    );
  }

  @computed
  get isAnyRequisites(): boolean {
    // без toJS, после создания документа, значение isAnyRequisites почему-то берется из кеша
    const { receiveType, contractNumber, contractType, financingType, turnoverType } = toJS(this.delivery);
    return Boolean(receiveType || contractNumber || contractType || financingType || turnoverType);
  }

  @computed
  get modalMap() {
    return this.actions.modalMap;
  }

  @computed
  get loadingMap() {
    return this.actions.loadingMap;
  }

  toggleModal(modalName: DetailPageModalNames) {
    return this.actions.toggleModal(modalName);
  }

  toggleLoading(loadingName: DetailPageLoadingName) {
    return this.actions.toggleLoading(loadingName);
  }

  handleUnmount() {
    return this.actions.handleUnmount();
  }

  getCompletionUser(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionUser(stepInfo);
  }

  getCompletionDate(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionDate(stepInfo);
  }

  getTitleSize(stage: IStage) {
    return this.actions.getTitleSize(this.delivery.stages, stage);
  }

  async sign(cert: ICertificate, shouldSignOnlyChild?: boolean) {
    const { status, childDeliveries, isRecalled, isRejected } = this.delivery;
    const deliveriesToSign: ShipmentDeliveryModel[] = [];
    if (
      (status === DeliveryStatus.Signing || status === DeliveryStatus.Signed) &&
      !(isRejected || isRecalled) &&
      !shouldSignOnlyChild
    ) {
      deliveriesToSign.push(this.delivery);
    }
    if (childDeliveries) {
      childDeliveries.forEach(d => {
        if (d.status === DeliveryStatus.Signing || d.status === DeliveryStatus.Signed) {
          deliveriesToSign.push(d);
        }
      });
    }
    await this.actions.sign(cert, deliveriesToSign);
  }

  getSendingStepName(stage: IStage): string {
    return stage.progress === ProgressStage.Planned ? "Отправка в ИС МДЛП" : "Отправлено в ИС МДЛП";
  }

  @action
  onCollapseDelivery(key: string, val?: boolean) {
    const isOpened = val !== undefined ? val : !this.isDeliveryOpenedState.get(key);
    this.isDeliveryOpenedState.set(key, isOpened);
  }

  @action
  addChildDelivery(overrideFields?: Partial<ShipmentDeliveryModel>) {
    this.delivery.addChildDelivery(overrideFields);
    this.isDeliveryOpenedState.set("new", true);
  }

  prepareDocuments = async () => {
    this.toggleLoading("download");
    try {
      const taskId = await this.deliveryStore.prepareDocuments(this.delivery.id);
      this.actions.startPolling(taskId, "download");
    } catch (e) {
      this.toggleLoading("download");
      throw e;
    }
  };

  @action.bound
  toggleIsRequisitesOpen() {
    this.isRequisitesOpen = !this.isRequisitesOpen;
  }
}
