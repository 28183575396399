import React from "react";
import { UnpackingSidePageBody } from "./UnpackingSidePageBody";
import { SignatureStore } from "stores/SignatureStore";
import { PackingStore } from "stores/PackingStore";
import { PackingDeliveryModel } from "models/Delivery/PackingDeliveryModel";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { ScannerSsccErrorImg, ScannerSsccImg } from "features/ScannerSidePage/ScannerSidePage.styles";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { UnpackingSidePageVM } from "./UnpackingSidePageVM";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface ISidePageProps {
  close(): void;
  save(model: Partial<CodesReportModelExtended>, id?: string): Promise<void>;
  delivery: PackingDeliveryModel;
  additionalInfo?: CodesAdditionalInfo;
  isNew?: boolean;
}

// React.memo + () => true - не обновляет компонент
export const UnpackingSidePage: React.FC<ISidePageProps> = React.memo(
  (props: ISidePageProps) => {
    const { delivery, save, isNew, additionalInfo } = props;
    const checkedAdditionalInfo = additionalInfo || new CodesAdditionalInfo(delivery.id, []);
    const vm = new UnpackingSidePageVM(
      PackingStore,
      SignatureStore,
      AdvertisementStore,
      checkedAdditionalInfo,
      delivery,
      save
    );

    return (
      <ScannerSidePageContainer
        scanText={"Отсканируйте групповые коды для расформирования агрегатов"}
        headerText={"Расформирование агрегатов"}
        saveBtn={isNew ? "Перейти к расформированию" : "Сохранить"}
        placeholderText={"Ввести код агрегата вручную"}
        vm={vm}
        customScannerImg={<ScannerSsccImg />}
        customScannerErrorImg={<ScannerSsccErrorImg />}
        hideSgtinImg
        {...props}
      >
        <UnpackingSidePageBody vm={vm} />
      </ScannerSidePageContainer>
    );
  },
  () => true
);
