import styled from "styles/styled-components";

export const minLoaderH = "134px";

export const HelpIconWrap = styled.div`
  color: ${props => props.theme.colors.darkBlue};
  margin-right: 7px;
  position: relative;
`;

export const InfoText = styled.div`
  width: 465px;
  line-height: 20px;
`;

export const InputBlock = styled.div`
  margin: 0 35px 10px;
  display: flex;
  align-items: center;
`;

export const InputHeaderBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
`;

export const TooltipWrap = styled.div`
  flex-grow: 1;
`;

export const InputWrap = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const ImgBlock = styled.div`
  margin-top: 19px;
  margin-bottom: 19px;
`;

export const SsccImg = styled.img`
  margin-right: 21px;
  margin-top: 1px;
  margin-left: 30px;
  height: 90px;
`;

export const SgtinImg = styled.img`
  height: 90px;
`;

export const AdditionalInfoWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;
