import { PositionReportModel, PositionsReportModel } from "typings/server";
import { UtdPositionModelExtended } from "./Delivery/ItemModel/UtdPositionModelExtended";

export interface IPositionsReportModelExtended extends PositionsReportModel {}

export class PositionsReportModelExtended implements IPositionsReportModelExtended {
  public deliveryId: string;
  public positionReports: PositionReportModel[];

  constructor(deliveryId: string, positions: UtdPositionModelExtended[]) {
    this.deliveryId = deliveryId;
    this.setPositions(positions);
  }

  private setPositions(positions: UtdPositionModelExtended[]) {
    this.positionReports = positions.map(position => {
      const codeInfo = position.allCodes.map(item => ({
        code: item.code,
        seriesNumber: "",
      }));
      return {
        positionId: position.id,
        codeInfo,
      };
    });
  }
}
