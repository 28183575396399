import React from "react";
import { Redirect, Route, Switch, RouteComponentProps } from "react-router-dom";
import { PackingRoute } from "routes";
import { DetailsPageWrapper as UnpackingDetailsPage } from "./Unpacking/DetailsPage/DetailsPageWrapper";
import { DetailsPageWrapper as ExtractionDetailsPage } from "./Extraction/DetailsPage/DetailsPageWrapper";
import { PackingIndexPage } from "./PackingIndexPage";

interface PackingPageWrapperProps extends RouteComponentProps<PackingRoute> {}

export const PackingPageWrapper: React.FC<PackingPageWrapperProps> = (_props: PackingPageWrapperProps) => {
  return (
    <Switch>
      <Redirect exact from={PackingRoute.baseRoute} to={PackingRoute.unpackingBaseRoute} push />
      <Route path={PackingRoute.typeRoute} exact component={PackingIndexPage} />
      <Route path={PackingRoute.unpackingRoute} exact component={UnpackingDetailsPage} />
      <Route path={PackingRoute.extractionRoute} exact component={ExtractionDetailsPage} />
    </Switch>
  );
};
