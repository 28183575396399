import React from "react";
import { observer } from "mobx-react";
import { DeliveryStagesBlock } from "Common/Styles/DetailsPage.styles";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { DateAndFIOWrap } from "Common/Stages/Stages.styles";
import { ReverseShipmentPageVM } from "../ReverseShipmentPageVM";
import { ReverseShipmentStagesPart } from "./ReverseShipmentStagesPart";

interface IStagesProps {
  vm: ReverseShipmentPageVM;
}

export const ReverseShipmentStagesBlock: React.FC<IStagesProps> = observer((props: IStagesProps) => {
  const { vm } = props;

  return (
    <DeliveryStagesBlock>
      <DeliveryStatusBlock
        data-tid="ShippedStage"
        name={vm.stages[DeliveryStage.Shipped].name}
        status={vm.stages[DeliveryStage.Shipped].progress}
        error={vm.stages[DeliveryStage.Shipped].errorInfo}
        alwaysVisibleComp={
          <DateAndFIOWrap data-tid="CompleteInfo">
            {vm.getCompletionDate(vm.delivery.stepsInfo[DeliveryStatus.Draft])}
          </DateAndFIOWrap>
        }
      />

      <ReverseShipmentStagesPart
        data-tid="ProcessingStage"
        delivery={vm.delivery}
        vm={vm}
        overrideFields={{
          name: vm.stages[DeliveryStage.Processing].name,
          isRecalled: false,
          isRejected: false,
        }}
      />

      {vm.delivery.childDeliveries.map(child => (
        <ReverseShipmentStagesPart
          data-tid="ChildStage"
          delivery={child}
          vm={vm}
          key={child.id}
          isPartial={child.allCodesLen !== vm.delivery.allCodesLen}
        />
      ))}

      {vm.showCompleteStage && (
        <DeliveryStatusBlock
          data-tid="DoneStage"
          status={vm.stages[DeliveryStage.Done].progress}
          name={vm.stages[DeliveryStage.Done].name}
          error={vm.stages[DeliveryStage.Done].errorInfo}
          size={vm.getTitleSize(vm.stages[DeliveryStage.Done])}
        />
      )}
    </DeliveryStagesBlock>
  );
});
