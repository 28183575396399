import React from "react";
import { observer } from "mobx-react";
import { Button, Link, Modal, Tooltip } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { DisposalEditorModalVM } from "./DisposalEditorModalVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { UserStore } from "stores/UserStore";
import { RouteComponentProps, withRouter } from "react-router";
import { CirculationRoute } from "routes";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { EditorFields } from "../EditorFields";
import { DocumentHeader, ModalBodyInner } from "../EditorModal.styles";
import { HeaderComment, Instruction, InstructionText } from "./DisposalEditorModal.styles";
import { HelpBook } from "@skbkontur/react-icons";
import { ZnakEmail, ZnakSupportPhone } from "StringResx";
import { CirculationSubRoute } from "routes/CirculationRoute";

interface IOperationInternalModalProps extends IOperationModalProps, RouteComponentProps<CirculationRoute> {
  vm: DisposalEditorModalVM;
}

const DisposalModalInternal = withRouter(
  observer((props: IOperationInternalModalProps) => {
    const { vm, history } = props;

    const onClose = () => props.onClose();

    const onSave = async () => {
      try {
        const id = await vm.save();
        if (id) {
          history.push(CirculationRoute.getHref(id, CirculationSubRoute.withdrawal));
        } else {
          onClose();
        }
      } catch (e) {
        // empty promise for validation fail
        if (e) {
          throw e;
        }
      }
    };

    const renderInstruction = () => {
      return (
        <InstructionText>
          <li>
            Позвонить в техподдержку Честного Знака по номеру <b>{ZnakSupportPhone}</b> или подать обращение на почту{" "}
            <Link href={`mailto:${ZnakEmail}`}>
              <b>{ZnakEmail}</b>
            </Link>
            . В обращении необходимо указать: ИНН организации, идентификатор места осуществления деятельности в ИС МДЛП,
            адрес места установки РВ и описание возникшей проблемы РВ.
          </li>
          <li>Техническая поддержка регистрирует обращение и направляет информацию сервисным инженерам.</li>
          <li>Сервисные инженеры связываются с субъектом обращения с целью устранения проблемы удаленно.</li>
          <li> В случае, если проблему не удается решить удаленно, инженер направляет информацию в ИС МДЛП.</li>
          <li>
            В период неработоспособности РВ возможен вывод из оборота для оказания медицинской помощи в сервисе
            Контур.Маркировка Фарма.
          </li>
        </InstructionText>
      );
    };

    return (
      <Modal data-tid="DisposalEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>
          Вывод из оборота для оказания медицинской помощи
          <HeaderComment>При неработоспособности регистратора выбытия</HeaderComment>
        </Modal.Header>
        <Modal.Body>
          <Instruction>
            <Tooltip render={renderInstruction} trigger={"click"} pos={"right middle"}>
              <HelpBook />
              &nbsp;Инструкция при неработоспособности регистратора выбытия
            </Tooltip>
          </Instruction>
          <ModalBodyInner>
            <Grid cols={"130px 1fr"}>
              <EditorFields.Sender vm={vm} required row={1} />

              <EditorFields.ReportDate vm={vm} required row={2} />

              <GridCell colStart={1} colEnd={3} row={3}>
                <DocumentHeader>Документ-основание</DocumentHeader>
              </GridCell>

              <EditorFields.DocumentNumber vm={vm} required row={4} />

              <EditorFields.DocumentDate vm={vm} required row={5} />
            </Grid>
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer panel>
          <FooterBtnWrapper>
            <Button data-tid="Submit" use="primary" size={"large"} onClick={onSave}>
              {vm.isNew ? "Добавить вывод из оборота" : "Сохранить"}
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  })
);

interface IOperationModalProps {
  onClose(): void;
  delivery?: WithdrawalDeliveryModel;
}

// React.memo + () => true - не обновляет компонент
export const DisposalEditorModal = React.memo(
  (props: IOperationModalProps) => {
    const { delivery } = props;
    const vm = new DisposalEditorModalVM(WithdrawalStore, UserStore, delivery);
    return <DisposalModalInternal {...props} vm={vm} />;
  },
  () => true
);
