import React from "react";
import { Button, Modal } from "components";

interface DeletionModalProps {
  header: string;
  onClose: () => void;
  onDelete: () => void;
}

export function DeletionModal({ header, onClose, onDelete }: DeletionModalProps) {
  return (
    <Modal data-tid="DeletionModal" onClose={onClose}>
      <Modal.Header sticky>{header}</Modal.Header>
      <Modal.Body />
      <Modal.Footer sticky={false}>
        <Button data-tid="Submit" size={"large"} onClick={onDelete} use={"danger"}>
          Удалить
        </Button>
        &nbsp;&nbsp;
        <Button data-tid="Cancel" size={"large"} onClick={onClose}>
          Не удалять
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
