import { SentryExtras, SentryTags } from "helpers/logger";

export class ServerError extends Error {
  public sentryTags?: SentryTags;
  public sentryExtras?: SentryExtras;

  constructor(message: string, public status: number, public headers: Headers, public jsonMsg: any) {
    super(message);
    Object.setPrototypeOf(this, ServerError.prototype);
    this.sentryTags = ServerError.getSentryTags(status, headers);
    this.sentryExtras = ServerError.getSentryExtras(jsonMsg);
  }

  private static getSentryTags = (status: number, headers: Headers) => {
    let tags: SentryTags = {};
    const traceId = headers.get("x-kontur-trace-id");
    const singularBackend = headers.get("x-singular-backend");
    const singularReplica = headers.get("x-singular-replica");

    tags["response-status"] = status.toString();
    if (traceId) tags["trace-id"] = traceId;
    if (singularBackend) tags["singular-backend"] = singularBackend;
    if (singularReplica) tags["singular-replica"] = singularReplica;

    return Object.keys(tags).length > 0 ? tags : undefined;
  };

  private static getSentryExtras = (jsonMsg: any) => {
    return jsonMsg ? { jsonMsg } : undefined;
  }
}
