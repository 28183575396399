import React from "react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { TextGrey } from "styles/shared.styles";

export const EmptyCodesRow = () => (
  <Grid cols={"40px 1fr"} cellsBorderBottom>
    <GridCell col={1} row={1}>
      <TextGrey>1</TextGrey>
    </GridCell>
    <GridCell col={2} row={1}>
      <TextGrey>Нет отсканированных кодов </TextGrey>
    </GridCell>
  </Grid>
);
