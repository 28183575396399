import React from "react";
import { observer } from "mobx-react";
import { Button, Loader, Modal, Toast } from "components";
import { CertificateListControl } from "@skbkontur/certificates-list";
import { ICertificate } from "@skbkontur/plugin-js";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { CertListBody } from "./CertificateList.styles";
import { CustomCertErrors } from "./CryptoProErrors";
import { CertificateListVM } from "./CertificateListVM";
import { DefaultErrorSignBlock, notFoundCertError } from "./shared";

export interface ICertificatesListProps {
  title: string;
  signText?: string;
  onClose(): void;
  onDismiss?(): void; // если пользователь отказался подписывать
  sign(certificate: ICertificate): Promise<void>;
  successText?: string;
  renderSuccessBlock?: () => JSX.Element;
  errorBlock?: React.ElementType;
}

interface ICertificatesListBody extends ICertificatesListProps {
  vm: CertificateListVM;
}

export const CertificatesListBody: React.FC<ICertificatesListBody> = observer((props: ICertificatesListBody) => {
  const { title, renderSuccessBlock, errorBlock, successText, vm } = props;
  const signText = props.signText || "Доступ для организации будет открыт при помощи сертификата:";

  const onSign = async () => {
    try {
      await vm.onSign();
      if (!renderSuccessBlock) {
        if (successText) Toast.push(successText);
        vm.onClose();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const tryAgain = () => {
    vm.clearErrors();
  };

  const getErrorBlock = () => errorBlock && React.createElement(errorBlock, null, [vm.mdlpErrorDescription]);

  return (
    <Modal data-tid="CertificatesListModal" ignoreBackgroundClick onClose={vm.onDismiss}>
      <Modal.Header sticky>{title}</Modal.Header>
      <Modal.Body>
        <CertListBody>
          <Loader data-tid="Loader" active={vm.loading}>
            {!vm.isSigned && !vm.errors.size && (
              <>
                {signText}
                <br />
                <br />
                <CertificateListControl
                  data-tid="CertificateList"
                  customFilter={CertificateListVM.filterCertificate}
                  storeTypes={CertificateListVM.storeTypes}
                  onClick={vm.setCertificate}
                  customErrors={CustomCertErrors}
                />
              </>
            )}

            {vm.isSigned && renderSuccessBlock?.()}
            {!!vm.errors.size && vm.errors.has("certNotFound") && notFoundCertError}
            {!!vm.errors.size &&
              vm.errors.has("defaultSignError") &&
              (getErrorBlock() || <DefaultErrorSignBlock>{vm.mdlpErrorDescription}</DefaultErrorSignBlock>)}
          </Loader>
        </CertListBody>
      </Modal.Body>

      <Modal.Footer panel sticky>
        <FooterBtnWrapper>
          {!vm.loading && (
            <>
              {!vm.isSigned && !vm.errors.size && (
                <>
                  <Button
                    data-tid="Sign"
                    size={"large"}
                    use="primary"
                    disabled={!vm.certificate}
                    width={"189px"}
                    onClick={onSign}
                  >
                    Подписать
                  </Button>
                  <Button data-tid="Cancel" size={"large"} onClick={vm.onDismiss}>
                    Отмена
                  </Button>
                </>
              )}
              {!!vm.errors.size && (
                <Button data-tid="TryAgain" size={"large"} onClick={tryAgain} use={"primary"}>
                  Попробовать еще раз
                </Button>
              )}
              {(vm.isSigned || !!vm.errors.size) && (
                <Button
                  data-tid="Dismiss"
                  size={"large"}
                  onClick={vm.onDismiss}
                  use={vm.isSigned ? "primary" : "default"}
                >
                  Закрыть
                </Button>
              )}
            </>
          )}
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  );
});

// React.memo + () => true - не обновляет компонент
export const CertificatesList = React.memo(
  (props: ICertificatesListProps) => {
    const vm = new CertificateListVM(props.sign, props.onClose, props.onDismiss);

    return <CertificatesListBody {...props} vm={vm} />;
  },
  () => true
);
