import { ScanMode } from "./ScanHelper";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import { Advertisement } from "stores/AdvertisementStore";

export const currentCodeTimeout = 3000; // 3 sec
export const pollCodesTimeout = 5000; // 5 sec

export type TLoadingState = "additionalInfo" | "onSave";

export interface IScannerSidePageVM {
  loadingState: Map<TLoadingState, boolean>;
  currentCode: string | undefined;
  errors: Map<any, string | IErrorObj>;
  warns?: Map<any, string | IErrorObj>;
  allCodesLen?: number;
  allScanned?: boolean;
  ssccScanned?: boolean;
  showWarn?: boolean;
  showError: boolean;
  showCurrentCode: boolean;
  serverErrors?: Map<any, string>;
  mode: ScanMode;
  deliveryId: string;
  positionId?: string;
  inputStr: string;
  disabledSaveBtn?: boolean;
  warnErrorDescription: string;
  warnErrorTitle: string;
  codeAdditionalInfo?: CodeAdditionalInfoModel | undefined;
  advertisementsMap: Map<Advertisement, boolean>;
  onInput: (val: string) => void;
  discardCode: (code: string) => void;
  setCurrentCode?(code: string | undefined): void;
  changeMode(mode: ScanMode): void;
  addScannedCode(code?: string, codeStr?: string): void;
  toggleModal?(s: string): void;
  save(): Promise<void>;
  discardAll(): void;
  handleUnmount(): void;
  setAdvertisementShown(key: Advertisement): void;
  validateSubmit?(): boolean;
}

export interface IErrorObj {
  title: string;
  description: string;
}
