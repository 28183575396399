import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import {
  AcceptusType,
  CreateDisposalModel,
  CreateDisposalWithRegistratorModel,
  CreateReentryModel,
  CreateTrasferenceModel,
  CreateWithdrawalModel,
  DeliveriesFilterModel,
  DeliveriesModel,
  DeliveryModel,
  DeliveryType,
  NewDeliveriesCountModel,
  NewDeliveryModel,
  PatchDeliveryModel,
  SyncInfoModel,
} from "typings/server";
import { headersFormData, getHttpClient } from "./shared";

export type TDeliveryPropertyName = "status" | "scannedCodes" | "items";

class DeliveriesApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getAll(options?: Partial<DeliveriesFilterModel>): Promise<DeliveriesModel> {
    return this.httpClient.get("", options);
  }

  getDelivery(id: string): Promise<DeliveryModel> {
    return this.httpClient.get(`${id}`);
  }

  getCounters(): Promise<NewDeliveriesCountModel> {
    return this.httpClient.get("count/new");
  }

  uploadUtd(file: File, deliveryType: DeliveryType, acceptusType: AcceptusType): Promise<NewDeliveryModel> {
    const formData = new FormData();
    formData.append("file", file);

    return this.httpClient.post("new/uploadUtd", formData, { deliveryType, acceptusType }, headersFormData);
  }

  addNewDelivery(deliveryId: string): Promise<void> {
    return this.httpClient.patch(`new/confirm/${deliveryId}`);
  }

  addNewDisaggregation(sscc: string): Promise<string> {
    return this.httpClient.post(`new/unpacking`, null, { sscc });
  }

  addNewTransfer(model: CreateTrasferenceModel): Promise<string> {
    return this.httpClient.post(`new/transference`, model);
  }

  addNewWithdrawal(model: CreateWithdrawalModel): Promise<string> {
    return this.httpClient.post(`new/withdrawal`, model);
  }

  addNewReentry(model: CreateReentryModel): Promise<string> {
    return this.httpClient.post(`new/reentry`, model);
  }

  addNewDisposal(model: CreateDisposalModel): Promise<string> {
    return this.httpClient.post(`new/disposal`, model);
  }

  addNewDisposalWithRegistrar(model: CreateDisposalWithRegistratorModel): Promise<string> {
    return this.httpClient.post(`new/disposal-registrator`, model);
  }

  addNewOperation(deliveryType: DeliveryType): Promise<string> {
    return this.httpClient.post(`new/${deliveryType}`);
  }

  /**
   * Перезатирает неотправленные поля модели PatchDeliveryModel
   * всегда надо пробрасывать уже заполненные значения
   * @param delivery
   * @param deliveryId
   */
  updateDelivery(delivery: Partial<PatchDeliveryModel>, deliveryId: string): Promise<void> {
    return this.httpClient.patch(`${deliveryId}`, delivery);
  }

  rollbackDeliveryStatus(deliveryId: string): Promise<void> {
    return this.httpClient.patch(`${deliveryId}/rollback`);
  }

  getSyncInfo(): Promise<SyncInfoModel> {
    return this.httpClient.get("synchronization");
  }

  synchronize() {
    return this.httpClient.post("synchronization");
  }

  getDeliveryProperty(deliveryId: string, propertyName: TDeliveryPropertyName) {
    return this.httpClient.get(`${deliveryId}/${propertyName}`);
  }

  deleteDelivery(deliveryId: string) {
    return this.httpClient.delete(deliveryId);
  }
}

export const DeliveriesApi = new DeliveriesApiInternal(getHttpClient(drugsApi + "/deliveries"));
