import React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MenuCountIcon, MenuCountText, MenuItemName, MenuItemStyle, StyledMenuLink } from "./Menu.styles";

interface MenuItemProps extends RouteComponentProps {
  href: string;
  icon: React.FunctionComponentElement<SVGAElement | string>;
  name: string;
  active: boolean;
  count?: number | null;
}

const MenuItemInternal: React.FunctionComponent<MenuItemProps> = observer(
  ({ href, name, icon, count, history, active }: MenuItemProps) => {
    const onClick = () => {
      if (!active) history.push(href);
    };
    return (
      <StyledMenuLink onClick={onClick}>
        <MenuItemStyle href={href} active={active}>
          {icon}
          <MenuItemName data-tid="Title">{name}</MenuItemName>
          {!!count && (
            <MenuCountIcon title="Новые поставки">
              <MenuCountText data-tid="NewDeliveriesCount">{count}</MenuCountText>
            </MenuCountIcon>
          )}
        </MenuItemStyle>
      </StyledMenuLink>
    );
  }
);

export const MenuItem = withRouter(MenuItemInternal);
