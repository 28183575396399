import styled from "styles/styled-components";

export const ModalWrapper = styled.div`
  min-width: 720px;
  max-width: 785px;
  padding-bottom: 5px;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.darkRed};
`;

export const RefreshIconWrap = styled.span`
  margin-left: 10px;
  position: relative;
`;

export const ItemComment = styled.span`
  color: ${props => props.theme.textColors.lightGray};
`;

export const ItemWrap = styled.span`
  display: block;
  margin-top: -5px;
  margin-bottom: -5px;

  padding-top: 5px;
  padding-bottom: 5px;

  &:hover {
    ${ItemComment} {
      color: ${props => props.theme.textColors.disabledWhite};
    }
  }
`;

export const UnregisteredConteragent = styled.span`
  color: ${props => props.theme.colors.orangeDark};
`;

export const HintText = styled.div`
  margin: 0 0 20px 5px;
`;
