import { action, observable } from "mobx";
import { WelcomeApi } from "api/WelcomeApi";

type WelcomeErrors = "notFound";

export class WelcomeVM {
  private static instance: WelcomeVM;
  private readonly inviteId: string | null;
  @observable errors: Map<WelcomeErrors, string> = new Map();
  @observable loading: boolean = true;

  constructor(inviteId: string | null) {
    this.inviteId = inviteId;
  }

  public static getInstance(inviteId: string | null): WelcomeVM {
    if (!WelcomeVM.instance) {
      WelcomeVM.instance = new WelcomeVM(inviteId);
    }
    return WelcomeVM.instance;
  }

  @action
  async checkInvitation() {
    this.errors.clear();
    if (this.inviteId) {
      try {
        await WelcomeApi.createUser(this.inviteId);
      } catch (e) {
        this.errors.set("notFound", "Приглашение не найдено");
        throw e;
      } finally {
        this.loading = false;
      }
    } else {
      this.errors.set("notFound", "Приглашение не найдено");
      this.loading = false;
      return Promise.reject();
    }
  }
}
