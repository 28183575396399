import React from "react";
import { DeliveryStatus, StepInfoModel } from "typings/server";
import { UserNameSeparator } from "../Styles/DetailsPage.styles";
import { DateAndFIOWrap } from "./Stages.styles";

interface Props {
  delivery: { stepsInfo: { [key in DeliveryStatus]: StepInfoModel } };
  step: DeliveryStatus;
  getCompletionDate(stepInfo: StepInfoModel): string;
  getCompletionUser(stepInfo: StepInfoModel): string;
}

export function DateAndUser({ getCompletionUser, getCompletionDate, delivery, step }: Props) {
  const userName = getCompletionUser(delivery.stepsInfo[step]);
  return (
    <DateAndFIOWrap data-tid="CompleteInfo">
      {getCompletionDate(delivery.stepsInfo[step])}
      {userName && (
        <>
          <UserNameSeparator>.</UserNameSeparator>
          {userName}
        </>
      )}
    </DateAndFIOWrap>
  );
}
