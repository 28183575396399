import styled from "styles/styled-components";

export const IconWrap = styled.div`
  color: ${props => props.theme.colors.orangeDark};
  margin-right: 10px;
`;

export const WarningBlock = styled.div`
  background-color: ${props => props.theme.colors.yellowPale};
  margin-top: 18px;
  display: flex;
  padding: 15px 10px;
  border-radius: 3px;
`;
