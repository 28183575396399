import React, { FC, useMemo } from "react";
import { ThemeContext as ReactUiThemeContext, Input, InputProps, FLAT_THEME, ThemeFactory } from "@skbkontur/react-ui";
import { theme } from "styles/theme";

interface Props extends InputProps {
  theme?: Partial<typeof FLAT_THEME>;
  ref?: any;
}

export const ThemedInput: FC<Props> = React.memo(props => {
  const updatedTheme = useMemo(() => {
    const customPart = props.theme || {};
    return ThemeFactory.create(
      {
        inputBorderColorFocus: theme.colors.bluePrimary,
        ...customPart,
      },
      FLAT_THEME
    );
  }, [props.theme]);

  return (
    <ReactUiThemeContext.Provider value={updatedTheme}>
      <Input {...props} />
    </ReactUiThemeContext.Provider>
  );
});
