import styled from "styles/styled-components";
import { DeliveryItemStatus } from "typings/server";
import { theme } from "styles/theme";

interface IItemNameWrapProps {
  status?: DeliveryItemStatus;
}

export const TableWrap = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.darkBackground};
`;

export const ItemWrapper = styled.div`
  position: relative;
`;

export const ItemPosName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
`;

export const TooltipRoot = styled.div`
  max-width: 320px;
`;

export const CollapseIcon = styled.span`
  margin: 0 4px 0 -4px;
  color: ${props => props.theme.colors.darkGray};
`;

export const ItemTextWrap = styled.span<IItemNameWrapProps>`
  color: ${props => getColor(props.status)};
`;

export const CodeStatusRaw = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
`;

export const CodeStatusText = styled.div`
  text-align: left;
`;

function getColor(status?: DeliveryItemStatus) {
  switch (status) {
    case DeliveryItemStatus.Accepted:
      return theme.textColors.green;
    case DeliveryItemStatus.Error:
      return theme.textColors.red;
    default:
      return "";
  }
}
