import { action, computed, observable, toJS } from "mobx";
import { hasRuChars } from "helpers/lang";
import { isDataMatrix, isSgtin, isSscc } from "helpers/codes";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { IScannerSidePageVM } from "../../shared";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { getSortByFunc } from "helpers/array";
import { IStore } from "stores/shared";
import { ScanHelper, ScanMode } from "../../ScanHelper";
import { QRCodeId } from "../../QrCode/QrCodeBlock";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { ISignatureStore } from "stores/SignatureStore";
import { Logger } from "helpers";
import { BaseScanPageVM } from "../BaseScanPageVM";
import { IAdvertisementStore } from "stores/AdvertisementStore";

export class UtdScanSidePageVM extends BaseScanPageVM<IStore, CodeModelExtended, string> implements IScannerSidePageVM {
  @observable copiedGood: UtdPositionModelExtended;
  private readonly good: UtdPositionModelExtended;
  public readonly positionId?: string;

  constructor(
    store: IStore,
    signatureStore: ISignatureStore,
    advertisementStore: IAdvertisementStore,
    additionalInfo: CodesAdditionalInfo,
    good: UtdPositionModelExtended,
    readonly onSaveCodes: () => Promise<void>
  ) {
    super(store, signatureStore, advertisementStore, additionalInfo, store.selectedDelivery.id);
    this.copiedGood = new UtdPositionModelExtended(toJS(good));
    this.good = good;
    this.positionId = good.id;
  }

  @action
  async addScannedCode(code: string, codeStr: string) {
    // clear server error, clear last codeAdditionalInfo
    if (!this.tokenExpired) this.serverErrors.clear();
    if (!this.checkValidation(code, codeStr)) return;
    const codeItem = this.copiedGood.tryToAddCode(code);
    this.setNewCodeItem(codeItem);
    this.setCurrentCode(code);
    this.getCodeAdditionalInfo(code);
  }

  @action
  private checkValidation(code: string, codeStr?: string): boolean {
    this.showError = false;
    this.showWarn = false;
    this.errors.clear();
    this.warns.clear();
    this.setErrorWarningTimeout();
    if (codeStr && hasRuChars(codeStr)) {
      const err = this.isInputMode
        ? "Код маркировки не должен содержать русские буквы"
        : "Русская раскладка клавиатуры. Переключите на английскую и повторите сканирование";
      this.errors.set("lang", err);
      this.showError = true;
      return false;
    }
    if (
      (!isSgtin(code) && !isSscc(code)) ||
      (isSgtin(code) && ScanHelper.isCurrentMode(ScanMode.Scanner) && codeStr && !isDataMatrix(codeStr))
    ) {
      const err = this.isInputMode ? "Неверный формат кода" : "Неверный формат кода. Продолжайте сканирование.";
      this.errors.set("wrongCode", err);
      this.showError = true;

      Logger.message({        
        message: "Некорректный код",
        data: { code, codeStr, deliveryId: this.deliveryId, positionId: this.good.id },
      });

      return false;
    }
    const codeExistInOtherPos = this.store.selectedDelivery.utdPositions.some(
      (pos: UtdPositionModelExtended) => pos.getCode(code) && pos.id !== this.copiedGood.id
    );
    const codeExistInCurPos = !!this.copiedGood.getCode(code);
    if (codeExistInOtherPos || codeExistInCurPos) {
      const err = this.isInputMode ? "Этот код уже есть в списке" : "Этот код уже сканировали";
      this.warns.set("alreadyExist", err);
      this.showWarn = true;
      // show additional info again and blink code
      this.getCodeAdditionalInfo(code).then(() => {
        const found = this.copiedGood.getCode(code);
        if (found) this.setNewCodeItem(found);
      });
      return false;
    }
    return true;
  }

  @action
  async save() {
    this.loadingState.set("onSave", true);
    try {
      if (this.isPhoneMode) {
        await this.updateAndSave();
      } else {
        this.good.setCodes(this.copiedGood.allCodes);
        await this.onSaveCodes();
      }
    } finally {
      this.loadingState.set("onSave", false);
    }
  }

  @action
  private async updateAndSave() {
    // stop polling and get fresh codes from the server before closing side page
    this.pollCodes.stop();
    await this.pollScannedCodesFunc();
    this.good.setCodes(this.copiedGood.allCodes);
    if (this.onSaveCodes) {
      await this.onSaveCodes();
    }
  }

  @action
  discardAll() {
    this.copiedGood.setCodes([]);
    this.resetFields();
  }

  @action
  discardCode = (code: string) => {
    const codeModel = this.copiedGood.getCode(code);
    if (codeModel) {
      this.discardItem(codeModel);
      this.resetFields();
    }
  };

  @action
  discardItem(codeModel: CodeModelExtended): void {
    const { code } = codeModel;
    this.copiedGood.removeCode(code);
    this.deletedCodes.push(code);
    if (this.codeAdditionalInfo && this.codeAdditionalInfo.code === code) {
      this.additionalInfo.codesInfo.delete(code);
    }
  }

  @action
  protected async pollScannedCodesFunc() {
    const items = await this.store.getItems(this.deliveryId);
    if (items) {
      items.forEach(item => {
        if (item.positionId === this.positionId && !this.deletedCodes.includes(item.code)) {
          this.copiedGood.tryToAddCode(item.code);
        }
      });
    }
  }

  @computed
  get allScannedLen(): number {
    return this.copiedGood.sgtinScanLen + this.copiedGood.ssccScanLen;
  }

  @computed
  get sortedCodes(): CodeModelExtended[] {
    return this.copiedGood.allCodes.sort(getSortByFunc("-index"));
  }

  @computed
  get warnErrorDescription(): string {
    return this.errors.values().next().value || this.warns.values().next().value;
  }

  @computed
  get warnErrorTitle(): string {
    return this.warnErrorDescription;
  }

  createQRCode() {
    ScanHelper.createQRCode(QRCodeId, this.deliveryId, null, this.positionId);
  }
}
