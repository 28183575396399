import { action, observable } from "mobx";
import { CodeSyncStatus, DeliveryItemStatus } from "typings/server";
import { isSgtin } from "helpers";

export interface ICode {
  code: string;
  scanned: boolean;
  codeMdlpStatus?: string;
  codeSyncStatus?: CodeSyncStatus;
  dataMatrix?: string;
  index?: number;
  status?: DeliveryItemStatus;
  statusReason?: string;
  itemsCount?: number;
  isGtin?: boolean;
  part?: number;
  totalParts?: number;
}

export class CodeModelExtended implements ICode {
  code: string;
  codeMdlpStatus?: string;
  codeSyncStatus?: CodeSyncStatus;
  dataMatrix?: string;
  index?: number;
  status?: DeliveryItemStatus;
  statusReason?: string;
  itemsCount?: number;
  readonly isGtin: boolean;
  @observable scanned: boolean;
  @observable part?: number;
  @observable totalParts?: number;

  constructor(obj: ICode) {
    Object.assign(this, obj);
    this.isGtin = isSgtin(this.code);
  }

  @action.bound
  setScanned(scanned: boolean) {
    this.scanned = scanned;
  }

  @action.bound
  setPart(part?: number) {
    this.part = part;
  }

  @action.bound
  setTotalPart(totalParts?: number) {
    this.totalParts = totalParts;
  }
}
