import React, { useEffect, useState } from "react";
import { DeliveryItemModel, DeliveryType } from "typings/server";
import { Button, Modal } from "components";
import { ButtonWrap, Fill } from "./DeleteDeliveryModal.styles";

interface DeleteDeliveryModalProps {
  deliveryType: DeliveryType;
  isDeliveryError?: boolean;
  isScannedCodes?: boolean;
  isLoading?: boolean;
  onDelete(): void;
  onClose(): void;
  getDeliveryItems?(): Promise<DeliveryItemModel[] | undefined>;
}

export function DeleteDeliveryModal({
  isDeliveryError,
  isScannedCodes,
  deliveryType,
  isLoading,
  onClose,
  onDelete,
  getDeliveryItems,
}: DeleteDeliveryModalProps) {
  const [deliveryItems, setDeliveryItems] = useState<DeliveryItemModel[] | undefined>(undefined);

  useEffect(() => {
    if (isScannedCodes === undefined) {
      getItems();
    }

    async function getItems() {
      if (getDeliveryItems) {
        const items = await getDeliveryItems();
        setDeliveryItems(items);
      }
    }
  }, [isScannedCodes]);
  const content = renderContent();
  return (
    <Modal data-tid="DeleteDeliveryModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header>{renderHeader()}</Modal.Header>
      {content ? <Modal.Body>{content}</Modal.Body> : <Fill />}
      <Modal.Footer>
        <ButtonWrap>
          <Button data-tid="Delete" use="danger" onClick={onDelete} loading={isLoading}>
            Удалить
          </Button>
        </ButtonWrap>
        <Button data-tid="Cancel" use="default" onClick={onClose}>
          Не удалять
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function renderHeader() {
    const { draftStr, errorStr } = getHeaderStringMap();
    if (isDeliveryError) {
      return errorStr[deliveryType];
    }
    return draftStr[deliveryType];
  }

  function renderContent() {
    if (isDeliveryError) {
      const { errorStr, impossibleStr } = getContentStr();
      return (
        <>
          {errorStr[deliveryType]}
          <br />
          {impossibleStr[deliveryType]}
        </>
      );
    }

    if (isScannedCodes || (deliveryItems && deliveryItems.length > 0)) {
      return (
        <>
          Вы собираетесь удалить черновик с отсканированными кодами.
          <br />
          Восстановить удаленный черновик невозможно.
        </>
      );
    }

    return null;
  }

  function getHeaderStringMap() {
    return {
      errorStr: {
        [DeliveryType.Acceptance]: "Удалить приемку?",
        [DeliveryType.Shipment]: "Удалить отгрузку?",
        [DeliveryType.Unpacking]: "Удалить расформирование?",
        [DeliveryType.Extraction]: "Удалить изъятие?",
        [DeliveryType.Transference]: "Удалить перемещение?",
        [DeliveryType.Withdrawal]: "Удалить вывод из оборота?",
        [DeliveryType.Disposal]: "Удалить вывод из оборота?",
        [DeliveryType.Reentry]: "Удалить повторный вввод в оборот?",
        [DeliveryType.DisposalWithRegistrator]: "Удалить вывод из оборота?",
        [DeliveryType.Destruction]: "Удалить передачу на уничтожение?",
        [DeliveryType.Enclosure]: "",
      },
      draftStr: {
        [DeliveryType.Acceptance]: "Удалить черновик приемки?",
        [DeliveryType.Shipment]: "Удалить черновик отгрузки?",
        [DeliveryType.Unpacking]: "Удалить черновик расформирования?",
        [DeliveryType.Extraction]: "Удалить черновик изъятия?",
        [DeliveryType.Transference]: "Удалить черновик перемещения?",
        [DeliveryType.Withdrawal]: "Удалить черновик вывода из оборота?",
        [DeliveryType.Reentry]: "Удалить черновик повторного вввода в оборот?",
        [DeliveryType.Disposal]: "Удалить черновик вывода из оборота?",
        [DeliveryType.DisposalWithRegistrator]: "Удалить черновик вывода из оборота?",
        [DeliveryType.Destruction]: "Удалить черновик передачи на уничтожение?",
        [DeliveryType.Enclosure]: "",
      },
    };
  }

  function getContentStr() {
    return {
      errorStr: {
        [DeliveryType.Acceptance]: "Вы собираетесь удалить приемку с ошибкой.",
        [DeliveryType.Shipment]: "Вы собираетесь удалить отгрузку с ошибкой.",
        [DeliveryType.Unpacking]: "Вы собираетесь удалить расформирование с ошибкой.",
        [DeliveryType.Extraction]: "Вы собираетесь удалить изъятие с ошибкой.",
        [DeliveryType.Transference]: "Вы собираетесь удалить перемещение с ошибкой.",
        [DeliveryType.Withdrawal]: "Вы собираетесь удалить вывод из оборота с ошибкой.",
        [DeliveryType.Reentry]: "Вы собираетесь удалить повторный ввод в оборот с ошибкой.",
        [DeliveryType.Disposal]: "Вы собираетесь удалить вывод из оборота с ошибкой.",
        [DeliveryType.DisposalWithRegistrator]: "Вы собираетесь удалить вывод из оборота с ошибкой.",
        [DeliveryType.Destruction]: "Вы собираетесь удалить передачу на уничтожение с ошибкой.",
        [DeliveryType.Enclosure]: "",
      },
      impossibleStr: {
        [DeliveryType.Acceptance]: "Восстановить удаленную приемку невозможно.",
        [DeliveryType.Shipment]: "Восстановить удаленную отгрузку невозможно.",
        [DeliveryType.Unpacking]: "Восстановить удаленное расформирование невозможно.",
        [DeliveryType.Extraction]: "Восстановить удаленное изъятие невозможно.",
        [DeliveryType.Transference]: "Восстановить удаленное перемещение невозможно.",
        [DeliveryType.Withdrawal]: "Восстановить удаленный вывод из оборота невозможно.",
        [DeliveryType.Reentry]: "Восстановить удаленный повторный ввод в оборот невозможно.",
        [DeliveryType.Disposal]: "Восстановить удаленный вывод из оборота невозможно.",
        [DeliveryType.Destruction]: "Восстановить удаленную передачу на уничтожение невозможно.",
        [DeliveryType.DisposalWithRegistrator]: "Восстановить удаленный вывод из оборота невозможно.",
        [DeliveryType.Enclosure]: "",
      },
    };
  }
}
