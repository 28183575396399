import React from "react";
import { DeliveryStatus } from "typings/server";
import NotificationBellOnIcon from "@skbkontur/react-icons/NotificationBellOn";
import ShipmentIcon from "@skbkontur/react-icons/Shipment";
import CertificateIcon from "@skbkontur/react-icons/Certificate";
import Send2Icon from "@skbkontur/react-icons/Send2";
import ClockIcon from "@skbkontur/react-icons/Clock";
import OkIcon from "@skbkontur/react-icons/Ok";
import ErrorIcon from "@skbkontur/react-icons/Error";
import { DocumentSolid } from "@skbkontur/react-icons";
import { theme } from "styles/theme";
import ClearIcon from "@skbkontur/react-icons/Clear";

type TStatus = {
  color?: string;
  icon?: JSX.Element;
};

export const DeliveryStatuses: Record<DeliveryStatus, TStatus> = {
  [DeliveryStatus.Draft]: { icon: <DocumentSolid />, color: theme.textColors.lightenGray },
  [DeliveryStatus.New]: { icon: <NotificationBellOnIcon />, color: theme.colors.orangeDark },
  [DeliveryStatus.Processing]: { icon: <ShipmentIcon /> },
  [DeliveryStatus.Signing]: { icon: <CertificateIcon /> },
  [DeliveryStatus.Signed]: { icon: <CertificateIcon /> },
  [DeliveryStatus.Sending]: { icon: <Send2Icon /> },
  [DeliveryStatus.CreatingDocument]: { icon: <ClockIcon /> },
  [DeliveryStatus.MdlpProcessing]: { icon: <ClockIcon /> },
  [DeliveryStatus.WaitingForCounterparty]: { icon: <ClockIcon /> },
  [DeliveryStatus.Success]: { icon: <OkIcon />, color: theme.colors.greenDark },
  [DeliveryStatus.Failed]: { icon: <ErrorIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.PartiallyAccepted]: { icon: <OkIcon /> },
  [DeliveryStatus.Rejected]: { icon: <ClearIcon />, color: theme.colors.darkGray },
  [DeliveryStatus.Recalled]: { icon: <ClearIcon />, color: theme.colors.darkGray },
  [DeliveryStatus.PartiallyRecalled]: { icon: <ClearIcon />, color: theme.colors.darkGray },
  [DeliveryStatus.RecallFailed]: { icon: <ErrorIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.PartiallyFailed]: { icon: <OkIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.PartiallyFailedIncompleted]: { icon: <ErrorIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.Deleted]: {},
  [DeliveryStatus.PartiallyRejected]: { icon: <ClearIcon />, color: theme.colors.darkGray },
};

export const OperationStatuses: Record<DeliveryStatus, TStatus> = {
  [DeliveryStatus.Draft]: { icon: <DocumentSolid /> },
  [DeliveryStatus.New]: { icon: <DocumentSolid /> },
  [DeliveryStatus.Processing]: { icon: <DocumentSolid /> },
  [DeliveryStatus.Signing]: { icon: <CertificateIcon /> },
  [DeliveryStatus.Signed]: { icon: <CertificateIcon /> },
  [DeliveryStatus.Sending]: { icon: <Send2Icon /> },
  [DeliveryStatus.CreatingDocument]: { icon: <ClockIcon /> },
  [DeliveryStatus.MdlpProcessing]: { icon: <ClockIcon /> },
  [DeliveryStatus.WaitingForCounterparty]: { icon: <ClockIcon /> },
  [DeliveryStatus.Success]: { icon: <OkIcon />, color: theme.colors.greenDark },
  [DeliveryStatus.Failed]: { icon: <ErrorIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.Recalled]: { icon: <ClearIcon />, color: theme.textColors.lightenGray },
  [DeliveryStatus.PartiallyRecalled]: { icon: <ClearIcon />, color: theme.colors.darkGray },
  [DeliveryStatus.PartiallyAccepted]: { icon: <OkIcon /> },
  [DeliveryStatus.Rejected]: { icon: <ClearIcon />, color: theme.colors.darkGray },
  [DeliveryStatus.RecallFailed]: { icon: <ErrorIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.PartiallyFailed]: { icon: <OkIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.PartiallyFailedIncompleted]: { icon: <ErrorIcon />, color: theme.colors.darkRed },
  [DeliveryStatus.Deleted]: {},
  [DeliveryStatus.PartiallyRejected]: { icon: <ClearIcon />, color: theme.colors.darkGray },
};

export const DestructionStatuses: Record<DeliveryStatus, TStatus> = {
  ...OperationStatuses,
  [DeliveryStatus.WaitingForCounterparty]: {
    icon: <NotificationBellOnIcon color={theme.colors.orangeDark} />,
    color: theme.colors.orangeDark,
  },
};
