import styled from "styles/styled-components";

export const Container = styled.div`
  margin-left: 4px;
`;

export const ControlPanel = styled.div`
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;
  align-items: center;
`;

export const DateWrap = styled.div`
  margin-right: 57px;
`;

export const ToggleWrap = styled.div`
  cursor: pointer;
`;

export const CheckBoxWrap = styled.div`
  cursor: pointer;
  margin-left: 30px;
`;

export const DateText = styled.span`
  margin-right: 32px;
`;

export const ScanPanel = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: baseline;
`;

export const ScanPanelText = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;
`;

export const CodesBlock = styled.div`
  margin-right: 34px;
`;

export const ChildWrap = styled.div`
  margin-top: 10px;
`;
