import styled from "./styled-components";

export const TextGrey = styled.span`
  color: ${props => props.theme.textColors.lightenGray};
`;

export const TextSecondary = styled.span`
  color: ${props => props.theme.textColors.secondaryText};
`;

export const TextBold = styled.span`
  font-weight: 600;
`;

export const TextGreen = styled.span`
  color: ${props => props.theme.textColors.green};
`;

export const TextOrange = styled.span`
  color: ${props => props.theme.colors.orangeDark};
`;

export const TextRed = styled.span`
  color: ${props => props.theme.textColors.red};
`;

export const TextDarkRed = styled.span`
  color: ${props => props.theme.textColors.darkRed};
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const Capitalized = styled.span`
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const LabelRequired = styled.div`
  position: relative;

  &:after {
    content: "*";
    position: relative;
    color: ${props => props.theme.colors.red};
    top: -3px;
    right: -2px;
  }
`;
