import "core-js/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./sentry";
import { BrowserRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import { WelcomeRoute } from "./routes";
import { Welcome } from "./features/Welcome/Welcome";
import "./support";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import { prepareEnvironment } from "./enviroment";

prepareEnvironment();

//todo: move  <Route path={WelcomeRoute} component={Welcome} /> inside App
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={WelcomeRoute} component={Welcome} />
      <Route component={App} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
