import { action, computed, observable, toJS } from "mobx";
import {
  AcceptusType,
  DeliveriesFilterModel,
  DeliveryStatus,
  DeliveryType,
  ReturnReason,
  WithdrawalCause,
  WithdrawalReason,
} from "typings/server";
import { DELIVERIES_PER_PAGE } from "stores/shared";
import {
  acceptanceStatuses,
  packingStatuses,
  shipmentStatuses,
  transferStatuses,
  withdrawalStatuses,
  reentryStatuses,
  destructionStatuses,
} from "./availableStatuses";
import { orderedAcceptusTypes } from "./orderedAcceptusTypes";
import { DisposalReason } from "features/Circulation/WithdrawalDictionary";
import { isEqual } from "lodash-es";

export type WithFilterInfo<T> = { name: T; isFilterApplied: boolean };

export class FilterModel {
  @observable activePage: number = 1;
  @observable pagesCount: number;
  @observable private allowedTypes: DeliveryType[] = this.defaultAllowedTypes;
  @observable allowedStatuses: WithFilterInfo<DeliveryStatus>[] = [];
  @observable allowedCounteragents: WithFilterInfo<string>[] = [];
  @observable documentNumberQuery: string = "";
  @observable aggregateNumberQuery: string = "";
  @observable allowedDestinations: WithFilterInfo<string>[] = [];
  @observable allowedAcceptusType: WithFilterInfo<AcceptusType>[] = [];
  @observable allowedWithdrawalReason: WithFilterInfo<DisposalReason | WithdrawalReason>[] = [];
  @observable allowedWithdrawalCauses: WithFilterInfo<WithdrawalCause>[] = [];
  @observable allowedReturnReasons: WithFilterInfo<ReturnReason>[] = [];
  @observable isPreserveFilters = true;

  private loadingCount: number = DELIVERIES_PER_PAGE;

  constructor(private defaultAllowedTypes: DeliveryType[]) {}

  setDefaultAllowedTypes(types: DeliveryType[]) {
    this.defaultAllowedTypes = types;
  }

  private get startFrom(): number {
    return (this.activePage - 1) * this.loadingCount;
  }

  @computed
  get isAnyFilterApplied() {
    return (
      !isEqual(toJS(this.allowedTypes).sort(), toJS(this.defaultAllowedTypes).sort()) ||
      this.allowedStatuses.some(x => x.isFilterApplied) ||
      this.allowedCounteragents.some(x => x.isFilterApplied) ||
      this.documentNumberQuery.length > 0 ||
      this.aggregateNumberQuery.length > 0 ||
      this.allowedDestinations.some(x => x.isFilterApplied) ||
      this.allowedAcceptusType.some(x => x.isFilterApplied) ||
      this.allowedWithdrawalReason.some(x => x.isFilterApplied) ||
      this.allowedWithdrawalCauses.some(x => x.isFilterApplied) ||
      this.allowedReturnReasons.some(x => x.isFilterApplied)
    );
  }

  @action
  public setIsPreserveFilters(isPreserve: boolean) {
    this.isPreserveFilters = isPreserve;
  }

  @action
  public setActivePage(page: number) {
    this.activePage = page;
  }

  @action
  public setPagesCount(totalSize: number) {
    this.pagesCount = Math.ceil(totalSize / this.loadingCount);
  }

  @action
  public resetAllFiltersAndPage() {
    this.resetAllFilters();
    this.setActivePage(1);
  }

  @action resetAllFilters() {
    this.allowedTypes = this.defaultAllowedTypes;
    this.resetFilter(this.allowedStatuses);
    this.resetFilter(this.allowedCounteragents);
    this.resetFilter(this.allowedDestinations);
    this.resetFilter(this.allowedAcceptusType);
    this.resetFilter(this.allowedWithdrawalReason);
    this.resetFilter(this.allowedWithdrawalCauses);
    this.resetFilter(this.allowedReturnReasons);
    this.documentNumberQuery = "";
    this.aggregateNumberQuery = "";
  }

  @action resetFilter<T>(filterItems: WithFilterInfo<T>[]) {
    filterItems.forEach(x => {
      x.isFilterApplied = false;
    });
  }

  @action
  setDocumentNumberQuery(query: string) {
    this.documentNumberQuery = query;
    this.activePage = 1;
  }

  @action
  setAggregateNumberQuery(query: string) {
    this.aggregateNumberQuery = query;
  }

  @action
  public setAllowedTypes(allowedTypes: DeliveryType[]) {
    this.allowedTypes = allowedTypes;
  }

  @action
  setAllowedStatuses(filters: DeliveryStatus[], type: DeliveryType) {
    const availableFilters = this.getStatusesAvailableFilters(type);
    this.allowedStatuses = this.applyFilterFields(filters, this.allowedStatuses, availableFilters);
  }

  @action
  setAllowedCounteragents(filters: string[]) {
    this.allowedCounteragents = this.applyFilterFields(filters, this.allowedCounteragents);
  }

  @action
  setAllowedDestinations(filters: string[]) {
    this.allowedDestinations = this.applyFilterFields(filters, this.allowedDestinations);
  }

  @action
  setAllowedAcceptusType(filters: AcceptusType[]) {
    this.allowedAcceptusType = this.applyFilterFields(filters, this.allowedAcceptusType, orderedAcceptusTypes);
  }

  @action
  setAllowedWithdrawalCauses(filters: WithdrawalCause[]) {
    this.allowedWithdrawalCauses = this.applyFilterFields(filters, this.allowedWithdrawalCauses);
  }

  @action
  setAllowedReturnReasons(filters: ReturnReason[]) {
    this.allowedReturnReasons = this.applyFilterFields(filters, this.allowedReturnReasons);
  }

  @action
  setAllowedWithdrawalReason(filters: Array<WithdrawalReason | DisposalReason>, types: DeliveryType[]) {
    const isDisposal = types.some(x => x === DeliveryType.Disposal || x === DeliveryType.DisposalWithRegistrator);
    if (isDisposal) {
      if (filters.length === 0) {
        filters = this.allowedWithdrawalReason.map(x => x.name);
      } else {
        filters.push(DisposalReason.MedicalAssistance);
      }
    }
    this.allowedWithdrawalReason = this.applyFilterFields(filters, this.allowedWithdrawalReason);
  }

  private applyFilterFields<T>(
    filterNames: T[] | undefined,
    oldData: WithFilterInfo<T>[],
    availableFilters?: T[]
  ): WithFilterInfo<T>[] {
    if (!filterNames) {
      return [];
    }
    const checkedFilterNames = availableFilters
      ? availableFilters.filter(x => filterNames.includes(x))
      : filterNames.sort();

    return checkedFilterNames.map(x => {
      const existedFilter = oldData.find(old => old.name === x);
      return {
        name: x,
        isFilterApplied: existedFilter?.isFilterApplied || false,
      };
    });
  }

  @action
  toggleFilter<T>(filter: WithFilterInfo<T>) {
    filter.isFilterApplied = !filter.isFilterApplied;
    this.activePage = 1;
  }

  public getFullFiltersInfo(): Partial<DeliveriesFilterModel> {
    return {
      sscc: this.aggregateNumberQuery.trim(),
      documentNumber: this.documentNumberQuery.trim(),
      allowedStatuses: toJS(this.getFilterForRequest(this.allowedStatuses)),
      allowedCounteragents: toJS(this.getFilterForRequest(this.allowedCounteragents)),
      allowedDestinations: toJS(this.getFilterForRequest(this.allowedDestinations)),
      allowedTypes: toJS(this.getAllowedTypes()),
      allowedAcceptusTypes: toJS(this.getFilterForRequest(this.allowedAcceptusType)),
      allowedWithdrawalReasons: toJS(
        this.getFilterForRequest(
          this.allowedWithdrawalReason.filter(
            x => x.name !== DisposalReason.MedicalAssistance
          ) as WithFilterInfo<WithdrawalReason>[]
        )
      ),
      allowedWithdrawalCauses: toJS(this.getFilterForRequest(this.allowedWithdrawalCauses)),
      allowedReturnReasons: toJS(this.getFilterForRequest(this.allowedReturnReasons)),
      count: this.loadingCount,
      startFrom: this.startFrom,
    };
  }

  private getAllowedTypes() {
    if (this.allowedTypes.some(x => x === DeliveryType.Withdrawal)) {
      return this.getAllowedTypesByWithdrawalReason();
    }

    if (this.allowedTypes.some(x => x === DeliveryType.Destruction)) {
      return [DeliveryType.Destruction];
    }
    return this.defaultAllowedTypes;
  }

  private getAllowedTypesByWithdrawalReason() {
    const isWithdrawalSelected = this.allowedWithdrawalReason.some(
      x => x.name !== DisposalReason.MedicalAssistance && x.isFilterApplied
    );
    const isDisposalSelected = this.allowedWithdrawalReason.some(
      x => x.name === DisposalReason.MedicalAssistance && x.isFilterApplied
    );
    const isNoSelected = this.allowedWithdrawalReason.every(x => !x.isFilterApplied);

    // ничего не выбрано или выбрано и withdrawal и disposal с disposalWithRegistrator
    if (isNoSelected || (isWithdrawalSelected && isDisposalSelected)) {
      return this.defaultAllowedTypes;
    }
    // только withdrawal
    if (isWithdrawalSelected) {
      return [DeliveryType.Withdrawal];
    }
    // только disposal и disposalWithRegistrator
    if (isDisposalSelected) {
      return [DeliveryType.Disposal, DeliveryType.DisposalWithRegistrator];
    }
  }

  private getStatusesAvailableFilters(type: DeliveryType) {
    switch (type) {
      case DeliveryType.Acceptance:
        return acceptanceStatuses;
      case DeliveryType.Shipment:
        return shipmentStatuses;
      case DeliveryType.Unpacking:
      case DeliveryType.Extraction:
        return packingStatuses;
      case DeliveryType.Transference:
        return transferStatuses;
      case DeliveryType.Withdrawal:
      case DeliveryType.Disposal:
        return withdrawalStatuses;
      case DeliveryType.Reentry:
        return reentryStatuses;
      case DeliveryType.Destruction:
        return destructionStatuses;
      default:
        throw new Error("Поддержи новый тип операции в фильтрах models/Filter/FilterModel.ts");
    }
  }

  private getFilterForRequest<T>(filters: WithFilterInfo<T>[]): T[] {
    return filters.filter(x => x.isFilterApplied).map(x => x.name);
  }
}
