import { ReactNotificationOptions } from "react-notifications-component";

export const errorNotification: ReactNotificationOptions = {
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 120000, // 2 минуты
    showIcon: true,
    click: false,
  },
};

export const warnNotification: ReactNotificationOptions = {
  type: "warning",
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 0,
    showIcon: true,
  },
};
