import styled from "styles/styled-components";

export const Divider = styled.span`
  margin: 0 3px;
`;

export const ArrowWrap = styled.span`
  position: relative;
  top: 1px;
`;

export const DeleteIconWrap = styled.span`
  margin-left: 2px;
  cursor: pointer;
`;

export const TooltipTextWrap = styled.div`
  max-width: 180px;
`;

export const PartTitle = styled.span`
  line-height: 32px;
`;
