import React from "react";
import { observer } from "mobx-react";
import {
  StatusTitleBlock,
  StatusIcon,
  StatusBlock,
  StatusName,
  StatusIconArrow,
  DeliveryStatusWrap,
  CollapsedTextBlock,
  ErrorText,
  StatusBlockContent,
  ErrorWrap,
  LinkWrap,
  BillingErrorText,
} from "./DeliveryStatus.styles";
import ArrowChevronDownIcon from "@skbkontur/react-icons/ArrowChevronDown";
import ArrowChevronUpIcon from "@skbkontur/react-icons/ArrowChevronUp";
import { ErrorInfoModel } from "typings/server";
import { HelpDot } from "@skbkontur/react-icons";
import { Link } from "components";
import { MDLPErrorsInstruction, OrderMoreCodesUrl } from "StringResx";

export enum ProgressStage {
  InProgress = "inProgress",
  Done = "done",
  Planned = "planned",
}

export type StatusSize = "large" | "medium";

export interface DeliveryStatusBlockProps {
  status: ProgressStage;
  name: string;
  size?: StatusSize;
  error?: ErrorInfoModel | boolean;
  isRejected?: boolean;
  isRecalled?: boolean;
  // Пропс, если отмена пошла по инициативе самого юзера (цвет индикатора будет другим)
  isRecalledByClient?: boolean;
  alwaysVisibleComp?: string | JSX.Element;
  collapsed?: boolean;
  onCollapse?(): void;
}

export const DeliveryStatusBlock: React.FC<DeliveryStatusBlockProps> = observer(
  ({
    name,
    status,
    size,
    alwaysVisibleComp,
    error,
    isRecalledByClient,
    isRecalled,
    isRejected,
    collapsed,
    onCollapse,
    children,
    ...restProps
  }: React.PropsWithChildren<DeliveryStatusBlockProps>) => {
    const toggleCollapsed = () => onCollapse?.();
    const isStepFinished = status === ProgressStage.Done;
    const isStepPlanned = status === ProgressStage.Planned;
    const isCollapsible = Boolean(onCollapse);
    const errorDescription = typeof error !== "boolean" && error?.errorDescription;
    return (
      <DeliveryStatusWrap {...restProps}>
        <StatusTitleBlock
          data-tid="Title"
          size={size}
          collapsible={isCollapsible}
          onClick={toggleCollapsed}
          isPlanned={isStepPlanned}
        >
          <StatusIcon
            data-tid="Status"
            status={status}
            hasError={Boolean(error)}
            isRecalledByClient={isRecalledByClient}
            isRecalled={isRecalled}
            isRejected={isRejected}
          />
          <StatusName>{name}</StatusName>
          {isCollapsible && (
            <StatusIconArrow>{collapsed ? <ArrowChevronDownIcon /> : <ArrowChevronUpIcon />}</StatusIconArrow>
          )}
        </StatusTitleBlock>
        <StatusBlock finished={isStepFinished}>
          <CollapsedTextBlock additionalMargin={size === "large"}>{alwaysVisibleComp}</CollapsedTextBlock>
          {errorDescription ? (
            <>
              <ErrorWrap>
                <ErrorText data-tid="ErrorDescription">{errorDescription}</ErrorText>
                <LinkWrap>
                  <Link href={MDLPErrorsInstruction} icon={<HelpDot />} target="_blank">
                    Как исправить ошибки ИС МДЛП
                  </Link>
                </LinkWrap>
              </ErrorWrap>
              {errorDescription.includes("Ошибка отправки: недостаточно оплаченных кодов") && (
                <BillingErrorText>
                  Для продления обслуживания{" "}
                  <Link href={OrderMoreCodesUrl} target="_blank">
                    оставьте заявку на оплату
                  </Link>
                  . После продления вы сможете заново отправить сведения по этой операции в ИС МДЛП.
                </BillingErrorText>
              )}
            </>
          ) : (
            <StatusBlockContent collapsed={collapsed}>{children}</StatusBlockContent>
          )}
        </StatusBlock>
      </DeliveryStatusWrap>
    );
  }
);
