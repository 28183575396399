import React from "react";
import { observer } from "mobx-react";
import {
  AcceptedText,
  Code,
  InfoBlock,
  InfoTitle,
  SgtinProducerWidth,
  SgtinProducerWidthInputMode,
  SsccProducerWidth,
} from "./AdditionalInfo.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { getFormattedDate } from "helpers";
import { TextSecondary } from "styles/shared.styles";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import { EllipsisText } from "components/EllipsisText/EllipsisText";
import { DiscardTopBlockWrap } from "../ScannerSidePage.styles";
import { Button } from "components";
import { AcceptedStatusIcon } from "icons";
import { Undo } from "@skbkontur/react-icons";
import { AdditionalInfoWarning } from "../Errors/ServerErrors";
import { AdditionalInfoVM } from "./AdditionalInfoVM";
import { WithdrawalStore } from "stores/WithdrawalStore";

interface IAdditionalInfoProps {
  info: CodeAdditionalInfoModel;
  maxWidth: number;
  discardCode?(code: string): void;
  isInputMode?: boolean;
}

export const AdditionalInfo: React.FC<IAdditionalInfoProps> = observer((props: IAdditionalInfoProps) => {
  const { info, maxWidth, discardCode, isInputMode } = props;

  const onDiscard = () => discardCode?.(info.code);

  const vm = new AdditionalInfoVM(WithdrawalStore);

  if (info.isSscc) {
    return (
      <InfoBlock maxWidth={`${maxWidth}px`} isInputMode={isInputMode}>
        <InfoTitle>
          <EllipsisText width={maxWidth - 30}>{info.name}</EllipsisText>
        </InfoTitle>
        <div>
          <Code bold={!info.name}>Код агрегата: {info.code}</Code>
          {isInputMode && (
            <AcceptedText>
              <AcceptedStatusIcon /> Код маркировки добавлен
            </AcceptedText>
          )}
          <DiscardTopBlockWrap>
            <Button use={"link"} size={"small"} onClick={onDiscard} icon={<Undo />}>
              {isInputMode ? "Отменить добавление" : "Отменить сканирование"}
            </Button>
          </DiscardTopBlockWrap>
        </div>
        {info.isError && (
          <AdditionalInfoWarning isInputMode={isInputMode} description={info.description} errorCode={info.errorCode} />
        )}

        {!info.isError && !vm.codeWarning && (
          <Grid cols={"170px 45px 20px 120px 1fr"} paddingCells={"3px 0"}>
            <GridCell row={1} col={1}>
              <TextSecondary>Состав, агрегат</TextSecondary>
            </GridCell>
            <GridCell row={1} col={2}>
              {info.containedSsccCount || "—"}
            </GridCell>
            <GridCell row={1} col={4}>
              <TextSecondary>GTIN</TextSecondary>
            </GridCell>
            <GridCell row={1} col={5}>
              {info.gtin || "—"}
            </GridCell>

            <GridCell row={2} col={1}>
              <TextSecondary>Состав, штучный товар</TextSecondary>
            </GridCell>
            <GridCell row={2} col={2}>
              {info.containedSgtinsCount || "—"}
            </GridCell>
            <GridCell row={2} col={4}>
              <TextSecondary>Производитель</TextSecondary>
            </GridCell>
            <GridCell row={2} col={5}>
              <EllipsisText width={SsccProducerWidth}>{info.producer || "—"}</EllipsisText>
            </GridCell>
          </Grid>
        )}
        {vm.codeWarning && !info.isError && (
          <AdditionalInfoWarning
            isInputMode={isInputMode}
            description={vm.codeWarning.description}
            errorCode={vm.codeWarning.errorCode}
            maxWidth={maxWidth - 65}
          />
        )}
      </InfoBlock>
    );
  }

  return (
    <InfoBlock maxWidth={`${maxWidth}px`} isInputMode={isInputMode}>
      <InfoTitle>
        <EllipsisText width={maxWidth - 30}>{info.name}</EllipsisText>
      </InfoTitle>
      <div>
        <Code bold={!info.name}>SGTIN: {info.code}</Code>
        {isInputMode && (
          <AcceptedText>
            <AcceptedStatusIcon /> Код маркировки добавлен
          </AcceptedText>
        )}
        <DiscardTopBlockWrap>
          <Button use={"link"} size={"small"} onClick={onDiscard} icon={<Undo />}>
            {isInputMode ? "Отменить добавление" : "Отменить сканирование"}
          </Button>
        </DiscardTopBlockWrap>
      </div>
      {info.isError && (
        <AdditionalInfoWarning isInputMode={isInputMode} description={info.description} errorCode={info.errorCode} />
      )}
      {vm.codeWarning && !info.isError && (
        <AdditionalInfoWarning
          isInputMode={isInputMode}
          description={vm.codeWarning.description}
          errorCode={vm.codeWarning.errorCode}
          maxWidth={maxWidth - 65}
        />
      )}
      {!info.isError && !vm.codeWarning && (
        <Grid cols={"145px 80px 0.2fr 185px 1fr"} paddingCells={"3px 0"}>
          <GridCell row={1} col={1}>
            <TextSecondary>Дата производства</TextSecondary>
          </GridCell>
          <GridCell row={1} col={2}>
            {(info.releaseDate && getFormattedDate(info.releaseDate)) || "—"}
          </GridCell>
          <GridCell row={1} col={4}>
            <TextSecondary>Производственная серия</TextSecondary>
          </GridCell>
          <GridCell row={1} col={5}>
            {info.batch || "—"}
          </GridCell>

          <GridCell row={2} col={1}>
            <TextSecondary>Срок годности</TextSecondary>
          </GridCell>
          <GridCell row={2} col={2}>
            {(info.expirationDate && getFormattedDate(info.expirationDate)) || "—"}
          </GridCell>
          <GridCell row={2} col={4}>
            <TextSecondary>Производитель</TextSecondary>
          </GridCell>
          <GridCell row={2} col={5}>
            <EllipsisText width={isInputMode ? SgtinProducerWidthInputMode : SgtinProducerWidth}>
              {info.producer || "—"}
            </EllipsisText>
          </GridCell>
        </Grid>
      )}
    </InfoBlock>
  );
});
