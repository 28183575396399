import {
  CreateDestructionModel,
  DestructionCause,
  DestructionMethod,
  DestructionReason,
  DestructionModel,
  DestructModel,
  PutDestructionModel,
} from "typings/server";
import { action, observable } from "mobx";
import { DestructionApi } from "api/DestructionApi";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { BaseEditorModalVM, TBaseErrors } from "../BaseEditorModalVM";
import { FiasAddress } from "@skbkontur/react-ui-addons";

type TErrors = TBaseErrors | "invalidOperationDate" | "reasonRequired";

interface FieldsForValidate {
  id?: string;
  contractDocumentNumber: string;
  actDocumentNumber: string;
  contractDocumentDate: string | undefined;
  actDocumentDate: string | undefined;
  destructionOrg: "ul" | "ip";
  inn: string | undefined;
  kpp: string | undefined;
  addressFias: string;
  address: string;
  destructionMethod?: DestructionMethod;
  destructionCause?: DestructionCause;
  destructionReason?: DestructionReason;
  rznDecision?: string;
}

export class DestructionEditorModalVM extends BaseEditorModalVM<TErrors> {
  @observable operationDate: string = new Date().toISOString();
  @observable getContractDocumentNumberError: string | undefined = undefined;
  @observable getAddressFiasError: boolean | undefined = undefined;
  @observable getContractDocumentDateError: string | undefined = undefined;
  @observable getActDocumentNumberError: string | undefined = undefined;
  @observable getActDocumentDateError: string | undefined = undefined;
  @observable getInnError: string | undefined = undefined;
  @observable getAddressError: string | undefined = undefined;
  @observable getKppError: string | undefined = undefined;

  @observable addressFias: FiasAddress | undefined = undefined;

  @observable destruction: DestructionModel | undefined = undefined;

  private isOperationDateModified: boolean = false;
  private fieldsForValidate: FieldsForValidate | undefined = undefined;

  @action.bound
  setOperationDate(date?: string) {
    this.operationDate = date || "";
    this.isOperationDateModified = true;
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @action
  async save(params: FieldsForValidate): Promise<string | void> {
    this.isSubmitted = true;
    this.fieldsForValidate = params;
    const {
      id,
      inn,
      kpp,
      contractDocumentDate,
      contractDocumentNumber,
      actDocumentDate,
      actDocumentNumber,
      address,
      addressFias,
      destructionOrg,
      destructionCause,
      destructionReason,
      rznDecision,
    } = params;

    if (this.validate()) {
      if (this.isNew) {
        const model = new CreateDestructionModel();

        const data = {
          operationDate: this.isOperationDateModified ? this.operationDate : undefined,
          documentDate: contractDocumentDate,
          documentNumber: contractDocumentNumber,
          inn,
          kpp: destructionOrg === "ul" ? kpp : undefined,
          actNumber: actDocumentNumber,
          actDate: actDocumentDate,
          address,
          addressFias,
        };
        Object.assign(model, data);

        return await this.createNewSendingToDestruction(model);
      } else {
        if (!id) {
          return;
        }
        const model = new PutDestructionModel();

        const data = {
          operationDate: this.isOperationDateModified ? this.operationDate : undefined,
          documentDate: contractDocumentDate,
          documentNumber: contractDocumentNumber,
          inn,
          kpp: destructionOrg === "ul" ? kpp : undefined,
          actNumber: actDocumentNumber,
          actDate: actDocumentDate,
          address,
          addressFias,
          // Тут зафолбэчим на OwnerDecision, все равно потом юзер переопределит (во втором этапе заполнения формы)
          destructionCause: destructionCause || DestructionCause.OwnerDecision,
          destructionReason,
          rznDecision,
        };

        Object.assign(model, data);
        await this.patchOperation(id, model);
      }
    } else {
      return Promise.reject();
    }
  }

  @action
  async destruct(id: string, model: DestructModel): Promise<void> {
    await DestructionApi.destruct(id, model);
  }

  @action
  async createNewSendingToDestruction(model: CreateDestructionModel): Promise<void> {
    return await DestructionApi.addNewSendingToDestruction(model);
  }

  @action
  async patchOperation(id: string, model: PutDestructionModel): Promise<void> {
    await DestructionApi.updateSendingToDestruction({ id, model });
  }

  @action
  validate(): boolean {
    this.clearErrors();
    if (!this.fieldsForValidate?.actDocumentDate) {
      this.getActDocumentDateError = "Укажие дату акта передачи на уничтожение";
    }
    if (!this.fieldsForValidate?.contractDocumentDate) {
      this.getContractDocumentDateError = "Укажие дату договора передачи на уничтожение";
    }
    if (!!this.fieldsForValidate?.actDocumentDate && new Date(this.fieldsForValidate.actDocumentDate) > new Date()) {
      this.getActDocumentDateError = "Дата акта не должна быть больше текущей даты и времени";
    }
    if (
      !!this.fieldsForValidate?.contractDocumentDate &&
      new Date(this.fieldsForValidate.contractDocumentDate) > new Date()
    ) {
      this.getContractDocumentDateError = "Дата договора не должна быть больше текущей даты и времени";
    }
    if (!this.fieldsForValidate?.contractDocumentNumber) {
      this.getContractDocumentNumberError = "Укажите номер договора передачи на уничтожение";
    }

    if (!this.fieldsForValidate?.actDocumentNumber) {
      this.getActDocumentNumberError = "Укажите номер акта передачи на уничтожение";
    }

    if (!this.fieldsForValidate?.address) {
      this.getAddressError = "Укажите адрес организации, выполняющей уничтожение";
    }

    if (!this.fieldsForValidate?.inn) {
      this.getInnError = "Укажите ИНН организации, выполняющей уничтожение";
    }

    if (this.fieldsForValidate?.inn?.length !== 12 && this.fieldsForValidate?.destructionOrg === "ip") {
      this.getInnError = "Проверьте корректность ИНН";
    }

    if (this.fieldsForValidate?.inn?.length !== 10 && this.fieldsForValidate?.destructionOrg === "ul") {
      this.getInnError = "Проверьте корректность ИНН";
    }

    if (!this.fieldsForValidate?.kpp && this.fieldsForValidate?.destructionOrg === "ul") {
      this.getKppError = "Укажите КПП организации, выполняющей уничтожение";
    }

    if (!this.fieldsForValidate?.address) {
      this.getAddressFiasError = true;
    }
    if (!this.documentDate) {
      this.errors.set("docDateRequired", "Укажите дату документа-основания");
    }
    if (!this.documentNumber) {
      this.errors.set("docNumberRequired", "Укажите номер документа-основания");
    }
    return (
      !this.getContractDocumentNumberError &&
      !this.getAddressFiasError &&
      !this.getContractDocumentDateError &&
      !this.getActDocumentNumberError &&
      !this.getActDocumentDateError &&
      !this.getInnError &&
      !this.getAddressError &&
      !this.getKppError
    );
  }

  @action
  setByDelivery(delivery: WithdrawalDeliveryModel) {
    this.operationDate = delivery.reportDate ? new Date(delivery.reportDate).toISOString() : new Date().toISOString();
    if (delivery.documentDate) {
      this.documentDate = new Date(delivery.documentDate).toISOString();
    }
    this.documentNumber = delivery.documentNumber || "";
  }

  clearErrors() {
    this.getActDocumentDateError = undefined;
    this.getActDocumentNumberError = undefined;
    this.getContractDocumentDateError = undefined;
    this.getContractDocumentNumberError = undefined;
    this.getAddressFiasError = undefined;
    this.getInnError = undefined;
    this.getKppError = undefined;
    this.getAddressError = undefined;
  }
}
