import { RouteName } from "./RouteNames";

class AcceptanceRouteParams {
  deliveryId?: string;
}

export class AcceptanceRoute extends AcceptanceRouteParams {
  public static readonly title = "Приемка\n\rтовара";
  public static readonly baseRoute = `/${RouteName.Acceptance}`;
  public static readonly supportRoute = `/${RouteName.Acceptance}?openSupport`;
  public static readonly route = `${AcceptanceRoute.baseRoute}/:deliveryId?`;

  public static getHref(acceptanceId?: string): string {
    return acceptanceId ? `${this.baseRoute}/${acceptanceId}` : this.baseRoute;
  }
}
