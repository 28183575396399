import styled from "styles/styled-components";

export interface ContentProps {
  background?: string;
  shadow?: boolean;
  fixed?: boolean;
}

export const HeaderRoot = styled.div`
  height: 64px;
`;

export const ContentRoot = styled.div`
  min-width: ${props => props.theme.layout.minWidth};
`;

export const ContentBody = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.layout.maxWidth};
`;

export const ContentWrapper = styled.div<ContentProps>`
  background: ${props => props.background || "transparent"};
  border-radius: 4px;
  box-shadow: ${props => (props.shadow ? props.theme.contentWrap.shadow : "none")};
  width: ${props => (props.fixed ? props.theme.contentWrap.fixedWidth : "auto")};
  margin-left: ${props => (props.fixed ? "auto" : "0")};
  margin-right: ${props => (props.fixed ? "auto" : "0")};
  min-height: ${props => props.theme.contentWrap.minHeight};
  display: ${props => props.theme.contentWrap.display};
`;

export const RouteContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;
interface IContentProps {
  padding?: string | number;
}

export const Content = styled.div<IContentProps>`
  padding: ${props => props.padding || props.theme.content.padding};
  flex-grow: 1;
  max-width: ${props => props.theme.contentWrap.maxWidth};
`;

export const ContentWrapperWithFooter = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const EmptyContent = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;
