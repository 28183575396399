import React, { FC } from "react";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { reentryInstruction } from "StringResx";
import { OperationsList } from "./OperationsList/OperationsList";
import AddIcon from "@skbkontur/react-icons/Add";
import { List } from "./MainPage.styles";
import { CirculationRoute, CirculationSubRoute } from "routes/CirculationRoute";
import { DeliveryDropdownWrap, DeliveryH3, DeliveryTopBlock } from "Common/Styles/MainPage.styles";
import { Button, Link, Loader } from "components";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { IMainPageProps } from "./MainPage";
import { observer } from "mobx-react";
import { ReentryEditorModal } from "../Editor/ReentryEditorModal/ReentryEditorModal";

const DELIVERY_TYPES = [DeliveryType.Reentry];

export const Reentry: FC<IMainPageProps> = observer(({ vm, history }) => {
  const goToDetailsPage = (doc: DeliveryIndexModel) => {
    vm.setIsPreserveFilters(true);
    if (doc.id) {
      history.push(CirculationRoute.getHref(doc.id, CirculationSubRoute.reentry));
    }
  };

  const toggleNewReentryModal = () => vm.toggleModal("newReentry");

  if (vm.loadingState.get("start")) {
    return <Loader active />;
  }

  return (
    <>
      {vm.modalState.get("newReentry") && <ReentryEditorModal onClose={toggleNewReentryModal} />}
      <DeliveryTopBlock>
        <DeliveryDropdownWrap>
          <Button data-tid="CreateNewOperation" onClick={toggleNewReentryModal} size="medium" icon={<AddIcon />}>
            Добавить повторный ввод в оборот
          </Button>
          <Link data-tid="InfoLink" href={reentryInstruction} icon={<HelpDotIcon />} target="_blank">
            Повторный ввод в оборот
          </Link>
        </DeliveryDropdownWrap>
        <DeliveryH3>Операции</DeliveryH3>
      </DeliveryTopBlock>
      <List>
        <OperationsList
          data-tid="OperationsList"
          listType={DeliveryType.Reentry}
          operations={vm.deliveries}
          onClick={goToDetailsPage}
          vm={vm}
          types={DELIVERY_TYPES}
        />
      </List>
    </>
  );
});
