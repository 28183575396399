export class YMetrika {
  static readonly counterId = 65431042;
  static readonly event = "reachGoal";

  static sendEvent(targetName: string) {
    if (YMetrika.checkYM()) {
      // eslint-disable-next-line no-undef
      ym(YMetrika.counterId, YMetrika.event, targetName);
    }
  }

  static checkYM() {
    if (!window["ym"]) {
      // eslint-disable-next-line no-console
      console.error("yandex metrika is not defined!");
      return false;
    }
    return true;
  }
}
