import styled from "styles/styled-components";

interface RowWrapProps {
  paddingLeft?: string;
}

export const RowWrap = styled.div<RowWrapProps>`
  padding: 8px 0 8px ${props => props.paddingLeft || "4px"};
`;

export const FilterTextWrap = styled.span`
  margin-right: 15px;
`;
