import React, { ReactNode } from "react";
import { Level, WarningBlock, WarningIconWrap } from "./WarningText.styles";
import WarningIcon from "@skbkontur/react-icons/Warning";

interface WarningTextProps {
  text: ReactNode;
  level: Level;
  isIcon?: boolean;
  maxWidth?: string;
  top?: boolean;
}

export const WarningText = ({ text, isIcon = true, level, maxWidth, top }: WarningTextProps) => {
  if (!text) {
    return null;
  }

  return (
    <WarningBlock level={level} maxWidth={maxWidth} top={top}>
      {isIcon && !top && (
        <WarningIconWrap>
          <WarningIcon />
        </WarningIconWrap>
      )}
      {text}
    </WarningBlock>
  );
};
