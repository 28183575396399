import React from "react";
import { observer } from "mobx-react";
import { Button, Loader, Modal } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { ICertificate } from "@skbkontur/plugin-js";
import { ShipmentStore } from "stores/ShipmentStore";
import { UserStore } from "stores/UserStore";
import { SignatureStore } from "stores/SignatureStore";
import { ErrorText, HintText, ModalWrapper, UnregisteredConteragent } from "Common/Editor/Editor.style";
import { MdlpModal } from "../../../../../CertificatesList/MdlpModal";
import { ShipmentEditorVM } from "./ShipmentEditorVM";
import { ShipmentEditorFields } from "./ShipmentEditorFields";
import { EditorFields } from "Common/Editor/EditorFields";
import { ShipmentTypesNames } from "typings/dictionaries";
import { UtdTitle } from "features/Shipment/MainPage/UtdTitle/UtdTitle";
import { AcceptusType } from "typings/server";

interface IEditorModalProps {
  onClose(): void;
  errorText?: string;
  onComplete?(): Promise<void>;
}

interface IEditorModalInternalProps extends IEditorModalProps {
  vm: ShipmentEditorVM;
}

const EditorModalInternal: React.FC<IEditorModalInternalProps> = observer((props: IEditorModalInternalProps) => {
  const { vm, onComplete, errorText } = props;

  const onClose = () => props.onClose();

  const onCloseCertListModal = () => vm.setNeedToSign(false);

  const onSign = async (cert: ICertificate) => {
    await vm.onSign(cert);
    await vm.findDepartments();
  };

  const onSave = async () => {
    await vm.onSave();
    if (onComplete) onComplete();
    updateUrlSearchParams();
    onClose();
  };

  const fillOutLater = () => {
    updateUrlSearchParams();
    onClose();
  };

  const updateUrlSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("new");
    window.history.replaceState({}, document.title, url.href);
  };

  if (vm.needToSign) {
    return <MdlpModal onClose={onCloseCertListModal} title={"Доступ в ИС МДЛП"} sign={onSign} />;
  }

  return (
    <Modal data-tid="EditorModal" onClose={onClose} ignoreBackgroundClick>
      <Modal.Header sticky>{<UtdTitle acceptusType={vm.deliveryCopy.acceptusType as AcceptusType} />}</Modal.Header>
      <Modal.Body>
        <ModalWrapper>
          <HintText>
            {errorText ||
              (!!vm.errors.size && (
                <ErrorText>{errorText || "Для добавления отгрузки заполните оставшиеся поля"}</ErrorText>
              ))}
          </HintText>
          <Loader data-tid="Loader" active={vm.loading} minHeight="400px">
            <Grid alignItems="baseline" cols="200px 1fr">
              <ShipmentEditorFields.Supplier vm={vm} row={1} />

              <ShipmentEditorFields.Recipient vm={vm} row={2} />

              <GridCell row={3} col={1}>
                Адрес получателя
              </GridCell>

              {vm.unregisteredCounteragent ? (
                <UnregisteredConteragent>
                  {ShipmentTypesNames[AcceptusType.UnregisteredCounteragent]}
                </UnregisteredConteragent>
              ) : (
                <EditorFields.RecipientAddress vm={vm} row={3} margin={"0 0 16px"} />
              )}

              {vm.unregisteredCounteragent && (
                <ShipmentEditorFields.RecipientInnKpp vm={vm} row={4} margin={"0 0 16px"} />
              )}

              <EditorFields.DocumentDateAndNumber vm={vm} row={6} padding={"16px 4px"} />

              <EditorFields.ReportDate vm={vm} row={8} label={"Дата и время отгрузки"} margin={"0 0 40px"} />

              {!vm.isStateDispatch && !vm.isSuspendedReturn && (
                <>
                  {!vm.unregisteredCounteragent && !vm.unregisteredDepartment && (
                    <>
                      <ShipmentEditorFields.TurnoverType vm={vm} row={9} />

                      <EditorFields.FinancingType vm={vm} row={10} />

                      <EditorFields.ContractNumber vm={vm} row={12} />
                    </>
                  )}

                  <EditorFields.ContractType vm={vm} row={11} />
                </>
              )}
            </Grid>
          </Loader>
        </ModalWrapper>
      </Modal.Body>

      <Modal.Footer panel sticky>
        <FooterBtnWrapper>
          <Button data-tid="Submit" use="primary" size={"large"} onClick={onSave}>
            Сохранить
          </Button>
          <Button data-tid="Cancel" size={"large"} onClick={fillOutLater}>
            Отменить
          </Button>
        </FooterBtnWrapper>
      </Modal.Footer>
    </Modal>
  );
});

export const ShipmentEditor = (props: IEditorModalProps) => {
  const { errorText } = props;
  const vm = new ShipmentEditorVM(ShipmentStore, UserStore, SignatureStore, errorText);
  return <EditorModalInternal vm={vm} {...props} />;
};
