import styled from "styles/styled-components";
import { colors } from "styles/colors";

const width = "96px";

export const MenuBlock = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.blueMenuItem};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: ${width};
  display: flex;
  flex-direction: column;
  min-height: ${props => props.theme.contentWrap.minHeight};
`;

interface MenuItemStyleProps {
  href: string;
  active: boolean;
}

export const StyledMenuLink = styled.div`
  text-decoration: none;
  position: relative;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const MenuItemStyle = styled.span<MenuItemStyleProps>`
  position: relative;
  transition: background-color 200ms ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${width};
  color: ${props => props.theme.colors.white};
  padding: 10px 5px;
  cursor: ${props => props.href && "pointer"};
  background-color: ${props => props.active && props.theme.colors.bgColorActive};

  &:hover {
    background-color: ${props => props.href && !props.active && props.theme.colors.bgColorHover};
  }
`;

export const MenuCountIcon = styled.span`
  position: absolute;
  min-width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: ${colors.orange};
  text-align: center;
  top: 7px;
  right: 10px;
  padding-right: 2px;
  padding-left: 2px;
`;

export const MenuCountText = styled.span`
  font-size: 12px;
  line-height: 15px;
  vertical-align: text-top;
  font-weight: 100;
  color: ${colors.black};
`;

export const MenuItemName = styled.span`
  white-space: pre-line;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  margin-top: 4px;
`;
