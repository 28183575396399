import React from "react";
import { observer } from "mobx-react";
import { TableWrap } from "../../SettingsPage.styles";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { SettingsPageVM } from "../../SettingsPageVM";
import { DepartmentsTableItem } from "./DepartmentsTableItem";
import { DepartmentModel } from "typings/server";

interface IDepartmentsTableProps {
  vm: SettingsPageVM;
  onSelect?: (dep: DepartmentModel) => void;
}

export const DepartmentsTable = observer((props: IDepartmentsTableProps) => {
  const { vm, onSelect } = props;
  const cols = "5fr 2fr 3fr";

  return (
    <TableWrap data-tid="DepartmentsTable">
      <Grid cols={cols} headerBorderBottom paddingCells={"13px 4px 16px"}>
        <GridCellHeader col={1} row={1}>
          Адрес
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Статус
        </GridCellHeader>
        <GridCellHeader col={3} row={1}>
          Тип
        </GridCellHeader>
      </Grid>

      {vm.departments.map(department => (
        <DepartmentsTableItem key={department.id} cols={cols} department={department} onSelect={onSelect} />
      ))}
    </TableWrap>
  );
});
