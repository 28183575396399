import React, { useEffect } from "react";
import { observer } from "mobx-react";
import AddIcon from "@skbkontur/react-icons/Add";
import {
  DeliveryDropdownWrap,
  DeliveryH3,
  DeliveryHeader,
  DeliveryTable,
  DeliveryTopBlock,
  UpdateBlock,
  MenuHeaderText,
  InputWrap,
} from "Common/Styles/MainPage.styles";
import { AcceptanceRoute } from "routes";
import { Dropdown, Input, Link, Loader, MenuItem } from "components";
import { DeliveriesList } from "./DeliveriesList/DeliveriesList";
import { RouteComponentProps } from "react-router";
import { AcceptusType, DeliveryIndexModel, DeliveryType } from "typings/server";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { Content } from "components/Layout";
import { UtdModal } from "Common/UtdModal/UtdModal";
import { ICertificate } from "@skbkontur/plugin-js";
import { SignatureStore } from "stores/SignatureStore";
import { SyncInfo } from "Common/SyncInfo/SyncInfo";
import { UserStore } from "stores/UserStore";
import { getNewAcceptanceDeliveryInfoBlock } from "./NewDeliveryInfo/NewDeliveryInfo";
import { MdlpModal } from "../../CertificatesList/MdlpModal";
import { AcceptanceStore } from "stores/AcceptanceStore";
import { SYNC_POLL_INTERVAL } from "stores/shared";
import { DeliveriesStore } from "stores/DeliveriesStore";
import SearchIcon from "@skbkontur/react-icons/Search";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { acceptanceInstruction } from "StringResx";
import { SupportCenter } from "support";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { AdvertisementStore } from "stores/AdvertisementStore";
import { UtdTitle } from "features/Acceptance/MainPage/UtdTitle/UtdTitle";
import { YMetrika } from "YMetrika";

interface IAcceptanceMainPageProps extends RouteComponentProps<AcceptanceRoute> {
  vm: DeliveriesMainPageVM;
}

const DELIVERY_TYPE = DeliveryType.Acceptance;

const AcceptanceMainPageInternal = observer((props: IAcceptanceMainPageProps) => {
  const { vm, history } = props;

  // mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("openSupport")) {
      SupportCenter.open();
      const url = new URL(window.location.href);
      url.searchParams.delete("openSupport");
      window.history.replaceState({}, document.title, url.href);
    }
  });

  // unmount
  useEffect(() => {
    return () => {
      vm.handleUnmount();
    };
  }, [vm]);

  const goToAcceptancePage = (doc: DeliveryIndexModel) => {
    vm.setIsPreserveFilters(true);
    if (doc.id) history.push(AcceptanceRoute.getHref(doc.id));
  };

  const onSimplifiedAccept = () => {
    vm.setAcceptusType(AcceptusType.Simplified);
    toggleUtdModal();
  };

  const onReversedAccept = () => {
    vm.setAcceptusType(AcceptusType.Reverse);
    toggleUtdModal();
  };

  const toggleUtdModal = () => vm.toggleModal("utd");

  const toggleMdlpModal = () => vm.toggleModal("mdlpModal");

  const toggleDeleteModal = () => vm.toggleModal("delete");

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
    await vm.synchronizeAndUpdate(SYNC_POLL_INTERVAL);
  };

  const onDelete = async () => {
    toggleDeleteModal();
    await vm.deleteDelivery();
  };

  if (vm.loadingState.get("start")) return <Loader active />;

  return (
    <Content data-tid="MainPage">
      {vm.modalState.get("utd") && (
        <UtdModal
          store={AcceptanceStore}
          baseRoute={AcceptanceRoute.baseRoute}
          title={<UtdTitle isSimplified={vm.acceptusType === AcceptusType.Simplified} />}
          onClose={toggleUtdModal}
          type={DELIVERY_TYPE}
          acceptusType={vm.acceptusType}
          btnText={"Добавить приемку"}
          newDeliveryBlockFunc={getNewAcceptanceDeliveryInfoBlock}
        />
      )}
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={toggleMdlpModal}
          sign={onSign}
          title={"Доступ в ИС МДЛП"}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}
      {vm.modalState.get("delete") && vm.deliveryToDelete && (
        <DeleteDeliveryModal
          deliveryType={vm.deliveryToDelete.type}
          onDelete={onDelete}
          onClose={toggleDeleteModal}
          getDeliveryItems={vm.getDeliveryItems}
          isLoading={vm.loadingState.get("delete")}
        />
      )}
      <DeliveryTopBlock>
        <DeliveryHeader>
          <h2>{AcceptanceRoute.title}</h2>
          <UpdateBlock>
            <SyncInfo vm={vm} />
          </UpdateBlock>
        </DeliveryHeader>
        <DeliveryDropdownWrap>
          <div>
            <Dropdown
              data-tid="CreateNewOperation"
              caption="Добавить приемку"
              disabled={!vm.canLoadUtd}
              icon={<AddIcon />}
            >
              <MenuHeaderText>Загрузить товарную накладную:</MenuHeaderText>
              <MenuItem data-tid="ReverseAcceptance" onClick={onReversedAccept}>
                Для приемки по обратному акцепту
              </MenuItem>
              <MenuItem data-tid="Simplified" onClick={onSimplifiedAccept}>
                По упрощенной 702 схеме
              </MenuItem>
            </Dropdown>
            <InputWrap>
              <Input
                data-tid="DocumentNumberSearch"
                value={vm.documentNumberQuery}
                onValueChange={vm.changeDocumentNumberQuery}
                width={"250px"}
                placeholder="Поиск по номеру документа"
                rightIcon={<SearchIcon />}
              />
            </InputWrap>
          </div>
          <Link
            data-tid="InfoLink"
            href={acceptanceInstruction}
            icon={<HelpDotIcon />}
            target="_blank"
            onClick={() => {
              YMetrika.sendEvent("acceptanceInfoClick");
            }}
          >
            Приемка товара
          </Link>
        </DeliveryDropdownWrap>
        <DeliveryH3>Поставки</DeliveryH3>
      </DeliveryTopBlock>
      <DeliveryTable data-tid="OperationsList">
        <DeliveriesList type={DELIVERY_TYPE} vm={vm} onClick={goToAcceptancePage} />
      </DeliveryTable>
    </Content>
  );
});

export const MainPage = (props: RouteComponentProps<AcceptanceRoute>) => {
  const vm = new DeliveriesMainPageVM(AcceptanceStore, SignatureStore, UserStore, DeliveriesStore, AdvertisementStore, [
    DELIVERY_TYPE,
  ]);
  return <AcceptanceMainPageInternal vm={vm} {...props} />;
};
