import { action, observable } from "mobx";
import {
  AcceptusType,
  DeliveryIndexModel,
  DeliveryItemModel,
  DeliveryModel,
  DeliveryStatus,
  DeliveryType,
  NewDeliveryModel,
  PositionsReportModel,
} from "typings/server";
import { ILoadOptions } from "./shared";
import { DeliveriesApi } from "api/DeliveryApi";
import { DeliveryStoreActions } from "./DeliveryStoreActions";
import { AcceptanceDeliveryModel } from "models/Delivery/AcceptanceDeliveryModel";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import { DeliveriesStore, IDeliveryStore, ISyncPollingOptions } from "./DeliveriesStore";
import { FilterModel } from "models/Filter/FilterModel";

class AcceptanceStoreInternal {
  @observable deliveries: DeliveryIndexModel[] = [];
  @observable public selectedDelivery: AcceptanceDeliveryModel;
  @observable totalSize: number;
  readonly type = DeliveryType.Acceptance;
  readonly filters: FilterModel;
  private readonly actions: DeliveryStoreActions<AcceptanceDeliveryModel>;
  private readonly deliveriesStore: IDeliveryStore = DeliveriesStore;

  constructor() {
    this.actions = new DeliveryStoreActions();
    this.filters = new FilterModel([this.type]);
  }

  @action
  updateDeliveries = async () => {
    const filters = this.filters.getFullFiltersInfo();
    const result = await DeliveriesApi.getAll(filters);
    if (result) {
      this.totalSize = result.totalSize;
      this.setDeliveries(result.deliveries);
      this.filters.setPagesCount(result.totalSize);
      this.filters.setAllowedStatuses(result.presenceInfo.statuses, this.type);
      this.filters.setAllowedCounteragents(result.presenceInfo.counteragents);
      this.filters.setAllowedAcceptusType(result.presenceInfo.acceptusTypes);
    }
  };

  @action
  setActivePage = async (page: number) => {
    this.filters.setActivePage(page);
    await this.updateDeliveries();
  };

  @action
  setDeliveries(deliveries: DeliveryIndexModel[]) {
    this.deliveries = deliveries;
  }

  @action
  async getItem(id: string, options?: Partial<ILoadOptions>): Promise<DeliveryModel> {
    return this.actions.getItem(this.selectedDelivery, id, options);
  }

  @action
  setSelected(delivery: DeliveryModel) {
    this.selectedDelivery = new AcceptanceDeliveryModel(delivery);
  }

  @action
  async startPollingDeliveries(page: number) {
    await this.setActivePage(page);
    this.restartPollingDeliveries({ fireImmediately: true });
  }

  @action
  async restartPollingDeliveries(options?: ISyncPollingOptions) {
    await this.deliveriesStore.restartSyncInfoPolling(this.updateDeliveries, options);
  }

  @action stopPollingDeliveries() {
    this.deliveriesStore.stopSyncInfoPolling();
  }

  async rollbackDeliveryStatus(deliveryId: string) {
    return this.actions.rollbackDeliveryStatus(deliveryId);
  }

  @action
  async deleteDelivery(deliveryId: string) {
    await this.actions.deleteDelivery(deliveryId);
    await this.updateDeliveries();
  }

  async prepareDocuments(deliveryId: string) {
    return this.actions.prepareDocuments(deliveryId);
  }

  async checkDocumentsStatus(taskId: string) {
    return this.actions.checkDocumentsStatus(taskId);
  }

  @action
  addNewDelivery(delivery: NewDeliveryModel) {
    return this.actions.addNewDelivery(delivery);
  }

  async saveCodes(options: Partial<CodesReportModelExtended>): Promise<any> {
    return this.actions.saveCodes(options);
  }

  async savePositions(options: Partial<PositionsReportModel>): Promise<any> {
    return this.actions.savePositions(options);
  }

  async uploadUtd(file: File, deliveryType: DeliveryType, acceptusType: AcceptusType): Promise<NewDeliveryModel> {
    return this.actions.uploadUtd(file, deliveryType, acceptusType);
  }

  async getScannedCodes(deliveryId: string): Promise<string[]> {
    return this.actions.getDeliveryProperty(deliveryId, "scannedCodes");
  }

  async getItems(deliveryId: string): Promise<DeliveryItemModel[]> {
    return this.actions.getDeliveryProperty(deliveryId, "items");
  }

  isCanBeDeleted(status?: DeliveryStatus, acceptusType?: AcceptusType) {
    if (
      acceptusType === AcceptusType.Reverse ||
      acceptusType === AcceptusType.Simplified ||
      acceptusType === AcceptusType.SuspendedReturn
    ) {
      return status === DeliveryStatus.Draft || status === DeliveryStatus.Processing;
    }
    return false;
  }
}

export interface IAcceptanceStore extends AcceptanceStoreInternal {}

export const AcceptanceStore = new AcceptanceStoreInternal();
