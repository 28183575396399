import { IHttpClient } from "api/HttpClient/shared";
import { BillingInfoModel } from "typings/server";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";

class BillingApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getBillingInfo(): Promise<BillingInfoModel> {
    return this.httpClient.get("");
  }
}

export const BillingApi = new BillingApiInternal(getHttpClient(drugsApi + "/billing"));
