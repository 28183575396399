import styled from "styles/styled-components";

export const GoodsHeadWrapper = styled.div`
  padding-left: 5px;
  line-height: 25px;
  padding-bottom: 10px;

  h4 {
    padding-bottom: 5px;
  }
`;

export const ScanText = styled.div`
  display: inline-block;
  padding: 11px 16px;
  background-color: ${props => props.theme.colors.greenLight};
`;

interface ICreatingDocWrapProps {
  leftOffset?: string;
  top?: string;
}

export const CreatingDocWrap = styled.div<ICreatingDocWrapProps>`
  position: absolute;
  top: ${props => props.top || "5px"};
  left: ${props => props.leftOffset || "164px"};
`;

export const SpinnerWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
`;

export const DateAndFIOWrap = styled.span`
  font-size: 12px;
  color: ${props => props.theme.textColors.lightenGray};
`;

export const StagePartWrap = styled.div`
  margin-top: 2px;
  margin-left: 1px;
`;

export const GoodsFormWrap = styled.div`
  margin: 20px 20px 20px 15px;
`;

export const CounterWrap = styled.div`
  margin-right: 30px;
  display: inline-block;
`;

export const RecalledWrap = styled.div`
  margin-left: 5px;
  margin-bottom: 4px;
`;

export const CodesOffset = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

export const RejectedCodesOffset = styled.div`
  margin-right: 20px;
`;

export const StageGeyText = styled.span`
  color: ${props => props.theme.textColors.lightGray2};
`;

export const RejectedWrap = styled.div`
  margin-right: 24px;
  margin-top: 9px;
  display: inline-block;
  margin-bottom: 15px;
`;

interface IGoodsTableWrapProps {
  shortTable?: boolean;
}

export const GoodsTableWrap = styled.div<IGoodsTableWrapProps>`
  margin-bottom: 10px;

  // except ie11
  @supports (grid-template-columns: fit-content(8px)) {
    max-width: ${props => (props.shortTable ? "875px" : "100%")};
  }
`;
