import React from "react";
import ArrowChevronDownIcon from "@skbkontur/react-icons/ArrowChevronDown";
import FilterIcon from "@skbkontur/react-icons/Filter";
import { HeaderWrap, IconWrap } from "./FilterTableHeader.styles";
import { colors } from "styles/colors";

interface FilterTableHeaderProps {
  isActive: boolean;
  caption: string;
  isAnyFilterApplied: boolean;
  tableHeaderPaddings?: "small" | "middle";
}

export const FilterTableHeader = ({
  caption,
  isActive,
  isAnyFilterApplied,
  tableHeaderPaddings,
}: FilterTableHeaderProps) => {
  return (
    <HeaderWrap isActive={isActive} tableHeaderPaddings={tableHeaderPaddings}>
      {caption}
      <IconWrap>
        {isAnyFilterApplied ? <FilterIcon color={colors.darkBlue} size={14} /> : <ArrowChevronDownIcon size={14} />}
      </IconWrap>
    </HeaderWrap>
  );
};
