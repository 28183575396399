import React from "react";
import { observer } from "mobx-react";
import { DeliveryStatus, StepInfoModel } from "typings/server";
import { IStage } from "models/Delivery/shared";
import { DeliveryStage } from "../../DeliveryDictionary";
import { DateAndFIOWrap, StagePartWrap } from "../Stages.styles";
import { DeliveryStatusBlockSM } from "../../Status/DeliveryStatus/DeliveryStatusBlockSM";
import { ErrorText } from "Common/Status/DeliveryStatus/DeliveryStatus.styles";

interface VM {
  isSigned: string | undefined;
  showPartialStages: boolean;
  sendingStageName: string;
  showSpinner: boolean;
  isSend: string;
  getCompletionDate(stepInfo: StepInfoModel): string;
  getCompletionUser(stepInfo: StepInfoModel): string;
}

interface Delivery {
  stepsInfo: { [key in DeliveryStatus]: StepInfoModel };
  status: DeliveryStatus;
}

interface IDeliverySendingStepsProps {
  vm: VM;
  delivery: Delivery;
  parentDelivery?: Delivery;
  stages: Record<string, IStage>;
  statusNames: Record<DeliveryStatus, string>;
}

export const DeliverySendingSteps = observer((props: IDeliverySendingStepsProps) => {
  const { vm, delivery, parentDelivery, stages, statusNames } = props;
  const signingStepInfo =
    delivery.stepsInfo[DeliveryStatus.Signing] || parentDelivery?.stepsInfo[DeliveryStatus.Signing];
  const partialStagesErrorDescription =
    stages[DeliveryStage.Sending].errorInfo?.errorDescription ||
    stages[DeliveryStage.Signing].errorInfo?.errorDescription;
  return (
    <StagePartWrap>
      {vm.isSigned ? (
        <DateAndFIOWrap data-tid="CompleteInfo">
          {vm.getCompletionDate(signingStepInfo)} подписал{vm.isSend && " и отправил"}&nbsp;
          {vm.getCompletionUser(signingStepInfo)}
        </DateAndFIOWrap>
      ) : (
        <DateAndFIOWrap data-tid="CompleteInfo">
          {vm.getCompletionDate(delivery.stepsInfo[DeliveryStatus.Processing])}
        </DateAndFIOWrap>
      )}

      {vm.showPartialStages && (
        <>
          <DeliveryStatusBlockSM
            data-tid="SigningSmallStage"
            name={vm.isSigned ? "Подписано" : "Подписание"}
            status={stages[DeliveryStage.Signing].progress}
            error={stages[DeliveryStage.Signing].errorInfo}
            showSpinner={delivery.status === DeliveryStatus.CreatingDocument}
            spinnerCaption={statusNames[DeliveryStatus.CreatingDocument]}
          />

          <DeliveryStatusBlockSM
            data-tid="SendingSmallStage"
            name={vm.sendingStageName}
            status={stages[DeliveryStage.Sending].progress}
            error={stages[DeliveryStage.Sending].errorInfo}
            showSpinner={vm.showSpinner}
          />
        </>
      )}
      {!vm.showPartialStages && partialStagesErrorDescription && <ErrorText>{partialStagesErrorDescription}</ErrorText>}
    </StagePartWrap>
  );
});
