import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { CirculationWarning } from "Common/CirculationWarning/CirculationWarning";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { GoodsTable } from "Common/GoodsTable/GoodsTable/GoodsTable";
import { AcceptedCodes, FailedCodes, RecalledCodes, RejectedCodes } from "Common/Stages/CodesStatuses/CodesStatuses";
import { DeliverySendingSteps } from "Common/Stages/DeliverySendingSteps/DeliverySendingSteps";
import { GoodsTableWrap, RecalledWrap } from "Common/Stages/Stages.styles";
import { DeliveryStatusBlock, DeliveryStatusBlockProps } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { ShipmentDeliveryStatusNames } from "Common/Status/StatusesName";
import { GoodsWrapperBlock } from "Common/Styles/DetailsPage.styles";
import { ShipmentDeliveryModel } from "models/Delivery/ShipmentDeliveryModel";
import { DeliveryStatus } from "typings/server";
import { StagesPartVM } from "../../Common/StagesPartVM";
import { ReverseShipmentPageVM } from "../ReverseShipmentPageVM";

interface IAcceptanceStagesPartProps {
  delivery: ShipmentDeliveryModel;
  vm: ReverseShipmentPageVM;
  isPartial?: boolean;
  overrideFields?: Partial<DeliveryStatusBlockProps>;
}

export const ReverseShipmentStagesPart = observer((props: IAcceptanceStagesPartProps) => {
  const { delivery, vm, isPartial, overrideFields } = props;

  const stagesVM = useMemo(() => new StagesPartVM(delivery, vm.additionalInfo, isPartial), [delivery]);

  const onCollapse = () => vm.onCollapseDelivery(delivery.id);

  return (
    <DeliveryStatusBlock
      size={delivery.isActive ? "large" : "medium"}
      name={stagesVM.title}
      status={delivery.stages[DeliveryStage.Processing].progress}
      collapsed={!vm.isDeliveryOpenedState.get(delivery.id)}
      alwaysVisibleComp={
        <>
          <DeliverySendingSteps
            vm={stagesVM}
            delivery={delivery}
            stages={delivery.stages}
            statusNames={ShipmentDeliveryStatusNames}
          />
          {stagesVM.isShowCirculationWarning && <CirculationWarning isError={stagesVM.isFailedStatus} />}
        </>
      }
      onCollapse={onCollapse}
      error={stagesVM.isFailedStatus}
      isRejected={stagesVM.isRejected}
      isRecalled={delivery.status === DeliveryStatus.Recalled}
      {...overrideFields}
    >
      <GoodsWrapperBlock>
        <RecalledWrap>
          <AcceptedCodes delivery={delivery} acceptedCodesLen={delivery.acceptedCodesLen} />
          <FailedCodes failedCodesLen={delivery.failedCodesLen} />
          <RecalledCodes recalledCodesLen={delivery.recalledCodesLen} />
          <RejectedCodes vm={vm} rejectedCodesLen={delivery.rejectedCodesLen} delivery={delivery} />
        </RecalledWrap>
        <GoodsTableWrap>
          <GoodsTable goods={delivery.items} additionalInfo={vm.additionalInfo} />
        </GoodsTableWrap>
      </GoodsWrapperBlock>
    </DeliveryStatusBlock>
  );
});
