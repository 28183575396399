import { action, computed, observable } from "mobx";
import { CounteragentApi } from "api/CounteragentApi";
import {
  AcceptusType,
  ContractType,
  DepartmentModel,
  FinancingType,
  PatchDeliveryModel,
  TurnoverType,
} from "typings/server";
import { IUserStore } from "stores/UserStore";
import {
  ContractTypeNames,
  ContractTypeNamesArr,
  FinancingTypeNames,
  IType,
  TurnoverTypeNames,
  UnregisteredDepartmentAddress,
} from "typings/dictionaries";
import { DeliveriesApi } from "api/DeliveryApi";
import { copyOnlyExistingProperties } from "helpers/object";
import { ICertificate } from "@skbkontur/plugin-js";
import { ISignatureStore } from "stores/SignatureStore";
import { MDLP_AUTH_REQUIRED_STATUS, NOT_FOUND_STATUS } from "api/shared";
import { IStore } from "stores/shared";
import { MdlpAuthApi } from "api/MdlpAuthApi";
import { ShipmentEditorBaseVM } from "../ShipmentEditorBaseVM";
import { getLSValueOrSetIfNotExist } from "helpers/localStorage";

type EditorModalError =
  | "requiredAddress"
  | "invalidReportDate"
  | "requiredTurnoverType"
  | "requiredContractType"
  | "requiredContractNumber"
  | "requiredFinancingType"
  | "requiredDocumentNumber"
  | "requiredDocumentDate";

export class ShipmentEditorVM extends ShipmentEditorBaseVM<EditorModalError> {
  @observable needToSign: boolean = false;
  @observable counteragentSelectedDepartment: DepartmentModel;
  @observable private _unregisteredCounteragent: boolean = false;
  @observable private _departments: DepartmentModel[] = [];
  @observable private hasToken: boolean = true;
  private counteragentId?: string;
  readonly unregisteredDepartmentValue: DepartmentModel = new DepartmentModel();

  constructor(
    deliveryStore: IStore,
    userStore: IUserStore,
    private readonly signatureStore: ISignatureStore,
    errorText?: string
  ) {
    super(deliveryStore, userStore);

    if (!this.isStateDispatch) {
      // Берем дефолтные значения из LS если они там есть
      this.getDefaultValues();

      this.deliveryCopy.contractNumber = this.deliveryStore.selectedDelivery.contractNumber || "";

      this.unregisteredDepartmentValue.id = AcceptusType.UnregisteredDepartment;
      this.unregisteredDepartmentValue.address = UnregisteredDepartmentAddress;

      // set unregistered department
      if (this.deliveryStore.selectedDelivery.acceptusType === AcceptusType.UnregisteredDepartment) {
        this.counteragentSelectedDepartment = this.unregisteredDepartmentValue;
      }

      // set unregistered counteragent
      if (this.deliveryStore.selectedDelivery.acceptusType === AcceptusType.UnregisteredCounteragent) {
        this._unregisteredCounteragent = true;
      }
    }

    this.findDepartments();

    if (errorText) {
      this.onShowError();
    }
  }

  @action
  async findDepartments() {
    if (!this.inn) {
      return;
    }
    try {
      this.loading = true;
      const counteragent = await CounteragentApi.findCounteragents(this.inn);
      this.counteragentId = counteragent.organizationId;

      this._departments = counteragent?.departments || [];
      this.tryToSetDepartment();
    } catch (e: any) {
      if (e?.status === MDLP_AUTH_REQUIRED_STATUS) {
        this.setNeedToSign(true);
        this.hasToken = false;
      }
      if (e?.jsonMsg?.statusCode === NOT_FOUND_STATUS && !this.isStateDispatch) {
        this.unregisteredCounteragent = true;
      }
    } finally {
      this.loading = false;
    }
  }

  private getDefaultValues() {
    if (!this.deliveryCopy.turnoverType && this.deliveryCopy.acceptusType !== AcceptusType.SuspendedReturn) {
      this.deliveryCopy.turnoverType = getLSValueOrSetIfNotExist<TurnoverType>({
        scope: this.scope,
        key: "TurnoverType",
        value: TurnoverType.Sale,
        organizationId: this.userStore.user.chosenOrganizationId,
        counteragentInn: this.inn,
      });
    }

    if (!this.deliveryCopy.contractType && this.deliveryCopy.acceptusType !== AcceptusType.SuspendedReturn) {
      this.deliveryCopy.contractType = getLSValueOrSetIfNotExist<ContractType>({
        scope: this.scope,
        key: "ContractType",
        value: ContractType.PurchaseAndSale,
        organizationId: this.userStore.user.chosenOrganizationId,
        counteragentInn: this.inn,
      });
    }

    if (!this.deliveryCopy.financingType && this.deliveryCopy.acceptusType !== AcceptusType.SuspendedReturn) {
      this.deliveryCopy.financingType = getLSValueOrSetIfNotExist<FinancingType>({
        scope: this.scope,
        key: "FinancingType",
        value: FinancingType.OwnFunds,
        organizationId: this.userStore.user.chosenOrganizationId,
        counteragentInn: this.inn,
      });
    }
  }

  private tryToSetDepartment() {
    if (this.unregisteredCounteragent || this.unregisteredDepartment) return;
    const departmentId = this.deliveryCopy.counteragentDepartment?.id;
    if (departmentId) {
      const department = this._departments.find(dep => dep.id === departmentId);
      if (department) this.setSelectedDepartment(department);
    } else if (this._departments.length === 1) {
      this.setSelectedDepartment(this._departments[0]);
    }
  }

  @action
  onRefresh = async () => {
    try {
      await MdlpAuthApi.check();
      await this.findDepartments();
    } catch (e: any) {
      if (e.status === MDLP_AUTH_REQUIRED_STATUS) {
        this.setNeedToSign(true);
      }
    }
  };

  @action
  setNeedToSign(val: boolean) {
    this.needToSign = val;
  }

  @action
  setSelectedDepartment(department: DepartmentModel) {
    this.counteragentSelectedDepartment = department;
    if (department === this.unregisteredDepartmentValue) {
      this.resetFields();
    }
    if (this.submitted) this.validate();
  }

  @action
  async saveDelivery() {
    this.loading = true;

    try {
      const delivery = this.getDeliveryPatchModel();
      await DeliveriesApi.updateDelivery(delivery, this.deliveryCopy.id);
      const counteragentDep =
        this.unregisteredCounteragent || this.unregisteredDepartment ? undefined : this.counteragentSelectedDepartment;
      const acceptusType = this.getAcceptusType();
      this.deliveryStore.selectedDelivery.update(delivery, acceptusType, counteragentDep);
    } finally {
      this.loading = false;
    }
  }

  getDeliveryPatchModel(): PatchDeliveryModel {
    const delivery = new PatchDeliveryModel();
    copyOnlyExistingProperties(delivery, this.deliveryCopy);

    if (!this.unregisteredDepartment && !this.unregisteredCounteragent) {
      delivery.counteragentDepartmentId = this.counteragentSelectedDepartment?.id;
    }

    delivery.reportDate = this.isDateModified ? this.deliveryCopy.reportDate : "";

    if (this.deliveryCopy.contractNumber) {
      delivery.contractNumber = this.deliveryCopy.contractNumber.trim();
    }

    if (!this.isStateDispatch) {
      delivery.unregisteredCounteragent = this.unregisteredCounteragent && !this.unregisteredDepartment;
    }

    if (this.unregisteredDepartment) {
      // sent counteragentOrganizationId only when department is unregistered
      delivery.counteragentOrganizationId = this.counteragentId || "";
    }
    return delivery;
  }

  private getAcceptusType(): AcceptusType {
    if (this.isStateDispatch) {
      return AcceptusType.StateDispatch;
    }
    if (this.isSuspendedReturn) {
      return AcceptusType.SuspendedReturn;
    }
    if (this.unregisteredDepartment) {
      return AcceptusType.UnregisteredDepartment;
    }
    if (this.unregisteredCounteragent) {
      return AcceptusType.UnregisteredCounteragent;
    }
    return AcceptusType.Direct;
  }

  @action
  protected validate() {
    this.errors.clear();

    if (!this.deliveryCopy.documentDate) {
      this.errors.set("requiredDocumentDate", "Укажите дату документа");
    }

    if (!this.deliveryCopy.documentNumber) {
      this.errors.set("requiredDocumentNumber", "Укажите номер документа");
    }

    if (!this.counteragentSelectedDepartment && !this.unregisteredCounteragent) {
      this.errors.set("requiredAddress", "Укажите адрес");
    }

    if (!this.deliveryCopy.contractNumber && this.isGovernmentFinancing) {
      this.errors.set("requiredContractNumber", "Укажите реестровый номер контракта");
    }

    if (!this.isStateDispatch && !this.isSuspendedReturn) {
      if (!this.contractType) {
        this.errors.set("requiredContractType", "Укажите тип договора");
      }

      if (!this.unregisteredCounteragent && !this.unregisteredDepartment) {
        if (!this.turnoverType) {
          this.errors.set("requiredTurnoverType", "Укажите тип операции");
        }

        if (!this.financingType) {
          this.errors.set("requiredFinancingType", "Укажите источник финансирования");
        }
      }
    }

    this.validateDate();
  }

  @action
  protected validateDate() {
    this.errors.delete("invalidReportDate");

    if (this.deliveryCopy.reportDate && new Date(this.deliveryCopy.reportDate) > new Date()) {
      this.errors.set("invalidReportDate", "Дата не должна быть больше текущей даты и времени");
    }
  }

  @action
  async onSign(cert: ICertificate) {
    await this.signatureStore.signCode(cert);
    this.hasToken = true;
  }

  @action
  setFinancingType(type: FinancingType) {
    this.deliveryCopy = { ...this.deliveryCopy, financingType: type };

    if (this.isGovernmentFinancing && this.deliveryCopy.contractType !== ContractType.Government) {
      this.deliveryCopy = { ...this.deliveryCopy, contractType: ContractType.Government };
    }
    if (this.submitted) this.validate();
  }

  @computed
  private get isGovernmentFinancing() {
    return (
      this.deliveryCopy.financingType === FinancingType.FederalFunds ||
      this.deliveryCopy.financingType === FinancingType.RegionalFunds
    );
  }

  @computed
  get financingType(): IType | undefined {
    const type = this.deliveryCopy.financingType;
    if (type) {
      return { label: FinancingTypeNames[type], value: type };
    }
    return undefined;
  }

  @computed
  get contractTypeArr(): IType[] {
    if (this.unregisteredCounteragent || this.unregisteredDepartment) {
      return ContractTypeNamesArr.filter(item => item.value !== ContractType.Government);
    }

    // если источник финансирования - средства регионального бюджета или - средства федерального бюджета,
    // то тип контракта указывается только один - государственное лекарственное обеспечение
    if (this.isGovernmentFinancing) {
      return [{ label: ContractTypeNames[ContractType.Government], value: ContractType.Government }];
    }
    return ContractTypeNamesArr;
  }

  @computed
  get turnoverType(): IType | undefined {
    const type = this.deliveryCopy.turnoverType;
    if (type) {
      return { label: TurnoverTypeNames[type], value: type };
    }
    return undefined;
  }

  @computed
  get unregisteredCounteragent(): boolean {
    return this._unregisteredCounteragent;
  }

  set unregisteredCounteragent(val: boolean) {
    this._unregisteredCounteragent = val;
    if (this._unregisteredCounteragent) {
      this.resetFields();
    }
  }

  // для незарегестрированного подразделения/агента сбрасываем поля
  private resetFields() {
    const contractType =
      this.deliveryCopy.contractType === ContractType.Government ? undefined : this.deliveryCopy.contractType;
    this.updateDeliveryCopy({
      ...this.deliveryCopy,
      financingType: undefined,
      turnoverType: undefined,
      contractNumber: "",
      contractType,
    });
  }

  @computed
  get unregisteredDepartment(): boolean {
    return this.counteragentSelectedDepartment === this.unregisteredDepartmentValue;
  }

  @computed
  get departments() {
    if (!this.hasToken && !this.counteragentId) {
      return this._departments || [];
    }
    return this._departments.concat([this.unregisteredDepartmentValue]);
  }

  @computed
  get isStateDispatch() {
    return this.deliveryCopy.acceptusType === AcceptusType.StateDispatch;
  }

  @computed
  get isSuspendedReturn() {
    return this.deliveryCopy.acceptusType === AcceptusType.SuspendedReturn;
  }
}
