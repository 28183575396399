import { Tooltip } from "components";
import { getFormattedDate } from "helpers/date";
import { observer } from "mobx-react";
import { CodeAdditionalInfoModel } from "models/Code/CodeAdditionalInfo";
import React from "react";
import { TextOrange } from "styles/shared.styles";
import { TooltipSubTextWrapper, TooltipTextWrapper } from "../../AdditionalInfo.styles";

export interface CirculationDateProps {
  codeInfo?: CodeAdditionalInfoModel;
}

export const CirculationDate = observer(({ codeInfo }: CirculationDateProps) => {
  const renderCirculationDateTooltipBody = () => (
    <TooltipTextWrapper>
      <TooltipSubTextWrapper>Дата ввода в оборот после 1 февраля 2021 г.</TooltipSubTextWrapper>
      Для таких товаров дистрибьютеру необходимо успешно передать сведения в ИС МДЛП.
    </TooltipTextWrapper>
  );

  const isShowCirculationTooltip = codeInfo?.isEnteredCirculationAfterNewRules;
  const circulationDateStr = (codeInfo?.circulationEntryDate && getFormattedDate(codeInfo.circulationEntryDate)) || "—";

  return isShowCirculationTooltip ? (
    <Tooltip render={renderCirculationDateTooltipBody}>
      <TextOrange data-tid="CirculationDate">{circulationDateStr}</TextOrange>
    </Tooltip>
  ) : (
    <span data-tid="CirculationDate">{circulationDateStr}</span>
  );
});
