import React from "react";
import { observer } from "mobx-react";
import { Button, Input, Modal, DateTimePicker } from "components";
import { FooterBtnWrapper } from "components/Layout/Footer.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { CancellationModalVM } from "./CancellationModalVM";
import { CreateRecallModel } from "typings/server";
import { ModalWrap } from "./CancellationModal.styles";
import { LabelRequired } from "styles/shared.styles";

interface ICancellationModalInternalProps extends ICancellationModalProps {
  vm: CancellationModalVM;
}

const CancellationModalInternal: React.FC<ICancellationModalInternalProps> = observer(
  (props: ICancellationModalInternalProps) => {
    const { vm } = props;

    const onClose = () => props.onClose();

    const onConfirm = async () => {
      try {
        await vm.onConfirm();
        onClose();
      } catch (e) {
        if (e) {
          Promise.reject(e);
        }
      }
    };

    const getOperationDateError = () => {
      return vm.errors.get("operationDateRequired") || vm.errors.get("invalidOperationDate");
    };

    return (
      <Modal data-tid="CancellationModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>Отмена перемещения</Modal.Header>
        <Modal.Body>
          <ModalWrap>
            <Grid cols={"130px 1fr"}>
              <GridCell col={1} row={1}>
                <LabelRequired>Дата отмены</LabelRequired>
              </GridCell>
              <GridCell col={2} row={1}>
                <DateTimePicker
                  data-tid="OperationDate"
                  getReportDateError={getOperationDateError}
                  maxDate={vm.maxDate}
                  value={vm.operationDate}
                  onChange={vm.setOperationDate}
                />
              </GridCell>
              <GridCell col={1} row={2}>
                Причина отмены
              </GridCell>
              <GridCell col={2} row={2}>
                <Input data-tid="RecallReason" value={vm.reason} onValueChange={vm.setReason} width={"500px"} />
              </GridCell>
            </Grid>
          </ModalWrap>
        </Modal.Body>
        <Modal.Footer panel sticky>
          <FooterBtnWrapper>
            <Button data-tid="Submit" use="primary" size={"large"} onClick={onConfirm}>
              Подписать и отменить перемещение
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Modal.Footer>
      </Modal>
    );
  }
);

interface ICancellationModalProps {
  onClose(): void;
  onConfirm(model: CreateRecallModel): Promise<void>;
  deliveryId: string;
}

// React.memo + () => true - не обновляет компонент
export const CancellationModal = React.memo(
  (props: ICancellationModalProps) => {
    const vm = new CancellationModalVM(props.deliveryId, props.onConfirm);
    return <CancellationModalInternal {...props} vm={vm} />;
  },
  () => true
);
