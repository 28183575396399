import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import { TaskModel } from "typings/server";

class TasksApiInternal {
  constructor(private httpClient: IHttpClient) {}

  checkDocumentsStatus(taskId: string): Promise<TaskModel> {
    return this.httpClient.get(taskId);
  }
}

export const TasksApi = new TasksApiInternal(getHttpClient(drugsApi + "/tasks"));
