import { IUserStore } from "stores/UserStore";
import { CreateTrasferenceModel, DeliveryModel, DepartmentModel } from "typings/server";
import { action, observable } from "mobx";
import { DeliveriesApi } from "api/DeliveryApi";
import { ITransferStore } from "stores/TransferStore";
import { TransferDeliveryModel } from "models/Delivery/TransferDeliveryModel";
import { DepartmentsApi } from "api/DepartmentsApi";

type TErrors = "docDateRequired" | "invalidDocDate" | "invalidTransferDate" | "docNumRequired" | "recipientRequired";

export class TransferEditorModalVM {
  @observable transferDate: string = new Date().toISOString();
  @observable documentDate: string = "";
  @observable documentNumber?: string = "";
  @observable recipient: DepartmentModel;
  @observable errors: Map<TErrors, string> = new Map();
  @observable isSubmitted: boolean = false;
  @observable loading: boolean = true;
  @observable departments: DepartmentModel[] = [];
  private delivery?: TransferDeliveryModel;
  readonly maxDate: string;
  readonly isNew: boolean = true;
  private isTransferDateModified: boolean = false;

  constructor(
    private readonly userStore: IUserStore,
    private readonly transferStore: ITransferStore,
    delivery?: TransferDeliveryModel
  ) {
    this.setDepartments();
    if (delivery) {
      this.setDelivery(delivery);
      this.isNew = false;
    }
    this.maxDate = new Date().toISOString();
  }

  @action
  setDelivery(delivery: TransferDeliveryModel) {
    this.delivery = delivery;
    this.transferDate = delivery.reportDate ? new Date(delivery.reportDate).toISOString() : new Date().toISOString();
    this.setRecipient(delivery.counteragentDepartment as DepartmentModel);
    this.documentDate = new Date(delivery.documentDate).toISOString();
    this.documentNumber = delivery.documentNumber;
  }

  get currentDepartmentAddress(): string {
    if (this.delivery) return this.delivery.supplier?.address || "";
    return (this.userStore.currentDepartment && this.userStore.currentDepartment.address) || "";
  }

  get companyName(): string {
    return (this.userStore.organization && this.userStore.organization.name) || "";
  }

  @action.bound
  setTransferDate(date?: string) {
    this.transferDate = date ?? "";
    this.isTransferDateModified = true;
    if (this.isSubmitted) {
      this.validate();
    }
  }

  @action.bound
  setDocumentDate(date?: string) {
    this.documentDate = date ?? "";
    if (this.isSubmitted) this.validate();
  }

  @action
  async setDepartments() {
    this.loading = true;
    try {
      const departmentsModel = await DepartmentsApi.getDepartments();
      const departments = departmentsModel.departments || [];
      const currentDepId = this.userStore.currentDepartment && this.userStore.currentDepartment.id;
      if (currentDepId) {
        this.departments = departments.filter(d => d.id !== currentDepId) || [];
        return;
      }
      this.departments = departments;
    } finally {
      this.loading = false;
    }
  }

  @action
  setDocumentNumber = (num: string) => {
    this.documentNumber = num.trim();
    if (this.isSubmitted) this.validate();
  };

  @action
  setRecipient = (recipient: DepartmentModel) => {
    this.recipient = recipient;
    if (this.isSubmitted) this.validate();
  };

  @action
  async save(): Promise<string | void> {
    this.isSubmitted = true;
    if (this.validate()) {
      const model = new DeliveryModel();
      const reportDate = this.isTransferDateModified || this.delivery?.reportDate ? this.transferDate : undefined;

      const data = {
        reportDate,
        documentDate: this.documentDate,
        documentNumber: this.documentNumber,
        counteragentDepartment: {
          id: this.recipient.id,
          address: this.recipient.address,
        },
      };
      Object.assign(model, data);

      if (this.isNew) {
        return await this.createNewTransfer(model);
      }

      return await this.patchTransfer(model);
    } else {
      return Promise.reject();
    }
  }

  async createNewTransfer(model: Partial<DeliveryModel>): Promise<string> {
    const transferModel: CreateTrasferenceModel = {
      documentDate: model.documentDate,
      documentNumber: model.documentNumber as string,
      destinationDepartmentId: model.counteragentDepartment?.id as string,
      operationDate: model.reportDate,
    };
    return await DeliveriesApi.addNewTransfer(transferModel);
  }

  async patchTransfer(model: DeliveryModel): Promise<void> {
    let delivery = { ...model, counteragentDepartmentId: model.counteragentDepartment?.id };
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    await this.transferStore.saveDelivery(delivery, this.delivery!.id);
    this.delivery?.updateDelivery(delivery);
  }

  @action
  validate(): boolean {
    this.errors.clear();

    if (!this.recipient) {
      this.errors.set("recipientRequired", "Укажите получателя");
    }
    if (!this.documentNumber) {
      this.errors.set("docNumRequired", "Укажите номер документа");
    }
    if (!this.documentDate) {
      this.errors.set("docDateRequired", "Укажите дату документа");
    }
    if (this.transferDate && new Date(this.transferDate) > new Date()) {
      this.errors.set("invalidTransferDate", "Дата не должна быть больше текущей даты и времени");
    }
    if (this.documentDate && new Date(this.documentDate) > new Date()) {
      this.errors.set("invalidDocDate", "Дата не должна быть больше текущей даты и времени");
    }
    return !this.errors.size;
  }
}
