import React, { SyntheticEvent, useMemo } from "react";
import { getFormattedDate } from "helpers/date";
import { CodesSyncStatus, DeliveryIndexModel, DeliveryStatus, DeliveryType } from "typings/server";
import { DeleteWrap, DeliveryListRow } from "Common/DeliveriesList/DeliveriesList.styles";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { StatusItem } from "Common/Status/StatusItem";
import {
  DisposalReason,
  DisposalReasonNames,
  ReturnReasonNames,
  WithdrawalCauseInReentryNames,
  WithdrawalReasonNames,
  WithdrawalTypesNames,
} from "../../WithdrawalDictionary";
import TrashIcon from "@skbkontur/react-icons/Trash";
import { colors } from "styles/colors";
import { AdvertisementBlock } from "Common/AdvertisementBlock/AdvertisementBlock";

type WithdrawalOperationTypes = DeliveryType.Disposal | DeliveryType.Withdrawal | DeliveryType.DisposalWithRegistrator;

interface AcceptanceDeliveriesListItemProps {
  operation: DeliveryIndexModel;
  isPromoted?: boolean;
  cols: string;
  listType: DeliveryType.Withdrawal | DeliveryType.Reentry | DeliveryType.Destruction;
  isCanBeDeleted: boolean;
  closePromo?: () => void;
  onClick: (doc: DeliveryIndexModel) => void;
  onDelete: (e: SyntheticEvent<HTMLElement>, delivery: DeliveryIndexModel) => void;
}

export const OperationsListItem = ({
  cols,
  listType,
  operation,
  isCanBeDeleted,
  isPromoted,
  closePromo,
  onClick,
  onDelete,
}: AcceptanceDeliveriesListItemProps) => {
  const {
    status,
    date,
    withdrawalReason,
    withdrawalCause,
    returnReason,
    type,
    documentNumber,
    document2Number,
    document3Number,
    counteragentName,
  } = operation;
  const handleRowClick = () => onClick(operation);
  const handleOpenDeleteModal = (e: SyntheticEvent<HTMLElement>) => onDelete(e, operation);

  const codesStatus = useMemo(() => {
    if (operation.status === DeliveryStatus.Success || operation.status === DeliveryStatus.PartiallyFailed) {
      switch (operation.codesSyncStatus) {
        case CodesSyncStatus.Syncing:
          return <div style={{ color: colors.darkGray }}>Ожидание</div>;
        case CodesSyncStatus.SyncedFailed:
          return <div style={{ color: colors.orangeDark }}>Завершен с отклонениями</div>;
        case CodesSyncStatus.SyncedSuccess:
          return "Завершен";
        default:
          return "—";
      }
    }
    return "—";
  }, []);

  const codesStatusWithAds = useMemo(() => {
    if (!closePromo) {
      return codesStatus;
    }
    return (
      <AdvertisementBlock
        text="Внутри операции вы можете проверить, какие коды выведены из оборота"
        isDisplayed={!!isPromoted}
        onClose={closePromo}
        width="182px"
      >
        {codesStatus}
      </AdvertisementBlock>
    );
  }, [codesStatus, isPromoted, closePromo]);

  const withdrawalInfo = useMemo(() => {
    if (listType === DeliveryType.Reentry) {
      return !!withdrawalCause ? WithdrawalCauseInReentryNames[withdrawalCause] : "";
    }

    return WithdrawalTypesNames[type as WithdrawalOperationTypes];
  }, [listType, withdrawalCause, type]);

  const reasonInfo = useMemo(() => {
    if (listType === DeliveryType.Reentry) {
      return !!returnReason ? ReturnReasonNames[returnReason] : "";
    }

    return withdrawalReason
      ? WithdrawalReasonNames[withdrawalReason]
      : DisposalReasonNames[DisposalReason.MedicalAssistance];
  }, [withdrawalReason, listType]);

  if (listType === DeliveryType.Destruction) {
    return (
      <DeliveryListRow onClick={handleRowClick}>
        <Grid cols={cols}>
          <GridCell data-tid="DocumentDate" breakWord="normal" col={1} row={1} margin="0 0 0 5px">
            {date && getFormattedDate(date)}
          </GridCell>
          <GridCell data-tid="DocumentNumber" breakWord="normal" col={2} row={1}>
            {documentNumber || "—"}
          </GridCell>
          <GridCell data-tid="Document2Number" breakWord="normal" col={3} row={1} textAlign="left">
            {document2Number || "—"}
          </GridCell>
          <GridCell data-tid="Document3Number" breakWord="normal" col={4} row={1} textAlign="left">
            {document3Number || "—"}
          </GridCell>

          <GridCell col={5} row={1} textAlign="left" margin="0 0 0 9px">
            <StatusItem type={type} status={status} />
          </GridCell>
          <GridCell data-tid="CounteragentName" col={6} row={1} textAlign="left">
            {counteragentName}
          </GridCell>
          <GridCell col={7} row={1} textAlign="center">
            {isCanBeDeleted && (
              <DeleteWrap data-tid="DeleteIcon" onClick={handleOpenDeleteModal}>
                <TrashIcon />
              </DeleteWrap>
            )}
          </GridCell>
        </Grid>
      </DeliveryListRow>
    );
  }

  if (listType === DeliveryType.Reentry) {
    return (
      <DeliveryListRow onClick={handleRowClick}>
        <Grid cols={cols}>
          <GridCell data-tid="DocumentDate" col={1} row={1} margin="0 0 0 5px">
            {date && getFormattedDate(date)}
          </GridCell>
          <GridCell data-tid="DocumentNumber" col={2} row={1}>
            {documentNumber || "—"}
          </GridCell>
          <GridCell col={3} row={1} textAlign="left" margin="0 0 0 9px">
            <StatusItem type={type} status={status} />
          </GridCell>
          <GridCell data-tid={"WithdrawalCauses"} col={4} row={1} textAlign="left">
            {withdrawalInfo}
          </GridCell>
          <GridCell data-tid={"ReturnReason"} col={5} row={1} margin="0 0 0 9px">
            {reasonInfo}
          </GridCell>
          <GridCell col={6} row={1} textAlign="center">
            {isCanBeDeleted && (
              <DeleteWrap data-tid="DeleteIcon" onClick={handleOpenDeleteModal}>
                <TrashIcon />
              </DeleteWrap>
            )}
          </GridCell>
        </Grid>
      </DeliveryListRow>
    );
  }

  return (
    <DeliveryListRow onClick={handleRowClick}>
      <Grid cols={cols}>
        <GridCell data-tid="DocumentDate" col={1} row={1} margin="0 0 0 5px">
          {date && getFormattedDate(date)}
        </GridCell>
        <GridCell data-tid="DocumentNumber" col={2} row={1}>
          {documentNumber || "—"}
        </GridCell>
        <GridCell col={3} row={1} textAlign="left" margin="0 0 0 9px">
          <StatusItem type={type} status={status} />
        </GridCell>
        <GridCell col={4} row={1} textAlign="left" margin="0 0 0 9px" padding="8px 0">
          {codesStatusWithAds}
        </GridCell>
        <GridCell data-tid={"WithdrawalType"} col={5} row={1} textAlign="left">
          {withdrawalInfo}
        </GridCell>
        <GridCell data-tid={"WithdrawalReason"} col={6} row={1} margin="0 0 0 9px">
          {reasonInfo}
        </GridCell>
        <GridCell col={7} row={1} textAlign="center">
          {isCanBeDeleted && (
            <DeleteWrap data-tid="DeleteIcon" onClick={handleOpenDeleteModal}>
              <TrashIcon />
            </DeleteWrap>
          )}
        </GridCell>
      </Grid>
    </DeliveryListRow>
  );
};
