import { action, computed, observable, toJS } from "mobx";
import { CounteragentApi } from "api/CounteragentApi";
import {
  AcceptusType,
  ContractType,
  DeliveryModel,
  DepartmentModel,
  FinancingType,
  PatchDeliveryModel,
  ReceiveType,
} from "typings/server";
import { IUserStore } from "stores/UserStore";
import {
  ContractTypeNames,
  ContractTypeNamesArr,
  FinancingTypeNames,
  IType,
  ReceiveTypeNames,
} from "typings/dictionaries";
import { DeliveriesApi } from "api/DeliveryApi";
import { copyOnlyExistingProperties } from "helpers/object";
import { ICertificate } from "@skbkontur/plugin-js";
import { ISignatureStore } from "stores/SignatureStore";
import { MDLP_AUTH_REQUIRED_STATUS } from "api/shared";
import { IStore } from "stores/shared";
import { MdlpAuthApi } from "api/MdlpAuthApi";
import { getLSValueOrSetIfNotExist, setLSValueInScope } from "helpers/localStorage";

type EditorModalError =
  | "requiredAddress"
  | "invalidReportDate"
  | "requiredReceiveType"
  | "requiredContractType"
  | "requiredFinancingType"
  | "requiredContractNumber"
  | "requiredDocumentNumber"
  | "requiredDocumentDate";

export class AcceptanceEditorVM {
  @observable needToSign: boolean = false;
  @observable loading: boolean = false;
  @observable deliveryCopy: DeliveryModel;
  @observable counteragentSelectedDepartment: DepartmentModel;
  @observable errors: Map<EditorModalError, string> = new Map();
  @observable submitted: boolean = false;
  public readonly maxDate: string;
  departments: DepartmentModel[];
  private isDateModified: boolean = true;
  private currentDate = new Date().toISOString();
  private scope: string;

  constructor(
    private readonly deliveryStore: IStore,
    private readonly userStore: IUserStore,
    private readonly signatureStore: ISignatureStore,
    private readonly inn: string
  ) {
    this.inn = inn;
    this.deliveryCopy = new DeliveryModel();
    copyOnlyExistingProperties(this.deliveryCopy, toJS(this.deliveryStore.selectedDelivery));

    if (!this.deliveryStore.selectedDelivery.reportDate) {
      this.isDateModified = false;
    }

    this.deliveryCopy.reportDate = this.deliveryStore.selectedDelivery.reportDate || this.currentDate;
    this.maxDate = this.currentDate;

    this.scope = `acceptance${this.deliveryCopy.acceptusType}`;

    // Берем дефолтные значения из LS если они там есть
    this.getDefaultValues();
    this.deliveryCopy.contractNumber = this.deliveryStore.selectedDelivery.contractNumber || "";
    this.findDepartments();
  }

  @action
  async findDepartments() {
    if (this.isSimplified) return;
    try {
      this.loading = true;
      const conteragent = await CounteragentApi.findCounteragents(this.inn);
      this.departments = (conteragent && conteragent.departments) || [];
      this.tryToSetDepartment();
    } catch (e: any) {
      if (e.status === MDLP_AUTH_REQUIRED_STATUS) {
        this.setNeedToSign(true);
      }
    } finally {
      this.loading = false;
    }
  }

  private getDefaultValues() {
    if (!this.deliveryCopy.receiveType) {
      this.deliveryCopy.receiveType = getLSValueOrSetIfNotExist<ReceiveType>({
        scope: this.scope,
        key: "ReceiveType",
        value: ReceiveType.Arrival,
        organizationId: this.userStore.user.chosenOrganizationId,
        counteragentInn: this.inn,
      });
    }
    if (!this.deliveryCopy.contractType) {
      this.deliveryCopy.contractType = getLSValueOrSetIfNotExist<ContractType>({
        scope: this.scope,
        key: "ContractType",
        value: ContractType.PurchaseAndSale,
        organizationId: this.userStore.user.chosenOrganizationId,
        counteragentInn: this.inn,
      });
    }
    if (!this.deliveryCopy.financingType) {
      this.deliveryCopy.financingType = getLSValueOrSetIfNotExist<FinancingType>({
        scope: this.scope,
        key: "FinancingType",
        value: FinancingType.OwnFunds,
        organizationId: this.userStore.user.chosenOrganizationId,
        counteragentInn: this.inn,
      });
    }
  }

  private tryToSetDepartment() {
    const departmentId = this.deliveryCopy.counteragentDepartment && this.deliveryCopy.counteragentDepartment.id;
    if (departmentId) {
      const department = this.departments.find(dep => dep.id === departmentId);
      if (department) this.setSelectedDepartment(department);
    } else if (this.departments.length === 1) {
      this.setSelectedDepartment(this.departments[0]);
    }
  }

  @action
  onRefresh = async () => {
    try {
      await MdlpAuthApi.check();
      await this.findDepartments();
    } catch (e: any) {
      if (e.status === MDLP_AUTH_REQUIRED_STATUS) {
        this.setNeedToSign(true);
      }
    }
  };

  @action
  setNeedToSign(val: boolean) {
    this.needToSign = val;
  }

  @action
  setSelectedDepartment(department: DepartmentModel) {
    this.counteragentSelectedDepartment = department;
    if (this.submitted) this.validate();
  }

  @action
  updateDeliveryCopy(delivery: Partial<DeliveryModel>) {
    this.deliveryCopy = { ...this.deliveryCopy, ...delivery };
    if (this.submitted) this.validate();
  }

  @action
  async save() {
    this.loading = true;
    const delivery = this.getPatchDeliveryModel();

    this.setDefaultsOnSave();
    try {
      await DeliveriesApi.updateDelivery(delivery, this.deliveryCopy.id);
      this.deliveryStore.selectedDelivery.update(
        delivery,
        this.deliveryCopy.acceptusType,
        this.counteragentSelectedDepartment
      );
    } finally {
      this.loading = false;
    }
  }

  private setDefaultsOnSave() {
    // Сохраняем значения в localStorage как дефолты
    setLSValueInScope<ReceiveType>({
      scope: this.scope,
      key: "ReceiveType",
      value: this.deliveryCopy.receiveType,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });
    setLSValueInScope<ContractType>({
      scope: this.scope,
      key: "ContractType",
      value: this.deliveryCopy.contractType,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });
    setLSValueInScope<FinancingType>({
      scope: this.scope,
      key: "FinancingType",
      value: this.deliveryCopy.financingType,
      organizationId: this.userStore.user.chosenOrganizationId,
      counteragentInn: this.inn,
    });
  }

  private getPatchDeliveryModel(): PatchDeliveryModel {
    const delivery = new PatchDeliveryModel();
    copyOnlyExistingProperties(delivery, this.deliveryCopy);

    if (this.deliveryCopy.contractNumber) {
      delivery.contractNumber = this.deliveryCopy.contractNumber.trim();
    }

    delivery.counteragentDepartmentId = this.counteragentSelectedDepartment && this.counteragentSelectedDepartment.id;

    delivery.reportDate = this.isDateModified ? this.deliveryCopy.reportDate : "";

    if (this.isSimplified) {
      // в упрощеной схеме не указывается тип операции
      this.deliveryCopy.receiveType = undefined;
    }

    return delivery;
  }

  @action
  validate() {
    this.errors.clear();

    if (!this.deliveryCopy.documentDate) {
      this.errors.set("requiredDocumentDate", "Укажите дату документа");
    }

    if (!this.deliveryCopy.documentNumber) {
      this.errors.set("requiredDocumentNumber", "Укажите номер документа");
    }

    if (!this.counteragentSelectedDepartment && !this.isSimplified) {
      this.errors.set("requiredAddress", "Укажите адрес");
    }

    if (!this.deliveryCopy.contractNumber && this.isGovernmentFinancing) {
      this.errors.set("requiredContractNumber", "Укажите реестровый номер контракта");
    }

    if (!this.contractType) {
      this.errors.set("requiredContractType", "Укажите тип договора");
    }

    if (!this.receiveType && !this.isSimplified) {
      this.errors.set("requiredReceiveType", "Укажите тип операции");
    }

    if (!this.financingType) {
      this.errors.set("requiredFinancingType", "Укажите источник финансирования");
    }

    this.validateDate();
  }

  @action
  private validateDate() {
    this.errors.delete("invalidReportDate");

    if (this.deliveryCopy.reportDate && new Date(this.deliveryCopy.reportDate) > new Date()) {
      this.errors.set("invalidReportDate", "Дата не должна быть больше текущей даты и времени");
    }
  }

  @action.bound
  setReportDate(date?: string) {
    this.isDateModified = true;
    this.deliveryCopy = { ...this.deliveryCopy, reportDate: date };
    if (this.submitted) this.validateDate();
  }

  @action
  setSubmitted(val: boolean) {
    this.submitted = val;
  }

  @action
  setFinancingType(type: FinancingType) {
    this.deliveryCopy = { ...this.deliveryCopy, financingType: type };

    if (this.isGovernmentFinancing && this.deliveryCopy.contractType !== ContractType.Government) {
      this.deliveryCopy = { ...this.deliveryCopy, contractType: ContractType.Government };
    }
    if (this.submitted) this.validate();
  }

  @action
  async onSign(cert: ICertificate) {
    await this.signatureStore.signCode(cert);
  }

  @computed
  get reportDate() {
    return this.deliveryCopy.reportDate ?? this.currentDate;
  }

  @computed
  get currentUserDepartment(): DepartmentModel | undefined {
    return this.userStore.currentDepartment;
  }

  @computed
  get financingType(): IType | undefined {
    const type = this.deliveryCopy.financingType;
    if (type) {
      return { label: FinancingTypeNames[type], value: type };
    }
    return undefined;
  }

  @computed
  get contractType(): IType | undefined {
    const type = this.deliveryCopy.contractType;
    if (type) {
      return { label: ContractTypeNames[type], value: type };
    }
    return undefined;
  }

  @computed
  get receiveType(): IType | undefined {
    const type = this.deliveryCopy.receiveType;
    if (type) {
      return { label: ReceiveTypeNames[type], value: type };
    }
    return undefined;
  }

  @computed
  get isValid(): boolean {
    return !this.errors.size;
  }

  @computed
  get consignorOrSupplierAddress() {
    return this.deliveryCopy.supplier?.address;
  }

  @computed
  private get isGovernmentFinancing() {
    return (
      this.deliveryCopy.financingType === FinancingType.FederalFunds ||
      this.deliveryCopy.financingType === FinancingType.RegionalFunds
    );
  }

  @computed
  get contractTypeArr(): IType[] {
    // если источник финансирования - средства регионального бюджета или - средства федерального бюджета,
    // то тип контракта указывается только один - государственное лекарственное обеспечение
    if (this.isGovernmentFinancing) {
      return [{ label: ContractTypeNames[ContractType.Government], value: ContractType.Government }];
    }
    return ContractTypeNamesArr;
  }

  get isSimplified(): boolean {
    return this.deliveryCopy.acceptusType === AcceptusType.Simplified;
  }

  get companyName(): string {
    return (this.userStore.organization && this.userStore.organization.name) || "";
  }
}
