import { CodesApi } from "api/CodesApi";
import { FractionValidationInfo } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageVM";
import { getEnding } from "helpers/endings";
import { action, computed, observable } from "mobx";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { CodeModelExtended } from "models/Code/CodeModelExtended";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { Dictionary } from "typings/dictionaries";
import { CodeInfoModel, CodeSyncStatus, DeliveryType, PartialDisposalModel } from "typings/server";
import { ExtraField, ExtraFieldInfo } from "./extraFields/extraFields";

type IItem = DeliveryItemModelExtended | UtdPositionModelExtended | CodeModelExtended;

export type StatusIconType = "all" | "none" | "withDeviation" | "waiting" | "error" | undefined;

export class AdditionalInfoVM {
  @observable codePartialInfo: Dictionary<PartialDisposalModel | undefined> | undefined;
  @observable codesStatuses: Dictionary<CodeInfoModel | undefined> | undefined;

  constructor(
    readonly goods: Array<IItem>,
    readonly additionalInfo: CodesAdditionalInfo,
    readonly isSidePage: boolean,
    readonly deliveryType: DeliveryType,
    private readonly additionalFields: Record<ExtraField, ExtraFieldInfo>,
    readonly fractionValidationMap?: Map<string, FractionValidationInfo>,
    readonly fractionDisposalValidation?: (code: string, part?: number, totalParts?: number) => void,
    // handleErrors - коллбэк, который нужно дернуть, если произошла ошибка по запросам
    // additional-инфы, и показать нотификашку, что МДЛП недоступен, или протух токен,
    // например, когда ходим за инфой про доступные для выбытия доли!
    readonly handleErrors?: (e: { status: number }, options?: { showModal?: boolean }) => void
  ) {}

  @computed
  get isAnyCodeHasAddInfo(): boolean {
    return !!this.additionalInfo.codesInfo.size;
  }

  @computed
  get showExtraField(): boolean {
    return !this.isSidePage || (this.isSidePage && !!this.extraField?.sidePage);
  }

  @computed
  get sgtinCols(): string {
    let cols = `4fr 2.5fr ${this.showExtraField && this.isSidePage ? "1.4fr" : "1.9fr"}`;

    if (!this.isSidePage) {
      cols += " " + "1.4fr";
    }
    if (this.showExtraField && this.extraField) {
      const colWidth = this.isSidePage ? this.extraField.colWidthSidePage : this.extraField.colWidthTimeline;
      cols += " " + colWidth;

      if (this.isSidePage && this.extraField?.type === ExtraField.FractionDisposal) {
        cols += " " + "20px";
      }
    }
    return cols;
  }

  @computed
  get ssccCols(): string {
    return "2fr 1.6fr 1.6fr 1.8fr";
  }

  getProducer(code: string) {
    return this.additionalInfo.codesInfo.get(code)?.producer;
  }

  @computed
  get extraField() {
    switch (this.deliveryType) {
      case DeliveryType.Acceptance:
      case DeliveryType.Shipment:
      case DeliveryType.Unpacking:
      case DeliveryType.Extraction:
      case DeliveryType.Transference:
        return this.additionalFields[ExtraField.CirculationDate];
      case DeliveryType.Disposal:
      case DeliveryType.DisposalWithRegistrator:
        return this.additionalFields[ExtraField.FractionDisposal];
      case DeliveryType.Withdrawal:
      case DeliveryType.Destruction:
      case DeliveryType.Enclosure:
      case DeliveryType.Reentry:
        return undefined;
      default:
        const never: never = this.deliveryType;
        return never;
    }
  }

  partialValue = (code: string): number | undefined => {
    const curr = this.codePartialInfo?.[code];
    if (!curr) {
      return;
    }
    if (!curr.packageSize || !curr.soldPart) {
      return;
    }

    return Math.round(curr.packageSize * (1 - curr.soldPart.numerator / curr.soldPart.denominator));
  };

  partialSuggestText = (code: string): string | undefined => {
    const partialValue = this.partialValue(code);

    const packageSize = this.codePartialInfo?.[code]?.packageSize;

    if (!partialValue || !packageSize) {
      return;
    }
    return `Для вывода из оборота доступно ${partialValue} ${getEnding(
      partialValue,
      "доля",
      "доли",
      "долей"
    )} из ${packageSize}`;
  };

  checkIfGoodHasAdditionalInfo(good: IItem): boolean {
    // @ts-ignore - проверяем, если есть поле
    const codes = good.allCodesFlat ?? [good.code];
    return this.additionalInfo.checkCodesHaveAdditionalInfo(codes);
  }

  @action
  checkIsPartInvalid(inputCodePart: number | undefined, code: string) {
    if (!inputCodePart) {
      return false;
    }
    const partialValue = this.partialValue(code);
    if (!partialValue) {
      return false;
    }
    return inputCodePart > partialValue;
  }

  @action
  async getPartialInfo(code: string) {
    await CodesApi.getPartialInfo(code)
      .then(data => {
        if (data) {
          this.setCodePartialInfo(data, code);
        } else {
          return Promise.reject();
        }
      })
      .catch(e => {
        this.setCodePartialInfo(undefined, code);
        if (!this.handleErrors) {
          return;
        }
        this.handleErrors(e);
      });
  }

  @action
  getCodeStatus(codeSyncStatus: CodeSyncStatus | undefined): undefined | { status: string; icon: StatusIconType } {
    if (codeSyncStatus === CodeSyncStatus.SyncedSuccess) {
      return {
        status: "Выведены из оборота",
        icon: "all",
      };
    } else if (codeSyncStatus === CodeSyncStatus.SyncedFail) {
      return {
        status: "Выведены с отклонением",
        icon: "withDeviation",
      };
    }
    if (codeSyncStatus === CodeSyncStatus.Syncing) {
      return { status: "Ожидание вывода из оборота", icon: "waiting" };
    }
    return { status: "Ошибка вывода", icon: "error" };
  }

  private setCodePartialInfo(codePartialInfo: PartialDisposalModel | undefined, code: string) {
    if (!this.codePartialInfo) {
      this.codePartialInfo = {};
      this.codePartialInfo[code] = codePartialInfo;
    } else {
      this.codePartialInfo[code] = codePartialInfo;
    }
  }
}
