import { action, computed, observable } from "mobx";
import { AcceptusType, DeliveryType, NewDeliveryModel } from "typings/server";
import { IUserStore } from "stores/UserStore";
import { TooltipTrigger } from "@skbkontur/react-ui";

type TUtdErrors = "wrongFormat" | "serverError";
type TUtdTooltips = "utd" | "invoice";

interface IUtdModalDeliveryStore {
  uploadUtd(file: File, type: DeliveryType, acceptusType: AcceptusType): Promise<NewDeliveryModel>;
  addNewDelivery(newDelivery: NewDeliveryModel): void;
}

export class UtdModalVM {
  @observable public loading: boolean = false;
  @observable public newDelivery: NewDeliveryModel;
  @observable public updFileName: string;
  @observable public errors: Map<TUtdErrors, string> = new Map();
  @observable public tooltips: Map<TUtdTooltips, TooltipTrigger> = new Map([
    ["invoice", "closed"],
    ["utd", "closed"],
  ]);

  constructor(
    private readonly deliveryStore: IUtdModalDeliveryStore,
    private readonly userStore: IUserStore,
    private readonly type: DeliveryType,
    private readonly acceptusType: AcceptusType = AcceptusType.Direct
  ) {}

  @action
  async uploadUtd(file: File): Promise<void | NewDeliveryModel> {
    this.loading = true;
    this.errors.clear();
    try {
      this.newDelivery = await this.deliveryStore.uploadUtd(file, this.type, this.acceptusType);
      this.updFileName = file.name;
    } catch (e: any) {
      if (e?.message) {
        this.setServerError(e.message);
      } else this.setServerError();
    } finally {
      this.loading = false;
    }
  }

  @action
  setServerError(msg?: string) {
    this.errors.set(
      "serverError",
      `При загрузке файла возникла ошибка.
       ${msg + "." || ""}
       Проверьте заполнение и загрузите файл еще раз.`
    );
  }

  @action
  async addDelivery(): Promise<void> {
    this.loading = true;
    try {
      await this.deliveryStore.addNewDelivery(this.newDelivery);
    } finally {
      this.loading = false;
    }
  }

  @action
  setError(name: TUtdErrors, val: string) {
    this.errors.set(name, val);
  }

  @action
  toggleTooltip(type: TUtdTooltips) {
    const isOpened = this.tooltips.get(type) === "opened";
    if (isOpened) {
      this.tooltips.set(type, "closed");
    } else this.tooltips.set(type, "opened");
  }

  @computed
  get currentDepartmentAddress(): string {
    return this.userStore.currentDepartment?.address || "";
  }
}
