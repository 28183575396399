const isProduction = process.env.NODE_ENV === "production";

export const KonturUrl = "https://kontur.ru/";
export const KonturServices = "https://kontur.ru/products/?using=footer";

export const MarkingTitle = "Маркировка.Фарма";

export const SupportPhone = "8 800 500-34-03";

export const ExcelTemplateFile = `${process.env.PUBLIC_URL}/files/template.xlsx`;

export const AppEmail = "farma@kontur.ru";
export const AppMailTo = `mailto:${AppEmail}`;
export const DiagnosticUrl = "https://help.kontur.ru/";
export const TechSupportUrl = "https://kontur.ru/support";
export const PluginInstallUrl = "https://help.kontur.ru/plugin/";
export const OrderMoreCodesUrl = "https://kontur.ru/markirovka/lekarstva#order";
export const AuthLink = "https://auth.kontur.ru";

// ФИАС
export const FIASUrl = isProduction ? "https://api.kontur.ru/fias/v1/" : "https://api.testkontur.ru/fias/v1.2/";

// Контур.Справка
export const CertInstructionsUrl = "https://support.kontur.ru/pages/viewpage.action?pageId=69140484";
export const MDLPInstructionHref702 = "https://support.kontur.ru/pages/viewpage.action?pageId=68321337";
export const VideoInstruction702 = "https://yadi.sk/i/xi00_O7WYggmGg";
export const MDLPRequisitesInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52856338";
export const departmentDirectoriesInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52856331";
export const employeesInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52856351";
export const disposalRegistrarInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=63307838";
export const acceptanceInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52855639";
export const reverseAcceptanceInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=68321359";
export const shipmentInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52855641";
export const directShipmentInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=68321375";
export const disaggregationInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52855643";
export const extractionInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=68321399";
export const transferenceInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52855645";
export const withdrawalInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52855647";
export const reentryInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=75763439";
export const destructionInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=79299912";
export const MDLPErrorsInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=69140500";
export const MobileInstruction = "https://support.kontur.ru/pages/viewpage.action?pageId=52856083";

/// МДЛП, ЦРПТ
export const MDLPCabinetUrl = "https://mdlp.crpt.ru/";
export const ZnakSupportPhone = "8 800 222-15-23";
export const ZnakEmail = "support@crpt.ru";
export const MDLPInstructionHref471 =
  "https://kb.crpt.ru/_wt/knowledge_base?cat_id=6968410781335302837&s_id=6969500160117272056&a_id=6969859598482751150";
export const MDLPInstructionHref417 =
  "https://kb.crpt.ru/_wt/knowledge_base?cat_id=6968410781335302837&s_id=6969500842952239742&a_id=6969524431565696107";

/// mobile apps
export const AndroidApp = "https://play.google.com/store/apps/details?id=ru.kontur.farma";
export const IOSdApp =
  "https://apps.apple.com/ru/app/%D1%84%D0%B0%D1%80%D0%BC%D0%B0-%D1%81%D0%BA%D0%B0%D0%BD%D0%B5%D1%80/id1518852617?l=en";
