import React from "react";
import { observer } from "mobx-react";
import { MdlpErrorIcon, TokenIcon, WarningOrangeIcon } from "icons";
import { Button, EllipsisText, Link } from "components";
import {
  ServerError,
  ServerErrorText,
  WarningBlock,
  WarningDescription,
  WarningIconWrap,
  WarningText,
} from "./Errors.styles";
import { MDLPCabinetUrl } from "StringResx";
import { DeliveryType } from "typings/server";

type TMdlpErrors = "certNotFound" | "unavailable";

interface IServerErrorsProps extends ITokenServerErrorProps {
  errors: Map<TMdlpErrors, string>;
}

export const ServerErrors = observer((props: IServerErrorsProps) => {
  const { errors, deliveryType, tryToAuth } = props;

  if (errors.get("certNotFound")) {
    return <TokenServerError deliveryType={deliveryType} tryToAuth={tryToAuth} />;
  }

  if (errors.get("unavailable")) {
    return <MdlpServerError />;
  }

  return null;
});

interface ITokenServerErrorProps {
  tryToAuth(): void;
  deliveryType: DeliveryType;
}

export const TokenServerError = observer((props: ITokenServerErrorProps) => {
  const tryToAuth = () => props.tryToAuth();

  return (
    <ServerError>
      <TokenIcon />
      <ServerErrorText>
        <b>Не найден ключ доступа к ИС МДЛП</b>
        <br />
        {props.deliveryType === DeliveryType.DisposalWithRegistrator || props.deliveryType === DeliveryType.Disposal
          ? "Дополнительная информация и сверка доступных долей для вывода из оборота недоступны."
          : "Дополнительная информация недоступна."}
        <p>
          Вы можете продолжить сканирование или&nbsp;
          <Button use="link" onClick={tryToAuth} size={"small"}>
            получить доступ
          </Button>
          .
        </p>
      </ServerErrorText>
    </ServerError>
  );
});

export const MdlpServerError = observer(() => {
  return (
    <ServerError>
      <MdlpErrorIcon />
      <ServerErrorText>
        <b>Сервер ИС МДЛП недоступен</b>
        <br />
        Дополнительная информация недоступна.
        <p>Вы можете продолжить сканирование без этой информации.</p>
      </ServerErrorText>
    </ServerError>
  );
});

interface AdditionalInfoWarningProps {
  description?: string;
  isInputMode?: boolean;
  errorCode?: number;
  maxWidth?: number;
}

export const AdditionalInfoWarning = observer(
  ({ description, errorCode, isInputMode, maxWidth }: AdditionalInfoWarningProps) => {
    return (
      <WarningBlock>
        <WarningIconWrap>
          <WarningOrangeIcon />
        </WarningIconWrap>
        <WarningText>
          <WarningDescription>
            <EllipsisText width={maxWidth || 570}>{description}</EllipsisText>
          </WarningDescription>
          {getTextByErrorCode()}
        </WarningText>
      </WarningBlock>
    );

    function getTextByErrorCode() {
      switch (errorCode) {
        case 4:
          return (
            <>
              Проверьте, что отсканированный код принадлежит вам в{" "}
              <Link href={MDLPCabinetUrl} target="_blank">
                личном кабинете ИС МДПЛ.
              </Link>
            </>
          );
        case 113:
          return (
            <>
              Проверьте {isInputMode ? "введенный" : "отсканированный"} код, в дальнейшем по нему зафиксируют нарушение.
            </>
          );
        default:
          return (
            <>
              Проверьте {isInputMode ? "введенный" : "отсканированный"} код, в дальнейшем с ним возможны проблемы
              со&nbsp;стороны ИС МДЛП.
            </>
          );
      }
    }
  }
);
