import {
  AcceptusType,
  DeliveryModel,
  DeliveryType,
  NewDeliveryModel,
  PatchDeliveryModel,
  PositionsReportModel,
} from "typings/server";
import { ILoadOptions } from "./shared";
import { DeliveriesApi, TDeliveryPropertyName } from "api/DeliveryApi";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import { ReportsApi } from "api/ReportsApi";
import { DocumentsApi } from "api/DocumentsApi";
import { TasksApi } from "api/TasksApi";

export class DeliveryStoreActions<T extends DeliveryModel> {
  async addNewDelivery(newDelivery: NewDeliveryModel): Promise<void> {
    await DeliveriesApi.addNewDelivery(newDelivery.id);
  }

  async uploadUtd(file: File, deliveryType: DeliveryType, acceptusType: AcceptusType): Promise<NewDeliveryModel> {
    return await DeliveriesApi.uploadUtd(file, deliveryType, acceptusType);
  }

  public async saveCodes(options: Partial<CodesReportModelExtended>): Promise<any> {
    return await ReportsApi.saveCodes(options);
  }

  public async savePositions(options: Partial<PositionsReportModel>): Promise<any> {
    return await ReportsApi.savePositions(options);
  }

  public async getItem(current: T, id: string, options?: Partial<ILoadOptions>): Promise<DeliveryModel> {
    const isTheSameDelivery = current && current.id === id;
    if (isTheSameDelivery && !(options && options.force)) {
      return Promise.resolve(current);
    }
    return await DeliveriesApi.getDelivery(id);
  }

  async saveDelivery(delivery: Partial<PatchDeliveryModel>, id: string) {
    // Перезатирает неотправленные поля модели PatchDeliveryModel
    await DeliveriesApi.updateDelivery(delivery, id);
  }

  async rollbackDeliveryStatus(deliveryId: string) {
    await DeliveriesApi.rollbackDeliveryStatus(deliveryId);
  }

  async deleteDelivery(deliveryId: string) {
    await DeliveriesApi.deleteDelivery(deliveryId);
  }

  async getDeliveryProperty(deliveryId: string, propertyName: TDeliveryPropertyName): Promise<any> {
    return await DeliveriesApi.getDeliveryProperty(deliveryId, propertyName);
  }

  async prepareDocuments(deliveryId: string) {
    return DocumentsApi.prepareDocuments(deliveryId);
  }

  async checkDocumentsStatus(taskId: string) {
    return TasksApi.checkDocumentsStatus(taskId);
  }
}
