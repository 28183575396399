import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link, Loader } from "components";
import { WelcomeVM } from "./WelcomeVM";
import { AppMailTo, SupportPhone } from "StringResx";
import { ErrorText, Text, ErrorWrapper, LoaderWrapper } from "./Welcome.styles";
import ErrorIcon from "@skbkontur/react-icons/Error";
import { GlobalStyle } from "styles/global.styles";

interface IWelcomeProps
  extends RouteComponentProps<{
    inviteId: string;
  }> {}

export const Welcome = withRouter(
  observer((props: IWelcomeProps) => {
    const { location, history } = props;
    const searchParams = new URLSearchParams(location.search.toLowerCase());
    const inviteId = searchParams.get("inviteid");
    const vm = WelcomeVM.getInstance(inviteId);

    useEffect(() => {
      vm.checkInvitation().then(() => {
        history.push("/");
      });
    }, [location]);

    if (vm.loading) {
      return (
        <LoaderWrapper>
          <GlobalStyle />
          <Loader active={vm.loading} caption="Проверяем приглашение..." />
        </LoaderWrapper>
      );
    }

    return (
      <>
        <GlobalStyle />
        <ErrorWrapper>
          {vm.errors.has("notFound") && (
            <Text>
              <ErrorText>
                <ErrorIcon />
                &nbsp;{vm.errors.get("notFound")}
              </ErrorText>
              <br />
              <br />
              Пожалуйста, напишите письмо в <Link href={AppMailTo}>техподдержку</Link>
              <br /> или позвоните по номеру&nbsp;
              <Link href={`tel:${SupportPhone}`}>{SupportPhone}</Link>
              <br />
            </Text>
          )}
        </ErrorWrapper>
      </>
    );
  })
);
