import React, { FC, useContext, useState } from "react";
import { observer } from "mobx-react";
import { FreeScanItemRow } from "./FreeScanItemRow";
import { FreeScanSidePageVM } from "./FreeScanSidePageVM";
import { CodesWrap } from "../../ScannerSidePage.styles";
import { ICertificate } from "@skbkontur/plugin-js";
import { IStore } from "stores/shared";
import { MdlpModal } from "../../../CertificatesList/MdlpModal";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { ScannerCounter } from "../../ScannerCounter/ScannerCounter";
import { EmptyCodesRow } from "../UtdScan/EmptyCodesRow";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { ExpandAllIconWrap, TableHeadCellWrap } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import { ExpandAllIcon, CollapseAllIcon } from "icons";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { DeliveryType } from "typings/server";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";

interface IFreeScanSidePageBodyProps {
  vm: FreeScanSidePageVM<IStore>;
}

export const FreeScanSidePageBody = observer((props: IFreeScanSidePageBodyProps) => {
  const { vm } = props;
  const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;

  const [allCodesExpanded, setAllCodesExpanded] = useState(false);
  const toggleAllCodes = () => {
    setAllCodesExpanded(v => !v);
  };

  const additionalInfoVM = React.useMemo(
    () =>
      new AdditionalInfoVM(
        vm.copiedData,
        vm.additionalInfo,
        true,
        deliveryType,
        extraFields,
        vm.fractionsValidationMap,
        vm.checkFractionDisposalValidation,
        vm.handleErrors
      ),
    [vm.copiedData]
  );

  const onDiscardItem = (item: DeliveryItemModelExtended) => vm.discardItem(item);
  const onDiscardCode = (code: string) => vm.discardCode(code);

  const onCloseMdlpModal = () => vm.toggleModal("mdlpModal");
  const onDismiss = () => {
    onCloseMdlpModal();
    vm.setCertNotFoundError();
  };

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
    vm.updateItemName(vm.currentCode);
  };

  return (
    <>
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={onCloseMdlpModal}
          title={"Доступ в ИС МДЛП"}
          sign={onSign}
          onDismiss={onDismiss}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}
      <ScannerCounter title={"Отсканировано кодов маркировки"} allScannedLen={vm.allScannedLen} />
      <TableHeadCellWrap top="7px" right="13px" hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
        <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
      </TableHeadCellWrap>
      <Grid cols={"40px 4fr 1fr"} cellsBorderBottom>
        <GridCellHeader col={1} row={1}>
          №
        </GridCellHeader>
        <GridCellHeader col={2} row={1}>
          Коды маркировки
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign={"right"}>
          Кол-во кодов
        </GridCellHeader>
      </Grid>

      <Table
        copiedData={vm.copiedData}
        newCode={vm.newCode}
        additionalInfoVM={additionalInfoVM}
        newCodeItem={vm.newCodeItem}
        onDiscardItem={onDiscardItem}
        onDiscardCode={onDiscardCode}
        count={vm.copiedData.length}
        allCodesExpanded={allCodesExpanded}
      />
    </>
  );
});

interface TableProps {
  copiedData: DeliveryItemModelExtended[];
  newCode?: string;
  additionalInfoVM: AdditionalInfoVM;
  newCodeItem?: DeliveryItemModelExtended;
  onDiscardItem: (item: DeliveryItemModelExtended) => void;
  onDiscardCode: (code: string) => void;
  count: number;
  allCodesExpanded: boolean;
}

const Table: FC<TableProps> = observer(props => {
  const {
    copiedData,
    newCode,
    additionalInfoVM,
    newCodeItem,
    count,
    onDiscardItem,
    onDiscardCode,
    allCodesExpanded,
  } = props;

  if (!copiedData.length) {
    return (
      <CodesWrap data-tid="ScannedCodes">
        <EmptyCodesRow />
      </CodesWrap>
    );
  }
  return (
    <CodesWrap data-tid="ScannedCodes">
      {copiedData.map((item, i) => (
        <FreeScanItemRow
          isNewItem={newCodeItem === item}
          newCode={newCode}
          item={item}
          index={count - 1 - i}
          itemCodesLength={copiedData.find(el => el.code === item.code)?.allCodes.length ?? 0}
          key={item.name}
          onDiscardItem={onDiscardItem}
          onDiscardCode={onDiscardCode}
          additionalInfoVM={additionalInfoVM}
          allCodesExpanded={allCodesExpanded}
        />
      ))}
    </CodesWrap>
  );
});
