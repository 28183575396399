import { DownloadResult } from "api/HttpClient/shared";

export namespace Navigator {
  export class msSaveOrOpenBlob {}
}

export function download({ data, fileName }: DownloadResult) {
  // @TODO разобраться с типами
  if ((window.navigator as any).msSaveOrOpenBlob) {
    // ie 10+
    (window.navigator as any).msSaveOrOpenBlob(data, fileName);
    return;
  }

  const blobUrl = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = blobUrl;

  if (fileName) {
    link.setAttribute("download", fileName);
  }

  if (typeof link.download === "undefined") {
    link.setAttribute("target", "_self");
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}

export function getFileName(headers: Headers): string {
  const disposition = headers.get("content-disposition");

  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/g;
    const fileName = getFileNameFromMatch(filenameRegex.exec(disposition));
    const fileNameUTF8 = getFileNameFromMatch(filenameRegex.exec(disposition));
    return fileNameUTF8 || fileName;
  }

  return "";
}

function getFileNameFromMatch(matches: RegExpExecArray | null) {
  if (matches != null && matches.length > 3) {
    const fileName = matches[3];
    return decodeURIComponent(fileName);
  }

  return "";
}
