import styled from "styles/styled-components";

export const IndexWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const HeadWrap = styled.div`
  padding: 24px 24px 0;
`;

export const TabsWrap = styled.div`
  margin-top: 12px;
  margin-left: 2px;
`;
