import React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Input, Loader, Modal, Select, Tooltip } from "components";
import { Footer, FooterBtnWrapper } from "components/Layout/Footer.styles";
import { TransferEditorModalVM } from "./TransferEditorModalVM";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { TransferModalBody } from "./TransferEditorModal.styles";
import { UserStore } from "stores/UserStore";
import { TextGrey } from "styles/shared.styles";
import { TransferStore } from "stores/TransferStore";
import { TransferRoute } from "routes";
import { TransferDeliveryModel } from "models/Delivery/TransferDeliveryModel";
import { DepartmentTypeNames } from "typings/dictionaries";
import { ItemComment, ItemWrap } from "Common/Editor/Editor.style";
import { DepartmentModel } from "typings/server";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

interface ITransferInternalModalProps extends INewTransferModalProps, RouteComponentProps<TransferRoute> {
  vm: TransferEditorModalVM;
}

const TransferModalInternal = withRouter(
  observer((props: ITransferInternalModalProps) => {
    const { vm, history } = props;

    const onClose = () => props.onClose();

    const renderAddress = (item: DepartmentModel) => item.address;

    const renderItemAddress = (item: DepartmentModel) => (
      <ItemWrap>
        {item.address}
        <br />
        <ItemComment>
          {item.type ? DepartmentTypeNames[item.type] : ""}
          {item.mdlpSubjectId ? `, № ${item.mdlpSubjectId}` : ""}
        </ItemComment>
      </ItemWrap>
    );

    const getDocDateError = () => vm.errors.get("invalidDocDate") || vm.errors.get("docDateRequired");

    const getTransferDateError = () => vm.errors.get("invalidTransferDate");

    const getRecipientError = () => vm.errors.get("recipientRequired");

    const getDocNumError = () => vm.errors.get("docNumRequired");

    const onSave = async () => {
      try {
        const id = await vm.save();
        if (id) {
          history.push(TransferRoute.getHref(id));
        } else {
          onClose();
        }
      } catch (e) {
        // empty promise for validation fail
        if (e) {
          throw e;
        }
      }
    };

    return (
      <Modal data-tid="TransferEditorModal" onClose={onClose} ignoreBackgroundClick>
        <Modal.Header sticky>{vm.isNew ? "Добавление нового перемещения" : "Редактирование перемещения"}</Modal.Header>
        <Modal.Body>
          <TransferModalBody>
            <Loader data-tid="Loader" active={vm.loading} minHeight="320px">
              <Grid cols={"148px 1fr"}>
                <GridCell col={1} row={1}>
                  Отправитель
                </GridCell>

                <GridCell col={2} row={1}>
                  <span data-tid="CompanyName">{vm.companyName}</span>
                  <br />
                  <TextGrey data-tid="CurrentDepartmentAddress">{vm.currentDepartmentAddress}</TextGrey>
                </GridCell>

                <GridCell col={1} row={2} padding={"15px 4px 8px"}>
                  Получатель
                </GridCell>

                <GridCell col={2} row={2}>
                  <Tooltip trigger={"hover"} render={getRecipientError}>
                    <Select<DepartmentModel>
                      data-tid="Departments"
                      placeholder={"Выберите"}
                      width={"100%"}
                      maxWidth={"444px"}
                      items={vm.departments}
                      renderValue={renderAddress}
                      renderItem={renderItemAddress}
                      onValueChange={vm.setRecipient}
                      error={!!getRecipientError()}
                      value={vm.recipient}
                    />
                  </Tooltip>
                </GridCell>

                <GridCell col={1} row={3} padding={"15px 4px 8px"}>
                  Дата перемещения
                </GridCell>
                <GridCell col={2} row={3} padding={"8px 4px 43px"}>
                  <DateTimePicker
                    data-tid="TransferDate"
                    getReportDateError={getTransferDateError}
                    maxDate={vm.maxDate}
                    value={vm.transferDate}
                    onChange={vm.setTransferDate}
                  />
                </GridCell>

                <GridCell col={1} row={4} padding={"15px 4px 8px"}>
                  № документа
                </GridCell>
                <GridCell col={2} row={4}>
                  <Tooltip trigger={"hover"} render={getDocNumError}>
                    <Input
                      data-tid="DocumentNumber"
                      width={"120px"}
                      value={vm.documentNumber}
                      onValueChange={vm.setDocumentNumber}
                      error={!!getDocNumError()}
                    />
                  </Tooltip>
                </GridCell>
                <GridCell col={1} row={5} padding={"15px 4px 8px"}>
                  Дата документа
                </GridCell>
                <GridCell col={2} row={5}>
                  <DateTimePicker
                    data-tid="DocumentDate"
                    getReportDateError={getDocDateError}
                    maxDate={vm.maxDate}
                    value={vm.documentDate}
                    onChange={vm.setDocumentDate}
                    withoutTime
                  />
                </GridCell>
              </Grid>
            </Loader>
          </TransferModalBody>
        </Modal.Body>
        <Footer>
          <FooterBtnWrapper>
            <Button data-tid="Submit" use="primary" size={"large"} onClick={onSave}>
              {vm.isNew ? "Добавить перемещение" : "Сохранить"}
            </Button>
            <Button data-tid="Cancel" onClick={onClose} size={"large"}>
              Отмена
            </Button>
          </FooterBtnWrapper>
        </Footer>
      </Modal>
    );
  })
);

interface INewTransferModalProps {
  onClose(): void;
  delivery?: TransferDeliveryModel;
}

// React.memo + () => true - не обновляет компонент
export const TransferEditorModal = React.memo(
  (props: INewTransferModalProps) => {
    const vm = new TransferEditorModalVM(UserStore, TransferStore, props.delivery);
    return <TransferModalInternal {...props} vm={vm} />;
  },
  () => true
);
