import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { OperationsListItem } from "./OperationsListItem";
import { Loader, Paging } from "components";
import { DeliveryPagingWrapper } from "Common/DeliveriesList/DeliveriesList.styles";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { EmptyDeliveries } from "../MainPage.styles";
import { StatusItem } from "Common/Status/StatusItem";
import { FilterDropdown } from "Common/Filter/FilterDropdown/FilterDropdown";
import { FilterResetRow } from "Common/Filter/FilterResetRow/FilterResetRow";

const cols = "1.7fr 1.8fr 2.6fr 5fr 50px";

interface IOperationsListProps {
  operations: DeliveryIndexModel[];
  onClick: (doc: DeliveryIndexModel) => void;
  type: DeliveryType;
  vm: DeliveriesMainPageVM;
}

export const OperationsList: React.FC<IOperationsListProps> = observer((props: IOperationsListProps) => {
  const { operations, onClick, type, vm } = props;
  const isLoadingList = Boolean(vm.loadingState.get("list"));
  const isLoadingListImmediate = Boolean(vm.loadingState.get("listImmediate"));
  const onDelete = (e: SyntheticEvent<HTMLElement>, delivery: DeliveryIndexModel) => {
    e.stopPropagation();
    vm.setDeliveryToDelete(delivery);
    vm.toggleModal("delete");
  };
  return (
    <>
      <Grid cols={cols} headerBorderBottom>
        <GridCellHeader col={1} row={1} padding="8px 4px 0 10px">
          Дата операции
        </GridCellHeader>
        <GridCellHeader col={2} row={1} textAlign="left">
          № документа
        </GridCellHeader>
        <GridCellHeader col={3} row={1} textAlign="left">
          <FilterDropdown
            data-tid="StatusFilter"
            value={vm.allowedStatuses}
            caption="Статус"
            vm={vm}
            renderCustomItemLabel={value => <StatusItem status={value.name} type={type} />}
          />
        </GridCellHeader>
        <GridCellHeader col={4} row={1} textAlign="left">
          <FilterDropdown
            data-tid="AllowedDestinationsFilter"
            value={vm.allowedDestinations}
            caption="Место получения"
            vm={vm}
            isSearchAvailable
          />
        </GridCellHeader>
        <GridCellHeader col={5} row={1}></GridCellHeader>
      </Grid>

      <Loader
        data-tid="Loader"
        active={isLoadingList || isLoadingListImmediate}
        immediate={isLoadingListImmediate}
        showChildren
      >
        {vm.isShowFilterResetRow && (
          <FilterResetRow
            totalSize={vm.totalSize}
            plural={["операция", "операции", "операций"]}
            resetFilters={vm.resetAllFiltersAndPage}
            paddingLeft="9px"
          />
        )}
        {operations.map(operation => (
          <OperationsListItem
            data-tid="OperationsListItem"
            type={type}
            cols={cols}
            key={operation.id}
            operation={operation}
            onClick={onClick}
            onDelete={onDelete}
            isCanBeDeleted={vm.isCanBeDeleted(operation)}
          />
        ))}
        {vm.pagesCount > 1 && (
          <DeliveryPagingWrapper>
            <Paging activePage={vm.activePage} onPageChange={vm.setActivePage} pagesCount={vm.pagesCount} />
          </DeliveryPagingWrapper>
        )}
        {vm.isShowEmptyMessageBlock && <EmptyDeliveries>Операций по перемещению еще нет</EmptyDeliveries>}
      </Loader>
    </>
  );
});
