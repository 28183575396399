import React, { useEffect } from "react";
import { observer } from "mobx-react";
import AddIcon from "@skbkontur/react-icons/Add";
import { AcceptanceRoute, ShipmentRoute } from "routes";
import { Dropdown, Input, Link, Loader, MenuItem } from "components";
import { RouteComponentProps } from "react-router";
import { AcceptusType, DeliveryIndexModel, DeliveryType } from "typings/server";
import { Content } from "components/Layout";
import { ICertificate } from "@skbkontur/plugin-js";
import { SignatureStore } from "stores/SignatureStore";
import { UserStore } from "stores/UserStore";
import {
  DeliveryDropdownWrap,
  DeliveryH3,
  DeliveryHeader,
  DeliveryTable,
  DeliveryTopBlock,
  UpdateBlock,
  InputWrap,
  MenuHeaderText,
} from "Common/Styles/MainPage.styles";
import { DeliveriesMainPageVM } from "Common/DeliveriesMainPageVM";
import { UtdModal } from "Common/UtdModal/UtdModal";
import { SyncInfo } from "Common/SyncInfo/SyncInfo";
import { DeliveriesList } from "./DeliveriesList/DeliveriesList";
import { getNewShipmentDeliveryInfoBlock } from "./NewDeliveryInfo/NewDeliveryInfo";
import { MdlpModal } from "../../CertificatesList/MdlpModal";
import { ShipmentStore } from "stores/ShipmentStore";
import { SYNC_POLL_INTERVAL } from "stores/shared";
import { DeliveriesStore } from "stores/DeliveriesStore";
import SearchIcon from "@skbkontur/react-icons/Search";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { AdvertisementBlock } from "Common/AdvertisementBlock/AdvertisementBlock";
import { AdvertisementStore } from "stores/AdvertisementStore";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { shipmentInstruction } from "StringResx";
import { UtdTitle } from "./UtdTitle/UtdTitle";

interface IAcceptanceMainPageProps extends RouteComponentProps<AcceptanceRoute> {
  vm: DeliveriesMainPageVM;
}

const DELIVERY_TYPE = DeliveryType.Shipment;

const AcceptanceMainPageInternal: React.FC<IAcceptanceMainPageProps> = observer((props: IAcceptanceMainPageProps) => {
  const { vm, history } = props;

  // unmount
  useEffect(() => {
    return () => {
      vm.handleUnmount();
    };
  }, [vm]);

  const goToAcceptancePage = (doc: DeliveryIndexModel) => {
    vm.setIsPreserveFilters(true);
    if (doc.id) history.push(ShipmentRoute.getHref(doc.id));
  };

  const toggleUtdModal = () => {
    vm.setAcceptusType(AcceptusType.Direct);
    vm.toggleModal("utd");
  };

  const toggleUtdEAEUModal = () => {
    vm.setAcceptusType(AcceptusType.Eeu);
    vm.toggleModal("utd");
  };

  const toggleUtdStateDispatchModal = () => {
    vm.setAcceptusType(AcceptusType.StateDispatch);
    vm.toggleModal("utd");
  };

  const toggleUtdSuspendedReturnDispatchModal = () => {
    vm.setAcceptusType(AcceptusType.SuspendedReturn);
    vm.toggleModal("utd");
  };

  const toggleMdlpModal = () => vm.toggleModal("mdlpModal");

  const toggleDeleteModal = () => vm.toggleModal("delete");

  const onSign = async (cert: ICertificate) => {
    await vm.onSing(cert);
    await vm.synchronizeAndUpdate(SYNC_POLL_INTERVAL);
  };

  const onDelete = async () => {
    toggleDeleteModal();
    await vm.deleteDelivery();
  };

  const onSetAdvertisementShown = () => vm.setAdvertisementShown("suspendedReturn");

  if (vm.loadingState.get("start")) return <Loader active />;

  return (
    <Content data-tid="MainPage">
      {vm.modalState.get("utd") && (
        <UtdModal
          store={ShipmentStore}
          acceptusType={vm.acceptusType}
          baseRoute={ShipmentRoute.baseRoute}
          title={<UtdTitle acceptusType={vm.acceptusType} />}
          onClose={toggleUtdModal}
          type={DELIVERY_TYPE}
          btnText={"Добавить отгрузку"}
          newDeliveryBlockFunc={getNewShipmentDeliveryInfoBlock}
        />
      )}
      {vm.modalState.get("mdlpModal") && (
        <MdlpModal
          onClose={toggleMdlpModal}
          sign={onSign}
          title={"Доступ в ИС МДЛП"}
          successText={"Доступ в ИС МДЛП открыт на 30 минут"}
        />
      )}
      {vm.modalState.get("delete") && vm.deliveryToDelete && (
        <DeleteDeliveryModal
          deliveryType={vm.deliveryToDelete.type}
          onDelete={onDelete}
          onClose={toggleDeleteModal}
          getDeliveryItems={vm.getDeliveryItems}
          isLoading={vm.loadingState.get("delete")}
        />
      )}
      <DeliveryTopBlock>
        <DeliveryHeader>
          <h2>{ShipmentRoute.pageTitle}</h2>
          <UpdateBlock>
            <SyncInfo vm={vm} />
          </UpdateBlock>
        </DeliveryHeader>
        <DeliveryDropdownWrap>
          <div>
            <AdvertisementBlock
              text="Новинка: возможность вернуть поставщику приостановленный товар"
              onClose={onSetAdvertisementShown}
              width="165px"
              isDisplayed={!!vm.advertisementsMap?.get("suspendedReturn")}
            >
              <Dropdown
                data-tid="CreateNewOperation"
                caption="Добавить отгрузку"
                disabled={!vm.canLoadUtd}
                icon={<AddIcon />}
                menuWidth="280px"
              >
                <MenuHeaderText>Загрузить товарную накладную:</MenuHeaderText>
                <MenuItem
                  data-tid="DirectShipment"
                  onClick={toggleUtdModal}
                  comment={
                    <>
                      В том числе на незарегистрированное
                      <br /> место деятельности
                    </>
                  }
                >
                  Для отгрузки
                </MenuItem>
                <MenuItem data-tid="StateDispatch" onClick={toggleUtdStateDispatchModal}>
                  Для смены владельца в рамках государственного лекарственного обеспечения
                </MenuItem>
                <MenuItem data-tid="SuspendedReturn" onClick={toggleUtdSuspendedReturnDispatchModal}>
                  Для возврата приостановленного товара
                </MenuItem>
                <MenuItem data-tid="EAEU" onClick={toggleUtdEAEUModal}>
                  Для отгрузки в страну ЕАЭС
                </MenuItem>
              </Dropdown>
            </AdvertisementBlock>
            <InputWrap>
              <Input
                data-tid="DocumentNumberSearch"
                value={vm.documentNumberQuery}
                onValueChange={vm.changeDocumentNumberQuery}
                width={"250px"}
                placeholder="Поиск по номеру документа"
                rightIcon={<SearchIcon />}
              />
            </InputWrap>
          </div>
          <Link data-tid="InfoLink" href={shipmentInstruction} icon={<HelpDotIcon />} target="_blank">
            Отгрузка
          </Link>
        </DeliveryDropdownWrap>
        <DeliveryH3>Отгрузки</DeliveryH3>
      </DeliveryTopBlock>

      <DeliveryTable>
        <DeliveriesList data-tid="OperationsList" vm={vm} onClick={goToAcceptancePage} type={DELIVERY_TYPE} />
      </DeliveryTable>
    </Content>
  );
});

export const MainPage = (props: RouteComponentProps<AcceptanceRoute>) => {
  const vm = new DeliveriesMainPageVM(ShipmentStore, SignatureStore, UserStore, DeliveriesStore, AdvertisementStore, [
    DELIVERY_TYPE,
  ]);
  return <AcceptanceMainPageInternal vm={vm} {...props} />;
};
