import { SentryExtras } from "helpers/logger";

export class ComponentDidCatchError extends Error {
  public sentryExtras: SentryExtras;

  constructor(cause: Error, errorInfo: React.ErrorInfo) {
    super("Component did catch error.", { cause });
    Object.setPrototypeOf(this, ComponentDidCatchError.prototype);
    this.sentryExtras = {"ReactErrorInfo": errorInfo };
  }
}
