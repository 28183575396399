import styled from "styles/styled-components";

export const PageInfoBlockWrap = styled.div`
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: 35px;
`;

export const DateWrap = styled.div`
  margin-bottom: 20px;
`;

export const DateText = styled.span`
  margin-right: 32px;
`;

export const Title = styled.h4`
  margin-bottom: 10px;
`;

export const CodesBlock = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 34px;
  margin-bottom: 10px;
`;

export const ScanPanel = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
`;
