import { getFormattedDate, longDateOptions } from "helpers/date";
import { computed } from "mobx";
import { IBalanceStore } from "stores/BalanceStore";

export class BalanceTextVM {
  constructor(private readonly store: IBalanceStore) {}

  @computed
  get balanceInfo() {
    const { lastSubscriptionDate, allRestCount } = this.store;
    const formattedDate = getFormattedDate(lastSubscriptionDate, longDateOptions);
    return { allRestCount, formattedDate };
  }

  @computed
  get isBalanceWarning() {
    const {
      isRestCodesLessThanCertainAmount,
      isSubscriptionExpiredSoon,
      isUnlimit,
      isSubscriptionExpired,
    } = this.store;
    const isFewCodes = isRestCodesLessThanCertainAmount && !isUnlimit;
    return (isFewCodes || isSubscriptionExpiredSoon) && !isSubscriptionExpired;
  }

  @computed
  get isBalanceSevereWarning() {
    const { isRestCodesEmpty, isSubscriptionExpired, isUnlimit } = this.store;
    return isRestCodesEmpty && !isSubscriptionExpired && !isUnlimit;
  }

  @computed
  get isUnlimit() {
    return this.store.isUnlimit;
  }

  @computed
  get balanceWarningLevel() {
    if (this.isBalanceSevereWarning) {
      return "error";
    }
    if (this.isBalanceWarning) {
      return "warning";
    }
    return "normal";
  }
}
