import { action, observable } from "mobx";
import { DeliveriesMainPageVM } from "../Common/DeliveriesMainPageVM";
import { ISignatureStore } from "stores/SignatureStore";
import { IUserStore } from "stores/UserStore";
import { DeliveryType } from "typings/server";
import { IPackingStore } from "stores/PackingStore";
import { PackingDeliveryModel } from "models/Delivery/PackingDeliveryModel";
import { IDeliveryStore } from "stores/DeliveriesStore";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import { AdvertisementStore } from "stores/AdvertisementStore";

export class PackingIndexPageVM {
  readonly listVM: DeliveriesMainPageVM;
  @observable loading: boolean = false;
  @observable newDelivery: PackingDeliveryModel;

  constructor(
    readonly deliveryStore: IPackingStore,
    readonly signatureStore: ISignatureStore,
    readonly userStore: IUserStore,
    readonly deliveriesStore: IDeliveryStore,
    type: DeliveryType
  ) {
    this.listVM = new DeliveriesMainPageVM(
      deliveryStore,
      signatureStore,
      userStore,
      deliveriesStore,
      AdvertisementStore,
      [type]
    );
  }

  async createDelivery(type: DeliveryType) {
    this.loading = true;
    try {
      this.newDelivery = await this.deliveryStore.createDelivery(type);
    } finally {
      this.loading = false;
    }
  }

  setType(type: DeliveryType) {
    this.listVM.setType([type]);
  }

  @action
  async saveItems() {
    const delivery = this.newDelivery;
    await this.deliveryStore.saveItems(delivery.items, delivery.id);
  }

  @action
  saveCodes = async (model: Partial<CodesReportModelExtended>) => {
    await this.deliveryStore.saveCodes(model);
  };
}
