import { action, computed, toJS } from "mobx";
import { getSsccFromBarcode, isSscc } from "helpers/codes";
import { IScannerSidePageVM } from "features/ScannerSidePage/shared";
import { ISignatureStore } from "stores/SignatureStore";
import { IPackingStore } from "stores/PackingStore";
import { ScanHelper, ScanMode } from "features/ScannerSidePage/ScanHelper";
import { PackingDeliveryModel } from "models/Delivery/PackingDeliveryModel";
import { hasRuChars } from "helpers";
import { CodeAdditionalInfoModel, CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { BaseScanPageVM } from "../BaseScanPageVM";
import { QRCodeId } from "../../QrCode/QrCodeBlock";
import { clone } from "lodash-es";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import { IAdvertisementStore } from "stores/AdvertisementStore";

export class UnpackingSidePageVM extends BaseScanPageVM<IPackingStore, string, string> implements IScannerSidePageVM {
  constructor(
    store: IPackingStore,
    signatureStore: ISignatureStore,
    advertisementStore: IAdvertisementStore,
    additionalInfo: CodesAdditionalInfo,
    readonly delivery: PackingDeliveryModel,
    readonly onSaveCodes: (model: Partial<CodesReportModelExtended>, id?: string) => Promise<void>
  ) {
    super(store, signatureStore, advertisementStore, additionalInfo, delivery.id);

    this.copiedData = clone(toJS(delivery.scannedCodes));
  }

  @action
  addScannedCode(code: string = "", codeStr?: string) {
    const normalizedCode = isSscc(code) ? code : getSsccFromBarcode(code);
    if (!this.checkValidation(normalizedCode, codeStr) || !normalizedCode) {
      return;
    }
    const index = this.copiedData.findIndex(x => x === code);
    if (index !== -1) {
      this.copiedData.splice(index, 1);
    }
    this.copiedData.unshift(code);

    this.setCurrentCode(normalizedCode);
    this.setNewCodeItem(code);
    this.getCodeAdditionalInfo(code);
  }

  @action
  checkValidation(code?: string, codeStr?: string): boolean {
    this.showError = false;
    this.errors.clear();
    this.setErrorWarningTimeout();

    if (codeStr && hasRuChars(codeStr)) {
      const err =
        this.mode === ScanMode.Input
          ? "Код маркировки не должен содержать русские буквы"
          : "Русская раскладка клавиатуры. Переключите на английскую и повторите сканирование";
      this.showError = true;
      this.errors.set("lang", err);
      return false;
    }

    if (!code || !isSscc(code)) {
      const err =
        this.mode === ScanMode.Input ? "Неверный формат кода" : "Неверный формат кода. Продолжайте сканирование.";
      this.errors.set("wrongCode", err);
      this.showError = true;
      return false;
    }

    const codeIsAlreadyScanned = this.copiedData.some(x => x === code);
    if (codeIsAlreadyScanned) {
      const err = this.mode === ScanMode.Input ? "Этот код уже есть в списке" : "Этот код уже сканировали";
      this.warns.set("alreadyExist", err);
      // show additional info again
      this.getCodeAdditionalInfo(code).then(() => {
        const found = this.copiedData.find(x => x === code);
        if (found) this.setNewCodeItem(found);
      });
      this.showWarn = true;
      return false;
    }
    return true;
  }

  @action
  setCertNotFoundError() {
    this.serverErrors.set("certNotFound", "Не найден ключ доступа к МДЛП.\n\r Состав не доступен");
  }

  @action
  async save() {
    this.loadingState.set("onSave", true);
    this.additionalInfo.abort();
    try {
      if (this.isPhoneMode) {
        // stop polling and get fresh codes from the server before closing side page
        this.pollCodes.stop();
        await this.pollScannedCodesFunc();
      }
      const model = {
        codes: this.copiedData,
        deliveryId: this.deliveryId,
      };
      this.delivery.updateScannedCodes(this.copiedData);
      await this.onSaveCodes(model, this.deliveryId);
      this.setAdvertisementShown("massDisaggregation");
    } finally {
      this.loadingState.set("onSave", false);
    }
  }

  @action
  async pollScannedCodesFunc() {
    const codes = await this.store.getScannedCodes(this.deliveryId);
    if (codes && codes.length) {
      codes.forEach(code => {
        const codeExist = this.copiedData.find(x => x === code);
        if (!this.deletedCodes.includes(code) && !codeExist) {
          this.copiedData.unshift(code);
        }
      });
    }
  }

  @action
  discardAll() {
    this.copiedData = [];
    this.resetFields();
  }

  @action.bound
  discardCode(code: string): void {
    const foundItem = this.copiedData.find(x => x === code);
    if (foundItem) {
      this.copiedData.splice(this.copiedData.indexOf(foundItem), 1);
      this.deletedCodes.push(code);
      this.resetFields();
    }
  }

  @computed
  get warnErrorDescription(): string {
    return this.errors.values().next().value || this.warns.values().next().value;
  }

  @computed
  get warnErrorTitle(): string {
    return this.warnErrorDescription;
  }

  @computed
  get disabledSaveBtn(): boolean {
    return !(this.copiedData && this.copiedData.length);
  }

  @computed
  get ssccScanned(): boolean {
    return !!this.copiedData.length;
  }

  createQRCode() {
    ScanHelper.createQRCode(QRCodeId, this.deliveryId);
  }

  showCodeContent(info?: CodeAdditionalInfoModel): boolean {
    return !!info && !info.isError;
  }
}
