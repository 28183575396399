import { observer } from "mobx-react";
import React from "react";
import { Button, Link } from "components";
import { SettingsPageVM } from "../SettingsPageVM";
import AddIcon from "@skbkontur/react-icons/Add";
import { ButtonWrap, GridWrap, PageWrap } from "./RegistratorsPage.styles";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { RegistratorItem } from "./RegistratorItem";
import { DeletionModal } from "../Common/DeletionModal/DeletionModal";
import { RegistratorEditor } from "./RegistratorEditor/RegistratorEditor";
import { disposalRegistrarInstruction } from "StringResx";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";

interface RegistratorsPageProps {
  vm: SettingsPageVM;
}

export const RegistratorsPage = observer(({ vm }: RegistratorsPageProps) => {
  const cols = "1fr 2fr 1fr 42px";

  const onDelete = () => vm.deleteRegistrator();

  const onCloseDeletionModal = () => {
    vm.setSelectedRegistrator(undefined);
    vm.toggleModal("deleteRegistrator");
  };

  const toggleRegistratorEditor = () => {
    vm.toggleModal("registratorEditor");
    if (vm.selectedRegistrator) {
      vm.setSelectedRegistrator(undefined);
    }
  };

  return (
    <>
      {vm.modals.get("deleteRegistrator") && (
        <DeletionModal header="Удалить регистратор?" onDelete={onDelete} onClose={onCloseDeletionModal} />
      )}
      {vm.modals.get("registratorEditor") && (
        <RegistratorEditor
          onSave={vm.onSaveRegistratorEditor}
          onClose={toggleRegistratorEditor}
          selectedRegistrator={vm.selectedRegistrator}
        />
      )}
      <PageWrap>
        <ButtonWrap>
          <Button data-tid="AddRegistrator" onClick={toggleRegistratorEditor} icon={<AddIcon />}>
            Добавить регистратор выбытия
          </Button>
          <Link href={disposalRegistrarInstruction} icon={<HelpDotIcon />} target="_blank">
            Как добавить регистратор выбытия
          </Link>
        </ButtonWrap>

        {vm.registrators.length > 0 && (
          <GridWrap>
            <Grid cols={cols} headerBorderBottom>
              <GridCellHeader row={1} col={1}>
                Регистратор выбытия
              </GridCellHeader>
              <GridCellHeader row={1} col={2}>
                Подразделение
              </GridCellHeader>
              <GridCellHeader row={1} col={3}>
                Сотрудники
              </GridCellHeader>
              <GridCellHeader row={1} col={4}></GridCellHeader>
            </Grid>

            {vm.registrators.map((x, i) => (
              <RegistratorItem
                vm={vm}
                index={i}
                item={x}
                cols={cols}
                departmentName={vm.getDepartmentByID(x.departmentId)}
                key={x.name || i}
              />
            ))}
          </GridWrap>
        )}
      </PageWrap>
    </>
  );
});
