import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Grid, GridCellHeader } from "components/Grid/Grid.styles";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import { TableWrap } from "./CodesTable.styles";
import { CodeItem } from "./CodeItem";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { CollapseAllIcon, ExpandAllIcon } from "icons";
import { DeliveryType } from "typings/server";
import { DeliveryTypeContext } from "Common/contexts/DeliveryTypeContext";
import { extraFields } from "Common/AdditionalInfo/extraFields/extraFields";
import { AdditionalInfoVM } from "Common/AdditionalInfo/AdditionalInfoVM";
import { TableHeadCellWrap, ExpandAllIconWrap } from "Common/GoodsTable/GoodsTable/GoodsTable.styles";

const cols = "2fr 4fr 1fr 2fr";

interface ICodesTableProps {
  items: DeliveryItemModelExtended[];
  additionalInfo: CodesAdditionalInfo;
}

export const WithdrawalCodesTable: React.FC<ICodesTableProps> = observer(
  ({ items, additionalInfo }: ICodesTableProps) => {
    const deliveryType = useContext(DeliveryTypeContext) as DeliveryType;

    const [allCodesExpanded, setAllCodesExpanded] = useState(false);
    const toggleAllCodes = () => {
      setAllCodesExpanded(v => !v);
    };

    const vm = React.useMemo(() => new AdditionalInfoVM(items, additionalInfo, false, deliveryType, extraFields), [
      items,
    ]);

    return (
      <TableWrap>
        <Grid cols={cols} headerBorderBottom>
          <GridCellHeader row={1} colStart="1" colEnd={"3"} colSp={"2"} padding="4px">
            <TableHeadCellWrap hasAddInfo={vm.isAnyCodeHasAddInfo} onClick={toggleAllCodes}>
              <ExpandAllIconWrap>{allCodesExpanded ? <ExpandAllIcon /> : <CollapseAllIcon />}</ExpandAllIconWrap>
              Коды маркировки
            </TableHeadCellWrap>
          </GridCellHeader>
          <GridCellHeader borderRight row={1} col={3} textAlign="right" padding="4px 16px 4px 4px">
            Кол-во кодов
          </GridCellHeader>
          <GridCellHeader row={1} col={4} padding="4px 4px 4px 12px">
            Статус вывода из оборота
          </GridCellHeader>
        </Grid>
        {items.map((item, i) => {
          return (
            <CodeItem key={item.name} item={item} index={i} cols={cols} vm={vm} allCodesExpanded={allCodesExpanded} />
          );
        })}
      </TableWrap>
    );
  }
);
