import React, { ReactNode } from "react";
import { CirculationDate, CirculationDateProps } from "./CirculationDate/CirculationDate";
import { FractionDisposal, FractionDisposalProps } from "./FractionDisposal/FractionDisposal";

export type RenderProps = CirculationDateProps | FractionDisposalProps;

export enum ExtraField {
  CirculationDate = "CirculationDate",
  FractionDisposal = "FractionDisposal",
}

export interface ExtraFieldInfo {
  type: ExtraField;
  renderField(props: RenderProps): ReactNode;
  header: string;
  colWidthTimeline: string;
  colWidthSidePage: string;
  sidePage: boolean;
}

export const extraFields: Record<ExtraField, ExtraFieldInfo> = {
  [ExtraField.CirculationDate]: {
    type: ExtraField.CirculationDate,
    colWidthTimeline: "1.5fr",
    colWidthSidePage: "1.5fr",
    header: "Дата ввода в оборот",
    sidePage: false,
    renderField: (props: CirculationDateProps) => <CirculationDate {...props} />,
  },
  [ExtraField.FractionDisposal]: {
    type: ExtraField.FractionDisposal,
    colWidthTimeline: "1.7fr",
    colWidthSidePage: "3fr",
    header: "Вывод доли",
    sidePage: true,
    renderField: (props: FractionDisposalProps) => <FractionDisposal {...props} />,
  },
};
