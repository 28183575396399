import React from "react";
import { Button, Modal } from "components";
import { ButtonWrapper } from "./RollbackModal.styles";

interface RollbackModalProps {
  headerText: string;
  bodyText: string;
  isLoading?: boolean;
  onRollback(): void;
  onClose(): void;
}

export function RollbackModal({ headerText, bodyText, isLoading, onRollback, onClose }: RollbackModalProps) {
  return (
    <Modal data-tid="RollbackModal" ignoreBackgroundClick onClose={onClose} width="500px">
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <ButtonWrapper>
          <Button data-tid="Rollback" loading={isLoading} use={"primary"} onClick={onRollback}>
            Вернуться
          </Button>
        </ButtonWrapper>
        <Button data-tid="Cancel" onClick={onClose}>
          Отменить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
