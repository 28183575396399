import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { ArrowTextWrap, EmployeeName, ItemWrap, MainName } from "./RegistratorsPage.styles";
import { SettingsPageVM } from "../SettingsPageVM";
import ArrowDownIcon from "@skbkontur/react-icons/ArrowChevronDown";
import ArrowRightIcon from "@skbkontur/react-icons/ArrowChevronRight";
import { TrashIconWrap } from "../EmployeesPage/Employees.styles";
import TrashIcon from "@skbkontur/react-icons/Trash";
import { RegistratorSettingsModel } from "typings/server";

interface RegistratorItemProps {
  vm: SettingsPageVM;
  item: RegistratorSettingsModel;
  cols: string;
  index: number;
  departmentName?: string;
}

export const RegistratorItem = observer(({ cols, index, item, vm, departmentName }: RegistratorItemProps) => {
  const employeesMapped = item.employeesWithAccess.map(x => <EmployeeName key={x.id}>{x.fio}</EmployeeName>);

  const onDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    vm.setSelectedRegistrator(item);
    vm.toggleModal("deleteRegistrator");
  };

  const onOpen = () => {
    vm.setSelectedRegistrator(item);
    vm.toggleModal("registratorEditor");
  };

  const onToggleRegistratorEmployees = (e: SyntheticEvent) => {
    e.stopPropagation();
    vm.toggleRegistratorEmployees(item.id);
  };

  return (
    <ItemWrap data-tid="RegistratorItem" onClick={onOpen}>
      <Grid cols={cols}>
        <GridCell col={1} row={index}>
          <MainName data-tid="Name">{item.name}</MainName>
          {item.name !== item.model && <div data-tid="Model">{item.model}</div>}
        </GridCell>
        <GridCell data-tid="DepartmentName" col={2} row={index}>
          {departmentName}
        </GridCell>
        <GridCell col={3} row={index}>
          {item.employeesWithAccess.length > 2 ? (
            <>
              <ArrowTextWrap onClick={onToggleRegistratorEmployees}>
                {vm.showRegistratorEmployeesMap.get(item.id) ? <ArrowDownIcon /> : <ArrowRightIcon />}&nbsp;
                {item.employeesWithAccess.length} сотрудников
              </ArrowTextWrap>
              {vm.showRegistratorEmployeesMap.get(item.id) && employeesMapped}
            </>
          ) : (
            employeesMapped
          )}
        </GridCell>
        <GridCell col={4} row={index}>
          <TrashIconWrap data-tid="Delete" onClick={onDelete}>
            <TrashIcon />
          </TrashIconWrap>
        </GridCell>
      </Grid>
    </ItemWrap>
  );
});
