import React, { FC } from "react";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { destructionInstruction } from "StringResx";
import { OperationsList } from "./OperationsList/OperationsList";
import AddIcon from "@skbkontur/react-icons/Add";
import { List } from "./MainPage.styles";
import { CirculationRoute, CirculationSubRoute } from "routes/CirculationRoute";
import { DeliveryDropdownWrap, DeliveryTopBlock } from "Common/Styles/MainPage.styles";
import { Button, Link, Loader } from "components";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { IMainPageProps } from "./MainPage";
import { observer } from "mobx-react";
import { DestructionEditorModal } from "../Editor/DestructionEditorModal";

const DELIVERY_TYPES = [DeliveryType.Destruction];

export const Destruction: FC<IMainPageProps> = observer(({ vm, history }) => {
  const goToDetailsPage = (doc: DeliveryIndexModel) => {
    vm.setIsPreserveFilters(true);
    if (doc.id) {
      history.push(CirculationRoute.getHref(doc.id, CirculationSubRoute.destruction));
    }
  };

  const toggleNewSendToDestructionModal = () => vm.toggleModal("newSendToDestruction");

  if (vm.loadingState.get("start")) {
    return <Loader active />;
  }

  return (
    <>
      {vm.modalState.get("newSendToDestruction") && (
        <DestructionEditorModal onClose={toggleNewSendToDestructionModal} />
      )}
      <DeliveryTopBlock>
        <DeliveryDropdownWrap>
          <Button
            data-tid="CreateNewOperation"
            onClick={toggleNewSendToDestructionModal}
            size="medium"
            icon={<AddIcon />}
          >
            Добавить передачу на уничтожение
          </Button>
          <Link data-tid="InfoLink" href={destructionInstruction} icon={<HelpDotIcon />} target="_blank">
            Передача на уничтожение и уничтожение
          </Link>
        </DeliveryDropdownWrap>
      </DeliveryTopBlock>
      <List>
        <OperationsList
          data-tid="OperationsList"
          listType={DeliveryType.Destruction}
          operations={vm.deliveries}
          onClick={goToDetailsPage}
          vm={vm}
          types={DELIVERY_TYPES}
        />
      </List>
    </>
  );
});
