import styled from "styles/styled-components";

export const AcceptanceModalText = styled.div`
  font-size: 16px;
  line-height: 25px;
  max-width: 500px;
`;

export const TrustedText = styled.div`
  display: flex;
`;

export const AcceptedIconWrap = styled.div`
  display: inline-block;
  margin-right: 14px;
  padding-top: 1px;
`;

export const RecallIconWrap = styled.div`
  margin-right: 9px;
  padding-top: 2px;
  display: inline-block;
`;

export const RecalledText = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 6px;
`;

export const Msg = styled.p`
  margin-top: 0;
  margin-bottom: 6px;
`;
