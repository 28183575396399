import styled from "styles/styled-components";

export const Item = styled.div`
  display: block;
  max-width: 500px;
  padding-bottom: 13px;

  &:last-child {
    padding-bottom: 0;
  }
`;
