import React, { useState, useEffect } from "react";
import { Error } from "@skbkontur/react-icons";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { AcceptedStatusIcon } from "icons";
import { DeliveryItemStatus } from "typings/server";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import {
  ItemIconWrap,
  ScannedIcon,
  GridItemWrap,
  TableItemHr,
  NameText,
  ItemName,
  AdditionalInfoWrap,
} from "../GoodsTable/GoodsTable.styles";
import { CollapseIcon, ItemTextWrap } from "./CodeItem.styles";
import ArrowChevronDownIcon from "@skbkontur/react-icons/ArrowChevronDown";
import ArrowChevronRightIcon from "@skbkontur/react-icons/ArrowChevronRight";
import { AdditionalInfoVM } from "../../AdditionalInfo/AdditionalInfoVM";
import { AdditionalInfoSgtinTable } from "../../AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "../../AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { observer } from "mobx-react";

interface CodeItemProps {
  item: DeliveryItemModelExtended;
  index: number;
  cols: string;
  vm: AdditionalInfoVM;
  allCodesExpanded: boolean;
}

export const CodeItem: React.FC<CodeItemProps> = observer(
  ({ item, index, cols, vm, allCodesExpanded }: CodeItemProps) => {
    const [isExpanded, setIsExpanded] = useState(!allCodesExpanded);

    const toggleOpenedState = () => {
      setIsExpanded(v => !v);
    };

    useEffect(() => {
      setIsExpanded(allCodesExpanded);
    }, [allCodesExpanded]);

    const hasAdditionalInfo = vm.checkIfGoodHasAdditionalInfo(item);

    const isSgtin = Boolean(item.gtin);
    return (
      <>
        <GridItemWrap collapsible={hasAdditionalInfo}>
          <ItemIconWrap top="7px" left="-13px">
            {item.status === DeliveryItemStatus.Accepted && (
              <ScannedIcon top={"0"}>
                <AcceptedStatusIcon />
              </ScannedIcon>
            )}
            {item.status === DeliveryItemStatus.Error && (
              <ScannedIcon color={"red"} top={"0"} left={"-3px"}>
                <Error />
              </ScannedIcon>
            )}
          </ItemIconWrap>
          <Grid cols={cols} onClick={toggleOpenedState} paddingCells="8px 5px">
            <GridCell col={1} row={2 + index}>
              <ItemName>
                {hasAdditionalInfo && (
                  <CollapseIcon>{isExpanded ? <ArrowChevronDownIcon /> : <ArrowChevronRightIcon />}</CollapseIcon>
                )}
                <NameText isAnyAdditionalInfo={vm.isAnyCodeHasAddInfo && !hasAdditionalInfo}>
                  {isSgtin ? "Штучный товар" : "Агрегат"}
                </NameText>
              </ItemName>
            </GridCell>
            <GridCell col={2} row={2 + index}>
              {item.name}
            </GridCell>
            <GridCell col={3} row={2 + index} textAlign={"right"}>
              <ItemTextWrap status={item.status}>{item.allCodes && item.allCodes.length}</ItemTextWrap>
            </GridCell>
          </Grid>
        </GridItemWrap>
        {isExpanded && hasAdditionalInfo && (
          <AdditionalInfoWrap>
            <AdditionalInfoSgtinTable vm={vm} codes={item.sgtinCodes} />
            <AdditionalInfoSsccTable vm={vm} codes={item.ssccCodesFlat} />
            <TableItemHr />
          </AdditionalInfoWrap>
        )}
      </>
    );
  }
);
