import React from "react";
import { ScannerSidePageContainer } from "features/ScannerSidePage/ScannerSidePageContainer";
import { SignatureStore } from "stores/SignatureStore";
import { FreeScanSidePageBody } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageBody";
import { FreeScanSidePageVM } from "features/ScannerSidePage/SidePages/FreeScan/FreeScanSidePageVM";
import { WithdrawalDeliveryModel } from "models/Delivery/WithdrawalDeliveryModel";
import { WithdrawalStore } from "stores/WithdrawalStore";
import { withdrawalValidations } from "./WithdrawalValidations";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { DeliveryType } from "typings/server";
import { AdvertisementStore } from "stores/AdvertisementStore";

interface ISidePageProps {
  close(): void;
  save(): Promise<void>;
  delivery: WithdrawalDeliveryModel;
  additionalInfo: CodesAdditionalInfo;
}

// React.memo + () => true - не обновляет компонент
export const WithdrawalSidePage: React.FC<ISidePageProps> = React.memo(
  (props: ISidePageProps) => {
    const { delivery, additionalInfo, save } = props;

    const customValidations = (code: string) => withdrawalValidations(delivery, code);

    const vm = new FreeScanSidePageVM(
      WithdrawalStore,
      SignatureStore,
      AdvertisementStore,
      additionalInfo,
      delivery,
      save,
      customValidations
    );
    return (
      <ScannerSidePageContainer
        scanText={
          delivery.type === DeliveryType.Reentry
            ? "Отсканируйте коды маркировки для повторного ввода в оборот"
            : "Отсканируйте коды маркировки для вывода из оборота"
        }
        headerText={"Сканирование кодов маркировки товаров"}
        onlyScanner={delivery.type === DeliveryType.DisposalWithRegistrator}
        vm={vm}
        {...props}
      >
        <FreeScanSidePageBody vm={vm} />
      </ScannerSidePageContainer>
    );
  },
  () => true
);
