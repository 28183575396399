import { action, computed, observable } from "mobx";
import { ISignatureStore } from "stores/SignatureStore";
import { CreateRecallModel, DeliveryStatus, DeliveryType, StepInfoModel } from "typings/server";
import { ICertificate } from "@skbkontur/plugin-js";
import { DeliveryActions, DetailPageLoadingName, DetailPageModalNames } from "Common/DeliveryActions";
import { IStage } from "models/Delivery/shared";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { ProgressStage } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { ITransferStore } from "stores/TransferStore";
import { ReportsApi } from "api/ReportsApi";
import { IUserStore } from "stores/UserStore";
import { CodesAdditionalInfo } from "models/Code/CodeAdditionalInfo";
import { TransferDeliveryModel } from "models/Delivery/TransferDeliveryModel";

export class TransferPageVM {
  @observable isDeliveryOpenedState: Map<string, boolean> = new Map();
  @observable submitted: boolean = false;
  @observable additionalInfo: CodesAdditionalInfo;
  private actions: DeliveryActions;

  constructor(
    private readonly transferStore: ITransferStore,
    documentStore: ISignatureStore,
    readonly userStore: IUserStore,
    type: DeliveryType
  ) {
    if (this.activeDelivery?.isActive) {
      this.isDeliveryOpenedState.set(this.activeDelivery.id, true);
    }
    this.actions = new DeliveryActions(transferStore, documentStore, userStore, type);

    if (this.transferStore.selectedDelivery.status === DeliveryStatus.CreatingDocument) {
      this.actions.startPolling(this.delivery.id);
    }

    this.additionalInfo = new CodesAdditionalInfo(this.delivery.id, this.delivery.allItemsCodes);
  }

  toggleModal(modalName: DetailPageModalNames) {
    return this.actions.toggleModal(modalName);
  }

  toggleLoading(loadingName: DetailPageLoadingName) {
    return this.actions.toggleLoading(loadingName);
  }

  @action
  onCollapseDelivery(id: string, val?: boolean) {
    const isOpened = val !== undefined ? val : !this.isDeliveryOpenedState.get(id);
    this.isDeliveryOpenedState.set(id, isOpened);
  }

  @action
  save = async () => {
    this.toggleLoading("creatingDocument");
    this.actions.sendReportDateMetrics(this.delivery.reportDate);
    await this.actions.save(this.delivery);
  };

  @action
  saveItems = async () => {
    return await this.transferStore.saveItems(this.delivery.items, this.delivery.id);
  };

  @action
  sign = async (cert: ICertificate) => {
    const { status, childDeliveries } = this.delivery;
    const deliveriesToSign: TransferDeliveryModel[] = [];
    if (status === DeliveryStatus.Signing || status === DeliveryStatus.Signed) {
      deliveriesToSign.push(this.delivery);
    }
    if (childDeliveries) {
      childDeliveries.forEach(d => {
        if (d.status === DeliveryStatus.Signing || d.status === DeliveryStatus.Signed) {
          deliveriesToSign.push(d);
        }
      });
    }
    await this.actions.sign(cert, deliveriesToSign);
  };

  @action
  async onRecall(model: CreateRecallModel) {
    this.delivery.setRecalled(true);
    try {
      await ReportsApi.recall(model);
      this.actions.startPolling(this.delivery.id);
      this.delivery.setNextStage(
        DeliveryStatus.CreatingDocument,
        DeliveryStage.Processing,
        {
          completionDate: new Date().toISOString(),
        },
        this.userStore.fullName
      );
    } catch (e) {
      this.delivery.setRecalled(false);
      throw e;
    }
  }

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.transferStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.transferStore.getItem(this.delivery.id, { force: true });
    this.transferStore.setSelected(item);
    this.toggleLoading("rollback");
  }

  @action async deleteDelivery() {
    this.toggleLoading("delete");
    await this.transferStore.deleteDelivery(this.delivery.id);
    this.toggleLoading("delete");
  }

  prepareDocuments = async () => {
    this.toggleLoading("download");
    try {
      const taskId = await this.transferStore.prepareDocuments(this.delivery.id);
      this.actions.startPolling(taskId, "download");
    } catch (e) {
      this.toggleLoading("download");
      throw e;
    }
  };

  handleUnmount() {
    return this.actions.handleUnmount();
  }

  @computed
  get stages(): Record<DeliveryStage | string, IStage> {
    return this.delivery.stages;
  }

  // поставка в которой надо совершить действие
  @computed
  get activeDelivery() {
    return this.delivery.childDeliveries.find(child => child.isActive) || this.delivery;
  }

  @computed
  get recallStages(): Record<DeliveryStage | string, IStage> {
    return this.delivery.recallStages;
  }

  @computed
  get delivery() {
    return this.transferStore.selectedDelivery;
  }

  @computed
  get hasDocuments() {
    return this.delivery.hasDocuments;
  }

  @computed
  get modalMap() {
    return this.actions.modalMap;
  }

  @computed
  get loadingMap() {
    return this.actions.loadingMap;
  }

  @computed
  get showSignBtn(): boolean {
    const { status } = this.transferStore.selectedDelivery;
    return status === DeliveryStatus.Signing || status === DeliveryStatus.Signed;
  }

  @computed
  get showSaveBtn(): boolean {
    const { status } = this.transferStore.selectedDelivery;
    return status === DeliveryStatus.Processing || status === DeliveryStatus.New;
  }

  @computed
  get showRecallBtn(): boolean {
    const { status } = this.transferStore.selectedDelivery;
    return this.isRecallBtnLoading || (status === DeliveryStatus.Success && !this.delivery.isRecalled);
  }

  @computed
  get isRecallBtnLoading() {
    const { status, isRecalled } = this.delivery;
    return status === DeliveryStatus.CreatingDocument && isRecalled;
  }

  @computed
  get showRollbackBtn(): boolean {
    const { status } = this.transferStore.selectedDelivery;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.Signing;
  }

  @computed
  get rollbackHintText(): string {
    const { status } = this.transferStore.selectedDelivery;
    return status === DeliveryStatus.Signing
      ? "Для редактирования перемещения"
      : "Для редактирования и повторного перемещения";
  }

  @computed
  get shouldOpenRollbackModal(): boolean {
    const { status } = this.transferStore.selectedDelivery;
    return status === DeliveryStatus.Failed;
  }

  @computed
  get showDeleteBtn(): boolean {
    const {
      selectedDelivery: { status },
      isCanBeDeleted,
    } = this.transferStore;
    return isCanBeDeleted(status) || status === DeliveryStatus.Failed;
  }

  @computed
  get isFailedStatus() {
    const { status } = this.transferStore.selectedDelivery;
    return (
      status === DeliveryStatus.Failed ||
      status === DeliveryStatus.PartiallyFailed ||
      status === DeliveryStatus.RecallFailed
    );
  }

  @computed
  get showDoneStage() {
    return !this.delivery.isRecalled && !this.isFailedStatus;
  }

  @computed
  get showFooter(): boolean {
    return this.showSignBtn || this.showSaveBtn || this.showRecallBtn || this.showRollbackBtn || this.showDeleteBtn;
  }

  @computed
  get showEditor(): boolean {
    return (
      this.delivery.status === DeliveryStatus.New ||
      this.delivery.status === DeliveryStatus.Processing ||
      this.delivery.status === DeliveryStatus.Draft
    );
  }

  @computed
  get isEditable(): boolean {
    return this.delivery.status === DeliveryStatus.New || this.delivery.status === DeliveryStatus.Processing;
  }

  @computed
  get supplier() {
    return this.delivery.supplier;
  }

  @computed
  get recipient() {
    return this.delivery.counteragentDepartment || this.delivery.recipient;
  }

  @computed
  get isShowCirculationWarning(): boolean {
    const { parentDeliveryId, stepsInfo, items } = this.delivery;
    const { isFailedCodesAppliedByNewRules, isAnyCodeAppliedByNewRules } = this.additionalInfo;

    const singnedStepDate = stepsInfo[DeliveryStatus.Signed]?.completionDate;
    const mdlpStepDate = stepsInfo[DeliveryStatus.MdlpProcessing]?.completionDate;

    const waitingTime = 15 * 60 * 1000;

    return (
      !parentDeliveryId &&
      (this.isFailedStatus || !mdlpStepDate) &&
      Date.now() > +new Date(singnedStepDate) + waitingTime &&
      (this.isFailedStatus ? isFailedCodesAppliedByNewRules(items) : isAnyCodeAppliedByNewRules())
    );
  }

  @action
  addChildDelivery() {
    const newDeliveryId = "new";
    this.delivery.addChildDelivery(newDeliveryId);
    this.isDeliveryOpenedState.set("new", true);
  }

  getCompletionUser(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionUser(stepInfo);
  }

  getCompletionDate(stepInfo: StepInfoModel): string {
    return DeliveryActions.getCompletionDate(stepInfo);
  }

  getTitleSize(stage: IStage) {
    return this.actions.getTitleSize(this.delivery.stages, stage);
  }

  getSendingStepName(stage: IStage): string {
    return stage.progress === ProgressStage.Planned ? "Отправка в ИС МДЛП" : "Отправлено в ИС МДЛП";
  }

  @computed
  get recallSendingDate() {
    const recallChild = this.delivery.childDeliveries.find(x => x.status === DeliveryStatus.Recalled);
    const sendingStep = recallChild?.stepsInfo[DeliveryStatus.Sending];
    return this.getCompletionDate(sendingStep!);
  }
}
