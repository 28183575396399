import React, { PropsWithChildren, useMemo } from "react";
import {
  ThemeContext as ReactUiThemeContext,
  Button,
  ButtonProps,
  FLAT_THEME,
  ThemeFactory,
} from "@skbkontur/react-ui";
import { theme } from "styles/theme";

export declare type ButtonSize = "small" | "medium" | "large";

export interface IButtonProps extends Omit<ButtonProps, "size"> {
  size?: ButtonSize;
  theme?: Partial<typeof FLAT_THEME>;
}

export const ThemedButton = React.memo((props: PropsWithChildren<IButtonProps>) => {
  const updatedTheme = useMemo(() => {
    const customPart = props.theme || {};
    return ThemeFactory.create(
      {
        btnDefaultBgEnd: theme.colors.white,
        btnDefaultBgStart: theme.colors.white,
        btnDefaultHoverBg: theme.colors.bgHoverGreyLight,
        btnDefaultHoverBorderColor: theme.colors.borderColorHover,
        btnDisabledBg: theme.colors.greyLight,
        bgDisabled: theme.colors.greyLight,
        btnDisabledBorderColor: theme.colors.bgColorActive,
        fontSizeMedium: theme.font.defaultSize,
        fontSizeSmall: theme.font.defaultSize,
        btnFontSizeMedium: theme.font.defaultSize,
        btnFontSizeLarge: theme.font.mediumSize,
        btnPrimaryBg: theme.colors.bluePrimary,
        btnPrimaryHoverBg: theme.colors.blueMenuItem,
        controlPaddingYLarge: theme.btn.paddingTopM,
        linkColor: theme.colors.darkBlue,
        linkHoverColor: theme.colors.darkBlue,
        btnLinkIconMarginRight: "20px",
        ...customPart,
      },
      FLAT_THEME
    );
  }, [props.theme]);

  return (
    <ReactUiThemeContext.Provider value={updatedTheme}>
      <Button {...props} size={props.size || "medium"}>
        {props.children}
      </Button>
    </ReactUiThemeContext.Provider>
  );
});
