import styled from "styles/styled-components";
import { theme } from "styles/theme";

export const DeleteBtnWrap = styled.span`
  margin-left: 15px;
`;

export const RejectBtnWrap = styled.span`
  margin-right: 10px;
`;

export const deleteBtnTheme = {
  btnLinkColor: theme.textColors.secondaryText,
  btnLinkHoverColor: theme.textColors.red,
  btnLinkHoverTextDecoration: "none",
};
