import styled from "styles/styled-components";

export const TopText = styled.p`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  margin-bottom: 16px;
`;

export const BottomText = styled.div`
  line-height: 22px;
  margin-top: 3px;
`;

export const BtnWrap = styled.div`
  margin-top: 5px;
`;

export const FooterWrap = styled.div`
  margin-top: 35px;
`;

export const RequisiteLabel = styled.div`
  margin-top: 7px;

  &:after {
    content: "*";
    display: inline-block;
    color: ${props => props.theme.textColors.red};
    position: relative;
    top: -3px;
    right: -1px;
  }
`;

export const IconWrap = styled.span`
  cursor: pointer;
`;
