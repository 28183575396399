import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { getHttpClient } from "./shared";
import { RequisitesModel } from "typings/server";

class RequisitesApiInternal {
  constructor(private httpClient: IHttpClient) {}

  get(): Promise<RequisitesModel> {
    return this.httpClient.get("");
  }

  save(model: Partial<RequisitesModel>): Promise<void> {
    return this.httpClient.post("", model);
  }
}

export const RequisitesApi = new RequisitesApiInternal(getHttpClient(drugsApi + "/requisites"));
