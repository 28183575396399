import {
  DeliveryType,
  WithdrawalReason,
  WithdrawalCause,
  ReturnReason,
  DestructionCause,
  DestructionReason,
  DestructionMethod,
} from "typings/server";
import { getArrayFromDictionary, IType } from "typings/dictionaries";

export const WithdrawalReasonNames: Record<WithdrawalReason, string> = {
  [WithdrawalReason.AfterExperiment]: "Вывод из оборота SSCC/SGTIN, накопленных в рамках эксперимента",
  [WithdrawalReason.Shortage]: "Недостача",
  [WithdrawalReason.Archive]: "Отбор архивных образцов",
  [WithdrawalReason.QualityAudit]: "Отбор контрольных образцов",
  [WithdrawalReason.SelectiveAudit]: "Отбор образцов в рамках выборочного контроля",
  [WithdrawalReason.FederalSupervision]: "Отбор образцов в рамках федерального надзора",
  [WithdrawalReason.ClinicalResearch]: "Отбор образцов для проведения клинических исследований",
  [WithdrawalReason.PharmaceuticalExamination]: "Отбор образцов для фармацевтической экспертизы",
  [WithdrawalReason.CustomAudit]: "Отбор образцов таможенными органами",
  [WithdrawalReason.SpecimensExtraction]: "Передача демонстрационных образцов",
  [WithdrawalReason.MedicalGoodsProduction]: "Производство медицинских изделий",
  [WithdrawalReason.DrugsProduction]: "Производство лекарственных препаратов",
  [WithdrawalReason.WriteOff]: "Списание без передачи на уничтожение",
  [WithdrawalReason.Spoilage]: "Списание производственного брака",
  [WithdrawalReason.IncompleteConsumerPackage]: "Списание разукомплектованной вторичной упаковки",
  [WithdrawalReason.Theft]: "Хищение",
};

export const WithdrawalCauseInReentryNames: Record<WithdrawalCause, string> = {
  [WithdrawalCause.SelectiveAudit]: "Выборочный контроль",
  [WithdrawalCause.HealthCare]: "Выдача для оказания медицинской помощи",
  [WithdrawalCause.ClinicalResearch]: "Клинические исследования",  
  [WithdrawalCause.SpecimensExtraction]: "Отбор образцов",
  [WithdrawalCause.UnregisteredCounteragentShipment]: "Отгрузка незарегистрированному участнику",
  [WithdrawalCause.EeuShipment]: "Отгрузка в ЕАЭС",
  [WithdrawalCause.Receipt]: "Отпуск по льготному рецепту",
  [WithdrawalCause.Reexport]: "Реэкспорт",
  [WithdrawalCause.Retail]: "Розничная продажа",
  [WithdrawalCause.WriteOff]: "Списание",
  [WithdrawalCause.Export]: "Экспорт",
};

export const ReturnReasonNames: Record<ReturnReason, string> = {
  [ReturnReason.GoodQuality]: "Возврат надлежащего качества",
  [ReturnReason.PoorQuality]: "Возврат ненадлежащего качества",
};

export const DestructionCausesNames: Record<DestructionCause, string> = {
  [DestructionCause.OwnerDecision]: "По решению владельца",
  [DestructionCause.RznDecision]: "По решению Росздравнадзора",
  [DestructionCause.CourtDecision]: "По решению суда",
};

export const DestructionReasonsNames: Record<DestructionReason, string> = {
  [DestructionReason.RegistryDeletion]: "Исключен из Государственного реестра лекарственных средств",
  [DestructionReason.OutOfDate]: "Истек срок годности",
  [DestructionReason.Illegal]: "Контрактрафактный товар",
  [DestructionReason.Bad]: "Недоброкачественный товар",
  [DestructionReason.ProducerRecall]: "Отзыв производителем/держателем",
  [DestructionReason.Spoiled]: "Утрата потребительских свойств",
  [DestructionReason.Fake]: "Фальсифицированный товар",
};

export const DestructionMethodsNames: Record<DestructionMethod, string> = {
  [DestructionMethod.Fragmentation]: "Дробление, разведение и слив в промышленную канализацию",
  [DestructionMethod.Smashing]: "Раздавливание ампулы, разведением содержимого и слив в промышленную канализацию",
  [DestructionMethod.Burning]: "Сжигание",
};

export const WithdrawalCauseInReentryNamesArr = getArrayFromDictionary(WithdrawalCauseInReentryNames);

export const DestructionCausesNamesArr = getArrayFromDictionary(DestructionCausesNames);

export const DestructionReasonsNamesArr = getArrayFromDictionary(DestructionReasonsNames);

export const DestructionMethodsNamesArr = getArrayFromDictionary(DestructionMethodsNames);

export const ReturnReasonNamesArr = getArrayFromDictionary(ReturnReasonNames);

export const WithdrawalReasonNamesArr: IType[] = getArrayFromDictionary(WithdrawalReasonNames);

export const WithdrawalTypesNames = {
  [DeliveryType.Withdrawal]: "По различным причинам",
  [DeliveryType.Disposal]: "При неработоспособности регистратора выбытия",
  [DeliveryType.DisposalWithRegistrator]: "Через регистратор выбытия",
};

export enum DisposalReason {
  MedicalAssistance = "MedicalAssistance",
}

export const DisposalReasonNames: Record<DisposalReason, string> = {
  [DisposalReason.MedicalAssistance]: "Для оказания медицинской помощи",
};
