import React, { useState, useEffect } from "react";
import { Grid, GridCell } from "components/Grid/Grid.styles";
import { CheckIconGreen, WarningIconOrange, WaitingIconGray, ErrorIconRed } from "icons";
import { DeliveryItemModelExtended } from "models/Delivery/ItemModel/DeliveryItemModelExtended";
import {
  ScannedIcon,
  GridItemWrap,
  NameText,
  ItemName,
  AdditionalInfoWrap,
} from "Common/GoodsTable/GoodsTable/GoodsTable.styles";
import {
  CollapseIcon,
  ItemTextWrap,
  CodeStatusRaw,
  CodeStatusText,
  ItemWrapper,
  ItemPosName,
  TooltipRoot,
} from "./CodesTable.styles";
import ArrowChevronDownIcon from "@skbkontur/react-icons/ArrowChevronDown";
import ArrowChevronRightIcon from "@skbkontur/react-icons/ArrowChevronRight";
import { AdditionalInfoVM, StatusIconType } from "Common/AdditionalInfo/AdditionalInfoVM";
import { AdditionalInfoSgtinTable } from "Common/AdditionalInfo/AdditionalInfoSgtin/AdditionalInfoSgtinTable";
import { AdditionalInfoSsccTable } from "Common/AdditionalInfo/AdditionalInfoSscc/AdditionalInfoSsccTable";
import { observer } from "mobx-react";
import { CodeModelExtended, ICode } from "models/Code/CodeModelExtended";
import { Link, Tooltip } from "components";
import { MdlpWithdrawalCodeStatuses } from "features/ScannerSidePage/AdditionalInfo/AdditionalInfoVM";

interface CodeItemProps {
  item: DeliveryItemModelExtended;
  index: number;
  cols: string;
  vm: AdditionalInfoVM;
  allCodesExpanded: boolean;
}

export const CodeItem: React.FC<CodeItemProps> = observer(
  ({ item, index, cols, vm, allCodesExpanded }: CodeItemProps) => {
    const [isExpanded, setIsExpanded] = useState(!allCodesExpanded);
    const hasAdditionalInfo = vm.checkIfGoodHasAdditionalInfo(item);

    const toggleOpenedState = () => {
      setIsExpanded(v => !v);
    };

    useEffect(() => {
      setIsExpanded(allCodesExpanded);
    }, [allCodesExpanded]);

    const statusIcon = (icon: StatusIconType) => {
      switch (icon) {
        case "all":
          return (
            <ScannedIcon top={"0"} left={"-3px"}>
              <CheckIconGreen width="11px" height="11px" />
            </ScannedIcon>
          );
        case "none":
        case "withDeviation":
          return (
            <ScannedIcon top={"0"} left={"-3px"}>
              <WarningIconOrange />
            </ScannedIcon>
          );
        case "waiting":
          return (
            <ScannedIcon top={"0"} left={"-3px"}>
              <WaitingIconGray />
            </ScannedIcon>
          );
        case "error":
          return (
            <ScannedIcon top={"0"} left={"-3px"}>
              <ErrorIconRed />
            </ScannedIcon>
          );
        default:
          return "";
      }
    };

    const codeStatus = (codes: CodeModelExtended[], isSingleCode?: boolean) => {
      const codeStatus = vm.getCodeStatus((codes[0] as ICode)?.codeSyncStatus);
      const renderTooltip = () => {
        const status = codes[0].codeMdlpStatus as keyof typeof MdlpWithdrawalCodeStatuses;
        if (isSingleCode && MdlpWithdrawalCodeStatuses[status]) {
          return (
            <TooltipRoot>
              Текущий статус кода маркировки:
              <br />
              {MdlpWithdrawalCodeStatuses[status]}
            </TooltipRoot>
          );
        }
        if (codeStatus?.icon === "waiting") {
          return (
            <>
              Коды маркировки приняты в ИС МДЛП.
              <br />
              Но при выводе из оборота этих кодов
              <br />
              зафиксировано отклонение от требований.
              <br />
              Проверьте коды в личном кабинете
              <br />
              ИС МДЛП в{" "}
              <Link href="https://mdlp.crpt.ru/#/app/goods/awaiting_withdrawal_by_dr" target="_blank">
                «Реестре КИЗ, ожидающих
                <br />
                вывода из оборота (РВ или документ)»
              </Link>
            </>
          );
        }
        if (codeStatus?.icon === "error") {
          return (
            <>
              Коды маркировки вернулись с ошибкой.
              <br />
              Исправьте ошибки и добавьте новый
              <br />
              вывод из оборота.
            </>
          );
        }
      };

      return (
        <Tooltip trigger="hover" render={renderTooltip} pos="top center">
          <CodeStatusRaw>
            <ScannedIcon color="red" top="1px" left="-3px">
              {statusIcon(codeStatus?.icon)}
            </ScannedIcon>
            <CodeStatusText>{codeStatus?.status || "-"}</CodeStatusText>
          </CodeStatusRaw>
        </Tooltip>
      );
    };

    const isSgtin = Boolean(item.gtin);

    return (
      <ItemWrapper>
        <GridItemWrap collapsible={hasAdditionalInfo}>
          <Grid cols={cols}>
            <GridCell col={1} row={2 + index} onClick={toggleOpenedState}>
              <ItemName>
                {hasAdditionalInfo && (
                  <CollapseIcon>{isExpanded ? <ArrowChevronDownIcon /> : <ArrowChevronRightIcon />}</CollapseIcon>
                )}
                <NameText isAnyAdditionalInfo={vm.isAnyCodeHasAddInfo && !hasAdditionalInfo}>
                  {isSgtin ? "Штучный товар" : "Агрегат"}
                </NameText>
              </ItemName>
            </GridCell>
            <GridCell col={2} row={2 + index} onClick={toggleOpenedState}>
              <ItemPosName title={item.name}>{item.name}</ItemPosName>
            </GridCell>
            <GridCell
              col={3}
              row={2 + index}
              textAlign="right"
              borderRight
              padding="8px 16px 4px 4px"
              onClick={toggleOpenedState}
            >
              <ItemTextWrap status={item.status}>{item.allCodes && item.allCodes.length}</ItemTextWrap>
            </GridCell>
            {!(isExpanded && hasAdditionalInfo) && (
              <GridCell col={4} row={2 + index} textAlign="right">
                {codeStatus(item.allCodes)}
              </GridCell>
            )}
          </Grid>
        </GridItemWrap>
        {isExpanded && hasAdditionalInfo && (
          <Grid cols={cols}>
            <GridCell colStart={1} colEnd={4} row={3 + index} textAlign="left" borderRight>
              <AdditionalInfoWrap>
                <AdditionalInfoSgtinTable vm={vm} codes={item.sgtinCodes} />
                <AdditionalInfoSsccTable vm={vm} codes={item.ssccCodesFlat} />
              </AdditionalInfoWrap>
            </GridCell>
            {/* Статусы спозиционированы абсолютом, это грустно, но иначе интегрировать их в табличку допинфы - просто боль...  */}
            <GridCell
              colStart={4}
              colEnd={5}
              row={3 + index}
              textAlign="right"
              alignSelf="end"
              padding="8px 4px 0px 0px"
            >
              {item.sgtinCodes.map((code, ind) => (
                <div key={code.code} style={{ position: "absolute", top: `${120 + ind * 38}px`, paddingLeft: "4px" }}>
                  {codeStatus([code], true)}
                </div>
              ))}
              {item.ssccCodes.map((code, ind) => {
                const top = !!item.sgtinCodes.length ? `${195 + item.sgtinCodes.length * 38}px` : `${120 + ind * 50}px`;
                return (
                  <div key={code.code} style={{ position: "absolute", top, paddingLeft: "4px" }}>
                    {codeStatus([code], true)}
                  </div>
                );
              })}
            </GridCell>
          </Grid>
        )}
      </ItemWrapper>
    );
  }
);
