import React, { useEffect, useRef, KeyboardEvent } from "react";
import { observer } from "mobx-react";
import { Container, HelpIconWrap, InfoText, InputBlock, InputWrap, TooltipWrap } from "./ScannerInput.styles";
import { Button, Input, Tooltip } from "components";
import { isEnterEvent } from "helpers/input";
import { getSgtinOrSsccFromString } from "helpers/codes";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { IErrorObj, IScannerSidePageVM } from "../shared";
import { YMetrika } from "YMetrika";
import { InputHeader } from "./InputHeaderBlock";
import { hasRuChars } from "helpers/lang";

export interface IScannerInputProps {
  vm: IScannerSidePageVM;
  placeholderText?: string;
  hideSgtinImg?: boolean;
}

export const ScannerInput: React.FC<IScannerInputProps> = observer((props: IScannerInputProps) => {
  const { vm, placeholderText, hideSgtinImg } = props;
  const tooltipEl = useRef<Tooltip>(null);

  useEffect(() => {
    if (tooltipEl && (vm.errors.size || (vm.warns && vm.warns.size))) tooltipEl.current!.show();
  });

  const onChangeInput = (val: string) => {
    vm.onInput(val.trim());

    if (hasRuChars(val)) {
      vm.errors.set("lang", { title: "Код маркировки не должен содержать русские буквы" } as IErrorObj);
      vm.showError = true;
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.getModifierState("CapsLock")) {
      vm.warns?.set("caps", "Проверьте регистр, включен Caps Lock");
      vm.showWarn = true;
    } else {
      vm.warns?.delete("caps");
    }
    if (isEnterEvent(e) && vm.inputStr) {
      onEnter();
    }
  };

  const onEnter = () => {
    const normalized = getSgtinOrSsccFromString(vm.inputStr);
    if (vm.setCurrentCode) vm.setCurrentCode(normalized);
    vm.addScannedCode(normalized, vm.inputStr);
    if (!vm.errors.size && !(vm.warns && vm.warns.size)) {
      vm.onInput("");
      // счетчик успешно добавленных кодов с помощью ручного ввода
      YMetrika.sendEvent("inputCodeEvent");
    }
    // счетчик попыток отсканировать код
    YMetrika.sendEvent("attemptsToAddCode");
  };

  const renderInputError = () => {
    return vm.warnErrorTitle;
  };

  const showInputError = !!vm.errors.size;

  const showInputWarn = !!(vm.warns && vm.warns.size);

  const renderInfo = () => (
    <InfoText>
      Индивидуальный код <b>Data Matrix</b> состоит из <b>GTIN</b> (14 символов) и <b>серийного номера</b> (13
      символов), например 05995327112039 oFE2UWc5peGoo. GTIN и серийный номер можно посмотреть на упаковке лекарства
      рядом с кодом маркировки.
      <br />
      <br />
      Код транспортной упаковки <b>SSCC</b> (18 символов) находится под линейным штриховым кодом после идентификатора
      «(00)», например (00)393123456000000051. Транспортной упаковкой может служить заводской короб или паллета.
    </InfoText>
  );

  return (
    <Container>
      <InputHeader vm={vm} hideSgtinImg={hideSgtinImg} />
      <InputBlock>
        <Tooltip trigger={"click"} render={renderInfo} pos={"top left"} allowedPositions={["top left", "top center"]}>
          <HelpIconWrap>
            <HelpDotIcon />
          </HelpIconWrap>
        </Tooltip>
        <TooltipWrap>
          <Tooltip render={renderInputError} trigger={"hover&focus"} ref={tooltipEl}>
            <InputWrap>
              <Input
                data-tid="ScannerInput"
                error={showInputError}
                warning={showInputWarn}
                width={"100%"}
                onValueChange={onChangeInput}
                value={vm.inputStr}
                onKeyDown={onKeyDown}
                placeholder={placeholderText || "Ввести код маркировки вручную"}
                autoFocus
                selectAllOnFocus
              />
            </InputWrap>
          </Tooltip>
        </TooltipWrap>
        &nbsp;
        <Button data-tid="Add" use={"primary"} onClick={onEnter} size={"small"} disabled={!vm.inputStr}>
          Добавить
        </Button>
      </InputBlock>
    </Container>
  );
});
