import { getHttpClient } from "./shared";
import { IHttpClient } from "api/HttpClient/shared";
import { drugsApi } from "../enviroment";
import { DownloadResult } from "typings/client";

interface ISignApiInternal {}

class SignApiInternal implements ISignApiInternal {
  constructor(private httpClient: IHttpClient) {}

  getDocumentToSign(deliveryId: string): Promise<DownloadResult> {
    return this.httpClient.download(`${deliveryId}`);
  }

  saveSignature(deliveryId: string, signature: string): Promise<void> {
    return this.httpClient.post(`${deliveryId}`, signature);
  }
}

export const SignApi = new SignApiInternal(getHttpClient(drugsApi + "/signing"));
