import React from "react";
import { observer } from "mobx-react";
import { DeliveryStatusBlock } from "Common/Status/DeliveryStatus/DeliveryStatusBlock";
import { DeliveryStatus } from "typings/server";
import { Spinner } from "components";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { CreatingDocWrap, DateAndFIOWrap } from "Common/Stages/Stages.styles";
import { TransferPageVM } from "../../TransferPage/TransferPageVM";
import { OperationsStatusNames } from "Common/Status/StatusesName";
import { DateAndUser } from "Common/Stages/DateAndUser";

interface IStagesProps {
  vm: TransferPageVM;
}

export const TransferRecallStagesBlock: React.FC<IStagesProps> = observer((props: IStagesProps) => {
  const { vm } = props;

  if (
    !vm.recallStages[DeliveryStage.Signing] ||
    !vm.recallStages[DeliveryStage.Sending] ||
    !vm.recallStages[DeliveryStage.Done]
  ) {
    return null;
  }

  return (
    <>
      <DeliveryStatusBlock
        data-tid="SigningStage"
        status={vm.recallStages[DeliveryStage.Signing].progress}
        name={vm.recallStages[DeliveryStage.Signing].name}
        size={vm.getTitleSize(vm.recallStages[DeliveryStage.Signing])}
        error={vm.recallStages[DeliveryStage.Signing].errorInfo}
        isRecalled={true}
        alwaysVisibleComp={
          <>
            {vm.delivery.status === DeliveryStatus.CreatingDocument && (
              <CreatingDocWrap leftOffset={"256px"}>
                <Spinner type="mini" caption={OperationsStatusNames[DeliveryStatus.CreatingDocument]} />
              </CreatingDocWrap>
            )}
            <DateAndUser
              delivery={vm.delivery}
              getCompletionUser={vm.getCompletionUser}
              getCompletionDate={vm.getCompletionDate}
              step={DeliveryStatus.Signing}
            />
          </>
        }
      />

      <DeliveryStatusBlock
        data-tid="SendingStage"
        status={vm.recallStages[DeliveryStage.Sending].progress}
        name={vm.getSendingStepName(vm.recallStages[DeliveryStage.Sending])}
        size={vm.getTitleSize(vm.recallStages[DeliveryStage.Sending])}
        error={vm.recallStages[DeliveryStage.Sending].errorInfo}
        alwaysVisibleComp={<DateAndFIOWrap data-tid="CompleteInfo">{vm.recallSendingDate}</DateAndFIOWrap>}
      />

      {vm.delivery.status !== DeliveryStatus.RecallFailed && (
        <DeliveryStatusBlock
          data-tid="DoneStage"
          status={vm.recallStages[DeliveryStage.Done].progress}
          name={vm.recallStages[DeliveryStage.Done].name}
          size={vm.getTitleSize(vm.recallStages[DeliveryStage.Done])}
        />
      )}
    </>
  );
});
