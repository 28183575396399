import React from "react";
import { GlobalStyle, NotificationStyle } from "styles/global.styles";
import { ThemeProvider } from "styles/styled-components";
import { theme } from "styles/theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as routes from "routes";
import { GreetingRoute } from "routes";
import { Layout } from "components/Layout/Layout";
import { NotFoundPage } from "components/ErrorPage";
import { ThemeContext as ReactUiThemeContext } from "@skbkontur/react-ui";
import { FLAT_THEME } from "@skbkontur/react-ui";
import { Provider } from "mobx-react";
import { RootStore } from "stores";
import { UserStore } from "stores/UserStore";
import { Loader } from "components/Loader";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import ReactNotification from "react-notifications-component";
import { DeliveriesStore } from "stores/DeliveriesStore";
import { EmptyContent } from "components/Layout";
import { ErrorBoundary } from "components/ErrorBoundary";
import { BalanceStore } from "stores/BalanceStore";

function getAllPaths(): string[] {
  return Object.values(routes)
    .map(route => {
      if (typeof route === "string") {
        return [route];
      } else if ("baseRoute" in route) {
        return [route.route, route.baseRoute];
      } else {
        return [route];
      }
    })
    .flat();
}

interface AppState {
  error: any;
  loading: boolean;
}

export class App extends React.Component<AppState> {
  state = {
    error: undefined,
    loading: true,
  };

  componentDidMount(): void {
    UserStore.getUser()
      .then(async () => {
        await this.init();
        this.setState({ loading: false });
      })
      .catch(e => {
        if (e.status !== 401) this.setState({ error: e });
      });
  }

  async init() {
    await UserStore.tryToSetUserData();
    const needConfiguration = UserStore.user?.needConfiguration;
    const userOnPage = window.location.pathname.includes(GreetingRoute.baseRoute);
    if (needConfiguration && !userOnPage) {
      // если пользователь не на странице настроек и у него нет реквизитов,
      // то перенаправляем на страницу с мастером настроек
      window.location.href = GreetingRoute.getHref();
    }
    await BalanceStore.getBillingInfo();
    DeliveriesStore.initPollingFreshDeliveriesCount();
  }

  render() {
    const { error, loading } = this.state;

    if (error) {
      return (
        <>
          <GlobalStyle />
          <ErrorPage error={error} />
        </>
      );
    }

    if (loading) {
      return (
        <>
          <GlobalStyle />
          <EmptyContent>
            <Loader active />
          </EmptyContent>
        </>
      );
    }

    return (
      <ReactUiThemeContext.Provider value={FLAT_THEME}>
        <Provider {...RootStore}>
          <NotificationStyle />
          <ReactNotification />
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <>
                <GlobalStyle />
                <BrowserRouter>
                  <Switch>
                    <Route path={getAllPaths()} component={Layout} />
                    <NotFoundPage />
                  </Switch>
                </BrowserRouter>
              </>
            </ThemeProvider>
          </ErrorBoundary>
        </Provider>
      </ReactUiThemeContext.Provider>
    );
  }
}
