import React from "react";
import { observer } from "mobx-react";
import { WithFilterInfo } from "models/Filter/FilterModel";
import { Checkbox } from "components";
import { Item } from "./FilterDropdownItem.styles";

interface FilterDropdownItemProps<T> {
  value: WithFilterInfo<T>;
  onClick(value: WithFilterInfo<T>): void;
  renderCustomItemLabel?(value: WithFilterInfo<T>): JSX.Element | string;
}

function FilterDropdownItemInternal<T>({ value, onClick, renderCustomItemLabel }: FilterDropdownItemProps<T>) {
  const { isFilterApplied } = value;
  return (
    <Item>
      <Checkbox data-tid="ToggleFilter" checked={isFilterApplied} onClick={() => onClick(value)}>
        {renderCustomItemLabel?.(value) || value.name}
      </Checkbox>
    </Item>
  );
}

export const FilterDropdownItem = observer(FilterDropdownItemInternal);
