import styled from "styles/styled-components";

export const HeaderComment = styled.div`
  color: ${props => props.theme.textColors.lightGray2};
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
`;

export const Instruction = styled.div`
  position: relative;
  margin-top: -8px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.darkBlue};
  cursor: pointer;
`;

export const InstructionText = styled.ol`
  width: 368px;
  padding-left: 16px;
  margin: 0;

  li {
    margin-bottom: 16px;
  }

  li:last-of-type {
    margin-bottom: 4px;
  }
`;
