import React from "react";
import { observer } from "mobx-react";
import { DateText, Container, ControlPanel } from "./GoodsForm.styles";
import { AcceptanceErrors } from "../../Common/BaseAcceptancePageVM";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";

interface GoodsFormProps {
  errors: Map<AcceptanceErrors, string>;
  rejectDate: string;
  setRejectDate: (val?: string) => void;
  maxDate: string;
  minDate: string;
}

export const GoodsForm: React.FC<GoodsFormProps> = observer(
  ({ errors, rejectDate, setRejectDate, maxDate, minDate }) => {
    const getRejectDateError = () => errors.get("invalidReportDate");

    const handleChangeDate = (val?: string) => setRejectDate(val);

    return (
      <Container>
        <ControlPanel>
          <DateText>Дата отказа</DateText>
          <DateTimePicker
            value={rejectDate}
            onChange={handleChangeDate}
            getReportDateError={getRejectDateError}
            maxDate={maxDate}
            minDate={minDate}
          />
        </ControlPanel>
        <h4>Товары</h4>
      </Container>
    );
  }
);
