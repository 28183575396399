import React, { FC } from "react";
import { observer } from "mobx-react";
import { DisposalRegistratorEditorModal } from "../Editor/DisposalRegistratorEditorModal/DisposalRegistratorEditorModal";
import { AdvertisementBlock } from "Common/AdvertisementBlock/AdvertisementBlock";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { withdrawalInstruction } from "StringResx";
import { OperationsList } from "./OperationsList/OperationsList";
import { WithdrawalEditorModal } from "../Editor/WithdrawalEditorModal/WithdrawalEditorModal";
import AddIcon from "@skbkontur/react-icons/Add";
import { DisposalEditorModal } from "../Editor/DisposalEditorModal/DisposalEditorModal";
import { HelpTextWrap, List } from "./MainPage.styles";
import { CirculationRoute, CirculationSubRoute } from "routes/CirculationRoute";
import { DeliveryDropdownWrap, DeliveryH3, DeliveryTopBlock } from "Common/Styles/MainPage.styles";
import { Dropdown, Link, Loader, MenuItem, RouterLink, Tooltip } from "components";
import { DeliveryIndexModel, DeliveryType, RegistratorEmbeddedModel } from "typings/server";
import { DELIVERY_TYPES, IMainPageProps } from "./MainPage";

export const Withdrawal: FC<IMainPageProps> = observer(({ vm, history }) => {
  const goToDetailsPage = (doc: DeliveryIndexModel) => {
    vm.setIsPreserveFilters(true);
    if (doc.id) {
      history.push(CirculationRoute.getHref(doc.id, CirculationSubRoute.withdrawal));
    }
  };

  const toggleNewWithdrawalModal = () => vm.toggleModal("newWithdrawal");

  const toggleNewDisposalModal = () => vm.toggleModal("newDisposal");

  const toggleNewRegistrarDisposalModal = () => vm.toggleModal("newRegistrarDisposal");

  const onSetAdvertisementShown = () => vm.setAdvertisementShown("disposalRegistrator");

  const availableRegistrators = vm.store.registrators;

  const renderRegistratorsHint = () =>
    availableRegistrators && !availableRegistrators.length ? (
      <HelpTextWrap isAdmin={vm.isAdmin}>
        {vm.isAdmin ? (
          <>
            Для начала работы добавьте регистратор выбытия в разделе{" "}
            <RouterLink to="/settings/registrators">«Настройки»</RouterLink>
          </>
        ) : (
          "Для начала работы попросите администратора добавить регистратор выбытия в настройках"
        )}
      </HelpTextWrap>
    ) : null;

  if (vm.loadingState.get("start")) {
    return <Loader active />;
  }

  return (
    <>
      {vm.modalState.get("newWithdrawal") && <WithdrawalEditorModal onClose={toggleNewWithdrawalModal} />}
      {vm.modalState.get("newDisposal") && <DisposalEditorModal onClose={toggleNewDisposalModal} />}
      {vm.modalState.get("newRegistrarDisposal") && (
        <DisposalRegistratorEditorModal
          onClose={toggleNewRegistrarDisposalModal}
          registrators={vm.store.registrators as RegistratorEmbeddedModel[]}
        />
      )}
      <DeliveryTopBlock>
        <DeliveryDropdownWrap>
          <div>
            <AdvertisementBlock
              isDisplayed={!!vm.advertisementsMap.get("disposalRegistrator")}
              text="Новинка: возможность вывести из оборота через регистратор выбытия"
              width="218px"
              onClose={onSetAdvertisementShown}
            >
              <Dropdown data-tid="CreateNewOperation" caption="Добавить вывод из оборота" icon={<AddIcon />}>
                <MenuItem data-tid="Withdrawal" onClick={toggleNewWithdrawalModal}>
                  По различным причинам
                </MenuItem>
                <MenuItem
                  data-tid="DisposalWithRegistrator"
                  disabled={availableRegistrators && availableRegistrators.length === 0}
                  onClick={toggleNewRegistrarDisposalModal}
                >
                  <Tooltip render={renderRegistratorsHint}>Через регистратор выбытия</Tooltip>
                </MenuItem>
                <MenuItem data-tid="Disposal" onClick={toggleNewDisposalModal}>
                  При неработоспособности регистратора выбытия
                </MenuItem>
              </Dropdown>
            </AdvertisementBlock>
          </div>
          <Link data-tid="InfoLink" href={withdrawalInstruction} icon={<HelpDotIcon />} target="_blank">
            Вывод из оборота
          </Link>
        </DeliveryDropdownWrap>
        <DeliveryH3>Операции</DeliveryH3>
      </DeliveryTopBlock>
      <List>
        <OperationsList
          data-tid="OperationsList"
          listType={DeliveryType.Withdrawal}
          operations={vm.deliveries}
          onClick={goToDetailsPage}
          vm={vm}
          types={DELIVERY_TYPES}
        />
      </List>
    </>
  );
});
