import styled from "styles/styled-components";

export const Container = styled.div`
  margin-left: 30px;
  margin-top: 10px;
`;

export const ScanPanel = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
`;

export const ScanPanelText = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;
`;

export const CodesBlock = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 34px;
`;

export const Reason = styled.span`
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.textColors.lightGray2};
  margin-bottom: 15px;
`;

export const ReturnName = styled.span`
  margin-left: 20px;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 8px;
    left: -11px;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background-color: ${props => props.theme.colors.greyWhisperDark};
  }
`;

export const DestructionBlock = styled.div`
  margin-bottom: 30px;
  white-space: pre-line;
`;
