import React from "react";
import { observer } from "mobx-react";
import { PackingRoute } from "routes";
import { Button, Input, Link } from "components";
import { RouteComponentProps } from "react-router";
import { DeliveryIndexModel, DeliveryType } from "typings/server";
import { List } from "./MainPage.styles";
import { UnpackingSidePage } from "features/ScannerSidePage/SidePages/UnpackingSidePage/UnpackingSidePage";
import { OperationsList } from "./OperationsList/OperationsList";
import { DeliveryDropdownWrap, DeliveryTopBlock, InputWrap } from "Common/Styles/MainPage.styles";
import AddIcon from "@skbkontur/react-icons/Add";
import { PackingSubRoute } from "routes/PackingRoute";
import { Content } from "components/Layout";
import SearchIcon from "@skbkontur/react-icons/Search";
import { PackingIndexPageVM } from "../../PackingIndexPageVM";
import { DeleteDeliveryModal } from "Common/DeleteDelivery/DeleteDeliveryModal/DeleteDeliveryModal";
import { CodesReportModelExtended } from "models/Code/CodesReportModelExtended";
import HelpDotIcon from "@skbkontur/react-icons/HelpDot";
import { disaggregationInstruction } from "StringResx";

interface IMainPageProps extends RouteComponentProps<PackingRoute> {
  pageVM: PackingIndexPageVM;
}

const DELIVERY_TYPE = DeliveryType.Unpacking;

export const MainPage: React.FC<IMainPageProps> = observer((props: IMainPageProps) => {
  const { pageVM, history } = props;
  const { listVM } = pageVM;

  const goToDetailsPage = (doc: DeliveryIndexModel) => {
    listVM.setIsPreserveFilters(true);
    if (doc.id) history.push(PackingRoute.getHref(doc.id, PackingSubRoute.unpacking));
  };

  const toggleSidePage = async () => {
    await pageVM.createDelivery(DELIVERY_TYPE);
    listVM.toggleModal("sidePage");

    // blur(), because scanner triggers button click on "enter"
    if (window.document.activeElement && (window.document.activeElement as HTMLElement).blur) {
      (window.document.activeElement as HTMLElement).blur();
    }
  };

  const onSave = async (model: Partial<CodesReportModelExtended>, id: string) => {
    await pageVM.saveCodes(model);
    history.push(PackingRoute.getHref(id, PackingSubRoute.unpacking));
  };

  const toggleDeleteModal = () => listVM.toggleModal("delete");

  const onDelete = async () => {
    toggleDeleteModal();
    await listVM.deleteDelivery();
  };

  return (
    <Content data-tid="MainPage" padding={"5px 24px 24px"}>
      {listVM.modalState.get("sidePage") && (
        <UnpackingSidePage delivery={pageVM.newDelivery} close={toggleSidePage} save={onSave} isNew />
      )}
      {listVM.modalState.get("delete") && listVM.deliveryToDelete && (
        <DeleteDeliveryModal
          deliveryType={listVM.deliveryToDelete.type}
          onDelete={onDelete}
          onClose={toggleDeleteModal}
          getDeliveryItems={listVM.getDeliveryItems}
          isLoading={listVM.loadingState.get("delete")}
        />
      )}
      <DeliveryTopBlock>
        <DeliveryDropdownWrap>
          <div>
            <Button data-tid="CreateNewOperation" onClick={toggleSidePage} loading={pageVM.loading} icon={<AddIcon />}>
              Расформировать агрегат
            </Button>
            <InputWrap>
              <Input
                data-tid="DocumentNumberSearch"
                value={listVM.aggregateNumberQuery}
                onValueChange={listVM.changeAggregateNumberQuery}
                width={"250px"}
                placeholder="Поиск по номеру агрегата"
                rightIcon={<SearchIcon />}
              />
            </InputWrap>
          </div>
          <Link data-tid="InfoLink" href={disaggregationInstruction} icon={<HelpDotIcon />} target="_blank">
            Расформирование агрегатов
          </Link>
        </DeliveryDropdownWrap>
      </DeliveryTopBlock>

      <List>
        <OperationsList
          data-tid="OperationsList"
          operations={listVM.deliveries}
          onClick={goToDetailsPage}
          type={DELIVERY_TYPE}
          vm={listVM}
        />
      </List>
    </Content>
  );
});
