import React from "react";
import { DatePickerProps } from "@skbkontur/react-ui";
import { DatePicker, Tooltip } from "components";
import { TimeInput } from "../TimeInput/TimeInput";
import { observer } from "mobx-react";
import { TimeWrapper } from "./DateTimePicker.styles";
import { dateFormat, dateFormatWithTime, getFormattedDate, getISODateFromString, getTimeFromISODate } from "helpers";

import { ThemeContext as ReactUiThemeContext, FLAT_THEME, ThemeFactory } from "@skbkontur/react-ui";
import { theme } from "styles/theme";

interface DateTimePickerProps extends Omit<DatePickerProps<string>, "value" | "onValueChange"> {
  value: string;
  onChange: (value?: string) => void;
  getReportDateError: () => string | undefined;
  withoutTime?: boolean;
  utc?: boolean;
}

@observer
export class DateTimePicker extends React.Component<DateTimePickerProps> {
  static defaultProps = {
    minDate: "01.01.1900",
    maxDate: "31.12.2099",
    width: 120,
    isHoliday: (_day: string, isWeekend: boolean) => isWeekend,
  };

  private datePicker: DatePicker | null;

  private updatedTheme = ThemeFactory.create(
    {
      calendarCellHoverBgColor: theme.colors.bluePrimary,
    },
    FLAT_THEME
  );

  render() {
    const { value, onChange, getReportDateError, maxDate, minDate, withoutTime, ...datePickerProps } = this.props;
    const date = getFormattedDate(value);
    const time = getTimeFromISODate(value) || "00:00";
    return (
      <ReactUiThemeContext.Provider value={this.updatedTheme}>
        <Tooltip render={getReportDateError} trigger="hover">
          <>
            <DatePicker
              ref={el => (this.datePicker = el)}
              value={date}
              error={!!getReportDateError()}
              onValueChange={this.onChangeDate}
              maxDate={getFormattedDate(maxDate)}
              minDate={getFormattedDate(minDate)}
              {...datePickerProps}
            />
            {!withoutTime && (
              <TimeWrapper>
                <TimeInput value={time} onChangeTime={this.onChangeTime} error={!!getReportDateError()} />
              </TimeWrapper>
            )}
          </>
        </Tooltip>
      </ReactUiThemeContext.Provider>
    );
  }

  onChangeDate = (newDate: string) => {
    const { value, onChange, withoutTime, utc } = this.props;
    const time = withoutTime ? "" : ` ${getTimeFromISODate(value)}`;
    const date = newDate
      ? getISODateFromString(`${newDate}${time}`, withoutTime ? dateFormat : dateFormatWithTime, utc)
      : newDate;
    onChange(date);
  };

  onChangeTime = (time: string) => {
    const { value, onChange, utc } = this.props;
    const date = getISODateFromString(`${getFormattedDate(value)} ${time || "00:00"}`, dateFormatWithTime, utc);
    onChange(date);
  };

  public focus = (): void => {
    if (this.datePicker != null) {
      this.datePicker.focus();
    }
  };
}
