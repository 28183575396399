import { action, computed, observable } from "mobx";
import { AcceptusType, CreateRefusalModel, DeliveryStatus } from "typings/server";
import { UtdPositionModelExtended } from "models/Delivery/ItemModel/UtdPositionModelExtended";
import { PositionsReportModelExtended } from "models/PositionsReportModelExtended";
import { DeliveryStage } from "Common/DeliveryDictionary";
import { ShipmentTypesNames } from "typings/dictionaries";
import { ReportsApi } from "api/ReportsApi";
import { BaseShipmentPageVM } from "../Common/BaseShipmentPageVM";

export interface IEditorModalParams {
  showError?: boolean;
  onComplete?: Function;
}

type TErrors = "requiredFields";

export class DirectShipmentPageVM extends BaseShipmentPageVM {
  @observable selectedGood: UtdPositionModelExtended;
  @observable editorModalParams: IEditorModalParams = {};
  @observable errors: Map<TErrors, string> = new Map();

  @action
  async save(): Promise<void> {
    this.toggleLoading("creatingDocument");
    this.actions.sendReportDateMetrics(this.delivery.reportDate);
    await this.actions.save(this.delivery);
  }

  savePositions = async () => {
    const reportModel = new PositionsReportModelExtended(this.delivery.id, this.delivery.utdPositions);
    await this.deliveryStore.savePositions(reportModel);
    if (this.delivery.status === DeliveryStatus.New) {
      this.actions.updateStatus(DeliveryStatus.Processing, DeliveryStage.Processing, this.delivery);
    }
  };

  @computed
  get hasAllRequiredFields(): boolean {
    if (this.isEAEUCountry) {
      return !!(this.recipient?.inn && this.recipient?.country && this.delivery.contractType);
    }

    if (this.unregisteredDepartment || this.unregisteredCounteragent) {
      return !!this.delivery.contractType;
    }

    // перед завершением обычной отгрузки по прямому проверяем только контрагента
    // т.к если не заполнен контрагент, то не заполнено ничего из обязательных полей
    return !!(this.delivery.counteragentDepartment && this.delivery.counteragentDepartment.id);
  }

  @action
  setSelectedGood(good: UtdPositionModelExtended) {
    this.selectedGood = good;
  }

  @action
  setSubmitted() {
    this.submitted = true;
  }

  @action
  canComplete(): boolean {
    if (!this.hasAllRequiredFields) {
      this.errors.set("requiredFields", "Для добавления отгрузки заполните оставшиеся поля");
      this.toggleModal("editorModal");
      return false;
    } else {
      this.errors.delete("requiredFields");
    }
    const isUnregistered = this.unregisteredDepartment || this.unregisteredCounteragent;

    if (isUnregistered || this.isEAEUCountry || !this.areAllPositionsHaveCodes) {
      this.toggleModal("confirmation");
      return false;
    }
    return true;
  }

  @action
  onRecall = async (model: CreateRefusalModel) => {
    this.delivery.setRecalled(true);
    try {
      await ReportsApi.refuse(model);
      this.actions.startPolling(this.delivery.id);
      this.delivery.setNextStage(
        DeliveryStatus.CreatingDocument,
        DeliveryStage.Processing,
        {
          completionDate: new Date().toISOString(),
        },
        this.userStore.fullName
      );
    } catch (e) {
      this.delivery.setRecalled(false);
      throw e;
    }
  };

  @action
  async resend(): Promise<void> {
    this.toggleLoading("resend");
    // Отправляем запрос на переотправку части сведений
    await ReportsApi.resend(this.delivery.id);
    // Получаем обновленную операцию
    const item = await this.deliveryStore.getItem(this.delivery.id, { force: true });
    // Обновляем модель
    await this.actions.pooling(this.delivery);
    this.deliveryStore.setSelected(item);
    this.toggleLoading("resend");
  }

  @action.bound
  async rollbackDeliveryStatus() {
    this.toggleLoading("rollback");
    await this.deliveryStore.rollbackDeliveryStatus(this.delivery.id);
    const item = await this.deliveryStore.getItem(this.delivery.id, { force: true });
    this.deliveryStore.setSelected(item);
    this.toggleLoading("rollback");
  }

  @action async deleteDelivery() {
    this.toggleLoading("delete");
    await this.deliveryStore.deleteDelivery(this.delivery.id);
    this.toggleLoading("delete");
  }

  @computed
  get disableCompleteBtn(): boolean {
    return !this.delivery.allCodesLen;
  }

  @computed
  get areAllPositionsHaveCodes(): boolean {
    return this.delivery.utdPositions.every(pos => pos.allCodesLen !== 0);
  }

  @computed
  get unscannedPositionsLen(): number {
    return this.delivery.utdPositions.reduce((sum: number, position: UtdPositionModelExtended) => {
      if (!position.isAllScanned) {
        sum += 1;
      }
      return sum;
    }, 0);
  }

  @computed
  get unregisteredDepartment(): boolean {
    return this.delivery.acceptusType === AcceptusType.UnregisteredDepartment;
  }

  @computed
  get unregisteredCounteragent(): boolean {
    return this.delivery.acceptusType === AcceptusType.UnregisteredCounteragent;
  }

  @computed
  get showWaitingForCounterpartyStep(): boolean {
    const { status } = this.delivery;
    return (
      !this.unregisteredDepartment &&
      !this.unregisteredCounteragent &&
      !this.isEAEUCountry &&
      (status === DeliveryStatus.WaitingForCounterparty || status === DeliveryStatus.PartiallyFailedIncompleted)
    );
  }

  @computed
  get isEAEUCountry(): boolean {
    return this.delivery.acceptusType === AcceptusType.Eeu;
  }

  @computed
  get isResended(): boolean {
    // Если в ОПА есть два и более чилдов, и какой-то из них не в конечной стадии (успех/отклонение/отзыв), значит он вернулся после переотправки
    return (
      this.delivery.childDeliveries.length > 1 &&
      this.delivery.childDeliveries.some(
        item =>
          item.status === DeliveryStatus.Signing ||
          item.status === DeliveryStatus.Sending ||
          item.status === DeliveryStatus.MdlpProcessing ||
          item.status === DeliveryStatus.CreatingDocument
      )
    );
  }

  @computed
  get isStateDispatch() {
    return this.delivery.acceptusType === AcceptusType.StateDispatch;
  }

  @computed
  get isSuspendedReturn() {
    return this.delivery.acceptusType === AcceptusType.SuspendedReturn;
  }

  @computed
  get processingStepName(): string {
    if (this.isEAEUCountry) return ShipmentTypesNames[AcceptusType.Eeu];
    if (this.is627message) return "Приемка по упрощенной схеме получателем";
    if (this.isStateDispatch) return "Смена владельца в рамках государственного лекарственного обеспечения";
    if (this.isSuspendedReturn) return "Возврат приостановленного товара поставщику";
    if (this.isResended) return "Создание черновика";
    return this.stages[DeliveryStage.Processing].name;
  }

  @computed
  get showFooter(): boolean {
    return (
      this.areGoodsEditable ||
      this.showTryAgainBtn ||
      this.showSignBtn ||
      this.showRecallBtn ||
      !!this.rollbackBtnText ||
      this.showDeleteBtn
    );
  }

  @computed
  get showTryAgainBtn(): boolean {
    return this.isPartiallyFailed && !this.delivery.isRejected;
  }

  @computed
  get showDeleteBtn(): boolean {
    const {
      selectedDelivery: { status, acceptusType },
      isCanBeDeleted,
    } = this.deliveryStore;
    return isCanBeDeleted(status, acceptusType) || status === DeliveryStatus.Failed;
  }

  @computed
  get showRecallBtn() {
    const { status } = this.delivery;
    return (
      status === DeliveryStatus.WaitingForCounterparty ||
      status === DeliveryStatus.PartiallyFailedIncompleted ||
      this.isRecallBtnLoading
    );
  }

  @computed
  get isRecallBtnLoading() {
    const { status, isRecalled } = this.delivery;
    return status === DeliveryStatus.CreatingDocument && isRecalled;
  }

  @computed
  get rollbackBtnText(): string | undefined {
    // При переотправке возможности вернуться в черновик мы не даем
    if (this.isResended) {
      return;
    }
    const { status } = this.deliveryStore.selectedDelivery;
    switch (status) {
      case DeliveryStatus.Failed:
      case DeliveryStatus.Recalled:
      case DeliveryStatus.Rejected:
        return "Вернуться к отгрузке";
      case DeliveryStatus.Signing:
        return "Вернуться к черновику";
    }
  }

  @computed
  get shouldOpenRollbackModal(): boolean {
    const { status } = this.deliveryStore.selectedDelivery;
    return status === DeliveryStatus.Failed || status === DeliveryStatus.Recalled || status === DeliveryStatus.Rejected;
  }

  @computed
  get rollbackHintText(): string {
    const { status } = this.deliveryStore.selectedDelivery;
    return status === DeliveryStatus.Signing
      ? "Для редактирования отгрузки"
      : "Для редактирования и повторной отгрузки";
  }

  @computed
  get isPartiallyFailed() {
    return this.delivery.isPartiallyFailed;
  }

  // TODO: подумать, может это можно подстроить под общий интрумент определения прогресса
  @computed
  get doneStageTitle() {
    if (this.delivery.isRecalled) {
      return "Отгрузка отозвана";
    }
    return this.stages[DeliveryStage.Done].name;
  }

  @computed
  get doneStageSize() {
    if (this.delivery.isRecalled) {
      return "medium";
    }
    return this.getTitleSize(this.stages[DeliveryStage.Done]);
  }
}
