import React, { FC, memo, useRef, useEffect, useState } from "react";
import { LoaderRoot, CustomLoaderRoot } from "./Loader.styles";
import { Loader as LoaderBase } from "@skbkontur/react-ui";
import { observer } from "mobx-react";
import { colors } from "styles/colors";

interface Props {
  active: boolean;
  minHeight?: string;
  caption?: string;
  showChildren?: boolean;
  immediate?: boolean;
}

export const Loader: FC<Props> = observer(props => {
  const [loading, setLoading] = useState(false);
  const { active, showChildren, immediate, minHeight, caption, children } = props;

  useEffect(() => {
    if (immediate) {
      setLoading(active);
      return;
    } else {
      const waitingTime = active ? 300 : 1000;
      const timeout = setTimeout(() => setLoading(active), waitingTime);

      return () => clearTimeout(timeout);
    }
  }, [active, immediate]);

  if (loading) {
    return (
      <LoaderBase
        delayBeforeSpinnerShow={0}
        minimalDelayBeforeSpinnerHide={0}
        caption={caption}
        type="big"
        component={<CustomLoader />}
        {...props}
      >
        {showChildren && props.children}
      </LoaderBase>
    );
  } else if (active) {
    return <LoaderRoot minHeight={minHeight}>{showChildren && children}</LoaderRoot>;
  }

  return <>{children}</>;
});

const SIZE = 96;
const RADIUS = 25;

export const CustomLoader = memo(() => {
  const svgRef = useRef<SVGSVGElement>(null);

  return (
    <CustomLoaderRoot
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      width={SIZE}
      height={SIZE}
      fill="none"
      strokeDasharray={`${(10 * RADIUS) / 6}, ${(27 * RADIUS) / 6}`}
      strokeDashoffset="0"
      stroke={colors.bluePrimary}
      strokeLinecap="round"
      strokeWidth={5}
      ref={svgRef}
    >
      <circle cx={SIZE / 2} cy={SIZE / 2} r={RADIUS} />
    </CustomLoaderRoot>
  );
});
